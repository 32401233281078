import { create } from "zustand";
import { persist } from "zustand/middleware";

import { TokenStore } from "types/uam/token";

export const useTokenStore = create<TokenStore>()(
  persist(
    (set,) => ({ 
      token: null,
      state: null,
      setToken: async (token: string| null) => {
        set(() => ({
          token: token
        }));
      },
      setState: async (state: string| null) => {
        set(() => ({
          state: state
        }));
      }      
    }),
    {
      name: 'hypredge-tokens',
    }
  )
)