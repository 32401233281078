import { FC } from "react";
import { Divider, Tabs, theme, Typography } from "antd";

import PageWrapper from "components/PageWrapper";
import { useDeveloperXStore, useSettingsStore } from "store";

import { DeveloperXTabType } from "types";

const { Title, Paragraph, Text, Link } = Typography;

import { AppsTab } from "./apps";

export const DeveloperX: FC = () => {
  const [activeTab, setActiveTab] = useDeveloperXStore(
    (state) => [state.activeTab, state.setActiveTab]
  );

  const { token } = theme.useToken();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);

  return (
    <PageWrapper
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <Title level={3}>Developer</Title>
          {useDivider && <Divider />}
          <Tabs
            defaultActiveKey={DeveloperXTabType.MyApps}
            activeKey={activeTab}
            onTabClick={(key) =>
              setActiveTab(key as DeveloperXTabType)
            }
            items={[
              {
                key: DeveloperXTabType.AllApps,
                label: "Apps",
                children: (
                  <AppsTab/>
                ),
              },
              {
                key: DeveloperXTabType.MyApps,
                label: "My Apps",
                children: (
                  <AppsTab />
                ),
              },
            ]}
          />
        </div>}
    />
  );
};
