import { Space } from "antd";
import { HttpRequestRetryLogic } from "types";
import {
  EditorContext,
  NumberField,
  TextField,
  ViewProps,
} from "components/EntityEditor";

import React, { useContext, useEffect } from "react";

export const HttpRetryPolicyView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onPrimitiveChange = async (
    identifier: string,
    value: string | boolean | number
  ) => {
    setTarget({ ...target, [identifier]: value });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <NumberField
        identifier={"retryCount"}
        label={"Retry Count"}
        value={target["retryCount"]}
        path={[...props.path, "retryCount"]}
        onChange={onPrimitiveChange}
      />

      <NumberField
        identifier={"retryDelaySeconds"}
        label={"Retry Delay Seconds"}
        value={target["retryDelaySeconds"]}
        path={[...props.path, "retryDelaySeconds"]}
        onChange={onPrimitiveChange}
      />

      <TextField
        identifier={"retryLogic"}
        label={"Retry Logic"}
        value={target["retryLogic"]}
        path={[...props.path, "retryLogic"]}
        onChange={onPrimitiveChange}
        options={Object.values(HttpRequestRetryLogic).map((v) => {
          return { label: v, value: v };
        })}
      />
    </Space>
  );
};
