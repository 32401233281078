import { create } from "zustand"
import { devtools, subscribeWithSelector } from "zustand/middleware";

import {
  App,
  PageInfo,
  DeveloperXStore,
  DeveloperXTabType,
  AppTabType,
} from "types";

import {getAppsApi,getAppApi, getDownloadUrlApi, getUploadUrlApi, changeContentAccessApi, createAppApi, deleteAppApi, updateAppApi,} from "api";
import app from "App";

export const useDeveloperXStore = create<DeveloperXStore>()(
  devtools((set, get) => ({
    appItemsMap: new Map<DeveloperXTabType, App[]>(),
    selectedApp: {} as App,
    appsPage: {number:1, size: 12, total: 0} as PageInfo,
    myAppsPage: {number:1, size: 12, total: 0} as PageInfo,
    activeTab: DeveloperXTabType.AllApps,
    activeAppTab: AppTabType.Overview,
    
    getApps: async (tabType, pageNumber, pageSize,  tenantId) => {
      //TODO - add tenantid filter here to get appas for tenant ID
      const [apps, total] = await getAppsApi(pageNumber, pageSize, true);
      const newPage: PageInfo = {number: pageNumber, size: pageSize, total: total};
      try {
        switch(tabType) {
          case DeveloperXTabType.AllApps:
            set((state) => ({
              appsPage: newPage,
              appItemsMap: new Map<DeveloperXTabType, App[]>(state.appItemsMap).set(tabType, apps)
            }));
            return;
          case DeveloperXTabType.MyApps:
            set((state) => ({
              myAppsPage: newPage,
              appItemsMap: new Map<DeveloperXTabType, App[]>(state.appItemsMap).set(tabType, apps)
            }));
            return;
        }
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getApp: async (tabType, appId) => {
      try {
        let app = get().appItemsMap.get(tabType)?.find((app) => app.id == appId);
        if (!app) {
          app = await getAppApi(appId);
        }
        set((state) => ({
          ...state,
          selectedApp: app,
        }));
        return app;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
    
    createApp: async (app) => {
      try {
        const appOut = await createAppApi(app);
        set((state) => ({
          ...state,
          selectedApp: appOut,
        }));  
        return appOut
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    updateApp: async (app: App, sync?: boolean) => {
      try {
        await updateAppApi(app);
        const appOut = await getAppApi(app.id)
        set((state) => ({
          ...state,
          selectedApp: appOut,
        }));  
        return appOut
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    deleteApp: async (appId) => {
      try {
        await deleteAppApi(appId);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setActiveTab: (tab: DeveloperXTabType) => {
      set((state) => ({
        ...state,
        activeTab: tab,
      }));
    },

    setActiveAppTab: (tab: AppTabType) => {
      set((state) => ({
        ...state,
        activeAppTab: tab,
      }));
    },
    
    getPageInfo: (tabType: DeveloperXTabType) => {
      switch(tabType){
        case DeveloperXTabType.AllApps:
          return get().appsPage;
        case DeveloperXTabType.MyApps:
          return get().myAppsPage;
        default:
          console.log("wrong search item type");
          throw "wrong search tab type"
      }
    }, 

    clearApps: () => {
      set((state) => ({
        appItemsMap: new Map<DeveloperXTabType, App[]>(),
        selectedApp: {} as App,
        appsPage: { number: 1, size: 12, total: 0 } as PageInfo,
        myAppsPage: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    clearSelectedApp: () => {
      set((state) => ({
        ...state,
        selectedApp: {} as App,
      }));
    },
  }),
    { name: "DeveloperXStore" }
  )
);
