import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse, Space, Spin, Table } from "antd";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { FieldProps } from "./common";
import { EditableCell, EditableRow, EditableTableProps } from "./editableTable";
import { EditorContext } from "./editorContext";
import { FieldLabel } from "./fieldLabel";

const { Column } = Table;
const { Panel } = Collapse;
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface DataSourceItem {
  id: string;
  k: string;
  v: string | number;
}

export interface PrimitiveMapFieldProps extends FieldProps {
  value: Record<string, string | number>;
  /** Add an empty field to the parent entity, and  reflect it in the data passed in via props */
  onAdd: (key: string) => Promise<void>;
  onChange: (
    key: string,
    items: Record<string, string | number>
  ) => Promise<void>;
  keyReadOnly?: boolean;
}

/** react function component */
export const PrimitiveMapField = (
  props: PropsWithChildren<PrimitiveMapFieldProps>
) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const editorContext = useContext(EditorContext);
  const [rows, setRows] = useState([] as DataSourceItem[]);
  useEffect(() => {
    const newRows = Object.entries(props.value).map(([k, v], index) => {
      return {
        id: `${props.identifier}-${index}`,
        k,
        v,
      };
    });
    setRows(newRows);
  }, [props.value]);

  const [selectedRowKeys, setRowKeys] = useState([] as React.Key[]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "Key",
      dataIndex: ["k"],
      editable: editorContext.view && !props.keyReadOnly ? false : true,
      onCell: (record: DataSourceItem) => ({
        record,
        editable: editorContext.view && !props.keyReadOnly ? false : true,
        dataIndex: ["k"],
        title: "Key",
        handleSave,
      }),
    },
    {
      title: "Value",
      dataIndex: ["v"],
      editable: editorContext.view ? false : true,
      onCell: (record: DataSourceItem) => ({
        record,
        editable: editorContext.view ? false : true,
        dataIndex: ["v"],
        title: "Value",
        handleSave,
      }),
    },
  ];

  const onDelete = async () => {
    const newItems = rows
      .filter((item) => {
        return !selectedRowKeys.includes(item.id);
      })
      .reduce((acc, item) => {
        acc[item.k] = item.v;
        return acc;
      }, {} as Record<string, string | number>);

    await props.onChange(props.identifier, newItems);
    setRowKeys([]);
  };

  /** Check the id to determine the item, replace or insert */
  const handleSave = async (row: DataSourceItem) => {
    const newData = [...rows];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    await props.onChange(
      props.identifier,
      newData.reduce((acc, item) => {
        acc[item.k] = item.v;
        return acc;
      }, {} as Record<string, string | number>)
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Spin spinning={false}>
      <Collapse ghost={true} defaultActiveKey={props.identifier}>
        <Panel
          header={<FieldLabel fp={props} />}
          key={props.identifier}
          extra={
            !editorContext.view && (
              <Space>
                <PlusOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.onAdd(props.identifier);
                  }}
                />
                <MinusOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </Space>
            )
          }
        >
          <Table
            rowSelection={
              editorContext.view
                ? undefined
                : {
                    type: "checkbox",
                    ...rowSelection,
                  }
            }
            rowKey="id"
            components={components}
            columns={columns}
            dataSource={rows}
            pagination={{ position: [] }}
            showHeader={false}
          ></Table>
        </Panel>
      </Collapse>
    </Spin>
  );
};
