
const suggestionInputRegEx = /{{\s*?([^{}]+)\s*?}}/g
const jsonPathRegEx        = /\$([*:@.?()=[\]'\\.a-zA-Z0-9_-]*)/
const startWordRegEx = /([^\s]+)/g

const supportedFuncs = [
    "now",
];

//TODO - add more validations of sprig func with pipes etc.. 
//depends on back end support and what funcs to allow from ui
export const validateSuggestionInput = (input?: string) => {
    if (input) {
        const paths = input.matchAll(suggestionInputRegEx);
        if (paths == null) {
            return true;
        }
        for (const path of paths) {
            if (jsonPathRegEx.test(path[1])) {
                continue;
            } 
            const start = path[1].match(startWordRegEx)
            if (start && start?.length >= 1) {
                if (supportedFuncs.includes(start[0]))  {
                    continue;
                }
            }
            return false;
        }
        return true;
    }
    return true;
}