import {
  BooleanField,
  EditorContext,
  NumberField,
  PrimitiveArrayField,
  TextField,
  ViewProps,
} from "components/EntityEditor";
import React, { useContext, useEffect } from "react";

import {
  ArtifactFieldType,
} from "types";
import { Space } from "antd";

export const ArtifactFieldView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onKeyChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const onTextChange = async (identifier: string, value: string) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onBooleanChange = async (identifier: string, value: boolean) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <TextField
        identifier={"recordKey"}
        label={"Field Name"}
        help={"Field name which user can search inside artifact"}
        value={target["recordKey"]}
        path={[...props.path, target["recordKey"]]}
        onChange={onKeyChange}
      />
      <TextField
        identifier={"description"}
        label={"Description"}
        help={"Description of the field"}
        value={target["recordValue"]["description"]}
        path={[...props.path, target["recordKey"], "description"]}
        onChange={onTextChange}
      />
      <TextField
        identifier={"type"}
        label={"Type"}
        help={"Field type"}
        value={target["recordValue"]["type"]}
        path={[...props.path, target["recordKey"], "type"]}
        onChange={onTextChange}
        options={Object.values(ArtifactFieldType).map((v) => ({
          label: v,
          value: v,
        }))}
      />
      <BooleanField
        identifier={"lowPowerMode"}
        optional={true}
        label={"Project Mode"}
        help={"Provide this field by default if no filters mentioned by user at search"}
        value={target["recordValue"]["lowPowerMode"]}
        checkedChildren="True"
        unCheckedChildren="False"
        defaultChecked={false}
        path={[...props.path, target["recordKey"], "lowPowerMode"]}
        onChange={onBooleanChange}
      />
      <BooleanField
        identifier={"required"}
        optional={true}
        label={"Required"}
        help={"Required field for the provider or not"}
        value={target["recordValue"]["required"]}
        checkedChildren="True"
        unCheckedChildren="False"
        defaultChecked={true}
        path={[...props.path, target["recordKey"], "required"]}
        onChange={onBooleanChange}
      />
      <BooleanField
        identifier={"isPii"}
        optional={true}
        label={"Is Field PII ?"}
        help={"Field contains PII or not, in case of PII it will be masked"}
        value={target["recordValue"]["isPii"]}
        checkedChildren="Yes"
        unCheckedChildren="No"
        defaultChecked={true}
        path={[...props.path, target["recordKey"], "isPii"]}
        onChange={onBooleanChange}
      />
    </Space>
  );
};
