import {
  Divider,
  Typography,
  theme,
  Tabs,
  Button,
  notification,
  Tag,
  Space,
  Row,
  Col,
} from "antd";

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "components/Modal";
import { NIL as NIL_UUID } from 'uuid';
import { useOrganizationStore, useSettingsStore, useCaseStore } from "store";
import ControlButton from "components/ControlButton/controlButton";
import { SvgIcon } from "components/SvgIcon";
import { caseMgmtIcons, commonIcons } from "assets/icons";
import { TabsProps } from "antd/lib";
import { CaseMgmtInfo } from "./CaseInfo";
import { capitalizeFirstWord, getTagColor } from "utility";
import { CaseMgmtNotes } from "./CaseNotes";
import { CaseMgmtActivities } from "./CaseActivities";
import { CaseTabType } from "types";
import { TextWithIcon } from "components/TextWithIcon";
import { CaseMgmtAttachments } from "./CaseAttachments";
import { CaseMgmtLinks } from "./CaseLinks";

const { Text } = Typography;

export interface CaseMgmtCaseProps {
  caseId: string;
}

export const CaseMgmtCase: FC<CaseMgmtCaseProps> = ({
  caseId
}) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [deleteCaseModal, setDeleteCaseModal] = useState<boolean>(false);
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);

  const {
    mspEnabled,
    context,
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags
  }));

  const {
    selectedCase,
    getCase,
    deleteCase,
    activeTab,
    setActiveTab,
  } = useCaseStore((state) => ({
    selectedCase: state.selectedCase,
    getCase: state.getCase,
    deleteCase: state.deleteCase,
    activeTab: state.activeTab,
    setActiveTab: state.setActiveTab,
  }));

  const loadSelectedCase = async (caseId: string) => {
    try {
      setLoader(true);
      await getCase(caseId);
    } catch (error) {
      console.log(error);
      //On failure of loading case, it could be a unauthorized access, switch it to hyprflows home page
      navigate(`/cases`);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    caseId && caseId != "" && loadSelectedCase(caseId);
  }, [caseId, context]);

  const deleteCurrentCase = async () => {
    try {
      setLoader(true);
      setDeleteCaseModal(false);
      await deleteCase(caseId);
      notification.success({
        message: "Case deleted successfully",
        duration: 6,
      });
      navigate(-1);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting case",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: CaseTabType.Info,
      label:  <TextWithIcon
                icon={caseMgmtIcons.caseMgmtInfoIcon}
                text={capitalizeFirstWord(CaseTabType.Info)}
              />,
      children: <CaseMgmtInfo caseLoader={loader} caseIn={selectedCase}/>,
    },
    {
      key: CaseTabType.Notes,
      label:  <TextWithIcon
                icon={caseMgmtIcons.caseMgmtNotesIcon}
                text={capitalizeFirstWord(CaseTabType.Notes)}
              />,
      children: <CaseMgmtNotes caseId={caseId} />,
    },
   {
      key: CaseTabType.Attachements,
      label:  <TextWithIcon
                icon={caseMgmtIcons.caseMgmtAttachmentsIcon}
                text={capitalizeFirstWord(CaseTabType.Attachements)}
              />,
      children: <CaseMgmtAttachments caseId={caseId} />,
    },
    {
      key: CaseTabType.Links,
      label:  <TextWithIcon
                icon={caseMgmtIcons.caseMgmtLinksIcon}
                text={capitalizeFirstWord(CaseTabType.Links)}
              />,
      children: <CaseMgmtLinks caseId={caseId}/>,
    },
    /* {
      key: CaseTabType.Tasks,
      label:  <TextWithIcon
                icon={caseMgmtIcons.caseMgmtTasksIcon}
                text={capitalizeFirstWord(CaseTabType.Tasks)}
              />,
      //children: 
    }, */
  ];

  return (
    <div style={{ margin: token.margin, width: "100%" }}>
      <div
        id="case-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "60px",
          padding: token.paddingXS,
          backgroundColor: token.colorBorderSecondary,
          alignItems: "center",
        }}
      >
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
        {selectedCase 
          &&
          <Space>
            <Text>{selectedCase?.number}</Text>
            {mspEnabled && tags && selectedCase?.tagID
              &&
              <Tag color={getTagColor(selectedCase?.tagID)}>{tags?.find((tag) => (tag.id == selectedCase?.tagID))?.value}</Tag>
            }
          </Space>
        }
        <Button
          type="primary" 
          disabled={caseId == NIL_UUID}
          onClick={() => setDeleteCaseModal(true)}
        >
          Delete
        </Button>
      </div>
      {useDivider && <Divider />}
        <Row gutter={24}>
          <Col span={17}>
            <Tabs
              defaultActiveKey={activeTab}
              onChange={(key) => setActiveTab(key as CaseTabType)}
              items={items}
            />
          </Col>
          <Col span={7}>
            <CaseMgmtActivities caseId={caseId} />
          </Col>
        </Row>
        {deleteCaseModal
          &&
          <Modal
            title={"Delete Case"}
            onClose={() => setDeleteCaseModal(false)}
            open={deleteCaseModal}
            onSubmit={deleteCurrentCase}
          >
            {`Are you sure you want to delete case "${selectedCase?.name}" ?`}
          </Modal>
        }
    </div>
  );
};