import { ExclamationCircleFilled } from "@ant-design/icons";
import { Badge, Button, Modal, Space, Typography, theme } from "antd";
import React from "react";
import { useOrganizationStore } from "store";
import { SubscriptionState } from "types";
import { getStyles } from "../../utility/styles";
import { MspSelector } from "../MspSubscribers/MspSelector";

const { Text, Title } = Typography;
const { confirm } = Modal;
export const SubscriberMode = () => {
  const { token } = theme.useToken();
  const [mspSelector, setMspSelector] = React.useState(false);
  const detachFromProvider = useOrganizationStore(
    (state) => state.detachFromProvider
  );
  const acknowledgeProviderCancel = useOrganizationStore(
    (state) => state.acknowledgeProviderCancel
  );

  const currentProviderId = useOrganizationStore(
    (state) => state.currentProviderId
  );
  const subscriptionState = useOrganizationStore(
    (state) => state.subscriptionState
  );
  const providers = useOrganizationStore((state) => state.providers);
  const classes = getStyles({
    container: {
      gap: token.marginXS,
      alignItems: "flex-start",
      marginBottom: token.margin,
    },
  })();

  const terminate = (name: string, id: string) => {
    confirm({
      title: "Do you Want to cancel your subscription?",
      icon: <ExclamationCircleFilled />,
      content: (
        <Space direction="vertical">
          <Text>{name}</Text>
          <Text type="danger" style={{ fontSize: "0.75rem" }}>
            {id}
          </Text>
        </Space>
      ),
      onOk() {
        detachFromProvider();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const mspSelect = () => {
    setMspSelector(true);
  };

  return (
    <div
      id="subscriber-modes-container"
      className={classes.container}
      style={{ backgroundColor: token.colorBgContainer }}
    >
      <Title level={4}>Managed By</Title>
      {currentProviderId && providers[currentProviderId] && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            width: "100%",
            maxWidth: "500px",
          }}
        >
          {subscriptionState === SubscriptionState.initiated && (
            <Text>Provider approval pending</Text>
          )}
          {subscriptionState === SubscriptionState.approved && (
            <Space>
              <Text>Managed by {providers[currentProviderId].name}</Text>
              <Button
                type="primary"
                onClick={() =>
                  terminate(
                    providers[currentProviderId].name,
                    providers[currentProviderId].id
                  )
                }
              >
                Terminate
              </Button>
            </Space>
          )}
          {subscriptionState === SubscriptionState.rejected && (
            <Space direction="vertical">
              <Badge count={"rejected"} color="red" />
              <Button type="primary" onClick={acknowledgeProviderCancel}>
                Acknowledge
              </Button>
            </Space>
          )}

          {subscriptionState ===
            SubscriptionState.cancelInitiatedByProvider && (
            <Space direction="horizontal">
              <Text>
                Cancellation initiated by{" "}
                <strong>{providers[currentProviderId].name}</strong>
              </Text>
              <Button type="primary" onClick={acknowledgeProviderCancel}>
                Ackowledge
              </Button>
            </Space>
          )}
          {subscriptionState ===
            SubscriptionState.cancelInitiatedBySubscriber && (
            <Space direction="horizontal">
              <Text>Cancellation acknowledgement pending</Text>
            </Space>
          )}
        </div>
      )}
      {!currentProviderId && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
              maxWidth: "500px",
              alignItems: "center",
            }}
          >
            <Text>Not managed by any provider.</Text>
            <Button type="primary" onClick={() => mspSelect()}>
              Providers
            </Button>
          </div>
          {mspSelector && (
            <MspSelector
              providers={providers}
              onClose={async () => setMspSelector(false)}
            />
          )}
        </>
      )}
    </div>
  );
};
