import { EmbeddedActionsParser, tokenMatcher } from "chevrotain";
import { tokenVocabulary } from "./lexer";
import { Query, Condition, Conditions } from "types";

const {
  Projection,
  Where,
  ConditionArtifactField,
  ConditionOperator,
  ConditionValue,
  LogicalOperator,
  LogicalOrOperator,
} = tokenVocabulary;


export class QueryParser extends EmbeddedActionsParser {
  constructor() {
    super(tokenVocabulary);
  
    const $: any = this; // eslint-disable-line

    $.RULE("queryStatement", () => {
      const query: Query = {
        projectionName: "",
        where: "where",
        conditions: [],
      };  
      query.projectionName = $.CONSUME(Projection).image;
      query.where = $.CONSUME(Where).image; 
      query.conditions = $.SUBRULE($.conditionsClause);
      return query
    });

    $.RULE("conditionClause", () => {
      const condition: Condition = {} as Condition;
  
      condition.fact = $.CONSUME(ConditionArtifactField).image;
      condition.operator = $.CONSUME(ConditionOperator).image;
      condition.value = $.CONSUME(ConditionValue).image;

      return condition;
    });

    $.RULE("conditionsClause", () => {
      const currentConditions: Array<Condition | Conditions> = [];
      
      const condition = $.SUBRULE($.conditionClause);
      currentConditions.push(condition);

      $.MANY(() => {
        const logicalOperator = $.CONSUME(LogicalOperator);
        const condition = $.SUBRULE2($.conditionClause);
        if (tokenMatcher(logicalOperator, LogicalOrOperator)) {
          currentConditions.push(condition);
        } else {
          let andConditions: Conditions = [];
          const lastCondition = currentConditions.pop();
          if (Array.isArray(lastCondition)) {
            andConditions = [...lastCondition, condition];
          } else {
            andConditions = [lastCondition, condition];
          }
          currentConditions.push(andConditions);
        }
      });

     

      return currentConditions;
    });

    this.performSelfAnalysis();
  }
}


export class CompatQueryParser extends EmbeddedActionsParser {
  constructor() {
    super(tokenVocabulary);
  
    const $: any = this; // eslint-disable-line

    $.RULE("queryStatement", () => {
      const query: Query = {
        projectionName: "",
        where: "where",
        conditions: [],
      };  
      query.projectionName = $.CONSUME(Projection).image;
      $.OPTION(() => {
        query.where = $.CONSUME(Where).image;
      });
      query.conditions = $.SUBRULE($.conditionsClause);
      return query
    });
    
    $.RULE("conditionClause", () => {
      const condition: Condition = {} as Condition;
  
      condition.fact = $.CONSUME(ConditionArtifactField).image;
      condition.operator = $.CONSUME(ConditionOperator).image;
      condition.value = $.CONSUME(ConditionValue).image;

      return condition;
    });

    $.RULE("conditionsClause", () => {
      const currentConditions: Array<Condition | Conditions> = [];
      
      const condition = $.SUBRULE($.conditionClause);
      currentConditions.push(condition);

      $.MANY(() => {
        const logicalOperator = $.CONSUME(LogicalOperator);
        const condition = $.SUBRULE2($.conditionClause);
        if (tokenMatcher(logicalOperator, LogicalOrOperator)) {
          currentConditions.push(condition);
        } else {
          let andConditions: Conditions = [];
          const lastCondition = currentConditions.pop();
          if (Array.isArray(lastCondition)) {
            andConditions = [...lastCondition, condition];
          } else {
            andConditions = [lastCondition, condition];
          }
          currentConditions.push(andConditions);
        }
      });

     

      return currentConditions;
    });

    this.performSelfAnalysis();
  }
}
export const parserInstance: any = new QueryParser();
export const compatParserInstance: any = new CompatQueryParser();
