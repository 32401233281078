import { FieldValidationRule } from "types"

export const rules = {
  notNullOrUndefined:  {
      name: "notNullOrUndefined",
      message: "Value cannot be null or undefined",
      validator: (value: any) => {
        return value !== null && value !== undefined;
      }
  },
  notEmpty: {
    name: "notEmpty",
    message: "Value cannot be empty",
    validator: (value: any) => {      
      return value !== null && value !== undefined && value !== "";
    }
  },
  minLength: (min: number) : FieldValidationRule=> {
    return {
      name: "minLength",
      message: "Value must be at least " + min + " characters long",
      validator: (value: any) => {
        return value?.length >= min;
      }
    }
  },
  maxLength: (max: number) : FieldValidationRule=> {
    return {
      name: "maxLength",
      message: "Value must be at most " + max + " characters long",
      validator: (value: any) => {
        return value?.length <= max;
      }
    }
  },
  oneOf: (values: any[]) : FieldValidationRule=> {
    return {
      name: "oneOf",
      message: "Value must be one of " + values.join(", "),
      validator: (value: any) => {
        return values.indexOf(value) !== -1;
      }
    }
  },
  lessThan: (max: number) : FieldValidationRule=> {
    return {
      name: "lessThan",
      message: "Value must be less than " + max,
      validator: (value: any) => {
        return value < max;
      }
    }
  },
  lessThanOrEqual: (max: number) : FieldValidationRule=> {
    return {
      name: "lessThanOrEqual",
      message: "Value must be less than or equal to " + max,
      validator: (value: any) => {
        return value <= max;
      }
    }
  },
  greaterThan: (min: number) : FieldValidationRule=> {
    return {
      name: "greaterThan",
      message: "Value must be greater than " + min,
      validator: (value: any) => {
        return value > min;
      }
    }
  },
  greaterThanOrEqual: (min: number) : FieldValidationRule=> {
    return {
      name: "greaterThanOrEqual",
      message: "Value must be greater than or equal to " + min,
      validator: (value: any) => {
        return value >= min;
      }
    }
  },
  matchRegex: (regex: RegExp) : FieldValidationRule=> {
    return {
      name: "matchRegex",
      message: "Value must match the pattern " + regex,
      validator: (value: any) => {
        return regex.test(value);
      }
    }
  },
  required: {
    name: "required",
    message: "Value is required",
    validator: (value: any) => {
      return value !== null && value !== undefined && value !== "";
    }
  }
}

