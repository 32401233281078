import { FieldValidationRule } from './developer/developer';
export type Key = string | number;
export class TreeNode {
  /** These are branch nodes */
  private children: Map<Key, TreeNode> = new Map();
  /** These are leaf nodes */
  private leafNodes: FieldValidationRule[] = [];

  constructor(private value: Key) {}

  public insertLeafNode(path: Key[], leafNode: FieldValidationRule[]): void {
    if (path.length === 0) {
      this.leafNodes = leafNode;
      return;
    }

    let nextNode = this.children.get(path[0]);
    if (!nextNode) {
      nextNode = new TreeNode(path[0]);
      this.children.set(path[0], nextNode);
    }

    nextNode.insertLeafNode(path.slice(1), leafNode);
  }

  public countLeafNodes(path: Key[]): number {
    if (path.length === 0) {
      return this.flattenLeafNodes().length;
    }

    const nextNode = this.children.get(path[0]);
    if (nextNode) {
      return nextNode.countLeafNodes(path.slice(1));
    }

    return 0;
  }

  public flattenLeafNodes(): FieldValidationRule[] {
    if (this.leafNodes.length > 0) {
      return this.leafNodes;
    }

    const flattenedLeafNodes: FieldValidationRule[] = [];
    this.children.forEach((child) => {
      flattenedLeafNodes.push(...child.flattenLeafNodes());
    });

    return flattenedLeafNodes;
  }
}