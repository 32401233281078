import { useState } from "react";
import { Popover, theme, Typography } from "antd";
import { useSettingsStore } from "store";

export interface LinkProperties {
  className?: string;
  style?: any;
  text: any;
  tooltip?: string;
  href?: any;
  target?: any;
  rel?: any;
  alwaysLinkColor?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  onClick?: () => void;
}

const { Link } = Typography;

export const HLink: React.FC<LinkProperties> = ({
  className,
  style,
  text,
  tooltip,
  href,
  target,
  rel,
  alwaysLinkColor,
  underline,
  ellipsis,
  onClick,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  return (
    <Link
      onClick={onClick}
      className={className}
      style={{
        color:
          hover || alwaysLinkColor
            ? mode
              ? "#D21D71"
              : token.colorPrimary
            : mode
            ? "#5c626b"
            : token.colorText,
        ...style,
      }}
      href={href}
      ellipsis={ellipsis}
      target={target}
      rel={rel}
      underline={hover || underline}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {tooltip ? <Popover title={tooltip}>{text}</Popover> : text}
    </Link>
  );
};
