export enum ContactType {
  Email = "email",
  Phone = "phone",
}

export interface Contact {
  value: string;
  primary: boolean;
  type: ContactType;
  validator?: (value: string) => boolean;
}

export enum ContactEditorAction {
  Display,
  Add,
  Remove,
  MakePrimary,
}

export interface ContactManageOperation {
  operation: ContactEditorAction;
  contact: Contact | null;
}
