import { theme, Image, Avatar } from "antd";
import { FunctionComponent } from "react";
import Icon from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
export interface IconProperties {
  className?: string;
  style?: any;
  component: FunctionComponent | string;
  size?: string | number;
  hexColor?: string;
  onClick?: () => void;
}

export const SvgIcon: React.FC<IconProperties> = ({
  onClick,
  className,
  style,
  component,
  size,
  hexColor,
}) => {
  const { token } = theme.useToken();
  if(typeof component == "string") {
    return (
      <Image
        preview={false}
        draggable={false}
        height={size}
        width={size}
        onClick={onClick}
        src={component}
        className={className}
        style={{
          ...style,
          fontSize: size || token.fontSizeHeading4,
          color: hexColor || token.colorPrimary,
        }}
      />
    );
  }
  return (
    <Icon
      onClick={onClick}
      component={component}
      className={className}
      style={{
        ...style,
        fontSize: size || token.fontSizeHeading4,
        color: hexColor || token.colorPrimary,
      }}
    />
  );
  
};
