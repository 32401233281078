import {
  BorderOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  WarningOutlined,
  AuditOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  MenuProps,
  Space,
  Spin,
  message,
  Typography,
  theme,
  Form,
  FormInstance,
  Tooltip,
} from "antd";
import { ReactFlowProvider } from "reactflow";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import pRetry from 'p-retry';

import { SvgIcon } from "components/SvgIcon";
import { actionWorkflowApi, getWorkflowVersionsApi } from "api";
import { Editor } from "components/HyprFlows";
import VersionCard from "components/VersionCard";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Node, isNode } from "reactflow";
import { useOrganizationStore, useWorkflowStore } from "store";
import { notification } from 'utility/notification';
import {
  OperatorType,
  SpecActionType,
  SpecStateType,
  SpecType,
  TriggerType,
  WorkflowActionType,
  WorkflowNodeActionType,
  WorkflowNodeProps,
  WorkflowNodeType,
  WorkflowRunStatusCode,
  WorkflowStepAction,
  WorkflowStepOperator,
  WorkflowTriggerRef,
  Workflow as WorkflowSpec,
  WorkflowRunInput,
} from "types";

import Modal from "../../components/Modal";
import PublishWorkflowModal from "../../components/HyprFlows/PublishWorkflowModal";
import PageWrapper from "../../components/PageWrapper";
import {
  getStepLogo,
  getStepName,
  getStepType,
  getTriggerDisplayName,
  getWorkflowPendingAudits,
  getWorkflowPendingConfigurations,
} from "../../utility/workflow";

import { commonIcons, workflowIcons } from "assets/icons";
import { getStyles } from "../../utility/styles";
import Controls from "./EditorControls/controls";
import ApprovalAction from "components/ApprovalAction";
import { JsonEditor } from "components/JsonEditor";
import RunInputModal from "./runInputModal";
import ControlButton from "components/ControlButton/controlButton";
const { Text } = Typography;

export const Workflow: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [enableRun, setEnableRun] = useState<boolean>(false);
  const [enablePublishModal, setEnablePublishModal] = useState<boolean>(false);
  const [enableAuditModal, setEnableAuditModal] = useState<string>("");
  const [enablePublishCancelModal, setEnablePublishCancelModal] = useState<boolean>(false);
  const [enableUnpublishModal, setEnableUnpublishModal] = useState<boolean>(false);
  const [revertToPublishModal, setRevertToPublishModal] = useState<boolean>(false);
  const [deleteWorkflowModal, setDeleteWorkflowModal] = useState(false);
  const [disableWorkflowModal, setDisableWorkflowModal] = useState(false);
  const [showVersionCard, setShowVersionCard] = useState<boolean>(false);
  const [showJsonView, setShowJsonView] = useState<boolean>(false);
  const [runInput, setRunInput] = useState<{ open: boolean, testMode: boolean }>({ open: false, testMode: false });
  const allowWorkflowRunNameChange = true;

  const abortController = new AbortController();

  const context = useOrganizationStore((state) => state.context);

  const { workflowId } = useParams();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS },
  })();

  const {
    getWorkflow,
    updateWorkflow,
    deleteWorkflow,
    executeWorkflow,
    publishWorkflow,
    publishCancelWorkflow,
    shareWorkflow,
    unpublishWorkflow,
    revertWorkflow,
    enableWorkflow,
    disableWorkflow,
    editWorkflow,
    selectedWorkflow,
    getWorkflowRunStatus,
    stopWorkflowRun,
    setActiveNodeCardId,
    selectedWorkflowRun,
    resetWorkflowState,
    setElements,
    elements,
  } = useWorkflowStore((state) => ({
    getWorkflow: state.getWorkflow,
    updateWorkflow: state.updateWorkflow,
    deleteWorkflow: state.deleteWorkflow,
    executeWorkflow: state.executeWorkflow,
    publishWorkflow: state.publishWorkflow,
    publishCancelWorkflow: state.publishCancelWorkflow,
    shareWorkflow: state.shareWorkflow,
    unpublishWorkflow: state.unpublishWorkflow,
    revertWorkflow: state.revertWorkflow,
    enableWorkflow: state.enableWorkflow,
    disableWorkflow: state.disableWorkflow,
    editWorkflow: state.editWorkflow,
    selectedWorkflow: state.selectedWorkflow,
    getWorkflowRunStatus: state.getWorkflowRunStatus,
    stopWorkflowRun: state.stopWorkflowRun,
    selectedWorkflowRun: state.selectedWorkflowRun,
    setActiveNodeCardId: state.setActiveNodeCardId,
    resetWorkflowState: state.resetWorkflowState,
    setElements: state.setElements,
    elements: state.elements,
  }));

  const [myWorkflow, setMyWorkflow] = useState<boolean>(`${selectedWorkflow?.tenantID}_tag` == context);

  const syncWorkflow = async () => {
    try {
      setLoader(true);
      await getWorkflow(workflowId, true);
    } catch (error) {
      console.log(error);
      //On failure of loading workflow, it could be a unauthorized access, switch it to hyprflows home page
      navigate(`/hyprflows`);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (workflowId) {
      syncWorkflow();
    }
    return () => { abortController.abort("exiting workflow page"); resetWorkflowState() };
  }, [context]);

  useEffect(() => {
    setMyWorkflow(`${selectedWorkflow?.tenantID}_tag` == context);
  }, [selectedWorkflow]);

  const draftModeItems: MenuProps["items"] = [
    {
      key: "versions",
      label: "Versions",
      icon: <HistoryOutlined />,
    },
    {
      key: "jsonview",
      label: "Json View",
      icon: <EditOutlined />,
    },
    {
      key: "delete",
      label: <div style={{ color: "red" }}>Delete</div>,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      disabled: enableRun,
    },
  ];

  const handleDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "versions" && showWorkflowVersions();
    e.key == "jsonview" && setShowJsonView(true);
    e.key == "delete" && setDeleteWorkflowModal(true);
  };

  const publishedModeItems: MenuProps["items"] = [
    {
      key: "unpublish",
      label: "Unpublish",
      icon: <VerticalAlignBottomOutlined />,
      disabled: enableRun,
    },
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      disabled: enableRun,
    },
    {
      key: "versions",
      label: "Versions",
      icon: <HistoryOutlined />,
    },
    {
      key: "jsonview",
      label: "Json View",
      icon: <EditOutlined />,
    },
  ];

  const handlePublishedModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "unpublish" && setEnableUnpublishModal(true);
    e.key == "versions" && showWorkflowVersions();
    e.key == "edit" && editCurrentWorkflow();
    e.key == "jsonview" && setShowJsonView(true);
  };

  const publishPendingModeItems: MenuProps["items"] = [
    {
      key: "versions",
      label: "Versions",
      icon: <HistoryOutlined />,
    },
    {
      key: "jsonview",
      label: "Json View",
      icon: <EditOutlined />,
    },
  ];

  const handlePublishedPendingModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "versions" && showWorkflowVersions();
    e.key == "jsonview" && setShowJsonView(true);
  };

  const publishedDraftModeItems: MenuProps["items"] = [
    {
      key: "versions",
      label: "Versions",
      icon: <HistoryOutlined />,
    },
    {
      key: "revert",
      label: "Revert",
      icon: <VerticalAlignBottomOutlined />,
      disabled: enableRun,
    },
    {
      key: "jsonview",
      label: "Json View",
      icon: <EditOutlined />,
    },
  ];

  const handlePublishedDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "versions" && showWorkflowVersions();
    e.key == "revert" && setRevertToPublishModal(true);
    e.key == "jsonview" && setShowJsonView(true);
  };

  const readOnlyModeItems: MenuProps["items"] = [
    {
      key: "versions",
      label: "Versions",
      icon: <HistoryOutlined />,
    },
    {
      key: "jsonview",
      label: "Json View",
      icon: <EditOutlined />,
    },
  ];

  const handleReadOnlyModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "versions" && showWorkflowVersions();
    e.key == "jsonview" && setShowJsonView(true);
  };

  const getWorkflowNodeIcon = (
    nodeType: WorkflowNodeType,
    resourceType: string
  ) => {
    return <SvgIcon component={getStepLogo(nodeType, resourceType)} />;
  };

  const handlePendingConfigurationMenuClick: MenuProps["onClick"] = (e) => {
    const node = elements.find((el: any) => isNode(el)) as Node;
    if (node && node.data) {
      const props = node.data as WorkflowNodeProps;
      props.actionCallback &&
        props.actionCallback(WorkflowNodeActionType.Click, e.key);
    }
  };

  const handlePendingAuditMenuClick: MenuProps["onClick"] = (e) => {
    const node = elements.find((el: any) => isNode(el)) as Node;
    if (node && node.data) {
      const props = node.data as WorkflowNodeProps;
      props.actionCallback &&
        props.actionCallback(WorkflowNodeActionType.Click, e.key);
    }
  };

  const getPendingConfigurationsMenu = () => {
    const configurations = getWorkflowPendingConfigurations(selectedWorkflow);
    const configurationItems: MenuProps["items"] = [];
    configurations.forEach((configuration) => {
      let menuKey,
        menuLabel = "";
      let menuIcon: any = {};
      switch (configuration.type) {
        case "trigger": {
          const trigger = configuration.obj as WorkflowTriggerRef;
          menuKey = configuration.id;
          menuLabel = getTriggerDisplayName(selectedWorkflow) || "Trigger";
          menuIcon = getWorkflowNodeIcon(
            WorkflowNodeType.Trigger,
            trigger.triggerType
          );
          break;
        }
        case "action": {
          const action = configuration.obj as WorkflowStepAction;
          menuKey = configuration.id;
          menuLabel = getStepName(
            action.actionID,
            WorkflowNodeType.Action
          ).displayName;
          menuIcon = getWorkflowNodeIcon(WorkflowNodeType.Action, action.type);
          break;
        }
        case "operator": {
          const operator = configuration.obj as WorkflowStepOperator;
          menuKey = configuration.id;
          menuLabel = getStepName(
            operator.operatorID,
            WorkflowNodeType.Operator
          ).displayName;
          menuIcon = getWorkflowNodeIcon(
            WorkflowNodeType.Operator,
            getStepType(operator.operatorID, WorkflowNodeType.Operator)
          );
        }
      }
      configurationItems.push({
        key: menuKey,
        label: menuLabel,
        icon: menuIcon,
      });
    });
    return {
      items: configurationItems,
      onClick: handlePendingConfigurationMenuClick,
    };
  };

  const getPendingAuditsMenu = () => {
    const audits = getWorkflowPendingAudits(selectedWorkflow);
    const auditItems: MenuProps["items"] = [];
    audits.forEach((audit) => {
      let menuKey,
        menuLabel = "";
      let menuIcon: any = {};
      switch (audit.type) {
        case "trigger": {
          const trigger = audit.obj as WorkflowTriggerRef;
          menuKey = audit.id;
          menuLabel = getTriggerDisplayName(selectedWorkflow) || "Trigger";
          menuIcon = getWorkflowNodeIcon(
            WorkflowNodeType.Trigger,
            trigger.triggerType
          );
          break;
        }
        case "action": {
          const action = audit.obj as WorkflowStepAction;
          menuKey = audit.id;
          menuLabel = getStepName(
            action.actionID,
            WorkflowNodeType.Action
          ).displayName;
          menuIcon = getWorkflowNodeIcon(WorkflowNodeType.Action, action.type);
          break;
        }
        case "operator": {
          const operator = audit.obj as WorkflowStepOperator;
          menuKey = audit.id;
          menuLabel = getStepName(
            operator.operatorID,
            WorkflowNodeType.Operator
          ).displayName;
          menuIcon = getWorkflowNodeIcon(
            WorkflowNodeType.Operator,
            getStepType(operator.operatorID, WorkflowNodeType.Operator)
          );
        }
      }
      auditItems.push({
        key: menuKey,
        label: menuLabel,
        icon: menuIcon,
      });
    });
    return {
      items: auditItems,
      onClick: handlePendingAuditMenuClick,
    };
  };

  const getMenuOptions = () => {
    if (myWorkflow) {
      switch (selectedWorkflow.state) {
        case SpecStateType.StateDraft:
          return {
            items: draftModeItems,
            onClick: handleDraftModeMenuClick,
          };
        case SpecStateType.StatePublished:
          return {
            items: publishedModeItems,
            onClick: handlePublishedModeMenuClick,
          };
        case SpecStateType.StatePublishPending:
          return {
            items: publishPendingModeItems,
            onClick: handlePublishedPendingModeMenuClick,
          };
        case SpecStateType.StatePublishedDraft:
          return {
            items: publishedDraftModeItems,
            onClick: handlePublishedDraftModeMenuClick,
          };
        default:
          return {};
      }
    } else {
      return {
        items: readOnlyModeItems,
        onClick: handleReadOnlyModeMenuClick,
      };
    }
  };

  const syncWorkflowRunInBackground = async (workflowRunId: string) => {
    const status = await getWorkflowRunStatus(workflowRunId);
    if (status == WorkflowRunStatusCode.Running || status == WorkflowRunStatusCode.PendingApproval) {
      throw new Error(status)
    }
  }

  const runCurrentWorkflow = async (input?: WorkflowRunInput) => {
    try {
      setLoader(true);
      setEnableRun(true);
      const workflow = {
        id: selectedWorkflow.id,
      } as WorkflowSpec;

      workflow.runInput = input;
      const workflowRunId = await executeWorkflow(selectedWorkflow.id, workflow);
      setActiveNodeCardId("workflow");

      pRetry(() => syncWorkflowRunInBackground(workflowRunId), {
        retries: 120,
        minTimeout: 5000,
        maxTimeout: 5000,
        signal: abortController.signal
      }).then(() => {
        setEnableRun(false);
      }).catch((error: any) => {
        setEnableRun(false);
        console.log("pretry sync workflow run status completed, exiting sync of status", error);
      });

    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while running your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const shareCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await shareWorkflow(selectedWorkflow.id)
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while sharing your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };


  const stopCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await stopWorkflowRun(selectedWorkflowRun.id);
      setEnableRun(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while stopping your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const editCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await editWorkflow(selectedWorkflow.id);
      setActiveNodeCardId("");
    } catch (error) {
      console.log(error);
      notification.error({
        message:
          "Something went wrong while switching to edit your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const showWorkflowVersions = async () => {
    setShowVersionCard(true);
  };

  const publishCurrentWorkflow = async (workflow: any) => {
    try {
      setLoader(true);
      setEnablePublishModal(false);
      await publishWorkflow(selectedWorkflow.id, workflow);
      setActiveNodeCardId("");
      notification.success({
        message: "Workflow published successfully",
        duration: 6,
      });
      syncWorkflow();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while publishing your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleAuditSubmitWorkflow = async (workflow: WorkflowSpec) => {
    try {
      setLoader(true);
      workflow.isAuditRequired = false;
      workflow.triggerRef.isAuditRequired = false;
      for (const [currentStepId, step] of Object.entries(workflow.steps)) {
        switch (step.type) {
          case WorkflowNodeType.Action: {
            (step as WorkflowStepAction).isAuditRequired = false;
            workflow.steps[currentStepId] = step;
            break;
          }
          case WorkflowNodeType.Operator: {
            (step as WorkflowStepOperator).isAuditRequired = false;
            workflow.steps[currentStepId] = step;
            break;
          }
        }
      }
      await updateWorkflow(workflow); 
      if(enableAuditModal == "publishing") {
        setEnablePublishModal(true);
      }else if(enableAuditModal == "test running") {
        selectedWorkflow?.parameters?.length || allowWorkflowRunNameChange ? setRunInput({ open: true, testMode: true }) : runCurrentWorkflow()
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while ${enableAuditModal} your workflow...!`,
        duration: 6,
      });
    } finally {
      setEnableAuditModal("");
      setLoader(false);
    }
  };

  const publishCancelCurrentWorkflow = async () => {
    try {
      setLoader(true);
      setEnablePublishCancelModal(false);
      await publishCancelWorkflow(selectedWorkflow.id);
      setActiveNodeCardId("");
      syncWorkflow();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while canceling publish of your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const unpublishCurrentWorkflow = async () => {
    try {
      setLoader(true);
      setEnableUnpublishModal(false);
      await unpublishWorkflow(selectedWorkflow.id);
      setActiveNodeCardId("");
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while unpublishing your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const revertToPublishCurrentWorkflow = async () => {
    try {
      setLoader(true);
      setRevertToPublishModal(false);
      await revertWorkflow(selectedWorkflow.id);
      setActiveNodeCardId("");
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while reverting your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const enableCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await enableWorkflow(selectedWorkflow.id);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while enabling your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const disableCurrentWorkflow = async () => {
    try {
      setLoader(true);
      setDisableWorkflowModal(false);
      await disableWorkflow(selectedWorkflow.id);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while disabling your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const deleteCurrentWorkflow = async () => {
    try {
      setLoader(true);
      await deleteWorkflow(selectedWorkflow.id);
      setDeleteWorkflowModal(false);
      navigate(`/hyprflows`);
      notification.success({
        message: "Workflow deleted successfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting your workflow...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const updateSelectedWorkflow = async (workflow: any) => {
    try {
      setLoader(true);
      await updateWorkflow(workflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const handlePendingApprovalMenuClick: MenuProps["onClick"] = (e) => {
    const node = elements.find((el: any) => isNode(el)) as Node;
    if (node && node.data) {
      const props = node.data as WorkflowNodeProps;
      props.actionCallback &&
        props.actionCallback(WorkflowNodeActionType.Click, e.key);
    }
  };

  const getPendingApprovals = () => {
    const approvalItems: MenuProps["items"] = [];
    if (selectedWorkflowRun?.result?.steps) {
      Object.entries(selectedWorkflowRun.result.steps).map(([stepId, result]) => {
        if (result.status == WorkflowRunStatusCode.PendingApproval) {
          approvalItems.push({
            key: stepId,
            label: selectedWorkflow.steps?.[stepId]?.name,
            icon: <SvgIcon component={getStepLogo(WorkflowNodeType.Operator, OperatorType.Approval)} />,
          });
        }
      })
    }
    return {
      items: approvalItems,
      onClick: handlePendingApprovalMenuClick,
    };
  };

  const ShowEditModeButtons: FC = () => {
    return (
      <Space size={8}>
        {enableRun ? (
          <Space>
            {myWorkflow && selectedWorkflowRun?.status == WorkflowRunStatusCode.PendingApproval
              &&
              <Dropdown
                menu={getPendingApprovals()}
                autoAdjustOverflow
                autoFocus
              >
                <Button
                  type="primary"
                  icon={<SvgIcon size={18} style={{ cursor: "default" }} component={workflowIcons.logPendingApprovalIcon} hexColor={token.colorWhite} />}
                >
                  Approvals
                </Button>
              </Dropdown>
            }
            <Button
              type="primary"
              icon={<BorderOutlined />}
              onClick={() => stopCurrentWorkflow()}
            >
              Stop
            </Button>
          </Space>
        ) :
          <Button
            type="primary"
            icon={<CaretRightOutlined />}
            disabled={!selectedWorkflow.isConfigured}
            onClick={() => {
              if(getWorkflowPendingAudits(selectedWorkflow).length)
                setEnableAuditModal("test running");
              else {
                selectedWorkflow?.parameters?.length || allowWorkflowRunNameChange ? setRunInput({ open: true, testMode: true }) : runCurrentWorkflow()
              }
            }}
          >
            Test Run
          </Button>
        }
        {myWorkflow &&
          <Button
            type="primary"
            icon={<VerticalAlignTopOutlined />}
            disabled={!selectedWorkflow.isConfigured || enableRun}
            onClick={() => {
              if(getWorkflowPendingAudits(selectedWorkflow).length)
                setEnableAuditModal("publishing");
              else
                setEnablePublishModal(true);
            }}
          >
            Publish
          </Button>
        }
      </Space>
    );
  };

  const ShowNonEditModeButtons: FC = () => {
    return (
      <Space size={8}>
        {enableRun ? (
          <Button
            type="primary"
            icon={<BorderOutlined />}
            onClick={() => stopCurrentWorkflow()}
          >
            Stop
          </Button>
        ) :
          selectedWorkflow?.triggerRef?.triggerType == TriggerType.Manual ?
            (
              <Button
                type="primary"
                icon={<CaretRightOutlined />}
                disabled={!selectedWorkflow.isConfigured}
                onClick={() => selectedWorkflow?.parameters?.length || allowWorkflowRunNameChange ? setRunInput({ open: true, testMode: false }) : runCurrentWorkflow()}
              >
                Run
              </Button>
            ) : <></>
        }
        {myWorkflow &&
          <Button
            type="primary"
            disabled={!selectedWorkflow.isConfigured || enableRun}
            onClick={() =>
              selectedWorkflow.enabled
                ? setDisableWorkflowModal(true)
                : enableCurrentWorkflow()
            }
          >
            {selectedWorkflow.enabled ? "Disable" : "Enable"}
          </Button>
        }
        {myWorkflow && !selectedWorkflow.shared &&
          <Button
            type="primary"
            icon={<ShareAltOutlined />}
            disabled={selectedWorkflow.shared}
            onClick={() => shareCurrentWorkflow()}
          >
            Share
          </Button>
        }
      </Space>
    );
  };

  const ShowApprovalModeButtons: FC = () => {
    return (
      <ApprovalAction
        approvalId={selectedWorkflow?.approvalID}
        displayName={selectedWorkflow?.name}
        isTooltipButton={false}
        onApproval={() => syncWorkflow()}
        showApprovalPending={true}
        showApprovalCancel={true}
        onCancel={() => setEnablePublishCancelModal(true)}
      />
    );
  };

  const onVersionCardFetchVersions = async (specId: string) => {
    const versions = await getWorkflowVersionsApi(specId);
    return versions as any[];
  };

  const onVersionCardAction = async (
    specId: string,
    action: SpecActionType,
    version?: unknown
  ) => {
    switch (action) {
      case SpecActionType.ActionDelete:
        await actionWorkflowApi(
          specId,
          WorkflowActionType.WorkflowActionTypeDelete,
          { Version: version }
        );
        return;
      case SpecActionType.ActionRestore:
        await actionWorkflowApi(
          specId,
          WorkflowActionType.WorkflowActionTypeRestore,
          { Version: version }
        );
        return;
    }
  };

  const onVersionCardActionCompleted = async (
    specId: string,
    action: SpecActionType
  ) => {
    navigate(`/workflows/` + specId);
  };

  return (
    <Spin spinning={loader}>
      {showJsonView && workflowId &&
        <JsonEditor
          open={showJsonView}
          width={800}
          title={selectedWorkflow?.name}
          editMode={myWorkflow}
          data={selectedWorkflow}
          icon={workflowIcons.workflowShortIcon}
          onClose={() => setShowJsonView(false)}
          onCopy={async (jsonData: any) => {
            const type = "text/plain";
            const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            await navigator.clipboard.write(data);
            message.success("Copied!");
          }}
          onSave={(jsonData: any) => {
            updateSelectedWorkflow(jsonData);
            setShowJsonView(false);
          }}
        />
      }
      {showVersionCard && workflowId && (
        <VersionCard
          specId={workflowId}
          specType={SpecType.Workflow}
          width={500}
          open={showVersionCard}
          editMode={myWorkflow}
          onFetchVersions={onVersionCardFetchVersions}
          onAction={onVersionCardAction}
          onActionCompleted={onVersionCardActionCompleted}
          onClose={() => {
            setShowVersionCard(false);
            syncWorkflow();
          }}
        />
      )}
      {enablePublishModal && (
        <PublishWorkflowModal
          open={enablePublishModal}
          onClose={() => setEnablePublishModal(false)}
          onSubmit={(workflow) => {
            publishCurrentWorkflow(workflow);
          }}
          loader={loader}
        />
      )}
      {enableAuditModal != "" &&  (
        <Modal
          title="Pending Manual Audits"
          onClose={() => {
            setEnableAuditModal("")
          }}
          open={enableAuditModal != ""}
          onSubmit={() => handleAuditSubmitWorkflow(selectedWorkflow)}
        >
          {`Some manual edits are pending. Are you sure to continue ${enableAuditModal} workflow?`}
        </Modal>
      )}
      {enablePublishCancelModal && (
        <Modal
          title="Workflow Approval Cancel"
          onClose={() => {
            setEnablePublishCancelModal(false);
          }}
          open={enablePublishCancelModal}
          onSubmit={publishCancelCurrentWorkflow}
        >
          {"Are you sure to cancel workflow approval?"}
        </Modal>
      )}
      {enableUnpublishModal && (
        <Modal
          title="Unpublish Workflow"
          onClose={() => {
            setEnableUnpublishModal(false);
          }}
          open={enableUnpublishModal}
          onSubmit={unpublishCurrentWorkflow}
        >
          {"Workflow versions will not be accessible after unpublish, Are you sure to unpublish workflow?"}
        </Modal>
      )}
      {revertToPublishModal && (
        <Modal
          title="Revert To Published Workflow"
          onClose={() => {
            setRevertToPublishModal(false);
          }}
          open={revertToPublishModal}
          onSubmit={revertToPublishCurrentWorkflow}
        >
          {"Are you sure to revert to published workflow?"}
        </Modal>
      )}
      {deleteWorkflowModal && (
        <Modal
          title="Delete Workflow"
          onClose={() => {
            setDeleteWorkflowModal(false);
          }}
          loader={loader}
          open={deleteWorkflowModal}
          onSubmit={deleteCurrentWorkflow}
        >
          {"Are you sure you want to delete your workflow?"}
        </Modal>
      )}
      {disableWorkflowModal && (
        <Modal
          title="Disable Workflow"
          onClose={() => {
            setDisableWorkflowModal(false);
          }}
          loader={loader}
          open={disableWorkflowModal}
          onSubmit={() => {
            disableCurrentWorkflow();
          }}
        >
          {"Are you sure you want to disable your workflow?"}
        </Modal>
      )}
      {runInput.open && (
        <RunInputModal
          open={runInput.open}
          testMode={runInput.testMode}
          selectedWorkflow={selectedWorkflow}
          onClose={() => setRunInput({ ...runInput, open: false })}
          onSubmit={(input: WorkflowRunInput) => {
            setRunInput({ ...runInput, open: false });
            runCurrentWorkflow(input);
          }}
        />
      )}
      <PageWrapper
        content={
          <DndProvider backend={HTML5Backend} options={{ enableMouseEvents: true }}>
            <ReactFlowProvider>
              <div id="workflow-container" className={classes.container}>
                <div
                  id="workflow-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: token.paddingXS,
                    backgroundColor: token.colorBorderSecondary,
                    alignItems: "center",
                  }}
                >
                  <Controls editMode={myWorkflow}></Controls>
                  <Space>
                    <Text style={{ fontSize: token.fontSizeHeading5 }} strong>{selectedWorkflow.name}</Text>
                    <Tooltip 
                      title={"Workflow Properties"}
                      color={token.colorPrimary}
                      placement="top"
                    >
                      <EditOutlined onClick={() => setActiveNodeCardId("workflow")}/>
                    </Tooltip>
                  </Space>
                  <Space>
                    {myWorkflow && selectedWorkflow.isAuditRequired &&
                      getWorkflowPendingAudits(selectedWorkflow).length ? (
                      <Dropdown
                        menu={getPendingAuditsMenu()}
                        autoAdjustOverflow
                        autoFocus
                      >
                        <Button type="primary">
                          <AuditOutlined style={{ color: token.colorSuccess, marginRight: token.marginXXS }} />
                          {
                            getWorkflowPendingAudits(selectedWorkflow).length
                          }
                        </Button>
                      </Dropdown>
                    ) : <></>
                    }
                    {myWorkflow && !selectedWorkflow.isConfigured &&
                      selectedWorkflow.state != SpecStateType.StatePublished &&
                      getWorkflowPendingConfigurations(selectedWorkflow).length ? (
                      <Dropdown
                        menu={getPendingConfigurationsMenu()}
                        //overlayStyle={{maxHeight: "90vh", maxWidth: "300vh", overflow: "auto"}}
                        autoAdjustOverflow
                        autoFocus
                      >
                        <Button type="primary">
                          <WarningOutlined style={{ color: token.colorWarning, marginRight: token.marginXXS }} />
                          {
                            getWorkflowPendingConfigurations(selectedWorkflow).length
                          }
                        </Button>
                      </Dropdown>
                    ) : <></>
                    }

                    {(selectedWorkflow.state == SpecStateType.StatePublished) ? (
                      <ShowNonEditModeButtons />
                    ) : (myWorkflow && selectedWorkflow.state == SpecStateType.StatePublishPending) ? (
                      <ShowApprovalModeButtons />
                    )
                      : (
                        <ShowEditModeButtons />
                      )}
                    <Dropdown placement="bottomLeft" menu={getMenuOptions()}>
                      <Button
                        icon={<SvgIcon component={commonIcons.moreOutlinedIcon} />}
                        ghost
                      />
                    </Dropdown>
                  </Space>
                </div>
                <Editor
                  editMode={
                    myWorkflow
                    &&
                    selectedWorkflow.state != SpecStateType.StatePublished
                  }
                />
              </div>
            </ReactFlowProvider>
          </DndProvider>
        }
      />
    </Spin>
  );
};
