import { createUseStyles } from "react-jss";
import { Dimensions } from "../constant";

export const useNodeStyles = createUseStyles({
  node: {
    minHeight: Dimensions.nodeHeight,
    minWidth: Dimensions.nodeWidth,
    maxHeight: Dimensions.nodeHeight,
    maxWidth: Dimensions.nodeWidth,
  },
  dropzoneNode: {
    minHeight: Dimensions.dropzoneNodeHeight,
    minWidth:  Dimensions.dropzoneNodeWidth,
    maxHeight:  Dimensions.dropzoneNodeHeight,
    maxWidth:  Dimensions.dropzoneNodeWidth,
  },
  nodeTopHandle: {
    opacity: "0",
    top: "6px",
  },
  nodeLeftHandle: {
    opacity: "0",
    left: Dimensions.dropzoneNodeWidth / 2,
  },
  nodeRightHandle: {
    opacity: "0",
    right: Dimensions.dropzoneNodeWidth / 2,
  },
  nodeBottomHandle: {
    opacity: "0",
    bottom: "5px",
  }
});
