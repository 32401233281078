import React from "react";

import { useEffect, useContext } from "react";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { FieldProps, ViewCreator, ViewerType } from "components/EntityEditor";
import { Button, Space, Collapse } from "antd";
import { DeveloperItem } from "types";
import { DeleteOutlined } from "@ant-design/icons";
import { FieldLabel } from "./fieldLabel";
import { EditorContext } from "./editorContext";
const { Panel } = Collapse;

import styles from "./EntityEditor.module.scss";

export interface PanelCollectionFieldProps extends FieldProps {
  items: DeveloperItem[];
  addLabel: string;
  onAdd: (key: string) => Promise<DeveloperItem>;
  onChange: (key: string, items: DeveloperItem[]) => Promise<void>;
  getName: (item: DeveloperItem) => string;
}

export const PanelCollectionField = (props: PanelCollectionFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);
  const editorContext = useContext(EditorContext);
  const [dataSource, setDataSource] = React.useState<DeveloperItem[]>(
    props.items
  );

  useEffect(() => {
    setDataSource(props.items);
  }, [props.items]);

  const onAdd = async () => {
    const newItem = await props.onAdd(props.identifier);
    const newDataSource = [...dataSource, newItem];
    setDataSource(newDataSource);
    await props.onChange(props.identifier, newDataSource);
  };

  const onRemove = async (item: DeveloperItem) => {
    const newDataSource = dataSource.filter((i) => i.id !== item.id);
    setDataSource(newDataSource);
    await props.onChange(props.identifier, newDataSource);
  };

  const onChange = async (index: number, value: DeveloperItem) => {
    const newDataSource = dataSource.map((x) =>
      x.id === value.id ? value : x
    );
    setDataSource(newDataSource);
    await props.onChange(props.identifier, newDataSource);
  };

  return (
    <Space
      direction="vertical"
      style={{ display: "flex", justifyContent: "center" }}
      size={"large"}
    >
      <Collapse ghost={true} activeKey={props.identifier}>
        <Panel header={<FieldLabel fp={props} />} key={props.identifier}>
          <Collapse ghost={true} accordion={true}>
            {dataSource.map((item, index) => {
              return (
                <Panel
                  key={item.id}
                  header={props.getName(item)}
                  style={{ margin: "5px" }}
                  extra={
                    !editorContext.view && (
                      <Button
                        ghost
                        shape="circle"
                        size="small"
                        className={styles.deleteCondButton}
                        style={{ marginTop: "-3px" }}
                        icon={
                          <DeleteTwoTone className={styles.deleteCondIcon} />
                        }
                        onClick={(e: any) => {
                          e.stopPropagation();
                          onRemove(item);
                        }}
                      />
                    )
                  }
                >
                  <ViewCreator
                    item={item}
                    name={""}
                    path={[...props.path, index]}
                    onSave={(value) =>
                      onChange(index, {
                        ...item,
                        item: value,
                      })
                    }
                    viewerType={ViewerType.NoControlsViewer}
                    onDelete={async () => {
                      return;
                    }}
                    onCancel={async () => {
                      return;
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
          {!editorContext.view && (
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                marginTop: "10px",
              }}
            >
              <Button
                type="default"
                onClick={onAdd}
                block
                icon={<PlusOutlined />}
              >
                {props.addLabel}
              </Button>
            </div>
          )}
        </Panel>
      </Collapse>
    </Space>
  );
};
