import { Divider, Form, Input, Spin, Tabs } from "antd";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useRbacStore } from "store";
import { useTokenStore } from "store/token";
import { PermissionSetDto } from "types";
import { Assign, HasIdentity } from "components/Assign";
import { notification } from "utility/notification";

const { TabPane } = Tabs;

const formFields = [
  {
    name: "name",
    label: "Permission Set Name",
    rules: [{ required: true, message: "Please input name!" }],
  },
  {
    name: "description",
    label: "Description",
    rules: [{ required: true, message: "Please input description!" }],
  },
];

export interface PermissionSetEditProps {
  isAdd: boolean;
  readOnly: boolean;
  pset?: PermissionSetDto;
  psets?: PermissionSetDto[] /** These are constituent psets */;
  onClose: () => Promise<void>;
}

export const PermissionSetEdit = (props: PermissionSetEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedPermissionSets, setSelectedPermissionSets] = useState<
    string[]
  >([]);
  const [drawerState, setDrawerState] = useState(false);
  const [, setTenantId] = useState<string>("");
  const jwt = useTokenStore((state) => state.token);

  const [permissionSetDataSource, setPermissionSetDataSource] = useState<
    HasIdentity[]
  >([]);

  /** One time initialization of tennat id and user id using JWT in localstorage */
  useEffect(() => {
    if (jwt) {
      const payload = jwt.split(".")[1];
      const decoded = JSON.parse(atob(payload));
      const tenantId = decoded["https://hypredge.com/rbac/zs_tenant_id"];
      setTenantId(tenantId);
    }
  }, [jwt]);

  useEffect(() => {
    setDrawerState(true);
    try {
      setLoading(true);
      if (props.pset) {
        const fields = {
          name: props.pset.name,
          description: props.pset.description,
        };
        form.setFieldsValue(fields);
      }
      const p =
        props.psets
          ?.filter((x) => props.pset?.constituents.includes(x.id))
          .map((x) => x.id) || [];
      console.log("selected psets", JSON.stringify(p));
      setSelectedPermissionSets(p);
      setPermissionSetDataSource(
        props.psets
          ?.filter((x) => x.id != props.pset?.id)
          .map((x) => ({
            id: x.id,
            displayName: x.name,
            description: x.description,
            selected: false,
            assignable: true,
          })) || []
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, [props.pset, props.psets]);

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;
    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        hasValidationError = true;
        throw error;
      }
      if (props.isAdd) {
        const { name, description } = values;
        await createPermissionSet({
          description,
          name,
          constituents: selectedPermissionSets,
        });
      } else if (props.pset) {
        const { name, description } = values;
        await updatePermissionSet({
          id: props.pset.id,
          constituents: selectedPermissionSets,
          name,
          description,
        });
      }

      notification.success({
        message: `Successfully ${
          props.isAdd ? "created" : "updated"
        } permission set`,
      });
    } catch (error) {
      if (!hasValidationError)
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } permission set`,
          duration: 6,
        });
    } finally {
      setLoading(false);
      if (!hasValidationError) props.onClose();
    }
  };

  const createPermissionSet = useRbacStore(
    (state) => state.createPermissionSet
  );

  const updatePermissionSet = useRbacStore(
    (state) => state.updatePermissionSet
  );

  const onPermissionSetSelectionChange = async (
    selectedRowKeys: React.Key[]
    // selectionRows: HasIdentity[]
  ) => {
    console.log("selected psets", JSON.stringify(selectedRowKeys));
    setSelectedPermissionSets(selectedRowKeys as string[]);
  };

  const onClose = async () => {
    setSelectedPermissionSets([]);
    form.resetFields();
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Permission Sets"
      subtitle="permissions and resource"
      onClose={onClose}
      open={drawerState}
      onSubmit={handleSubmit}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
              style={{ margin: "10px" }}
            >
              <Input disabled={props.readOnly} />
            </Form.Item>
          ))}
        </Form>
        <Divider />
        <Tabs defaultActiveKey="psets-tab">
          <TabPane tab="Permission Sets" key="psets-tab">
            <Assign
              dataSource={permissionSetDataSource.map((x) => ({
                ...x,
                selected: selectedPermissionSets.includes(x.id),
              }))}
              readonly={props.readOnly}
              name="Permission Sets"
              onChange={onPermissionSetSelectionChange}
              showAdd={!props.readOnly}
              type="permissionsets"
              multiple={true}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </Drawer>
  );
};
