/** Hypredge specific draweb implementation  */
import { PropsWithChildren, ReactNode } from "react";
import { Drawer as AntDrawer, theme } from "antd";
import { DrawerFooter } from "./DrawerFooter";
import { DrawerHeaderTitle } from "./DrawerHeaderTitle";

export interface DrawerProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  open: boolean;
  hideFooter?: boolean;
  footerName?: string;
  onClose: () => Promise<void>;
  onSubmit?: () => Promise<void>;
}

export const Drawer = (props: PropsWithChildren<DrawerProps>) => {
  const { token } = theme.useToken();
  return (
    <AntDrawer
      closable={false}
      width={420}
      title={
        <DrawerHeaderTitle
          title={props.title}
          subtitle={props.subtitle}
          onClose={props.onClose}
        />
      }
      onClose={props.onClose}
      open={props.open}
      headerStyle={{ padding: 0, backgroundColor: token.colorFill }}
      className={"drawer"}
      footer={
        !props.hideFooter && (
          <DrawerFooter
            onClose={props.onClose}
            handleSubmit={props.onSubmit || props.onClose}
            enabled={!!props.onSubmit}
            footerName={props.footerName}
          />
        )
      }
    >
      {props.children}
    </AntDrawer>
  );
};
