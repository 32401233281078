import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import {settingsApiPath} from "./constant"
import { SamlIdpSettings, Section } from "types";

export const getSectionsApi = async () : Promise<AxiosResponse<any, any>>=>  axiosInterceptor("get",settingsApiPath)  

 

export const setSectionsApI = async (sections: Section[]): Promise<AxiosResponse<any, any>> => 
  axiosInterceptor("put", settingsApiPath, sections );


export const getSamlIdpSettingsApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("get",`${settingsApiPath}/idp`);

export const setSamlIdpSettingsApi = async (samlIdpSettings: SamlIdpSettings) : Promise<AxiosResponse<any, any>> => axiosInterceptor("post",`${settingsApiPath}/idp`, samlIdpSettings);

export const getSamlSpSettingsApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("get",`${settingsApiPath}/sp/metadata`);

export const putTestApi = async () : Promise<AxiosResponse<any, any>> => axiosInterceptor("put",`${settingsApiPath}/oui`,);