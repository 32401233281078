import { DeveloperTab, ResourceType } from "types";
export const translateEnum = (value: ResourceType | DeveloperTab): string => {
  switch (value) {
    case ResourceType.Action:
      return "Action";
    case ResourceType.Trigger:
      return "Trigger";
    case ResourceType.Artifact:
      return "Artifact";
    case ResourceType.App:
      return "App";
    case ResourceType.ActionProvider:
      return "Action Provider";
    case ResourceType.TriggerProvider:
      return "Trigger Provider";
    case ResourceType.ArtifactProvider:
      return "Artifact Provider";
    case ResourceType.Parameters:
      return "Parameters";
    case ResourceType.Outputs:
      return "Outputs";
    case ResourceType.IODataParameter:
      return "Parameter";
    case ResourceType.IODataOutput:
      return "Primitive Output";
    case ResourceType.IODataMapOutput:
      return "Collection Outputs";
    case ResourceType.IODataArrayOutput:
      return "Sequential Outputs";
    case ResourceType.ArtifactField:
      return "Artifact Field";
    case ResourceType.Primitive:
      return "Primitive";
    case ResourceType.Condition:
      return "Condition";
    case ResourceType.HttpRequest:
      return "Http Request";
    case ResourceType.HttpBasicAuth:
      return "Basic Authentication";
    case ResourceType.HttpOAuth:
      return "OAuth2 Authorization";
    case ResourceType.HttpRetryPolicy:
      return "Retry Policys";
    case ResourceType.HttpPollPolicy:
      return "Poll Policy";
    case ResourceType.HttpPollIncrementalTokenLocatorHeaders:
      return "How to locate the token in the header";
    case ResourceType.HttpPollIncrementalTokenLocatorPayload:
      return "How to locate the token in the payload";
    case ResourceType.HttpPollIncrementalTokenLocatorQuery:
      return "How to locate the token in the query";
    case ResourceType.HttpPollIncrementalTokenReaderTimestamp:
      return "How to read the timestamp";
    case ResourceType.HttpPollIncrementalTokenReaderResponseHeader:
      return "How to read the token from the header";
    case ResourceType.HttpPollIncrementalTokenReaderResponsePayload:
      return "How to read the token from the payload";
    default:
      return "Unknown";
  }
};
