import {
  DeleteOutlined,
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Collapse,
  Dropdown,
  MenuProps,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import { DeveloperItem, INVALID_DEVELOPER_ITEM, ResourceType } from "types";
import { CollectionMutatorPosition, FieldProps, ViewerType } from "./common";
import { EditorContext } from "./editorContext";

import { translateEnum } from "utility/developer/enumTranslator";
import { EditItem } from "./common";
import { FieldLabel } from "./fieldLabel";
import { ViewCreator } from "./viewCreator";

const { Panel } = Collapse;
const { Text } = Typography;
/** A special value edit index that signifies edit of a newly added item */
const EDIT_INDEX_NEW = 999999999;

export interface CollectionFieldProps extends FieldProps {
  value: DeveloperItem[];
  /** Whether the array is currently being loaded by the parent  */
  loading: boolean;

  columns: ColumnType<DeveloperItem>[];
  /** What viewer type to use is determined by the type of individual item*/
  viewerType: ViewerType;
  /** Applicable only with table layout */
  showTableHeader: boolean;
  /** Whether  the colletion container should be displayed expanded initially*/
  showExpanded: boolean;
  /** Callback if item is not inline. Caller should only create a new empty value and return.
   * If multiple resource types are addable then the subKey argument is useful
   * */
  onAdd: (key: string, subKey?: ResourceType) => Promise<DeveloperItem>;

  onChange: (key: string, items: DeveloperItem[]) => Promise<void>;

  /** Whether the add remove options should be in panel header */
  mutatorPosition: CollectionMutatorPosition;
  /** Whether multiple items types maybe added , and is if so, what types*/
  allowedTypes: ResourceType[];

  mutatorButtonStyle?: React.CSSProperties;
  /** Whether to show the add button */
  showAdd: boolean;
  addLabel?: string;
  addButtonType?: any;
  addButtonIcon?: any;
  /** Whether to show the delete button */
  showDelete: boolean;
  /** Show count of items */
  showCount: boolean;
  /** Whether to show the edit button */
  noDrillDown?: boolean;
}

/**
 *  OnAdd : Get a new item and add it to the array end
 *  OnEdit : Get the index and the edit item, and invoke the eqdit jsx with the item
 *  OnChange : Get the new array and update the upstream value
 */

/** react function component */
export const CollectionField = (props: CollectionFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const editorContext = useContext(EditorContext);
  const [columns, setColumns] = useState<ColumnType<DeveloperItem>[]>([]);
  const [dataSource, setDataSource] = useState<DeveloperItem[]>(props.value);
  const [selectedRowKeys, setRowKeys] = useState([] as React.Key[]);
  const [collapsePanel, setCollapsePanel] = useState<boolean>(props.showExpanded);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: DeveloperItem) => ({
      disabled: record.context?.readonly,
    }),
  };

  const [editItem, setEditItem] = useState({
    index: -1,
    item: INVALID_DEVELOPER_ITEM,
  } as EditItem);

  /** Applicable for table view, renders first column as a clickable items */
  const renderFirstColumn = (
    text: any,
    record: DeveloperItem,
    index: number
  ) => {
    return (
      <>
        <Typography.Link
          onClick={() => {
            setEditItem({ index, item: record });
          }}
        >
          {typeof text === "string"
            ? text?.length > 0
              ? text
              : "No Name"
            : text}
        </Typography.Link>
      </>
    );
  };

  useEffect(() => {
    setDataSource(props.value);
  }, [props.value]);

  /** Applicable for table view  */
  useEffect(() => {
    const columns = props.columns.map((c, i) => {
      return {
        ...c,
        render:
          i != 0 ? c.render : props.noDrillDown ? c.render : renderFirstColumn,
      };
    });
    setColumns(columns);
  }, []);

  const onAdd = async (subType: ResourceType) => {
    const newItem = await props.onAdd(props.identifier, subType);
    /** Bail if new item is invalid */
    if (newItem.type == ResourceType.Unknown) return;
    setEditItem({ index: EDIT_INDEX_NEW, item: newItem });
  };

  /** Deleting an item is a mutation operation, and we cannot have two mutations
   * active at any given time. Hence, remove any active edit item during deletion
   */
  const deleteSelected = async (keys: React.Key[]) => {
    const newItems = props.value.filter((item) => {
      return !keys.includes(item.id);
    });
    setEditItem({ index: -1, item: INVALID_DEVELOPER_ITEM });
    await props.onChange(props.identifier, newItems);
  };

  /** Invoked during multi select delete, applicable for table view */
  const onDelete = async () => {
    await deleteSelected(selectedRowKeys);
    setRowKeys([]);
  };

  const onEditDone = async (value: { [k: string]: any }) => {
    const newItems: DeveloperItem[] = props.value.map((x, i): DeveloperItem => {
      return i === editItem.index ? { ...x, item: value } : x;
    });
    if (editItem.index == EDIT_INDEX_NEW && editItem.item) {
      newItems.push({ ...editItem.item, item: value });
    }

    props.onChange(props.identifier, newItems);

    setEditItem({ index: -1, item: INVALID_DEVELOPER_ITEM });
  };

  const items: MenuProps["items"] = props.allowedTypes?.map((type) => {
    return {
      label: translateEnum(type),
      key: type,
    };
  });

  const handleMenuClick: MenuProps["onClick"] = (selection) => {
    return onAdd(selection.key as ResourceType);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  /** A function that is trying to handle too many conditions for positioning, functionality and view of add delete button */
  const mutatorButtons = (block: boolean): JSX.Element => {
    return (
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            float: "left",
            textAlign: "left",
            width: "50%",
          }}
        >
          {props.showCount && (
            <Text>
              <h5>
                Total {props.label} ({dataSource.length})
              </h5>
            </Text>
          )}
        </div>
        <div
          style={
            props.mutatorButtonStyle || {
              float: "right",
              textAlign: "right",
              width: "50%",
            }
          }
        >
          <Space>
            {props.showAdd && props.allowedTypes.length == 1 && (
              <div>
                <Button
                  type={props.addButtonType || "primary"}
                  onClick={() => onAdd(props.allowedTypes[0])}
                  block={block}
                  icon={props.addButtonIcon}
                >
                  {props.addLabel || "Add"}
                </Button>
              </div>
            )}
            {props.showAdd && props.allowedTypes.length > 1 && (
              <Dropdown menu={menuProps}>
                <Button type="primary" block={block}>
                  <Space>
                    {props.addLabel || "Add"}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            )}
            {props.showDelete && (
              <Button
                type="primary"
                onClick={onDelete}
                disabled={!selectedRowKeys.length}
                block={block}
              >
                Delete
              </Button>
            )}
          </Space>
        </div>
      </div>
    );
  };

  const editItemFragment = (viewerType: ViewerType) => {
    return (
      <ViewCreator
        item={editItem.item}
        viewerType={viewerType}
        path={[...props.path, editItem.index]}
        onSave={onEditDone}
        onDelete={async (item: DeveloperItem) => {
          await deleteSelected([item.id]);
        }}
        onCancel={async () => {
          setEditItem({ index: -1, item: INVALID_DEVELOPER_ITEM });
        }}
        name={`${editItem?.item.item?.recordKey}`}
      />
    );
  };

  const onCollapseChange = () => {
    setCollapsePanel(!collapsePanel);
  };


  return (
    <Spin spinning={props.loading ? true : false}>
      <Space
        direction="vertical"
        size={"small"}
        style={{ display: "flex", width: "100%" }}
      >
        <Collapse
          defaultActiveKey={props.showExpanded ? props.identifier : undefined}
          ghost={true}
          onChange={onCollapseChange}
        >
          <Panel
            header={<FieldLabel fp={props} />}
            key={props.identifier}
            extra={
              /** Add remove button in header */
              props.mutatorPosition == CollectionMutatorPosition.Header ? (
                editorContext.view ? null : (
                  <Space>
                    {props.showAdd && props.allowedTypes.length == 1 && (
                      <PlusOutlined
                        onClick={(e: any) => {
                          e.stopPropagation();
                          onAdd(props.allowedTypes[0]);
                        }}
                      />
                    )}
                    {props.showAdd && props.allowedTypes.length > 1 && (
                      <Dropdown menu={menuProps}>
                        <PlusOutlined />
                      </Dropdown>
                    )}

                    {props.showDelete && (
                      <MinusOutlined
                        onClick={(e: any) => {
                          e.stopPropagation();
                          onDelete();
                        }}
                        //disabled={selectedRowKeys.length == 0}
                      />
                    )}
                  </Space>
                )
              ) : null
            }
          >
            {/* Add remove button in space between content and header */}
            {!editorContext.view &&
              props.mutatorPosition == CollectionMutatorPosition.InBetween &&
              mutatorButtons(false)}
            <Table
              rowKey={"id"}
              rowSelection={
                editorContext.view || props.noDrillDown
                  ? undefined
                  : {
                      type: "checkbox",
                      ...rowSelection,
                    }
              }
              dataSource={dataSource}
              columns={columns}
              pagination={{ position: [] }}
              showHeader={props.showTableHeader}
            ></Table>
            {editItem.index != -1 && editItemFragment(props.viewerType)}

            {!editorContext.view &&
              props.mutatorPosition == CollectionMutatorPosition.Footer &&
              mutatorButtons(true)}
          </Panel>
        </Collapse>
      </Space>
    </Spin>
  );
};
