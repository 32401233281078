import { App } from "types";

export function getAppLogoUrl(app: App, lightMode: boolean) {
  return lightMode
    ? app?.logoUrl
      ? app.logoUrl
      : ""
    : app?.darkLogoUrl
    ? app.darkLogoUrl
    : "";
}
