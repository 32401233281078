import { getMetricLayouts, getMetricsApi, getMetricsWithLayoutApi } from "api";
import { MetricStore, Metric, MetricLayout, metricLayouts } from "types";
import { create } from "zustand";
import defaultMetrics from "types/visibility/defaultMetrics.json"
import { jsonApiDeserializer } from "api/constant";

function normailizeMetrics(metrics: Metric[]):  Map<string, Metric[]> {
  const formedMetrics  = new Map<string, Metric[]>();
    metricLayouts.forEach ( (layout: MetricLayout, lid) => {
      const lmetrics: Metric[] = [];
      layout.metrics.forEach ( (id) =>  {
        const m  = metrics.find((metric: Metric) => metric.id == id)
        m && lmetrics.push(m)
      })
      formedMetrics.set(lid, lmetrics)
    })
  return formedMetrics
}

function normailizeMetricsWithLayout(layoutName: string, metrics: Metric[]):  Metric[] {
  const layout = metricLayouts.get(layoutName);
  const lmetrics : Metric[] = [];
  layout?.metrics.forEach( (lid) => {
    const m  = metrics.find((metric: Metric) => metric.id == lid)
    m && lmetrics.push(m)
  })
  return lmetrics
}

export const useMetricStore = create<MetricStore>()((set, get) => ({  
  metrics: normailizeMetrics(jsonApiDeserializer.deserialize(defaultMetrics) as Metric[]),
  
  fetchRefreshMetricsForLayout: async (layout: string) => {
    try {
      const layoutedMetrics = await getMetricsWithLayoutApi(layout)
      set((state) => ({
        ...state,
        metrics: new Map<string, Metric[]>(state.metrics).set(layout, normailizeMetricsWithLayout(layout, layoutedMetrics))
      }));
    }catch (error: any) {
      console.error(error);
    }
  },

  fetchMetricsForLayout: async (layout: string) => {
    try {
      const layoutedMetrics = await getMetricsWithLayoutApi(layout, true)
      set((state) => ({
        ...state,
        metrics: new Map<string, Metric[]>(state.metrics).set(layout, normailizeMetricsWithLayout(layout, layoutedMetrics))
      }));
    }catch (error: any) {
      console.error(error);
    }
    get().fetchRefreshMetricsForLayout(layout)  
  },  

  fetchRefreshMetrics: async () => {
    try {
      const  newMetrics = await getMetricsApi();
      set((state) => ({
        ...state,
        metrics: normailizeMetrics(newMetrics)
      }));
    } catch (error: any) {
      console.error(error);
    }
  },
  fetchMetrics: async () => {
    try {
      const  newMetrics = await getMetricsApi(true);
      set((state) => ({
        ...state,
        metrics: normailizeMetrics(newMetrics)
      }));
    } catch (error: any) {
      console.error(error);
    }
    get().fetchRefreshMetrics()  

  },
}));
