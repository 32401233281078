
export function calculateSearchQueryOnSelect(selectedValue: string, query: string, lastTokens: string[]): string {
  let newSearchQuery = "";
  if (query) {
    newSearchQuery = query;
  }
  const searchTokens = query?.split(" ");
  if (searchTokens?.length) {
    if (lastTokens.length) {
      for (const token of lastTokens) {
        if (
          token.length &&
          selectedValue.includes(token) &&
          searchTokens.pop() == token
        ) {
          newSearchQuery = searchTokens.join(" ");
        }
      }
    }
  }
  if (newSearchQuery.at(-1) && newSearchQuery.at(-1) != " ") {
    newSearchQuery = newSearchQuery + " ";
  }
  return newSearchQuery + selectedValue;
}