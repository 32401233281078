import { FC, useContext, useState, useEffect } from "react";
import { Button, Spin, Space, Dropdown, MenuProps } from "antd";
import { notification } from 'utility/notification';
import { useNavigate } from "react-router-dom";
import {
  MoreOutlined,
  ExportOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  ShareAltOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import * as FileSaver from "file-saver";

import {
  SpecStateType,
  DeveloperItem,
  SpecActionType,
  SpecType,
  ResourceType,
} from "types";
import {
  ActionSpecExecuteScope,
  TriggerSpecExecuteScope,
  ArtifactSpecExecuteScope,
  ActionProviderSpecExecuteScope,
  TriggerProviderSpecExecuteScope,
  ArtifactProviderSpecExecuteScope,
  ActionDeleteScope,
  TriggerDeleteScope,
  ArtifactDeleteScope,
  ActionProviderDeleteScope,
  TriggerProviderDeleteScope,
  ArtifactProviderDeleteScope,
  AppSpecExecuteScope,
} from "types/scopes";

import { useDeveloperStore, useFieldValidationStore } from "store";

import { validateSpec } from "utility/developer";

import { EditorContext } from "components/EntityEditor";

import Modal from "components/Modal";
import PublishModal from "./PublishModal";
import VersionCard from "components/VersionCard";

import styles from "./HeaderBar.module.scss";
import { ValidationType } from "utility/developer/validate";

export type HeaderBarProps = {
  specType: SpecType;
  di: DeveloperItem;
  onBack: () => void;
  onViewModeChange: (viewMode: boolean) => void;
};

export const HeaderBar: FC<HeaderBarProps> = ({
  specType,
  di,
  onBack,
  onViewModeChange,
}) => {
  const editorContext = useContext(EditorContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const errorTree = useFieldValidationStore((state) => state.tree);
  const errorCount = useFieldValidationStore((state) => state.countLeafNodes);
  const [hasValidationErrors, setHasValidationErrors] =
    useState<boolean>(false);

  useEffect(() => {
    const count = errorCount([di.type, di.id]);
    setHasValidationErrors(count > 0);
  }, [errorTree]);

  const [enablePublishModal, setEnablePublishModal] = useState<boolean>(false);
  const [enableUnpublishModal, setEnableUnpublishModal] =
    useState<boolean>(false);
  const [revertToPublishModal, setRevertToPublishModal] =
    useState<boolean>(false);
  const [deleteSpecModal, setDeleteSpecModal] = useState(false);
  const [enableShare, setEnableShare] = useState<boolean>(false);
  const [showVersionCard, setShowVersionCard] = useState<boolean>(false);
  const specTitle = di.item.displayName || di.item.name || di.id;
  const specTitlePath = di.type + "/" + specTitle;

  const doSpecAction = useDeveloperStore((state) => state.doSpecAction);
  const doSpecDelete = useDeveloperStore((state) => state.doSpecDelete);
  const doSpecVersionsFetch = useDeveloperStore((state) => state.doSpecVersionsFetch);

  const shareCurrentSpec = async () => {
    try {
      setLoader(true);
      setEnableShare(false);
      const newItem = await doSpecAction(
        specType,
        di.id,
        SpecActionType.ActionShare,
        null
      );
      notification.success({
        message: `${specType} ${specTitle} shared successfully`,
        duration: 6,
      });
      editorContext.onChange(newItem);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while sharing ${specType} ${specTitle}`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const publishCurrentSpec = async (comments: string) => {
    try {
      setLoader(true);
      setEnablePublishModal(false);
      const newItem = await doSpecAction(
        specType,
        di.id,
        SpecActionType.ActionPublish,
        { comments: comments }
      );
      notification.success({
        message: `${specType} ${specTitle} published successfully`,
        duration: 6,
      });
      editorContext.onChange(newItem);
      onViewModeChange(true);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while publishing ${specType} ${specTitle}`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const editCurrentSpec = async () => {
    try {
      setLoader(true);
      const newItem = await doSpecAction(
        specType,
        di.id,
        SpecActionType.ActionEdit,
        null
      );
      editorContext.onChange(newItem);
      onViewModeChange(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while unpublishing your ${specType}...!`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const unpublishCurrentSpec = async () => {
    try {
      setLoader(true);
      setEnableUnpublishModal(false);
      const newItem = await doSpecAction(
        specType,
        di.id,
        SpecActionType.ActionUnpublish,
        null
      );
      editorContext.onChange(newItem);
      onViewModeChange(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while unpublishing your ${specType}...!`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const revertToPublishCurrentSpec = async () => {
    try {
      setLoader(true);
      setRevertToPublishModal(false);
      const newItem = await doSpecAction(
        specType,
        di.id,
        SpecActionType.ActionRevert,
        null
      );
      editorContext.onChange(newItem);
      onViewModeChange(true);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while reverting your ${specType}...!`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const deleteCurrentSpec = async () => {
    try {
      setLoader(true);
      setDeleteSpecModal(false);
      await doSpecDelete(specType, di.id);
      onBack();
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Something went wrong while deleting your ${specType}...!`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const exportCurrentSpec = async () => {
    const blob = new Blob([JSON.stringify(di.item, null, 2)], {
      type: "application/json;charset=utf-8",
    });
    FileSaver.saveAs(blob, `hypredge-${di.type}-${specTitle}.json`);
  };

  const showSpecVersions = async () => {
    setShowVersionCard(true);
  };

  const onVersionCardFetchVersions = async (specId: string) => {
    const versions = await doSpecVersionsFetch(specType, specId);
    return  versions as any[];
  }

  const onVersionCardAction = async (specId: string, action: SpecActionType, version?: unknown) => {
    await doSpecAction(specType, specId, action, {version: version});
  }

  const onVersionCardActionCompleted = async (specId: string, action: SpecActionType) => {
    navigate(`/developer/${specType}/${specId}`);
  }


  const havePermission = (specActionType: SpecActionType) => {
    const specType = di.type;
    if (!specType) return false;
    const permissions = di.item?.accessInfo?.permissions as Array<string>;
    if (!permissions) return false;

    switch (specActionType) {
      case SpecActionType.ActionEdit:
      case SpecActionType.ActionPublish:
      case SpecActionType.ActionRevert:
      case SpecActionType.ActionShare:
      case SpecActionType.ActionUnpublish: {
        switch (specType) {
          case ResourceType.Action:
            return permissions.includes(ActionSpecExecuteScope);
          case ResourceType.Trigger:
            return permissions.includes(TriggerSpecExecuteScope);
          case ResourceType.Artifact:
            return permissions.includes(ArtifactSpecExecuteScope);
          case ResourceType.ActionProvider:
            return permissions.includes(ActionProviderSpecExecuteScope);
          case ResourceType.TriggerProvider:
            return permissions.includes(TriggerProviderSpecExecuteScope);
          case ResourceType.ArtifactProvider:
            return permissions.includes(ArtifactProviderSpecExecuteScope);
          case ResourceType.App:
            return permissions.includes(AppSpecExecuteScope);
          default:
            return false;
        }
      }
      case SpecActionType.ActionDelete: {
        switch (specType) {
          case ResourceType.Action:
            return permissions.includes(ActionDeleteScope);
          case ResourceType.Trigger:
            return permissions.includes(TriggerDeleteScope);
          case ResourceType.Artifact:
            return permissions.includes(ArtifactDeleteScope);
          case ResourceType.ActionProvider:
            return permissions.includes(ActionProviderDeleteScope);
          case ResourceType.TriggerProvider:
            return permissions.includes(TriggerProviderDeleteScope);
          case ResourceType.ArtifactProvider:
            return permissions.includes(ArtifactProviderDeleteScope);
          default:
            return false;
        }
      }
      default:
        return false;
    }
  };

  const getDraftModeItems = () => {
    let props: MenuProps["items"] = [];
    if (havePermission(SpecActionType.ActionDelete)) {
      props = [
        ...props,
        {
          key: "delete",
          label: <div style={{ color: "red" }}>Delete</div>,
          icon: <DeleteOutlined style={{ color: "red" }} />,
        },
      ];
    }
    props = [
      ...props,
      {
        key: "export",
        label: "Export",
        icon: <ExportOutlined />,
      },
    ];

    return props;
  };

  const handleDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "export" && exportCurrentSpec();
    e.key == "delete" && setDeleteSpecModal(true);
  };

  const getPublishedModeItems = () => {
    let props: MenuProps["items"] = [];

    if (havePermission(SpecActionType.ActionUnpublish)) {
      props = [
        ...props,
        {
          key: "unpublish",
          label: "Unpublish",
          icon: <VerticalAlignBottomOutlined />,
        },
      ];
    }
    if (havePermission(SpecActionType.ActionEdit)) {
      props = [
        ...props,
        {
          key: "edit",
          label: "Edit",
          icon: <EditOutlined />,
        },
      ];
    }
    props = [
      ...props,
      {
        key: "versions",
        label: "Versions",
        icon: <HistoryOutlined />,
      },
      {
        key: "export",
        label: "Export",
        icon: <ExportOutlined />,
      },
    ];
    return props;
  };

  const handlePublishedModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "unpublish" && setEnableUnpublishModal(true);
    e.key == "versions" && showSpecVersions();
    e.key == "edit" && editCurrentSpec();
    e.key == "export" && exportCurrentSpec();
  };

  const getPublishedDraftModeItems = () => {
    let props: MenuProps["items"] = [];
    if (havePermission(SpecActionType.ActionRevert)) {
      props = [
        ...props,
        {
          key: "revert",
          label: "Revert",
          icon: <VerticalAlignBottomOutlined />,
        },
      ];
    }
    props = [
      ...props,
      {
        key: "export",
        label: "Export",
        icon: <ExportOutlined />,
      },
    ];
    return props;
  };

  const handlepublishedDraftModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "revert" && setRevertToPublishModal(true);
    e.key == "export" && exportCurrentSpec();
  };

  const handleUnknownModeMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "export" && exportCurrentSpec();
  };
  const getUnkownModeItems = () => {
    const props: MenuProps["items"] = [
      {
        key: "export",
        label: "Export",
        icon: <ExportOutlined />,
      },
    ];
    return props;
  };

  const getMenuOptions = () => {
    if(!di || !di.id || !di.item.state){
      return {
          items: getUnkownModeItems(),
          onClick: handleUnknownModeMenuClick,
      };
    }
  
    switch (di.item.state) {
      case SpecStateType.StateDraft:
        return {
          items: getDraftModeItems(),
          onClick: handleDraftModeMenuClick,
        };
      case SpecStateType.StatePublished:
        return {
          items: getPublishedModeItems(),
          onClick: handlePublishedModeMenuClick,
        };
      case SpecStateType.StatePublishedDraft:
        return {
          items: getPublishedDraftModeItems(),
          onClick: handlepublishedDraftModeMenuClick,
        };
      default:
        return {};
    }
  };

  const ShowEditModeButtons: FC = () => {
    return (
      <Space size={8}>
        {havePermission(SpecActionType.ActionPublish) && (
          <Button
            className={styles.headerButton}
            type="primary"
            icon={<VerticalAlignTopOutlined />}
            disabled={hasValidationErrors || !validateSpec(di.type, di.item, ValidationType.Publish)}
            onClick={() => setEnablePublishModal(true)}
          >
            Publish
          </Button>
        )}
      </Space>
    );
  };

  const ShowNonEditModeButtons: FC = () => {
    return (
      <Space size={8}>
        {!di.item.shared && havePermission(SpecActionType.ActionShare) && (
          <Button
            className={styles.headerButton}
            type="primary"
            icon={<ShareAltOutlined />}
            onClick={() => shareCurrentSpec()}
          >
            Share
          </Button>
        )}
      </Space>
    );
  };

  return (
    <Spin spinning={loader}>
      <div className={styles.headerBar}>
        <Button
          className={styles.linkButton}
          icon={<RollbackOutlined />}
          onClick={() => {
            onBack();
          }}
          ghost
        />
        <div className={styles.specName}>
          <a>{specTitlePath}</a>
        </div>
        <Space size={5}>
          {di.item?.state != SpecStateType.StatePublished ? (
            <ShowEditModeButtons />
          ) : (
            <ShowNonEditModeButtons />
          )}
          <Dropdown placement="bottom" menu={getMenuOptions()}>
            <MoreOutlined className={styles.headerOptions} />
          </Dropdown>
        </Space>
      </div>
      { showVersionCard && di.id && 
        (<VersionCard 
            specId={di.id}
            specType={specType}
            editMode={true}
            onFetchVersions={onVersionCardFetchVersions}
            onAction={onVersionCardAction}
            onActionCompleted={onVersionCardActionCompleted}
            onClose={() =>  setShowVersionCard(false)}
          />
        )
      }
      {enablePublishModal && (
        <PublishModal
          specType={specType}
          open={enablePublishModal}
          onClose={() => setEnablePublishModal(false)}
          onSubmit={(values) => publishCurrentSpec(values.comments as string)}
          loader={loader}
        />
      )}
      {enableUnpublishModal && (
        <Modal
          title={`Unpublish ${specType}`}
          onClose={() => {
            setEnableUnpublishModal(false);
          }}
          open={enableUnpublishModal}
          onSubmit={unpublishCurrentSpec}
        >
          {`${specType} versions will not be accessible after unpublish, Are you sure to unpublish?`}
        </Modal>
      )}
      {revertToPublishModal && (
        <Modal
          title={`Revert To Published ${specType}`}
          onClose={() => {
            setRevertToPublishModal(false);
          }}
          open={revertToPublishModal}
          onSubmit={revertToPublishCurrentSpec}
        >
          {`Are you sure to revert to published ${specType}`}
        </Modal>
      )}
      {deleteSpecModal && (
        <Modal
          title={`Delete ${specType}`}
          onClose={() => {
            setDeleteSpecModal(false);
          }}
          loader={loader}
          open={deleteSpecModal}
          onSubmit={deleteCurrentSpec}
        >
          {`Are you sure you want to delete your ${specType}?`}
        </Modal>
      )}
    </Spin>
  );
};
