import { FC, useEffect, useState } from "react";
import { Modal as AntModal, theme, Button } from "antd";

import { Edge } from "types";
import EdgeInfos from "./edgeInfos";

export interface EdgeSelectionProps {
  width: number;
  open?: boolean;
  onClose: () => void;
  selectedEdge?: Edge;
  onSelect: (edge: Edge) => void;
}

const EdgeSelection: FC<EdgeSelectionProps> = ({
  width,
  open,
  onClose,
  selectedEdge,
  onSelect
}) => {
  const { token } = theme.useToken();
  const [currentEdge, setCurrentEdge] = useState<Edge|undefined>(selectedEdge);

  useEffect(() => {
    selectedEdge && setCurrentEdge(selectedEdge);
  }, [selectedEdge]);

  return (
    <AntModal
      title={"Select Edge"}
      onCancel={onClose}
      open={open}
      width={width}
      maskClosable={false}
      footer={[
        <Button 
          key="cancel" 
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button 
          key="select" 
          type="primary" 
          disabled={currentEdge == undefined} 
          onClick={() => {
            currentEdge && onSelect(currentEdge);
            onClose();
          }}
        >
          Select
        </Button>
      ]}
    >
      <EdgeInfos 
        editmode={true}
        selectedEdge={currentEdge}
        onSelect={(edge) => setCurrentEdge(edge)}
      />
    </AntModal>
  );
};

export default EdgeSelection;
