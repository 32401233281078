import { Space } from "antd";
import {
  EditorContext,
  ObjectField,
  RadioField,
  ViewProps,
} from "components/EntityEditor";
import { OAuthGrantType, ResourceType } from "types";

import React, { useContext, useEffect } from "react";

export const HttpOAuthView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const [oAuthGrantType, setOAuthGrantType] = React.useState<OAuthGrantType>(
    OAuthGrantType.OAuthGrantTypeClientCredentials
  );

  const onObjectChange = async (identifier: string, value: any) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onOAuthGrantTypeChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
    setOAuthGrantType(value as OAuthGrantType);
  };

  const getOAuthGrantTypeOptions = () => {
    return [
      {
        label: "Client Credentials",
        value: OAuthGrantType.OAuthGrantTypeClientCredentials,
      },
      // { label: "Authorization Code", value: OAuthGrantType.OAuthGrantTypeAuthorizationCode },
      // { label: "RefreshToken", value: OAuthGrantType.OAuthGrantTypeRefreshToken },
    ];
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <RadioField
        defaultSelected={oAuthGrantType}
        direction="horizontal"
        identifier={"grantType"}
        label={"Grant Type"}
        value={oAuthGrantType}
        path={[...props.path, "grantType"]}
        onChange={onOAuthGrantTypeChange}
        options={getOAuthGrantTypeOptions()}
      />
      {oAuthGrantType == OAuthGrantType.OAuthGrantTypeClientCredentials && (
        <ObjectField
          item={{
            id: "http-request-oauth-client-credentials",
            item: target["clientCredentialsConfig"],
            type: ResourceType.HttpOAuthClientCredentials,
          }}
          identifier={"clientCredentialsConfig"}
          label={"Client Credentials Configuration"}
          path={[...props.path, "clientCredentialsConfig"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
    </Space>
  );
};
