// Factory class to create instance of formdata , either from existing data, or new
import {
  Condition, Action, ActionProvider,ActionType,TriggerType,ArtifactProviderAdapterType, 
  App, Artifact, ArtifactFieldType,
  HTTPMethodType, HttpPollIncrementalTokenLocatorType,
  HttpPollIncrementTokenReaderType, HttpRequest,
  HttpRequestContentType, HttpRequestRetryLogic, HttpRequestType,HttpResponseContentType,
   Trigger, TriggerProvider, ArtifactProvider, AppEdgeDetector, AppEdgeDetectionHintType, ActionAccessType, ArtifactField,
  IODataMap,IODatas, ArtifactFields, ResourceType, AdapterType, Conditions
} from 'types';

import {generateRandomString} from "utility";

class ResourceFactory {
  createSpec = (resourceType: ResourceType) => {
    switch(resourceType){
      case ResourceType.Action:
        return this.createAction("", "");
      case ResourceType.Trigger:
        return this.createTrigger("", "");
      case ResourceType.Artifact:
        return this.createArtifact("", "");
      case ResourceType.ActionProvider:
        return this.createActionProvider();
      case ResourceType.TriggerProvider:
        return this.createTriggerProvider();
      case ResourceType.ArtifactProvider:
        return this.createArtifactProvider();
      case ResourceType.App:
        return this.createApp("", "");
      default:
        return {}
    }
  }
  createAction = (displayName: string, description: string) : Action=> {
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: description,
      type: ActionType.Custom,
      accessType: ActionAccessType.ActionAccessTypeReadOnly,
      parameters: {} as IODataMap,
      outputs: {} as IODatas,
    } as Action
  };
  createTrigger = (displayName: string, description: string) : Trigger=> {
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: description,  
      type: TriggerType.Custom,
      parameters: {} as IODataMap,
      outputs: {} as IODatas,
    } as Trigger
  };
  createArtifact = (displayName: string, description: string) : Artifact=> {
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: description,
      fields: {} as ArtifactFields,
    } as Artifact
  };
  createApp = (displayName: string, description: string) : App=> {
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: description,
      adapterTypes: [AdapterType.Http] as AdapterType[],
      edgeDetector: {
        detectionHint: AppEdgeDetectionHintType.AppEdgeDetectionHintDefault,
      } as AppEdgeDetector,
      tags: [] as string[],
      logoUrl: "",
    } as App
  };
  createActionProvider = () : ActionProvider=> {
    const displayName = `new action provider ${generateRandomString(3).toLowerCase()}`
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: "",
      comments: "",
      parameters: {} as IODataMap,
      outputs: {} ,
      http: {},
      appID: "",
      actionID: "",
      adapterType: AdapterType.Http,
    } as ActionProvider
  }
  createTriggerProvider = () : TriggerProvider=> {
    const displayName = `new trigger provider ${generateRandomString(3).toLowerCase()}`
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: "",
      comments: "",
      parameters: {} as IODataMap,
      outputs: {} ,
      http: {},
      webhook: {},
      appID: "",
      triggerID: "",
      adapterType: AdapterType.Webhook,
    } as TriggerProvider;
  }

  createArtifactProvider = () : ArtifactProvider=> {
    const displayName = `new artifact provider ${generateRandomString(3).toLowerCase()}`
    return {
      name: displayName.replaceAll(' ', '_'),
      displayName: displayName,
      description: "",
      comments: "",
      http: {},
      appID: "",
      artifactID: "",
      adapterType: ArtifactProviderAdapterType.http,
    } as ArtifactProvider
  }

  createProvider = (resourceType: ResourceType, ) : ActionProvider | TriggerProvider | ArtifactProvider => {
    switch (resourceType) {
      case ResourceType.ActionProvider:
        return this.createActionProvider();
      case ResourceType.TriggerProvider:
        return this.createTriggerProvider();
      case ResourceType.ArtifactProvider:
        return this.createArtifactProvider();
      default:
        throw new Error("Invalid spec type");
    }
  }

  createHttpRequest = () : HttpRequest => {
    return {
    path : '',
    method : HTTPMethodType.Get,
    contentType : HttpRequestContentType.Json,
    requestType : HttpRequestType.Sync,
    timeout : 10,
    headers : {},
    pathParams : {},
    queryParams : {},
    payload : {},
    successConditions :  [],
    retryPolicy : {retryCount: 0, retryDelaySeconds: 60,retryLogic: HttpRequestRetryLogic.Fixed},
    pollPolicy : { 
      pollConditions: [],
      pollIntervalInSeconds: 120,
      incrementalTokenReaderType: HttpPollIncrementTokenReaderType.Timestamp,
      queryParamsTokenLocator: {
        endTokenParams: [],
        startTokenParams: [],
      },
      headersTokenLocator: {
        startTokenHeaders: [],
        endTokenHeaders: [],
      },
      payloadTokenLocator: {
        startTokenPayloads: [],
        endTokenPayloads: [],
      },
      incrementalTokenLocatorType : HttpPollIncrementalTokenLocatorType.Headers,
      responseHeaderTokenReader: {
        headerName: "",
        endInitialValue: "",
        startInitialValue: ""
      }, 
      responsePayloadTokenReader: {
        initialValue: "",
        token:""
      },
      timestampTokenReader: {
        endInitialValue: "",
        startInitialValue: "",
        timeStampFormat: "",
      }
    },
    responseContentType :  HttpResponseContentType.Json
  }
  
  }

  
  createHttpRequestSuccessCondition = (httpRequestName: string) : Conditions => {
    return [{
      fact: `{{ $.http.${httpRequestName}.response.status }}`,
      operator: "matches",
      value: "^2[0-9][0-9]"
    } as Condition
  ]}

  createHttpRequestPollCondition = () : Conditions => {
    return [{} as Condition];
  }

  createArtifactField = () : ArtifactField => {
    return {
      description: "",
      type: ArtifactFieldType.String,
      lowPowerMode: false,
      required: true,
      filterable: true,
      isPii: false,
    } as ArtifactField;
  }
  
}

export const resourceFactory = new ResourceFactory();