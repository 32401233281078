import { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Space,
  theme,
  Typography,
  Switch,
  Card,
} from "antd";

import {
  AuthType,
  AuthTypeMap,
  SqlDBConfigProps,
} from "types";

import { FieldLabel } from "components/FieldLabel";

import { useAppSubscriptionStore } from "store";

import { showSecrets }  from "utility";
import { DatabaseType, DatabaseTypeMap } from "types/adapter/sql_adapter";
import { AppSqlDBConfiguration } from "types/app/sqldb_configuration";

const { Text } = Typography;

const SqlDBConfig: FC<SqlDBConfigProps> = ({
  form,
  edtiMode,
}) => {
  const { token } = theme.useToken();
  const [authType, setAuthType] = useState<AuthType>(AuthType.AuthTypeBasic);
  const [disableSsl, setDisableSsl] = useState<boolean>(false);

  const selectedAppSubscription = useAppSubscriptionStore((state) => state.selectedAppSubscription);
  const selectedApp = useAppSubscriptionStore((state) => state.selectedApp);

  useEffect(() => {
    if (selectedAppSubscription) {
      if (!selectedAppSubscription?.sqlDBConfiguration) {
        selectedAppSubscription.sqlDBConfiguration = {} as AppSqlDBConfiguration;
        selectedAppSubscription.sqlDBConfiguration.dbType = selectedApp?.sqlDBConfiguration.dbTypes?.at(0) as DatabaseType;
        selectedAppSubscription.sqlDBConfiguration.authType = selectedApp?.sqlDBConfiguration.authTypes?.at(0) as AuthType;
        selectedAppSubscription.sqlDBConfiguration.basic = selectedApp?.sqlDBConfiguration?.basic;
        selectedAppSubscription.sqlDBConfiguration.disableSsl = false;
      }

      form.setFieldsValue(selectedAppSubscription.sqlDBConfiguration);
     
      setAuthType(selectedAppSubscription.sqlDBConfiguration.authType);
      setDisableSsl(selectedAppSubscription?.sqlDBConfiguration?.disableSsl ? selectedAppSubscription.sqlDBConfiguration.disableSsl : false);
    }
  }, [selectedAppSubscription]);

  return (
    <Card
      style={{
        width: "100%",
        maxHeight: "400px",
        height: "auto",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <Form
          form={form}
          name="sqldbConfigForm"
          layout="vertical"
          autoComplete="off"
          disabled={!edtiMode}
        >
          <Form.Item 
            name="host" 
            label={<FieldLabel label={"Host"} help={"SQL DB Host in host:port format"} />}
            rules={[
              { required: true, message: 'Host is required' },
              { pattern: new RegExp(`(([0-9]+(.[0-9]+){3})|([0-9a-z-]+(.[0-9a-z-]+){0,2})):[0-9]+`), message: "Host field must be in host:port format." }
            ]}
          >
            <Input />
          </Form.Item>
          {selectedApp?.sqlDBConfiguration?.dbTypes?.length 
            &&
            <Form.Item 
              name="dbType" 
              label={<FieldLabel label={"Database Type"}/>}
              rules={[{ required: true, message: 'Database Type is required' }]}
            >
              <Select
                showAction={["focus", "click"]}
                options={selectedApp?.sqlDBConfiguration?.dbTypes?.map((dbType) => ({ 
                  label: DatabaseTypeMap.get(dbType), value: dbType 
                }))}
              />
            </Form.Item>
          }
          <Form.Item 
            name="dbName" 
            label={<FieldLabel label={"Database Name"}/>}
            rules={[
              { required: true, message: 'Database name is required' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="disableSsl"
            label={<FieldLabel label={"Disable SSL"}/>}
          >
            <Switch
              value={disableSsl}
              //checked={selectedApp?.sqlDBConfiguration?.disableSsl}
              onChange={setDisableSsl}
            />
          </Form.Item>
          {selectedApp?.sqlDBConfiguration?.authTypes?.length 
            &&
            <Form.Item 
              name="authType" 
              label={<FieldLabel label={"Auth Type"} help={"Type of Authentication"} />}
              rules={[{ required: true, message: 'Auth Type is required' }]}
            >
              <Select
                showAction={["focus", "click"]}
                onSelect={(value) => setAuthType(value)}
                options={selectedApp?.sqlDBConfiguration?.authTypes?.map((authType) => ({ label: AuthTypeMap.get(authType), value: authType }))}
              />
            </Form.Item>
          }
          {authType == AuthType.AuthTypeBasic &&
            <>
              <Form.Item
                name={['basic', 'username']}
                label={<FieldLabel label={"Username"} help={"Username"} />}
                rules={[{ required: true, message: 'Username is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['basic', 'password']}
                label={<FieldLabel label={"Password"} help={"Password"} />}
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password size="middle" visibilityToggle={showSecrets()} />
              </Form.Item>
            </>
          }
        </Form>
      </Space>
    </Card>
  );
};

export default SqlDBConfig;