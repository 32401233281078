import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/style.css"; // Ensure you import the default CSS
import { PhoneInputProps } from "react-phone-input-2";
import { theme } from "antd";

export const HyprEdgePhoneInput = (props: PhoneInputProps) => {
  const { token } = theme.useToken();
  const overideStyle = `.react-tel-input .flag-dropdown.open .selected-flag {
    background: ${token.colorBgContainer};
    border-radius: 3px 0 0 0;
  }
  .react-tel-input .selected-flag:hover {
    background: ${token.colorBgTextHover};
    border-radius: 3px 0 0 0;
  }

  .react-tel-input .selected-flag:focus {
    background: ${token.colorBgTextHover};
    border-radius: 3px 0 0 0;
  }
  .react-tel-input .country-list .search {
    position: sticky;
    top: 0;
    background-color: ${token.colorBgElevated};  
    padding: 10px 0 6px 10px;
  }  
  .react-tel-input .country-list .country.highlight {
      background-color: ${token.colorBgTextActive};
  }
  .react-tel-input .country-list .country:hover {
    background-color: ${token.colorBgTextHover};
    color: ${token.colorText};
  }
  .react-tel-input .country-list .country .dial-code {
    color: ${token.colorBgTextActive};
  }          
  `;

  return (
    <div className="">
      <style>{overideStyle}</style>
      <PhoneInput
        inputStyle={{
          backgroundColor: token.colorBgContainer, // Dynamic container background color
          color: token.colorText, // Dynamic text color
          borderColor: token.colorBorder, // Dynamic border color
          width: "100%",
        }}
        buttonStyle={{
          backgroundColor: token.colorBgContainer,
          borderColor: token.colorBorder,
        }}
        dropdownStyle={{
          backgroundColor: token.colorBgContainer,
          color: token.colorText,
        }}
        searchStyle={{
          backgroundColor: token.colorBgContainer, // Dark dropdown background
          borderColor: token.colorBorder, // Dark dropdown border
          color: token.colorText, // White text in dropdown
        }}
        {...props}
      />
    </div>
  );
};
