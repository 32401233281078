import { Badge, Tag, Typography } from 'antd';
import {
    FileImageOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    FileGifOutlined,
    FileJpgOutlined,
    FileMarkdownOutlined,
    FilePptOutlined,
    FileTextOutlined,
    FileWordOutlined,
    FileZipOutlined,
    FlagOutlined
  } from '@ant-design/icons';

import { BaseType } from 'antd/es/typography/Base';
import { CasePriority, CasePriorityMap, CaseSeverity, CaseSeverityMap, CaseSLAStatus, CaseSLAStatusMap, CaseStatus, CaseStatusMap } from 'types';

const { Text } = Typography;

export const getCaseStatus= (caseStatus: CaseStatus) => {
  switch (caseStatus) {
    case CaseStatus.New:
      return <Badge status="error" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
    case CaseStatus.InProgress:
      return <Badge status="processing" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
    case CaseStatus.OnHold:
      return <Badge status="warning" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
    case CaseStatus.Resolved:
      return <Badge status="success" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
    case CaseStatus.Closed:
      return <Badge status="default" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
  }
}
export const getCaseSLAStatus= (caseSLAStatus: CaseSLAStatus) => {
  switch (caseSLAStatus) {
    case CaseSLAStatus.Breached:
      return <Badge status="error" text={<Text>{CaseSLAStatusMap.get(caseSLAStatus)}</Text>} />;
    case CaseSLAStatus.OnTrack:
      return <Badge status="processing" text={<Text>{CaseSLAStatusMap.get(caseSLAStatus)}</Text>} />;
    case CaseSLAStatus.AtRisk:
      return <Badge status="warning" text={<Text>{CaseSLAStatusMap.get(caseSLAStatus)}</Text>} />;
    case CaseSLAStatus.OnTime:
      return <Badge status="success" text={<Text>{CaseSLAStatusMap.get(caseSLAStatus)}</Text>} />;
    case CaseSLAStatus.NoSLA:
      return <Badge status="default" text={<Text>{CaseSLAStatusMap.get(caseSLAStatus)}</Text>} />;
  }
}

export const getSLADueAtColor= (slaStatus: string) => {
  switch (slaStatus) {
    case CaseSLAStatus.Breached:
      return "error";
    case  CaseSLAStatus.AtRisk:
      return  "warning";
    case  CaseSLAStatus.OnTrack:
      return "success";
    case  CaseSLAStatus.OnTime:
      return  "default";
  }
}

export const getCaseSeverity= (caseSeverity: CaseSeverity) => {
  let color = ""
  switch (caseSeverity) {
    case CaseSeverity.Informational:
      color = "#0aab3d";
      break;
    case CaseSeverity.Low:
      color = "#2F9CCA";
      break;
    case CaseSeverity.Medium:
      color = "#CFAD0C";
      break;
    case CaseSeverity.High:
      color = "#f2885e";
      break;
    case CaseSeverity.Critical:
      color = "#d60d0d";
      break;
  }
  return  <Tag icon={<FlagOutlined />} color={color}>
            {CaseSeverityMap.get(caseSeverity)}
          </Tag>
}

export const getCasePriority= (casePriority: CasePriority) => {
  let type: BaseType | undefined = undefined
  switch (casePriority) {
    case CasePriority.P1:
      type = "danger";
      break;
    case CasePriority.P2:
      type = "danger";
      break;
    case CasePriority.P3:
      type = "warning";
      break;
    case CasePriority.P4:
      type = "secondary";
      break;
    case CasePriority.P5:
      type = "secondary";
      break;
  }
  return <Text type={type}>{CasePriorityMap.get(casePriority)}</Text>;
}

export const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case "image/svg+xml":
      case "image/png":
        return <FileImageOutlined />
      case "image/gif":
        return <FileGifOutlined />
      case "image/jpeg":
        return <FileJpgOutlined />
      case "application/pdf":
       return <FilePdfOutlined />
      case "application/vnd.ms-excel":
        return <FileExcelOutlined />
      case "text/markdown":
        return <FileMarkdownOutlined />
      case "application/vnd.ms-powerpoint":
        return <FilePptOutlined />
      case "text/plain":
        return <FileTextOutlined />
      case "application/msword":
        return <FileWordOutlined />
      case "application/gzip":
        return <FileZipOutlined />
      default:
        return <FileOutlined />
    }
  }
