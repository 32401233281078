import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { dashboardsApiPath } from "./constant";

export interface WidgetDto {
  id: string;
  type: string;
  unit: string;
  title: string;
  description: string;
  search: string;
  searchIntervalSeconds: number;
  color: string
  position : number;
}


export interface WidgetListDto {
  widgets: WidgetDto[];
}

export interface DashboardDto {
  id: string;
  name: string;
  description: string;
  widgets: WidgetDto[];
  color: string;
}

export interface DashboardListDto {
  dashboards: DashboardDto[];
}

export type WidgetCreateRequestDto = Omit<WidgetDto, "id">;
export type WidgetUpdateRequestDto = Partial<WidgetCreateRequestDto>;


export const listDashboardsApi = async (): Promise<AxiosResponse<DashboardListDto>> => axiosInterceptor(
  "get", 
  dashboardsApiPath);

export const getDashboardApi = async (id: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor(
  "get", 
  `${dashboardsApiPath}/${id}`);

export const createDashboardApi = async (name: string, description: string, color: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor(
  "post", 
  dashboardsApiPath, 
  { name, description, color });

export const updateDashboardWidgetPositionsApi = async (id: string, layout: {[k:string] : number}): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor(
  "patch", 
  `${dashboardsApiPath}/${id}/widgets/positions`, 
  layout );

export const updateDashboardPropertiesApi = async (id: string, name: string, description: string, color: string): Promise<AxiosResponse<DashboardDto>> => axiosInterceptor(
  "patch", 
  `${dashboardsApiPath}/${id}`, 
  { name, description, color });

export const addWidgetApi = async (id: string, widget: WidgetCreateRequestDto): Promise<AxiosResponse<WidgetDto>> => axiosInterceptor(
  "post", 
  `${dashboardsApiPath}/${id}/widgets`, 
  widget );

export const updateWidgetApi = async (dashboardId: string, widgetId: string, widget: WidgetUpdateRequestDto): Promise<AxiosResponse<WidgetDto>> => axiosInterceptor(
  "patch", 
  `${dashboardsApiPath}/${dashboardId}/widgets/${widgetId}`, 
  widget );

export const deleteWidgetApi = async (dashboardId: string, widgetId: string): Promise<AxiosResponse<void>> => axiosInterceptor(
  "delete",
   `${dashboardsApiPath}/${dashboardId}/widgets/${widgetId}`);

export const deleteDashboardApi = async (dashboardId: string): Promise<AxiosResponse<void>> => axiosInterceptor(
  "delete", 
  `${dashboardsApiPath}/${dashboardId}`);

export const transferWidgetApi = async (dashboardId: string,  targetDashboardId: string, widgetId: string): Promise<AxiosResponse<void>> => axiosInterceptor(
  "patch",
  `${dashboardsApiPath}/${dashboardId}/widgets/${widgetId}/${targetDashboardId}`);

