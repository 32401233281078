import { ArgsProps, NotificationInstance } from 'antd/es/notification/interface';

export interface NotificationDetail {
    type: 'success' | 'error' | 'warning' | 'info';
    args: ArgsProps;
}

function getEvent(type: NotificationDetail['type'], args: ArgsProps) {
    return new CustomEvent<NotificationDetail>('notificationEvent', {
        detail: {
            type,
            args,
        },
    });
}

export const notification: Omit<NotificationInstance, 'open' | 'destroy'> = {
    info: (args: ArgsProps) => {
        document.dispatchEvent(getEvent('info', args));
    },
    error: (args: ArgsProps) => {
        document.dispatchEvent(getEvent('error', args));
    },
    success: (args: ArgsProps) => {
        document.dispatchEvent(getEvent('success', args));
    },
    warning: (args: ArgsProps) => {
        document.dispatchEvent(getEvent('warning', args));
    },
};