import { SpecStateType, AccessInfo, TextType, ConditionOpType, LogicalConditionOpType} from "types";

export enum ArtifactFieldType {
    String = "string",
    Numeric = "numeric",
    Boolean = "boolean",
    TimeStamp = "timestamp",
    Duration = "duration",
    IP = "ip",
    Array = "array",
    Map = "map",
    Artifact = "artifact"
}
export interface ArtifactField {
    description: string;
    displayName: string;
    type: ArtifactFieldType;
    lowPowerMode: boolean;
    required: true;
    filterable: boolean;
    isFilterableOnly: boolean;
    isPii: boolean;
    textType?: TextType;
    order: number;
    values: any[];
    isMandatory: boolean;
    complementaryMandatoryFields?: string[];
    isNotRepeatable: boolean;
    nestedFilterableFields?: string[];
    operators: ConditionOpType[];
}
export type ArtifactFields = Record<string, ArtifactField>;

export interface ConflictAction {
    id: string;
    name: string;
}

export type ArtifactActionParameters  = Record<string, string>

export interface  ArtifactAction  {
    id: string,
    parameters: ArtifactActionParameters
}

export type ArtifactActions = ArtifactAction[];

export type ArtifactOperators  = ConditionOpType[]
export type ArtifactLogicalOperators = LogicalConditionOpType[]

export interface Artifact {
    id: string
    comments: string;
    state: SpecStateType;
    shared: boolean;
    private: boolean;
    name: string;
    displayName: string;
    description: string;
    fields: ArtifactFields;
    actions: ArtifactActions;
    operators:  ArtifactOperators;
    logicalOperators: ArtifactLogicalOperators;
    accessInfo: AccessInfo;
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;
    tags: string[];
}

export type ArtifactUploadObjectType = Omit<Artifact,  'state' | 'shared' |'private'| 'accessInfos' | 'updatedAt' | 'createdAt' | 'userID'>;
