/** Given a list of user ids, creates and render an avatart group */

import React, { FC, useEffect, useState } from "react";
import { Avatar, Popover, Typography, theme } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDeveloperStore, useRbacStore, useUserInfoStore } from "store";
import { AvatarSize } from "antd/es/avatar/AvatarContext";
import { useMediaQuery } from "react-responsive";
import {BigScreenQuery} from "types";

interface UserDisplayProps {
  displayName: string;
  userId: string;
  imageUri: string;
}

export interface UserAvatarGroupProps {
  userIds: string[];
  size?: AvatarSize;
}


export const UserAvatarGroup: FC<UserAvatarGroupProps> = ({
  userIds,
  size,
}) => {
  const { token } = theme.useToken();
  const { users } = useRbacStore((state) => ({
    users: state.users,
  }));
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const getDownloadUrl = useDeveloperStore((state) => state.getDownloadUrl);

  const [userDisplayProps, setUserDisplayProps] = useState<UserDisplayProps[]>(
    []
  );

  useEffect(() => {
    const asyncUseEffect = async () => {
      const userDisplayProps = [] as UserDisplayProps[];
      for (const userId of userIds) {
        const user = users[userId];
        if (user) {
          let imageUrl = `https://ui-avatars.com/api/?name=${user.firstName}+${user.lastName}`;
          if (user.image) {
            const content = await getDownloadUrl(user.image);
            imageUrl = content.url;
          }
          userDisplayProps.push({
            displayName: `${user.firstName} ${user.lastName}`,
            userId: user.id,
            imageUri: imageUrl,
          });
        }
      }
      setUserDisplayProps(userDisplayProps);
    };
    asyncUseEffect();
  }, [userIds]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: token.marginXS,
      }}
    >
      <Avatar.Group maxCount={isBigScreen? 5 :3}>
        {userDisplayProps.map((user) => (
          <Popover
            key={user.userId}
            content={
              <Typography>
                <Typography.Text style={{ marginBottom: 0 }}>
                  {user.displayName}
                </Typography.Text>
              </Typography>
            }
          >
            <Avatar size={size ? size : "small"} src={user.imageUri} icon={<UserOutlined />} />
          </Popover>
        ))}
      </Avatar.Group>
    </div>
  );
};
