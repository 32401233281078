import { FC, useEffect } from "react";

import {
  Form,
  FormInstance,
  Select,
  Input,
} from "antd";

import {
  IODataType,
  OperatorKVItem,
} from "types";

import { FieldLabel } from "components/FieldLabel";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";

const { Option } = Select;

export interface KVItemProps {
  editMode: boolean;
  item: OperatorKVItem;
  suggestionsTree: [];
  onChange?: (value: OperatorKVItem) => void;
  onRender?: (form: FormInstance) => void;
}

const KVItem: FC<KVItemProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  return (
    <Form
      form={form}
      name="kvItemForm"
      layout="vertical"
      autoComplete="off"
      initialValues={props.item}
      onValuesChange={(changedValues: any, _: any) => {
        const values = form.getFieldsValue(true);
        props.onChange?.(values);
      }}
    >
      <Form.Item
        name="key"
        label={<FieldLabel label={"Key"} help={"KV Key"} />}
        normalize={(value) => value.trim()}
        rules={[
          { required: true, message: "Key Name is required!" },
          {
            validator: (_, value) =>
              value && !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error("No spaces allowed")),
          },
        ]}
      >
        <Input disabled={!props.editMode} />
      </Form.Item>
      <Form.Item
        name="type"
        label={<FieldLabel label={"Type"} help={"KV Value Type"} />}
        rules={[{ required: true, message: "Type is required!" }]}
      >
        <Select disabled={!props.editMode} showAction={["focus", "click"]} defaultValue={IODataType.String}>
          <Option key={IODataType.String} value={IODataType.String}>
            String
          </Option>
          <Option key={IODataType.Numeric} value={IODataType.Numeric}>
            Number
          </Option>
          <Option key={IODataType.Boolean} value={IODataType.Boolean}>
            Boolean
          </Option>
          <Option key={IODataType.Array} value={IODataType.Array}>
            List
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="value"
        label={<FieldLabel label={"Value"} help={"KV Value"} />}
        rules={[
          { required: true, message: "Value is required!" },
          {
            validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
          }
        ]}
      >
        <SuggestionsInput
            editMode={props.editMode}
            name={"value"}
            suggestionsTree={props.suggestionsTree}
        />
      </Form.Item>
    </Form>
  );
};

export default KVItem;
