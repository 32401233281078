import { Space, message, Spin} from "antd";

import {
  CollectionField,
  CollectionMutatorPosition,
  EditorContext,
  ImageField,
  ObjectBackedTextField,
  ObjectField,
  PrimitiveArrayField,
  TextField,
  TextFieldType,
  ViewerType,
  ViewProps,
  rules,
} from "components/EntityEditor";
import React, { useContext, useEffect } from "react";
import { resourceFactory } from "utility/resource";
import { useDeveloperStore } from "store";
import {
  AppConfiguration,
  DeveloperItem,
  ResourceType,
  SpecType,
  INVALID_DEVELOPER_ITEM
} from "types";
import { v4 as uuidv4 } from "uuid";
import { ViewSchema } from "./viewSchema";

/** Function component with app view props as argument */
export const AppView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  
  const doSpecsFetch = useDeveloperStore((state) => state.doSpecsFetch);
  const doSpecCreate = useDeveloperStore((state) => state.doSpecCreate);
  
  
  const onTextChange = async (key: string, value: string) => {
    setTarget({ ...target, [key]: value });
  };

  const onDisplayNameChange = async (identifier: string, value: string) => {
    const name = value.replaceAll(" ", "_").toLowerCase();
    setTarget({ ...target, [identifier]: value, [schema.name.identifier]: name });
  };

  const onImageChange = async (key: string, value: string) => {
    console.log("On image change", key, value)
    setTarget({ ...target, [key]: value });
  };

  const onObjectChange = async (key: string, value: any) => {
    setTarget({ ...target, [key]: value });
  };


  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  
  
  /** On text field add*/
  const onArrayTextFieldAdd = async (key: string): Promise<DeveloperItem> => {
    const item = target[key];
    const index = item?.length;
    const newItem = `New Item ${index}`;
    item.push(newItem);

    setTarget({ ...target, [key]: item });
    return {
      id: `app-${key}-${index}`,
      type: ResourceType.Primitive,
      item: { [0]: newItem },
    };
  };

  const onArrayTextFieldChange = async (
    key: string,
    value: DeveloperItem[]
  ) => {
    setTarget({ ...target, [key]: value.map((v) => v.item[0]) });
  };

  
  const schema: { [k: string]: ViewSchema } = {
    name: {
      identifier: "name",
      label: "Name",
      help: "A name for the app",
      validators: [],
    },
    displayName: {
      identifier: "displayName",
      label: "Name",
      help: "A display name for the app",
      validators: [rules.required, rules.minLength(1), rules.maxLength(50)],
    },
    description: {
      identifier: "description",
      label: "Description",
      help: "The description of the app",
      validators: [rules.required, rules.minLength(1), rules.maxLength(50)],
    },
    logoUrl: {
      identifier: "logoUrl",
      label: "Logo Image",
      help: "Image for the logo, MUST be a png or jpg",
      validators: [],
    },
    license: {
      identifier: "license",
      label: "License",
      help: "License information for the app",
      validators: [rules.required, rules.minLength(1), rules.maxLength(50)],
    },
    configurations: {
      identifier: "configurations",
      label: "Configurations",
      help: "Configuration which will be asked by the user while configuring an app",
      validators: [],
    },
    webhookConfiguration: {
      identifier: "webhookConfiguration",
      label: "Webhook Configuration",
      help: "Webhook Configuration required by user",
      validators: [],
    },
    edgeDetector: {
      identifier: "edgeDetector",
      label: "Edge Detection Technique",
      help: "Method used to detect  edge for the app",
      validators: [],
    },
    tags: {
      identifier: "tags",
      label: "Tags",
      help: "Tags to be associated with this app",
      validators: [],
    },
    details: {
      identifier: "details",
      label: "Details",
      help: "Additional details for the app",
      validators: [],
    },
    docs: {
      identifier: "docs",
      label: "Documentation",
      help: "Documentation for the app",
      validators: [],
    },
    media: {
      identifier: "media",
      label: "Media",
      help: "Informational and promotional media for the app",
      validators: [],
    },
    providers: {
      identifier: "providers",
      label: "Providers",
      help: "Providers associated with the app",
      validators: [],
    },
  };

  return (
    <Spin spinning={loader}>
      <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
        <TextField
          identifier={schema.displayName.identifier}
          label={schema.displayName.label}
          value={target[schema.displayName.identifier]}
          path={[...props.path, schema.displayName.identifier]}
          validators={schema.displayName.validators}
          help={schema.displayName.help}
          onChange={onDisplayNameChange}
        />
        <TextField
          identifier={schema.description.identifier}
          label={schema.description.label}
          value={target[schema.description.identifier]}
          path={[...props.path, schema.description.identifier]}
          validators={schema.description.validators}
          help={schema.description.help}
          onChange={onTextChange}
        />

        <ImageField
          identifier={schema.logoUrl.identifier}
          label={schema.logoUrl.label}
          max={1}
          onChange={onImageChange}
          value={target[schema.logoUrl.identifier]}
          path={[...props.path, schema.logoUrl.identifier]}
          help={schema.logoUrl.help}
        />

        {/* <CollectionField
          identifier={schema.configurations.identifier}
          label={schema.configurations.label}
          help={schema.configurations.help}
          validators={schema.configurations.validators}
          path={[...props.path, schema.configurations.identifier]}
          value={target[schema.configurations.identifier]?.map(
            (configuration: AppConfiguration, index: number) => {
              return {
                id: `app-configuration-${index}`,
                item: configuration,
                type: ResourceType.AppConfiguration,
              };
            }
          )}
          columns={[
            { title: "Name", dataIndex: ["item", "name"] },
            { title: "Description", dataIndex: ["item", "description"] },
            { title: "Type", dataIndex: ["item", "type"] },
          ]}
          onChange={onConfigurationChange}
          onAdd={onConfigurationItemAdd}
          viewerType={ViewerType.CardViewer}
          mutatorPosition={CollectionMutatorPosition.InBetween}
          allowedTypes={[ResourceType.AppConfiguration]}
          loading={loader}
          showAdd={true}
          showDelete={true}
          showExpanded={false}
          showTableHeader={true}
          showCount={true}
          optional={true}
        />

        <ObjectField
          identifier={schema.webhookConfiguration.identifier}
          label={schema.webhookConfiguration.label}
          path={[...props.path, schema.webhookConfiguration.identifier]}
          validators={schema.webhookConfiguration.validators}
          help={schema.webhookConfiguration.help}
          item={{
            id: "app-webhook-configuration",
            item: target[schema.webhookConfiguration.identifier],
            type: ResourceType.AppWebhookConfiguration,
            context: {
              configurations: target[schema.configurations.identifier],
            },
          }}
          onChange={onObjectChange}
          showExpanded={false}
          optional={true}
        />

        <ObjectField
          identifier={schema.edgeDetector.identifier}
          label={schema.edgeDetector.label}
          path={[...props.path, schema.edgeDetector.identifier]}
          validators={schema.edgeDetector.validators}
          help={schema.edgeDetector.help}
          item={{
            id: "app-edge-detector",
            item: target[schema.edgeDetector.identifier],
            type: ResourceType.AppEdgeDetector,
            context: {
              configurations: target[schema.configurations.identifier],
            },
          }}
          onChange={onObjectChange}
          showExpanded={false}
        /> */}

        
        <PrimitiveArrayField
          identifier={schema.tags.identifier}
          label={schema.tags.label}
          path={[...props.path, schema.tags.identifier]}
          validators={schema.tags.validators}
          help={schema.tags.help}
          value={target[schema.tags.identifier]?.map(
            (tag: string, index: number): DeveloperItem => {
              return {
                id: `app-tag-${index}`,
                item: { [0]: tag },
                type: ResourceType.Primitive,
              };
            }
          )}
          addLabel={"Add Tag"}
          showExpanded={false}
          onAdd={onArrayTextFieldAdd}
          onChange={onArrayTextFieldChange}
          optional={true}
        />
      </Space>
    </Spin>
  );
};
