import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Select, Space, Typography } from "antd";
import { debounce } from "lodash";
import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { useFieldValidationStore } from "store";
import { FieldValidationRule } from "types";
import { FieldProps } from "./common";
import { EditorContext } from "./editorContext";
import { FieldLabel } from "./fieldLabel";
import { rules } from "./rules";

const { Text } = Typography;

export const enum TextFieldType {
  text = "text",
  textArea = "textArea",
}

/**
 * Cannot use text area with options
 */
interface TextFieldProps extends FieldProps {
  value: string;
  type?: TextFieldType;
  password?: boolean;
  readOnly?: boolean;
  options?: { label: string; value: string }[];
  onChange: (key: string, value: string) => Promise<void>;
}

/** react function component */
export const TextField = (props: PropsWithChildren<TextFieldProps>) => {
  const mounted = React.useRef(false);
  const [field, setField] = React.useState(props.value);
  const insertError = useFieldValidationStore((state) => state.insertLeafNode);
  const editorContext = React.useContext(EditorContext);

  useEffect(() => {
    mounted.current
      ? validateAndPropagate(props.identifier, field)
      : (mounted.current = true);
  }, [field]);

  const validate = (key: string, value: string) => {
    const errors: string[] = [];
    const matches: FieldValidationRule[] = [];

    if (!props.optional && !value) {
      errors.push(`${key} is required field and can not be left blank`);
      matches.push(rules.required);
    }

    props.validators?.forEach((rule) => {
      const valid = rule.validator(value);
      if (!valid) {
        errors.push(rule.message);
        matches.push(rule);
      }
    });
    return { errors, matches };
  };

  const validateAndPropagate = (key: string, value: string) => {
    const { errors, matches } = validate(key, value);
    setErrors(errors);
    insertError(props.path, matches);

    if (errors.length > 0) {
      return;
    }
    props.onChange(key, value);
  };

  const debouncedSetField = useMemo(() => {
    return debounce(setField, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSetField.cancel();
    };
  });

  /** On initial load, we want to validate, so that upstream knows there is errorm but we dont want to propagate */
  useEffect(() => {
    const { errors, matches } = validate(props.identifier, field);
    setErrors(errors);
    insertError(props.path, matches);
    /** Clear out error during unload */
    return () => {
      insertError(props.path, []);
    };
  }, [props.value]);

  const [errors, setErrors] = React.useState<string[]>([]);

  return (
    <Space
      direction="vertical"
      size={"small"}
      style={{ display: "flex", width: "100%" }}
    >
      {/* label */}
      <FieldLabel fp={props} />
      <Input.Group compact>
        {props.type === TextFieldType.textArea ? (
          <Input.TextArea
            defaultValue={props.value}
            onChange={(e) => {
              debouncedSetField(e.target.value);
            }}
            maxLength={100}
            disabled={editorContext.view}
          />
        ) : (
          <>
            {props.options && props.options.length > 0 ? (
              /** Ant design dropdown using the props option */
              <Select
                defaultValue={props.value}
                options={props.options}
                onChange={(value: string) => {
                  debouncedSetField(value);
                }}
                disabled={editorContext.view}
                style={{
                  width: "100%",
                }}
              />
            ) : (
              <>
                {props.password ? (
                  <Input.Password
                    placeholder="input password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    defaultValue={props.value}
                    onChange={(e) => {
                      debouncedSetField(e.target.value);
                    }}
                    maxLength={500}
                    disabled={editorContext.view}
                  />
                ) : (
                  <Input
                    defaultValue={props.value}
                    onChange={(e) => {
                      if(props.readOnly && props.readOnly == true) {
                        return;
                      }
                      debouncedSetField(e.target.value);
                    }}
                    maxLength={500}
                    disabled={(props.readOnly && props.readOnly == true) ? true: editorContext.view}
                  />
                )}
              </>
            )}
          </>
        )}
      </Input.Group>
      {props.showError &&
        errors.map((error, index) => (
          <Text
            key={`${props.identifier}-error-${index}`}
            type="danger"
            style={{ fontSize: "0.8rem" }}
          >
            {error}
          </Text>
        ))}
    </Space>
  );
};
