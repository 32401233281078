import { PropsWithChildren } from "react";
import { theme, ConfigProvider } from "antd";

export const ThemeManager = (props: PropsWithChildren) => {
  const { token } = theme.useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: token.colorPrimary,
          colorLinkHover: token.colorPrimaryTextHover,
          colorLinkActive: token.colorPrimaryActive,
        },
        components: {
          Menu: {
            itemSelectedBg: token.colorPrimary,
            itemSelectedColor: token.colorTextLightSolid,
          },
          Modal: {
            wireframe: true
          }
        },
      }}
    >
      {props.children}
    </ConfigProvider>
  );
};
