import type { FC } from "react";
import { useRef,  useEffect, memo} from "react";
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'

import {v4 as uuidv4} from 'uuid';
import {  DraggableItem, WorkflowNodeType, DraggableSourceType} from "types";

import SidebarItem from "./item";

export interface SidebarDraggableItemProps {
  resourceId: string,
  resourceType: string,
  description: string
  displayName: string
  nodeType: WorkflowNodeType
  disabled: boolean
}

const SidebarDraggableItem:FC <SidebarDraggableItemProps> = ({
  resourceId,
  resourceType,
  description,
  displayName,
  nodeType,
  disabled,
}) => {
  const draggableId = useRef(uuidv4());
  const item: DraggableItem = {
    id: draggableId.current,
    nodeType:nodeType,
    resourceId: resourceId,
    resourceType: resourceType,
    source:  DraggableSourceType.Sidebar,
  }
  const [collected, dragRef, dragPreview] = useDrag(() => ({
    type: DraggableSourceType.Sidebar,
    item: item,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: 'move',
    },
  }));

  const commonStyle: React.CSSProperties = {
    opacity: collected.isDragging || disabled ? 0.6 : 1,
  };

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div ref={dragRef}  style={commonStyle} >
      <SidebarItem 
        description={description} 
        displayName={displayName} 
        nodeType={nodeType} 
        resourceType={resourceType} 
        disabled={disabled}
        isDragging={collected.isDragging}
      />
    </div>
  )
};

export default memo(SidebarDraggableItem);
