import { Button,  Popover, Typography, theme } from "antd";
import { StepForwardOutlined } from "@ant-design/icons";
import {  useState } from "react";
import ReactPlayer from 'react-player'
import { SizeType } from "antd/es/config-provider/SizeContext";
const {  Text } = Typography;

export interface VideoPlayerProps {
  videoUrl: string;
  thumbnailUrl: string;
  playIconSize?: SizeType;
  pipMode?: boolean;
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const { token } = theme.useToken();
  const [isPlaying, setPlaying] = useState(false);
  const [pip, setPip] = useState(false);
  return (
    <ReactPlayer 
        url={props.videoUrl}
        light={<img 
            style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} 
            src={props.thumbnailUrl} 
            alt='Thumbnail'
            onClick={() =>  setPlaying(true)}
        />}
        width='100%'
        height='100%'
        playIcon={  
            <Button type="primary" size={props.playIconSize ? props.playIconSize : "large"} shape="circle"  onClick={ () =>  setPlaying(true)} icon={<StepForwardOutlined />}  />
        }
        controls
        style={{
            position: "absolute", 
            top: 0, 
            left: 0
        }}
        onStart={() => props.pipMode && setPip(true)}
        pip={pip}
        playing={isPlaying} 
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        muted={false}
    />
  );
};