import { Divider, Form, Input, Spin, Tabs } from "antd";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useRbacStore } from "store";
import { RoleDto } from "types";
import { Assign, HasIdentity } from "components/Assign";
import { notification } from "utility/notification";

const { TabPane } = Tabs;

const formFields = [
  {
    name: "name",
    label: "Role Name",
    rules: [{ required: true, message: "Please input name!" }],
  },
  {
    name: "description",
    label: "Description",
    rules: [{ required: true, message: "Please input description!" }],
  },
];

export interface RoleEditProps {
  readOnly: boolean;
  role?: RoleDto;
  isAdd: boolean;
  onClose: () => Promise<void>;
  onUserAdd: () => Promise<void>;
  onPermissionSetAdd: () => Promise<void>;
}

export const RoleEdit = (props: RoleEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedPsets, setSelectedPsets] = useState<string[]>([]);
  const [drawerState, setDrawerState] = useState(false);
  const users = useRbacStore((state) => state.users);
  const permissionSets = useRbacStore((state) => state.permissionSets);

  const [psetDataSource, setPsetDataSource] = useState<HasIdentity[]>([]);
  const [userDataSource, setUserDataSource] = useState<HasIdentity[]>([]);

  useEffect(() => {
    setDrawerState(true);
    try {
      setLoading(true);
      if (props.role) {
        const fields = {
          name: props.role.name,
          description: props.role.description,
        };
        form.setFieldsValue(fields);
      }
      const p = props.role?.psets.map((x) => x.id) || [];
      const u = props.role?.users.map((x) => x.id) || [];
      setSelectedPsets(p);
      setSelectedUsers(u);
      setPsetDataSource(
        Object.values(permissionSets).map((x) => ({
          id: x.id,
          displayName: x.name,
          description: x.description,
          selected: p.includes(x.id),
          assignable: true,
        }))
      );
      setUserDataSource(
        Object.values(users).map((x) => ({
          id: x.id,
          displayName: `${x.firstName} ${x.lastName}`,
          description: "An user",
          selected: u.includes(x.id),
          assignable: true,
        }))
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, [props.role, users, permissionSets]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      if (props.isAdd) {
        const { name, description } = values;
        await createRole({
          name,
          description,
          psets: selectedPsets,
          users: selectedUsers,
        });
      } else if (props.role) {
        const { name, description } = values;
        const addPsets = selectedPsets.filter(
          (x) => !props.role?.psets.map((y) => y.id).includes(x)
        );
        const removePsets = props.role?.psets
          .filter((x) => !selectedPsets.includes(x.id))
          .map((x) => x.id);
        const addUsers = selectedUsers.filter(
          (x) => !props.role?.users.map((y) => y.id).includes(x)
        );
        const removeUsers = props.role?.users
          .filter((x) => !selectedUsers.includes(x.id))
          .map((x) => x.id);
        await updateRole({
          id: props.role.id,
          name,
          description,
          addPsets,
          removePsets,
          addUsers,
          removeUsers,
        });
      }
      notification.success({
        message: `Successfully ${props.isAdd ? "created" : "updated"} role`,
      });
    } catch (error) {
      notification.error({
        message: `Failed to ${props.isAdd ? "create" : "update"} role`,
        duration: 6,
      });
    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const createRole = useRbacStore((state) => state.createRole);
  const updateRole = useRbacStore((state) => state.updateRole);

  const onPermissionSetSelectionChange = async (
    selectedRowKeys: React.Key[]
    // selectionRows: HasIdentity[]
  ) => {
    setSelectedPsets(selectedRowKeys as string[]);
  };

  const onUserSelectionChange = async (
    selectedRowKeys: React.Key[]
    // selectionRows: HasIdentity[]
  ) => {
    setSelectedUsers(selectedRowKeys as string[]);
  };

  const onClose = async () => {
    setSelectedPsets([]);
    setSelectedUsers([]);
    form.resetFields();
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Roles"
      subtitle="permission sets and users"
      onClose={onClose}
      open={drawerState}
      onSubmit={props.readOnly ? undefined : handleSubmit}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              <Input disabled={props.readOnly} />
            </Form.Item>
          ))}
        </Form>
        <Divider />
        <Tabs defaultActiveKey="permission-sets-tab">
          <TabPane tab="Permission Sets" key="permission-sets-tab">
            <Assign
              dataSource={psetDataSource}
              name="Permission Sets"
              readonly={props.readOnly}
              onChange={onPermissionSetSelectionChange}
              showAdd={!props.readOnly}
              onAdd={props.onPermissionSetAdd}
              type="permission-sets"
              multiple={true}
            />
          </TabPane>
          <TabPane tab="Users" key="users-tab">
            <Assign
              dataSource={userDataSource}
              name="Users"
              onChange={onUserSelectionChange}
              readonly={props.readOnly}
              showAdd={!props.readOnly}
              onAdd={props.onUserAdd}
              type="users"
              multiple={true}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </Drawer>
  );
};
