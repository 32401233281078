import { useState, useEffect } from "react";
import { Card, Form, Input, Select, Space, Typography, Upload, UploadFile } from "antd";
import type { SelectProps } from 'antd';

import { FieldLabel } from "components/FieldLabel";
import { FC } from "react";
import { useDeveloperXStore } from "store";
import { AdapterType, AuthType, AuthTypeMap, OAuthGrantTypeMap, SpecNameValidationState } from "types";
import { capitalizeWords } from "utility";
import TextArea from "antd/es/input/TextArea";
import AppLogos from "../appLogos";
import CollapsePanel from "components/CollapsePanel";
import OAuthHttpConfiguration from "./oauthConfiguration";


const { Text } = Typography;


const AppHttpConfiguration: FC = () => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [nameValidationState, setNameValidationState] = useState(SpecNameValidationState.Unknown);

  const {
    selectedApp,
  } = useDeveloperXStore((state) => ({
    selectedApp: state.selectedApp,
  }));

  const onValuesChange = async () => {
    // const currentValues = form.getFieldsValue(true);  
    // const newValues: any = {}; 
    // parameters && Object.entries(parameters).map(([name, value]) => {
    //   if (name in currentValues) {
    //     if (value.type == IODataType.Array) {
    //       newValues[name] = (currentValues[name] as string).split(" ");
    //     } else {
    //       newValues[name] = currentValues[name];
    //     }
    //   }
    // });
    // onChange?.(newValues);
  };

  const syncCurrentState = async () => {
    try {
      form.setFieldsValue({
        authTypes: selectedApp.httpConfiguration.authTypes,
      });
      if (selectedApp.httpConfiguration.authTypes.find((x) => x == AuthType.AuthTypeOAuth)) {
        form.setFieldsValue({
          oAuthGrantTypes: selectedApp.httpConfiguration.oAuthGrantTypes,
        });
      }

    } catch (error) {
      console.log(error);
    }
  };



  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };



  useEffect(() => {
    syncCurrentState();
  }, [selectedApp]);

  return (
    <Form
      form={form}
      name="httpConfiguration"
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
      disabled={false}
    >
      <Space size={0} direction="vertical" style={{ display: 'flex' }}>
        <Form.Item
          name="authTypes"
          required
          label={
            <FieldLabel label={"Auth Types"} help={"Supported auth types by http configuration"} />
          }
          rules={[
            { required: true, message: `Auth type must be selected!` }
          ]}
        >
          <Select
            placeholder={`Select Auth Type`}
            allowClear
            mode="multiple"
          >
            {Object.entries(AuthTypeMap).map(([k, v]) => (
              <Select.Option key={k} value={v}>
                {k}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedApp.httpConfiguration?.authTypes?.find((x) => x == AuthType.AuthTypeOAuth) &&
          <CollapsePanel
            name={
              <Text>OAuth Configuration</Text>
            }
            ghost={false}
            collapsePanel={true}
          >
            <OAuthHttpConfiguration />
          </CollapsePanel>
        }
      </Space>
    </Form>
  );
};

export default AppHttpConfiguration;
