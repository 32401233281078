import {
  changeContentAccessApi,
  createActionApi, updateActionApi, deleteActionApi, getActionApi,getActionsApi, actionActionApi,
  createTriggerApi, updateTriggerApi, deleteTriggerApi, getTriggerApi, getTriggersApi, actionTriggerApi,
  createArtifactApi, updateArtifactApi, deleteArtifactApi, getArtifactApi, getArtifactsApi, actionArtifactApi,
  createAppApi, updateAppApi, deleteAppApi, getAppApi, getAppsApi, actionAppApi,

  createActionProviderApi, updateActionProviderApi, deleteActionProviderApi, getActionProvidersApi, getActionProviderApi, actionActionProviderApi,
  createTriggerProviderApi, updateTriggerProviderApi, deleteTriggerProviderApi, getTriggerProvidersApi, getTriggerProviderApi, actionTriggerProviderApi,
  createArtifactProviderApi, updateArtifactProviderApi, deleteArtifactProviderApi, getArtifactProvidersApi, getArtifactProviderApi, actionArtifactProviderApi,

  getDownloadUrlApi, getUploadUrlApi, getActionVersionsApi, getTriggerVersionsApi, getArtifactVersionsApi, getAppVersionsApi, getActionProviderVersionsApi, getTriggerProviderVersionsApi, getArtifactProviderVersionsApi,
} from "api";

import { 
  Action, 
  ActionProvider, 
  Artifact, 
  DeveloperActions, 
  DeveloperStore, 
  Trigger,
  App,
  TriggerProvider,
  DeveloperTab, 
  SpecActionType,
  SpecType,
  ArtifactProvider,
  INVALID_DEVELOPER_ITEM,
  DeveloperItem,
  DeveloperTabType,
  PageInfo,
  DeveloperXItem,
  DeveloperNodeCardType,
} from "types";


import { create } from "zustand"



export const useDeveloperStore = create<DeveloperStore & DeveloperActions>((set) => ({
    triggers : [],
    actions: [],
    artifacts: [],
    apps: [],
    actionProviders: [],
    triggerProviders: [],
    artifactProviders: [],
    
    appPage: {number:1, size: 12, total: 0} as PageInfo,
    triggerPage: {number:1, size: 12, total: 0} as PageInfo,
    actionPage: {number:1, size: 12, total: 0} as PageInfo,
    artifactPage: {number:1, size: 12, total: 0} as PageInfo,
    triggerProviderPage: {number:1, size: 12, total: 0} as PageInfo,
    actionProviderPage: {number:1, size: 12, total: 0} as PageInfo,
    artifactProviderPage: {number:1, size: 12, total: 0} as PageInfo,

    /** Editable is a stack of items being edited. When edit finishes in the editor, navigate to the next id */
    navigables: [],
    currentEditable: null,
    activeItem: INVALID_DEVELOPER_ITEM,
    selectedItem: {specType: SpecType.Unknown, item: {}},
    /** Currently active tab  */
    activeTab: {tabType: DeveloperTabType.Actions, specType: SpecType.Action} as DeveloperTab,
    activeCard: null,
    activeNodeCard: null,

    
    doSpecCreate: async (specType: SpecType, obj: unknown) => {
      switch(specType){
        case SpecType.Action:{
          const actionOut = await createActionApi(obj as Action);
          const action = await getActionApi(actionOut.id);
          set( state => ({...state, actions: [...state.actions, action]}));    
          return action;
        }
        case SpecType.Trigger: {
          const triggerOut = await createTriggerApi(obj as Trigger);
          const trigger = await getTriggerApi(triggerOut.id);
          set( state => ({...state, triggers: [...state.triggers, trigger]}));    
          return trigger;
        }
        case SpecType.Artifact: {
          const artifactOut = await createArtifactApi(obj as Artifact);
          const artifact = await getArtifactApi(artifactOut.id);
          set( state => ({...state, artifacts: [...state.artifacts, artifact]}));    
          return artifact;
        }
        case SpecType.App: {
          const appOut = await createAppApi(obj as App);
          const app = await getAppApi(appOut.id);
          set( state => ({...state, apps: [...state.apps, app]}));    
          return app;
        }
        case SpecType.ActionProvider: {
          const actionProviderOut = await createActionProviderApi(obj as ActionProvider);
          const actionProvider = await getActionProviderApi(actionProviderOut.id);
          set( state => ({...state, actionProviders: [...state.actionProviders, actionProvider]}));    
          return actionProvider;
        }
        case SpecType.TriggerProvider: {
          const triggerProviderOut = await createTriggerProviderApi(obj as TriggerProvider);
          const triggerProvider = await getTriggerProviderApi(triggerProviderOut.id);
          set( state => ({...state, triggerProviders: [...state.triggerProviders, triggerProvider]}));    
          return triggerProvider;
        }
        case SpecType.ArtifactProvider: {
          const artifactProviderOut = await createArtifactProviderApi(obj as ArtifactProvider);
          const artifactProvider = await getArtifactProviderApi(artifactProviderOut.id);
          set( state => ({...state, artifactProviders: [...state.artifactProviders, artifactProvider]}));    
          return artifactProvider
        }
        default:
          throw new Error(`specType ${specType} is not supported with any create`);
      }
    },

    doSpecUpdate: async(specType: SpecType, obj: unknown) => {
      switch(specType){
        case SpecType.Action: {
          const actionIn = obj as Action;
          await updateActionApi(actionIn);
          const action = await getActionApi(actionIn.id);
          set((state) => {
            const index = state.actions.findIndex( (x) =>  x.id == action.id);
            index == -1 ? state.actions = [...state.actions, action] : state.actions[index] = action ;
            return {...state}
          });
          return action;
        }
        case SpecType.Trigger: {
          const triggerIn = obj as Trigger;
          await updateTriggerApi(triggerIn);
          const trigger = await getTriggerApi(triggerIn.id);
          set((state) => {
            const index = state.triggers.findIndex( (x) =>  x.id == trigger.id);
            index == -1 ? state.triggers = [...state.triggers, trigger] : state.triggers[index] = trigger ;
            return {...state}
          });
          return trigger;
        }
        case SpecType.Artifact: {
          const artifactIn = obj as Artifact;
          await updateArtifactApi(artifactIn);
          const artifact = await getArtifactApi(artifactIn.id);
          set((state) => {
            const index = state.artifacts.findIndex( (x) =>  x.id == artifact.id);
            index == -1 ? state.artifacts = [...state.artifacts, artifact] : state.artifacts[index] = artifact ;
            return {...state}
          });
          return artifact;
        }
        case SpecType.App: {
          const appIn = obj as App;
          await updateAppApi(appIn);
          const app = await getAppApi(appIn.id);
          set((state) => {
            const index = state.apps.findIndex( (x) =>  x.id == app.id);
            index == -1 ? state.apps = [...state.apps, app] : state.apps[index] = app ;
            return {...state}
          }); 
          return app;
        }
        case SpecType.ActionProvider: {
          const actionProviderIn = obj as ActionProvider;
          await updateActionProviderApi(actionProviderIn);
          const actionProvider = await getActionProviderApi(actionProviderIn.id);
          set((state) => {
            const index = state.actionProviders.findIndex( (x) =>  x.id == actionProvider.id);
            index == -1 ? state.actionProviders = [...state.actionProviders, actionProvider] : state.actionProviders[index] = actionProvider ;
            return {...state}
          });
          return actionProvider;
        }
        case SpecType.TriggerProvider: {
          const triggerProviderIn = obj as TriggerProvider;
          await updateTriggerProviderApi(triggerProviderIn);
          const triggerProvider = await getTriggerProviderApi(triggerProviderIn.id);
          set((state) => {
            const index = state.triggerProviders.findIndex( (x) =>  x.id == triggerProvider.id);
            index == -1 ? state.triggerProviders = [...state.triggerProviders, triggerProvider] : state.triggerProviders[index] = triggerProvider ;
            return {...state}
          });
          return triggerProvider;
        }
        case SpecType.ArtifactProvider: {
          const artifactProviderIn = obj as ArtifactProvider;
          await updateArtifactProviderApi(artifactProviderIn);
          const artifactProvider = await getArtifactProviderApi(artifactProviderIn.id);
          set((state) => {
            const index = state.artifactProviders.findIndex( (x) =>  x.id == artifactProvider.id);
            index == -1 ? state.artifactProviders = [...state.artifactProviders, artifactProvider] : state.artifactProviders[index] = artifactProvider ;
            return {...state}
          });
          return artifactProvider;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any update`);
      }
    },
  
    doSpecDelete: async (specType: SpecType, id: string, data?: any) => {
      switch(specType){
        case SpecType.Action:{
          await deleteActionApi(id);
          break;
        }
        case SpecType.Trigger: {
          await deleteTriggerApi(id);
          break;
        }
        case SpecType.Artifact: {
          await deleteArtifactApi(id);
          break;
        }
        case SpecType.App: {
          await deleteAppApi(id);
          break;
        }
        case SpecType.ActionProvider: {
          await deleteActionProviderApi(id);
          break;
        }
        case SpecType.TriggerProvider: {
          await deleteTriggerProviderApi(id);
          break;
        }
        case SpecType.ArtifactProvider: {
          await deleteArtifactProviderApi(id);
          break;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any delete`);
      }
      useDeveloperStore.getState().doSpecsFetch(specType, data); 
    },
  
    doSpecAction: async(specType: SpecType, id: string, actionType: SpecActionType, obj?: unknown) => {
      switch(specType){
        case SpecType.Action: {
          await actionActionApi(id, actionType, obj);
          const action = await getActionApi(id);
          set((state) => {
            const index = state.actions.findIndex( (x) =>  x.id == id);
            index == -1 ? state.actions = [...state.actions, action] : state.actions[index] = action ;
            return {...state}
          });
          return action;
        }
        case SpecType.Trigger: {
          await actionTriggerApi(id, actionType, obj);
          const trigger = await getTriggerApi(id);
          set((state) => {
            const index = state.triggers.findIndex( (x) =>  x.id == id);
            index == -1 ? state.triggers = [...state.triggers, trigger] : state.triggers[index] = trigger ;
            return {...state}
          });
          return trigger;
        }
        case SpecType.Artifact: {
          await actionArtifactApi(id, actionType, obj);
          const artifact = await getArtifactApi(id);
          set((state) => {
            const index = state.artifacts.findIndex( (x) =>  x.id == id);
            index == -1 ? state.artifacts = [...state.artifacts, artifact] : state.artifacts[index] = artifact ;
            return {...state}
          });
          return artifact;
        }
        case SpecType.App: {
          await actionAppApi(id, actionType, obj);
          const app = await getAppApi(id);
          set((state) => {
            const index = state.apps.findIndex( (x) =>  x.id == id);
            index == -1 ? state.apps = [...state.apps, app] : state.apps[index] = app ;
            return {...state}
          });

          return app;
        }
  
        case SpecType.ActionProvider: {
          await actionActionProviderApi(id, actionType, obj);
          const actionProvider = await getActionProviderApi(id);
          set((state) => {
            const index = state.actionProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.actionProviders = [...state.actionProviders, actionProvider] : state.actionProviders[index] = actionProvider ;
            return {...state}
          });
          
          return actionProvider;
        }
        case SpecType.TriggerProvider: {
          await actionTriggerProviderApi(id, actionType, obj);
          const triggerProvider = await getTriggerProviderApi(id);
          set((state) => {
            const index = state.triggerProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.triggerProviders = [...state.triggerProviders, triggerProvider] : state.triggerProviders[index] = triggerProvider ;
            return {...state}
          });
          return triggerProvider;
        }
        case SpecType.ArtifactProvider: {
          await actionArtifactProviderApi(id, actionType, obj);
          const artifactProvider = await getArtifactProviderApi(id);
          set((state) => {
            const index = state.artifactProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.artifactProviders = [...state.artifactProviders, artifactProvider] : state.artifactProviders[index] = artifactProvider ;
            return {...state}
          });
          return artifactProvider;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any actions`);
      }
    },

    doSpecFetch: async(specType: SpecType, id: string) => {
      switch(specType){
        case SpecType.Action: {
          const action = await getActionApi(id);
          set((state) => {
            const index = state.actions.findIndex( (x) =>  x.id == id);
            index == -1 ? state.actions = [...state.actions, action] : state.actions[index] = action ;
            return {...state}
          });
          return action;
        }
        case SpecType.Trigger: {
          const trigger = await getTriggerApi(id);
          set((state) => {
            const index = state.triggers.findIndex( (x) =>  x.id == id);
            index == -1 ? state.triggers = [...state.triggers, trigger] : state.triggers[index] = trigger ;
            return {...state}
          });
          return trigger;
        }
        case SpecType.Artifact: {
          const artifact = await getArtifactApi(id);
          set((state) => {
            const index = state.artifacts.findIndex( (x) =>  x.id == id);
            index == -1 ? state.artifacts = [...state.artifacts, artifact] : state.artifacts[index] = artifact ;
            return {...state}
          });
          return artifact;
        }
        case SpecType.App: {
          const app = await getAppApi(id);
          set((state) => {
            const index = state.apps.findIndex( (x) =>  x.id == id);
            index == -1 ? state.apps = [...state.apps, app] : state.apps[index] = app ;
            return {...state}
          });
          return app;
        }
        case SpecType.ActionProvider: {
          const actionProvider = await getActionProviderApi(id);
          set((state) => {
            const index = state.actionProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.actionProviders = [...state.actionProviders, actionProvider] : state.actionProviders[index] = actionProvider ;
            return {...state}
          });
          return actionProvider;
        }
        case SpecType.TriggerProvider: {
          const triggerProvider = await getTriggerProviderApi(id);
          set((state) => {
            const index = state.triggerProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.triggerProviders = [...state.triggerProviders, triggerProvider] : state.triggerProviders[index] = triggerProvider ;
            return {...state}
          });
          return triggerProvider;
        }
        case SpecType.ArtifactProvider: {
          const artifactProvider = await getArtifactProviderApi(id);
          set((state) => {
            const index = state.artifactProviders.findIndex( (x) =>  x.id == id);
            index == -1 ? state.artifactProviders = [...state.artifactProviders, artifactProvider] : state.artifactProviders[index] = artifactProvider ;
            return {...state}
          });
          return artifactProvider;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any actions`);
      }
    },

    doSpecVersionsFetch: async(specType: SpecType, id:string) => {
      switch(specType){
        case SpecType.Action: {
          const actions = await getActionVersionsApi(id);
          return actions;
        }
        case SpecType.Trigger: {
          const triggers = await getTriggerVersionsApi(id);
          return triggers;
        }
        case SpecType.Artifact: {
          const artifacts = await getArtifactVersionsApi(id);
          return artifacts;
        }
        case SpecType.App: {
          const apps = await getAppVersionsApi(id)
          return apps;
        }
        case SpecType.ActionProvider: {
          const actionProviders = await getActionProviderVersionsApi(id)
          return actionProviders;
        }
        case SpecType.TriggerProvider: {
          const triggerProviders = await getTriggerProviderVersionsApi(id)
          return triggerProviders;
        }
        case SpecType.ArtifactProvider: {
          const artifactProviders = await getArtifactProviderVersionsApi(id)
          return artifactProviders;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any actions`);
      }
    },


    doSpecsFetch: async(specType: SpecType, data?:any, pageNumber?: number, pageSize?: number) => {
      console.log("DO Spec fetc", pageNumber, pageSize, specType)
      switch(specType){
        case SpecType.Action: {
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.actionPage.number;
            const ps = pageSize ? pageSize :  state.actionPage.size;
            getActionsApi(pn, ps).then(([newActions, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set( state => ({...state, actions: newActions, actionPage: newPage}));
            })
            return state;
          });
          return;
        }
        case SpecType.Trigger: {
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.triggerPage.number;
            const ps = pageSize ? pageSize :  state.triggerPage.size;
            getTriggersApi(false,pn, ps).then(([newTriggers, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set( state => ({...state, triggers: newTriggers, triggerPage: newPage}));
            })
            return state;
          });
          return;
        }
        case SpecType.Artifact: {
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.artifactPage.number;
            const ps = pageSize ? pageSize :  state.artifactPage.size;
            
            getArtifactsApi(pn, ps).then(([newArtifacts, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set( state => ({...state, artifacts: newArtifacts, artifactPage: newPage}));
            })
            return state;
          });
          return;
        }
        case SpecType.App: {
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.appPage.number;
            const ps = pageSize ? pageSize :  state.appPage.size;
            
            getAppsApi(pn, ps).then(([newApps, total]) => {
              const newPage: PageInfo = {number: state.appPage.number, size: state.appPage.size, total: total};
              set( state => ({...state, apps: newApps, appPage: newPage}));
            })
            return state;
          });
          return;
        }
        case SpecType.ActionProvider: {
          const actionId = data?.actionId ? data?.actionId : null;
          const appId = data?.appId ? data?.appId : null;
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.actionProviderPage.number;
            const ps = pageSize ? pageSize :  state.actionProviderPage.size;
            
            getActionProvidersApi(actionId, appId, pn, ps).then(([newActionProviders, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set((state) => {
                /** get new array that removes all action providers contained in the newActionProviders */
                const existingProviders = state.actionProviders.filter((actionProvider) => {
                  return !newActionProviders.find((newActionProvider) => newActionProvider.id === actionProvider.id);
                });
                return { ...state, actionProviders : [...existingProviders, ...newActionProviders], actionProviderPage: newPage};
              });
              return state;
            })
            return state;
          });
          return;
        }
        case SpecType.TriggerProvider: {
          const triggerId = data?.triggerId ? data?.triggerId : null;
          const appId = data?.appId ? data?.appId : null;
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.triggerProviderPage.number;
            const ps = pageSize ? pageSize :  state.triggerProviderPage.size;
            
            getTriggerProvidersApi(triggerId, appId, pn, ps).then(([newTriggerProviders, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set((state) => {
                /** get new array that removes all trigger providers contained in the newTriggerProviders */
                const existingProviders = state.triggerProviders.filter((triggerProvider) => {
                  return !newTriggerProviders.find((newTriggerProvider) => newTriggerProvider.id === triggerProvider.id);
                });
                return { ...state, triggerProviders : [...existingProviders, ...newTriggerProviders], triggerProviderPage: newPage};
              });
              return state;
            })
            return state;
          });

          return;
        }
        case SpecType.ArtifactProvider: {
          const artifactId = data?.artifactId ? data?.artifactId : null;
          const appId = data?.appId ? data?.appId : null;
          set( (state) => {
            const pn = pageNumber ? pageNumber: state.artifactProviderPage.number;
            const ps = pageSize ? pageSize :  state.artifactProviderPage.size;
            
            getArtifactProvidersApi(artifactId, appId, pn, ps).then(([newArtifactProviders, total]) => {
              const newPage: PageInfo = {number: pn, size: ps, total: total};
              set((state) => {
                /** get new array that removes all artifact providers contained in the newArtifactProviders */
                const existingProviders = state.artifactProviders.filter((artifactProvider) => {
                  return !newArtifactProviders.find((newArtifactProvider) => newArtifactProvider.id === artifactProvider.id);
                });
                return { ...state, artifactProviders : [...existingProviders, ...newArtifactProviders], artifactProviderPage: newPage};
              });
              return state;
            })
            return state;
          });
          return;
        }
        default:
          throw new Error(`specType ${specType} is not supported with any fetches`);
      }
    },
    
    setCurrentPage: async(specType: SpecType, pageNumber: number, pageSize: number) => {
      switch(specType){
        case SpecType.Action: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.actionPage.total};
            return { ...state, actionPage : newPage};
          });
          return;
        }
        case SpecType.Trigger: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.triggerPage.total};
            return { ...state, triggerPage : newPage};
          });
          return;
        }
        case SpecType.Artifact: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.artifactPage.total};
            return { ...state, artifactPage : newPage};
          });
          return;
        }
        case SpecType.App: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.appPage.total};
            return { ...state, appPage : newPage};
          });
          return;
        }
        case SpecType.ActionProvider: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.actionProviderPage.total};
            return { ...state, actionProviderPage : newPage};
          });
          return;
        }
        case SpecType.TriggerProvider: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.triggerProviderPage.total};
            return { ...state, triggerProviderPage : newPage};
          });
          return;
        }
        case SpecType.ArtifactProvider: {
          set( (state) => {
            const newPage: PageInfo = {number: pageNumber, size: pageSize, total: state.artifactProviderPage.total};
            return { ...state, artifactProviderPage : newPage};
          });
          return;
        }
        default:
          throw new Error(`specType ${specType} is not supported`);
      }
    },
        
    setActiveTab: async(tab: DeveloperTab) => {
      set((state) => ({...state, activeTab: tab}));
    },
    setActiveCard: async(active: string|null) => {
      set((state) => ({...state, activeCard: active}));
    }, 
    
    setActiveNodeCard: async(active: DeveloperNodeCardType|null) => {
      set((state) => ({...state, activeNodeCard: active}));
    }, 
  
    setActiveItem : (di: DeveloperItem) => {
      set((state) => ({...state, activeItem: di}))
    },

    setSelectedItem : (di: DeveloperXItem) => {
      set((state) => ({...state, selectedItem: di}))
    },

    getDownloadUrl: getDownloadUrlApi,
    getUploadUrl: getUploadUrlApi,
    changeContentAccess : changeContentAccessApi,

  })
);
