import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { PageInfo, CaseActiviy, CaseActivityStore } from "types";

import { getCaseActivitiesApi } from "api";

export const useCaseActivityStore = create<CaseActivityStore>()(
  devtools((set, get) => ({
    activities: [] as CaseActiviy[],
    pageInfo: {number:1, size: 12, total: 0} as PageInfo,
    totalCount: 0,
    
    getCaseActivities: async (caseId: string, all?: boolean) => {
      try {
        const pageInfo = get().pageInfo;
        const sorters = ["createdAt"];
        const pageNumber = all ? undefined : pageInfo.number;
        const pageSize = all ? undefined : pageInfo.size;

        const [activities, totalCount] = await getCaseActivitiesApi(caseId, pageNumber, pageSize, sorters);
        set((state) => ({
          ...state,
          activities: [...activities],
          totalCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearCaseActivities: () => {
      set((state) => ({
        ...state,
        activities: [],
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },
  }),
    { name: "CaseActivityStore" }
  )
);