import { FC } from "react";

import ProviderForm from "./ProviderForm";

const IdentityProvider: FC = () => {
  return (
    <div>
      <ProviderForm />
    </div>
  );
};

export default IdentityProvider;
