
import { ArrayType, DataType, TextType } from "types";

//iodata value
export type IODataValue = string | number | boolean | any[];
export type IODataValueMap = Record<string, IODataValue>;
export type IODataValueArray = IODataValueMap[];
export type IODataValueOutputs = Record<string, IODataValue | IODataValueMap | IODataValueArray>

export enum IODataType {
  String = "string",
  Password = "password",
  Numeric = "numeric",
  Boolean = "boolean",
  Array = "array",
  Map = "map",
  TimeStamp = "timestamp",
  IP = "ip",
  File = "file"
}

export const IODataTypeMap: Map<IODataType, string>  =  new Map<IODataType, string> (
  [
    [IODataType.String, "String"],
    [IODataType.Password, "Password"],
    [IODataType.Numeric, "Numeric"],
    [IODataType.Boolean, "Boolean"],
    [IODataType.Array, "Array"],
    [IODataType.Map, "Map"],
    [IODataType.TimeStamp, "Time Stamp"],
    [IODataType.IP, "IP"],
    [IODataType.File, "File"]
  ]
)

//iodata
export interface IOData {
  description: string;
  displayName: string;
  type: IODataType;
  required: boolean;
  min: number;
  max: number;
  values?: any[];
  default?: any;
  order?: number;
  textType?: TextType;
  arrayType?: ArrayType;
}
export type IODataMap = Record<string, IOData>;
export type IODataArray = IODataMap[];
export type IODatas = Record<string, IOData | IODataMap | IODataArray>;
