import { Collapse } from "antd";

import { ViewProps } from "components/EntityEditor";
import { IODataOutputView } from "./ioDataOutputView";
const { Panel } = Collapse;

/** props.data is a {recordKey, recordValue, where the recordValue is a single element array
 * The element in the array is IODataMap. that is , a set of key and associate schema items } */
/**
 * target is structured as follows:
 * {
 * "recordKey": "outputSequence",
 * "recordValue": [
 *  {
 *    "parameter_0": {
 *      "description": "The name of the person",
 *      "type": "String",
 *      "required": true,
 *      "max": 0,
 *      "min": 0
 *    },
 *    "parameter_1": {
 *      "description": "The age of the person",
 *      "type": "Integer",
 *      "required": true,
 *      "max": 0,
 *      "min": 0
 *    }
 *  }
 *  ]
 * }
 *
 */

export const IODataArrayOutputView = (props: ViewProps) => {
  return (
    <IODataOutputView
      array={true}
      data={props.data}
      path={props.path}
      context={props.context}
      validator={props.validator}
    />
  );
};
