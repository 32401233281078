import { App} from 'antd';
import React from 'react';

import { NotificationDetail } from 'utility/notification';

export function NotificationHolder(): null {
    const { notification } = App.useApp();
    React.useEffect(() => {
        const handleMessage = ((event: CustomEvent<NotificationDetail>) => {
            const { args, type } = event.detail;
            notification[type](args);
        }) as EventListener;

        document.addEventListener('notificationEvent', handleMessage);

        return () => {
            document.removeEventListener('notificationEvent', handleMessage);
        };
    }, [notification]);

    return null;
}