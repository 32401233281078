import { useNavigate } from "react-router-dom";
import { Typography, Button } from "antd";
import { ssoLoginUrl } from "api/constant";
import { FC, useState, useEffect } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { useTokenStore } from "store/token";
import { TokenUtils } from "types";

/** The route gets 2 parameters: one is the user, and the other is the current url , so that we can come back to it after login */
export const Relogin: FC = () => {
  const token = useTokenStore((state) => state.token);
  const [tokenStatus, setTokenStatus] = useState(
    TokenUtils.getTokenStatus(token)
  );

  useEffect(() => {
    const status = TokenUtils.getTokenStatus(token);
    setTokenStatus(status);
  }, [token]);

  const [searchParams] = useSearchParams();
  const user = searchParams.get("user");
  const path = searchParams.get("path");
  const navigate = useNavigate();

  return (
    <div>
      {user && tokenStatus.isSso ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Typography.Title>Session Expired</Typography.Title>
          <Typography.Text>
            Your session has ended. Please click on link below to relogin
          </Typography.Text>
          <Typography.Link
            href={`${ssoLoginUrl}?user=${user}&path=${path}`}
            target="_self"
          >{`${ssoLoginUrl}?user=${user}&path=${path}`}</Typography.Link>
          <br />
          <Button type="primary" onClick={() => navigate("/signin")}>
            Go to SignIn instead
          </Button>
        </div>
      ) : (
        <Navigate to="/signin" />
      )}
    </div>
  );
};
