import { FC, useState } from "react";
import { Form, Input, InputNumber, Radio, RadioChangeEvent, Select, Space, Typography } from "antd";
const { Option } = Select;
const { TextArea } = Input;

import Modal from "../../components/Modal";
import { IODataType, ParameterStoreItemProps } from "types";
import { FieldLabel } from "components/FieldLabel";

// TODO - add item map and secure string support
const ParameterStoreItem: FC<ParameterStoreItemProps> = (props) => {
  const [form] = Form.useForm();
  const [itemType, setItemType] = useState<IODataType>(props.item?.type || IODataType.String);

  return (
    <Modal
      title="Parameter"
      onClose={props.onClose}
      open={true}
      footerName={props.item?.name == "" ? "Add" : "Update"}
      onSubmit={() => {
        form
          .validateFields()
          .then((values) => props.onSubmit?.(values))
          .catch((err) => {
            console.log(err);
          })
      }}
    >
      <Form 
        form={form} 
        name="parameterForm" 
        layout="vertical" 
        autoComplete="off"
        initialValues={props.item}
      >
        <Form.Item 
          name="name" 
          label={<FieldLabel label={"Name"}/>}
          validateTrigger="onSubmit"
          normalize={(value) => value.trim()}
          rules={[
            { required: true, message: "Name is required!" },
            {
              validator: (_, value) => {
                if (value) {
                  if (value.includes(" ")) {
                    return Promise.reject(new Error("No spaces allowed"));
                  }
                  if (props.checkNameExists(value)) {
                    return Promise.reject(new Error("Name already exists"));
                  }
                  return Promise.resolve();
                }
            }},
          ]}
        >
          <Input disabled={!props.editMode}/>
        </Form.Item>
        <Form.Item 
          name="description" 
          label={<FieldLabel label={"Description"}/>}
          validateTrigger="onSubmit"
        >
          <TextArea
            disabled={!props.editMode}
            showCount
            maxLength={100}
          />
        </Form.Item>
        <Form.Item 
          name="type"
          label={<FieldLabel label={"Type"} />}
          validateTrigger="onSubmit"
        >
          <Radio.Group 
            disabled={!props.editMode}
            onChange={(e: RadioChangeEvent) => setItemType(e.target.value)}
          >
            <Space direction="vertical">
              <Radio value={IODataType.String}>String</Radio>
              <Radio value={IODataType.Array}>StringList</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          name="value" 
          label={<FieldLabel label={"Value"}/>}
          rules={[
            { required: true, message: 'Value is required!'},
          ]}
        >
          { itemType == IODataType.String 
            &&
            <Input disabled={!props.editMode}/>
            ||
            itemType == IODataType.Array 
            &&
            <Select
              disabled={!props.editMode}
              showAction={["focus", "click"]}
              allowClear
              mode="tags"
            >
              {Object.keys(props.item?.value)
                .filter((val) => val != "")
                .map((val) => (
                  <Option key={val} value={val}>
                    {val}
                  </Option>
              ))}
            </Select>
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};
  
export default ParameterStoreItem;