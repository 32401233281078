import * as React from "react";
import { useEffect } from "react";
import { DeveloperItem } from "types";
import { EditorContext } from "./editorContext";
import { PageContext } from "./pageContext";
import { EntityCard } from "./entityCard";
import { EntityPanel } from "./entityPanel";
import { ViewerType } from "./common";
import { Path } from "types";
import { EntityNoControls } from "./entityNoControls";

export interface ViewCreatorProps {
  viewerType: ViewerType;
  item: DeveloperItem;
  name: string | number;
  /** This will will called by the child editor ro send saved props up the stack */
  onSave: (item: { [k: string]: any }) => Promise<void>;
  onCancel: () => Promise<void>;
  onDelete: (item: DeveloperItem) => Promise<void>;
  path: Path;
}

/**
 * A view creator is a child of a field, and allows fields to render their own view
 * The field will pass all meta data required to setup an editor (and hence populate EditorProps downstream
 * The global state of view and viewfactory is passed down from the EditorContext, and not obtained from the parent element
 * The path is path of the field wrt its parent editor
 */
export const ViewCreator = (props: ViewCreatorProps) => {
  const editorContext = React.useContext(EditorContext);
  const pageContext = React.useContext(PageContext);
  useEffect(() => {
    // if (props.viewerType == ViewerType.PageViewer) {
    //   pageContext.setPage(props.item, props.path);
    // }
  });
  if (props.viewerType == ViewerType.PageViewer) {
    return <></>;
  } else if (props.viewerType == ViewerType.CardViewer) {
    return (
      <EntityCard
        name={props.name}
        editable={props.item}
        view={editorContext.view}
        onSave={props.onSave}
        onCancel={props.onCancel}
        onDelete={props.onDelete}
        path={props.path}
        viewFactory={editorContext.viewFactory}
      >
        {editorContext.viewFactory(props.item, props.path)}
      </EntityCard>
    );
  } else if (props.viewerType == ViewerType.InlineViewer) {
    return (
      <EntityPanel
        name={props.name}
        editable={props.item}
        view={editorContext.view}
        onSave={props.onSave}
        onCancel={props.onCancel}
        onDelete={props.onDelete}
        path={props.path}
        viewFactory={editorContext.viewFactory}
      >
        {editorContext.viewFactory(props.item, props.path)}
      </EntityPanel>
    );
  } else if (props.viewerType == ViewerType.NoControlsViewer) {
    return (
      <EntityNoControls
        name={props.name}
        editable={props.item}
        view={editorContext.view}
        onSave={props.onSave}
        onCancel={props.onCancel}
        path={props.path}
        viewFactory={editorContext.viewFactory}
        onDelete={props.onDelete}
      >
        {editorContext.viewFactory(props.item, props.path)}
      </EntityNoControls>
    );
  } else return <>{editorContext.viewFactory(props.item, props.path)}</>;
};
