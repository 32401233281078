import {
  ApprovalStatus,
  Workflow,
} from "types";

//WorkflowRun
export const enum WorkflowRunStatusCode {
  NotRunning = "not_running",
  Unspecified = "unspecified",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
  Canceled = "canceled",
  Terminated = "terminated",
  TimedOut = "timed_out",
  Waiting = "waiting",
  PendingApproval = "pending_approval",
}
  
export const WorkflowRunStatusCodeMap: Map<WorkflowRunStatusCode, string>  =  new Map<WorkflowRunStatusCode, string> (
  [
    [WorkflowRunStatusCode.NotRunning, "Not Running"], 
    [WorkflowRunStatusCode.Unspecified, "Unspecified"],
    [WorkflowRunStatusCode.Running, "Running"], 
    [WorkflowRunStatusCode.Completed, "Completed"],
    [WorkflowRunStatusCode.Failed, "Failed"], 
    [WorkflowRunStatusCode.Canceled, "Canceled"], 
    [WorkflowRunStatusCode.Terminated, "Terminated"],
    [WorkflowRunStatusCode.TimedOut, "Timed Out"],
    [WorkflowRunStatusCode.Waiting, "Waiting"],
    [WorkflowRunStatusCode.PendingApproval, "Pending Approval"],
  ]
)

  export interface WorkflowRunStepResult {
    status: WorkflowRunStatusCode;
    reason: string;
    searchRunID?: string;
    searchRunActionID?: string;  
    approvalID?: string;  
    subworkflowRunID?: string;  
  }
  
  export interface  WorkflowRunResult {
    trigger: WorkflowRunStepResult;
    steps: Record<string, WorkflowRunStepResult>;
  }
  
  export interface WorkflowRunApprover {
    id: string;
    email: string;
    isManagedUser: boolean;
    approvalStatus: ApprovalStatus;
    isMyApproval: boolean;
    approveUrl?: string;
    rejectUrl?: string;
    comments?: string;
  }
  
  export interface WorkflowRunApproval {
    approvers: WorkflowRunApprover[];
  }
  
  export type WorkflowRunApprovals = Record<string, WorkflowRunApproval>;

  export interface WorkflowRun {
    id: string;
    name: string;
    user: string;
    workflowID: string;
    workflows: Workflow;
    edgeID: string;
    status: WorkflowRunStatusCode;
    isSubworkflowRun: boolean;
    result: WorkflowRunResult;
    startTime: string;
    closeTime: string;
    tagID: string;
    tenantID: string;
    userID: string;
    approvals?: WorkflowRunApprovals;
  }
  