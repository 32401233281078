import { 
    SpecStateType, 
    AccessInfo,
    Artifact,
    HttpAdapterSpec,
    AppInfo,
} from "types";

export enum ArtifactProviderAdapterType {
    http = "http"
}

export interface ArtifactProvider {
    id: string;
    comments: string;
    state: SpecStateType;
    shared: boolean;
    name: string;
    displayName: string;
    description: string;
    appID: string;
    artifactID: string;
    appInfo: AppInfo;
    artifacts: Artifact;
    adapterType: ArtifactProviderAdapterType;
    http?: HttpAdapterSpec;
    accessInfo?: AccessInfo;
    updatedAt?: string;
    createdAt?: string;
    tenantID: string;
    userID: string;
}

export type ArtifactProviderUploadObjectType = Omit<ArtifactProvider, 'state' | 'shared' | 'accessInfo' | 'appInfo'|  'updatedAt' | 'createdAt'| 'userID'>;

export interface ArtifactProviders {
    artifact: Artifact;
    providers: ArtifactProvider[];
}

