import { AutoComplete, Collapse, Table } from "antd";
import { useContext, useEffect, useState, useMemo } from "react";
import { createUseStyles } from "react-jss";
import {
  IODatas,
  IODataValue,
  IODataValueArray,
  IODataValueMap,
  IODataValueOutputs,
} from "types";
import { FieldProps } from "./common";
import { FieldLabel } from "./fieldLabel";
import { SuggestionContext } from "./suggestionContext";
import { typeGuards } from "./typeGuards";
import { debounce } from "lodash";

const { Panel } = Collapse;

export interface OutputsTransformFieldProps extends FieldProps {
  /** this is the source outputs coming from the action (or trigger) */
  outputs: IODatas;
  /** These are the values that are already in place */
  current: IODataValueOutputs;
  /** Notify upstream when value changes */
  onChange: (identifier: string, value: IODataValueOutputs) => Promise<void>;
}

const useStyles = createUseStyles({
  autoComplete: {
    height: "40px !important",
    borderRadius: "4px !important",
    border: "solid 1px #DFE5E9 !important",
    padding: "0px 0px !important",
    marginBottom: "10px",
    width: "100%",
    minWidth: "200px",
  },
});

/** Existing output transforms should match up  */
export const OutputsTransformField = (props: OutputsTransformFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const [dataSource, setDataSource] = useState<IODataValueOutputs>({});
  const suggestionContext = useContext(SuggestionContext);
  const classes = useStyles();

  useEffect(() => {
    const result: IODataValueOutputs = {};
    if(!props.outputs) return;

    Object.keys(props?.outputs).forEach((key) => {
      const output = props.outputs[key];
      const current = props.current[key];
      if (typeGuards.isIOData(output)) {
        result[key] = current || "";
      }
      if (typeGuards.isIODataMap(output)) {
        result[key] = current || {};
      }
      if (typeGuards.isIODataArray(output)) {
        result[key] = current || [];
      }
    });
    setDataSource(result);
  }, [props.outputs, props.current]);

  /** Do a search, and return possible results for autocomplete */
  const onSearch = (value: string) => {
    let opts: { value: string; label: string }[] = [];
    suggestionContext.suggestions?.forEach((suggestionList, regex) => {
      if (value && value.match(regex)) {
        opts = suggestionList.map((str) => ({
          value: `${value}${str}`,
          label: str,
        }));
      }
    });

    return setOptions(opts);
  };

  const onSelect = (
    data: string,
    root: string,
    key: string | null,
    isIoDataArray: boolean
  ) => {
    // Update the value in the data source
    let newDataSource = {};
    if (!key) {
      newDataSource = { ...dataSource, [root]: data };
    } else {
      const map = dataSource[root] as IODataValueMap;
      newDataSource = {
        ...dataSource,
        [root]: { ...map, [key]: isIoDataArray ? [data] : data },
      };
    }

    // Update the value in the parent
    props.onChange(props.identifier, newDataSource);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  /**  */
  const getAutoCompleteField = (
    root: string,
    key: string | null,
    isIoDataArray: boolean
  ) => {
    const value = !key
      ? dataSource[root]
      : isIoDataArray
      ? (dataSource[root] as IODataValueArray)[0]?.[key]
      : (dataSource[root] as IODataValueMap)[key];
    // At this stage, the value is a primitive, and we convert to string to display in the autocomplete field
    const primitive = value.toString();

    return (
      <AutoComplete
        defaultValue={primitive}
        className={classes.autoComplete}
        allowClear={true}
        options={options}
        onSelect={(data: string) => onSelect(data, root, key, isIoDataArray)}
        onSearch={(text) => debouncedSearch(text)}
      />
    );
  };

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const render = (
    value: IODataValue | IODataValueMap | IODataValueArray,
    record: [string, IODataValue | IODataValueMap | IODataValueArray]
  ) => {
    // record[0] is the key, record[1] is the value. The same key also indexes into the props.outputs object
    if (typeGuards.isIOData(props.outputs[record[0]])) {
      return getAutoCompleteField(record[0], null, false);
    } else {
      const isIODataArray = typeGuards.isIODataArray(props.outputs[record[0]]);
      return (
        <Table
          dataSource={Object.entries(
            isIODataArray ? (record[1] as IODataValueArray)[0] : record[1]
          )}
          columns={[
            { title: "Name", dataIndex: 0 },
            {
              title: "Value",
              dataIndex: 1,
              render: (
                _: any,
                v: [string, IODataValue | IODataValueMap | IODataValueArray]
              ) => getAutoCompleteField(record[0], v[0], isIODataArray),
            },
          ]}
        ></Table>
      );
    }
  };

  return (
    <Collapse defaultActiveKey={["1"]} ghost={true}>
      <Panel header={<FieldLabel fp={props} />} key={"1"}>
        <Table
          rowKey={(record) => `output-transfrom-${record[0]}`}
          dataSource={Object.entries(dataSource)}
          columns={[
            { title: "Name", dataIndex: [0] },
            {
              title: "Value",
              dataIndex: [1],
              render,
            },
          ]}
          pagination={{ position: [] }}
          showHeader={true}
        ></Table>
      </Panel>
    </Collapse>
  );
};
