import axios, { AxiosResponse } from "axios";
import { ClientCredentialsCreateRequestDto, ClientCredentialsCreateResponseDto, ClientCredentialsUpdateRequestDto,ClientCredentialsListResponseDto, CreatePermissionSet, CreateRole, CreateUser, PermissionSetDto, RoleDto, UpdatePermissionSet, UpdateRole, UpdateUser, UserDto, ClientCredentialsUpdateResponseDto } from "types";
import { axiosInterceptor } from "./axios";
import { clientCredentialsApiPath, permissionSetsApiPath as psetsApiPath, rolesApiPath, usersApiPath } from "./constant";


export const getEligibilityApi = async (): Promise<boolean> => true;
export const listUsersApi = async () : Promise<AxiosResponse<UserDto[]>> => axiosInterceptor("get", usersApiPath);
export const getUserApi = async (userId: string) : Promise<AxiosResponse<UserDto>> => axiosInterceptor("get", `${usersApiPath}/${userId}`);
export const createUserApi = async ( cu : CreateUser): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("post", usersApiPath, { ...cu });
export const updateUserApi = async ( u: UpdateUser): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("patch", `${usersApiPath}/${u.id}`, { ...u });
export const deleteUserApi = async (id: string): Promise<AxiosResponse<UserDto>>  => axiosInterceptor("delete", `${usersApiPath}/${id}`);

export const listRolesApi = async () : Promise<AxiosResponse<RoleDto[]>> => axiosInterceptor("get", rolesApiPath);
export const createRoleApi = async (role: CreateRole): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("post", rolesApiPath, { ...role });
export const getRoleApi = async (id: string): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("get", `${rolesApiPath}/${id}`);
export const updateRoleApi = async (role: UpdateRole): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("patch", `${rolesApiPath}/${role.id}`, { ...role });
export const deleteRoleApi = async (id: string): Promise<AxiosResponse<RoleDto>>  => axiosInterceptor("delete", `${rolesApiPath}/${id}`);

export const listPermissionSetsApi = async () : Promise<AxiosResponse<PermissionSetDto[]>> => axiosInterceptor("get", psetsApiPath);
export const createPermissionSetApi = async (ps: CreatePermissionSet): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("post", psetsApiPath, { ...ps });
export const getPermissionSetApi = async (id: string): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("get", `${psetsApiPath}/${id}`);
export const updatePermissionSetApi = async (ps: UpdatePermissionSet): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("patch", `${psetsApiPath}/${ps.id}`, { ...ps });
export const deletePermissionSetApi = async (id: string): Promise<AxiosResponse<PermissionSetDto>>  => axiosInterceptor("delete", `${psetsApiPath}/${id}`);

export const listClientCredentialsApi = async (): Promise<AxiosResponse<ClientCredentialsListResponseDto>> => axiosInterceptor("get", `${clientCredentialsApiPath}`);
export const createClientCredentialsApi = async (cc: ClientCredentialsCreateRequestDto): Promise<AxiosResponse<ClientCredentialsCreateResponseDto>> => axiosInterceptor("post", `${clientCredentialsApiPath}`, { ...cc });
export const updateClientCredentialsApi = async (clientId: string , cc: ClientCredentialsUpdateRequestDto): Promise<AxiosResponse<ClientCredentialsUpdateResponseDto>> => axiosInterceptor("patch", `${clientCredentialsApiPath}/${clientId}`, { ...cc });
export const deleteClientCredentialsApi = async (clientId: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${clientCredentialsApiPath}/${clientId}`);

export const getUserApiWithToken = async (userId: string, token: string) : Promise<AxiosResponse<UserDto>> => {
  const url = `${usersApiPath}/${userId}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
}