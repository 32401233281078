import {
    SpecStateType,
    AccessInfo,
    EdgeInfraType,
    AdapterType,
    HttpAdapterSpec,
    AppWebhookConfiguration,
    AppSubscription,
    AppHttpConfigurationSpec
} from "types";
import { AppSqlDBConfigurationSpec } from "./sqldb_configuration";
  
export enum AppEdgeDetectionHintType {
    AppEdgeDetectionHintConnectivity = "connectivity",
    AppEdgeDetectionHintConstant = "constant",
    AppEdgeDetectionHintDefault = "default",
    AppEdgeDetectionHintUser = "user"
}

export interface AppEdgeDetectorConnectivity {
    adapterType: AdapterType;
    http?: HttpAdapterSpec;
}
export interface AppEdgeDetectorConst {
    //InfraType Infra type
    infraType: EdgeInfraType;
    //Region region
    region: string;
}
export interface AppEdgeDetector {
    detectionHint: AppEdgeDetectionHintType;
    connectivityDetector?: AppEdgeDetectorConnectivity;
    constantDetector?: AppEdgeDetectorConst;
}

export enum AppParameterType {
    String = "string",
    Password = "password",
    Numeric = "numeric",
    Boolean = "boolean",
    Array = "array"
}

export interface AppParameter {
    name: string;
    label: string;
    placeholder: string;
    description: string;
    type: AppParameterType;
    required: boolean;
    readOnly: boolean;
    values: any[];
    default: any;
    suffix: string;
    prefix: string;
}

export interface App {
    id: string;
    comments: string;
    state: SpecStateType;
    active: boolean;
    shared: boolean;
    name: string;
    displayName: string;
    description: string;
    category: string;
    visibility: boolean;
    logoPath: string;
    darkLogoPath: string;
    logoUrl: string;
    darkLogoUrl: string;
    logoBuffer: string;
    darkLogoBuffer: string;
    edgeDetector: AppEdgeDetector;
    adapterTypes: AdapterType[];
    parameters: AppParameter[];
    httpConfiguration: AppHttpConfigurationSpec;
    sqlDBConfiguration: AppSqlDBConfigurationSpec;
    webhookConfiguration: AppWebhookConfiguration;
    tags: string[];
    appSubscriptions: AppSubscription[];
    accessInfo: AccessInfo;
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;
}

export type AppUploadObjectType = Omit<App,
    'state' | 'shared'  | 'appSubscriptions' | 'accessInfo'
    | 'updatedAt' | 'createdAt' | 'subscriptionStatus' | 'subscriptionId' | 'edgeInfraType' 
    | 'edgeDisplayName' | 'userID'>;




// DELETE after developer ui changes

export enum AppConfigurationType {
    String = "string",
    Password = "password",
    Numeric = "numeric",
    Boolean = "boolean",

}

export interface AppConfiguration {
    name: string;
    label: string;
    placeholder?: string;
    description: string;
    type: AppConfigurationType;
    required: boolean;
    readOnly: boolean;
    values?: string[];
    default?: string;
    suffix?: string;
    prefix?: string;
}