
import { 
    Conditions, 
    HTTPMethodType,
} from "types";

export enum HttpRequestType {
    Sync = "sync",
    Async = "async"
}

export enum HttpRequestContentType {
    File = "file",
    Text = "text",
    Xml = "xml",
    Json = "json"
}

export enum HttpResponseContentType {
    Json = "json"
}
export enum HttpRequestRetryLogic {
    Fixed = "fixed",
    ExponentialBackoff = "exponential_backoff"
}

export interface HttpRequestRetryPolicy {
    /** retry logic */
    retryLogic: HttpRequestRetryLogic;
    /** retry count */
    retryCount: number;
    /** retry interval */
    retryDelaySeconds: number;
}

export enum HttpPollIncrementTokenReaderType {
    Timestamp = "timestamp",
    ResponseHeader = "responseHeader",
    ResponsePayload = "responsePayload"
}

export enum HttpPollIncrementalTokenLocatorType {
    QueryParams = "queryParams",
    Headers = "headers",
    Payload = "payload"
}
export interface HttpPollIncrementTokenReaderTimeStamp {
    //TimestampFormat timestamp format
    timeStampFormat: string,
    //StartInitialValue to be sent for first request
    startInitialValue: string,
    //EndInitialValue to be sent for first request
    endInitialValue: string,
}

export interface HttpPollIncrementTokenReaderResponseHeader {
    //HeaderName header name
    headerName: string,
    //StartInitialValue to be sent for first request
    startInitialValue: string,
    //EndInitialValue to be sent for first request
    endInitialValue: string,
}

export interface HttpPollIncrementTokenReaderResponsePayload {
    //Token to be read from response payload, it can be JSON or XML path
    token: string
    //InitialValue to be sent for first request
    initialValue: string
}

export interface HttpPollIncrementalTokenLocatorQueryParam {
    //StartTokenParams query params you want to put the startTokenValue read
    startTokenParams: string[],
    //EndTokenParams query params you want to put the endTokenValue read  
    endTokenParams: string[],
}

export interface HttpPollIncrementalTokenLocatorHeaders {
    //StartTokenHeaders headers you want to put the startTokenValue read
    startTokenHeaders: string[],
    //EndTokenHeaders headers you want to put the endTokenValue read
    endTokenHeaders: string[],
}

export interface HttpPollIncrementalTokenLocatorPayload {
    //StartTokenPayload payload you want to put the startTokenValue read
    startTokenPayloads: string[],
    //EndTokenPayload payload you want to put the endTokenValue read
    endTokenPayloads: string[],
}


export interface PollPolicy {
    //PollCondition continue polling
    pollConditions: Conditions
    //PollIntervalInSeconds poll the http endpoint in seconds
    pollIntervalInSeconds: number
    //IncrementalTokenReaderype next incrmental token reading request type
    incrementalTokenReaderType: HttpPollIncrementTokenReaderType
    //TimestampTokenReader
    timestampTokenReader: HttpPollIncrementTokenReaderTimeStamp
    //ResponseHeaderTokenReader
    responseHeaderTokenReader: HttpPollIncrementTokenReaderResponseHeader
    //ResponsePayloadTokenReader
    responsePayloadTokenReader: HttpPollIncrementTokenReaderResponsePayload

    //IncrementalTokenLocatorType next incremental token placement locator type
    incrementalTokenLocatorType: HttpPollIncrementalTokenLocatorType
    //QueryParamsTokenLocator
    queryParamsTokenLocator: HttpPollIncrementalTokenLocatorQueryParam
    //HeadersTokenLocator
    headersTokenLocator: HttpPollIncrementalTokenLocatorHeaders
    //PayloadTokenLocator
    payloadTokenLocator: HttpPollIncrementalTokenLocatorPayload
}

export interface HttpRequest {
    /** path string */
    path: string;
    /** http method  */
    method: HTTPMethodType;
    /** content type */
    contentType: HttpRequestContentType;
    /** request type */
    requestType: HttpRequestType;
    /** timeout */
    timeout: number;
    /** headers */
    headers: Record<string, string>;
    /** path params */
    pathParams: Record<string, string>;
    /** query params */
    queryParams: Record<string, string>;
    /** body */
    payload: any
    /** success conditions */
    successConditions: Conditions;
    /** retry policy */
    retryPolicy: HttpRequestRetryPolicy;
    /** poll policy */
    pollPolicy: PollPolicy;
    /** response content type */
    responseContentType: HttpResponseContentType;
}

/**
 * Http adapter is a record of string and httprequest items
 */
export type HttpAdapterSpec = Record<string, HttpRequest>;


