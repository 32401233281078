import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { PageInfo, CaseAttachment, CaseAttachmentStore } from "types";

import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import {
  getCaseAttachmentsApi,
  getCaseAttachmentApi,
  deleteCaseAttachmentApi,
  uploadCaseAttachmentApi,
  downloadCaseAttachmentApi,
} from "api";
import { convertToApiFilters, convertToApiSorters } from "store/utils";

export const useCaseAttachmentStore = create<CaseAttachmentStore>()(
  devtools((set, get) => ({
    attachments: [] as CaseAttachment[],
    selectedAttachment: {} as CaseAttachment,
    pageInfo:  {number:1, size: 12, total: 0} as PageInfo,
    filter: {} as Record<string, FilterValue | null>,
    sorter: {} as SorterResult<DataType>,
    searchTextMap: new Map<string, string>(),
    totalCount: 0,
    
    getCaseAttachments: async (caseId: string, all?: boolean) => {
      try {
        const pageInfo = get().pageInfo;
        const filters = get().filter;
        const searches = get().searchTextMap;
        const sorters = get().sorter;
        
        const attachmentFilters = convertToApiFilters(filters, searches)     
        const attachmentSorters = convertToApiSorters(sorters);
        //default sorting
        if (attachmentSorters.length == 0) {
          attachmentSorters.push("-createdAt");
        }

        const pageNumber = all ? undefined : pageInfo.number;
        const pageSize = all ? undefined : pageInfo.size
        const [attachments, totalCount] = await getCaseAttachmentsApi(caseId, pageNumber, pageSize, attachmentFilters, attachmentSorters);
       
        set((state) => ({
          ...state,
          attachments: attachments,
          totalCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearCaseAttachments: () => {
      set((state) => ({
        ...state,
        attachments: [],
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getCaseAttachment: async (caseId: string, attachmentId: string) => {
      try {
        let caseAttachment = get().attachments.find((c) => c.id == attachmentId);
        if (!caseAttachment) {
          caseAttachment = await getCaseAttachmentApi(caseId, attachmentId);
        }

        set((state) => ({
          ...state,
          selectedAttachment: caseAttachment,
        }));
        return caseAttachment;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    uploadCaseAttachment: async (caseId: string, formData: any, caseNoteId?: string) => {
      try {
        await uploadCaseAttachmentApi(caseId, formData, caseNoteId);
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    downloadCaseAttachment: async (caseId: string, attachmentId: string) => {
      try {
        const response = await downloadCaseAttachmentApi(caseId, attachmentId);
        return response;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    deleteCaseAttachment: async (caseId: string, attachmentId: string) => {
      try {
        await deleteCaseAttachmentApi(caseId, attachmentId);
        set((state) => {
          state.attachments = state.attachments.filter(c => c.id !== attachmentId);
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },

    setFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, filter : filters};
      });
      return;
    },

    setSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, sorter : sorters};
      });
      return;
    },

    setSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        searchTextMap: new Map<string, string>(searches),
      }));
    },
  }),
    { name: "CaseAttachmentStore" }
  )
);
