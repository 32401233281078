import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { notification } from 'utility/notification';

import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./CreateNewPassword.module.scss";

const CreateNewPassword: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [submitButtonDisable, setSubmitButtonDisable] = useState<boolean>(true);

  const onSubmit = (value: any) => {
    console.log("value :>> ", value);

    // on success
    notification.success({
      message: "PASSWORD UPDATED",
      description: "Your password has been updated!",
      duration: 6,
    });
    navigate("/signin");

    // on error
    // notification.error({
    //   message: "SOMETHING WENT WRONG",
    //   description: "Please try again",
    //   duration: 6,
    // });
  };

  const onValuesChange = (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    if (
      values.password != undefined &&
      values.confirmPassword != undefined &&
      values.password != "" &&
      values.confirmPassword != ""
    ) {
      setSubmitButtonDisable(false);
    } else {
      setSubmitButtonDisable(true);
    }
  };

  return (
    <SignInSignUpWrapper>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <LockOutlined />
        </div>

        <h2 className={styles.verificationTitle}>NEW CREDENTIALS</h2>
        <p className={styles.verificationDescription}>
          Your identity has been verified! Set your new password
        </p>
        <Form
          form={form}
          name="signUpSetPasswordForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          <p className={styles.fromp}>Password</p>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Password is required!" },
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (
                    !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                      value
                    )
                  ) {
                    return Promise.reject(
                      "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              className="authInput"
              prefix={<LockOutlined />}
              placeholder="Enter your Password"
            />
          </Form.Item>
          <p className={styles.fromp}>Confirm Password</p>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="authInput"
              prefix={<LockOutlined />}
              placeholder="Confirm your Password"
            />
          </Form.Item>
        </Form>

        <Button
          className="authbtn_100"
          type="primary"
          disabled={submitButtonDisable}
          onClick={() =>
            form
              .validateFields()
              .then(onSubmit)
              .catch((err) => {
                console.log(err);
              })
          }
        >
          Update Password
        </Button>
      </div>
    </SignInSignUpWrapper>
  );
};

export default CreateNewPassword;
