import { Space } from "antd";
import {
  EditorContext,
  ViewProps,
  PrimitiveArrayField,
} from "components/EntityEditor";
import { ResourceType, DeveloperItem } from "types";

import React, { useContext, useEffect } from "react";

export const HttpPollIncrementalTokenLocatorPayloadView = (
  props: ViewProps
) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onArrayTextFieldAdd = async (key: string): Promise<DeveloperItem> => {
    const item = target[key];
    const index = item.length;
    const newItem = `New Item ${index}`;
    item.push(newItem);
    setTarget({ ...target, [key]: item });
    return {
      id: `poll-start-token-payload-${index}`,
      type: ResourceType.Primitive,
      item: { [0]: newItem },
    };
  };

  const onArrayTextFieldChange = async (
    key: string,
    value: DeveloperItem[]
  ) => {
    setTarget({ ...target, [key]: value.map((v) => v.item[0]) });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <PrimitiveArrayField
        value={target["startTokenPayloads"]?.map(
          (value: string, index: number): DeveloperItem => {
            return {
              id: `poll-start-token-payload-${index}`,
              item: { [0]: value },
              type: ResourceType.Primitive,
            };
          }
        )}
        identifier={"startTokenPayloads"}
        label={"Start Token Payloads"}
        addLabel={"Add Start Token Payload"}
        showExpanded={false}
        onAdd={onArrayTextFieldAdd}
        onChange={onArrayTextFieldChange}
        path={[...props.path, "startTokenPayloads"]}
      />

      <PrimitiveArrayField
        value={target["endTokenPayloads"]?.map(
          (value: string, index: number): DeveloperItem => {
            return {
              id: `poll-end-token-payload-${index}`,
              item: { [0]: value },
              type: ResourceType.Primitive,
            };
          }
        )}
        identifier={"endTokenPayloads"}
        label={"End Token Payloads"}
        addLabel={"Add End Token Payload"}
        showExpanded={false}
        onAdd={onArrayTextFieldAdd}
        onChange={onArrayTextFieldChange}
        path={[...props.path, "endTokenPayloads"]}
      />
    </Space>
  );
};
