import { DeveloperItem } from "types";
import { Path, ResourceType } from "types";
import { ActionView } from "./actionView";
import { AppConfigurationView } from "./App/appConfigurationView";
import { AppEdgeDetectorView } from "./App/appEdgeDetectorView";
import { AppEdgeDetectorConstantView } from "./App/appEdgeDetectorConstantView";
import { AppEdgeDetectorConfigurationView } from "./App/appEdgeDetectorConfigurationView";
import { AppView } from "./appView";
import { ArtifactView } from "./artifactView";
import { TriggerView } from "./triggerView";
import { IODataView } from "./Data/ioDataView";
import { IODataUsageType } from "./Data/ioDataView";
import { IODataMapOutputView } from "./Data/ioDataMapOutputView";
import { IODataArrayOutputView } from "./Data/ioDataArrayOutputView";
import { ArtifactFieldView } from "./Data/artifactFieldView";
import { TriggerProviderView } from "./triggerProviderView";
import { ActionProviderView } from "./actionProviderView";
import { HttpRequestView } from "./Http/httpRequestView";
import { HttpRequestBasicAuthView } from "./Http/httpBasicAuthView";
import { HttpOAuthView } from "./Http/httpOAuthView";
import { HttpRetryPolicyView } from "./Http/httpRetryPolicyView";
import { ConditionView } from "./Condition/conditionView";
import { ConditionsView } from "./Condition/conditionsView";
import { HttpPollPolicyView } from "./Http/httpPollPolicyView";
import { HttpPollIncrementalTokenLocatorHeadersView } from "./Http/httpPollIncrementalTokenLocatorHeadersView";
import { HttpPollIncrementalTokenLocatorPayloadView } from "./Http/httpPollIncrementalTokenLocatorPayloadView";
import { HttpPollIncrementalTokenLocatorQueryParamView } from "./Http/httpPollIncrementalTokenLocatorQueryParamView";
import { HttpPollIncrementTokenReaderTimeStampView } from "./Http/httpPollIncrementTokenReaderTimestampView";
import { HttpPollIncrementTokenReaderResponseHeaderView } from "./Http/httpPollIncrementTokenReaderResponseHeaderView";
import { HttpPollIncrementTokenReaderResponsePayloadView } from "./Http/httpPollIncrementTokenReaderResponsePayloadView";
import { HttpOAuthClientCredentialsView } from "./Http/httpOAuthClientCredentialsView";
import { AppWebhookConfigurationView } from "./App/appWebhookConfigurationView";


/**
 *  When we navigate to this page, the following things should happen
 *  1. Get (remove ) param item from devstore ( current path )
 *  2. Get query patam from devstore ( param id )
 *  3. Merge path to current data
 */

export const viewFactory = (item: DeveloperItem, path: Path) => {
  switch (item.type) {
    case ResourceType.App:
      return <AppView data={item.item} path={path} />;
    case ResourceType.Action:
      return <ActionView data={item.item} path={path} />;
    case ResourceType.Trigger:
      return <TriggerView data={item.item} path={path} />;
    case ResourceType.Artifact:
      return <ArtifactView data={item.item} path={path} />;
    case ResourceType.AppEdgeDetector:
      return (
        <AppEdgeDetectorView
          data={item.item}
          context={item.context}
          path={path}
        />
      );
    case ResourceType.AppEdgeDetectorConstant:
      return <AppEdgeDetectorConstantView data={item.item} path={path} />;
    case ResourceType.AppEdgeDetectorConfiguration:
      return (
        <AppEdgeDetectorConfigurationView
          data={item.item}
          context={item.context}
          path={path}
        />
      );
    case ResourceType.TriggerProvider:
      return <TriggerProviderView data={item.item} path={path} />;
    case ResourceType.ActionProvider:
      return <ActionProviderView data={item.item} path={path} />;
    case ResourceType.IODataParameter:
      return <IODataView type={IODataUsageType.Parameter} data={item.item} path={path} />;
    case ResourceType.IODataOutput:
      return <IODataView type={IODataUsageType.Output} data={item.item} path={path} />;
    case ResourceType.IODataMapOutput:
      return <IODataMapOutputView data={item.item} path={path} />;
    case ResourceType.IODataArrayOutput:
      return <IODataArrayOutputView data={item.item} path={path} />;
    case ResourceType.ArtifactField:
      return <ArtifactFieldView data={item.item} path={path} />;
    case ResourceType.HttpRequest:
      return (
        <HttpRequestView context={item.context} data={item.item} path={path} />
      );
    case ResourceType.HttpBasicAuth:
      return <HttpRequestBasicAuthView data={item.item} path={path} />;
    case ResourceType.HttpOAuth:
      return <HttpOAuthView data={item.item} path={path} />;
    case ResourceType.HttpOAuthClientCredentials:
      return <HttpOAuthClientCredentialsView data={item.item} path={path} />;
    case ResourceType.HttpRetryPolicy:
      return <HttpRetryPolicyView data={item.item} path={path} />;
    case ResourceType.Condition:
      return <ConditionView data={item.item} path={path} />;
    case ResourceType.Conditions:
      return <ConditionsView data={item.item} path={path} />;
    case ResourceType.HttpPollPolicy:
      return <HttpPollPolicyView data={item.item} path={path} />;
    case ResourceType.HttpPollIncrementalTokenLocatorHeaders:
      return (
        <HttpPollIncrementalTokenLocatorHeadersView
          data={item.item}
          path={path}
        />
      );
    case ResourceType.HttpPollIncrementalTokenLocatorPayload:
      return (
        <HttpPollIncrementalTokenLocatorPayloadView
          data={item.item}
          path={path}
        />
      );
    case ResourceType.HttpPollIncrementalTokenLocatorQuery:
      return (
        <HttpPollIncrementalTokenLocatorQueryParamView
          data={item.item}
          path={path}
        />
      );
    case ResourceType.HttpPollIncrementalTokenReaderTimestamp:
      return (
        <HttpPollIncrementTokenReaderTimeStampView
          data={item.item}
          path={path}
        />
      );
    case ResourceType.HttpPollIncrementalTokenReaderResponseHeader:
      return (
        <HttpPollIncrementTokenReaderResponseHeaderView
          data={item.item}
          path={path}
        />
      );
    case ResourceType.HttpPollIncrementalTokenReaderResponsePayload:
      return (
        <HttpPollIncrementTokenReaderResponsePayloadView
          data={item.item}
          path={path}
        />
      );
    default:
      return (
        <div>
          Unknown type {item.type}
          {JSON.stringify(item)}
        </div>
      );
  }
};
