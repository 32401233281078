import Elk from "elkjs";
import { Position, isEdge, isNode, Edge, Node} from "reactflow";
import { WorkflowNodeType } from "types";

import {Dimensions} from "../constant";


//https://rtsys.informatik.uni-kiel.de/elklive/examples.html
//https://www.eclipse.org/elk/reference/options/  
const elkOptions = {
  //'elk.padding': `[left=12, top=12, right=12, bottom=12]`,
  'elk.algorithm': 'layered',
  'elk.direction': 'DOWN',
  'org.eclipse.elk.layered.layering.strategy': 'INTERACTIVE',
  'org.eclipse.elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF',
  'org.eclipse.elk.layered.nodePlacement.favorStraightEdges': 'true',
  'org.eclipse.elk.edgeRouting': 'ORTHOGONAL',
  'org.eclipse.elk.alg.mrtree.options.EdgeRoutingMode': 'AVOID_OVERLAP',
  'org.eclipse.elk.edge.thickness': '1',
  'elk.layered.spacing.nodeNodeBetweenLayers': '8',
  'org.eclipse.elk.alg.libavoid.enableHyperedgesFromCommonSource': 'true',
  'elk.spacing.nodeNode': '80',
  'elk.spacing.edgeNode': '30',
  'elk.layered.spacing.edgeNodeBetweenLayers': '12',
  'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
  'org.eclipse.elk.layered.separateConnectedComponents': 'false',
  'elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
  //'org.eclipse.elk.core.options.HierarchyHandling': 'INCLUDE_CHILDREN',
};

const elk = new Elk();

export const getLayoutedElements = (elements: any) => {
  const graph = {
    id: 'root',
    layoutOptions: elkOptions,
    children: elements.filter((el: any) => isNode(el)).map((node: any) => {
      const { width, height } = getNodeHeightWidth(node);
      return {
        ...node,
        targetPosition: Position.Top,
        sourcePosition: Position.Bottom,
        properties: {
          'org.eclipse.elk.portConstraints': 'FIXED_ORDER',
        },
        width: width,
        height: height
      };
    }),
    edges: elements.filter((el: any) => isEdge(el))
  };

  return elk
    .layout(graph)
    .then((layoutedGraph) => {
        return elements.map((el: any) => {
          if (isNode(el)) {
            const elkNode = layoutedGraph?.children?.find((n) => n.id === el.id);
            const { x, y } = getElkNodeXYPosition(elkNode);
            if (x && y) {
              el.position = { x: x, y: y }
              el.targetPosition = Position.Top;
              el.sourcePosition = Position.Bottom;
              el.width = elkNode?.width;
              el.height = elkNode?.height;
            }
            return el;
          } else {
            return el;
          }
        });
    })
    .catch(console.error);
};

const getNodeHeightWidth = (el: any) => {
  switch (el.type) {
    case WorkflowNodeType.Dropzone:
      return {
        width: Dimensions.dropzoneNodeActiveWidthHeight,
        height: Dimensions.dropzoneNodeActiveWidthHeight,
      };
    case WorkflowNodeType.End:
      return {
        width: Dimensions.endNodeWidthHeight,
        height: Dimensions.endNodeWidthHeight
      };
    default:
      return {
        width: el.width || Dimensions.nodeWidth,
        height: el.height || Dimensions.nodeHeight,
      };
  }
}

const getElkNodeXYPosition = (node: any) => {
  if (node?.x && node?.y && node?.width && node?.height) {
    switch (node.type) {
      case WorkflowNodeType.Dropzone: {
        return {
          x: node.x - Dimensions.dropzoneNodeXShift,
          y: node.y - Dimensions.dropzoneNodeYShift,
        }
      }
      case WorkflowNodeType.End:
        return {
          x: node.x - Dimensions.endNodePositionXShift,
          y: node.y - Dimensions.endNodePositionYShift,
        }
      default:
        return {
          x: node.x - node.width / 2 + Math.random() / 1000,
          y: node.y - node.height / 2 + Dimensions.nodeYShift,
        }
    }
  }
  return { x: undefined, y: undefined }
}