import { Divider, Form, Input, Spin, Typography } from "antd";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useOrganizationStore } from "store";
import { Subscriber, SubscriberUpdateRequest } from "types";
import { notification } from "utility/notification";

export interface SubscriberEditFieldsProps {
  subscriber?: Subscriber;
  onClose: () => Promise<void>;
}

export const SubscriberEditFields = (props: SubscriberEditFieldsProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [drawerState, setDrawerState] = useState(false);

  const updateSubscriberFields = useOrganizationStore(
    (state) => state.updateSubscriberFields
  );

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }
      if (props.subscriber?.tenantId) {
        const request: SubscriberUpdateRequest = {
          subscriber: props.subscriber?.tenantId,
          name: values.name,
          description: values.description,
        };
        await updateSubscriberFields(request);
      }
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to update subscriber`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const formFields = [
    {
      name: "name",
      label: "Name",
      rules: [{ required: true, message: "Please input organization name!" }],
    },
    {
      name: "description",
      label: "Description",
      rules: [
        { required: true, message: "Please input organization description!" },
      ],
    },
  ];

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setDrawerState(true);
      try {
        setLoading(true);
        if (props.subscriber) {
          const fields = {
            name: props.subscriber.name,
            description: props.subscriber.description,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [props.subscriber]);

  const onClose = async () => {
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Subscriber"
      onClose={onClose}
      open={drawerState}
      subtitle="Update subscriber details"
      onSubmit={handleSubmit}
    >
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ span: 8 }}>
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              <Input />
            </Form.Item>
          ))}
        </Form>
        <Divider />
      </Spin>
    </Drawer>
  );
};
