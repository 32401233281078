import { create } from "zustand"
import { devtools, persist } from "zustand/middleware";

import { UserInfoStore, UserInfo } from "types";
import {
    getUserApi,
    getDownloadUrlApi,
} from "api";

import { companyIcons } from "assets/icons";

export const useUserInfoStore = create<UserInfoStore>()(
  devtools(persist(
    (set, get) => ({
      userInfos: {} as Record<string, UserInfo>, 

      loadUserInfos: async (userIds: string[]) => {
        try {
          const uniqueUserIdSet = new Set(userIds);
          const uniqueUserIds = [...uniqueUserIdSet];
          Promise.allSettled(uniqueUserIds.map(async (userId) => {
            //TODO - for all system users, display hypredge system image, Need to check who publish activities in backend
            const regEx = new RegExp("^[a-z,0-9,-]{36,36}_system$")
            if (regEx.test(userId)) {
              const userInfo = {
                firstName: "HyprEdge",
                lastName: "System",
                image: "",
                logoUrl: companyIcons.hyprEdgeLogoShortSvg,
                email: "",
              } as UserInfo
              return {userId: userId, userInfo: userInfo};
            } else {
              const response = await getUserApi(userId);
              const userInfo = {
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                image: response.data.image,
                email: response.data.email,
              } as UserInfo;
              if (userInfo.image && userInfo.image != "") {
                userInfo.logoUrl = (await getDownloadUrlApi(userInfo.image)).url;
              }
              if (!userInfo.logoUrl) {
                userInfo.logoUrl = "";
              }
              return {userId: userId, userInfo: userInfo};
            }
          })).then((values) => {
            const userInfos = {} as Record<string, UserInfo>;
            values.map((value) => {
              if(value.status == "fulfilled") {
                userInfos[value.value.userId] = value.value.userInfo;
              }
            });
            set((state) => ({
                ...state,
                userInfos: {...state.userInfos, ...userInfos},
            }));
          });
        } catch (error: any) {
          console.error(error);
        }
      },

    }),
    {
        name: "UserInfoStore", 
        getStorage: () => sessionStorage,
      }),
    { name: "UserInfoStore" })
);
