import { memo, useState, useEffect } from "react";
import { Handle, Position } from "reactflow";
import { useDrop } from "react-dnd";
import { Tooltip, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSettingsStore, useWorkflowStore } from "store";
import { DraggableItem, WorkflowNodeActionType, DraggableSourceType, DroppableItem } from "types";

import { useNodeStyles } from "./styles";
import { Dimensions } from "../constant";
import { SvgIcon } from "components/SvgIcon";

const DropzoneNode = (props: any) => {
  const { token } = theme.useToken();
  const [isHover, setHover] = useState(false);
  const [pasteEnable, setPasteEnable] = useState<boolean>(false);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const commonStyles = useNodeStyles();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));

  const { activeCopyId, activeDragItem, selectedWorkflow, setActiveDropItem } = useWorkflowStore(
    (state) => ({
      activeCopyId: state.activeCopyId,
      activeDragItem: state.activeDragItem as DraggableItem,
      setActiveDropItem: state.setActiveDropItem,
      selectedWorkflow: state.selectedWorkflow,
    })
  );
  const isDropCompatible = (dragItem: DraggableItem) => {
    if (dragItem && dragItem.notCompatibleDroppables) {
      if (dragItem.notCompatibleDroppables.find((x) => x == props.id)) {
        return false;
      }
    }
    return true;
  }

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: [DraggableSourceType.Sidebar, DraggableSourceType.Editor],
      canDrop: () => {
        if (!props.data.editMode || !isDropCompatible(activeDragItem) ||  props.data.hidden) {
          return false;
        }
        return true;
      },
      drop: (item: DraggableItem) => {
        const di  = {
          id: item.source == DraggableSourceType.Sidebar ? undefined: item.id, 
          nodeType: item.nodeType,
          resourceId: item.resourceId,
          resourceType: item.resourceType,
          source:  item.source,
          targetId: props.id,
        } as DroppableItem
        setActiveDropItem(di);
      },
      collect: (monitor) => ({
        isOver:  monitor.isOver() &&  activeDragItem && isDropCompatible(activeDragItem),
        canDrop: monitor.isOver() && activeDragItem && monitor.canDrop(),
      }),
    }),
    [activeDragItem]
  );
  
  useEffect(() => {
    if (activeCopyId) {
      setTooltipTitle(
        "paste step '" + selectedWorkflow.steps[activeCopyId]?.name + "'"
      );
      setPasteEnable(true);
    } else {
      setPasteEnable(false);
      setTooltipTitle("");
    }
  }, [activeCopyId]);

  const MouseOver = () => {
    activeCopyId && selectedWorkflow.steps[activeCopyId] && setHover(true);
  };

  const MouseOut = () => {
    setHover(false);
  };

  useEffect(() => {
    isOver || (isHover && pasteEnable)
      ? setDropzoneActive(true)
      : setDropzoneActive(false);
  }, [isOver, isHover]);

  const onNodeClick = () => {
    isHover &&
      pasteEnable &&
      props.data.actionCallback &&
      props.data.actionCallback(
        WorkflowNodeActionType.Paste,
        props.id,
        activeCopyId
      );
  };

  return (
    <div
      key={props.id}
      ref={dropRef}
      className={commonStyles.dropzoneNode}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "none",
        position: "relative",
      }}
    >
      <div
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        onClick={(event: any) => {
          event.stopPropagation();
          onNodeClick();
        }}
        style={{
          width: (isOver  || dropzoneActive )? Dimensions.dropzoneNodeActiveWidthHeight : Dimensions.dropzoneNodeVisibleWidthHeight,
          height: (isOver  || dropzoneActive )? Dimensions.dropzoneNodeActiveWidthHeight : Dimensions.dropzoneNodeVisibleWidthHeight,
          cursor: "none",
          position: "relative",
          backgroundColor: props.data.hidden ? ""
            : dropzoneActive
              ? lightMode ? token.colorPrimary : "#DED7D7"
              : lightMode ? token.colorBorder : token.colorBorderSecondary,
          borderRadius: Dimensions.dropzoneNodeVisibleWidthHeight,
        }}
      >
        {((isHover && pasteEnable)  || isOver) && (
          <Tooltip
            placement="right"
            title={tooltipTitle}
            overlayInnerStyle={{ maxWidth: "400px", textOverflow: "ellipsis" }}
          >
            <PlusOutlined
              style={{
                color: token.colorText,
                margin: "3px",
                zIndex: "3",
              }}
              size={5}
            />
          </Tooltip>
        )}
        <Handle
      className={commonStyles.nodeTopHandle}
      type="target"
      id="b"
      position={Position.Top}
    />
    <Handle
      className={commonStyles.nodeBottomHandle}
      type="source"
      id="a"
      position={Position.Bottom}
    />
      </div>
      {props.data.dropzoneNodeType == "join" &&
        <Handle
          className={commonStyles.nodeLeftHandle}
          type="target"
          id="b1"
          position={Position.Left}
        />
      }
      {props.data.dropzoneNodeType == "join" &&
        <Handle
          className={commonStyles.nodeRightHandle}
          type="target"
          id="b2"
          position={Position.Right}
        />
      }
      
    </div>
  );
};

export default memo(DropzoneNode);
