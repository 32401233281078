import { DataType } from "@textea/json-viewer";
import { FilterValue, SorterResult } from "antd/es/table/interface";

export const convertToApiFilters = (filters?: Record<string, FilterValue | null>, searches?: Map<string, string>): string[] =>{
    const wsFilters = [] as string[];
    if (filters) {
        Object.entries(filters).map(([field, values]) => {
            const valuesArr = values?.map((v) => `'${v}'`);
            const modifiedValues = valuesArr?.join(",");
            if (modifiedValues) {
                wsFilters.push("contains("+field+","+modifiedValues+")");
            }
        });
    }

    if (searches) {
      searches.forEach((value, name) => {
        wsFilters.push("contains(" + name + ",'"+value+"')");
      }); 
    }

    return wsFilters;
}

export const convertToApiSorters = (sorters?: SorterResult<DataType> | SorterResult<DataType>[]): string[] =>{
    const wsSorters = [] as string[];
    if (sorters) {
      const sortersMap = new Map<string, string>();
      if (Array.isArray(sorters)) {
        sorters.map((sort) => {
          const field = sort.field?.toString();
          if (field && sort.order) {
            sortersMap.set(field, sort.order);
          }
        });
      } else {
        const field = sorters.field?.toString();
        if (field && sorters.order) {
          sortersMap.set(field, sorters.order);
        }
      }
      sortersMap.forEach((order, field) => {
        if (order == 'descend') {
          field = '-'+field;
        } 
        wsSorters.push(field);
      })
    }
    return wsSorters;
}