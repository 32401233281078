import { FC, useState, useEffect, useReducer } from "react";
import { Form, Button, Space, FormInstance, theme } from "antd";
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { WorkflowCardOutputsProps, WorkflowIODatas, IODataType, TriggerType } from "types"
import { useWorkflowStore } from "store";
import Modal from "../../../Modal";
import CollapsePanel from "../../../CollapsePanel";
import WorkflowCardOutput from "./Output"
import { validateFormFields } from "utility";
import { 
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
} from "components/Suggestions";
import { DefaultOptionType } from "antd/es/select";

interface DeleteOutputProps {
  enable: boolean;
  key?: number;
  name?: string;
}

const WorkflowCardOutputs: FC<WorkflowCardOutputsProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentOutputs, setCurrentOutputs] = useState<WorkflowIODatas>(props.outputs);
  const [deleteOutputProps, setDeleteOutputProps] = useState<DeleteOutputProps>({enable: false});
  const [outputSuggestionsTree, setOutputSuggestionsTree] = useState<[]>([]);

  const [outputsForms, dispatch] = useReducer((outputsForms: FormInstance[], action: any) => {
    switch (action.type) {
      case 'add': {
        const forms = [...outputsForms];
        forms.splice(action.key, 0, action.form);
        return forms;
      }
      case 'delete': {
        const forms = [...outputsForms];
        forms.splice(action.key, 1);
        return forms;
      }
      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  }, []);

  const {
    selectedWorkflow,
  } = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
    }),
  );

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const buildOutputSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && 
        buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, "", suggestionsTree);
      setOutputSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    buildOutputSuggestions();
  }, [selectedWorkflow]);

  const onValuesChange = async (changedValues: any, values: any) => {
    setCurrentOutputs(form.getFieldValue("outputs"));
    props.onChange?.(form.getFieldValue("outputs"));
  };

  const onOutputAdd = () => {
    let outputs = currentOutputs ? currentOutputs.map((_, index) =>  form.getFieldValue(["outputs", index])) : [] ;
    outputs = [...outputs, {name: `Output_${outputs.length}`, description: "", type: IODataType.String, value: ""}];
    form.setFields([{name:["outputs"], value: outputs}]);
    setCurrentOutputs(outputs);
  };

  const onOutputDelete = () => {
    if (deleteOutputProps.key != undefined) {
      const outputs = currentOutputs.map((_, index) =>  form.getFieldValue(["outputs", index]));
      outputs.splice(deleteOutputProps.key, 1);
      dispatch({type: "delete", key: deleteOutputProps.key});
      form.resetFields(["outputs", deleteOutputProps.key]);
      form.setFields([{name:["outputs"], value: outputs}]);
      setCurrentOutputs(outputs);
      setDeleteOutputProps({enable: false});
    }
  };

  useEffect(() => {
    props.onChange?.(currentOutputs);
  }, [currentOutputs, deleteOutputProps])

  return (
    <>
      <Form 
        form={form} 
        name="outputsForm" 
        autoComplete="off" 
        layout="vertical"
        initialValues={{outputs: props.outputs}}
        onValuesChange={onValuesChange}>
          <Form.List 
            name="outputs">
            {(fields) => (
              <Space direction="vertical" style={{ display: 'flex' }}>
                {fields.map((field) => (
                  <CollapsePanel 
                    key={field.key}
                    name={currentOutputs[field.key].name}
                    collapsePanel={false}
                    extraElement={
                      props.editMode && 
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        onClick={(e: any) => {e.stopPropagation(); setDeleteOutputProps({enable: true,  key: field.key, name: currentOutputs[field.key].name})}}
                      />
                    }
                  > 
                    <Form.Item 
                      {...field} 
                      key={field.key} 
                      name={[field.key]}
                      rules={[
                        { validator: (_, value) =>  validateFormFields(outputsForms[field.key]) },
                      ]}>
                        <WorkflowCardOutput
                          editMode={props.editMode}
                          output={currentOutputs[field.key]}
                          outputSuggestionsTree={outputSuggestionsTree}
                          onRender={form => dispatch({type: "add", key: field.key, form: form})}/>
                    </Form.Item>
                  </CollapsePanel>
                ))}
                {props.editMode && 
                  <div style={{width : "50%", justifyContent: "center", alignItems: "center", margin: "auto"}}>
                    <Button
                      type="default" 
                      style={{ background: token.colorPrimaryBg }} 
                      onClick={onOutputAdd} 
                      block 
                      icon={<PlusOutlined />}
                    >
                      Output
                    </Button>
                  </div>
                }
              </Space>
            )}
          </Form.List>
      </Form>
      { deleteOutputProps.enable && 
        (<Modal
            title="Delete Output"
            onClose={() => {setDeleteOutputProps({enable: false})}}
            open={deleteOutputProps.enable}
            onSubmit={() => onOutputDelete()}
          >
            {"Are you sure you want to delete workflow Output \"" + `${deleteOutputProps?.name}` + "\" ?"}
          </Modal>
        )
      }
    </>
  );
};
  
export default WorkflowCardOutputs;

