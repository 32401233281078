import { FC, useEffect, useState } from "react";
import { Form, Input, Switch, FormInstance, Space, theme } from "antd";

const { TextArea } = Input;

import { validateFormFields } from "utility";
import { NodeCardRuleProps, Rule } from "types";
import ConditionsForm from "components/ConditionsForm";
import { FieldLabel } from "components/FieldLabel";

const NodeCardRule: FC<NodeCardRuleProps> = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [conditionsForm, setConditionsForm] = useState<
    undefined | FormInstance
  >(undefined);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    values["status"] =
      values["status"] != undefined && values["status"]
        ? "enabled"
        : "disabled";
    props.onChange?.(values);
  };

  return (
    <Form
      form={form}
      name="ruleForm"
      layout="vertical"
      autoComplete="off"
      initialValues={props.rule}
      onValuesChange={onValuesChange}
    >
      <Space direction="vertical" size={token.sizeXXS} style={{ display: "flex" }}>
        <Form.Item
          name="name"
          label={<FieldLabel label={"Name"} help={"Unique name for rule"} />}
          rules={[{ required: true, message: "Rule name is required!" }]}
        >
          <Input disabled={!props.editMode} />
        </Form.Item>
        <Form.Item 
          name="desc" 
          label={<FieldLabel label={"Description"} help={"Description of rule"} />}
        >
          <TextArea disabled={!props.editMode} showCount maxLength={100} />
        </Form.Item>
        <Form.Item 
          name="status"
          label={<FieldLabel label={"Status"} help={"Enable or Disable the rule"} />}
        >
          <Switch
            disabled={!props.editMode}
            checkedChildren="Enable"
            unCheckedChildren="Disable"
            defaultChecked={props.rule?.status == "enabled"}
          />
        </Form.Item>
        <Form.Item
          name="conditions"
          label={<FieldLabel label={"Conditions"} help={"Conditions to match the rule"} />}
          rules={[
            { required: true, message: "Rule conditions are required!" },
            { validator: (_, value) => validateFormFields(conditionsForm) },
          ]}
        >
          <ConditionsForm
            editMode={props.editMode}
            required={true}
            conditions={props.rule?.conditions}
            factSuggestionsTree={props.factSuggestionsTree}
            onRender={(form) => setConditionsForm(form)}
          />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default NodeCardRule;
