import { DownOutlined, RightOutlined } from "@ant-design/icons";
import Dagre from "@dagrejs/dagre";
import { Switch } from "antd";
import { useCallback, useEffect, useState } from "react";

import ReactFlow, {
  Background,
  BackgroundVariant,
  Edge,
  Node,
  NodeTypes,
  Panel,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from "reactflow";

import "reactflow/dist/style.css";
import { TreeNode } from "utility/msp";
import { TreeDisplayData, TreeDisplayNode } from "./TreeDisplayNode";
import { useOrganizationStore } from "store";

const dagreGraph = new Dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 150;
const nodeHeight = 36;

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = "TB"
) => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction, marginx: 150, marginy: 100 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: nodeHeight,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  Dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

interface LayoutFlowProps {
  nodes: Node[];
  edges: Edge[];
  nodeTypes: NodeTypes;
  onEdit: (subscriberId: string) => Promise<void>;
  onDelete: (subscriberId: string) => Promise<void>;
}

const LayoutFlow = (props: LayoutFlowProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);
  const treeDirection = useOrganizationStore((state) => state.treeDirection);
  const setTreeDirection = useOrganizationStore(
    (state) => state.setTreeDirection
  );

  const onLayout = useCallback(
    (direction: string) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      props.nodes,
      props.edges,
      treeDirection
    );
    console.log("Layouted nodes", layoutedNodes);
    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [props.nodes, props.edges]);

  return (
    <div id = "subscribers-renderflow" style={{ flex: 1, height: "calc(100vh - 135px)", width: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={props.nodeTypes}
        zoomOnPinch={false}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnDrag={true}
        style={{ cursor: "pointer" }}
        snapToGrid={true}

        // fitView
        // fitViewOptions={{
        //   maxZoom: 1,
        //   minZoom: 1,
        //   includeHiddenNodes: true,
        // }}
      >
        <Background variant={BackgroundVariant.Dots} size={1} gap={24} style={{opacity: .7}}/>
        <Panel position="top-right">
          <Switch
            checkedChildren={<RightOutlined />}
            unCheckedChildren={<DownOutlined />}
            checked={treeDirection === "LR"}
            onChange={(checked) => {
              onLayout(checked ? "LR" : "TB");
              setTreeDirection(checked ? "LR" : "TB");
            }}
          ></Switch>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export interface TreeViewProperties {
  data: TreeNode[];
  onEdit: (subscriberId: string) => Promise<void>;
  onDelete: (subscriberId: string) => Promise<void>;
}

/** Implement a dfs traversal of TreeNode */

export const TreeView = (props: TreeViewProperties) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodeTypes, setNodeTypes] = useState<NodeTypes>({});
  useEffect(() => {
    const initialNodes: Node[] = [];
    const initialEdges: Edge[] = [];
    const nodeTypes = { treeItem: TreeDisplayNode };
    const dfs = (node: TreeNode, callback: (node: TreeNode) => void) => {
      callback(node);
      node.children.forEach((child) => dfs(child, callback));
    };
    props.data.forEach((root) => {
      dfs(root, (node) => {
        const tdd: TreeDisplayData = {
          id: node.id,
          name: node.attributes.name,
          description: node.attributes.description,
          owner: node.attributes.owner,
          isLeafNode: node.children?.length == 0,
          onEdit: props.onEdit,
          onDelete: props.onDelete,
        };
        const n = {
          id: node.id,
          data: tdd,
          type: "treeItem",
          position: { x: 0, y: 0 },
        };

        initialNodes.push(n);
        node.children.forEach((child) => {
          initialEdges.push({
            id: `${node.id}-${child.id}`,
            source: node.id,
            target: child.id,
          });
        });
      });
    });

    const { nodes, edges } = getLayoutedElements(initialNodes, initialEdges);
    setNodes(nodes);
    setEdges(edges);
    setNodeTypes(nodeTypes);
  }, [props.data]);

  return (
    <>
      {nodes.length > 0 && (
        <ReactFlowProvider>
          <LayoutFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
          />
        </ReactFlowProvider>
      )}
    </>
  );
};
