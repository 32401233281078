import { FC, useState, useEffect } from "react";
import { Spin } from "antd";
import { Search, SearchTabType } from "types";
import { useOrganizationStore, useSearchStore, useUserInfoStore } from "store";
import { SearchTabProps } from "types/uiprops";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const SearchTab: FC<SearchTabProps> = ({
  searchTabType,
  searchPageInfo,
  headerPrefix,
  searchItemColumns,
  searchItemsDelete,
}) => {
  const [loader, setLoader] = useState(false);
  const context = useOrganizationStore((state) => state.context);
  const loadUserInfos = useUserInfoStore((state) => state.loadUserInfos);

  const { 
    searchItemsMap, 
    getSearchItems,
    searchPage, 
    setCurrentPage,
    searchFilters,
    setSearchFilters,
    searchSorters,
    setSearchSorters,
    searchSearchTextMap,
    setSearchSearchTextMap,
  } = useSearchStore((state) => ({
    searchItemsMap: state.searchItemsMap,
    getSearchItems: state.getSearchItems,
    searchPage: state.searchPage,
    setCurrentPage: state.setCurrentPage,
    searchFilters: state.searchFilters[searchTabType],
    setSearchFilters: state.setSearchFilters,
    searchSorters: state.searchSorters[searchTabType],
    setSearchSorters: state.setSearchSorters,
    searchSearchTextMap: state.searchSearchTextMap,
    setSearchSearchTextMap: state.setSearchSearchTextMap
  }));

  useEffect(() => {
    const asyncUseEffect = async () => {  
      setLoader(true);
      await getSearchItems(searchTabType, searchPageInfo.number, searchPageInfo.size)
      setLoader(false);
    };
    asyncUseEffect();
  }, [searchSearchTextMap, searchFilters, searchSorters, context]);

  const onPageChange = (pageNumber : number, pageSize : number)  => {
    setLoader(true);
    setCurrentPage(searchTabType, pageNumber, pageSize)
    getSearchItems(searchTabType, pageNumber, pageSize).then( () => {
      setLoader(false);
    });
  }

  //Fetch user info for all users linked to workflows
  useEffect(() => {
    if (searchTabType == SearchTabType.SavedSearches) {
      try {
        const userIds = [] as string[];
        searchItemsMap?.get?.(searchTabType)?.forEach((search) => {
          search.userID && search.userID != "" && userIds.push(search.userID);
        });
        userIds.length > 0 && loadUserInfos(userIds);
      } catch (error) {
        console.log(error);
      }
    }
  }, [searchItemsMap]);

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setSearchSorters(searchTabType, sorter);
    setSearchFilters(searchTabType, filters);
    setCurrentPage(searchTabType);
  }

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header={headerPrefix}
        dataSource={searchItemsMap?.get?.(searchTabType) as any[]}
        columns={searchItemColumns}
        //enableDelete={searchItemsDelete ? true : false}
        pageNumber={searchPage[searchTabType].number}
        pageSize={searchPage[searchTabType].size}
        total={searchPage[searchTabType].total}
        onChange={onChange}
        onSearch={searchTabType == SearchTabType.SavedSearches 
          ? 
          (text: string) => {
            setCurrentPage(searchTabType);
            const searchTextMap = searchSearchTextMap[searchTabType];
            if(searchTextMap) {
              searchTextMap.set("name", text);
              setSearchSearchTextMap(searchTabType, searchTextMap);
            }
          }
          : undefined
        }
        searchPlaceholder={searchTabType == SearchTabType.SavedSearches  ? "search searches by name" : undefined}
        searchText={searchTabType == SearchTabType.SavedSearches  ? searchSearchTextMap[searchTabType]?.get("name") : undefined}
        onPageChange={onPageChange}
        getItemProps={(record: Search) => {
          return {
            disabled: `${record.tenantID}_tag` != context,
          };
        }}
        actions={searchItemsDelete ?
          [
            {
              key: "delete", 
              label: "Delete", 
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return true;
                } else {
                  return false;
                }
              }, 
              showWarn: true,
              onClick: (itemIds) => {
                searchItemsDelete && searchItemsDelete(itemIds as string[]);
                getSearchItems(searchTabType, searchPageInfo.number, searchPageInfo.size);
              }
            },
          ]: []
        }
      />
    </Spin>
  );
};

export default SearchTab;
