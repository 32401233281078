import { FC, useState, useEffect, useReducer } from "react";

import { Form, Button, Space, FormInstance, theme } from "antd";
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { WorkflowCardParametersProps, WorkflowIODatas, IODataType } from "types"
import Modal from "../../../Modal";
import CollapsePanel from "../../../CollapsePanel";
import WorkflowCardParameter from "./Parameter"
import { validateFormFields } from "utility";

import styles from './Parameters.module.scss';

interface DeleteParameterProps {
  enable: boolean;
  key?: number;
  name?: string;
}

const WorkflowCardParameters: FC<WorkflowCardParametersProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentParameters, setCurrentParameters] = useState<WorkflowIODatas>(props.parameters);
  const [deleteParameterProps, setDeleteParameterProps] = useState<DeleteParameterProps>({enable: false});

  const [parametersForms, dispatch] = useReducer((parametersForms: FormInstance[], action: any) => {
    switch (action.type) {
      case 'add': {
        const forms = [...parametersForms];
        forms.splice(action.key, 0, action.form);
        return forms;
      }
      case 'delete': {
        const forms = [...parametersForms];
        forms.splice(action.key, 1);
        return forms;
      }
      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  }, []);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, values: any) => {
    setCurrentParameters(form.getFieldValue("parameters"));
    props.onChange?.(form.getFieldValue("parameters"));
  };

  const onParameterAdd = () => {
    let parameters = currentParameters ? currentParameters.map((_, index) =>  form.getFieldValue(["parameters", index])) : [] ;
    parameters = [...parameters, {name: `Parameter_${parameters.length}`, description: "", type: IODataType.String, value: ""}];
    form.setFields([{name:["parameters"], value: parameters}]);
    setCurrentParameters(parameters);
  };

  const onParameterDelete = () => {
    if (deleteParameterProps.key != undefined) {
      const parameters = currentParameters.map((_, index) =>  form.getFieldValue(["parameters", index]));
      parameters.splice(deleteParameterProps.key, 1);
      dispatch({type: "delete", key: deleteParameterProps.key});
      form.resetFields(["parameters", deleteParameterProps.key]);
      form.setFields([{name:["parameters"], value: parameters}]);
      setCurrentParameters(parameters);
      setDeleteParameterProps({enable: false});
    }
  };

  useEffect(() => {
    props.onChange?.(currentParameters);
  }, [currentParameters, deleteParameterProps])
 
  return (
    <>
      <Form 
        form={form} 
        name="parametersForm" 
        autoComplete="off" 
        layout="vertical"
        initialValues={{parameters: props.parameters}}
        onValuesChange={onValuesChange}>
          <Form.List 
            name="parameters">
            {(fields) => (
              <Space direction="vertical" style={{ display: 'flex' }}>
                {fields.map((field) => (
                  <CollapsePanel 
                    key={field.key}
                    name={currentParameters[field.key].name}
                    collapsePanel={false}
                    extraElement={
                      props.editMode && 
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        onClick={(e: any) => {e.stopPropagation(); setDeleteParameterProps({enable: true,  key: field.key, name: currentParameters[field.key].name})}}
                      />
                    }
                  > 
                    <Form.Item 
                      {...field} 
                      key={field.key} 
                      name={[field.key]}
                      rules={[
                        { validator: (_, value) =>  validateFormFields(parametersForms[field.key]) },
                      ]}>
                        <WorkflowCardParameter
                          editMode={props.editMode}
                          parameter={currentParameters[field.key]} 
                          onRender={form => dispatch({type: "add", key: field.key, form: form})}/>
                    </Form.Item>
                  </CollapsePanel>
                ))}
                {props.editMode && 
                  <div style={{width : "50%", justifyContent: "center", alignItems: "center", margin: "auto"}}>
                    <Button 
                      type="default" 
                      style={{ background: token.colorPrimaryBg }} 
                      onClick={onParameterAdd} 
                      block 
                      icon={<PlusOutlined />}
                    >
                      Parameter
                    </Button>
                  </div>
                }
              </Space>
            )}
          </Form.List>
      </Form>
      { deleteParameterProps.enable && 
        (<Modal
            title="Delete Parameter"
            onClose={() => {setDeleteParameterProps({enable: false})}}
            open={deleteParameterProps.enable}
            onSubmit={() => onParameterDelete()}
          >
            {"Are you sure you want to delete workflow parameter \"" + `${deleteParameterProps?.name}` + "\" ?"}
          </Modal>
        )
      }
    </>
  );
};
  
export default WorkflowCardParameters;

