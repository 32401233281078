import { WorkflowNodeType } from "types";

export enum OperatorConditionEdgeLabel {
    True = "TRUE",
    False = "FALSE",
    Join = "Join",
}

export enum OperatorApprovalEdgeLabel {
    True = "YES",
    False = "NO",
    Join = "Join",
}


export enum DecisionStepLabel {
    True = "True",
    False = "False",
}

export enum OperatorParallelEdgeLabel {
    Branch = "Branch",
    Top = "Top",
    Join = "Join"
}

export enum OperatorLoopEdgeLabel {
    Top = "Top",
    Join = "Join"
}

export enum DropzoneNodeHint {
    InsideLoopAndBreakCompatible = "InsideLoopAndBreakCompatible"
}

export enum DropzoneNodeType {
    Normal = "normal",
    Join = "join"
}

export interface EdgeData  {
    parentType: string;
    parentId: string;
    hiddenLabel: OperatorParallelEdgeLabel | OperatorLoopEdgeLabel | OperatorConditionEdgeLabel | OperatorApprovalEdgeLabel
}

export interface LabelColors  {
    successBgColor: string;
    errorBgColor: string;
    successColor: string;
    errorColor: string;
}