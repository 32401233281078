import { FC, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Form,
  Input,
  Space,
  Typography,
  theme,
  Row,
  Select,
  InputNumber,
  Modal as AntModal,
  Switch
} from "antd";

import {
  WorkflowStepProvider,
  WorkflowNodeCardProps,
  TriggerType,
  WorkflowAppSubscriptionInfo,
  App,
  AdapterType,
  AppSubscription,
  WorkflowStepOperator,
  TextType,
  ArrayObject,
} from "types";
import { notification } from 'utility/notification';
import { useAppStore, useAppSubscriptionStore, useEdgeGlobalStore, useWorkflowStore } from "store";
import {
  generateReferenceName,
} from "utility/workflow";
import {
  buildWorkflowParameterTree,
  buildTriggerOutputTree,
  buildStepOutputTree,
  SuggestionsInput,
  getRegEx
} from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";
import CollapsePanel from "components/CollapsePanel";
import { SvgIcon } from "components/SvgIcon";
import { commonIcons, workflowIcons } from "assets/icons";
import { OperatorIODatasForm } from "./OperatorUtils";
import { DatabaseType, SqlQueryType, SqlRequest, SqlResponseFormat } from "types/adapter/sql_adapter";
import { FormInstance } from "antd/lib";
import { getUserDetailsFromJWT, validateFormFields } from "utility";
import { runAppActionApi } from "api";
import { JsonEditor } from "components/JsonEditor";
import RichTextEditor from "components/RichTextEditor";

const { Option } = Select;
const { Text } = Typography;

//TODO - get database type and adapter type from app response and assign db operator parameters
// for now hard assigning, adapter type as sql and database type as postgres.
const DBOperatorCard: FC<WorkflowNodeCardProps> = ({
  id,
  workflowId,
  resourceId,
  nodeType,
  editMode,
  onClose
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [outputsLoader, setOutputsLoader] = useState(false);
  const [appLoader, setAppLoader] = useState(false);
  const [form] = Form.useForm();
  const [outputsForm, setOutputsForm] = useState<undefined | FormInstance>(undefined);
  const [currentApp, setCurrentApp] = useState<App>({} as App);
  const [appSubscriptionId, setAppSubscriptionId] = useState("");
  const [parameterSuggestionsTree, setParameterSuggestionsTree] = useState<[]>([]);
  const [dbSuggestionsTree, setDbSuggestionsTree] = useState<[]>([]);
  const [showJsonView, setShowJsonView] = useState<{enable: boolean, data?: any}>({enable: false});
  const [showJsonLoadError, setShowJsonLoadError] = useState<boolean>(false);

  const getEdgeEndpoint = useEdgeGlobalStore((state) => state.getEdgeEndpoint);

  const { selectedWorkflow, updateWorkflow } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    updateWorkflow: state.updateWorkflow,
  }));

  const [referenceName, setReferenceName] = useState<string>(selectedWorkflow?.steps[id]?.referenceName);
  const [description, setDescription] = useState<string>(selectedWorkflow?.steps[id]?.description);
  const [adapterType, setAdapterType] = useState<AdapterType>(AdapterType.Sql);
  const [dbType, setDbType] = useState<DatabaseType>(DatabaseType.PostgresDatabaseType);
  const [dbRequest, setDbRequest] = useState<SqlRequest>({
    queryType: SqlQueryType.Read,
    query: "",
    responseFormat: SqlResponseFormat.Json,
    timeout: 300,
  } as SqlRequest);
  const [dbOutputs, setDbOutputs] = useState<ArrayObject[]>([]);

  const { 
    apps, 
    getApps, 
    resetAppStore, 
    setAppFields,
    setAppFilter,
  } = useAppStore((state: any) => ({
    apps: state.apps,
    getApps: state.getApps,
    resetAppStore: state.resetAppStore,
    setAppFields: state.setAppFields,
    setAppFilter: state.setAppFilter,
  }));

  const {
    appSubscriptions, 
    getAppSubscriptions,
    clearAppSubscriptions
  } = useAppSubscriptionStore((state: any) => ({
    appSubscriptions: state.appSubscriptions,
    getAppSubscriptions: state.getAppSubscriptions,
    clearAppSubscriptions: state.clearAppSubscription
  }));
  
  const loadApps = async () => {
    try {
      setAppLoader(true); 
      setAppFields(["id", "displayName"]);
      setAppFilter({"adapterTypes": [AdapterType.Sql]});
      await getApps(true, true);
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoader(false);
    }
  };

  const syncCurrentState = async () => {
    try {
      const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
      if(operatorStep) {
        console.log("on retriaval db operator", operatorStep);
        if (operatorStep.dbProvider) {
          setCurrentApp({id: operatorStep.dbProvider.appID, displayName: operatorStep.dbProvider.displayName} as App);
          form.setFieldValue("app", operatorStep.dbProvider.displayName);
          setAppSubscriptionId(operatorStep.dbProvider?.appSubscriptionInfos?.[0].appSubscriptionID);
          form.setFieldValue("appSubscription", (operatorStep.dbProvider?.appSubscriptionInfos?.[0].name));
        }
        const parametersValues = operatorStep.parameters;
        if (parametersValues) {
          const req = parametersValues['db_request'] as SqlRequest;
          if (req) {
            setDbRequest(req);
            form.setFieldValue("db_request", req);
          } else {
            form.setFieldValue("db_request", dbRequest);
          }
          const outputs = parametersValues['db_outputs'] as ArrayObject[];
          if (outputs) {
            setDbOutputs(outputs);
            form.setFieldValue("db_outputs", outputs);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildParameterSuggestions = () => {
    if (selectedWorkflow) {
      const suggestionsTree: [] = [];
      buildWorkflowParameterTree(selectedWorkflow.parameters, suggestionsTree);
      selectedWorkflow.triggerRef.triggerType == TriggerType.Custom && buildTriggerOutputTree(selectedWorkflow.triggerRef.triggerID, suggestionsTree);
      buildStepOutputTree(selectedWorkflow, id, suggestionsTree);
      setParameterSuggestionsTree(suggestionsTree);
    }
  };

  useEffect(() => {
    syncCurrentState();
    buildParameterSuggestions();
    return () => {
      resetAppStore();
    };
  }, [selectedWorkflow]);

  useEffect(() => {
    buildDBSuggestions();
  }, [referenceName]);

  const buildDBSuggestions = () =>  {
    const suggestionsTree: any[] = [];
    suggestionsTree.push({
      title: adapterType,
      label: adapterType,
      key: `$.${adapterType}`,
      regex: getRegEx(),
      value: `{{$.${adapterType}`,
      selectable: false,
      children: [
        {
          title: referenceName,
          label: referenceName,
          key: `$.${adapterType}.${referenceName}`,
          regex: getRegEx(`${adapterType}`),
          value: `{{$.${adapterType}.${referenceName}`,
          selectable: false,
          children: [
            {
              title: "response",
              label: "response",
              key: `$.${adapterType}.${referenceName}.response`,
              regex: getRegEx(`${adapterType}`, referenceName),
              value: `{{$.${adapterType}.${referenceName}.response`,
              selectable: true,
              isLeaf: false,
            }
          ]
        }
      ]
    });
    setDbSuggestionsTree(suggestionsTree as []);
  }

  const updateSelectedWorkflow = async () => {
    try {
      setLoader(true);
      await updateWorkflow(selectedWorkflow);
      notification.success({
        message: `Updated workflow ${selectedWorkflow.name} successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Updating workflow ${selectedWorkflow.name} failed`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnFinish = (values: any) => {
    form
      .validateFields()
      .then(() => {
        const operatorStep = selectedWorkflow.steps[id] as WorkflowStepOperator;
        operatorStep.isConfigured = true;
        operatorStep.name = values.name;
        operatorStep.referenceName = referenceName;
        operatorStep.description = values.description;

        operatorStep.parameters = {};
        operatorStep.parameters.db_type = dbType;
        const sqlReq = values.db_request as SqlRequest;
        operatorStep.parameters.db_request = sqlReq;
        operatorStep.parameters.db_outputs = values.db_outputs;
      
        const dbProvider = {} as WorkflowStepProvider;
        dbProvider.appID = currentApp?.id;
        dbProvider.displayName = currentApp?.displayName;

        dbProvider.appSubscriptionInfos = [] as WorkflowAppSubscriptionInfo[];
        dbProvider.appSubscriptionInfos.push({
          appSubscriptionID: appSubscriptionId
        } as WorkflowAppSubscriptionInfo);

        operatorStep.includeRawOutput = values.includeRawOutput;
        operatorStep.dbProvider = dbProvider;
        selectedWorkflow.steps[id] = operatorStep;
        console.log("on save db operator", operatorStep);
        updateSelectedWorkflow();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Form
      form={form}
      name="dbCardForm"
      layout="vertical"
      autoComplete="off"
      onFinish={OnFinish}
    >
      <Spin spinning={loader}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Form.Item
            name="name"
            label={<FieldLabel label={"Name"} help={"Name of action"} />}
            initialValue={selectedWorkflow.steps[id]?.name}
            rules={[
              { required: true, message: "Name is required!" },
            ]}
            extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
          >
            <Input disabled={!editMode} onChange={(e) => setReferenceName(generateReferenceName(e.target.value, selectedWorkflow))}/>
          </Form.Item>
          <Form.Item
            name="description"
            label={<FieldLabel label={"Description"} />}
            initialValue={""}
            validateTrigger="onSubmit"
          >
            <RichTextEditor
              height="100px"
              maxChars={4096}
              editMode={editMode}
              content={description}
              onChange={(value) => {
                setDescription(value);
                form.setFieldValue("description", value);
              }}
            />
          </Form.Item>
          <CollapsePanel
            name={
              <Space  size={token.marginXXS}>
                <SvgIcon component={workflowIcons.appsShortIcon} />
                <div>Apps</div>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <Form.Item
              name="app"
              label={<FieldLabel label={"App"} help={"Select app which is configured for db"}/>}
              rules={[
                { required: true, message: "App should be selected!" },
              ]}
            >
              <Select
                showSearch
                disabled={!editMode}
                notFoundContent={appLoader ? <Spin size="small"/> : null}
                showAction={["focus", "click"]}
                placeholder="Select App..."
                value={currentApp?.id}
                filterOption={(input, option) => {
                  const label = option?.label as string;
                  return (label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                onSelect={(_, option) => {
                  setCurrentApp({id: option.value, displayName: option.label} as App);
                  option.value && option.value != "" && getAppSubscriptions(option.value, -1, -1);
                  form.setFieldValue("appSubscription", "")
                }}
                onClick={() => loadApps()}
                options={apps.map((a: App) => ({
                    label: a.displayName,
                    value: a.id,
                  }))
                }
              />
            </Form.Item>
            <Form.Item
              name="appSubscription"
              label={<FieldLabel label={"App Subscription"} help={"App subscription"}/>}
              rules={[
                { required: true, message: "App Subscription should be selected!" },
              ]}
            >
              <Select
                showSearch
                disabled={!editMode}
                showAction={["focus", "click"]}
                placeholder="Select App Subscription..."
                value={appSubscriptionId}
                filterOption={(input, option) => {
                  const label = option?.label as string;
                  return (label ?? '').toLowerCase().includes(input.toLowerCase())
                }}
                onSelect={(id) => setAppSubscriptionId(id)}
                onClick={() => currentApp?.id  ? getAppSubscriptions(currentApp.id, -1, -1) : clearAppSubscriptions()}
                options={appSubscriptions
                  .map((as: AppSubscription) => ({
                    label: as.name,
                    value: as.id,
                  }))}
              />
            </Form.Item>
          </CollapsePanel>
          <CollapsePanel 
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false} 
            collapsePanel={false}
          >
            <Form.Item
              name={['db_request', 'queryType']}
              label={<FieldLabel label={"Query Type"} help={"Query Type, supported read only"} />}
              rules={[{ required: true, message: "Query Type is required!" }]}
            >
              <Select
                disabled={!editMode}
                defaultValue={SqlQueryType.Read}
                options={[
                  { value: SqlQueryType.Read, label: "Read" },
                  //{ value: SqlQueryType.Write, label: "Write" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['db_request', 'query']}
              label={<FieldLabel label={"Query"} help={"Database query, supports read only queries"} />}
            >
              <SuggestionsInput
                editMode={editMode}
                name={"payload"}
                textType={TextType.Text}
                suggestionsTree={parameterSuggestionsTree}
              />
            </Form.Item>
            <Form.Item
              name={['db_request', 'responseFormat']}
              label={<FieldLabel label={"Response Format"} help={"Db response format"} />}
              rules={[{ required: true, message: "Response Format is required!" }]}
            >
              <Select
                disabled={!editMode}
                defaultValue={SqlResponseFormat.Json}
                options={[
                  { value: SqlResponseFormat.Json, label: "JSON" },
                  //{ value: SqlResponseFormat.Text, label: "TEXT" },
                  //{ value: SqlResponseFormat.Csv, label: "CSV" },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={['db_request', 'timeout']}
              label={<FieldLabel label={"Timeout"} help={"DB response timeout in seconds"} />}
              rules={[{ required: true, message: "Timeout is required!" }]}
            >
              <InputNumber disabled={!editMode}/>
            </Form.Item>
            <Form.Item
              name={"db_outputs"}
              label={
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <FieldLabel label={"Outputs"} help={"DB outputs"} />
                  <Button 
                    size="small"
                    type="primary"
                    loading={outputsLoader}
                    onClick={() => {
                        form
                        .validateFields()
                        .then(async () => {
                          try {
                              setOutputsLoader(true);
                              const tenantId = getUserDetailsFromJWT()?.tenantId;
                              const appSubscription = appSubscriptions?.find((as: AppSubscription) => as.id == appSubscriptionId) as AppSubscription;
                              if (appSubscription) {
                                const edgeEndpoint = await getEdgeEndpoint(appSubscription.edgeID);
                                const sqlReq = form.getFieldValue('db_request');
                                const payload = {
                                  sqlName: referenceName,
                                  sqlRequest: sqlReq
                                }
                                const data = await runAppActionApi(edgeEndpoint, tenantId, appSubscriptionId, AdapterType.Sql, payload)
                                if (data) {
                                  setShowJsonView({enable: true, data: data})
                                } else {
                                  setShowJsonView({enable: true, data: {}});
                                } 
                              }
                            } catch (error) {
                              console.log(error);
                              setShowJsonLoadError(true);
                            } finally {
                              setOutputsLoader(false);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                    }}
                  >
                    Test Run
                  </Button>
                </Space>
              }
              rules={[
                { validator: (_, value) =>  validateFormFields(outputsForm) },
              ]}
            >
              <OperatorIODatasForm 
                editMode={editMode} 
                isOutput={true}
                iodatas={dbOutputs} 
                suggestionsTree={dbSuggestionsTree}
                onRender={form => setOutputsForm(form)}
              />
            </Form.Item>
            <Form.Item
              name="includeRawOutput"
              label={<FieldLabel label={"Include Raw Output"} help={"Store step raw output to access in following steps"} />}
              initialValue={selectedWorkflow.steps[id]?.includeRawOutput}
            >
              <Switch
                disabled={!editMode}
                checkedChildren="Yes"
                unCheckedChildren="No"
                defaultChecked={false}
              />
            </Form.Item>
          </CollapsePanel>
        </Space>
      </Spin>
      {editMode && (
        <Row justify="space-between" style={{ marginTop: token.margin }}>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            key="save"
            type="primary" 
            htmlType="submit"
            size="middle"
          >
            Save
          </Button>
        </Row>
      )}
      {showJsonView.enable && 
        <JsonEditor
          open={showJsonView.enable}
          width={800}
          title={"Sql Outputs"}
          editMode={false}
          data={showJsonView.data}
          icon={commonIcons.databaseIcon}
          onClose={() => setShowJsonView({enable: false})}
        />
      }
      {showJsonLoadError && 
        <AntModal
          title="Error"
          onCancel={() => setShowJsonLoadError(false)}
          open={showJsonLoadError}
          footer={[
            <Button 
              key="ok" 
              type="primary" 
              onClick={() => setShowJsonLoadError(false)}
            >
              OK
            </Button>
          ]}
        >
          {"Failed to fetch sql outputs"}
        </AntModal>
      }
    </Form>
  );
};

export default DBOperatorCard;
