import { FC, memo, useState, ReactNode } from "react";
import { Tooltip, Card, theme } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

export type ControlButtonProps = {
  displayName: string;
  svgComponent: ReactNode;
  hoverable: boolean;
  placement?: TooltipPlacement;
};

const ControlButton: FC<ControlButtonProps> = ({
  displayName,
  svgComponent,
  hoverable,
  placement,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);

  return (
    <Tooltip
      color={token.colorPrimary}
      title={displayName}
      placement={placement ? placement : "top"}
    >
      <Card.Grid
        style={{
          cursor: "default",
          height: "20px",
          width: "20px",
          display: "flex",
          padding: token.padding,
          borderRadius: token.borderRadius,
          justifyContent: "center",
          marginBottom: "1px",
          backgroundColor: hover && hoverable ? token.colorPrimaryBgHover : "",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {svgComponent}
      </Card.Grid>
    </Tooltip>
  );
};

export default memo(ControlButton);
