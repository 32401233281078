import { theme, Typography } from "antd";
import { createUseStyles } from "react-jss";

import { Metric, MetricLayout } from "types";
import { MetricDisplay } from "./MetricDisplay";

const { Text } = Typography;

export interface KpiDashboardPieProps {
  layout?: MetricLayout;
  metrics?: Metric[];
}

export const KpiDashboardPie = (props: KpiDashboardPieProps) => {
  const { token } = theme.useToken();
  const useStyles = createUseStyles({
    container: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      paddingTop: "15px",
      paddingLeft: "10px",
      justifyContent: "space-between",
    },
  });

  const classes = useStyles();
  return (
    <div id="dashboard-pie-container" className={classes.container}>
      <Text
        style={{
          fontSize: token.fontSizeHeading5,
          fontWeight: "bold",
        }}
      >
        {props.layout?.displayName}
      </Text>
      <div
        id={"dashboard-pie-content"}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        {props.metrics?.map((metric: Metric) => (
          <MetricDisplay
            key={metric.id}
            metric={metric}
            stretchMiniTiles={false}
          />
        ))}
      </div>
    </div>
  );
};
