import { axiosInterceptor } from "./axios";
import { contentApiBasePath } from "./constant";
import { Content } from "../types";

export const getUploadUrlApi = async (id: string, mime: string, restricted : boolean ) : Promise<Content>=> {
  console.log(`getUploadUrlApi id: ${id}, mime: ${mime}, restricted: ${restricted}`);
  const response = await axiosInterceptor("post",`${contentApiBasePath}/objectstore/upload`, {
    id,
    mime,
    restricted,
  });
  return response.data
};

export const getDownloadUrlApi = async (id: string): Promise<Content> => {
  const response = await axiosInterceptor("get",`${contentApiBasePath}/objectstore/download/${id}`);
  return response.data;
}

export const changeContentAccessApi = async (id: string, restricted : boolean ) : Promise<void>=> {
  console.log(`changeContentAccessApi id: ${id}, restricted: ${restricted}`);
  await axiosInterceptor("patch",`${contentApiBasePath}/objectstore/upload/${id}?restricted=${restricted}`);
  return;
}
