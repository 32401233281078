import { sha256 } from "js-sha256";
import { create } from "zustand";

import {
  emailVerifyCompleteApi,
  emailVerifyStartApi,
  getProfileApi,
  getProfileSecureDataApi,
  passwordVerifyCompleteApi,
  passwordVerifyStartApi,
  phoneVerifyCompleteApi,
  phoneVerifyStartApi,
  removeEmailApi,
  removePhoneApi,
  updatePrimaryEmailApi,
  updatePrimaryPhoneApi,
  updateProfileApi,
} from "api";
import { ProfileStore } from "types";


export const useProfileStore = create<ProfileStore>(
    (set, get) => ({
      // initialize the store
      loading: false,
      profile: {},
      profileSecureData: {},
      beforeVerifyPhone: "",
      beforeVerifyEmail: "",
      challenge: "",

      setLoading: async (loading) => {
        set((state) => ({...state, loading}));
      },
      
      getProfile: async () => {
        try {
          get().setLoading(true);
          const response = await getProfileApi();
          set((state) => ({
            ...state,
            profile: {
              business: response.data.business ,
              email: response.data.email,
              phone: response.data.phone,
              title: response.data.title,
              region: response.data.region,
              image: response.data.image,
              fullImage: response.data.fullImage,
              firstName: response.data.first_name,
              lastName: response.data.last_name,
              locale: response.data.locale,
            },
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }finally {
          get().setLoading(false);
        }
      },

      updateProfile: async (updateProfileData) => {
        try {
          const response = await updateProfileApi(updateProfileData);

          set((state) => ({
            ...state,
            profile: {
              ...state.profile,
              business: response.data.business,
              email: response.data.email,
              phone: response.data.phone,
              title: response.data.title,
              region: response.data.region,
              image: response.data.image,
              fullImage: response.data.fullImage,
              firstName: response.data.first_name,
              lastName: response.data.last_name,
              locale: response.data.locale,
            },
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      getProfileSecureData: async () => {
        try {
          const response = await getProfileSecureDataApi()
          set((state) => ({
            ...state,
            profileSecureData: {
              emails: response?.data?.emails,
              phones: response?.data?.phones,
              primaryEmailIndex: response?.data?.primaryEmailIndex,
              primaryPhoneIndex: response?.data?.primaryPhoneIndex,
            }
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      phoneVerifyStart: async (
        value: string,
        challenge: string
      ) => {
        try {
          await phoneVerifyStartApi(value, challenge);
          set(() => ({
            beforeVerifyPhone: value,
            challenge: challenge,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      phoneVerifyComplete: async (otp: string) => {
        try {
          const verifier = sha256(String(get().challenge));
          const response = await phoneVerifyCompleteApi(verifier, otp);

          set((state) => ({
            ...state,
            profileSecureData: {
              emails: response?.data?.emails,
              phones: response?.data?.phones,
              primaryEmailIndex: response?.data?.primaryEmailIndex,
              primaryPhoneIndex: response?.data?.primaryPhoneIndex,
            }
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      emailVerifyStart: async (
        value: string,
        challenge: string
      ) => {
        try {
          await emailVerifyStartApi(value, challenge);
          set(() => ({
            beforeVerifyEmail: value,
            challenge: challenge,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      emailVerifyComplete: async (otp: string) => {
        try {
          const verifier = sha256(String(get().challenge));
          const response = await emailVerifyCompleteApi(verifier, otp);

          set((state) => ({
            ...state,
            profileSecureData: {
              emails: response?.data?.emails,
              phones: response?.data?.phones,
              primaryEmailIndex: response?.data?.primaryEmailIndex,
              primaryPhoneIndex: response?.data?.primaryPhoneIndex,
            }
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      passwordVerifyStart: async (
        challenge: string
      ) => {
        const value = ""
        try {
          await passwordVerifyStartApi(value, challenge);
          set(() => ({
            challenge: challenge,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      passwordVerifyComplete: async (otp: string) => {
        try {
          const verifier = sha256(String(get().challenge));
          await passwordVerifyCompleteApi(verifier, otp);

        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      updatePrimaryPhone: async (value) => {
        try {
          const response = await updatePrimaryPhoneApi(value);
          set((state) => ({
            ...state,
            profileSecureData: {
              emails: response?.data?.emails,
              phones: response?.data?.phones,
              primaryEmailIndex: response?.data?.primaryEmailIndex,
              primaryPhoneIndex: response?.data?.primaryPhoneIndex,
            }
          }));          
          get().getProfile();          
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      updatePrimaryEmail: async (value) => {
        try {
          const response = await updatePrimaryEmailApi(value);
          set((state) => ({
            ...state,
            profileSecureData: {
              emails: response?.data?.emails,
              phones: response?.data?.phones,
              primaryEmailIndex: response?.data?.primaryEmailIndex,
              primaryPhoneIndex: response?.data?.primaryPhoneIndex,
            }
          }));
          get().getProfile();
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },
      
      removeEmail: async (value) => {
        await removeEmailApi(value);
        await get().getProfileSecureData();
      },
      removePhone: async (value) => {
        await removePhoneApi(value);
        await get().getProfileSecureData();
      }
    }
  )
);
