import type { CSSProperties, FC } from "react";
import { memo, useEffect, useState } from "react";

import { WorkflowNodeType } from "types";
import { getStepLogo } from "utility/workflow";
import { useSettingsStore } from "store";

import { Card, Space, Tooltip, theme, Typography } from "antd";

import { SvgIcon } from "components/SvgIcon";
import { commonIcons } from "assets/icons";
import { workflowIcons } from "assets/icons";

const { Text } = Typography;

export interface SidebarItemProps {
  description?: string;
  displayName: string;
  nodeType: WorkflowNodeType;
  resourceType: string;
  disabled?: boolean;
  isDragging: boolean;
}

const SidebarItem: FC<SidebarItemProps> = ({
  description,
  displayName,
  nodeType,
  resourceType,
  disabled,
  isDragging,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  function getSingleActionItem() {
    return (
      <Card.Grid
        style={{
          cursor: mouseDown ? "grabbing" : "grab",
          height: "40px",
          width: "98%",
          display: "flex",
          padding: token.paddingXS,
          backgroundColor: hover && !isDragging ? token.colorPrimaryBg : token.colorBgBase,
          border: isDragging ? "1px solid" : "",
          borderColor: isDragging ? token.colorBorder : "",
          borderRadius: token.borderRadius,
          marginBottom: "1px",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => setMouseDown(false)}
      >
        <Space size={token.sizeXS}>
          <Tooltip
            color={token.colorPrimary}
            title={!isDragging && description}
            placement={"right"}
          >
            <SvgIcon
              size={token.size}
              style={{ marginTop: "3px"}}
              component={
                mode ? workflowIcons.actionSidebarLightIcon : workflowIcons.actionSidebarDarkIcon
              }
            />
          </Tooltip>
          <Text
            style={{
              fontFamily: "Roboto",
              fontSize: token.fontSizeSM,
              justifyContent: "center",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              alignContent: "center"
            }}
          >
            {displayName}
          </Text>
        </Space>
        <SvgIcon
          size={token.size}
          style={{ marginLeft: "auto"}}
          component={commonIcons.directionsIcon}
        />
      </Card.Grid>
    );
  }

  function getSingleOperatorItem() {
    return (
      <Tooltip
        color={token.colorPrimary}
        title={!isDragging && displayName}
        placement={"bottom"}
      >
        <Space size={0} direction="vertical" align="center">
          <Card.Grid
            style={{
              cursor: disabled ? "not-allowed" : (mouseDown ? "grabbing" : "grab"),
              height: "40px",
              width: "40px",
              display: "flex",
              padding: token.padding,
              backgroundColor:
                hover && !isDragging && !disabled
                  ? token.colorPrimaryBgHover
                  : token.colorBgBase,
              border: isDragging ? "1px solid" : "",
              borderColor: isDragging ? token.colorBorder : "",
              borderRadius: token.borderRadius,
              justifyContent: "center",
              marginBottom: "1px",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
          >
            <SvgIcon
              size={token.sizeLG}
              style={{ marginLeft: "auto"}}
              component={getStepLogo(nodeType, resourceType)}
            />
          </Card.Grid>
         
          <Text
            style={{
              fontSize: "8px",
              fontWeight: "500",
              overflow: "hidden",
              display: "flex",
              marginBottom: "4px",
            }}
            ellipsis
          >
            {displayName}
          </Text>

        </Space>
      </Tooltip>
    );
  }

  return nodeType === WorkflowNodeType.Action
    ? getSingleActionItem()
    : getSingleOperatorItem();
};

export default memo(SidebarItem);
