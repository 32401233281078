import { FC, useState} from "react";
import {
  Radio,
  Space,
  RadioChangeEvent,
  theme,
} from "antd";

import {
  Action,
  Operator,
  WorkflowStepOperator,
} from "types";
import { useWorkflowStepsStore, useWorkflowStore } from "store";

import { WorkflowNodeType, WorkflowStepAction } from "types";

export interface NodeDeleteOptionsProps {
  childrens: string[];
  defaultIndex: number;
  onKeepBranchChangedCallback?: (keepBranchId: string) => void;
}

const NodeDeleteOptions: FC<NodeDeleteOptionsProps> = ({
  childrens,
  defaultIndex,
  onKeepBranchChangedCallback,
}) => {
  const { token } = theme.useToken();
  const [keepBranchId, setKeepBranchId] = useState<string>(childrens[defaultIndex]);

  const onKeepBranchChanged = (e: RadioChangeEvent) => {
    setKeepBranchId(e.target.value);
    onKeepBranchChangedCallback && onKeepBranchChangedCallback(e.target.value);
  };

  const {  selectedWorkflow} = useWorkflowStore(
    (state) => ({
      selectedWorkflow: state.selectedWorkflow,
    }),
  );
  
  const { actions, operators } = useWorkflowStepsStore(
    (state: any) => ({
      actions: state.actions,
      operators: state.operators
    }),
  );

  
  function getStepLabel(childStepId: string) {
    const stepType = selectedWorkflow.steps[childStepId].type;
    const referenceName =   "( " + selectedWorkflow.steps[childStepId].referenceName + " )"
    switch(stepType){
      case WorkflowNodeType.Action: {
        const step = selectedWorkflow.steps[childStepId] as WorkflowStepAction;
        const action = actions.find((i: Action) => i.id == step.actionID) as Action;
        return action.displayName + referenceName;
      }
      case WorkflowNodeType.Operator: {
        const step = selectedWorkflow.steps[childStepId] as WorkflowStepOperator;
        const operator = operators.find((i: Operator) => i.id == step.operatorID) as Operator;
        return operator.displayName + referenceName;
      }
      default:
        return "";
    }
  }

  return (
    <>
      <p style={{ color: token.colorTextDescription,  marginTop: token.margin }}>
        Select which branch to maintain while deleting step.
      </p>
      <div style={{marginTop: token.marginSM, marginLeft: token.marginSM}}>
        <Radio.Group onChange={onKeepBranchChanged} value={keepBranchId}>
          <Space direction="vertical">
            {childrens.map((childStepId) => (
              <Radio key={childStepId} value={childStepId}> 
                {getStepLabel(childStepId)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};

export default NodeDeleteOptions;