import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { WorkspaceItems, WorkspaceStore, WorkspaceTabType, PageInfo } from "types";

import {
  getWorkflowRunsApi,
  getSearchRunsApi,
  getSearchRunActionsApi,
} from "api";
import { useApprovalStore } from "./approval";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "./utils";

export const useWorkspaceItemStore = create<WorkspaceStore>()(
  devtools((set, get) => ({
    workspaceItemsMap: new Map<WorkspaceTabType, WorkspaceItems>(),
    workspacePage: {
      [WorkspaceTabType.WorkflowRuns]: { number: 1, size: 12, total: 0 } as PageInfo,
      [WorkspaceTabType.SearchRuns]: { number: 1, size: 12, total: 0 } as PageInfo,
      [WorkspaceTabType.ActionRuns]: { number: 1, size: 12, total: 0 } as PageInfo,
      [WorkspaceTabType.Approvals]: { number: 1, size: 12, total: 0 } as PageInfo
    } as Record<WorkspaceTabType, PageInfo>,
    workspaceFilters:{
      [WorkspaceTabType.WorkflowRuns]: {} as Record<string, FilterValue | null>,
      [WorkspaceTabType.SearchRuns]: {} as Record<string, FilterValue | null>,
      [WorkspaceTabType.ActionRuns]: {} as Record<string, FilterValue | null>,
      [WorkspaceTabType.Approvals]: {} as Record<string, FilterValue | null>
    } as Record<WorkspaceTabType, Record<string, FilterValue | null>>,
    workspaceSorters: {
      [WorkspaceTabType.WorkflowRuns]: {} as SorterResult<DataType>,
      [WorkspaceTabType.SearchRuns]: {} as SorterResult<DataType>,
      [WorkspaceTabType.ActionRuns]: {} as SorterResult<DataType>,
      [WorkspaceTabType.Approvals]: {} as SorterResult<DataType>
    } as Record<WorkspaceTabType, SorterResult<DataType> | SorterResult<DataType>[]>,
    workspaceSearchTextMap: {
      [WorkspaceTabType.WorkflowRuns]: new Map<string, string>(),
      [WorkspaceTabType.SearchRuns]: new Map<string, string>(),
      [WorkspaceTabType.ActionRuns]: new Map<string, string>(),
      [WorkspaceTabType.Approvals]: new Map<string, string>()
    } as Record<WorkspaceTabType, Map<string, string>>,
    activeTab: WorkspaceTabType.WorkflowRuns,

    setActiveTab: (tabType: WorkspaceTabType) => {
      set((state) => ({
        ...state,
        activeTab: tabType,
      }));
    },

    setCurrentPage: (tabType: WorkspaceTabType, pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const pageInfo = state.workspacePage[tabType];
        const newPage: PageInfo = {...pageInfo, number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: pageInfo?.total as number};
        return { ...state, workspacePage : {...state.workspacePage, [tabType]: newPage}};
      });
      return;
    },

    setWorkspaceFilters: (tabType: WorkspaceTabType, filters?: Record<string, FilterValue | null>) =>  {
      set( (state) => {
        return { ...state, workspaceFilters: {...state.workspaceFilters, [tabType]: filters}};
      });
      return;
    },

    setWorkspaceSorters: (tabType: WorkspaceTabType, sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, workspaceSorters: {...state.workspaceSorters, [tabType]: sorters}};
      });
      return;
    },

    setWorkspaceSearchTextMap: (tabType: WorkspaceTabType, searchTextMap: Map<string, string>) => {
      set( (state) => {
        return { ...state, workspaceSearchTextMap: {...state.workspaceSearchTextMap, [tabType]: searchTextMap}};
      });
      return;
    },
    
    getWorkspaceItems: async (tabType, pageNumber, pageSize) => {
      let items: any[] = [];
      let totalCount = 0;
      const filters = get().workspaceFilters[tabType];
      const sorters = get().workspaceSorters[tabType];
      const searches = get().workspaceSearchTextMap[tabType];
     
      const wsFilters = convertToApiFilters(filters, searches);
      const wsSorters = convertToApiSorters(sorters);
      //Default sort field
      if (wsSorters.length == 0) {
        wsSorters.push("-updatedAt");
      }

      switch (tabType) {
        case WorkspaceTabType.WorkflowRuns:
          [items, totalCount] = await getWorkflowRunsApi(pageNumber, pageSize, wsFilters, wsSorters);
          break;
        case WorkspaceTabType.SearchRuns: {
          [items, totalCount] = await getSearchRunsApi(pageNumber, pageSize, wsFilters, wsSorters);
          break;
        }
        case WorkspaceTabType.ActionRuns: {
          [items, totalCount] = await getSearchRunActionsApi(pageNumber, pageSize, wsFilters, wsSorters);
          break;
        }
        case WorkspaceTabType.Approvals: {
          [items, totalCount] = await useApprovalStore.getState().getApprovals(pageNumber, pageSize, wsFilters, wsSorters);
          break;
        }
        default:
          console.log("wrong workspace item type");
          throw "wrong workspace item type";
      }

      const newPage: PageInfo = { number: pageNumber, size: pageSize, total: totalCount };
      set((state) => ({
        workspacePage: {...state.workspacePage, [tabType]: newPage},
        workspaceItemsMap: new Map<WorkspaceTabType, WorkspaceItems>(state.workspaceItemsMap).set(tabType, items)
      }));
      return;
    },

  }),
    { name: "WorkspaceItemStore" })
);