import { create } from "zustand"
import { devtools } from "zustand/middleware";


import { SidebarStore } from "types";

export const useSidebarStore = create<SidebarStore>()(
  devtools((set, get) => ({
    sidebarCollapsed: false,
    sidebarWidth: "200px",
    changeSidebarCollapse: () => {
      set((state) => ({
        ...state,
        sidebarCollapsed: !get().sidebarCollapsed,
        sidebarWidth : get().sidebarCollapsed ? "200px" : "80px",
      }));
    },
  }),
  { name: "SidebarCollapseStore" })
);
