import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import http from "http";
// import https from "https";

import { useTokenStore } from "store/token";

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_RETRY_DELAY = 5 * 1000; //5 secs

export interface AxiosConfig extends AxiosRequestConfig {
  maxRetries?: number;
  retryDelay?: number;
  retryStatusCodes?: number[];
  shouldRetryOnResponse?: (response: AxiosResponse<any, any>) => boolean
}

export const axios = Axios.create({
  // httpAgent: new http.Agent({ keepAlive: true }),
  // httpsAgent: new https.Agent({ keepAlive: true }),
});

axios.interceptors.response.use(
  (response) => {
    const config = response.config as AxiosConfig;
    if(config.shouldRetryOnResponse && config.shouldRetryOnResponse(response)) {
      if (config.maxRetries && config?.maxRetries != 0) {
        config.maxRetries -= 1;
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(axios(config));
          }, config.retryDelay);
        });
      }
    }
    return response;
  },
  (error) => {
    const { config } = error;
    if (config.retryStatusCodes && config.retryStatusCodes.some((statusCode: number) => (statusCode == error.response?.status))){
      if (config.maxRetries && config.maxRetries != 0) {
        config.maxRetries -= 1;
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(axios(config));
          }, config.retryDelay);
        });
      }
    }
    let message = (error.response?.data as any)?.message || "Api call failed, return code is " + error.response?.status;
    if (error.response?.data?.errors) {
      if (error.response?.data?.errors.length > 0  && error.response?.data?.errors[0]?.title) {
        message = error.response?.data?.errors[0].title;
      }
    }
    return Promise.reject(new Error(message) );
  },
);


export const axiosInterceptor = async (
  method: "get" | "post" | "delete" | "patch" | "put",
  url: string,
  args?: any,
  config?: AxiosConfig
) : Promise<AxiosResponse<any, any>>=> {
  const axiosRequestConfig: AxiosConfig = config ? config : {}
  
  if (axiosRequestConfig.retryStatusCodes) {
    if(axiosRequestConfig.maxRetries == undefined) {
      axiosRequestConfig.maxRetries = DEFAULT_MAX_RETRIES;
    }
    if(axiosRequestConfig.retryDelay == undefined) {
      axiosRequestConfig.retryDelay = DEFAULT_RETRY_DELAY;
    }
  }
 
  const JWT = useTokenStore.getState().token;
  
  if (JWT){
    if(axiosRequestConfig.headers){
      axiosRequestConfig.headers["Authorization"] = `Bearer ${JWT}`
    }else {
      axiosRequestConfig.headers = { Authorization: `Bearer ${JWT}` }
    }
  }
  
  if (method == "get" || method == "delete") {
    return axios[method](url, axiosRequestConfig)
  } else {
    return axios[method](url, args, axiosRequestConfig);
  }

};
