export const defaultAppImage = `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7494_14331)">
<mask id="mask0_7494_14331" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
<path d="M48 0H0V48H48V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_7494_14331)">
<path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#6B0BEA"/>
<g clip-path="url(#clip1_7494_14331)">
<mask id="mask1_7494_14331" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="12" width="24" height="24">
<path d="M36 12H12V36H36V12Z" fill="white"/>
</mask>
<g mask="url(#mask1_7494_14331)">
<g filter="url(#filter0_i_7494_14331)">
<path d="M18.5 29.5005H12V33.0005C12 33.7962 12.3161 34.5592 12.8787 35.1218C13.4413 35.6843 14.2044 36.0005 15 36.0005H18.5V29.5005Z" fill="white"/>
<path d="M36 29.5005H29.5V36.0005H33C33.7957 36.0005 34.5587 35.6843 35.1213 35.1218C35.6838 34.5592 36 33.7962 36 33.0005V29.5005Z" fill="white"/>
<path d="M18.5 20.7495H12V27.2495H18.5V20.7495Z" fill="white"/>
<path d="M36 20.7495H29.5V27.2495H36V20.7495Z" fill="white"/>
<path d="M18.5 12H15C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15V18.5H18.5V12Z" fill="white"/>
<path d="M27.25 29.5005H20.75V36.0005H27.25V29.5005Z" fill="white"/>
<path d="M21.25 26.7495V21.2495H26.75V26.7495H21.25Z" fill="#6B0BEA" stroke="white"/>
<path d="M27.25 12H20.75V18.5H27.25V12Z" fill="white"/>
<path d="M33 12H29.5V18.5H36V15C36 14.2044 35.6838 13.4413 35.1213 12.8787C34.5587 12.3161 33.7957 12 33 12Z" fill="white"/>
</g>
</g>
</g>
</g>
</g>
<defs>
<filter id="filter0_i_7494_14331" x="12" y="12" width="24" height="28.0005" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_7494_14331"/>
</filter>
<clipPath id="clip0_7494_14331">
<rect width="48" height="48" fill="white"/>
</clipPath>
<clipPath id="clip1_7494_14331">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>
`

export const defaultAppImageColor = "#6B0BEA"