import { FC, useState, useEffect, useReducer } from "react";
import { Form, Button, Space, FormInstance, theme } from "antd";
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import { ArrayObjects, ArrayObjectsFormProps, ArrayObjectDataType } from "types"

import Modal from "components/Modal";
import CollapsePanel from "components/CollapsePanel";
import { validateFormFields } from "utility";
import ArrayObjectForm from "./ArrayObject";

interface DeleteObjectProps {
  enable: boolean;
  key?: number;
  name?: string;
}

const ArrayObjectsForm: FC<ArrayObjectsFormProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentObjects, setCurrentObjects] = useState<ArrayObjects>(props.objects);
  const [deleteObjectProps, setDeleteObjectProps] = useState<DeleteObjectProps>({enable: false});

  const [objectForms, dispatch] = useReducer((objectForms: FormInstance[], action: any) => {
    switch (action.type) {
      case 'add': {
        const forms = [...objectForms];
        forms.splice(action.key, 0, action.form);
        return forms;
      }
      case 'delete': {
        const forms = [...objectForms];
        forms.splice(action.key, 1);
        return forms;
      }
      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  }, []);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, values: any) => {
    setCurrentObjects(form.getFieldValue("objects"));
    props.onChange?.(form.getFieldValue("objects"));
  };

  const onObjectAdd = () => {
    let objects = currentObjects ? currentObjects.map((_, index) =>  form.getFieldValue(["objects", index])) : [] ;
    objects = [...objects, {name: "", type: ArrayObjectDataType.String, value: ""}];
    form.setFields([{name:["objects"], value: objects}]);
    setCurrentObjects(objects);
  };

  const onObjectDelete = () => {
    if (deleteObjectProps.key != undefined) {
      const objects = currentObjects.map((_, index) =>  form.getFieldValue(["objects", index]));
      objects.splice(deleteObjectProps.key, 1);
      dispatch({type: "delete", key: deleteObjectProps.key});
      form.resetFields(["objects", deleteObjectProps.key]);
      form.setFields([{name:["objects"], value: objects}]);
      setCurrentObjects(objects);
      setDeleteObjectProps({enable: false});
    }
  };

  useEffect(() => {
    props.onChange?.(currentObjects);
  }, [currentObjects, deleteObjectProps])

  return (
    <>
      <Form 
        form={form} 
        name="objectsForm" 
        autoComplete="off" 
        layout="vertical"
        initialValues={{objects: props.objects}}
        onValuesChange={onValuesChange}>
          <Form.List 
            name="objects">
            {(fields) => (
              <Space direction="vertical" style={{ display: 'flex' }}>
                {fields.map((field) => (
                  <CollapsePanel 
                    key={field.key}
                    name={currentObjects[field.key].name}
                    collapsePanel={false}
                    extraElement={
                      props.editMode && (props.required ? field.key != 0 : true) &&
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        onClick={(e: any) => {e.stopPropagation(); setDeleteObjectProps({enable: true,  key: field.key, name: currentObjects[field.key].name})}}
                      />
                    }
                  > 
                    <Form.Item 
                      {...field} 
                      key={field.key} 
                      name={[field.key]}
                      validateTrigger="onSubmit"
                      rules={[
                        { validator: (_, value) =>  validateFormFields(objectForms[field.key]) },
                      ]}>
                        <ArrayObjectForm
                          editMode={props.editMode}
                          name={props.name}
                          hideValue={props.hideValue}
                          object={currentObjects[field.key]}
                          suggestionsTree={props.suggestionsTree}
                          onRender={form => dispatch({type: "add", key: field.key, form: form})}
                        />
                    </Form.Item>
                  </CollapsePanel>
                ))}
                {props.editMode && 
                  <div style={{width : "50%", justifyContent: "center", alignItems: "center", margin: "auto"}}>
                    <Button
                      type="default" 
                      style={{ background: token.colorPrimaryBg }} 
                      onClick={onObjectAdd} 
                      block 
                      icon={<PlusOutlined />}
                    >
                      {props.name}
                    </Button>
                  </div>
                }
              </Space>
            )}
          </Form.List>
      </Form>
      { deleteObjectProps.enable && 
        (<Modal
            title={"Delete " + `${props.name}`}
            onClose={() => {setDeleteObjectProps({enable: false})}}
            open={deleteObjectProps.enable}
            onSubmit={() => onObjectDelete()}
          >
            {"Are you sure you want to delete " + `${props.name}` + " \"" + `${deleteObjectProps?.name}` + "\" ?"}
          </Modal>
        )
      }
    </>
  );
};
  
export default ArrayObjectsForm;

