import React, { useEffect, useState } from "react";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { Typography } from "antd";
const { Text } = Typography;

export interface FaderProperties {
  text: string;
  onClickText: string;
  textStyle?: React.CSSProperties;
}

export const Fader = (props: FaderProperties) => {
  const [isVisible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!isVisible);
    // Automatically hide the tooltip after 3 seconds (3000 milliseconds)
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };

  return (
    <CopyToClipboard text={props.text}>
      {isVisible ? (
        <div>
          <CheckOutlined />
          <Text style={props.textStyle}>{props.onClickText}</Text>
        </div>
      ) : (
        <CopyOutlined onClick={handleClick} />
      )}
    </CopyToClipboard>
  );
};
