import { Col } from "antd";
import { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
export const getStyles = (properties: { [k: string]: CSSProperties }) => {
  const styles = createUseStyles({
    container: {
      overflow: "hidden",
      display: "flex",
      padding: 0,
      width: "100%",
      top: 0,
      right: 0,
      justifyContent: "flex-start",
      justifyItems: "center",
      alignItems: "center",
      flexDirection: "column",
      ...properties["container"],
    },
  });
  return styles;
};
