import React from "react";
import { DeveloperItem, Path } from "types";
/** Editor context is meant to pass on editor control state to the child elements
*/
interface EditorContextProps {
  view: boolean;
  viewFactory: (item: DeveloperItem, path: Path) => JSX.Element;
  onCancel: () => Promise<void>;
  /** Called by the child view whenever state of the view of updated */
  onChange: (item: { [k: string]: any }) => Promise<void>;
}

export const EditorContext = React.createContext<EditorContextProps>({
  view: true,

  viewFactory: (item: DeveloperItem, path: Path) => {
    throw new Error("No viewFactory function provided");
  },
  onCancel: () => {
    throw new Error("No onCancel function provided");
  },
  onChange: (item: { [k: string]: any }) => {
    throw new Error("No onChange function provided");
  },
});
