
import {App,PageInfo,} from "types";




export enum DeveloperXTabType {
  AllApps = "allApps",
  MyApps = "myApps",
}

export enum AppTabType {
  Overview = "overview",
  Providers = "providers",
}


export interface DeveloperXStore{
    appItemsMap: Map<DeveloperXTabType, App[]>;
    selectedApp: App;
    appsPage: PageInfo;
    myAppsPage: PageInfo;
    activeTab: DeveloperXTabType;
    activeAppTab: AppTabType
    
    getApps: (tabType: DeveloperXTabType, pageNumber: number, pageSize: number, tenantId?: string) => Promise<void>;
    getApp: (tabType: DeveloperXTabType, appId: string) => Promise<App>;
    createApp: (app: App) => Promise<App>;
    updateApp: (app: App) => Promise<App>;
    deleteApp: (appId: string) => Promise<void>;
    getPageInfo: (tabType: DeveloperXTabType) => PageInfo;
    setActiveTab: (tab: DeveloperXTabType) => void;
    setActiveAppTab: (tab: AppTabType)=> void;

    clearApps: () => void;
    clearSelectedApp: () => void;
}
