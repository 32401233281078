import { Typography, theme } from "antd";
import { createUseStyles } from "react-jss";

import { Metric } from "types";
import { MetricDisplay } from "./MetricDisplay";

const { Text } = Typography;

export interface KpiPageHighlightGridProps {
  metrics?: Metric[];
}

export const KpiPageHighlightGrid = (props: KpiPageHighlightGridProps) => {
  const { token } = theme.useToken();
  const useStyles = createUseStyles({
    container: {
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "row",
      paddingTop: "15px",
      paddingLeft: "10px",
      paddingRight: "10px",
      gap: "5px",
    },
  });

  const classes = useStyles();
  return (
    <div id="pageHighlight-container" className={classes.container}>
      {props.metrics?.map((metric) => (
        <MetricDisplay
          key={metric.id}
          metric={metric}
          stretchMiniTiles={true}
        />
      ))}
    </div>
  );
};
