import { theme } from "antd";
import ActivityOverview from "components/ActivityOverview";
import { KpiDashboardGrid } from "components/Metrics/KpiDashboardGrid";
import { KpiDashboardPie } from "components/Metrics/KpiDashboardPies";
import { useEffect } from "react";
import { useMetricStore, useOrganizationStore } from "store";
import { MetricLayoutIdentifier, metricLayouts } from "types";

export const HyprEdgeDashboard = () => {
  const { token } = theme.useToken();
  const context = useOrganizationStore((state) => state.context);
  const { metrics, fetchMetrics } = useMetricStore((state: any) => ({
    metrics: state.metrics,
    fetchMetrics: state.fetchMetrics,
  }));

  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetrics();
    };
    asyncUseEffect();
  }, [context]);

  return (
    <div
      id="dashboard-metrics-container"
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        id="dashboard-metrics"
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: token.marginXS,
          flex: 1,
        }}
      >
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardProductivity
          )}
          metrics={
            metrics &&
            metrics.get(
              MetricLayoutIdentifier.MetricLayoutDashboardProductivity
            )
          }
        />
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardCases
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardCases)
          }
        />
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardWorkflow
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardWorkflow)
          }
        />
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardSearch
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardSearch)
          }
        />
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardEdge
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardEdge)
          }
        />
        <KpiDashboardGrid
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardApp
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardApp)
          }
        />
        <KpiDashboardPie
          layout={metricLayouts.get(
            MetricLayoutIdentifier.MetricLayoutDashboardActivity
          )}
          metrics={
            metrics &&
            metrics.get(MetricLayoutIdentifier.MetricLayoutDashboardActivity)
          }
        />
      </div>
      {/* <div id="dashboard-activity" style={{ marginTop: 20 }}>
        <ActivityOverview pageSize={18} />
      </div> */}
    </div>
  );
};
