import { APIError } from "openai";

export const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  day: "numeric", month: "short", year: "numeric",
  hour: "2-digit", minute: "2-digit", second: "numeric"
};

export const userRegions: string[] = [
  "North America",
  "Europe",
  "Asia Pacific",
  "Latin America",
  "Middle East",
  "Africa",
];

export interface Content {
  url : string;
  id : string;
  mime : string;
}

export interface PageInfo {
  size: number;
  number: number;
  total: number;
}

export interface Item {
  name: string; 
  description: string; 
  id: string
}

export interface ApiError {
  status: string;
  title?: string;
}
export type ApiErrors = ApiError[];

export const BigScreenQuery = { query: "(min-width: 1800px)" }

export const enum ServiceCode {
	AppService        = "app",
	VisibilityService = "visibility",
	SearchService     = "search",
	WorkflowService   = "workflow",
	EdgeService       = "edge",
	CaseMgmtService   = "casemgmt",
	ApprovalService   = "approval",
	UmgtService       = "umgt",
	DashboardService  = "dashboard",
	GoalService       = "goal",
} 

export const ServiceCodesMap: Map<ServiceCode, string> = new Map<ServiceCode, string>(
  [
      [ServiceCode.AppService, "App"],
      [ServiceCode.VisibilityService, "Visibility"],
      [ServiceCode.SearchService, "Search"],
      [ServiceCode.WorkflowService, "Workflow"],
      [ServiceCode.EdgeService, "Edge"],
      [ServiceCode.CaseMgmtService, "Case Management"],
      [ServiceCode.ApprovalService, "Approval"],
      [ServiceCode.UmgtService, "User Management"],
      [ServiceCode.DashboardService, "Dashboard"],
      [ServiceCode.GoalService, "Goal"],
  ]
)

export const enum SearchServiceCode {
	SearchService     = "search",
	WorkflowService   = "workflow",
	DashboardService  = "dashboard",
	GoalService       = "goal",
} 

export const SearchServiceCodeOptions = [
  { value: SearchServiceCode.SearchService, label: 'Search' },
  { value: SearchServiceCode.WorkflowService, label: 'Workflow' },
  { value: SearchServiceCode.DashboardService, label: 'Dashboard' },
  { value: SearchServiceCode.GoalService, label: 'Goals' },
]

export const SearchServiceCodesMap: Map<SearchServiceCode, string> = new Map<SearchServiceCode, string>(
  [
      [SearchServiceCode.SearchService, "Search"],
      [SearchServiceCode.WorkflowService, "Workflow"],
      [SearchServiceCode.DashboardService, "Dashboard"],
      [SearchServiceCode.GoalService, "Goal"],
  ]
)
