export const Dimensions = {
    nodeHeight: 50,
    nodeWidth: 280,
    nodeYShift: 8,

    dropzoneNodeWidth: 280,
    dropzoneNodeHeight: 50,
    dropzoneNodeVisibleWidthHeight: 10,
    dropzoneNodeActiveWidthHeight: 20,
    dropzoneNodeXShift: 270,
    dropzoneNodeYShift: 32,
    
    endNodeWidthHeight: 40,
    endNodePositionXShift: 140,
    endNodePositionYShift: 11,
}