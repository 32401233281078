export enum WebhookRequestType {
  Get = "get",
  Post = "post",
}

export const WebhookRequestTypeMap: Map<string, string>  =  new Map<string, string> (
  [
    [WebhookRequestType.Get, "GET"], 
    [WebhookRequestType.Post, "POST"],
  ]
)

export enum  WebhookRequestContentType {
  Json = "json"
}

export const WebhookRequestContentTypeMap: Map<string, string>  =  new Map<string, string> (
  [
    [WebhookRequestContentType.Json, "applicayion/json"], 
  ]
)

export enum  WebhookResponseContentType {
  Text = "text",
  Json = "json"
}
  
export const WebhookResponseContentTypeMap: Map<string, string>  =  new Map<string, string> (
  [
    [WebhookResponseContentType.Text, "applicayion/text"], 
    [WebhookResponseContentType.Json, "applicayion/json"], 
  ]
)

export interface AppWebhookConfiguration {
  url: string;
	secret: string;
  requestType: WebhookRequestType;
  contentType: WebhookRequestContentType;
  expectedHeaders?: Record<string, string>;
  expectedQueryParams?: Record<string, string>;
  responseHeaders?: Record<string, string>;
  responseCode: number;
  responseContentType: WebhookRequestContentType;
  responsePayload?: any;
}