

export enum ApprovalStatus {
	ApprovalStatusPending  = "pending",
	ApprovalStatusApproved = "approved",
	ApprovalStatusRejected = "rejected",
	ApprovalStatusExpired  = "expired",
	ApprovalStatusCanceled  = "canceled"
}

export const ApprovalStatusMap: Map<ApprovalStatus, string>  =  new Map<ApprovalStatus, string> (
	[
		[ApprovalStatus.ApprovalStatusApproved, "Approved"], 
		[ApprovalStatus.ApprovalStatusRejected, "Rejected"],
		[ApprovalStatus.ApprovalStatusPending, "Pending"], 
		[ApprovalStatus.ApprovalStatusExpired, "Expired"],
		[ApprovalStatus.ApprovalStatusCanceled, "Canceled"],
	]
)
  
export const ApprovalResourceTypeMap: Map<string, string>  =  new Map<string, string> (
	[
		["workflow-runs", "workflow-execution"], 
		["workflows", "workflow"],
	]
)

export enum ApprovalsRequiredFrom {
    All = "all",
    Any = "any",
}

export interface ApproverResponse {
	approvalStatus: ApprovalStatus;
	reason?: string;
}

export interface Approver {
	id: string;
	email: string;
	isManagedUser: boolean;
	isMyApproval: boolean;
	approveUrl: string;
	rejectUrl: string;
	response: ApproverResponse;
}

export type Approvers = Approver[];
 
export type ApprovalMetaData = Record<string, any>;

export interface Approval {
	id: string;
	resourceName: string;
	resourceID: string;
	resourceType: string;
	approvers: Approvers;
	status: ApprovalStatus;
	requiredFrom: ApprovalsRequiredFrom;
	taskToken: string;
	metaData?: ApprovalMetaData;
	tenantID: string;
	userID: string;
	updatedAt: string;
    createdAt: string;
}