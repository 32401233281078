import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import { FieldProps } from "./common";
import { getLabel } from "./utils";
const { Text } = Typography;

import styles from "./EntityEditor.module.scss";

export const FieldLabel = (props: { fp: FieldProps }) => {
  return (
    <Text className={styles.labeltxt}>
      <Space>
        {getLabel(props.fp.label, props.fp.optional ? true : false)}
        {props.fp.help && (
          <Tooltip title={props.fp.help}>
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </Space>
    </Text>
  );
};
