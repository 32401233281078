import { DatePicker, InputNumber, Radio, Select, Space } from "antd";

import { debounce } from "lodash";
import { GoalMeasurement } from "types";
import dayjs from "dayjs";
import { useEffect, useState, useMemo } from "react";

export enum MeasurementCondition {
  greaterThan = ">",
  lessThan = "<",
  equal = "==",
  notEqual = "!=",
  greaterThanOrEqual = ">=",
  lessThanOrEqual = "<=",
}

export interface MeasurementEditorProps {
  measurement: GoalMeasurement;
  target: string;
  onChange: (measurement: GoalMeasurement, target: string) => void;
}

const getMeasurementString = (m: GoalMeasurement) => {
  switch (m) {
    case GoalMeasurement.BOOLEAN:
      return "Boolean";
    case GoalMeasurement.CURRENCY:
      return "Currency";
    case GoalMeasurement.NUMBER:
      return "Number";
    case GoalMeasurement.PERCENTAGE:
      return "Percentage";
    case GoalMeasurement.TIME:
      return "Time";
    default:
      return "";
  }
};

export const MeasurementEditor = (props: MeasurementEditorProps) => {
  const [measurement, setMeasurement] = useState<GoalMeasurement>(
    props.measurement
  );
  const [condition, setCondition] = useState(MeasurementCondition.equal);
  const [goal, setGoal] = useState<number | boolean>(0);

  useEffect(() => {
    if (props.target) {
      const logic = JSON.parse(props.target);
      const args: (number | boolean)[] = Object.values(logic)?.[0] as (
        | number
        | boolean
      )[];
      const targetValue = args[1];
      const condition = Object.keys(logic)?.[0];
      setCondition(condition as MeasurementCondition);
      setGoal(targetValue);
    }
  }, [props.target]);

  const createLogic = (
    condition: MeasurementCondition,
    value: number | boolean
  ) => {
    const logic = {
      [condition]: [{ var: "v" }, value],
    };
    return JSON.stringify(logic);
  };

  const debounceInput = useMemo(() => {
    return debounce((e: number | null) => {
      if (e === null) return;
      setGoal(e);
      props.onChange(measurement, createLogic(condition, e));
    }, 300);
  }, [measurement, condition]);

  useEffect(() => {
    return () => {
      debounceInput.cancel();
    };
  });

  const getMeasurementElement = (measurement: GoalMeasurement) => {
    switch (measurement) {
      case GoalMeasurement.BOOLEAN:
        return (
          <Radio.Group
            onChange={(checked) => {
              setCondition(MeasurementCondition.equal);
              setGoal(checked.target.value);
              props.onChange(
                measurement,
                createLogic(MeasurementCondition.equal, checked.target.value)
              );
            }}
            defaultValue={!!goal}
            value={goal}
          >
            <Space direction="horizontal">
              <Radio value={true}>True</Radio>
              <Radio value={false}>False</Radio>
            </Space>
          </Radio.Group>
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Select
              onSelect={(value) => {
                setCondition(value as MeasurementCondition);
                props.onChange(
                  measurement,
                  createLogic(value as MeasurementCondition, goal)
                );
              }}
              defaultValue={condition}
              style={{ minWidth: "200px", width: "100%" }}
            >
              {Object.values(MeasurementCondition).map((m) => (
                <Select.Option key={m} value={m}>
                  {m}
                </Select.Option>
              ))}
            </Select>
            {measurement == GoalMeasurement.TIME ? (
              <DatePicker
                showTime
                value={dayjs(new Date(goal as number))}
                style={{ minWidth: "200px", width: "100%" }}
                onChange={(date) => {
                  if (date) {
                    setGoal(date.toDate().getTime());
                    props.onChange(
                      measurement,
                      createLogic(condition, date.toDate().getTime())
                    );
                  }
                }}
              />
            ) : (
              <InputNumber
                style={{ minWidth: "200px", width: "100%" }}
                value={goal as number}
                onChange={(e) => debounceInput(e)}
              ></InputNumber>
            )}
          </div>
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Select
        onSelect={(value) => {
          setMeasurement(value as GoalMeasurement);
          props.onChange(
            value as GoalMeasurement,
            createLogic(condition, goal)
          );
        }}
        defaultValue={props.measurement}
      >
        {Object.keys(GoalMeasurement).map((m) => (
          <Select.Option key={m} value={m}>
            {getMeasurementString(m as GoalMeasurement)}
          </Select.Option>
        ))}
      </Select>
      {getMeasurementElement(measurement)}
    </div>
  );
};
