import { FC, useState, useEffect } from "react";

import { Spin, Typography, theme, Card, Form } from "antd";

import { AppEdgeConfigProps, AdapterType, Edge } from "types";
import { useAppSubscriptionStore } from "store";

import EdgeInfos from "components/EdgeSelection/edgeInfos";
import { getEdgeApi } from "api";

const { Text, Title } = Typography;

const AppEdgeConfig: FC<AppEdgeConfigProps> = ({
  form,
  appId,
  enableEdgeAutoDetect,
  editMode,
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [detectEdgeFailed, setDectEdgeFailed] = useState(false);
  const [selectedEdge, setSelectedEdge] = useState<Edge|undefined>();
  
  const { 
    selectedApp,
    selectedAppSubscription,
    recommendedEdges, 
    getRecommendedEdges, 
    clearRecommendedEdges 
  } = useAppSubscriptionStore((state) => ({
      selectedApp: state.selectedApp,
      selectedAppSubscription: state.selectedAppSubscription,
      recommendedEdges: state.recommendedEdges,
      getRecommendedEdges: state.getRecommendedEdges,
      clearRecommendedEdges: state.clearRecommendedEdges,
  }));

  const loadAppEdge = async (edgeId: string) => {
    try {
      setLoader(true);
      const edge = await getEdgeApi(edgeId);
      setSelectedEdge(edge);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    selectedAppSubscription?.edgeID && loadAppEdge(selectedAppSubscription.edgeID)
  }, [selectedAppSubscription?.edgeID]);

  const loadRecommendedEdges = async () => {
    try {
      setLoader(true);
      await getRecommendedEdges(
        appId,
        selectedAppSubscription?.httpConfiguration
      );
    } catch (error) {
      console.log(error);
      setDectEdgeFailed(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "500px",
        overflow: "auto",
        scrollbarWidth: "thin"
      }}
    >
      <Spin spinning={loader}>
        <div>
          {detectEdgeFailed && (
            <Text type="warning">Error in detection of recommended edges, Retry again!</Text>
          )}
          {
            <EdgeInfos 
              selectedEdge={selectedEdge}
              editmode={editMode}
              onSelect={(edge) => {
                setSelectedEdge(edge);
                form.setFieldValue("edgeID", edge.id);
              }}
              enableAutoDetect={
                selectedApp?.adapterTypes?.some((type) => type == AdapterType.Http)
                &&
                enableEdgeAutoDetect
              }
              onAutoDetect={() => {
                setDectEdgeFailed(false); 
                clearRecommendedEdges();
                loadRecommendedEdges();
              }}
              recommendedEdges={recommendedEdges}
            />
          }
        </div>
        <Form
          form={form}
          name="appParametersForm"
          layout="vertical"
          autoComplete="off"
          disabled={!editMode}
        >
          <Form.Item
            name="edgeID"
            rules={[{ required: true, message: "Edge should be selected" }]}
          />
        </Form>
      </Spin>
    </Card>
  );
};

export default AppEdgeConfig;
