import { Image, Space, Switch, Typography, theme } from "antd";
import SearchBar from "components/SearchBar";
import { useOrganizationStore, useSearchStore, useSettingsStore } from "store";
import { AIBasedSearchQueryPolicyName, PolicyCategoryType, SearchMetaData } from "types";

import { commonIcons } from "assets/icons";
import { useEffect, useState } from "react";
import { usePolicyStore } from "store/policy";

const { Title } = Typography;

const SearchBox = () => {
  const { token } = theme.useToken();
  const lightMode = useSettingsStore((state) => (state.lightMode));
  const [aiBasedSearchQuery, setAiBasedSearchQuery] = useState<boolean>(false);
  const getPolicy = usePolicyStore((state) => state.getPolicy);

  const { searchMetaData, setSearchMetaData } = useSearchStore((state) => ({
    searchMetaData: state.searchMetaData,
    setSearchMetaData: state.setSearchMetaData,
  }));
  const { context } = useOrganizationStore( (state) => ({
    context: state.context
  }));

  useEffect(() => { 
    const asyncUseEffect = async () => {
      const policy = await getPolicy(PolicyCategoryType.SearchGeneral, AIBasedSearchQueryPolicyName);
      setAiBasedSearchQuery(policy?.value.data);
    };
    asyncUseEffect();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: token.colorBgBase,
        borderRadius: "10px",
      }}
    >
      <Title level={4}>Search Anything, Anytime, Anywhere</Title>
      <Space direction="horizontal">
        <div
          style={{
            // display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: token.paddingContentHorizontalSM,
          }}
        >
          <Image
            preview={false}
            height={36}
            src={
              lightMode
                ? (aiBasedSearchQuery ? commonIcons.coPilotLightModeSvg : commonIcons.NoCoPilotSvg)
                : (aiBasedSearchQuery ? commonIcons.coPilotDarkModeSvg : commonIcons.NoCoPilotSvg)
            }
          />
        </div>
      </Space>
      <div
        key="searchbar"
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          marginTop: token.margin,
          marginBottom: token.marginXXL,
        }}
      >
        <SearchBar inHeader={false} />
      </div>
    </div>
  );
};

export default SearchBox;
