import { FC, ReactNode } from "react";
import { Space, Typography, theme } from "antd";
import { SvgIcon } from "components/SvgIcon";

const { Text } = Typography;

export interface TextWithIconProps {
  icon: any,
  iconSize?: number,
  iconColor?: string,
  text: string | ReactNode,
  subText?: string | ReactNode,
  color?: string,
}

export const TextWithIcon: FC<TextWithIconProps> = (props) => {
  const { token } = theme.useToken();
 
  return (
    <Space size={token.marginXXS}>
      {props.iconSize 
        ? <SvgIcon size={props.iconSize} component={props.icon} hexColor={props.iconColor}/>
        : <SvgIcon component={props.icon} hexColor={props.iconColor}/>
      }
      <Text style={{ color: props.color }}>{props.text}</Text>
      {props.subText && <Text style={{ fontSize: "smaller",  color: props.color}}>{props.subText}</Text>}
    </Space>
  );
};