import { FC, useEffect } from "react";

import { Form, Input, Select, Card, Button, theme, Space } from "antd";
import { DeleteFilled } from "@ant-design/icons";

import { ConditionFormProps, ConditionOpType } from "types";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import { validate } from "uuid";

const ConditionForm: FC<ConditionFormProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const getOperatorTypes = () => {
    return Object.values(ConditionOpType).map( (opType) =>  ({
      label: opType,
      value: opType,
    }));
  };

  const onValuesChange = async (changedValues: any, values: any) => {
    props.onChange?.(form.getFieldsValue(true));
  };

  return (
    <>
      {props.editMode && props.isCloseRequired ? (
        <Button 
          type="default" 
          shape="circle" 
          size="small"
          style={{
            position: "absolute",
            right: -10,
            top: -10,
            zIndex: 1,
          }}
          icon={<DeleteFilled style={{ 
            color: token.colorPrimary,
            position: "absolute",
            right: 4,
            top: 4,
           }} />}
          onClick={() => props.onDelete?.()}
        />
      ) : null}
      <Card bodyStyle={{ padding: token.padding }}>
        <Form
          form={form}
          name="conditionForm"
          layout="vertical"
          autoComplete="off"
          initialValues={props.condition}
          onValuesChange={onValuesChange}
        >
          <Space direction="vertical" size={token.sizeXXS} style={{ display: "flex" }}>
            <Form.Item
              name="fact"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: "fact is required!" },
                {
                  validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                }
              ]}
            >
              {props.factList ? (
                <Select
                  key="fact"
                  disabled={!props.editMode}
                  showAction={["focus", "click"]}
                >
                  {props.factList.map((val: any) => (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              ) : props.factSuggestionsTree ? (
                <SuggestionsInput
                  editMode={props.editMode}
                  name="fact"
                  suggestionsTree={props.factSuggestionsTree}
                />
              ) : (
                <Input disabled={!props.editMode} size={"middle"} />
              )}
            </Form.Item>
            <Form.Item
              name="operator"
              validateTrigger="onSubmit"
              rules={[{ required: true, message: "operator is required!" }]}
            >
              <Select
                disabled={!props.editMode}
                showAction={["focus", "click"]}
                options={getOperatorTypes()}
              />
            </Form.Item>
            <Form.Item
              name="value"
              validateTrigger="onSubmit"
              rules={[
                { required: true, message: "value is required!" },
                {
                  validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                }
              ]}
            >
              {props.valueList ? (
                <Select
                  key="value"
                  disabled={!props.editMode}
                  showAction={["focus", "click"]}
                >
                  {props.valueList.map((val: any) => (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
              ) : props.valueSuggestionsTree ? (
                <SuggestionsInput
                  editMode={props.editMode}
                  name="value"
                  suggestionsTree={props.valueSuggestionsTree}
                />
              ) : (
                <Input disabled={!props.editMode} size={"middle"} />
              )}
            </Form.Item>
          </Space>
        </Form>
      </Card>
    </>
  );
};

export default ConditionForm;
