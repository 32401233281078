import { useEffect } from "react";
import {
  CollectionField,
  CollectionMutatorPosition,
  FieldProps,
  ViewerType,
} from "components/EntityEditor";
import { DeveloperItem, IOData, IODataType, ResourceType, TextType } from "types";

export interface ParametersFieldProps extends FieldProps {
  value: { [k: string]: any };
  inherited?: { [k: string]: any };
  onChange: (key: string, value: { [k: string]: any }) => void;
  showExpanded?: boolean;
  viewerType: ViewerType;
  mutatorPosition?: CollectionMutatorPosition;
  showCount?: boolean;
}

/** react function component */
/**
 *  target is structured as follows:
 *  {
 *   "parameter_0": {
 *    "description": "The name of the person",
 *    "type": "String",
 *    "required": true,
 *    "max": 0,
 *    "min": 0
 *   },
 *  "parameter_1": {
 *    "description": "The age of the person",
 *    "type": "Integer",
 *    "required": true,
 *    "max": 0,
 *    "min": 0
 *   }
 *  }
 */
export const ParametersField = (props: ParametersFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const onIODataItemAdd = async (key: string): Promise<DeveloperItem> => {
    const newIoData: IOData = {
      description: "",
      displayName: "",
      type: IODataType.String,
      required: false,
      max: 4096,
      min: 0,
    };
    const count = Object.keys(props.value).length;
    return {
      id: `parameter-${count}`,
      item: { recordKey: `parameter_${count}`, recordValue: newIoData },
      type: ResourceType.IODataParameter,
      context: {
        cardTitle: "Parameter",
        readonly: false,
      },
    };
  };

  const onIODataChange = async (key: string, value: DeveloperItem[]) => {
    const newTarget: { [k: string]: any } = value.reduce((acc, cur) => {
      if (cur.item?.context?.readonly) return acc;
      acc[cur.item.recordKey] = cur.item.recordValue;
      return acc;
    }, {} as { [k: string]: any });
    props.onChange(props.identifier, newTarget);
  };

  const convertToDeveloperItem = (
    item: { [k: string]: any } | undefined,
    inherited: boolean
  ): DeveloperItem[] => {
    if (!item) return [];
    return Object.keys(item).map((key) => {
      return {
        id: `ioData-${inherited ? "inherited-" : ""}${key}`,
        item: {
          recordKey: key,
          recordValue: inherited ? props.inherited?.[key] : props.value[key],
        },
        type: ResourceType.IODataParameter,
        context: {
          cardTitle: "Parameter",
          readonly: inherited,
        },
      };
    });
  };

  return (
    <CollectionField
      optional={props.optional}
      value={[
        ...convertToDeveloperItem(props.inherited, true),
        ...convertToDeveloperItem(props.value, false),
      ]}
      columns={[
        { title: "Name", dataIndex: ["item", "recordKey"] },
        {
          title: "Description",
          dataIndex: ["item", "recordValue", "description"],
        },
        { title: "Type", dataIndex: ["item", "recordValue", "type"] },
      ]}
      help={props.help}
      identifier={props.identifier}
      label={props.label}
      onChange={onIODataChange}
      onAdd={onIODataItemAdd}
      viewerType={props.viewerType}
      path={props.path}
      showTableHeader={true}
      showExpanded={props.showExpanded || false}
      mutatorPosition={
        props.mutatorPosition || CollectionMutatorPosition.InBetween
      }
      allowedTypes={[ResourceType.IODataParameter]}
      showAdd={true}
      showDelete={true}
      loading={false}
      showCount={props.showCount ? true : false}
    />
  );
};
