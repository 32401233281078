import { Space, Spin } from "antd";
import {
  RadioField,
  ObjectBackedTextField,
  EditorContext,
  TextField,
  ViewProps,
  ViewerType,
} from "components/EntityEditor";
import { EdgeInfraTypeMap, EdgeInfraType, ResourceType } from "types";
import { getRegionsApi } from "api";
import React, { useContext, useEffect } from "react";

export const AppEdgeDetectorConstantView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const [regions, setRegions] = React.useState<Map<EdgeInfraType, []>>();
  const onTextChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const onRadioChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const infraTypeOptions = () => {
    return [
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Amazon)!,
        value: EdgeInfraType.Amazon,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Azure)!,
        value: EdgeInfraType.Azure,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Gcp)!,
        value: EdgeInfraType.Gcp,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Oci)!,
        value: EdgeInfraType.Oci,
      },
    ];
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      try {
        setLoader(true);
        const regions = await getRegionsApi();
        setRegions(regions);
      } catch (e) {
        console.log("failed to acquire regions", e);
      } finally {
        setLoader(false);
      }
    };
    asyncUseEffect();
  }, []);

  return (
    <Spin spinning={loader}>
      <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
        <RadioField
          identifier={"infraType"}
          label={"Select Infra Provider"}
          value={target?.["infraType"]}
          path={[...props.path, "infraType"]}
          onChange={onRadioChange}
          defaultSelected={EdgeInfraType.Amazon}
          direction="vertical"
          options={infraTypeOptions()}
        />
        
        <ObjectBackedTextField
          identifier={"region"}
          label={`Region for ${EdgeInfraTypeMap.get(target?.["infraType"])}`}
          path={[...props.path, "region"]}
          value={{
            id: target?.["region"],
            type: ResourceType.Unknown,
            item: target?.["region"],
            context: {
              displayName: target?.["region"],
              description: target?.["region"],
            },
          }}
          options={regions?.get(target?.["infraType"])?.map((region) => ({
            id: region,
            item: region,
            type: ResourceType.Unknown,
            context: {
              displayName: region,
              description: region,
            },
          }))}
          viewerType={ViewerType.CardViewer}
          onChange={onTextChange}
          popupOptions={true}
        />
      </Space>
    </Spin>
  );
};
