import { FC } from "react";
import { Tabs, TabsProps } from "antd";
import IdentityProvider from "components/IdentityProvider";
import ServiceProvider from "components/ServiceProvider";

export const EnterpriseSso: FC = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Identity Provider",
      children: <IdentityProvider />,
    },
    {
      key: "2",
      label: "Service Provider",
      children: <ServiceProvider />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
};
