import { FC } from "react";

import {
  theme,
  Typography,
  Space,
  Badge,
  Image,
  Tag,
} from "antd";
import { useOrganizationStore, useSettingsStore } from "store";
import { App } from "types";
import { getAppLogoUrl } from "utility/app";
import { getTagColor } from "utility";
const { Text } = Typography;

export interface PanelHeaderProps {
  app: App;
  appSubscriptionName: string;
  count: number
  tagId?: string;
}

export const PanelHeader: FC<PanelHeaderProps> = ({
  app,
  appSubscriptionName,
  count,
  tagId,
}) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const {
    mspEnabled, 
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags
  }));


  return (
    <Space
      style={{
        position: "sticky",
        width: "100%",
        top: 0,
        right: 0,
      }}
      size={token.sizeMS}
    >
      {(app?.logoUrl || app?.darkLogoUrl) && (
        <Image
          preview={false}
          draggable={false}
          width={"90px"}
          height={"30px"}
          src={getAppLogoUrl(app, lightMode)}
        />
      )}
      {app?.displayName && (
        <Text style={{ fontSize: token.fontSizeHeading5 }}>
          {`${app.displayName}(${appSubscriptionName})`}
        </Text>
      )}
      {tagId && mspEnabled &&
        <Tag color={getTagColor(tagId)}>
          {tags?.find((tag) => (tag.id == tagId))?.value}
        </Tag>
      }
      <Badge
        count={count}
        overflowCount={9999}
        showZero
        color={token.colorText}
      />
    </Space>
  )
}