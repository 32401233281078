import { Button, Tag, Typography, List } from "antd";
import { ActionProvider, TriggerProvider, HttpAdapterSpec } from "types";
import { ColumnType } from "antd/es/table";
import { IDataType } from "components/EntityDisplay";

const { Text } = Typography;

/** Missing fields are not rendered, so , we can use the same columnset for artifacts and other other entities */
export const providerColumns: ColumnType<IDataType>[] = [
  {
    title: "Name",
    dataIndex: "appID",
    render: (_, record: IDataType) => {
      if (record.item.actionID != null) {
        const ap = record.item as ActionProvider;
        return (
          <Text>
            <Text strong>Action Provider</Text>
            {ap.actions?.displayName}
          </Text>
        );
      } else {
        const tp = record.item as TriggerProvider;
        return (
          <Text>
            <Text strong>Trigger Provider</Text>
            {tp.triggers?.displayName}
          </Text>
        );
      }
    },
  },
  {
    title: "Http Specs",
    dataIndex: "http",
    render: (adapter: HttpAdapterSpec, record: IDataType) => (
      <Button
        type="link"
        onClick={() => {
          console.log("http specs");
        }}
      >
        {"http"}
      </Button>
    ),
  },
];
