import { ParameterStoreParameter } from "types";
import { axiosInterceptor, AxiosConfig} from "./axios";
import { AxiosResponse } from "axios";


export const getParametersApi = async (
    edgeUrl: string,
    parameterStoreId: string,
    pageNumber?: number,
    pageSize?: number): Promise<ParameterStoreParameter[]> => {
    
    let url = `${edgeUrl}/${parameterStoreId}/parameters`
    if (pageNumber && pageSize) 
        url += `?page[number]=${pageNumber}&page[size]=${pageSize}`

    const response = await axiosInterceptor("get", url);
    return response.data
};

export const getParameterApi = async (edgeUrl: string, parameterStoreId: string, name: string): Promise<ParameterStoreParameter> => {
    const url = `${edgeUrl}/${parameterStoreId}/parameters/${name}`
    const response = await axiosInterceptor("get", url);
    return response.data
};

export const createParameterApi = async (edgeUrl: string, parameterStoreId: string, parameter: ParameterStoreParameter) :  Promise<ParameterStoreParameter>=> {
    const url = `${edgeUrl}/${parameterStoreId}/parameters`
    const response = await axiosInterceptor(
      "post",
      url,
      parameter
    );
    return response.data as ParameterStoreParameter;
};

export const updateParameterApi = async (edgeUrl: string, parameterStoreId: string, name: string, parameter: ParameterStoreParameter) :  Promise<void> => {
    const url = `${edgeUrl}/${parameterStoreId}/parameters/${name}`
    await axiosInterceptor(
        "patch",
        url,
        parameter
    );
};

export const deleteParameterApi = async (edgeUrl: string, parameterStoreId: string, name: string):  Promise<void> => {
    const url = `${edgeUrl}/${parameterStoreId}/parameters/${name}`
    await  axiosInterceptor("delete",`${url}`);
};