import { FC, useEffect, useState } from "react";
import { Upload, Typography, Space } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { LIST_IGNORE } from "antd/es/upload/Upload";

const { Dragger } = Upload;
const { Text } = Typography;

export interface FileUploadProps {
  fileTypes?: string;
  multiple?: boolean;
  maxSize?: number;
  onUpload: (files: any[]) => void;
}

export const FileUpload: FC<FileUploadProps> = ({
  fileTypes,
  multiple,
  maxSize,
  onUpload
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [maxUploadSize, setMaxUploadSize] = useState<number>(100); //100MB
  const [maxSizeError, setMaxSizeError] = useState<boolean>(false);

  useEffect(() => {
    maxSize && setMaxUploadSize(maxSize);
  }, [maxSize]);

  return (
    <>
      <Dragger
        accept={fileTypes ? fileTypes : "*.*"}
        multiple={multiple ? multiple : false}
        fileList={[...fileList]}
        onRemove={(file) => {
          setFileList(() =>  {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
          })
        }}
        onChange={() => setMaxSizeError(false)}
        beforeUpload={(file, files) => {
          if (files && files.length > 0) {
            let size = 0;
            files.map((file)  => {
              size += file.size;
            })
            
            if(size > maxUploadSize * 1024 * 1024) {
              setMaxSizeError(true);
              return Upload.LIST_IGNORE;
            }
          }

          if (files) {
            setFileList([...fileList, ...files]);
          } else {
            setFileList([...fileList, file]);
          }
          onUpload(files);
          return false;
        }}
      >
        <Space direction="vertical">
          <InboxOutlined/>
          <Text italic>Click or drag file here to attach</Text>
        </Space>
      </Dragger>
      {maxSizeError == true
        &&
        <Text type="danger">Attachment size exceeds maximum limit {maxUploadSize}MB</Text>
      }
    </>
  );
};