import React from "react";
import { DeveloperItem, Path } from "types";
/** Editor context is meant to pass on editor control state to tghe child elements
 * For example, when the user clicks on the save button, the state is updated i the save flag
 */
interface AuthContextProps {
  authenticated: boolean;
}

export const AuthenticationContext = React.createContext<AuthContextProps>({
  authenticated: false,
});
