import { Button, Form, Input, Select, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import { notification } from 'utility/notification';

import { useOrganizationStore, useSettingsStore } from "store";
const { Option } = Select;
const ProviderForm: FC = () => {
  const getSamlIdpSettings = useSettingsStore(
    (state) => state.getSamlIdpSettings
  );

  const setSamIdpSettings = useSettingsStore(
    (state) => state.setSamlIdpSettings
  );

  const context = useOrganizationStore((state) => state.context);

  const samlIdpSettings = useSettingsStore((state) => state.samlIdpSettings);

  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [formDisabled, setFormDisabled] = useState(true);
  const onSubmit = (value: any) => {
    setLoader(true);
    setSamIdpSettings(value)
      .then(() => {
        notification.success({
          message: "Success",
          description: "SAML IDP settings saved successfully",
        });
        setLoader(false);
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Unable to save saml idp settings",
        });
        setLoader(false);
      });
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      try {
        setLoader(true);
        await getSamlIdpSettings();
      } catch (error) {
        console.log(
          `Error fetching saml idp settings: ${JSON.stringify(error)}`
        );
      } finally {
        setLoader(false);
      }
    };
    asyncUseEffect();
  }, [context]);

  useEffect(() => {
    form.resetFields();
  }, [samlIdpSettings]);

  return (
    <Spin spinning={loader}>
      <Form
        style={{ width: "75%" }}
        form={form}
        name="signInForm"
        initialValues={{
          entityId: samlIdpSettings?.entityId || "",
          ssoUrl: samlIdpSettings?.ssoUrl || "",
          ssoBinding:
            samlIdpSettings?.ssoBinding ||
            "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect",
          x509: samlIdpSettings?.x509 || "",
        }}
        disabled={formDisabled}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="entityId"
          label="SAML provider's Entity ID"
          rules={[{ required: true, message: "Entity Id is required!" }]}
        >
          <Input placeholder="Enter SAML provider's Entity ID" />
        </Form.Item>
        <Form.Item
          name="ssoUrl"
          label="SAML provider's Sign On URL"
          rules={[{ required: true, message: "Sign on url is required!" }]}
        >
          <Input placeholder="Enter SAML provider's Sign on Url" />
        </Form.Item>
        <Form.Item label="Binding" name={"ssoBinding"}>
          <Select placeholder="Binding">
            <Option value="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect">
              Redirect
            </Option>
            <Option value="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST">
              Post
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="x509"
          label="X509 certificate"
          rules={[{ required: true, message: "X509 certificate is required!" }]}
        >
          <Input.TextArea
            placeholder="Enter X509 certificate"
            autoSize={{ minRows: 4, maxRows: 8 }}
          />
        </Form.Item>
        {!formDisabled && (
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                className="authbtn_100"
                type="primary"
                htmlType="submit"
                onClick={() =>
                  form
                    .validateFields()
                    .then(onSubmit)
                    .catch((err) => {
                      console.log(err);
                    })
                    .finally(() => {
                      setFormDisabled(true);
                    })
                }
              >
                Save
              </Button>
              <Button
                className="authbtn_100"
                onClick={() => setFormDisabled(true)}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        )}
      </Form>

      {formDisabled && (
        <Button type="primary" onClick={() => setFormDisabled(false)}>
          Edit
        </Button>
      )}
    </Spin>
  );
};

export default ProviderForm;
