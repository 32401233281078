
import { 
    AppHttpConfiguration,
    AppWebhookConfiguration,
    App,
} from "types";
import { AppSqlDBConfiguration } from "./sqldb_configuration";

export enum AppSubscriptionStatusType {
    Enabled = "enabled",
    Disabled = "disabled",
}

export interface AppSubscription {
    id: string;
    name: string;
    appID: string;
    apps: App;
    edgeID: string;
    edgeSubscriptionID: string;
    status: AppSubscriptionStatusType;
    parameters: Record<string, any>;
    httpConfiguration: AppHttpConfiguration;
    sqlDBConfiguration: AppSqlDBConfiguration;
    webhookConfiguration?: AppWebhookConfiguration;
    labels: string[];
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;

    //extra field in UI code to take decision to download app subscription or not based on configuraiton avaiability
    isConfigIncluded?: boolean;
}

export type AppSubscriptionUploadObjectType = Omit<AppSubscription, 'edgeInfo' | 'app' | 'status'>;