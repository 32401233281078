import { CopyOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tooltip, Typography, theme } from "antd";
import { useEffect, useState } from "react";
import { useRbacStore } from "store";
import { ResourceType, RoleUserDto } from "types";
import { getStyles } from "../../utility/styles";
import { MutationData } from "./MutationData";
import { PermissionSetEdit } from "./PermissionSetEdit";
import { RoleEdit } from "./RoleEdit";
import { UserEdit } from "./UserEdit";

import { RoleDto } from "types";
import styles from "./Rbac.module.scss";
import { HLink } from "components/Link";
import { notification } from "utility/notification";
import { UserAvatarGroup } from "components/UserAvatarGroup";

const { Text } = Typography;
export interface RoleTabItemProps {
  readOnly: boolean;
}
export const RoleTabItem = (props: RoleTabItemProps) => {
  const [mutationData, setMutationData] = useState<MutationData[]>([]);
  const [editItem, setEditItem] = useState<MutationData | null>(null);

  const deleteRole = useRbacStore((state) => state.deleteRole);
  const roles = useRbacStore((state) => state.roles);

  const pushMutationData = (data: MutationData) => {
    setMutationData([...mutationData, data]);
  };

  const popMutationData = () => {
    const newMutationData = [...mutationData];
    newMutationData.pop();
    setMutationData(newMutationData);
  };

  useEffect(() => {
    if (mutationData.length) {
      const lastMutationData = mutationData[mutationData.length - 1];
      setEditItem(lastMutationData);
    } else {
      setEditItem(null);
    }
  }, [mutationData]);

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);
  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  const onCopyOrEdit = async (role: RoleDto, isCopy: boolean) => {
    const newRole = { ...role };
    if (isCopy) {
      newRole.name = `${role.name} (copy)`;
      newRole.users = [];
    }
    pushMutationData({
      isAdd: isCopy,
      role: newRole,
      type: ResourceType.Role,
    });
  };

  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deleteRole(id);
      }, Promise.resolve());

      notification.success({
        message: `Successfully deleted roles`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: `Error deleting roles`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => popMutationData();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record: RoleDto) => ({
      disabled: record.admin,
    }),
  };

  return (
    <div
      id="roles-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <div
        id="roles-header"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: token.margin,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontSize: token.fontSizeHeading4 }}>
            Total Roles ({Object.keys(roles).length})
          </Text>
          {props.readOnly && (
            <Text type="danger" style={{ fontSize: "0.75rem" }}>
              Create , edit and delete are disabled in the current context
            </Text>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            disabled={props.readOnly}
            onClick={() =>
              pushMutationData({ isAdd: true, type: ResourceType.Role })
            }
          >
            Add
          </Button>
          <Button
            type="primary"
            onClick={() => onDelete(selectedRowKeys)}
            disabled={props.readOnly || !selectedRowKeys.length}
          >
            Delete
          </Button>
        </Space>
      </div>

      <div className={styles.wrapper}>
        <Table
          rowKey={"id"}
          key={`role-table`}
          rowSelection={props.readOnly ? undefined : rowSelection}
          columns={[
            {
              title: "Name",
              render: (_: any, record: RoleDto) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <HLink
                        onClick={() => onCopyOrEdit(record, false)}
                        tooltip={record.description}
                        text={record.name}
                      />
                    </div>
                    <Space>
                      <Tooltip title="Copy">
                        <CopyOutlined
                          onClick={() => onCopyOrEdit(record, true)}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                );
              },
            },
            {
              title: "Users Assigned",
              dataIndex: "users",
              key: "users",
              render: (users: RoleUserDto[], record: RoleDto) => (
                <UserAvatarGroup
                  key={record.id}
                  userIds={users.map((x) => x.id)}
                />
              ),
            },
            {
              title: "Permission Sets Assigned",
              dataIndex: "psets",
              key: "psets",
              render: (_: any, record: RoleDto) => record.psets?.length,
            },
          ]}
          dataSource={Object.values(roles)}
          bordered={true}
          pagination={
            Object.keys(roles).length <= 10 ? false : { pageSize: 10 }
          }
        />
      </div>
      {editItem && editItem.type == ResourceType.User && (
        <UserEdit
          isAdd={editItem.isAdd}
          readOnly={props.readOnly}
          user={editItem.user}
          onClose={onClose}
          onRoleAdd={async () =>
            pushMutationData({
              type: ResourceType.Role,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.Role && (
        <RoleEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          role={editItem.role}
          onClose={onClose}
          onPermissionSetAdd={async () =>
            pushMutationData({
              type: ResourceType.PermissionSet,
              isAdd: true,
            })
          }
          onUserAdd={async () =>
            pushMutationData({
              type: ResourceType.User,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.PermissionSet && (
        <PermissionSetEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          pset={editItem.permissionSet}
          onClose={onClose}
        />
      )}
    </div>
  );
};
