import { Space, Tabs, theme, Typography } from "antd";
import { FC, useEffect, useState } from "react";
const { Text, Title } = Typography;

import { managedmodeIcons } from "assets/icons";

import { MspSubscribers } from "components/MspSubscribers";
import { SvgIcon } from "components/SvgIcon";
import { getUserDetailsFromJWT } from "utility";

import { KpiPageHighlightGrid } from "components/Metrics/KpiPageHighlightGrid";
import { MspSettings } from "components/MspSettings";
import { TagSettings } from "components/TagSettings";
import { useOrganizationStore, useRbacStore, useTokenStore } from "store";
import { Metric, MetricInterval, MiniTileData, WidgetType } from "types";
import PageWrapper from "../../components/PageWrapper";

export const ManagedMode: FC = () => {
  const jwt = useTokenStore((state) => state.token);
  const mspEnabled = useOrganizationStore((state) => state.mspEnabled);
  const subscribers = useOrganizationStore((state) => state.subscribers);
  const tags = useOrganizationStore((state) => state.tags);
  const users = useRbacStore((state) => state.users);
  const roles = useRbacStore((state) => state.roles);

  const { token } = theme.useToken();
  const tabItems = [
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={managedmodeIcons.managedModeSettings} />
          <Text>Subscribers</Text>
        </Space>
      ),
      key: "1",
      forceRender: true,
      children: <MspSubscribers />,
    },

    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={managedmodeIcons.managedModeTags} />
          <Text>Tags</Text>
        </Space>
      ),
      key: "2",
      children: <TagSettings />,
    },
    {
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={managedmodeIcons.managedModeSettings} />
          <Text>Settings</Text>
        </Space>
      ),
      key: "3",
      forceRender: true,
      children: <MspSettings />,
    },
  ];

  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [tenantId, setTenantId] = useState<string>("");

  useEffect(() => {
    const details = getUserDetailsFromJWT();
    setTenantId(details.tenantId);
  }, [jwt]);

  useEffect(() => {
    const metrics: Metric[] = [];
    if (subscribers) {
      const subscriberCount = Object.keys(subscribers).length;
      const providers = Object.values(subscribers).filter((x) => {
        const components = x.nodePath.split("/");
        return components.length == 2 && components[0] == tenantId;
      });

      const subscriberMiniTile: MiniTileData = {
        interval: MetricInterval.DAY,
        primary: {
          text: "MSP",
          value: providers.length,
          unit: "",
        },
        secondary: {
          text: "Customers",
          value: subscriberCount,
          unit: "",
        },
        tertiary: {
          items: providers.map((x) => ({
            id: x.tenantId,
            name: x.name,
            description: x.name,
          })),
        },
      };

      const customerMiniTile: MiniTileData = {
        interval: MetricInterval.DAY,
        primary: {
          text: "Customers",
          value: subscriberCount,
          unit: "",
        },
        secondary: {
          text: "MSP",
          value: providers.length,
          unit: "",
        },
        tertiary: {
          items: Object.values(subscribers).map((x) => ({
            id: x.tenantId,
            name: x.name,
            description: x.name,
          })),
        },
      };
      const tagsMiniTile: MiniTileData = {
        interval: MetricInterval.DAY,
        primary: {
          text: "Tags",
          value: Object.keys(tags).length,
          unit: "",
        },
        secondary: {
          text: "Customers",
          value: subscriberCount,
          unit: "",
        },
        tertiary: {
          items: Object.values(tags).map((x) => ({
            id: x.id,
            name: x.value,
            description: x.value,
          })),
        },
      };
      const usersMiniTile: MiniTileData = {
        interval: MetricInterval.DAY,
        primary: {
          text: "Users",
          value: Object.keys(users).length,
          unit: "",
        },
        secondary: {
          text: "Roles",
          value: Object.keys(roles).length,
          unit: "",
        },
        tertiary: {
          items: Object.values(users).map((x) => ({
            id: x.id,
            name: `${x.firstName} ${x.lastName}`,
            description: x.title,
          })),
        },
      };

      const rolesMiniTile: MiniTileData = {
        interval: MetricInterval.DAY,
        primary: {
          text: "Roles",
          value: Object.keys(roles).length,
          unit: "",
        },
        secondary: {
          text: "Users",
          value: Object.keys(users).length,
          unit: "",
        },
        tertiary: {
          items: Object.values(roles).map((x) => ({
            id: x.id,
            name: x.name,
            description: x.name,
          })),
        },
      };

      metrics.push({
        id: "msp-subscribers",
        widgetType: WidgetType.MiniTile,
        data: subscriberMiniTile,
      });
      metrics.push({
        id: "msp-customers",
        widgetType: WidgetType.MiniTile,
        data: customerMiniTile,
      });
      metrics.push({
        id: "msp-tags",
        widgetType: WidgetType.MiniTile,
        data: tagsMiniTile,
      });
      metrics.push({
        id: "users-total",
        widgetType: WidgetType.MiniTile,
        data: usersMiniTile,
      });
      metrics.push({
        id: "roles-total",
        widgetType: WidgetType.MiniTile,
        data: rolesMiniTile,
      });
    }
    setMetrics(metrics);
  }, [subscribers, tags, users, roles, tenantId]);

  return (
    <PageWrapper
      highlights={<KpiPageHighlightGrid key="managedmode" metrics={metrics} />}
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <Title level={3}>Managed Services</Title>
          <Tabs
            items={tabItems.filter((x) => (mspEnabled ? true : x.key != "1"))}
          />
        </div>
      }
    ></PageWrapper>
  );
};
