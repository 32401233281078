
import {conditionOperators, logicalAndOperator, logicalOrOperator, logicalOperators} from "types"
export const enum TokenTypes {
    Projection =  "Projection",
    Where =  "Where",
    ConditionArtifactField =  "ConditionArtifactField",
    ConditionOperator = "ConditionOperator",
    ConditionValue = "ConditionValue",
    LogicalOperator = "LogicalOperator",
    LogicalAndOperator = "LogicalAndOperator",
    LogicalOrOperator = "LogicalOrOperator",
    WhiteSpace = "Whitespace"
} 


export const whitespaceRegEx = /\s+/
export const whereRegEx = /where/;
export const conditionOperatorRegEx = RegExp(conditionOperators.join("|"))
export const logicalAndOperatorRegEx = RegExp(logicalAndOperator);
export const logicalOrOperatorRegEx = RegExp(logicalOrOperator);
export const logicalOperatorRegEx = RegExp(logicalOperators.join("|"))
export const artifactRegEx  = /[a-zA-Z][a-zA-Z_-]*/;
export const artifactFieldRegEx = /[a-zA-Z][a-zA-Z0-9_.]*/;
export const IdentRegEx             = /"([^"]+)"|([^\s]+)/;
