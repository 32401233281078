import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { PageInfo, CaseNote, CaseNotesStore } from "types";

import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import {
  getCaseNotesApi,
  getCaseNoteApi,
  createCaseNoteApi,
  updateCaseNoteApi,
  deleteCaseNoteApi,
} from "api";
import { convertToApiFilters, convertToApiSorters } from "store/utils";

export const useCaseNotesStore = create<CaseNotesStore>()(
  devtools((set, get) => ({
    notes: [] as CaseNote[],
    selectedNote: {} as CaseNote,
    pageInfo:  {number:1, size: 12, total: 0} as PageInfo,
    filter: {} as Record<string, FilterValue | null>,
    sorter: {} as SorterResult<DataType>,
    searchTextMap: new Map<string, string>(),
    totalCount: 0,
    
    getCaseNotes: async (caseId: string, all?: boolean) => {
      try {
        const pageInfo = get().pageInfo;
        const filters = get().filter;
        const searches = get().searchTextMap;
        const sorters = get().sorter;
        
        const notesFilters = convertToApiFilters(filters, searches)     
        const notesSorters = convertToApiSorters(sorters);
        //default sorting
        if (notesSorters.length == 0) {
          notesSorters.push("-updatedAt");
        }

        const pageNumber = all ? undefined : pageInfo.number;
        const pageSize = all ? undefined : pageInfo.size
        const [notes, totalCount] = await getCaseNotesApi(caseId, pageNumber, pageSize, notesFilters, notesSorters);

        set((state) => ({
          ...state,
          notes: notes,
          totalCount: notes.length,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearCaseNotes: () => {
      set((state) => ({
        ...state,
        notes: [],
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getCaseNote: async (caseId: string, noteId: string) => {
      try {
        let caseNote = get().notes.find((c) => c.id == noteId);
        if (!caseNote) {
          caseNote = await getCaseNoteApi(caseId, noteId);
        }

        set((state) => ({
          ...state,
          selectedCaseNote: caseNote,
        }));
        return caseNote;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    createCaseNote: async (caseId: string, caseNote: CaseNote) => {
      try {
        const caseNoteOut = await createCaseNoteApi(caseId, caseNote);
        set((state) => {
          const index = state.notes.findIndex((x) => x.id == caseNoteOut.id);
          index == -1 ? state.notes = [...state.notes, caseNoteOut] : state.notes[index] = caseNoteOut;
          state.selectedNote = caseNoteOut;
          return { ...state }
        });
        return caseNoteOut;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    updateCaseNote: async  (caseId: string, caseNote: CaseNote) =>  {
      try {
        await updateCaseNoteApi(caseId, caseNote);
        set((state) => {
          const idx =  state.notes.findIndex(c => c.id == caseNote.id);
          state.notes[idx] = caseNote;
          return {
            ...state,
            selectedCaseNote: caseNote
          }
        });
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },

    deleteCaseNote: async (caseId: string,  noteId: string) => {
      try {
        await deleteCaseNoteApi(caseId, noteId);
        set((state) => {
          state.notes = state.notes.filter(c => c.id !== noteId);
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },

    setFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, filter : filters};
      });
      return;
    },

    setSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, sorter : sorters};
      });
      return;
    },

    setSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        searchTextMap: new Map<string, string>(searches),
      }));
    },
  }),
    { name: "CaseNotesStore" }
  )
);
