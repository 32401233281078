import { create } from "zustand"
import { devtools, persist } from "zustand/middleware";
import { AuthStore } from "types";

import {
  initiateSignUpApi,
  signUpSetPasswordApi,
  signUpValidateApi,
} from "api";
import { sha256 } from "js-sha256";


export const useAuthStore = create<AuthStore>()(
  devtools(
    persist((set, get) => ({      
      user: {},      
      token: null,
      expiry: null,
      isAuthenticated: false,
      loginStateChanged: false,
      pollIntervalMs : 1000 * 60 * 5, // Every 5 mins by default
      initiateSignUp: async (business, email, firstName, lastName, phone, region, code, challenge) => {
        try {
          const response = await initiateSignUpApi(business, email,firstName, lastName, phone,region,code, challenge);
          set((state) => ({
            user: {
              ...state.user,
              tenantId: response.data.tenant_id,
              email: email,
              phone: phone,
              region: region,
              challenge: challenge,
            },
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      resendOTP: async () => {
        try {
          const firstName = String(get().user.firstName);
          const lastName = String(get().user.lastName);
          const business = String(get().user.business);
          const email = String(get().user.email);
          const phone = String(get().user.phone);
          const region = String(get().user.region);
          const challenge = String(get().user.challenge);
          const code = String(get().user.code);

          const response = await initiateSignUpApi(business,email,firstName, lastName, phone,region,code, challenge);

          set((state) => ({
            user: {
              ...state.user,
              challenge: challenge,
            },
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      signUpValidate: async (otp) => {
        try {
          const tenantId = String(get().user.tenantId);
          const verifier = sha256(String(get().user.challenge));
          await signUpValidateApi(verifier,otp,tenantId);
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      signUpSetPassword: async (password) => {
        try {
          const tenantId = String(get().user.tenantId);
          const verifier = sha256(String(get().user.challenge));
          await signUpSetPasswordApi(verifier,password,tenantId);
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },              
    }),
    { name: "AuthStore" }),
  { name: "AuthStore" })
);
