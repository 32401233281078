import { useEffect } from "react";
import { Switch, Typography } from "antd";
import { createUseStyles } from "react-jss";
import { FieldProps } from "./common";
import { FieldLabel } from "./fieldLabel";
const { Text } = Typography;
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    flexBasis: "20%",
    minWidth: "200px",
  },
  right: {},
});

/**
 * Cannot use text area with options
 */
interface BooleanFieldProps extends FieldProps {
  checkedChildren: string;
  unCheckedChildren: string;
  defaultChecked: boolean;
  value: boolean;
  onChange: (key: string, value: boolean) => void;
}

/** react function component */
export const BooleanField = (props: BooleanFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <FieldLabel fp={props} />
      </div>
      <div className={classes.right}>
        <Switch
          checked={props.value}
          checkedChildren={props.checkedChildren}
          unCheckedChildren={props.unCheckedChildren}
          defaultChecked={props.defaultChecked}
          onChange={(checked) => props.onChange(props.identifier, checked)}
        ></Switch>
      </div>
    </div>
  );
};
