import { FC, useState, useEffect } from "react";
import { Popover, Spin, Typography,Space, theme, Avatar, Badge, Tag } from "antd";
import { useOrganizationStore, useUserInfoStore, useWorkspaceItemStore } from "store";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { WorkflowRun, WorkflowRunStatusCode, WorkflowRunStatusCodeMap, WorkspaceTabType, dateTimeFormatOptions } from "types";
import { useNavigate } from "react-router-dom";
import { deleteWorkflowRunApi } from "api";
import { HLink } from "components/Link";
import { getTagColor } from "utility";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";

const { Text, Link } = Typography;

const WorkflowRunTab: FC = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { mspEnabled, context, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags,
  }));


  const { 
    workspaceItemsMap, 
    getWorkspaceItems, 
    workflowRunsPage, 
    setCurrentPage,
    workflowRunsFilters,
    setWorkspaceFilters,
    workflowRunsSorters,
    setWorkspaceSorters,
  } = useWorkspaceItemStore((state) => ({
    workspaceItemsMap: state.workspaceItemsMap,
    getWorkspaceItems: state.getWorkspaceItems,
    workflowRunsPage: state.workspacePage[WorkspaceTabType.WorkflowRuns],
    setCurrentPage: state.setCurrentPage,
    workflowRunsFilters: state.workspaceFilters[WorkspaceTabType.WorkflowRuns],
    setWorkspaceFilters: state.setWorkspaceFilters,
    workflowRunsSorters: state.workspaceSorters[WorkspaceTabType.WorkflowRuns],
    setWorkspaceSorters: state.setWorkspaceSorters,
  }));


  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const loadItems = async () => {
    try {
      setLoader(true);
      await getWorkspaceItems(WorkspaceTabType.WorkflowRuns,  workflowRunsPage?.number as number, workflowRunsPage?.size as number);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onPageChange = (pageNumber : number, pageSize : number)  => {
    setLoader(true);
    setCurrentPage(WorkspaceTabType.WorkflowRuns, pageNumber, pageSize)
    getWorkspaceItems(WorkspaceTabType.WorkflowRuns, pageNumber, pageSize).then( () => {
      setLoader(false);
    });
  }

  useEffect(() => {
    loadItems();
  }, [workflowRunsFilters, workflowRunsSorters, context]);

  //Load user infos of workspace items
  useEffect(() => {
    const userIds = [] as string[];
    const items = workspaceItemsMap.get(WorkspaceTabType.WorkflowRuns);
    items && items?.map((workspaceItem: any) => {
      workspaceItem.userID && userIds.push(workspaceItem.userID);
      workspaceItem.user && userIds.push(workspaceItem.user);
    });
    userIds.length > 0 && loadUserInfos(userIds);
  }, [workspaceItemsMap?.get(WorkspaceTabType.WorkflowRuns)]);

  const renderStatus = (status: string): JSX.Element => {
    const statusCode = status as WorkflowRunStatusCode;
    switch (statusCode) {
      case WorkflowRunStatusCode.Running:
        return <Text type="warning">{WorkflowRunStatusCodeMap.get(statusCode)}</Text>;
      case WorkflowRunStatusCode.PendingApproval:
        return <Text type="secondary">{WorkflowRunStatusCodeMap.get(statusCode)}</Text>;
      case WorkflowRunStatusCode.Completed:
        return <Text type="success">{WorkflowRunStatusCodeMap.get(statusCode)}</Text>;
      case WorkflowRunStatusCode.Failed:
      case WorkflowRunStatusCode.Canceled:
      case WorkflowRunStatusCode.Terminated:
      case WorkflowRunStatusCode.TimedOut:
        return <Text type="danger">{WorkflowRunStatusCodeMap.get(statusCode)}</Text>;
      default:
        return <></>;
    }
  };

  const columns: ColumnsType<WorkflowRun> = [
    {
      title: "Execution Id",
      dataIndex: "",
      key: "name",
      render: (record) => {
        const prefix = record.id?.split("-")[0];
        const name = record.name != "" ? record.name : `workflow-execution-${prefix}`;
        return (
          <Text>
            <Popover content={<Text copyable>{record.id}</Text>}>
              <div>
                <HLink onClick={() => navigate(`/workflow-runs/${record.id}`)}  text={name} />
              </div>
            </Popover>
          </Text>
        );
      },
    },
    {
      title: "Executed By",
      dataIndex: "",
      key: "user",
      render: (record) => {
        const user = record.user != "" ? record.user : record.userID;
        return (
          <Space size={token.marginXXS}>
            {userInfos[user]?.logoUrl && (
              <Avatar src={userInfos[user]?.logoUrl} />
            )}
            <Text>
              {userInfos[user]?.firstName} {userInfos[user]?.lastName}
            </Text>
          </Space>
        );
      },
    },
    {
      title: "Tag",
      dataIndex: "tagID",
      key: "tagID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: workflowRunsFilters["tagID"],
      render: (tagID: string) => {
        const tag = tags?.find((tag) => (tag.id == tagID));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Owner",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: workflowRunsFilters["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => (tag.id == `${tenantID}_tag`));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Workflow",
      dataIndex: "",
      key: "workflowID",
      render: (record) => (
        <HLink  onClick={() => navigate(`/workflows/${record.workflowID}`)} text={record.workflows.name} />
      ),
    },
    {
      title: "Started On",
      dataIndex: "startTime",
      key: "startTime",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (startTime: string) =>
        new Date(startTime).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "Completed On",
      dataIndex: "closeTime",
      key: "closeTime",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (closeTime: string) => (
        !closeTime.match("0001-01-01T00:00:00Z")
          ?
            new Date(closeTime)?.toLocaleTimeString(
              undefined,
              dateTimeFormatOptions
            )
          :  <></>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterSearch: true,
      filters: Array.from(WorkflowRunStatusCodeMap, ([tag, value]) => ({ text: value, value: tag })),
      filteredValue: workflowRunsFilters["status"],
      render: renderStatus,
    },
  ];

  const deleteAllowed = (record: WorkflowRun) => {
    return  record.status != WorkflowRunStatusCode.Running ;
  };

  const deleteItems = async (itemKeys: string[]) => {
    await Promise.all(
      itemKeys.map(async (itemKey) => {
        try {
          return await deleteWorkflowRunApi(itemKey);
        } catch (error) {
          console.error(
            "failed to delete workflow run",
            itemKey,
            error
          );
        }
      })
    )
  }

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setWorkspaceSorters(WorkspaceTabType.WorkflowRuns, sorter);
    setWorkspaceFilters(WorkspaceTabType.WorkflowRuns, filters);
    setCurrentPage(WorkspaceTabType.WorkflowRuns);
  }

  return (
    <Spin spinning={loader}> 
      <EntitiesDisplay
        header={"Workflow Executions"}
        dataSource={workspaceItemsMap.get(WorkspaceTabType.WorkflowRuns) as any[]}
        columns={columns.filter(item => !item.hidden)}
        pageNumber={workflowRunsPage?.number}
        pageSize={workflowRunsPage?.size}
        total={workflowRunsPage?.total as number}
        onChange={onChange}
        onPageChange={onPageChange}
        getItemProps={(record: WorkflowRun) => {
          return {
            disabled: record.isSubworkflowRun || `${record.tenantID}_tag` != context,
          };
        }}
        actions={
          [
            {
              key: "delete", 
              label: "Delete", 
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => workspaceItemsMap?.get(WorkspaceTabType.WorkflowRuns)?.find((item) => item.id == id && deleteAllowed(item)))
                } else {
                  return false
                }
              }, 
              showWarn: true,
              onClick: (itemIds) => {
                deleteItems(itemIds as string[])
                  .then(() => loadItems())
                  .catch((error) => console.error("failed to delete workspace items", error));
              }
            }
          ]
        }
      />
    </Spin>
  );
};

export default WorkflowRunTab;
