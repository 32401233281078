import { FC, ReactNode } from "react";
import { theme, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;

export interface HeaderTitleProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  onClose: () => Promise<void>;
}

export const DrawerHeaderTitle: FC<HeaderTitleProps> = ({
  title,
  subtitle,
  onClose,
}) => {
  const { token } = theme.useToken();
  const { margin } = token;

  return (
    <div style={{ margin, justifyContent: "space-between", display: "flex" }}>
      <div>
        <Title level={3}>{title}</Title>
        {subtitle && <Text>{subtitle}</Text>}
      </div>
      <div>
        <CloseOutlined onClick={onClose} />
      </div>
    </div>
  );
};
