/**
 * Custom field for uploading images images
 */

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Space, Typography, Upload } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDeveloperStore } from "store";
import { useTokenStore } from "store/token";
import { Content } from "types";
import { v4 as uuidv4 } from "uuid";
import { FieldProps } from "./common";
import { EditorContext } from "./editorContext";
import { FieldLabel } from "./fieldLabel";
const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    flexBasis: "20%",
    minWidth: "200px",
  },
  right: {},
});

export interface ImageFieldProps extends FieldProps {
  max: number;
  value: string;
  /** item is enabled based on option  */
  enabled?: boolean;
  hideLabel?: boolean;
  externalUploadButton?: JSX.Element;
  onChange: (key: string, value: string) => void;
}

export const ImageField = (props: ImageFieldProps) => {
  useEffect(() => {
    //console.log(`path : ${props.path}`);
  }, []);

  const editorContext = useContext(EditorContext);
  const token = useTokenStore((state) => state.token);
  const getDownloadUrl = useDeveloperStore((state) => state.getDownloadUrl);
  const getUploadUrl = useDeveloperStore((state) => state.getUploadUrl);

  const [loading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const [tenantId, setTenantId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  /** One time initialization of tennat id and user id using JWT in localstorage */
  useEffect(() => {
    const asyncUseEffect = async () => {
      if (token) {
        const payload = token.split(".")[1];
        const decoded = JSON.parse(atob(payload));
        const tenantId = decoded["https://hypredge.com/rbac/zs_tenant_id"];
        const userId = decoded["https://hypredge.com/rbac/zs_user_id"];
        setTenantId(tenantId);
        setUserId(userId);
      }
    };
    asyncUseEffect();
  }, []);

  /** Generate a new guid for the file before upload, and generate an upload url using it  */
  const beforeUpload = async (file: RcFile) => {
    if (file.uid !== props.value) {
      file.uid = uuidv4();
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }
    return file;
  };

  const handleChange = async (info: UploadChangeParam<UploadFile>) => {
    const { file } = info;
    if (file.status === "removed") {
      setFileList(fileList.filter((f) => f.uid !== file.uid));
    } else if (file.status === "uploading") {
      setFileList([...fileList, file]);
    } else if (file.status === "done") {
      const response = await getDownloadUrl(file.uid);
      const { url } = response;
      const newFile = {
        ...file,
        url,
      };

      setFileList(fileList.filter((f) => f.uid !== file.uid).concat(newFile));
      // setImageUrls([...imageUrls, url]);
      props.onChange(props.identifier, fileList.map((f) => f.uid).join(","));
    }
  };

  const handleUpload = async ({ onSuccess, onError, file }: any) => {
    const xhr = new XMLHttpRequest();
    const response: Content = await getUploadUrl(file.uid, file.type, true);

    // S3 requires PUT method!
    xhr.open("PUT", response.url);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.setRequestHeader("x-amz-meta-tenantid", tenantId);
    xhr.setRequestHeader("x-amz-meta-userid", userId);
    xhr.setRequestHeader("x-amz-meta-restricted", "true");
    xhr.onreadystatechange = async () => {
      if (xhr.readyState === 4) {
        xhr.status === 200
          ? onSuccess(null, file)
          : onError(xhr.responseText, xhr.response, file);
      }
    };
    xhr.send(file);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const classes = useStyles();

  return (
    <Space
      direction="vertical"
      size={"small"}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* label */}
      {!props.hideLabel && <FieldLabel fp={props} />}
      <Upload
        name="avatar"
        accept="image/jpg,image/jpeg,image/png"
        listType="picture-circle"
        showUploadList={true}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        disabled={props.enabled ? !props.enabled : editorContext.view}
      >
        {fileList.length < props.max
          ? props.externalUploadButton
            ? props.externalUploadButton
            : uploadButton
          : null}
      </Upload>
    </Space>
  );
};
