import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import {ssoUrlApiPath, tenantsApiPath} from "./constant"
import {passwordResetApiPath} from "./constant"

export const initiateSignUpApi = async (
  business: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  region: string,
  code: string,
  challenge: string,
  ) : Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor("post", tenantsApiPath, {
    business,
    email,
    firstName,
    lastName,
    phone,
    region,
    code,
    challenge,
  });
  return response;
};

export const signUpValidateApi = async (
  verifier: string,
  otp: string,
  tenantId: string
  ) : Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor("post",`${tenantsApiPath}/${tenantId}`, {
    otp,
    verifier,
  });

  return response;
};

export const signUpSetPasswordApi = async (
  verifier: string,
  password: string,
  tenantId: string,
  ):Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor("patch",`${tenantsApiPath}/${tenantId}`, {
    password,
    verifier,
  });  
  return response;
};

export const signupResetPasswordApi = async ( email: string) : Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor("post", passwordResetApiPath, {
    email,
  });
  return response;
}
