import { FC, useState, useEffect, useReducer } from "react";

import { Form, Button, Space, FormInstance, theme } from "antd";
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

import Modal from "components/Modal";
import CollapsePanel from "components/CollapsePanel";
import HttpHeader, { HttpHeaderData,  } from "./Header"
import { validateFormFields } from "utility";


interface DeleteHeaderProps {
  enable: boolean;
  key?: number;
  name?: string;
}

export interface HttpHeadersProps {
  editMode: boolean;
  headers: HttpHeaderData[];
  onChange?: (value: HttpHeaderData[]) => void;
  onRender?: (form: FormInstance) => void;
}
const HttpHeaders: FC<HttpHeadersProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentHeaders, setCurrentHeaders] = useState<HttpHeaderData[]>(props.headers);
  const [deleteHeaderProps, setDeleteHeaderProps] = useState<DeleteHeaderProps>({enable: false});

  const [headersForms, dispatch] = useReducer((headersForms: FormInstance[], action: any) => {
    switch (action.type) {
      case 'add': {
        const forms = [...headersForms];
        forms.splice(action.key, 0, action.form);
        return forms;
      }
      case 'delete': {
        const forms = [...headersForms];
        forms.splice(action.key, 1);
        return forms;
      }
      default:
        throw new Error(`Unknown action type: ${action.type}`);
    }
  }, []);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, values: any) => {
    setCurrentHeaders(form.getFieldValue("headers"));
    props.onChange?.(form.getFieldValue("headers"));
  };

  const onHeaderAdd = () => {
    let headers = currentHeaders ? currentHeaders.map((_, index) =>  form.getFieldValue(["headers", index])) : [] ;
    headers = [...headers, {key: `header_${headers.length}`, displayKey: `Header ${headers.length}`, value: "" ,isSecret: false, }];
    form.setFields([{name:["headers"], value: headers}]);
    setCurrentHeaders(headers);
  };

  const onHeaderDelete = () => {
    if (deleteHeaderProps.key != undefined) {
      const headers = currentHeaders.map((_, index) =>  form.getFieldValue(["headers", index]));
      headers.splice(deleteHeaderProps.key, 1);
      dispatch({type: "delete", key: deleteHeaderProps.key});
      form.resetFields(["headers", deleteHeaderProps.key]);
      form.setFields([{name:["headers"], value: headers}]);
      setCurrentHeaders(headers);
      setDeleteHeaderProps({enable: false});
    }
  };

  useEffect(() => {
    props.onChange?.(currentHeaders);
  }, [currentHeaders, deleteHeaderProps])
 
  return (
    <>
      <Form 
        form={form} 
        name="headersForm" 
        autoComplete="off" 
        layout="vertical"
        initialValues={{headers: props.headers}}
        onValuesChange={onValuesChange}>
          <Form.List 
            name="headers">
            {(fields) => (
              <Space direction="vertical" style={{ display: 'flex' }}>
                {fields.map((field) => (
                  <CollapsePanel 
                    key={field.key}
                    name={currentHeaders[field.key].displayKey}
                    collapsePanel={true}
                    extraElement={
                      props.editMode && 
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        onClick={(e: any) => {e.stopPropagation(); setDeleteHeaderProps({enable: true,  key: field.key, name: currentHeaders[field.key].key})}}
                      />
                    }
                  > 
                    <Form.Item 
                      {...field} 
                      key={field.key} 
                      name={[field.key]}
                      rules={[
                        { validator: (_, value) =>  validateFormFields(headersForms[field.key]) },
                      ]}>
                        <HttpHeader
                          editMode={props.editMode}
                          header={currentHeaders[field.key]} 
                          onRender={form => dispatch({type: "add", key: field.key, form: form})}/>
                    </Form.Item>
                  </CollapsePanel>
                ))}
                {props.editMode && 
                  <div style={{width : "50%", justifyContent: "center", alignItems: "center", margin: "auto"}}>
                    <Button 
                      type="default" 
                      style={{ background: token.colorPrimaryBg }} 
                      onClick={onHeaderAdd} 
                      block 
                      icon={<PlusOutlined />}
                    >
                      Header
                    </Button>
                  </div>
                }
              </Space>
            )}
          </Form.List>
      </Form>
      { deleteHeaderProps.enable && 
        (<Modal
            title="Delete Header"
            onClose={() => {setDeleteHeaderProps({enable: false})}}
            open={deleteHeaderProps.enable}
            onSubmit={() => onHeaderDelete()}
          >
            {"Are you sure you want to delete header \"" + `${deleteHeaderProps?.name}` + "\" ?"}
          </Modal>
        )
      }
    </>
  );
};
  
export default HttpHeaders;

