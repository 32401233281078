import { useState, useEffect } from "react";
import { Form, Input, Select, Space, Typography, Upload, UploadFile } from "antd";
import type { SelectProps } from 'antd';

import { FieldLabel } from "components/FieldLabel";
import { FC } from "react";
import { useDeveloperXStore } from "store";
import { AdapterType, SpecNameValidationState } from "types";
import { capitalizeWords } from "utility";
import TextArea from "antd/es/input/TextArea";
import AppLogos from "./appLogos";
import AppHttpConfiguration from "./HttpConfig/appHttpConfiguration";

import CollapsePanel from "components/CollapsePanel";
import { update } from "lodash";

const { Text } = Typography;

export type AppOverviewTabProps = {
  editMode: boolean
  onChange: (now: boolean) => void;
}
const AppOverviewTab: FC<AppOverviewTabProps> = ({
  editMode,
  onChange,
}) => {
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [adapterTypes, setAdapterTypes] = useState<string[]>([]);
  const [nameValidationState, setNameValidationState] = useState(SpecNameValidationState.Unknown);

  const {
    selectedApp,
  } = useDeveloperXStore((state) => ({
    selectedApp: state.selectedApp,
  }));

  const onValuesChange = async () => {
    onChange(false);
    // const currentValues = form.getFieldsValue(true);  
    // const newValues: any = {}; 
    // parameters && Object.entries(parameters).map(([name, value]) => {
    //   if (name in currentValues) {
    //     if (value.type == IODataType.Array) {
    //       newValues[name] = (currentValues[name] as string).split(" ");
    //     } else {
    //       newValues[name] = currentValues[name];
    //     }
    //   }
    // });
    // onChange?.(newValues);
  };

  const onLogosChange = async () => {
    onChange(true);
  };

  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };


  const syncCurrentState = async () => {
    try {
      form.setFieldsValue({
        name: selectedApp.displayName,
        description: selectedApp.description,
        adapterTypes: selectedApp.adapterTypes,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncCurrentState()
  }, [selectedApp]);

  return (
    <Form
      form={form}
      name="parametersForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
      style={{ width: "50%" }}
      disabled={!editMode}
    >
      <Space size={0} direction="vertical" style={{ display: 'flex' }}>
        <Form.Item
          name={"name"}
          label={<FieldLabel bold label={"Name"} help={"App Name"} />}
          rules={getRules("name", true)}
        >
          <Input
            placeholder={`Enter app name`}
            maxLength={2048}
            onChange={(e) => {
              form.setFieldValue("name", capitalizeWords(e.target.value))
            }}
          />
        </Form.Item>
        <Form.Item
          name={"description"}
          label={<FieldLabel bold label={"Description"} help={"Description about the app"} />}
          rules={getRules("description", true)}
        >
          <TextArea
            placeholder={`Enter app description`}
            maxLength={4096}
          />
        </Form.Item>
        <FieldLabel bold label={"Logos"} help={"App logos for visibility"} />
        <AppLogos editMode={editMode} onChange={onLogosChange}/>
        <Form.Item
          name="adapterTypes"
          required
          label={<FieldLabel bold label={"Supported Adapter Types"} help={"Adapter Types supported by this app"} />}
          rules={[
            { required: true, message: `Adapter type must be selected!` }
          ]}
          validateTrigger="onSubmit"
        >
          <Select
            placeholder={`Select Adapter Type`}
            allowClear
            mode="multiple"
            value={adapterTypes}
          >
            {Object.values(AdapterType)?.map((id) => (
              <Select.Option key={id} value={id}>
                {id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedApp.adapterTypes?.find( (x) => x == AdapterType.Http) && 
          <CollapsePanel
            name={
              <Text strong>Http Configuration</Text>
            }
            ghost={false}
            collapsePanel={false}
          >
            <AppHttpConfiguration />
          </CollapsePanel>
        }
      </Space>
    </Form>

  );
};

export default AppOverviewTab;
