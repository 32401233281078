import { AxiosHeaders, AxiosResponse } from "axios";
import { SpecStateType, Action, TriggerType, Operator, Trigger, Workflow, WorkflowActionType, WorkflowRun,SpecActionType, Policy, ActionProvider, TriggerProvider, WorkflowFile} from "types";
import { AxiosConfig, axiosInterceptor } from "./axios";
import {
  actionsApiPath, jsonApiDeserializer, operatorsApiPath, workflowPoliciesApiPath, triggersApiPath, workflowRunsApiPath, workflowsApiPath,
  actionProvidersApiPath,
  triggerProvidersApiPath,
  workflowFilesApiPath
} from "./constant";
import { dtoFactory } from "utility/resource";

export const getActionsApi = async (
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[Action[], number]> => {  

  let url = `${actionsApiPath}?fields[actions]=name,displayName,description,state,shared,type,updatedAt,outputs,category,visibility`;
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url);
  const actions =  jsonApiDeserializer.deserialize(response.data)  as Action[];
  return [actions, response?.data?.meta?.totalCount];
};

export const getActionVersionsApi = async (actionId: string):Promise<Action[]> => {
  if(actionId) {
    const response = await axiosInterceptor(
      "get",
      `${actionsApiPath}?includeVersions=true&filter=equals(id,'${actionId}')&fields[actions]=comments`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as Action[];
  }
  throw new Error("actionId should be present in the action versions get");
};


export const getActionApi = async (actionId: string) :Promise<Action>=> {
  const response = await axiosInterceptor(
    "get",
    `${actionsApiPath}/${actionId}`
  );
  return jsonApiDeserializer.deserialize(response.data)  as Action;
};

export const deleteActionApi = async (actionId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${actionsApiPath}/${actionId}`);
};


export const createActionApi = async (action: Action) :  Promise<Action>=> {
  const response = await axiosInterceptor(
    "post",
    actionsApiPath,
    {
        "data": {
            "type": "actions",
            "attributes":  dtoFactory.convertActionToUploadObject(action),
        }
    }
  );
  const actionOut = jsonApiDeserializer.deserialize(response.data)  as Action;
  return actionOut;
};

export const updateActionApi = async (action: Action): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${actionsApiPath}/${action.id}`,
    {
      "data": {
        "type": "actions",      
        "id": action.id,
        "attributes": dtoFactory.convertActionToUploadObject(action),
      }
    }
  );
};

export const actionActionApi = async (actionId: string, actionType: SpecActionType, action?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${actionsApiPath}/${actionId}/${actionType}`,
      action 
      && {
            "data": {
              "type": "actions",      
              "id": actionId,
              "attributes": action,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const getTriggersApi = async (
  customOnly?: boolean,
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[Trigger[], number]> => {  
  let url = `${triggersApiPath}?fields[triggers]=name,displayName,description,state,shared,type,updatedAt,outputs`;
  if (customOnly) 
    url += `&filter=equals(type,'${TriggerType.Custom}')`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  
  const response = await axiosInterceptor("get", url);
  const triggers =  jsonApiDeserializer.deserialize(response.data)  as Trigger[];
  return [triggers, response?.data?.meta?.totalCount];
};

export const getTriggerVersionsApi = async (triggerId: string):Promise<Trigger[]> => {
  if(triggerId) {
    const response = await axiosInterceptor(
      "get",
      `${triggersApiPath}?includeVersions=true&filter=equals(id,'${triggerId}')&fields[triggers]=comments`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as Trigger[];
  }
  throw new Error("triggerId should be present in the trigger versions get");
};

export const getTriggerApi = async (triggerId: string): Promise<Trigger> => {
  const response = await axiosInterceptor(
    "get",
    `${triggersApiPath}/${triggerId}`
  );
  return jsonApiDeserializer.deserialize(response.data)  as Trigger;
};

export const createTriggerApi = async (trigger: Trigger) :  Promise<Trigger>=> {
  const response = await axiosInterceptor(
    "post",
    triggersApiPath,
    {
        "data": {
            "type": "triggers",
            "attributes":  dtoFactory.convertTriggerToUploadObject(trigger),
        }
    }
  );
  const triggerOut = jsonApiDeserializer.deserialize(response.data)  as Trigger;
  return triggerOut;
};

export const deleteTriggerApi = async (triggerId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${triggersApiPath}/${triggerId}`);
};

export const updateTriggerApi = async (trigger: Trigger): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${triggersApiPath}/${trigger.id}`,
    {
      "data": {
        "type": "triggers",      
        "id": trigger.id,
        "attributes": dtoFactory.convertTriggerToUploadObject(trigger),
      }
    }
  );
};

export const actionTriggerApi = async (triggerId: string, action: SpecActionType, trigger?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${triggersApiPath}/${triggerId}/${action}`,
      trigger 
      && {
            "data": {
              "type": "triggers",      
              "id": triggerId,
              "attributes": trigger,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const getOperatorsApi = async () : Promise<Operator[]>=> {
  const response = await axiosInterceptor(
    "get",
    operatorsApiPath
  );
  
  return jsonApiDeserializer.deserialize(response.data)  as Operator[];
};

export const getWorkflowsApi= async (
  runInfoRequired?: boolean,
  sharedOnly?: boolean,  
  pageNumber?: number,
  pageSize?: number, 
  subworkflowsOnly?: boolean,
  filters?: string[],
  sorter?: string[]) :  Promise<[Workflow[], number]>  => { 
  let url = `${workflowsApiPath}?`;
  
  if (runInfoRequired) 
    url += `&runInfoRequired=true`

  if (sharedOnly != undefined && sharedOnly == true) 
    url += `&sharedOnly=true`

  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`

  if (subworkflowsOnly != undefined) {
    url += `&filter=equals(isSubworkflow,'${subworkflowsOnly}')&filter=equals(state,'published')`
  } 

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get", url);
  const workflows =  jsonApiDeserializer.deserialize(response.data)  as Workflow[];
  return [workflows, response?.data?.meta?.totalCount];
};

export const getWorkflowsWithFiltersApi= async (filters: string) :  Promise<[Workflow[], number]>  => {
  const url = `${workflowsApiPath}?${filters}`;
  const response = await axiosInterceptor("get", url);
  const workflows =  jsonApiDeserializer.deserialize(response.data)  as Workflow[];
  return [workflows, response?.data?.meta?.totalCount];
};

export const getWorkflowApi = async (workflowId?: string): Promise<Workflow> => {
  if(workflowId) {
    const response = await axiosInterceptor(
      "get",
      `${workflowsApiPath}/${workflowId}`
    );
    
    const workflow = jsonApiDeserializer.deserialize(response.data)  as Workflow;
    return workflow;
  }
  throw new Error("workflowId should be present in the workflow get");
};

export const getWorkflowVersionsApi = async (workflowId: string):Promise<Workflow[]> => {
  if(workflowId) {
    const response = await axiosInterceptor(
      "get",
      `${workflowsApiPath}?includeVersions=true&filter=equals(id,'${workflowId}')&fields[workflows]=version,versionName,state,comments&sort=-version`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as Workflow[];
  }
  throw new Error("workflowId should be present in the workflow versions get");
};

export const deleteWorkflowVersionApi = async (workflowId: string, workflowVersion: string):Promise<Workflow[]> => {
  if(workflowId) {
    const response = await axiosInterceptor(
      "delete",
      `${workflowsApiPath}/${workflowId}?filter=equals(version,'${workflowVersion}')`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as Workflow[];
  }
  throw new Error("workflowId and version should be present in the workflow version delete");
};

//TODO - get only required fields from backend
export const getWorkflowRunStatusApi = async (workflowRunId?: string): Promise<WorkflowRun> => {
  if(workflowRunId) { 
    const config: AxiosConfig = { maxRetries: 5, retryDelay: 2*1000, retryStatusCodes: [403] };
    const url = `${workflowRunsApiPath}/${workflowRunId}`
    const response = await axiosInterceptor("get", url, null, config);
    
    return jsonApiDeserializer.deserialize(response.data)  as WorkflowRun;
  }
  throw new Error("workflowRunId should be present in the workflowRun status get");
};

export const getWorkflowRunApi = async (workflowRunId?: string): Promise<WorkflowRun> => {
  if(workflowRunId) {
    //TODO - add selected fields
    const response = await axiosInterceptor(
      "get",
      `${workflowRunsApiPath}/${workflowRunId}?include=workflows`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as WorkflowRun;
  }
  throw new Error("workflowRunId should be present in the workflowRun get");
};

export const deleteWorkflowRunApi = async (workflowRunId: string) : Promise<AxiosResponse<any, any>>=> {
  if(workflowRunId) {
    return axiosInterceptor(
      "delete",
      `${workflowRunsApiPath}/${workflowRunId}`
    );
  }
  throw new Error("workflowRunId should be present in the workflowRun delete");
};

export const cancelWorkflowRunApi = async (workflowRunId: string): Promise<AxiosResponse<any, any>> => {
  if(workflowRunId) {
    return axiosInterceptor(
      "post",
      `${workflowRunsApiPath}/${workflowRunId}/cancel`
    );
  }
  throw new Error("workflowRunId should be present in the workflowRun cancel");
};

export const createWorkflowApi = async (workflow: Workflow, isImport?: boolean, tagsOnly?: boolean) :  Promise<Workflow>=> {
  let path = workflowsApiPath
  if (isImport) {
    path += "?import=true"
    if (tagsOnly) {
      path += "&tagsOnly=true"
    }
  } else {
    if (tagsOnly) {
      path += "?tagsOnly=true"
    }
  }

  const response = await axiosInterceptor(
    "post",
    path,
    {
        "data": {
            "type": "workflows",
            "id": workflow.id,
            "attributes": dtoFactory.convertWorkflowToUploadObject(workflow),
        }
    }
  );
  const workflowOut = jsonApiDeserializer.deserialize(response.data)  as Workflow;
  return workflowOut;
};

export const updateWorkflowApi = async (workflow: Workflow): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${workflowsApiPath}/${workflow.id}`,
    {
      "data": {
        "type": "workflows",      
        "id": workflow.id,
        "attributes": dtoFactory.convertWorkflowToUploadObject(workflow),
      }
    }
  );
};

export const deleteWorkflowApi = async (workflowId: string): Promise<void> => {
  await axiosInterceptor(
    "delete",
    `${workflowsApiPath}/${workflowId}`
  );
};

export const actionWorkflowApi = async (workflowId: string, action: WorkflowActionType, workflow?: unknown, tagsOnly?: boolean) => {
  let url = `${workflowsApiPath}/${workflowId}/${action}`;
  if (tagsOnly) {
    url += "?tagsOnly=true"
  }

  const response = await axiosInterceptor(
      "post",
      url,
      workflow 
      && {
            "data": {
              "type": "workflows",      
              "id": workflowId,
              "attributes": workflow,
            }
          }
    );
    return (response && response.data) ? jsonApiDeserializer.deserialize(response.data) : null;
};

export const exportWorkflowApi = async (workflowId: string) => {
  const response = await axiosInterceptor(
    "post",
    `${workflowsApiPath}/${workflowId}/export`
    );
    return (response && response.data) ? response.data : null;
};

export const getWorkflowPoliciesApi = async (category: string, name?: string): Promise<Policy[]> => {
  let url = `${workflowPoliciesApiPath}?filter=equals(category,'${category}')`;
  
  if (name) 
    url += `&filter=equals(name,'${name}')`

  const response = await axiosInterceptor("get", url);
  return jsonApiDeserializer.deserialize(response.data) as Policy[];
};

export const updateWorkflowPolicyApi = async (policy: Policy): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${workflowPoliciesApiPath}/${policy.id}`,
    {
      "data": {
        "type": "policies",      
        "id": policy.id,
        "attributes": policy,
      }
    }
  );
};

export const createWorkflowProviderPolicyApi = async (policy: Policy): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "post",
    workflowPoliciesApiPath,
    {
      "data": {
        "type": "policies",      
        "id": policy.id,
        "attributes": policy,
      }
    }
  );
};


export const deleteWorkflowProviderPolicyApi = async (policyId: string): Promise<void> => {
  await axiosInterceptor(
    "delete",
    `${workflowPoliciesApiPath}/${policyId}`
  );
};


export const getWorkflowActionProviderPoliciesApi = async (
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[ActionProvider[], number]> => {  
  let url = `${actionProvidersApiPath}?&subscribedAppsOnly=true&includePolicyActions=true&include=actions&fields[action-providers]=appID,actionID,appInfo&fields[actions]=name,displayName,description`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url)
  const actionProviders =  jsonApiDeserializer.deserialize(response.data)  as ActionProvider[];
  return [actionProviders, response?.data?.meta?.totalCount];
};

export const getWorkflowTriggerProviderPoliciesApi = async (
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[TriggerProvider[], number]> => {  
  let url = `${triggerProvidersApiPath}?&subscribedAppsOnly=true&includePolicyActions=true&include=triggers&fields[trigger-providers]=appID,triggerID,appInfo&fields[triggers]=name,displayName,description`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url)
  const triggerProviders =  jsonApiDeserializer.deserialize(response.data)  as TriggerProvider[];
  return [triggerProviders, response?.data?.meta?.totalCount];
};

export const getWorkflowFileApi = async (
  workflowId: string, 
  fileId: string
) : Promise<WorkflowFile>=> {
  const response = await axiosInterceptor("get",`${workflowFilesApiPath}/${fileId}?wid=${workflowId}`);
  return jsonApiDeserializer.deserialize(response.data) as WorkflowFile;
};

export const deleteWorkflowFileApi = async (
  workflowId: string, 
  fileId: string
): Promise<AxiosResponse<any,any>>=> {
  return await axiosInterceptor("delete", `${workflowFilesApiPath}/${fileId}?wid=${workflowId}`);
};

export const uploadWorkflowFileApi = async (
  workflowId: string, 
  formData: any,
): Promise<WorkflowFile>=> {
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers['Content-Type'] = 'multipart/form-data';

  const url = `${workflowFilesApiPath}/upload?wid=${workflowId}`;

  const response = await axiosInterceptor(
    "post",
    url,
    formData,
    config
  );
  return jsonApiDeserializer.deserialize(response.data)  as WorkflowFile;
};

export const downloadWorkflowFileApi = async (
  workflowId: string, 
  fileId: string,
): Promise<any>=> {

  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.responseType = 'arraybuffer';

  const response = await axiosInterceptor(
    "get",
    `${workflowFilesApiPath}/${fileId}/download?wid=${workflowId}`,
    null,
    config
  );
  return response.data;
};
