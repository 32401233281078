import { Form, Input, Space, theme} from "antd";
import { useState } from "react";
import { useSearchGlobalStore } from "store/search";
import { Search, SearchMetaData, SearchServiceCode } from "types";
import { notification } from "utility/notification";

import Modal from "components/Modal";

export interface SaveSearchProps {
  serviceCode: SearchServiceCode;
  searchMetaData: SearchMetaData;
  onClose: () => void;
  onSaved: (item: Search) => void;
}

export const SaveSearch = (props: SaveSearchProps) => {
  const [, setLoading] = useState(false);
  const { createSearch } = useSearchGlobalStore((state) => ({
    createSearch: state.createSearch,
  }));
  const { token } = theme.useToken();
  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }
      const search = {
        name: values.name,
        description: values.description,
        query: props.searchMetaData.query,
        isAiBasedQuery: props.searchMetaData.isAiBasedQuery,
        serviceCode: props.serviceCode,
        searchTags: props.searchMetaData.searchTags,
      } as Search;
      const saveResult = await createSearch(search);
      /** Now look for the search item that has the same name, description and query as ours */
      props.onSaved(saveResult);
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to save search`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        props.onClose();
      }
    }
  };

  const formFields = [
    {
      name: "name",
      label: "Name",
      rules: [{ required: true, message: "Please input search name!" }],
    },
    {
      name: "description",
      label: "Description",
      rules: [{ required: true, message: "Please input search description!" }],
    },
  ];
  const [form] = Form.useForm();
  const renderSwitchForInputType = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      case "description":
        return (
          <Input.TextArea 
            placeholder="Enter description"
            autoSize 
            maxLength={2048} 
            onChange={(e) => {
              form.setFieldsValue({ description: e.target.value });
            }}
          />
        );
      default:
        return <Input placeholder="Enter search name" />;
    }
  };

  return (
    <Modal
      title={"Save Search"}
      open={true}
      footerName="Save"
      onClose={props.onClose}
      onSubmit={handleSubmit}
    >
      <div>
        <Form
          form={form}
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
        >
          <Space size={token.marginXXS} direction="vertical" style={{ display: 'flex' }}>
            {formFields.map((field) => (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
              >
                {renderSwitchForInputType(field.name)}
              </Form.Item>
            ))}
          </Space>
        </Form>
      </div>
    </Modal>
  );
};
