import { FC, useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  Space,
  Button,
  theme,
} from "antd";
import { PlusOutlined, DeleteFilled } from "@ant-design/icons";

import {
  ArrayObject,
  IODataType,
} from "types";

import Modal from "components/Modal";
import { FieldLabel } from "components/FieldLabel";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import CollapsePanel from "components/CollapsePanel";
import { FormInstance } from "antd/lib";

const { Option } = Select;

export interface OperatorKeyValueFormProps {
  editMode: boolean;
  name: string;
  keyValues: Record<string, string>;
  suggestionsTree: [];
  onChange?: (value: Record<string, string>) => void;
  onRender?: (form: FormInstance) => void;
}

interface DeleteProps {
  enable: boolean;
  index?: number;
}

export const OperatorKeyValueForm: FC<OperatorKeyValueFormProps> = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [deleteKeyValueProps, setDeleteKeyValueProps] = useState<DeleteProps>({enable: false});

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  useEffect(() => {
    if (props.keyValues && Object.keys(props.keyValues).length > 0) {
      const kvList = Object.entries(props.keyValues).map(([k, v]) => ({
        key: k,
        value: v,
      }));
      form.setFieldValue("kvList", kvList);
    } 
  }, [props.keyValues]);

  return (
    <Form
      form={form}
      name="httpKVForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={(changedValues: any, _: any) => {
        const values = form.getFieldsValue(true);
        const kvList = values.kvList;
        const kvs = {} as Record<string,string>
        kvList.map((kv: any) => kvs[kv?.key] = kv?.value);
        props.onChange?.(kvs);
      }}
    >
      <Form.List name={"kvList"}>
        {(fields, { add, remove }) => (
          <div style={{width: "100%"}}>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key} style={{display: "flex",gap: "6px", maxWidth: "100%", alignItems: "baseline"}}>
                <Form.Item
                  {...restField}
                  name={[name, 'key']}
                  validateTrigger="onSubmit"
                  rules={[
                    { required: true, message: "Key is required!" },
                    {
                      validator: (_, value) =>
                        value && !value.includes(" ")
                          ? Promise.resolve()
                          : Promise.reject(new Error("No spaces allowed")),
                    },
                  ]}
                  style={{
                    width: "100%"
                  }}
                >
                    <Input placeholder="Key" disabled={!props.editMode} />
                </Form.Item>
                
                <Form.Item
                  {...restField}
                  name={[name, 'value']}
                  validateTrigger="onSubmit"
                  rules={[
                    { required: true, message: "Value is required!" },
                    {
                      validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
                    }
                  ]}
                  style={{
                    width: "100%"
                  }}
                >
                  <SuggestionsInput
                    editMode={props.editMode}
                    name={"value"}
                    suggestionsTree={props.suggestionsTree}
                  />
                </Form.Item>
                {props.editMode &&<Button
                  type="default"
                  shape="circle"
                  size="small"
                  style={{background: token.colorPrimaryBg }}
                  icon={<DeleteFilled style={{ color: token.colorPrimary }}/>}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setDeleteKeyValueProps({
                      enable: true,
                      index: key
                    });
                  }}
                />}
              </div>
            ))}
            {props.editMode && ( 
              <Form.Item>
                <Button 
                  type="default" 
                  style={{ background: token.colorPrimaryBg }}
                  onClick={() => add()} 
                  block 
                  icon={<PlusOutlined />}
                >
                  {props.name}
                </Button>
              </Form.Item>
            )}
            {deleteKeyValueProps.enable && (
              <Modal
                title={"Delete " + props.name}
                onClose={() => {
                  setDeleteKeyValueProps({ enable: false });
                }}
                open={deleteKeyValueProps.enable}
                onSubmit={() => {
                  if (deleteKeyValueProps.index != undefined) {
                    remove(deleteKeyValueProps.index)
                  }
                  setDeleteKeyValueProps({ enable: false });
                }}
              >
                {
                  'Are you sure you want to delete ' + `${props.name}` + ' ?'
                }
              </Modal>
            )}
          </div>
        )}
      </Form.List>
    </Form>
  );
};

export interface OperatorIODatasFormProps {
  editMode: boolean;
  isOutput: boolean;
  iodatas: ArrayObject[];
  hideValue?: boolean;
  suggestionsTree?: [];
  onChange?: (value: ArrayObject[]) => void;
  onRender?: (form: FormInstance) => void;
}

export const OperatorIODatasForm: FC<OperatorIODatasFormProps> = (props) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [iodataNames, setIODataNames] = useState<string[]>([]);
  const [deleteIODatas, setDeleteIODatas] = useState<DeleteProps>({enable: false});

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  useEffect(() => {
    form.setFieldValue("operatorIODatas", props.iodatas);
    const names = Object.values(props.iodatas).map((value) => value.name);
    setIODataNames(names);
  }, [props.iodatas]);

  return (
    <Form
      form={form}
      name="operatorIODataForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={(changedValues: any, _: any) => {
        const values = form.getFieldsValue(true);
        const iodatas = values.operatorIODatas;
        const names = iodatas?.map((value: any) => value?.name);
        setIODataNames(names);
        props.onChange?.(values.operatorIODatas);
      }}
    >
      <Form.List 
        name="operatorIODatas"
        initialValue={[{ name: "", type: IODataType.String, value: "" }]}
      >
        {(fields, { add, remove }) => (
          <Space direction="vertical" style={{ display: "flex" }}>
          {fields.map(({ key, name, ...restField }) => (
              <CollapsePanel
                key={key}
                name={iodataNames[name]}
                collapsePanel={false}
                extraElement={
                  props.editMode && (
                  <Button
                    type="default"
                    shape="circle"
                    size="small"
                    style={{ background: token.colorPrimaryBg }}
                    icon={ <DeleteFilled style={{ color: token.colorPrimary }}/> }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setDeleteIODatas({
                        enable: true,
                        index: key
                      });
                    }}
                  />
                  )
                }
              >
                <Form.Item
                  {...restField}
                  name={[name, 'name']}
                  validateTrigger="onSubmit"
                  label={<FieldLabel label={"Name"}/>}
                  normalize={(value) => value.trim()}
                  rules={[
                    { required: true, message: "Name is required!" },
                  ]}
                >
                  <Input disabled={!props.editMode} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'type']}
                  validateTrigger="onSubmit"
                  label={<FieldLabel label={"Type"}/>}
                  rules={[{ required: true, message: "Type is required!" }]}
                >
                  <Select 
                    disabled={!props.editMode} 
                    defaultValue={IODataType.String}
                    showAction={["focus", "click"]}
                  >
                    <Option key={IODataType.String} value={IODataType.String}>
                      String
                    </Option>
                    <Option key={IODataType.Numeric} value={IODataType.Numeric}>
                      Number
                    </Option>
                    <Option key={IODataType.Boolean} value={IODataType.Boolean}>
                      Boolean
                    </Option>
                    <Option key={IODataType.Array} value={IODataType.Array}>
                      List
                    </Option>
                  </Select>
                </Form.Item>
                {!props.hideValue &&
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    validateTrigger="onSubmit"
                    label={<FieldLabel label={"Value"}/>}
                    rules={[
                      { required: true, message: "Value is required!" },
                      {
                        validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid value")
                      }
                    ]}
                  >
                    <SuggestionsInput
                        editMode={props.editMode}
                        name={"value"}
                        suggestionsTree={props.suggestionsTree}
                    />
                  </Form.Item>
                }
              </CollapsePanel>
            ))}
            {props.editMode && (
              <Form.Item>
                <Button
                  type="default"
                  style={{ background: token.colorPrimaryBg }}
                  onClick={() => add({type: IODataType.String})} 
                  block
                  icon={<PlusOutlined />}
                >
                  {props.isOutput ? "Output" : "Input" }
                </Button>
              </Form.Item>
            )}
            {deleteIODatas.enable && (
              <Modal
                title={props.isOutput ? "Delete Output" : "Delete Input"}
                onClose={() => {
                  setDeleteIODatas({ enable: false });
                }}
                open={deleteIODatas.enable}
                onSubmit={() => {
                  if (deleteIODatas.index != undefined) {
                    remove(deleteIODatas.index)
                  }
                  setDeleteIODatas({ enable: false });
                }}
              >
                {
                  'Are you sure you want to delete ' + `${props.isOutput ? "Output" : "Input"}` + ' ?'
                }
              </Modal>
            )}
          </Space>
        )}
      </Form.List>
    </Form>
  );
};