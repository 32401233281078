import { Handle, Position } from "reactflow";
import { memo } from "react";

import NodeBody from "./Body";
import { useNodeStyles } from "./styles";

const TriggerNode = (props: any) => {
  const commonStyles = useNodeStyles();
  return (
    <div 
      key={props.id}
      className={commonStyles.node} 
      style={{
        position: "relative",
        cursor: "pointer",
      }}
    >
      <NodeBody {...props.data} />
      <Handle
        className={commonStyles.nodeBottomHandle}
        type="source"
        position={Position.Bottom}
        id="a"
      />
    </div>
  );
};
export default memo(TriggerNode);
