import { Space } from "antd";
import {
  EditorContext,
  TextField,
  PrimitiveArrayField,
  ViewProps,
} from "components/EntityEditor";
import { ResourceType, DeveloperItem } from "types";

import React, { useContext, useEffect } from "react";

export const HttpOAuthClientCredentialsView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onPrimitiveChange = async (
    identifier: string,
    value: string | boolean | number
  ) => {
    setTarget({ ...target, [identifier]: value });
  };

  const onOAuthScopesFieldAdd = async (key: string): Promise<DeveloperItem> => {
    const item = target[key];
    const index = item.length;
    const newItem = `New Scope ${index}`;
    item.push(newItem);
    setTarget({ ...target, [key]: item });
    return {
      id: `oauth-scopes-${key}-${index}`,
      type: ResourceType.Primitive,
      item: { [0]: newItem },
    };
  };

  const onOAuthScopesFieldChange = async (
    key: string,
    value: DeveloperItem[]
  ) => {
    setTarget({ ...target, [key]: value.map((v) => v.item[0]) });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <TextField
        identifier={"clientID"}
        label={"Client ID"}
        help={"OAuth clientID"}
        value={target["clientID"]}
        path={[...props.path, "clientID"]}
        onChange={onPrimitiveChange}
      />

      <TextField
        identifier={"clientSecret"}
        label={"Client Secret"}
        help={"OAuth client secret"}
        value={target["clientSecret"]}
        path={[...props.path, "clientSecret"]}
        onChange={onPrimitiveChange}
        password={true}
      />

      <TextField
        identifier={"tokenURL"}
        label={"Token Url"}
        help={"OAuth token url to fetch tokens"}
        value={target["tokenURL"]}
        path={[...props.path, "tokenURL"]}
        onChange={onPrimitiveChange}
      />
      <PrimitiveArrayField
        value={target["scopes"]?.map(
          (scope: string, index: number): DeveloperItem => {
            return {
              id: `oauth-scope-${index}`,
              item: { [0]: scope },
              type: ResourceType.Primitive,
            };
          }
        )}
        identifier={"scopes"}
        label={"Scopes"}
        help={"OAuth token scopes to fetch token"}
        addLabel={"Add Scope"}
        showExpanded={false}
        onAdd={onOAuthScopesFieldAdd}
        onChange={onOAuthScopesFieldChange}
        path={[...props.path, "oauthScopes"]}
      />
    </Space>
  );
};
