import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Typography } from "antd";
import {
  // AddPasswordDrawer,
  ContactDrawer,
  ContactType,
} from "components/ProfileComponents";
import { ResetPasswordDrawer } from "components/ProfileComponents/ResetPassword";
import { useEffect, useState } from "react";
import { useProfileStore } from "store";

const { Text } = Typography;

interface ItemType {
  title: string;
  content: string;
  icon: JSX.Element;
}

export const SecurityCard = () => {
  const { profile } = useProfileStore((state) => ({
    profile: state.profile,
  }));

  const [items, setItems] = useState<ItemType[]>([]);
  const [addEmail, setAddEmail] = useState(false);
  const [addPhone, setAddPhone] = useState(false);
  const [addPassword, setAddPassword] = useState(false);

  useEffect(() => {
    setItems([
      {
        title: "Email Address",
        content: profile.email || "",
        icon: <ArrowRightOutlined onClick={() => setAddEmail(true)} />,
      },
      {
        title: "Phone Number",
        content: profile.phone || "",
        icon: <ArrowRightOutlined onClick={() => setAddPhone(true)} />,
      },
      {
        title: "Password",
        content: "********",
        icon: <ArrowRightOutlined onClick={() => setAddPassword(true)} />,
      },
    ]);
  }, [profile]);
  return (
    <Card title="Security Information" style={{ width: "100%" }}>
      {addEmail && (
        <ContactDrawer
          type={ContactType.Email}
          visible={addEmail}
          onClose={async () => setAddEmail(false)}
        />
      )}

      {addPhone && (
        <ContactDrawer
          type={ContactType.Phone}
          visible={addPhone}
          onClose={async () => setAddPhone(false)}
        />
      )}

      {addPassword && (
        <ResetPasswordDrawer
          visible={addPassword}
          onClose={async () => setAddPassword(false)}
        />
      )}

      <div
        id="profile-security-container"
        style={{
          width: "100%",
        }}
      >
        {items.map((item, index) => (
          <Row key={index}>
            <Col span={10}>
              <Text strong>{item.title}</Text>
            </Col>
            <Col span={12}>
              <Text>{item.content}</Text>
            </Col>
            <Col span={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {item.icon}
              </div>
            </Col>
            <Divider />
          </Row>
        ))}
      </div>
    </Card>
  );
};
