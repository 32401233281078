import { createToken, Lexer, IToken } from "chevrotain";
import { useSearchArtifactStore } from "store";

import { Artifact, ArtifactField, ArtifactFields, ArtifactFieldType, ArtifactOperators, conditionOperators, logicalOperators, ArtifactLogicalOperators, ValueAndConditionOpType } from "types";
import * as constants from './constants';

const tokenVocabulary: any = {};

const WhiteSpace = createToken({
  name: constants.TokenTypes.WhiteSpace,
  label: constants.TokenTypes.WhiteSpace,
  pattern: constants.whitespaceRegEx,
  group: Lexer.SKIPPED
});
/* eslint-disable @typescript-eslint/naming-convention */
const Projection = createToken({
  name: constants.TokenTypes.Projection,
  label: constants.TokenTypes.Projection,
  pattern: matchArtifact,
  line_breaks: false,
  push_mode: "condition_mode"
})

const Where = createToken({
  name: constants.TokenTypes.Where,
  label: constants.TokenTypes.Where,
  pattern: constants.whereRegEx,
  line_breaks: false,
})

/* eslint-disable @typescript-eslint/naming-convention */
const ConditionArtifactField = createToken({
  name: constants.TokenTypes.ConditionArtifactField,
  label: constants.TokenTypes.ConditionArtifactField,
  pattern: matchArtifactField,
  line_breaks: false
});
/* eslint-disable @typescript-eslint/naming-convention */
const ConditionOperator = createToken({
  name: constants.TokenTypes.ConditionOperator,
  label: constants.TokenTypes.ConditionOperator,
  pattern: constants.conditionOperatorRegEx,
  line_breaks: false,
  push_mode: "condition_value_mode"
});
/* eslint-disable @typescript-eslint/naming-convention */
const ConditionValue = createToken({
  name: constants.TokenTypes.ConditionValue,
  label: constants.TokenTypes.ConditionValue,
  pattern: constants.IdentRegEx,
  line_breaks: false,
  push_mode: "lop_mode"
});
/* eslint-disable @typescript-eslint/naming-convention */
const LogicalOperator = createToken({
  name: constants.TokenTypes.LogicalOperator,
  label: constants.TokenTypes.LogicalOperator,
  pattern: Lexer.NA,
  line_breaks: false,
  push_mode: "condition_mode"
});
/* eslint-disable @typescript-eslint/naming-convention */
const LogicalAndOperator = createToken({
  name: constants.TokenTypes.LogicalAndOperator,
  label: constants.TokenTypes.LogicalAndOperator,
  pattern: constants.logicalAndOperatorRegEx,
  categories: LogicalOperator,
  line_breaks: false,
  push_mode: "condition_mode"
});
/* eslint-disable @typescript-eslint/naming-convention */
const LogicalOrOperator = createToken({
  name: constants.TokenTypes.LogicalOrOperator,
  label: constants.TokenTypes.LogicalOrOperator,
  pattern: constants.logicalOrOperatorRegEx,
  categories: LogicalOperator,
  line_breaks: false,
  push_mode: "condition_mode"
});

// The order of tokens is important
const allTokens = [
  WhiteSpace,
  Projection,
  Where,
  ConditionArtifactField,
  ConditionOperator,
  ConditionValue,
  LogicalAndOperator,
  LogicalOrOperator,
  LogicalOperator,
];

const multiModeLexerDefinition = {
  modes: {
    projection_mode: [
      WhiteSpace,
      Projection,
      Where,
    ],
    condition_mode: [
      WhiteSpace,
      Where,
      ConditionOperator,
      ConditionArtifactField,
    ],
    condition_value_mode: [
      WhiteSpace,
      ConditionValue,
    ],
    lop_mode: [
      WhiteSpace,
      LogicalOperator,
      LogicalAndOperator,
      LogicalOrOperator,
    ],
  },

  defaultMode: "projection_mode"
}

export const queryLexer: any = new Lexer(multiModeLexerDefinition);

allTokens.forEach(tokenType => {
  tokenVocabulary[tokenType.name] = tokenType;
});

export { tokenVocabulary };

function matchArtifact(text: string, startOffset: number) {
  const matchedExpression = constants.artifactRegEx.exec(text.substring(startOffset))
  if (matchedExpression == null) {
    return null
  }
  if (!isArtifactExists(matchedExpression[0])) {
    return null
  }
  return matchedExpression
}

function matchArtifactField(text: string, startOffset: number, matchedTokens: IToken[]) {
  const artifactToken = matchedTokens.at(0)
  if (!artifactToken) {
    return null
  }
  const matchedExpression = constants.artifactFieldRegEx.exec(text.substring(startOffset))
  if (matchedExpression == null) {
    return null
  }
  if (!isArtifactFieldExists(artifactToken.image, matchedExpression[0])) {
    return null
  }
  return matchedExpression
}


function isArtifactExists(name: string): boolean {
  const artifacts = useSearchArtifactStore.getState().artifacts
  if (artifacts.length == 0) {
    return false
  }
  return artifacts?.some((i: Artifact) => i.name === name)
}

function isArtifactFieldExists(artifactName: string, fieldName: string): boolean {
  const tokens = fieldName.split(".")
  if (tokens.length  == 2 ){
    return isArtifactFieldExists(tokens[0], tokens[1])
  }
  const artifact = getArtifact(artifactName)
  if (!artifact) {
    return false
  }
  return fieldName in artifact.fields;
}

export function getArtifacts(): Artifact[] {
  return useSearchArtifactStore.getState().artifacts
}

export function getArtifactFieldsById(artifactId: string): ArtifactFields {
  const artifact =  getArtifacts()?.find((i: Artifact) => i.id === artifactId)
  if (!artifact) {
    const fields: ArtifactFields = {}
    return fields
  }
  return getArtifactFields(artifact.name);
}

export function getArtifactFields(artifactName: string): ArtifactFields {
  const artifact = getArtifact(artifactName)
  if (!artifact) {
    const fields: ArtifactFields = {}
    return fields
  }
  const fields = artifact.fields
  const sortedFields: ArtifactFields = {}
  Object.entries(fields).filter(([name, field]) => (field.filterable || field.isFilterableOnly) && (field.type != ArtifactFieldType.Artifact))
    .sort(([name1, field1], [name2, field2]) => name1.localeCompare(name2))
    .map(([name, field]) => {
      sortedFields[name] = field
    });
  Object.entries(fields).filter(([name, field]) => field.type == ArtifactFieldType.Artifact)
    .forEach ( ([name, field]) => {
      const artifact = getArtifact(name)
      if(artifact != null) {
        field.nestedFilterableFields?.forEach( (value) => {
          sortedFields[name + "." + value] = artifact.fields[value]
        });
      }
  });
  return sortedFields
}

function reduceOperatorsAsPerType(operators: ArtifactOperators, fieldType: ArtifactFieldType): ArtifactOperators {
  const reducedOperators: ArtifactOperators = [];
  const allowedOperators = ValueAndConditionOpType.get(fieldType)
  if (!(allowedOperators?.length)) {
    return reducedOperators
  }

  operators?.forEach((o) => {
    const ao = allowedOperators.find((value) => value == o)
    if (ao) {
      reducedOperators.push(o)
    }
  });
  return reducedOperators
}

export function getArtifactFieldOperators(artifactName: string, fieldName: string): ArtifactOperators {
  const tokens = fieldName.split(".")
  if (tokens.length  == 2 ){
    return getArtifactFieldOperators(tokens[0], tokens[1])
  }
  const artifact = getArtifact(artifactName)
  if (!artifact) {
    return []
  }
  const artifactField = artifact.fields[fieldName]
  if (!artifactField) {
    return []
  }
  if (artifactField.operators && artifact.operators?.length != 0) {
    return reduceOperatorsAsPerType(artifactField.operators, artifactField.type)
  }

  if (artifact.operators && artifact.operators?.length != 0) {
    return reduceOperatorsAsPerType(artifact.operators, artifactField.type)
  }

  return reduceOperatorsAsPerType(conditionOperators, artifactField.type)
}

export function getArtifactLogicalOperators(artifactName: string): ArtifactLogicalOperators {
  const artifact = getArtifact(artifactName)
  if (!artifact) {
    return []
  }
  if (artifact.logicalOperators && artifact.logicalOperators.length != 0) {
    return artifact.logicalOperators
  }
  return logicalOperators
}

export function getArtifact(name: string): Artifact | null {
  const artifacts = useSearchArtifactStore.getState().artifacts
  if (!artifacts.length) {
    return null
  }
  const artifact = artifacts?.find((i: Artifact) => i.name === name && !i.private)
  if (!artifact) {
    return null
  }
  return artifact as Artifact
}