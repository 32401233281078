import { useState } from "react";
import { Card, Popover, Space, Typography, theme } from "antd";
import { Handle, Node, NodeProps, Position } from "reactflow";
import { TreePopoverContent } from "./TreePopoverContent";
import { getTagColor } from "utility";
import { SvgIcon } from "components/SvgIcon";

import { managedmodeIcons } from "assets/icons";
import { useSettingsStore } from "store";
const { Text } = Typography;

export interface TreeDisplayData {
  id: string;
  name: string;
  description: string;
  owner: string;
  isLeafNode: boolean;
  onEdit: (subscriberId: string) => Promise<void>;
  onDelete: (subscriberId: string) => Promise<void>;
}

export type CustomNode = Node<TreeDisplayData>;

export const TreeDisplayNode = ({
  data,
  targetPosition,
  sourcePosition,
}: NodeProps<TreeDisplayData>) => {
  const { token } = theme.useToken();
  const { lightMode } = useSettingsStore((state) => ({
    lightMode: state.lightMode,
  }));
  const boxShadowColor = lightMode ? "#D3D3D3" : "#1c1c1c";
  const [hover, setHover] = useState(false);



  return (
    <>
      <Handle type="target" position={targetPosition ?? Position.Top} />
      <Handle type="source" position={sourcePosition ?? Position.Bottom} />
      <Card.Grid
        id={`node-${data.id}`}
        style={{
          background: hover ? token.colorPrimaryBg : token.colorBgBase,
          minHeight: "50px",
          minWidth: "180px",
          maxHeight: "50px",
          maxWidth: "180px",
          justifyContent: "center",
          borderRadius: token.sizeXS,
          border: "1px solid",
          borderLeftWidth: token.sizeXXS,
          borderLeftColor: getTagColor(`${data.id}_tag`),
          borderRightColor: token.colorBorder,
          borderTopColor: token.colorBorder,
          borderBottomColor: token.colorBorder,
          boxShadow: `2px 2px 2px ${boxShadowColor}`,
          zIndex: 200,

        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Space size={0}>
          <SvgIcon
            size={40}
            style={{
              marginTop: token.sizeXXS,
              marginLeft: token.sizeXXS,
              height: "40px"
            }}
            component={data.isLeafNode ? managedmodeIcons.managedModeCustomer   :  managedmodeIcons.managedModeMsp}
          />
          <Popover content={<TreePopoverContent {...data} />}>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "400",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: lightMode ? token.colorPrimary : token.colorText,
                marginLeft: token.sizeXS,
              }}
              ellipsis
            >   
              {data.name}
            </Text>
          </Popover>
        </Space>
      </Card.Grid>
    </>
  );
};
