

export const AppCreateScope      = "ap.ap.c"
export const AppListScope        = "ap.ap.l"
export const AppReadScope        = "ap.ap.r"
export const AppUpdateScope      = "ap.ap.u"
export const AppDeleteScope      = "ap.ap.d"
export const AppSpecExecuteScope = "ap.ap.sx"

export const ArtifactCreateScope      = "sr.af.c"
export const ArtifactListScope        = "sr.af.l"
export const ArtifactReadScope        = "sr.af.r"
export const ArtifactUpdateScope      = "sr.af.u"
export const ArtifactDeleteScope      = "sr.af.d"
export const ArtifactSpecExecuteScope = "sr.af.sx"

export const ArtifactProviderCreateScope      = "sr.ap.c"
export const ArtifactProviderListScope        = "sr.ap.l"
export const ArtifactProviderReadScope        = "sr.ap.r"
export const ArtifactProviderUpdateScope      = "sr.ap.u"
export const ArtifactProviderDeleteScope      = "sr.ap.d"
export const ArtifactProviderSpecExecuteScope = "sr.ap.sx"

export const ActionCreateScope      = "wf.ac.c"
export const ActionListScope        = "wf.ac.l"
export const ActionReadScope        = "wf.ac.r"
export const ActionUpdateScope      = "wf.ac.u"
export const ActionDeleteScope      = "wf.ac.d"
export const ActionSpecExecuteScope = "wf.ac.sx"

export const TriggerCreateScope      = "wf.tr.c"
export const TriggerListScope        = "wf.tr.l"
export const TriggerReadScope        = "wf.tr.r"
export const TriggerUpdateScope      = "wf.tr.u"
export const TriggerDeleteScope      = "wf.tr.d"
export const TriggerSpecExecuteScope = "wf.tr.sx"

export const ActionProviderCreateScope      = "wf.ap.c"
export const ActionProviderListScope        = "wf.ap.l"
export const ActionProviderReadScope        = "wf.ap.r"
export const ActionProviderUpdateScope      = "wf.ap.u"
export const ActionProviderDeleteScope      = "wf.ap.d"
export const ActionProviderSpecExecuteScope = "wf.ap.sx"

export const TriggerProviderCreateScope      = "wf.tp.c"
export const TriggerProviderListScope        = "wf.tp.l"
export const TriggerProviderReadScope        = "wf.tp.r"
export const TriggerProviderUpdateScope      = "wf.tp.u"
export const TriggerProviderDeleteScope      = "wf.tp.d"
export const TriggerProviderSpecExecuteScope = "wf.tp.sx"

export const WorkflowCreateScope      = "wf.wf.c"
export const WorkflowListScope        = "wf.wf.l"
export const WorkflowReadScope        = "wf.wf.r"
export const WorkflowUpdateScope      = "wf.wf.u"
export const WorkflowDeleteScope      = "wf.wf.d"
export const WorkflowExecuteScope     = "wf.wf.x"
export const WorkflowSpecExecuteScope = "wf.wf.sx"