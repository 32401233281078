import { FC, useState } from "react";
import {
  Form,
  Input,
  Spin,
  Space,
  theme
} from "antd";

import Modal from "components/Modal";

const { TextArea } = Input;

import { SpecNameValidationState  } from "types";
import { FieldLabel } from "components/FieldLabel";
import { capitalizeFirstWord, capitalizeWords } from "utility";
import { getNameIconByState } from "utility/developer";

export interface CreateModalProps {
  open: boolean;
  header: string;
  object: string;
  notAvailableNames: string[]
  onClose: () => void;
  onSubmit: (values: any) => void;
  loader: boolean;
}
export const CreateModal: FC<CreateModalProps> = ({
  open,
  header,
  object,
  notAvailableNames,
  onClose,
  onSubmit,
  loader
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [nameValidationState, setNameValidationState] = useState(SpecNameValidationState.Unknown);

  const validateNameField = async () : Promise<any> =>  {
    const name = form.getFieldValue("name");
    if(name == "") {
      setNameValidationState(SpecNameValidationState.Unknown);
      return Promise.reject(new Error(`${header} name must not be blank !`));
    }
    const found = notAvailableNames.find( (x) => x.toLowerCase() == name.toLowerCase());
    if(found){
      setNameValidationState(SpecNameValidationState.InValid);
      return Promise.reject(new Error(`The ${object} name already exists.`));
    }else {
      setNameValidationState(SpecNameValidationState.Valid);
      return Promise.resolve();
    }
  };
  
  return (
    <Modal
      loader={loader}
      title={`New ${header}`}
      open={open}
      footerName="Save"
      onClose={onClose}
      onSubmit={() =>
        form
          .validateFields()
          .then(onSubmit)
          .catch((err) => {
            console.log(err);
          })
      }
    >
      <Spin spinning={loader}>     
        <Form
          form={form}
          name="saveForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical" 
        >
          <Space size={token.marginXXS} direction="vertical" style={{ display: 'flex' }}>  
            <Form.Item
              name="name"
              label={<FieldLabel  label={`${header}  Name`} help={`${header}  Name`} />}
              required
              initialValue={""}
              rules={[
                { validator: (_, value) =>  validateNameField()},
              ]}
            >
              <Input 
                placeholder={`Enter ${object} name`} 
                suffix={nameValidationState && getNameIconByState(nameValidationState)}
                //maxLength={2048}
                onChange={(e) => {
                  form.setFieldValue("name", capitalizeWords(e.target.value))
                }}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<FieldLabel  label={"Description"} help={`Description about the ${object}`} />}
              rules={[{ required: true, message: "Description is required!" }]}
              initialValue={""}
            >
              <TextArea  
                placeholder="Enter description"  
                maxLength={2048}
                onChange={(e) => {
                  form.setFieldValue("description", capitalizeFirstWord(e.target.value))
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
};
export default CreateModal;
