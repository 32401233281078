import { FC, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  theme,
} from "antd";

import {
  AppParameterProps,
  AppParameterType,
  AppParameter,
} from "types";

import { FieldLabel } from "components/FieldLabel";
import { useAppSubscriptionStore } from "store";
import { showSecrets }  from "utility";

const AppParameters: FC<AppParameterProps> = ({
  form,
  edtiMode,
}) => {
  const { token } = theme.useToken();
  const selectedAppSubscription = useAppSubscriptionStore((state) => state.selectedAppSubscription);
  const selectedApp = useAppSubscriptionStore((state) => state.selectedApp);

  useEffect(() => {
    if (selectedAppSubscription) {
      if (!selectedAppSubscription.parameters) {
        selectedAppSubscription.parameters = {} as Record<string,  any>;
        Object.entries(selectedApp?.parameters).map(([index, parameter]) => {
          if (parameter.default) {
            selectedAppSubscription.parameters[parameter.name] =  parameter.default;
          }
        });
      }
      form.setFieldsValue(selectedAppSubscription?.parameters);
    }
  }, [selectedAppSubscription]);

  const renderParameterValues = (parameter: AppParameter) => {
    return <Select
            showAction={["focus", "click"]}
            placeholder={parameter.placeholder}
          >
            {parameter.values.map((val: any) =>  (
                <Select.Option key={val} value={val}>
                  {val}
                </Select.Option>
            ))}
          </Select>;
  };

  return (
    <Card
      style={{
        width: "100%",
        maxHeight: "400px",
        height: "auto",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <Form
          form={form}
          name="appParametersForm"
          layout="vertical"
          autoComplete="off"
          disabled={!edtiMode}
        >
          {selectedApp && Object.entries(selectedApp.parameters).map(([index, parameter]) => (
            <Space key={parameter.name} direction="vertical" style={{ display: 'flex' }}>
              { parameter.prefix && <div>{parameter.prefix}</div> }
              <Form.Item 
                name={parameter.name}
                label={<FieldLabel label={parameter.label} help={parameter.description ? parameter.description : undefined} />}
                rules={[
                  { required: parameter.required, message: `${parameter.label} is required!`}
                ]}
              >
                { parameter.type ==  AppParameterType.String && 
                  (parameter.values?.length
                    ?
                      renderParameterValues(parameter)
                    :
                    <Input
                      placeholder={parameter.placeholder}
                      readOnly={parameter.readOnly}
                    />)
                }
                { parameter.type ==  AppParameterType.Password && 
                  <Input.Password 
                    placeholder={parameter.placeholder}
                    readOnly={parameter.readOnly}
                    visibilityToggle={showSecrets()}
                  /> 
                }
                { parameter.type ==  AppParameterType.Numeric && 
                  (parameter.values?.length
                    ?
                      renderParameterValues(parameter)
                    :
                    <InputNumber 
                      placeholder={parameter.placeholder}
                      readOnly={parameter.readOnly}
                    />) 
                }
                { parameter.type == AppParameterType.Boolean &&
                  <Select
                    showAction={['focus', 'click']}
                    allowClear
                  >
                    <>
                      <Select.Option key={1} value={true}>
                        True
                      </Select.Option>
                      <Select.Option key={0} value={false}>
                        False
                      </Select.Option>
                    </>
                  </Select>
                }
                { parameter.type ==  AppParameterType.Array && 
                  <Select mode="tags"/>
                }
              </Form.Item>
              { parameter.suffix && <div>{parameter.suffix}</div> }
            </Space>
          ))}
        </Form>
      </Space>
    </Card>
  );
};

export default AppParameters;