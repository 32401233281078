import {
  Badge,
  Button,
  Space,
  Table,
  Tag as AntdTag,
  Typography,
  theme,
  Flex,
} from "antd";
import { HLink } from "components/Link";
import { useEffect, useState } from "react";
import { useOrganizationStore, useProfileStore } from "store";
import { Tag, TypedResource } from "types";

import { getTagColor, getUserDetailsFromJWT } from "utility";
import { notification } from "utility/notification";
import { getStyles } from "../../utility/styles";
import { TagEdit } from "./TagEdit";

const { Text } = Typography;

interface MutationData {
  isAdd: boolean;
  tag?: Tag;
}

interface Assignee {
  id: string;
  name: string;
}

export const TagSettings = () => {
  const { token } = theme.useToken();
  const tags = useOrganizationStore((state) => state.tags);
  const subscribers = useOrganizationStore((state) => state.subscribers);
  const profile = useProfileStore((state) => state.profile);
  const [assignees, setAssignees] = useState<Record<string, Assignee>>({});

  const deleteTag = useOrganizationStore((state) => state.deleteTag);

  const [mutationData, setMutationData] = useState<MutationData | null>(null);

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  useEffect(() => {
    const assignees: Record<string, Assignee> = {};
    Object.keys(subscribers).forEach((key) => {
      assignees[key] = { id: key, name: subscribers[key].name };
    });
    const { tenantId } = getUserDetailsFromJWT();
    const self = { id: tenantId, name: profile.business || "" };
    assignees[tenantId] = self;
    setAssignees(assignees);
  }, [subscribers, profile]);
  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deleteTag(id);
      }, Promise.resolve());
      setSelectedRowKeys([]);
      notification.success({
        message: `Successfully deleted tags`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting tags`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    setMutationData(null);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record: Tag) => ({
      disabled: record.readonly,
    }),
  };

  return (
    <div
      id="tags-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <div
        id="tags-header"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: token.margin,
        }}
      >
        <div>
          <Text style={{ fontSize: token.fontSizeHeading4 }}>
            Total Tags ({tags.length})
          </Text>
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => setMutationData({ isAdd: true })}
          >
            Add
          </Button>
          <Button
            type="primary"
            onClick={() => onDelete(selectedRowKeys)}
            disabled={!selectedRowKeys.length}
          >
            Delete
          </Button>
        </Space>
      </div>

      <div style={{ width: "100%" }}>
        <Table
          rowKey={"id"}
          key={`tags-table`}
          rowSelection={rowSelection}
          dataSource={tags}
          columns={[
            {
              title: "Value",
              render: (_: any, record: Tag) => {
                return (
                  <HLink
                    onClick={() => {
                      setMutationData({
                        isAdd: false,
                        tag: record,
                      });
                    }}
                    text={record.value}
                  />
                );
              },
            },
            {
              title: "Assigned to",
              key: "assignees",
              render: (_: any, record: Tag) => (
                <Flex gap="4px 1"  wrap="wrap" align={"stretch"}>
                  {record.resources.map((resource: TypedResource) => (
                    <AntdTag 
                      key={resource.id} 
                      color={getTagColor(resource.id)}
                    >
                      {assignees[resource.id]?.name}
                    </AntdTag>
                  ))}
                </Flex>
              ),
            },
          ]}
        />
      </div>
      {mutationData && (
        <TagEdit
          isAdd={mutationData.isAdd}
          tag={mutationData.tag}
          onClose={onClose}
          resources={Object.values(assignees).map((assignee) => ({
            id: assignee.id,
            name: assignee.name,
            assignable: !mutationData.tag?.readonly,
            displayName: assignee.name,
            description: assignee.name,
            selected:
              mutationData.tag?.resources
                .map((x) => x.id)
                .includes(assignee.id) || false,
          }))}
        />
      )}
    </div>
  );
};
