import { AxiosResponse } from "axios";
import { Tag, TagCreateRequest, TagList, TagUpdateRequest } from "types";
import { axiosInterceptor } from "./axios";
import { tagsApiPath } from "./constant";

export const listTagsApi = async (): Promise<AxiosResponse<TagList>> => axiosInterceptor("get", tagsApiPath);
export const getTagApi = async (id: string): Promise<AxiosResponse<Tag>> => axiosInterceptor("get", `${tagsApiPath}/${id}`);
export const createTagApi = async (tag: TagCreateRequest): Promise<AxiosResponse<Tag>> => axiosInterceptor("post", tagsApiPath, { ...tag });
export const updateTagApi = async (id: string, tag: TagUpdateRequest): Promise<AxiosResponse<Tag>> => axiosInterceptor("patch", `${tagsApiPath}/${id}`, tag);
export const deleteTagApi = async (id: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${tagsApiPath}/${id}`);

