import { FC, useEffect, useState } from "react";
import {
  theme,
  Button,
  Card,
  Space,
  Divider,
  Typography,
  Checkbox,
  Radio,
  CheckboxProps
} from "antd";
import { useOrganizationStore, useSearchStore } from "store";
import SearchableTags from "components/SearchableTags";
import { AIBasedSearchQueryPolicyName, AISearchQueryEngine, NativeSearchQueryEngine, SearchRunProviders, SearchTags } from "types";

const Text = Typography.Text;

export interface SearchFiltersProps {
  isAiQueryEngineEnabled: boolean;
  isAiBasedQuery?: boolean;
  searchTags?: SearchTags;
  onEngineChanged?: (engine: string) => void;
  onTagsChanged: (selectedTags: string[] | undefined) => void
}

export const SearchFilters: FC<SearchFiltersProps> = ({
  isAiQueryEngineEnabled,
  isAiBasedQuery,
  searchTags,
  onEngineChanged,
  onTagsChanged,
}) => {
  const { token } = theme.useToken();
  const [selectedTags, setSelectedTags] = useState<string[]>(searchTags ? searchTags : []);
  const [tagsFilterEnabled, setTagsFilterEnabled] = useState<boolean>(false);
  
  const { mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  console.log("setAiQueryEngineEnabled", isAiQueryEngineEnabled, isAiBasedQuery)
  useEffect(() => {
    if (searchTags && searchTags.length != 0) {
      setTagsFilterEnabled(true);
      setSelectedTags(searchTags);
    } else {
      setTagsFilterEnabled(false);
    }
  }, [searchTags])

  
  const renderSearchTags = () => {
    return (
      <SearchableTags
        tags={tags}
        selectedTags={selectedTags ? selectedTags : []}
        onSelect={(tag) => {
          let tags = [...selectedTags, tag.id];
          if (selectedTags.includes(tag.id)) {
            tags = [...selectedTags.filter((t) => t != tag.id)];
          }
          setSelectedTags(tags);
          onTagsChanged(tags);
        }}
      />
    )
  }

  const resetFilters = () => {
    setTagsFilterEnabled(false);
    onTagsChanged(undefined);
  }

  const onTagsFilterChange: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked == false) {
      setTagsFilterEnabled(false);
      onTagsChanged(undefined);
    } else {
      setTagsFilterEnabled(true);
    }
  };
  const onQueryEngineChanged: CheckboxProps['onChange'] = (e) => {
    onEngineChanged && onEngineChanged(e.target.checked ? AISearchQueryEngine : NativeSearchQueryEngine)
  };

  return (
    <div>
      <Space
        direction="vertical"
        size={token.size}
        style={{
          marginTop: "10px",
          marginLeft: "10px",
        }}
      >
        {isAiQueryEngineEnabled &&
          <Checkbox
            checked={isAiBasedQuery}
            onChange={onQueryEngineChanged}
          >
            Search using AI Query Engine
          </Checkbox>
        }
        {mspEnabled &&
          <Checkbox
            checked={tagsFilterEnabled}
            onChange={onTagsFilterChange}
          >
            Search filter based on selected tags
          </Checkbox>
        }
        {mspEnabled && tagsFilterEnabled &&
          <div
            style={{
              marginLeft: "10px",
              marginBottom: "10px"
            }}
          >
            {renderSearchTags()}
          </div>
        }
      </Space>
      <div style={{ textAlign: "right" }}>
        <Space>
          <Button
            type="link"
            onClick={resetFilters}
            ghost
          >
            Reset Filters
          </Button>
        </Space>
      </div>
    </div>
  );
};
