import { Space } from "antd";
import {
  EditorContext,
  NumberField,
  ObjectField,
  RadioField,
  TextField,
  TextFieldType,
  ViewProps,
  rules,
} from "components/EntityEditor";
import { PrimitiveMapField } from "components/EntityEditor/primitiveMapField";
import React, { useContext, useEffect } from "react";
import {
  HTTPMethodType,
  HttpRequestContentType,
  HttpRequestType,
  HttpResponseContentType,
  ResourceType,
} from "types";
import { resourceFactory } from "utility/resource";

export const HttpRequestView = (props: ViewProps) => {
  /** target is  { recorKey: key, recordValue : HttpRequest object} */
  const mounted = React.useRef(false);

  const [recordKey, setRecordKey] = React.useState(props.data?.recordKey || "");
  const [recordValue, setRecordValue] = React.useState(
    props.data?.recordValue || {}
  );

  const [httpRequestObjectName] = React.useState<string>(
    props.context ? props.context["displayName"] : ""
  );

  const ctx = useContext(EditorContext);

  const onKeyChange = async (identifier: string, value: string) => {
    setRecordKey(value);
  };

  const onTextChange = async (identifier: string, value: string) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onNumberChange = async (identifier: string, value: number) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onObjectChange = async (identifier: string, value: any) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onSuccessConditionsChange = async (identifier: string, value: any) => {
    console.log("On successs", identifier, value);
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };
  

  const onMapChange = async (
    identifier: string,
    value: Record<string, string | number>
  ) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onMapAdd = async (key: string) => {
    const rv = {
      ...recordValue,
      [key]: { ...recordValue[key], "New Key": "New Value" },
    };
    setRecordValue(rv);
  };

  useEffect(() => {
    mounted.current
      ? ctx.onChange({ recordKey, recordValue })
      : (mounted.current = true);
  }, [recordKey, recordValue]);

  const defaultHttpRequest = resourceFactory.createHttpRequest();
  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <TextField
        identifier={"recordKey"}
        label={"Name"}
        help={"Http request object name used for transformation"}
        value={recordKey}
        path={[...props.path, "recordKey"]}
        onChange={onKeyChange}
        validators={[rules.required]}
      />

      <TextField
        identifier={"path"}
        label={"Path"}
        help={"Url path if any, e.g. /api/v1/devices"}
        value={recordValue?.["urlPattern"] || defaultHttpRequest.path}
        path={[...props.path, "urlPattern"]}
        onChange={onTextChange}
        optional={true}
      />
      <TextField
        identifier={"method"}
        label={"Http Method"}
        help={"Http request method type"}
        value={recordValue?.["method"] || defaultHttpRequest.method}
        path={[...props.path, "urlPattern"]}
        onChange={onTextChange}
        options={Object.values(HTTPMethodType).map((v) => ({
          label: v,
          value: v,
        }))}
      />
      <TextField
        identifier={"contentType"}
        label={"Request Content Type"}
        help={"Http request content type"}
        value={recordValue?.["contentType"] || defaultHttpRequest.contentType}
        path={[...props.path, "contentType"]}
        onChange={onTextChange}
        options={Object.values(HttpRequestContentType).map((v) => ({
          label: v,
          value: v,
        }))}
      />
      <NumberField
        identifier={"timeout"}
        label={"Timeout"}
        help={"Timeout in seconds"}
        value={recordValue?.["timeout"] || defaultHttpRequest.timeout}
        path={[...props.path, "requestType"]}
        onChange={onNumberChange}
      />
      <PrimitiveMapField
        identifier={"headers"}
        label={"Headers"}
        help={"Http request headers"}
        value={recordValue?.["headers"] || defaultHttpRequest.headers}
        path={[...props.path, "headers"]}
        onChange={onMapChange}
        onAdd={onMapAdd}
        optional={true}
      />

      <PrimitiveMapField
        identifier={"pathParams"}
        label={"Path Parameters"}
        help={"Http request path params"}
        value={recordValue?.["pathParams"] || defaultHttpRequest.pathParams}
        path={[...props.path, "pathParams"]}
        onChange={onMapChange}
        onAdd={onMapAdd}
        optional={true}
      />
      <PrimitiveMapField
        identifier={"queryParams"}
        label={"Query Parameters"}
        help={"Http request query params"}
        value={recordValue?.["queryParams"] || defaultHttpRequest.queryParams}
        path={[...props.path, "pathParams"]}
        onChange={onMapChange}
        onAdd={onMapAdd}
        optional={true}
      />
      <TextField
        identifier={"payload"}
        label={"Request Payload"}
        help={"Http request payload which will be sent along with rquest"}
        value={recordValue?.["payload"] || defaultHttpRequest.payload}
        path={[...props.path, "payload"]}
        onChange={onTextChange}
        type={TextFieldType.textArea}
        optional={true}
      />

      <TextField
        identifier={"responseContentType"}
        label={"Response Content Type"}
        help={"Http response content type"}
        value={
          recordValue?.["responseContentType"] ||
          defaultHttpRequest.responseContentType
        }
        path={[...props.path, "responseContentType"]}
        onChange={onTextChange}
        options={Object.values(HttpResponseContentType).map((v) => ({
          label: v,
          value: v,
        }))}
      />
      
      <ObjectField
        item={{
          id: "http-request-retry-policy",
          item: recordValue?.["retryPolicy"] || defaultHttpRequest.retryPolicy,
          type: ResourceType.HttpRetryPolicy,
        }}
        identifier={"retryPolicy"}
        help={"Retry policy for http request"}
        label={"Retry Policy"}
        path={[...props.path, "retryPolicy"]}
        onChange={onObjectChange}
        showExpanded={false}
        optional={true}
      ></ObjectField>

      <ObjectField
        item={{
          id: "http-request-poll-policy",
          item: recordValue?.["pollPolicy"] || defaultHttpRequest.pollPolicy,
          type: ResourceType.HttpPollPolicy,
        }}
        identifier={"pollPolicy"}
        help={"Polly policy for http request"}
        label={"Poll Policy"}
        path={[...props.path, "pollPolicy"]}
        onChange={onObjectChange}
        showExpanded={false}
        optional={true}
      ></ObjectField>
    </Space>
  );
};
