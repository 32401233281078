import { FC, useState, useEffect, useRef } from "react";

import {Space, theme, InputRef, Input, Tag, Tooltip } from "antd";
import {  PlusOutlined } from '@ant-design/icons';

export interface LabelsProps {
  editMode: boolean;
  labels: string[];
  onChange: (labels: string[]) => void;
}

const Labels: FC<LabelsProps> = (props) => {
  const {token} = theme.useToken();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedLabel: string) => {
    const newLabels = props.labels.filter((label) => label !== removedLabel);
    console.log(newLabels);
    props.onChange?.(newLabels);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !props.labels.includes(inputValue)) {
      props.onChange?.([...props.labels, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newLabels = [...props.labels];
    newLabels[editInputIndex] = editInputValue;
    props.onChange?.([...props.labels, inputValue]);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const labelInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  };

  const labelPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  return (
    <Space size={[0, 8]} wrap>
      {props.labels.map((label, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={label}
              size="small"
              style={labelInputStyle}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }
        const isLongLabel = label.length > 20;
        const labelElem = (
          <Tag
            key={label}
            closable={index !== 0}
            style={{ userSelect: 'none' }}
            onClose={() => handleClose(label)}
          >
            <span
              onDoubleClick={(e) => {
                if (index !== 0) {
                  setEditInputIndex(index);
                  setEditInputValue(label);
                  e.preventDefault();
                }
              }}
            >
              {isLongLabel ? `${label.slice(0, 20)}...` : label}
            </span>
          </Tag>
        );
        return isLongLabel ? (
          <Tooltip title={label} key={label}>
            {labelElem}
          </Tooltip>
        ) : (
          labelElem
        );
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={labelInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag style={labelPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          New Label
        </Tag>
      )}
    </Space>
  );

};
  
export default Labels;

