import { Divider, Form, Input, Spin, theme } from "antd";
import { Assign, HasIdentity } from "components/Assign";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useOrganizationStore } from "store";
import { Tag, TagUpdateRequest } from "types";
import { notification } from "utility/notification";

export interface TagEditProps {
  tag?: Tag;
  isAdd: boolean;
  /** List of all resources that are available for assignment */
  resources: HasIdentity[];
  onClose: () => Promise<void>;
}

export const TagEdit = (props: TagEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  /** Denotes list of assigned resoruces */
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [drawerState, setDrawerState] = useState(false);

  const updateTag = useOrganizationStore((state) => state.updateTag);
  /** List of all available resources / subscribers that can be use for tags */
  const createTag = useOrganizationStore((state) => state.createTag);

  const [assignmentDataSource, setAssignmentDataSource] = useState<
    HasIdentity[]
  >([]);

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }

      if (props.isAdd) {
        const { value } = values;
        await createTag({
          value,
          resources: selectedRows, // This is the list of assigned resources
        });
      } else {
        if (props.tag) {
          const existantResources = props.tag?.resources;
          const added = selectedRows.filter((x) =>
            !props.tag
              ? false
              : !existantResources.map((x) => x.id).some((y) => y === x)
          );
          const removed = existantResources
            .map((x) => x.id)
            .filter((x) => !selectedRows.some((y) => y === x));
          const { value } = values;
          const updateData: TagUpdateRequest = {
            value,
            addResources: added,
            removeResources: removed,
          };
          await updateTag(props.tag.id, updateData);
        }
      }
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } tag, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const formFields = [
    {
      name: "value",
      label: "Value",
      rules: [{ required: true, message: "Please input tag value!" }],
    },
  ];

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setDrawerState(true);
      try {
        setLoading(true);
        if (props.tag) {
          const fields = {
            value: props.tag.value,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
        const r = props.tag?.resources || [];
        setSelectedRows(r.map((x) => x.id));
        setAssignmentDataSource(props.resources);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [props.tag, props.resources]);

  const renderSwitchForInputType = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      default:
        return <Input />;
    }
  };

  const onResourceSelectionChange = async (
    selectedRowKeys: any[]
    // selectionRows: any[]
  ) => {
    setSelectedRows(selectedRowKeys);
  };

  const onClose = async () => {
    setSelectedRows([]);
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Tags"
      onClose={onClose}
      open={drawerState}
      subtitle="Management and Assignment of Tags"
      onSubmit={props.tag?.readonly ? undefined : handleSubmit}
    >
      <Spin spinning={loading}>
        <Form
          disabled={props.tag?.readonly}
          form={form}
          labelCol={{ span: 6, offset: 0 }}
          wrapperCol={{ span: 18, offset: 0 }}
        >
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              {renderSwitchForInputType(field.name)}
            </Form.Item>
          ))}
        </Form>
        <Divider />
        <Assign
          dataSource={assignmentDataSource}
          name="Susbcribers"
          onChange={onResourceSelectionChange}
          showAdd={false}
          readonly={!!props.tag?.readonly}
          multiple={true}
          type="subscribers"
        />
      </Spin>
    </Drawer>
  );
};
