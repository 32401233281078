import { FC, useEffect, } from "react";
import { Form, FormInstance, Input, Select } from "antd";
const { Option } = Select;
const { TextArea } = Input;

import { FieldLabel } from "components/FieldLabel";

export type HttpHeaderData =  {
  key: string;
  displayKey: string;
  defaultValue: string;
  isSecret: boolean;
}

export interface HttpHeaderProps {
  editMode: boolean;
  header: HttpHeaderData;
  onChange?: (value: HttpHeaderData) => void;
  onRender?: (form: FormInstance) => void;
}


const HttpHeader: FC<HttpHeaderProps> = (props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    props.onChange?.(values);
  };

  return (
    <Form 
      form={form} 
      name="headerForm" 
      layout="vertical" 
      autoComplete="off"
      initialValues={props.header}
      onValuesChange={onValuesChange}>
        <Form.Item 
          name="key" 
          label={<FieldLabel label={"Key"} help={"Key for header"} />}
          normalize={(value) => value.trim()}
          rules={[
            { required: true, message: 'Key is required!'},
            { validator: (_, value) =>
                  !value.includes(" ")
                    ? Promise.resolve()
                    : Promise.reject(new Error("No spaces allowed")) }
          ]}>
          <Input disabled={!props.editMode} />
        </Form.Item>
        <Form.Item 
          name="displayKey" 
          label={<FieldLabel label={"Display Key"} help={"Display key for header"} />}
          normalize={(value) => value.trim()}
          rules={[
            { required: true, message: 'Display key is required!'},
          ]}>
          <Input disabled={!props.editMode} />
        </Form.Item>
        <Form.Item 
          name="defaultValue" 
          label={<FieldLabel label={"Default Value"} help={"Default value for header"} />}
          normalize={(value) => value.trim()}
          rules={[]}
        >
          <Input disabled={!props.editMode} />
        </Form.Item>
        <Form.Item 
          name="isSecret" 
          label={<FieldLabel label={"Is Secret Header ?"} help={"Is secret header ?"} />}
          rules={[
            { required: true, message: 'Value is required!'}
          ]}
        >
         <Select
              disabled={!props.editMode}
              showAction={['focus', 'click']}
              onChange={(e) => {
                form.setFieldValue("isSecret", e.target.value)
              }}
          >
            <Option key={1} value={true}>True</Option>
            <Option key={0} value={false}>False</Option>
          </Select>
        </Form.Item>
    </Form>
  );
};
  
export default HttpHeader;