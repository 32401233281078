import { Space } from "antd";
import {
  EditorContext,
  NumberField,
  ObjectField,
  RadioField,
  ViewerType,
  ViewProps,
} from "components/EntityEditor";
import { resourceFactory } from "utility/resource";

import React, { useContext, useEffect } from "react";
import {
  Condition,
  DeveloperItem,
  HttpPollIncrementalTokenLocatorType,
  HttpPollIncrementTokenReaderType,
  ResourceType,
} from "types";

export const HttpPollPolicyView = (props: ViewProps) => {
  /** target is  { recorKey: key, recordValue : HttpRequest object} */
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const ctx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const onTextChange = async (identifier: string, value: string) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onNumberChange = async (identifier: string, value: number) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onObjectChange = async (identifier: string, value: any) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onMapChange = async (
    identifier: string,
    value: Record<string, string | number>
  ) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: { ...target.recordValue, [identifier]: value },
    });
  };

  const onMapAdd = async (key: string) => {
    setTarget({
      recordKey: target.recordKey,
      recordValue: {
        ...target.recordValue,
        [key]: { ...target.recordValue[key], "New Key": "New Value" },
      },
    });
  };

  const onPollConditionAdd = async (key: string): Promise<DeveloperItem> => {
    const index = target[key]?.length || 0;
    const condition: Condition = {
      fact: "",
      operator: "",
      value: "",
    };
    return {
      id: `${key}-${index}`,
      item: condition,
      type: ResourceType.Condition,
    };
  };

  useEffect(() => {
    ctx.onChange(target);
  }, [target]);
  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      
      <NumberField
        identifier={"pollIntervalInSeconds"}
        label={"Poll Interval In Seconds"}
        value={target["pollIntervalInSeconds"] || 60}
        path={[...props.path, "pollIntervalInSeconds"]}
        onChange={onNumberChange}
      />

      <RadioField
        identifier={"incrementalTokenReaderType"}
        label={"Token Reader Type"}
        value={target["incrementalTokenReaderType"]}
        path={[...props.path, "incrementalTokenReaderType"]}
        onChange={onTextChange}
        options={Object.values(HttpPollIncrementTokenReaderType).map((v) => ({
          label: v,
          value: v,
        }))}
        defaultSelected={
          target["incrementalTokenReaderType"] ||
          HttpPollIncrementTokenReaderType.Timestamp
        }
        direction={"horizontal"}
      />

      {target["incrementalTokenReaderType"] ===
        HttpPollIncrementTokenReaderType.Timestamp && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-reader-Timestamp",
            item: target["TimestampTokenReader"],
            type: ResourceType.HttpPollIncrementalTokenReaderTimestamp,
          }}
          identifier={"TimestampTokenReader"}
          label={"Timestamp Token Reader"}
          path={[...props.path, "TimestampTokenReader"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
      {target["incrementalTokenReaderType"] ===
        HttpPollIncrementTokenReaderType.ResponseHeader && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-reader-ResponseHeader",
            item: target["ResponseHeaderTokenReader"],
            type: ResourceType.HttpPollIncrementalTokenReaderResponseHeader,
          }}
          identifier={"ResponseHeaderTokenReader"}
          label={"Response Header Token Reader"}
          path={[...props.path, "ResponseHeaderTokenReader"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
      {target["incrementalTokenReaderType"] ===
        HttpPollIncrementTokenReaderType.ResponsePayload && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-reader-ResponsePayload",
            item: target["ResponsePayloadTokenReader"],
            type: ResourceType.HttpPollIncrementalTokenReaderResponsePayload,
          }}
          identifier={"ResponsePayloadTokenReader"}
          label={"Response Payload Token Reader"}
          path={[...props.path, "ResponsePayloadTokenReader"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}

      <RadioField
        identifier={"incrementalTokenLocatorType"}
        label={"Token Locator Type"}
        value={target["incrementalTokenLocatorType"]}
        path={[...props.path, "incrementalTokenLocatorType"]}
        onChange={onTextChange}
        options={Object.values(HttpPollIncrementalTokenLocatorType).map(
          (v) => ({
            label: v,
            value: v,
          })
        )}
        defaultSelected={
          target["incrementalTokenLocatorType"] ||
          HttpPollIncrementalTokenLocatorType.Headers
        }
        direction={"horizontal"}
      />

      {target["incrementalTokenLocatorType"] ===
        HttpPollIncrementalTokenLocatorType.Headers && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-locator-Headers",
            item: target["HeadersTokenLocator"],
            type: ResourceType.HttpPollIncrementalTokenLocatorHeaders,
          }}
          identifier={"HeadersTokenLocator"}
          label={"Headers Token Locator"}
          path={[...props.path, "HeadersTokenLocator"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
      {target["incrementalTokenLocatorType"] ===
        HttpPollIncrementalTokenLocatorType.Payload && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-locator-payload",
            item: target["payloadTokenLocator"],
            type: ResourceType.HttpPollIncrementalTokenLocatorPayload,
          }}
          identifier={"payloadTokenLocator"}
          label={"Payload Token Locator"}
          path={[...props.path, "payloadTokenLocator"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
      {target["incrementalTokenLocatorType"] ===
        HttpPollIncrementalTokenLocatorType.QueryParams && (
        <ObjectField
          item={{
            id: "http-poll-policy-incremental-token-locator-params",
            item: target["queryParamsTokenLocator"],
            type: ResourceType.HttpPollIncrementalTokenLocatorQuery,
          }}
          identifier={"queryParamsTokenLocator"}
          label={"Query Params Token Locator"}
          path={[...props.path, "queryParamsTokenLocator"]}
          onChange={onObjectChange}
          showExpanded={true}
        ></ObjectField>
      )}
    </Space>
  );
};
