import { Flex, Typography, theme } from "antd";
import { goalsIcons } from "assets/icons";
import { FC, useState } from "react";
import { useProfileStore, useSettingsStore } from "store";
import { useNavigate } from "react-router-dom";
import { GoalsItem } from "./GoalsItem";
import { GoalsEdit } from "./GoalsEdit";
import { QuickLinkComponent, QuickLinkMetaMap } from "types/quicklinks";
import { QuickLink, QuickLinkProps } from "./QuickLink";
const { Text } = Typography;

function getGreeting(): string {
  const currentTime = new Date().getHours();

  if (currentTime >= 5 && currentTime < 12) {
    return "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
}

function formatDateDetails(date: Date): {
  dayOfWeek: string;
  monthTextFull: string;
  dayOfMonthNumeral: string;
} {
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "full",
    timeStyle: "long",
  }).format(date);
  const [dayOfWeek, monthTextFull, dayOfMonthNumeral] =
    formattedDate.split(" ");
  return { dayOfWeek, monthTextFull, dayOfMonthNumeral };
}

export const Home: FC = () => {
  const navigate = useNavigate();
  const lightMode = useSettingsStore((state) => state.lightMode);
  const { dayOfWeek, monthTextFull, dayOfMonthNumeral } = formatDateDetails(
    new Date()
  );
  const { profile } = useProfileStore((state) => ({
    profile: state.profile,
  }));

  const { token } = theme.useToken();
  const [goalAdd, setGoalAdd] = useState(false);

  const colors: string[] = [
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
    "#fee8da",
    "#e8f4da",
    "#dae8f4",
    "#e8daee",
    "#e8e8e8",
  ];

  const quickLinkProps: QuickLinkProps[] = [
    {
      id: "quick-link-0",
      category: "Operate",
      duration: "2 minutes",
      cta: "Create Measurable Goals",
      backgroundColor: token.colorPrimaryBg,
      description:
        "Create and manage an organization goal, track progress and share with your team",
      icon: goalsIcons.goalsCreateGoalsIcon,
      onCtaClick: () => setGoalAdd(true),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Goals)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Goals)?.thumbnail as string,
    },
    {
      id: "quick-link-1",
      category: "Configuration",
      duration: "1 minute",
      cta: "Configure Apps",
      backgroundColor: token.colorPrimaryBg,
      description:
        "Configure apps to enable capabilities on the hypredge platform. Leverage the power of automation using you enterprise application like servicenow amd virustotal",
      icon: goalsIcons.goalsConfigureAppsIcon,
      onCtaClick: () => navigate("/apps"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Apps)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Apps)?.thumbnail as string,
    },
    {
      id: "quick-link-2",
      category: "Configuration",
      duration: "1 minute",
      cta: "Configure Edges",
      backgroundColor: token.colorPrimaryBg,
      description:
        "Configure edges to optimize data transfer on the hypredge platform. Prevent data leakage and optimize data transfer",
      icon: goalsIcons.goalsConfigureEdgesIcon,
      onCtaClick: () => navigate("/edges"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Edges)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Edges)?.thumbnail as string,
    },
    {
      id: "quick-link-3",
      backgroundColor: token.colorPrimaryBg,
      category: "Operate",
      duration: "2 minutes",
      cta: "Try Federated Search",
      description:
        "Search for artifacts across multiple applications and get a unified view of your data. Find the needle in the haystack in seconds",
      icon: goalsIcons.goalsTrySearchIcon,
      onCtaClick: () => navigate("/search"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Search)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Search)?.thumbnail as string,
    },
    {
      id: "quick-link-4",
      backgroundColor: token.colorPrimaryBg,
      category: "Operate",
      duration: "8 minutes",
      cta: "Create HyprFlows",
      description:
        "Create workflows that tie together multiple applications and automate your business processes. Save hours of manual work and improve productivity",
      icon: goalsIcons.goalsCreateHyprFlowsIcon,
      onCtaClick: () => navigate("/hyprflows"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Hyprflows)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Hyprflows)?.thumbnail as string,
    },
    {
      id: "quick-link-5",
      backgroundColor: token.colorPrimaryBg,
      category: "Collaborate",
      duration: "3 minutes",
      cta: "Workspace",
      description:
        "Collaborate across different teams in your organization such as IT, SOC, Risk & Exposure Management etc. via approvals and review past execution of Workflows, Searches and Actions.",
      icon: goalsIcons.goalsWorkspace,
      onCtaClick: () => navigate("/workspace"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Workspace)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Workspace)?.thumbnail as string,
    },
    {
      id: "quick-link-6",
      backgroundColor: token.colorPrimaryBg,
      category: "Operate",
      duration: "4 minutes",
      cta: "Cases",
      description:
        "Manage and track cases across your organization in one place while making it part of your automation workflows to create and update cases.",
      icon: goalsIcons.goalsCases,
      onCtaClick: () => navigate("/cases"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Cases)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Cases)?.thumbnail as string,
    },
    {
      id: "quick-link-7",
      backgroundColor: token.colorPrimaryBg,
      category: "Review",
      duration: "2 minutes",
      cta: "Dashboard",
      description:
        "Track Productivity in terms of time saved and IO data saved with a drill down into Workflows, Searches, Edges and Apps. Create your own dashboard to track business metrics of your choice.",
      icon: goalsIcons.goalsDashboard,
      onCtaClick: () => navigate("/dashboard"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Dashboard)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Dashboard)?.thumbnail as string,
    },
    {
      id: "quick-link-8",
      backgroundColor: token.colorPrimaryBg,
      category: "Collaborate",
      duration: "4 minutes",
      cta: "Invite Users",
      description:
        "Invite team members to collaborate on your goals. Assign roles and permission boundaries for each user",
      icon: goalsIcons.goalsInviteUsersIcon,
      onCtaClick: () => navigate("/users-and-roles"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Rbac)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Rbac)?.thumbnail as string,
    },
    {
      id: "quick-link-9",
      backgroundColor: token.colorPrimaryBg,
      category: "Operate",
      duration: "3 minutes",
      cta: "Administration",
      description:
        "Configure enterprise wide policies such as retention period of Workflows and Searches, default apps for Triggers, Actions and Artifacts, Enable / Disable AI for Federated Search.",
      icon: goalsIcons.goalsAdministrationIcon,
      onCtaClick: () => navigate("/administration"),
      videoUrl: QuickLinkMetaMap.get(QuickLinkComponent.Administration)?.url as string,
      thumbnailUrl: QuickLinkMetaMap.get(QuickLinkComponent.Administration)?.thumbnail as string,
    }
  ];

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        padding: token.padding,
        width: "100%",
      }}
    >
      <div
        id="welcome-to-hypredge-box"
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: token.colorBgContainer,
          padding: token.padding,
          marginBottom: token.margin,
        }}
      >
        <Text
          style={{
            fontSize: token.fontSizeHeading5,
          }}
        >
          {dayOfWeek} {monthTextFull} {dayOfMonthNumeral}
        </Text>
        <Text
          style={{
            fontSize: token.fontSizeHeading3,
          }}
        >
          {getGreeting()}, {profile?.firstName} {profile?.lastName}
        </Text>
      </div>
      {/* <div id="goals-metrics-box">Home</div> */}
      <div id="goals-table-box">
        <GoalsItem />
      </div>
      <div
        id="configure-box"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: token.colorBgContainer,
          padding: token.padding,
          marginBottom: token.margin,
        }}
      >
        <div
          id="quick-link-text-box"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignSelf: "flex-start",
            marginBottom: token.margin
          }}
        >
          <Text style={{ fontSize: token.fontSizeHeading4 }}>Quick Links</Text>
        </div>
        <Flex wrap="wrap" gap={token.size}>
          {quickLinkProps.map((p) => (
            <div
              key={p.id}
              id={p.id}
              style={{
                width: "calc(33.33% - 20px)", // Each item takes up 1/3 of the container width minus the gap
              }}
            >
              <QuickLink
                backgroundColor={p.backgroundColor}
                category={p.category}
                cta={p.cta}
                description={p.description}
                onCtaClick={p.onCtaClick}
                duration={p.duration}
                icon={p.icon}
                videoUrl={p.videoUrl}
                thumbnailUrl={p.thumbnailUrl}
                key={p.id}
                id={p.id}
              />
            </div>
          ))}
        </Flex>
        {goalAdd && (
          <GoalsEdit
            isAdd={false}
            onClose={async () => setGoalAdd(false)}
          />
        )}
      </div>
    </div>
  );
};
