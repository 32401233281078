import {
  Divider,
  List,
  Space,
  Spin,
  Typography,
  notification,
  theme,
  Image,
  Badge,
  Tag,
  Avatar,
  Flex,
  Tooltip,
} from "antd";

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSubscriptionStore, useEdgeGlobalStore, useOrganizationStore, useSettingsStore, useUserInfoStore } from "store";
import Modal from "components/Modal";
import ControlButton from "components/ControlButton/controlButton";
import { SvgIcon } from "components/SvgIcon";
import { appIcons, commonIcons } from "assets/icons";
import { AdapterType, AppConfigsProps, AppSubscription, EdgeInfraType, EdgeInfraTypeMap, EdgeStatus, EdgeStatusMap, Workflow, dateTimeFormatOptions } from "types";
import { getWorkflowsWithFiltersApi } from "api";
import AppConfig from "./appConfig";
import WebhookConfig from "./webhookConfig";
import { HLink } from "components/Link";
import { getAppLogoUrl } from "utility/app";
import { getTagColor } from "utility";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { TextWithIcon } from "components/TextWithIcon";
import { getEdgeShortLogo } from "utility/edge";
import { color } from "html2canvas/dist/types/css/types/color";
import { Background } from "reactflow";

const { Text, Title } = Typography;

export const AppConfigs: FC<AppConfigsProps> = ({
  appId
}) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  
  const [showAppConfigModal, setShowAppConfigModal] = useState<{enable: boolean;  id?: string}>({enable: false});
  const [deleteAppConfigModal, setDeleteAppConfigModal] = useState<{enable: boolean; id?: string; name?: string; workflows?: Workflow[]}>({enable: false});
  const [showWebhookConfig, setShowWebhookConfig] =useState<{enable: boolean;  id?: string}>({enable: false});

  const mode = useSettingsStore((state) => state.lightMode);

  const edges = useEdgeGlobalStore((state) => state.edges);

  const {
    mspEnabled,
    context,
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const {
    selectedApp,
    appSubscriptions,
    getAppSubscriptions,
    clearAppSubscriptions,
    createAppSubscription,
    updateAppSubscription,
    deleteAppSubscription,
    appSubscriptionPage,
    setCurrentPage,
    clearAppSubscription,
    preferenceColumns,
    setPreferenceColumns,
    defaultPreferenceColumns,
  } = useAppSubscriptionStore((state) => ({
    selectedApp: state.selectedApp,
    appSubscriptions: state.appSubscriptions,
    getAppSubscriptions: state.getAppSubscriptions,
    clearAppSubscriptions: state.clearAppSubscriptions,
    createAppSubscription: state.createAppSubscription,
    updateAppSubscription: state.updateAppSubscription,
    deleteAppSubscription: state.deleteAppSubscription,
    appSubscriptionPage: state.appSubscriptionPage,
    setCurrentPage: state.setCurrentPage,
    clearAppSubscription: state.clearAppSubscription,
    preferenceColumns: state.preferenceColumns,
    setPreferenceColumns: state.setPreferenceColumns,
    defaultPreferenceColumns: state.defaultPreferenceColumns,
  }));

  const loadAppSubscriptions = async (appId: string, pageNumber : number, pageSize : number) => {
    try {
      setLoader(true);
      await getAppSubscriptions(appId, pageNumber, pageSize);
      setCurrentPage(pageNumber, pageSize);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadAppSubscriptions(appId, appSubscriptionPage.number, appSubscriptionPage.size);
  }, [appId, context]);

  useEffect(() => {
    try {
      const userIds = [] as string[];
      appSubscriptions.map((as) => {
        as.userID && as.userID != "" && userIds.push(as.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [appSubscriptions]);

  const onDeleteAppConfig = async (id: string) => {
    try {
      setLoader(true);
      const as = appSubscriptions.find((as) => as.id == id);
      if (as) {
        const [workflows, total] = await getWorkflowsWithFiltersApi(`appSubscriptionID=${id}`);
        if (workflows.length == 0) {
          setDeleteAppConfigModal({
            enable: true,
            id: id,
            name: as.name
          });
        }else {
          setDeleteAppConfigModal({
            enable: true,
            id: id,
            name: as.name,
            workflows: workflows
          });
        } 
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    } 
  };

  const deleteAppConfig = async (id: string) => {
    try {
      setLoader(true);
      setDeleteAppConfigModal({ enable: false });
      await deleteAppSubscription(id);
      notification.success({
        message: "APP CONFIG DELETED SUCCESSFULLY",
        description:
          "Your app " +
          deleteAppConfigModal.name +
          " configuraiton is deleted successfully",
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "SOMETHING WENT WRONG",
        description:
          "Something went wrong while deleting your app " +
          deleteAppConfigModal.name +
          "configuration, please try again",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const saveAppConfig = async (appSubscription: AppSubscription) => {
    try {
      setLoader(true);
      if (appSubscription) {
        if (appSubscription?.id) {
          await updateAppSubscription(appSubscription);
        } else {
          appSubscription.appID = appId;
          await createAppSubscription(appSubscription);
        }
        notification.success({
          message: "APP CONFIGURATION UPDATED SUCCESSFULLY",
          description:
            "Your app " +
            appSubscription.name +
            " configuration is updated successfully",
          duration: 6,
        });
      }
    } catch (error) {
      notification.error({
        message: "SOMETHING WENT WRONG",
        description:
          "Something went wrong while configuring your app, please try again",
        duration: 6,
      });
    } finally {
      setLoader(false);
      await loadAppSubscriptions(appId, appSubscriptionPage.number, appSubscriptionPage.size);
    }
  };

  const onCloseAppConfig = async (refreshData?: boolean) => {
    setShowAppConfigModal({enable: false});
    if (refreshData) {
      setLoader(true);
      await getAppSubscriptions(appId, appSubscriptionPage.number, appSubscriptionPage.size);
      setLoader(false);
    }
  };

  const columns: ColumnsType<AppSubscription> = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      render: (_, record) => {
        const edge = edges?.find((edge) => edge.id == record.edgeID);
        return (
          edge?.status == EdgeStatus.Online
            ? 
            <HLink 
              onClick={() => setShowAppConfigModal({enable: true, id: record.id})} 
              text={record.name} 
            />
            :
            <Tooltip 
              title={edge?.displayName + " edge is not online"}
              color={token.colorPrimary}
            >
              <Text>{record.name}</Text>
            </Tooltip>
            
        );
      },
    },
   /*  {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        return (
          <Badge count={status} color={status == AppSubscriptionStatusType.Enabled ? token.colorSuccess : token.colorTextDisabled} />
        );
      },
    }, */
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      render: (tenantID) => {
        return (
          <Tag color={getTagColor(`${tenantID}_tag`)}>{tags?.find((tag) => (tag.id == `${tenantID}_tag`))?.value}</Tag>
        );
      },
    },
    {
      title: "Edge",
      dataIndex: "tenatID",
      key: "edge",
      render: (_, record) => {
        const edge = edges?.find((edge) => edge.id == record.edgeID);
        return (
          edge && 
          <Flex gap={"small"} >
            <SvgIcon size={20} component={getEdgeShortLogo(edge.infraType)} />
            <Text>{edge.displayName}</Text>
          </Flex>
        );
      },
    },
    {
      title: "Last Update By",
      dataIndex: "userID",
      key: "userID",
      sorter: (a, b) => a.userID.localeCompare(b.userID),
      sortDirections: ["descend", "ascend"],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Last Update On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      sortDirections: ["descend", "ascend"],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
    {
      title: "Webhook",
      dataIndex: "",
      key: "webhook",
      hidden: selectedApp?.adapterTypes?.findIndex((type) => type == AdapterType.Webhook) == -1,
      render: (_, record) => {
        return (
          <HLink onClick={() => setShowWebhookConfig({enable: true, id: record.id})} text={"View"} />
        );
      },
    },
  ];

  return (
    <div style={{ margin: token.margin, width: "100%" }}>
      <div
        id="app-config-header"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "60px",
          padding: token.paddingXS,
          backgroundColor: token.colorBorderSecondary,
          alignItems: "center",
        }}
      >
        <ControlButton
          displayName={"Back"}
          svgComponent={
            <SvgIcon
              onClick={() => {
                navigate(-1);
                clearAppSubscriptions();
              }}
              component={commonIcons.backIcon}
            />
          }
          hoverable={true}
        />
        <Space size={10}>
          <Image 
            preview={false} 
            draggable={false}
            height={"25px"}
            src={getAppLogoUrl(selectedApp, mode)}
            alt="company-logo"
          />
          <Text>Configurations</Text>
        </Space>
        <div></div>
      </div>
      {useDivider && <Divider />}
      <Spin spinning={loader}>
        <EntitiesDisplay
          header={"Configurations"}
          dataSource={appSubscriptions as any[]}
          columns={columns}
          getItemProps={(record: AppSubscription) => {
            const edge = edges?.find((edge) => edge.id == record.edgeID);
            return {
              disabled: `${record.tenantID}_tag` != context || edge?.status != EdgeStatus.Online,
            };
          }}
          preferences={{
            columns: preferenceColumns,
            defaultColumns: defaultPreferenceColumns,
            setColumns: setPreferenceColumns,
            //setPagesize: (pageSize) => setCurrentPage(appSubscriptionPage.number, pageSize)
          }}
          total={appSubscriptions?.length}
          actions={
            context?.endsWith('_tag')
            ?
              [
                {
                  key: "add", 
                  label: "Add", 
                  enable: () => true, 
                  onClick: () => {
                    clearAppSubscription();
                    setShowAppConfigModal({enable: true, id: ""});
                  }
                },
                {
                  key: "delete", 
                  label: "Delete", 
                  enable: (ids) => {
                    if (ids && ids.length == 1) {
                      return appSubscriptions.find((as) => as.id == ids[0] && `${as.tenantID}_tag` == context) != undefined;
                    } else {
                      return false;
                    }
                  }, 
                  showWarn: false,
                  //warnMessage: <div>Are you sure to delete parameters? <br></br> Dependent workflows or searches will be impacted</div>,
                  onClick: (value) => {
                    const ids = value as string[];
                    if (ids.length == 1) {
                      onDeleteAppConfig(ids[0]);
                    }
                  }
                }
              ]
              :
              []
            }
        />         
        {showAppConfigModal.enable && (
          <AppConfig
            appId={appId}
            appSubscriptionId={showAppConfigModal.id}
            open={showAppConfigModal.enable}
            onClose={(refresh) => onCloseAppConfig(refresh)}
            onSave={(as: AppSubscription) => {
              saveAppConfig(as);
              onCloseAppConfig(true);
            }}
         />
        )}
        {showWebhookConfig.enable && showWebhookConfig.id && (
          <WebhookConfig
            appSubscriptionId={showWebhookConfig.id}
            open={showWebhookConfig.enable}
            onClose={async (isRegenerated?: boolean) => {
              setShowWebhookConfig({enable: false});
              if (isRegenerated) {
                await loadAppSubscriptions(appId, appSubscriptionPage.number, appSubscriptionPage.size);
              }
            }}
            onRegenerate={async (as: AppSubscription) => await updateAppSubscription(as)}
          />
        )}
        {deleteAppConfigModal.enable && (
          <Modal
            title="Delete App Configuration"
            onClose={() =>
              setDeleteAppConfigModal({ enable: false })
            }
            open={deleteAppConfigModal.enable}
            onSubmit={() => {
              deleteAppConfigModal.id && deleteAppConfig(deleteAppConfigModal.id);
            }}
            disableSubmit={deleteAppConfigModal.workflows != undefined}
          >
            {deleteAppConfigModal.workflows
              ?
              <Space direction="vertical">
                <Text>{`App Configuration can not be deleted because it is in use by following workflow(s):`}</Text>
                <List
                  size="small"
                  itemLayout={"horizontal"}
                  dataSource={deleteAppConfigModal.workflows}
                  renderItem={(workflow) => (
                    <List.Item>
                      <Typography.Text strong>
                        <HLink  
                          key={workflow.id} 
                          onClick={() => window.open(`/workflows/${workflow.id}`,'_blank')}
                          text={workflow.name} 
                          tooltip={workflow.description}
                        />
                      </Typography.Text>
                    </List.Item>
                  )}
                  style={{marginLeft: token.marginSM}}
                />
              </Space>
              :
              <Text>
                {"Are you sure you want to delete app configuration '" + deleteAppConfigModal.name + "' ?"}
              </Text>
            }
          </Modal>
        )}
      </Spin>
    </div>
  );
};

export default AppConfigs;