import {  useEffect } from "react";
import ReactFlow, {
  Background, useEdgesState, useNodesState, SmoothStepEdge,
  BackgroundVariant, useReactFlow,
  useNodesInitialized, useStoreApi
} from "reactflow";

import ActionNode from "../Nodes/Action";
import DropzoneNode from "../Nodes/Dropzone";
import EndNode from "../Nodes/End";
import OperatorNode from "../Nodes/Operator";
import TriggerNode from "../Nodes/Trigger";
import { getLayoutedElements } from "./layout";
import { useWorkflowStore } from "store";

import "reactflow/dist/style.css";

const proOptions = { account: "paid-pro", hideAttribution: true };

type RenderFlowProps = {
  elements: any,
}

const edgeTypes = {
  normal: SmoothStepEdge,
  join: SmoothStepEdge, //normal edges 
};

const nodeTypes = {
  trigger: TriggerNode,
  action: ActionNode,
  operator: OperatorNode,
  dropzone: DropzoneNode,
  end: EndNode,
}

const options = {
  includeHiddenNodes: false,
};

const panOnDrag = [1, 1];
const defaultZoom = 1.1

const RenderFlow = ({ elements }: RenderFlowProps) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const nodesInitialized = useNodesInitialized(options);
  const store = useStoreApi();
  const { fitView, setViewport } = useReactFlow();

  const {
    renderFlowInitialized,
    setRenderFlowInitialized,
  } = useWorkflowStore((state) => ({
    renderFlowInitialized: state.renderFlowInitialized,
    setRenderFlowInitialized: state.setRenderFlowInitialized,
  }));

  const runLayout = async () => {
    if (elements.length > 0) {
      getLayoutedElements(elements).then((layoutedElements) => {
        setNodes(layoutedElements.filter((x: any) => x.position));
        setEdges(layoutedElements.filter((x: any) => !x.position));
      });
    }
  }

  const setCenter = (x: number, y: number, bottomY: number) => {
    const { width, height } = store.getState();
    if (bottomY > height) {
      const centerX = width / 2 - x;
      setViewport({ x: centerX, y: y + 10, zoom: defaultZoom, }, { duration: 0 });
    } else {
      setTimeout(() => fitView({ maxZoom: defaultZoom, duration: 0 }), 0)
    }
  }
  
  useEffect(() => {
    runLayout();
  }, [elements]);


  useEffect(() => {
    if (nodesInitialized && !renderFlowInitialized && nodes.length) {
      setRenderFlowInitialized();
      setCenter(nodes[0].position.x, nodes[0].position.y, nodes[nodes.length - 1].position.y)
    }
  }, [nodesInitialized]);

  return (
    /** Take up remaining width */
    <div id="renderflow" style={{ flex: 1, height: "calc(100vh - 135px)", width: "100%" }}>
      <ReactFlow
        proOptions={proOptions}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultViewport={{ x: 0, y: 0, zoom: defaultZoom }}
        nodesConnectable={false}
        nodesDraggable={false}
        nodesFocusable={false}
        edgesFocusable={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        maxZoom={3}
        minZoom={0.2}
        panOnScroll={true}
        fitViewOptions={{ maxZoom: defaultZoom }}
        snapToGrid={true}
        fitView
      >
        <Background variant={BackgroundVariant.Dots} size={1} gap={24} style={{ opacity: .7 }} />
      </ReactFlow>
    </div>
  );
};

export default RenderFlow;