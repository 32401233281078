import { Space } from "antd";
import {
  EditorContext,
  NumberField,
  TextField,
  ViewProps,
} from "components/EntityEditor";

import React, { useContext, useEffect } from "react";

export const HttpPollIncrementTokenReaderResponsePayloadView = (
  props: ViewProps
) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onPrimitiveChange = async (
    identifier: string,
    value: string | boolean | number
  ) => {
    setTarget({ ...target, [identifier]: value });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <TextField
        identifier={"token"}
        label={"Token"}
        value={target["token"]}
        path={[...props.path, "token"]}
        onChange={onPrimitiveChange}
      />

      <TextField
        identifier={"payload"}
        label={"Payload"}
        value={target["payload"]}
        path={[...props.path, "payload"]}
        onChange={onPrimitiveChange}
      />
    </Space>
  );
};
