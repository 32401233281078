import { FC, useEffect, useState } from "react";

import { Form, Space, Input, InputNumber, Select, FormInstance } from "antd";

import { 
  NodeCardParametersProps, 
  WorkflowNodeType, 
  IODataType, 
  OperatorIODataType, 
  OperatorParameter, 
  IOData,
  Condition,
  TextType,
  ArrayType,
  ArrayObject,
  ArrayObjectDataType,
} from "types"

import { capitalizeFirstWord, validateFormFields } from "utility"

import ConditionsForm from "components/ConditionsForm";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";
import ArrayObjectsForm from "./ArrayObjects";

const { Option } = Select;

const NodeCardParameters: FC<NodeCardParametersProps> = ({
  nodeType,
  editMode,
  parameters,
  parametersValues,
  parameterSuggestionsTree,
  appName,
  onChange,
  onRender
}) => {
  const [form] = Form.useForm();
  const [conditionsForm, setConditionsForm] = useState<undefined|FormInstance>(undefined);
  const [arrayObjectsForm, setArrayObjectsForm] = useState<undefined|FormInstance>(undefined);
  
  useEffect(() => {
    onRender?.(form);
  }, []);

  const renderParameterValues = (name: string, value: OperatorParameter|IOData) => {
    return <Select
            key={name}
            disabled={!editMode}
            showAction={["focus", "click"]}
            placeholder={`Select a ${name}`}
            allowClear
            defaultValue={value?.default}
          >
            {value.values?.map((val: any) =>  (
                <Option key={val} value={val}>
                  {capitalizeFirstWord(val)}
                </Option>
            ))}
          </Select>;
  };

  const renderStringParameter = (name: string, value: OperatorParameter|IOData) => {
    return value.values ? (
            renderParameterValues(name, value)
          ) :
          (<SuggestionsInput
            editMode={editMode}
            name={name} 
            suggestionsTree={parameterSuggestionsTree}
            textType={value.textType}
          />);
  };

  const renderNumericParameter = (name: string, value: OperatorParameter|IOData) => {
    return value.values ? (
            renderParameterValues(name, value)
          ) :
          (<InputNumber
            key={name} 
            disabled={!editMode}
            placeholder={`Enter ${name}`}
          />);
  };

  const renderBooleanParameter = (value: OperatorParameter|IOData) => {
    return <Select
              disabled={!editMode}
              showAction={['focus', 'click']}
              allowClear
              defaultValue={value?.default}
          >
            <>
              <Option key={1} value={true}>
                True
              </Option>
              <Option key={0} value={false}>
                False
              </Option>
            </>
          </Select>;
  };

  const renderConditionsParameter = (name: string, required: boolean) => {
    return  <ConditionsForm 
              editMode={editMode}
              required={required}
              conditions={(parametersValues && (name in parametersValues)) ? parametersValues[name] : required ? [{} as Condition] : undefined}
              factSuggestionsTree={parameterSuggestionsTree}
              onRender={form => setConditionsForm(form)}
            />
  };

  const renderNativeArrayParameter = (name: string) => {
    return  <SuggestionsInput
              editMode={editMode}
              name={name} 
              suggestionsTree={parameterSuggestionsTree}
              textType={TextType.Array}
            />
  };

  const renderObjectArrayParameter = (name: string, value: IOData, hideValue?: boolean) => {
    return  <ArrayObjectsForm
              editMode={editMode}
              name={name}
              objects={
                (parametersValues && (name in parametersValues)) ? parametersValues[name] : value.required ? (hideValue ? [{name: "", type: ArrayObjectDataType.String} as ArrayObject] : [{name: "", type: ArrayObjectDataType.String, value: undefined} as ArrayObject]) : undefined
              }
              required={value.required}
              hideValue={hideValue}
              suggestionsTree={parameterSuggestionsTree}
              onRender={form => setArrayObjectsForm(form)}
            />
  };

  const renderMapParameter = (name: string) => {
    return  <SuggestionsInput
              editMode={editMode}
              name={name} 
              suggestionsTree={parameterSuggestionsTree}
              textType={TextType.Map}
            />
  };

  const renderIODataParameter = (name: string, value: IOData) => {
    switch (value.type) {
      case IODataType.String:
        return renderStringParameter(name, value);
      case IODataType.Numeric:
        return renderNumericParameter(name, value);
      case IODataType.Boolean:
        return renderBooleanParameter(value);
      case IODataType.Array:
        switch (value.arrayType) {
          case ArrayType.Object:
            return renderObjectArrayParameter(name, value);
          case ArrayType.ObjectWithNoValue:
            return renderObjectArrayParameter(name, value, true);
          default:
            return renderNativeArrayParameter(name);
        }
      default:
        return null;
    }
  }

  const renderOperatorParameter = (name: string, value: OperatorParameter) => {
    switch (value.type) {
      case OperatorIODataType.String:
        return renderStringParameter(name, value);
      case OperatorIODataType.Numeric:
        return renderNumericParameter(name, value);
      case OperatorIODataType.Boolean:
        return renderBooleanParameter(value);
      case OperatorIODataType.Conditions:
        return renderConditionsParameter(name, value.required);
      case OperatorIODataType.Array:
      case OperatorIODataType.Records:
        return renderNativeArrayParameter(name);
      case OperatorIODataType.Map:
        return renderMapParameter(name); 
      default:
        return null;
    }
  }

  //TODO - add more type specific validation params
  const getNativeTypeRule = (value: OperatorParameter|IOData): any => {
    switch (value.type) {
      case IODataType.String:
      case OperatorIODataType.String:
        //TODO - expect len or min&max or range from parameter def
        return { type: "string" };
      case IODataType.Numeric:
      case OperatorIODataType.Numeric:
        //TODO - expect len or min&max or range from parameter def
        return { type: "number" };
      case IODataType.Boolean:
      case OperatorIODataType.Boolean:
        return { type: "boolean" };
      case OperatorIODataType.Conditions:
        return { type:"any" };
      case OperatorIODataType.Array:
        return { type:"any" };
      default:
        return {};
    }
  };

  //TODO - add custom validation rules if any
  const getRules = (name: string, value: OperatorParameter|IOData): any[] => {
    const rules = [];
    if (value.required) {
      rules.push({ required: true, message: `${name} required!`})
    }
    rules.push(getNativeTypeRule(value));

    if (value.type == OperatorIODataType.Conditions) {
      if (value.required || getParameterValue(name, value)) {
        rules.push({ validator: () =>  validateFormFields(conditionsForm) });
      }
    }

    if (value.type == IODataType.Array) {
      switch (value.arrayType) {
        case ArrayType.Object, ArrayType.ObjectWithNoValue:
          if (value.required || getParameterValue(name, value)) {
            rules.push({ validator: () =>  validateFormFields(arrayObjectsForm) });
          }
          break;
        default:
          rules.push({
            validator: (_:any, value: any) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
          });
          break;
      }
    }

    if (value.type == IODataType.Map || (value.type == IODataType.String && !value.values)) {
      rules.push({
        validator: (_:any, value: any) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
      });
    }

    return rules;
  };

  const getParameterValue = (name: string, value: any) => {
    if (parametersValues && (name in parametersValues)) {
      if (value.type == IODataType.Array) {
        switch (value.arrayType) {
          case ArrayType.Object, ArrayType.ObjectWithNoValue:
            return parametersValues[name];
          default:
            return (parametersValues[name] as any[]).join(" ");
        }
      } else {
        return parametersValues[name];
      }
    } else {
      if (value.type == IODataType.Array) {
        switch (value.arrayType) {
          case ArrayType.Object, ArrayType.ObjectWithNoValue:
            return value.default ? value.default : undefined;
          default:
            return value.default ? (value.default as any[]).join(" "): undefined;
        }
      } else if (value.type == IODataType.Boolean) {
        return value.default;
      } else {
        return value.default ? value.default : undefined;
      }
    }
  };
  
  const onValuesChange = async () => {
    const currentValues = form.getFieldsValue(true);  
    const newValues: any = {}; 
    parameters && Object.entries(parameters).map(([name, value]) => {
      if (name in currentValues) {
        if (value.type == IODataType.Array) {
          switch (value.arrayType) {
            case ArrayType.Object, ArrayType.ObjectWithNoValue:
              newValues[name] = currentValues[name];
              break;
            default:
              newValues[name] = (currentValues[name] as string).split(" ");
              break;
          }
        } else {
          newValues[name] = currentValues[name];
        }
      }
    });
    onChange?.(newValues);
  };

  const getDisplayName = (name: string, value: OperatorParameter|IOData) => {
    let displayName = value.displayName ? value.displayName : capitalizeFirstWord(name);
    if (appName) {
      displayName += " (" + appName + ")";
    } 
    return displayName;
  };

  return (
    <Form 
      form={form} 
      name="parametersForm"
      layout="vertical" 
      autoComplete="off"
      onValuesChange={onValuesChange}
    >
      {parameters && Object.entries(parameters).sort((p1, p2) => p1[1]?.order-p2[1]?.order).map(([name, value]) => (
        <Space key={name} direction="vertical" style={{ display: 'flex' }}>
          <Form.Item 
            name={name}
            validateTrigger="onSubmit"
            label={
              <FieldLabel 
                label={getDisplayName(name, value)} 
                help={value.description ? value.description : null} 
              />
            }
            initialValue={getParameterValue(name, value)}
            rules={ getRules(capitalizeFirstWord(name), value) }
          >
            {
              (nodeType == WorkflowNodeType.Operator) ? renderOperatorParameter(name, value): renderIODataParameter(name, value)
            }
          </Form.Item>
        </Space>
      ))}
    </Form>
  );
};

export default NodeCardParameters;
