import { create } from "zustand";

import {
  CreatePermissionSet,
  CreateRole,
  CreateUser,
  PermissionSetDto,
  RbacStore,
  RbacTab,
  RoleDto,
  UpdatePermissionSet,
  UpdateRole,
  UpdateUser,
  UserDto,
  ClientCredentialsListResponseDto,
  ClientCredentials,
  ClientCredentialsCreateRequestDto,
  ClientCredentialsUpdateRequestDto,  
  ClientCredentialsCreateResponseDto
} from "types";



import {
  createPermissionSetApi,
  createRoleApi,
  createUserApi,
  deletePermissionSetApi,
  deleteRoleApi,
  deleteUserApi,
  getPermissionSetApi,
  getRoleApi,
  getUserApi,
  listPermissionSetsApi,
  listRolesApi,
  listUsersApi,
  updatePermissionSetApi,
  updateRoleApi,
  updateUserApi,
  listClientCredentialsApi,
  deleteClientCredentialsApi,
  createClientCredentialsApi,
  updateClientCredentialsApi
} from "api";


export const useRbacStore = create<RbacStore>()(
    (set, get) => ({       
      loading: false,
      activeTab: RbacTab.Users,
      initialized: false,
      eligible: false,
      users: {},
      roles: {},
      permissionSets: {},
      permissions: {},
      metricsEditable: true,
      psetConstituents: {},
      clientCredentials: {},
      
      setLoading: async (loading: boolean) => {
        set((state) => ({
          ...state,
          loading: loading,
        }));
      },

      getEligibility: async () => {
        const eligible = true;
        set((state) => ({
          ...state, eligible: eligible
        }));
      },
      setActiveTab: async (tab: RbacTab) => {
        set((state) => ({
          ...state,
          activeTab: tab,
        }));
      },
      
      listUsers: async () => {
        try {
          await get().setLoading(true);
          const response = await listUsersApi();
          const users = response.data.reduce((acc, user) => {
            acc[user.id] = user;
            return acc;
          }, {} as { [id: string]: UserDto });  
          set((state) => ({
            ...state,
            users: users,
          }));
        } finally {
          await get().setLoading(false);
        }
      },

      getUser: async (id: string) => {
        const response = await getUserApi(id);
        const user = response.data;
        set((state) => ({
          ...state,
          users: {
            ...state.users,
            [user.id]: user,
          },
        }));
      },
      
      createUser: async (user: CreateUser) => {
        await createUserApi(user);
        await get().listUsers();
        await get().listRoles();
      },

      updateUser: async (user: UpdateUser) => {
        await updateUserApi(user);
        await get().listUsers();
        await get().listRoles();
      },      

      deleteUser: async (id: string) => {
        await deleteUserApi(id);        
        await get().listUsers();
        await get().listRoles();
      },
      
      createRole: async (role: CreateRole) => {
        await createRoleApi(role);
        await get().listRoles();
        await get().listPermissionSets();
        await get().listUsers();
      },

      listRoles: async () => {
        try {
          const response = await listRolesApi();
          const roles = response.data.reduce((acc, role) => {
            // Do not assign if role id ends in _system_role
            if (role.id.endsWith("_system_role")) {
              return acc;
            }
            acc[role.id] = role;
            return acc;
          }, {} as { [id: string]: RoleDto });  
          set((state) => ({
            ...state,
            roles: roles,
          }));
        } finally {
          await get().setLoading(false);
        }
      },

      getRole: async (id: string) => {
        const response = await getRoleApi(id);
        const role = response.data;
        set((state) => ({
          ...state,
          roles: {
            ...state.roles,
            [role.id]: role,
          },
        }));
      },

      updateRole: async (role: UpdateRole) => {
        await updateRoleApi(role);
        await get().listRoles();
        await get().listPermissionSets();
        await get().listUsers();
      },

      deleteRole: async (id: string) => {
        await deleteRoleApi(id);
        await get().listRoles();
        await get().listPermissionSets();
        await get().listUsers();
      },
            
      createPermissionSet: async (ps: CreatePermissionSet) => {
        await createPermissionSetApi(ps);
        await get().listPermissionSets();
      },

      getPermissionSet: async (id: string) => {
        const response = await getPermissionSetApi(id);
        const permissionSet = response.data;
        set((state) => ({
          ...state,
          permissionSets: {
            ...state.permissionSets,
            [permissionSet.id]: permissionSet,
          },
        }));
      },

      listPermissionSets: async () => {
        try {
          get().setLoading(true);
          const response = await listPermissionSetsApi();
          const permissionSets = response.data.reduce((acc, ps) => {
            acc[ps.id] = ps;
            return acc;
          }, {} as { [id: string]: PermissionSetDto });  
          set((state) => ({
            ...state,
            permissionSets: permissionSets,
          }));
        } finally {
          await get().setLoading(false);
        }
      },
      updatePermissionSet: async (ps: UpdatePermissionSet) => {
        await updatePermissionSetApi(ps);
        await get().listPermissionSets();
      },

      deletePermissionSet: async (id: string) => {        
        await deletePermissionSetApi(id);
        await get().listPermissionSets();
      },

      listClientCredentials: async () => {
        try {
          get().setLoading(true);
          const response  = await listClientCredentialsApi();
          const data  : ClientCredentialsListResponseDto = response.data;
          const clientCredentials = data.items?.reduce((acc, cc) => {
            acc[cc.clientId] = cc;
            return acc;
          }, {} as { [id: string]: ClientCredentials });  
          set((state) => ({
            ...state,
            clientCredentials: clientCredentials,
          }));
        } finally {
          await get().setLoading(false);
        }
      },

      createClientCredentials: async (cc: ClientCredentialsCreateRequestDto) : Promise<ClientCredentialsCreateResponseDto> => {
        const response = await createClientCredentialsApi(cc);
        await get().listClientCredentials();
        return response.data;
      },

      updateClientCredentials: async (clientId: string, cc: ClientCredentialsUpdateRequestDto) => {
        await updateClientCredentialsApi(clientId, cc);
        await get().listClientCredentials();
      },

      deleteClientCredentials: async (clientId: string) => {
        await deleteClientCredentialsApi(clientId);
        await get().listClientCredentials();
      },
    })
);
