import { Space, Tabs, theme } from "antd";

import { Divider, Typography } from "antd";
import PageWrapper from "components/PageWrapper";
import { FC, useEffect, useState } from "react";
import {
  useOrganizationStore,
  useRbacStore,
  useSettingsStore,
  useTokenStore,
} from "store";

import { MetricLayoutIdentifier, RbacTab } from "types";

import { KpiPageHighlightGrid } from "components/Metrics";
import { PermissionSetTabItem } from "./PermissionSetTabItem";
import { RoleTabItem } from "./RoleTabItem";
import { UserTabItem } from "./UserTabItem";

const { Title, Text } = Typography;

import { rbacIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useMetricStore } from "store";
import { getUserDetailsFromJWT } from "utility";
import { ClientCredentialTabItem } from "./ClientCredentialsTabItem";
import { VideoPlayer } from "components/VideoPlayer";
import { QuickLinkComponent, QuickLinkMetaMap } from "types/quicklinks";

export const Rbac: FC = () => {
  const { token } = theme.useToken();
  const [activeTab, setActiveTab] = useRbacStore((state) => [
    state.activeTab,
    state.setActiveTab,
  ]);
  const context = useOrganizationStore((state) => state.context);
  const jwt = useTokenStore((state) => state.token);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const lightMode = useSettingsStore((state) => state.lightMode);

  useEffect(() => {
    const { tenantId } = getUserDetailsFromJWT();
    const readOnly = context != `${tenantId}_tag`;
    setReadOnly(readOnly);
  }, [context, jwt]);

  const tabItems = [
    {
      key: RbacTab.Users,
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={rbacIcons.usersShortIcon} />
          <Text>Users</Text>
        </Space>
      ),
      children: <UserTabItem readOnly={readOnly} />,
    },
    {
      key: RbacTab.Roles,
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={rbacIcons.rolesShortIcon} />
          <Text>Roles</Text>
        </Space>
      ),
      children: <RoleTabItem readOnly={readOnly} />,
    },
    {
      key: RbacTab.PermissionSets,
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={rbacIcons.permissionsShortIcon} />
          <Text>Permission Sets</Text>
        </Space>
      ),
      children: <PermissionSetTabItem readOnly={readOnly} />,
    },
    {
      key: RbacTab.ClientCredentials,
      label: (
        <Space size={token.marginXXS}>
          <SvgIcon component={rbacIcons.permissionsShortIcon} />
          <Text>Api Credentials</Text>
        </Space>
      ),
      children: <ClientCredentialTabItem readOnly={readOnly} />,
    },
  ];

  const { margin } = token;
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);

  const metrics = useMetricStore((state) => state.metrics);
  const fetchMetricsForLayout = useMetricStore(
    (state) => state.fetchMetricsForLayout
  );

  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutUsers);
    };
    asyncUseEffect();
  }, []);

  return (
    <PageWrapper
      highlights={
        <KpiPageHighlightGrid
          key="rbac"
          metrics={
            metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutUsers)
          }
        />
      }
      //feedback={<ActivityOverview />}
      content={
        <div style={{ margin, width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              height: "60px",
              paddingRight: token.padding,
              alignItems: "center",
            }}
          >
            <Title level={3}>Users And Roles</Title>
            <div style={{ position: "sticky", marginTop: token.margin, height:"90px", width: "200px", border: "solid", borderColor: token.colorPrimary}}>
              <VideoPlayer
                videoUrl={QuickLinkMetaMap.get(QuickLinkComponent.Rbac)?.url as string}
                thumbnailUrl={QuickLinkMetaMap.get(QuickLinkComponent.Rbac)?.thumbnail as string}
                playIconSize="small"
                pipMode={true}
              />
            </div>
          </div>
          {useDivider && <Divider />}
          <Tabs
            defaultActiveKey={activeTab}
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key as RbacTab)}
            items={tabItems}
          />
        </div>
      }
    ></PageWrapper>
  );
};
