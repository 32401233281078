import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "store";
import { notification } from "utility/notification";
import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./SignUpSetPassword.module.scss";

const SignUpSetPassword: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loader, setLoader] = useState<boolean>(false);
  const [submitButtonDisable, setSubmitButtonDisable] = useState<boolean>(true);

  const signUpSetPassword = useAuthStore((state) => state.signUpSetPassword);

  const onSubmit = async (value: any) => {
    console.log("value", value);
    setLoader(true);

    try {
      await signUpSetPassword(value.password);
      notification.success({
        message: "Success",
        description: "Password set successfully",
        duration: 6,
      });
      navigate("/signin");
    } catch (error) {
      console.error(error);
      // on error
      notification.error({
        message: "Error",
        description: "Error setting password. Please retry",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onValuesChange = () => {
    const values = form.getFieldsValue(true);
    if (
      values.password != undefined &&
      values.confirmPassword != undefined &&
      values.password != "" &&
      values.confirmPassword != ""
    ) {
      setSubmitButtonDisable(false);
    } else {
      setSubmitButtonDisable(true);
    }
  };

  return (
    <Spin spinning={loader}>
      <SignInSignUpWrapper>
        <div className={styles.wrapper}>
          <p className={styles.subTitle}>
            Enter your password to access HyprEdge.
          </p>
          <Form
            form={form}
            name="signUpSetPasswordForm"
            initialValues={{ remember: true }}
            autoComplete="off"
            onValuesChange={onValuesChange}
          >
            <p className={styles.fromp}>Password</p>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Password is required!" },
                () => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (
                      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                className="authInput"
                prefix={<LockOutlined />}
                placeholder="Enter your Password"
              />
            </Form.Item>
            <p className={styles.fromp}>Confirm Password</p>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="authInput"
                prefix={<LockOutlined />}
                placeholder="Confirm your Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="authbtn_100"
                type="primary"
                disabled={submitButtonDisable}
                onClick={() =>
                  form
                    .validateFields()
                    .then(onSubmit)
                    .catch((err) => {
                      console.log(err);
                    })
                }
              >
                Set Password
              </Button>
            </Form.Item>
          </Form>

          {/* <div className={styles.remindMeForgotPasswordWrapper}>
            <Checkbox>Remember me</Checkbox>
            <HLink
              className="textLink"
              onClick={() => navigate(`/forgot-password`)}
              text={"Reset your password?"}
            />
          </div> */}
        </div>
      </SignInSignUpWrapper>
    </Spin>
  );
};

export default SignUpSetPassword;
