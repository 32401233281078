import { axiosInterceptor } from "./axios";
import { AxiosResponse } from "axios";
import { edgeRbacApiPath, edgesApiPath, edgeSubscriptionsApiPath, jsonApiDeserializer, camelCaseEnforcerJsonApiDeserializer } from "./constant"
import { Edge, EdgeActionType, EdgeInfraType } from "types";
import { dtoFactory } from "utility/resource";
import { EdgeRbacClientCredentialsRequest, EdgeRbacClientCredentialsResponse } from "types/edge/edge_rbac";

export const getEdgesApi = async (
  includeSubscriptions?: boolean,
  pageNumber?: number,
  pageSize?: number,
  infraType?: EdgeInfraType,
  manageType?: string,
  filters?: string[],
  sorter?: string[],
  ) : Promise<[Edge[], number]>=> {
  
  let url = `${edgesApiPath}?`

  if (includeSubscriptions) 
    url += `&include=edge-subscriptions`
  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (infraType) 
    url += `&filter=equals(infraType,'${infraType}')`
  if (manageType) 
    url += `&filter=equals(manageType,'${manageType}')`
  
  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get", url);
  return [jsonApiDeserializer.deserialize(response.data) as Edge[], response?.data?.meta?.totalCount];
};

export const getEdgeApi = async (edgeId: string) : Promise<Edge>=> {
  const response = await axiosInterceptor("get",`${edgesApiPath}/${edgeId}`);
  return jsonApiDeserializer.deserialize(response.data) as Edge;
};

export const getEdgeEndpointApi = async (edgeId: string) : Promise<Edge>=> {
  const response = await axiosInterceptor("get",`${edgesApiPath}/${edgeId}?fields[edges]=endpoint`);
  return jsonApiDeserializer.deserialize(response.data) as Edge;
};

export const createEdgeApi = async (edge: Edge, deployNow?: boolean) :  Promise<Edge>=> {
  let url = `${edgesApiPath}`
  if (deployNow) 
    url += `&deployNow=${deployNow}`

  const response = await axiosInterceptor(
    "post",
    url,
    {
        "data": {
            "type": "edges",
            "id": edge.id,
            "attributes":  dtoFactory.convertEdgeToUploadObject(edge),
        }
    }
  );
  const edgeOut = jsonApiDeserializer.deserialize(response.data)  as Edge;
  return edgeOut;
};

export const updateEdgeApi = async (edge: Edge,  deployNow?: boolean): Promise<AxiosResponse<any, any>> => {
  let url = `${edgesApiPath}/${edge.id}`
  if (deployNow) 
    url += `?deployNow=${deployNow}`
    
  return axiosInterceptor(
    "patch",
    url,
    {
      "data": {
        "type": "edges",      
        "id": edge.id,
        "attributes": dtoFactory.convertEdgeToUploadObject(edge),
      }
    }
  );
};

export const deleteEdgeApi = async (edgeId: string): Promise<AxiosResponse<any,any>>=> {
  return await axiosInterceptor("delete",`${edgesApiPath}/${edgeId}`);
};

export const actionEdgeApi = async (edgeId: string, action: EdgeActionType, edge: unknown, skipCredentials?: boolean) => {
  let url = `${edgesApiPath}/${edgeId}/${action}`;
  if (skipCredentials) 
    url += `?skipCredentials=${skipCredentials}`

  const response = await axiosInterceptor(
      "post",
      url,
      {
        "data": {
          "type": "edges",      
          "id": edgeId,
          "attributes": edge,
        }
      }
    );
    return (response && response.data) ? jsonApiDeserializer.deserialize(response.data) : null;
};

export const enableEdgeSubscriptionApi = async (edgeId?: string) :Promise<AxiosResponse<any, any>>=> {
  const response = await axiosInterceptor("post",edgeSubscriptionsApiPath,{
    "data": {
      "type": "edge-subscriptions",
      "attributes": {
        "edgeID": edgeId
      }
    }
  });

  return response;
};

export const disableEdgeSubscriptionApi = async (
  edgeSubscriptionId?: string,
  ):Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor("delete",`${edgeSubscriptionsApiPath}/${edgeSubscriptionId}`);

  return response;
};

export const clientCredentialsEdgeRbacApi = async (
  payload: EdgeRbacClientCredentialsRequest
  ) : Promise<EdgeRbacClientCredentialsResponse> => {

  const response = await axiosInterceptor("post", `${edgeRbacApiPath}/proxy`, {
    "data": {
      "type": "edge-rbac",
      "attributes": {
          "apiType": "client-credentials",
          "payload": payload
      }
    }
  });
  return camelCaseEnforcerJsonApiDeserializer.deserialize(response.data) as EdgeRbacClientCredentialsResponse;
};