import { FC } from "react";
import { Button, Typography } from "antd";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./NoSsoUser.module.scss";

const { Text } = Typography;
const NoSsoUser: FC = () => {
  const { user } = useParams();
  const navigate = useNavigate();
  return (
    <SignInSignUpWrapper>
      <div
        className="verticalAlignDiv"
        style={{ width: "75%", maxWidth: "400px" }}
      >
        <div className={styles.formWrap}>
          <div className={styles.FormDiv}>
            {user ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ marginBlock: "20px" }}>
                  User <b>{atob(user)}</b> not found{" "}
                </Text>
                <Button
                  block
                  type="primary"
                  onClick={() => navigate("/signin")}
                >
                  Return to Sign In
                </Button>
              </div>
            ) : (
              <Navigate to={"/signin"} />
            )}
          </div>
        </div>
      </div>
    </SignInSignUpWrapper>
  );
};

export default NoSsoUser;
