
import { DataType } from "@textea/json-viewer";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import {
  WorkflowNodeType,
  Workflow,
  WorkflowRun,
  Trigger,
  TriggerType,
  Action,
  Operator,
  ActionProviders,
  TriggerProviders,
  WorkflowRunStatusCode,
  Approval,
  PageInfo,
} from "types";


export const workflowGlobalStoreName = "workflow-global-store";
export const workflowStoreName = "workflow-store";
export const workflowCardStoreName = "workflow-card-store";
export const workflowStepsStoreName = "workflow-steps-store";
export const  workflowStepProvidersStoreName = "workflow-step-providers-store";


//dragndrop
export enum DraggableSourceType {
    Sidebar = "sidebar",
    Editor = "editor"
  } 
  
  export interface DraggableItem {
    id?: string;
    nodeType: WorkflowNodeType;
    resourceId: string;
    resourceType: string,
    source:  DraggableSourceType;
    notCompatibleDroppables?: string [];
  }

  export interface DroppableItem {
    id?: string;
    nodeType: WorkflowNodeType;
    resourceId: string;
    resourceType: string,
    source:  DraggableSourceType;
    targetId: string
  }

  export enum HyprFlowTabType {
    Workflows = "workflows",
    WorkflowTemplates = "templates",
  }
  
  export interface WorkflowStore {
    workflows: Workflow[];
    templates: Workflow[];
    selectedWorkflow: Workflow;
    selectedWorkflowRun: WorkflowRun;
    subworkflows: Workflow[];
    activeNodeId: string;
    activeNodeCardId: string,
    activeDragItem: DraggableItem | null,
    activeDropItem: DroppableItem | null,
    activeCopyId: string| null,
    elements: any[];
    stepsSidebarCollapsed: boolean;
    renderFlowInitialized: boolean;
    workflowPage:  Record<HyprFlowTabType, PageInfo>,
    workflowFilters: Record<HyprFlowTabType, Record<string, FilterValue | null>>;
    workflowSorters: Record<HyprFlowTabType, SorterResult<DataType> | SorterResult<DataType>[]>;
    workflowSearchTextMap: Record<HyprFlowTabType, Map<string, string>>;
    activeTab: HyprFlowTabType,
    
    getWorkflows: (pageNumber: number, pageSize: number) => Promise<void>;
    getWorkflowTemplates: (pageNumber: number, pageSize: number) => Promise<void>;

    getWorkflow: (workflowId: string|undefined, syncAutoFill: boolean) => Promise<void>;
    getSubworkflows: (workflow: Workflow) => Promise<void>;
    clearSelectedWorkflow: () => void;

    changeSidebarCollapse: () => void;
  
    createWorkflow: (workflow: Workflow, isImport?: boolean, tagsOnly?: boolean) => Promise<Workflow>;
    updateWorkflow: (workflow: Workflow) => Promise<void>;
    deleteWorkflow: (workflowId: string) => Promise<void>;
    executeWorkflow: (workflowId: string, workflow: Workflow) => Promise<string>;
    publishWorkflow: (workflowId: string, workflow: Workflow) => Promise<void>;
    publishCancelWorkflow: (workflowId: string) => Promise<void>;
    shareWorkflow: (workflowId: string) => Promise<void>;
    unpublishWorkflow: (workflowId: string) => Promise<void>;
    revertWorkflow: (workflowId: string) => Promise<void>;
    enableWorkflow: (workflowId: string) => Promise<void>;
    disableWorkflow: (workflowId: string) => Promise<void>;
    editWorkflow: (workflowId: string) => Promise<void>;
  
    getWorkflowRunStatus: (workflowRunId: string) => Promise<WorkflowRunStatusCode>;
    getWorkflowRun: (workflowRunId: string, includeWorkflow: boolean) => Promise<void>;
    stopWorkflowRun: (workflowRunId: string) => Promise<void>;
  
    setActiveNodeId: (id: string) => void;
    setActiveNodeCardId: (activeNodeCardId: string) => void;
    setActiveDragItem: (item: DraggableItem|null) => void;
    setActiveDropItem: (item: DroppableItem|null) => void;
    setActiveCopyId: (id: string|null) => void;

    setElements: (elements: any[]) => void;
    
    resetWorkflowState: () => void;  
    setRenderFlowInitialized: () => void;  

    setActiveTab: (tabType: HyprFlowTabType) => void
    setCurrentPage: (tabType: HyprFlowTabType, pageNumber?: number, pageSize?: number) => void;
    setWorkflowFilters: (tabType: HyprFlowTabType, filters?: Record<string, FilterValue | null>) => void;
    setWorkflowSorters: (tabType: HyprFlowTabType, sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => void;
    setWorkflowSearchTextMap: (tabType: HyprFlowTabType, searches: Map<string, string>) => void;
  }
  
  export interface WorkflowStepsStore {
    triggers: Trigger[];
    actions: Action[];
    operators: Operator[];
    
    getTriggers: () => Promise<void>;
    getActions: () => Promise<void>;
    getOperators: () => Promise<void>;
  }
  
  export interface WorkflowStepProvidersStore {
    actionProvidersMap: Map<string, ActionProviders>;
    triggerProvidersMap: Map<string,TriggerProviders>;
    clearProviders: () => void;
    getActionProviders: (actionId: string) => Promise<void>;
    getTriggerProviders: (triggerId: string, triggerType: TriggerType) => Promise<void>;
  }
  
  export interface WorkerLogData {
    timestamp: string;
    message: string;
  }


export interface SidebarStore {
  sidebarCollapsed: boolean;
  sidebarWidth: string;
  changeSidebarCollapse: () => void;
}

export interface WorkflowGlobalStore {
  workflows: Workflow[];
  getWorkflows: () => Promise<void>;
}