import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useTokenStore } from "store/token";
import { TokenUtils } from "types";

const PrivateRoutes = () => {
  const expired = (token: string): boolean => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload).exp < Date.now() / 1000;
  };

  const token = useTokenStore((state) => state.token);
  const [tokenStatus, setTokenStatus] = useState(
    TokenUtils.getTokenStatus(token)
  );

  useEffect(() => {
    const status = TokenUtils.getTokenStatus(token);
    setTokenStatus(status);
  }, [token]);

  const path = window.location.pathname;
  const getUrl = () => {
    if (tokenStatus.subject)
      return `/relogin?user=${btoa(tokenStatus.subject)}&path=${btoa(path)}`;
    return `/signin`;
  };
  const authenticated = token && !expired(token);
  return authenticated ? <Outlet /> : <Navigate to={getUrl()} />;
};

export default PrivateRoutes;
