import { FC } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography, theme } from "antd";

const { Text } = Typography;


/** Fields are items in the  container that are able to be edited*/
export interface FieldLabelProps {
  /** Label for the field */
  label: string;
  /** Help text */
  help?: string;
  //bold 
  bold?: boolean;
}

export const FieldLabel: FC<FieldLabelProps> = (props) => {
  const { token } = theme.useToken();
  const getLabel = (label: string, bold?: boolean) => {
    return (
      <Text strong={bold}>
        {label}
      </Text>
    );
  };
  return (
    <Text>
      <Space size={token.marginXXS} direction="horizontal">
        {getLabel(props.label, props.bold)}
        {props.help && (
          <Tooltip title={props.help} color={token.colorPrimaryText}>
            <QuestionCircleOutlined style={{
               color: "red",
               width: "10px",
               height: "10px",
               marginLeft: token.marginXXS
            }}/>
          </Tooltip>
        )}
      </Space>
    </Text>
  );
};
