import { FC,  useState, useEffect } from "react";
import {
  Space,
  Typography,
  theme,
  Skeleton,
  Alert,
  Button,
  Badge,
} from "antd";

import {
  SearchRunActionStatus,SearchRunAction
} from "types";
import { getActionAlertType, getSearchRunStatusIcon, getSubHeadingMessageForActionBanner } from "../Utils";
import { useSearchStore } from "store";
import pRetry, { FailedAttemptError } from "p-retry";

const { Link } = Typography;

export interface ActionBannerProps {
  searchRunActionId: string;
  backgroundColor: string;
  hoverableColor: string;
  onClick?: () => void;
}

const ActionBanner: FC<ActionBannerProps> = ({
  searchRunActionId,
  backgroundColor,
  hoverableColor,
  onClick,
}) => {
  const { token } = theme.useToken();
  const [hover, setHover] = useState(false);
  const [searchRunAction, setSearchRunAction] = useState<SearchRunAction>({} as SearchRunAction);
  const abortController = new AbortController();

  const { getSearchRunAction, } = useSearchStore((state) => ({getSearchRunAction: state.getSearchRunAction,}));

  const syncSearchRunActionInBackground = async () => {
    const sra = await getSearchRunAction(searchRunActionId, false);
    setSearchRunAction(sra);
    if ( sra.status == SearchRunActionStatus.Running) {
      throw new Error(sra.status)
    }
  }

  
  useEffect(() => {
    const asyncUseEffect = async () => {
      pRetry(() => syncSearchRunActionInBackground(), {
        retries: 60,
        minTimeout: 5000,
        maxTimeout: 5000,
        signal: abortController.signal
      }).catch((e: FailedAttemptError) => {
        console.log("pretry sync search run status completed, exiting sync of status");
      })
      
    }
    asyncUseEffect();  
  },[searchRunActionId]);

  useEffect(() => {
    return () => {abortController.abort("exiting action banner page")} ;
  }, []);


  return (
    <>
      {(searchRunAction.id  && searchRunAction.actionInfo ) ? (
        <Alert
          style={{
            backgroundColor: hover ? hoverableColor : backgroundColor
          }}
          message={
            <Space direction="vertical">
              <Space size={token.size} style={{ marginTop: token.marginXXS }} >
                <div style={{marginTop: token.sizeXXS}}>
                  {getSearchRunStatusIcon(searchRunAction.status)}
                </div>
                <Badge count={searchRunAction.actionInfo.displayName} color={token.colorPrimary}></Badge>
              </Space>
              {getSubHeadingMessageForActionBanner(searchRunAction)}
            </Space>
          }
          action={
            <Button 
              type="link"
              onClick={()=>window.open(`/search-run-actions/${searchRunAction.id}`,'_blank', 'rel=noopener noreferrer')}
            >
              {"Details"}
            </Button>
          }
          type={getActionAlertType(searchRunAction.status)}    
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onClick}
        />
      ): (
        <Skeleton active style={{height: "60px"}}></Skeleton>
      )
      }
    </>
  )
};

export default ActionBanner;
