import { Space } from "antd";
import {
  RadioField,
  EditorContext,
  TextField,
  ViewProps,
} from "components/EntityEditor";
import {
  EdgeInfraTypeMap,
  EdgeInfraType,
  AppConfiguration,
} from "types";

import React, { useContext, useEffect } from "react";

export const AppEdgeDetectorConfigurationView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const [configurations] = React.useState<[]>(
    props.context ? props.context["configurations"] : []
  );

  const onTextChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const onRadioChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const infraTypeOptions = () => {
    return [
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Amazon)!,
        value: EdgeInfraType.Amazon,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Azure)!,
        value: EdgeInfraType.Azure,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Gcp)!,
        value: EdgeInfraType.Gcp,
      },
      {
        label: EdgeInfraTypeMap.get(EdgeInfraType.Oci)!,
        value: EdgeInfraType.Oci,
      },
    ];
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <RadioField
        identifier={"infraType"}
        label={"Select Infra Provider"}
        value={target?.["infraType"]}
        path={[...props.path, "infraType"]}
        onChange={onRadioChange}
        defaultSelected={EdgeInfraType.Amazon}
        direction="vertical"
        options={infraTypeOptions()}
      />
      <TextField
        identifier={"name"}
        label={"Select app configuration which will indicate region"}
        value={target?.["name"]}
        path={[...props.path, "name"]}
        onChange={onTextChange}
        options={Object.values(configurations).map(
          (v: AppConfiguration) => ({ label: v.name, value: v.name })
        )}
      />
    </Space>
  );
};
