import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Spin, Table } from "antd";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { DeveloperItem } from "types";
import { FieldProps } from "./common";
import { EditableCell, EditableRow, EditableTableProps } from "./editableTable";
import { EditorContext } from "./editorContext";
import { getLabel } from "./utils";

import styles from "./EntityEditor.module.scss";
import { FieldLabel } from "./fieldLabel";

const { Column } = Table;
const { Panel } = Collapse;
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export interface PrimitiveArrayFieldProps extends FieldProps {
  value: DeveloperItem[];
  loading?: boolean;
  addLabel: string;
  onAdd: (key: string) => Promise<DeveloperItem>;
  onChange: (key: string, items: DeveloperItem[]) => Promise<void>;
  showExpanded?: boolean;
}

/** react function component */
export const PrimitiveArrayField = (
  props: PropsWithChildren<PrimitiveArrayFieldProps>
) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const editorContext = useContext(EditorContext);
  const [dataSource, setDataSource] = useState<DeveloperItem[]>([]);

  useEffect(() => {
    const newItems =
      props.value?.map((x) => ({
        ...x,
        context: { ...x.context, isDelete: true },
      })) || [];
    setDataSource(newItems);
  }, [props.value]);

  const renderDelete = (isDelete: boolean, record: DeveloperItem) => {
    return (
      <Button
        ghost
        shape="circle"
        size="small"
        className={styles.deleteCondButton}
        icon={<DeleteTwoTone className={styles.deleteCondIcon} />}
        onClick={(e: any) => {
          e.stopPropagation();
          const newItems = props.value.filter((item) => {
            return item.id != record.id;
          });
          props.onChange(props.identifier, newItems);
        }}
      />
    );
  };

  const columns = [
    {
      title: "Value",
      dataIndex: ["item", 0],
      editable: editorContext.view ? false : true,
      onCell: (record: DeveloperItem) => ({
        record,
        editable: editorContext.view ? false : true,
        dataIndex: ["item", 0],
        title: "Value",
        handleSave,
      }),
    },
    {
      key: "mutator",
      title: "Action",
      dataIndex: ["context", "isDelete"],
      render: renderDelete,
    },
  ];

  const handleSave = async (row: DeveloperItem) => {
    const newData = [...props.value];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    await props.onChange(props.identifier, newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Spin spinning={props.loading ? true : false}>
      <Collapse
        ghost={true}
        defaultActiveKey={props.showExpanded ? props.identifier : undefined}
      >
        <Panel header={<FieldLabel fp={props} />} key={props.identifier}>
          {dataSource.length > 0 && (
            <Table
              rowKey="id"
              components={components}
              columns={columns}
              dataSource={dataSource}
              pagination={{ position: [] }}
              showHeader={false}
              size={"small"}
            />
          )}
          {!editorContext.view && (
            <div
              style={{
                width: "50%",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                marginTop: "10px",
              }}
            >
              <Button
                type="default"
                onClick={() => {
                  props.onAdd(props.identifier);
                }}
                block
                icon={<PlusOutlined />}
              >
                {props.addLabel}
              </Button>
            </div>
          )}
        </Panel>
      </Collapse>
    </Spin>
  );
};
