import { Navigate, Route, Routes } from "react-router-dom";

import {
  Administration,
  Callback,
  DashBoardWrap,
  Edges,
  HomeMain,
  HyprFlows,
  MobileOtpVerification,
  OAuth2Callback,
  Profiles,
  SamlCallback,
  Search,
  SearchRun,
  SearchRunAction,
  Workflow,
  WorkflowRun,
  WorkflowTemplate,
  Workspace,
  Apps,
  AppDeveloperView,
  DeveloperX,
} from "pages";
import { DeveloperRoute } from "pages/Developer";
import { Relogin } from "pages/Relogin";
import CreateNewPassword from "pages/CreateNewPassword";
import EmailOtpVerification from "../pages/EmailOtpVerification";
import ForgotPassword from "../pages/ForgotPassword";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpSetPassword from "../pages/SignUpSetPassword";
import UsersRoles from "../pages/UsersRoles";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { TokenManager } from "./TokenManager";
import NoSsoUser from "pages/NoSsoUser";
import { ManagedMode } from "pages/ManagedMode";
import CaseMgmt from "pages/CaseMgmt";
export const Router = () => {
  return (
    <TokenManager>
      <Routes>
        {/* apps are the default route for now */}
        <Route index element={<Navigate to="/dashboard" />} />

        {/* public routes */}
        <Route path="/signin" element={<PublicRoutes />}>
          <Route path="/signin" element={<SignIn />} />
        </Route>

        <Route path="/signup" element={<PublicRoutes />}>
          <Route path="/signup" element={<SignUp />} />
        </Route>

        <Route path="/relogin" element={<Relogin />} />

        <Route
          path="/mobile-otp-verification"
          element={<MobileOtpVerification />}
        />
        <Route
          path="/mobile-otp-verification"
          element={<PublicRoutes />}
        ></Route>
        <Route
          path="/email-otp-verification"
          element={<EmailOtpVerification />}
        />
        <Route
          path="/email-otp-verification"
          element={<PublicRoutes />}
        ></Route>
        <Route path="/signup-set-password" element={<PublicRoutes />}>
          <Route path="/signup-set-password" element={<SignUpSetPassword />} />
        </Route>
        <Route path="/forgot-password" element={<PublicRoutes />}>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route path="/create-new-password" element={<PublicRoutes />}>
          <Route path="/create-new-password" element={<CreateNewPassword />} />
        </Route>

        <Route path="callback" element={<Callback />} />
        <Route path="samlcallback" element={<SamlCallback />} />
        <Route path="/oauth2/callback" element={<OAuth2Callback />} />
        {/* privet routes */}
        <Route path="/home" element={<PrivateRoutes />}>
          <Route path="/home" element={<HomeMain />} />
        </Route>
        <Route path="/apps" element={<PrivateRoutes />}>
          <Route path="/apps" element={<Apps />} />
        </Route>
        <Route path="/apps/:appId" element={<PrivateRoutes />}>
          <Route path="/apps/:appId" element={<Apps />} />
        </Route>
        <Route path="/edges" element={<PrivateRoutes />}>
          <Route path="/edges" element={<Edges />} />
        </Route>
        <Route path="/users-and-roles" element={<PrivateRoutes />}>
          <Route path="/users-and-roles" element={<UsersRoles />} />
        </Route>
        <Route path="/search" element={<PrivateRoutes />}>
          <Route path="/search" element={<Search />} />
        </Route>
        <Route path="/search-runs/:id" element={<PrivateRoutes />}>
          <Route path="/search-runs/:id" element={<SearchRun />} />
        </Route>
        <Route path="/search-run-actions/:id" element={<PrivateRoutes />}>
          <Route path="/search-run-actions/:id" element={<SearchRunAction />} />
        </Route>
        <Route path="/profile" element={<PrivateRoutes />}>
          <Route path="/profile" element={<Profiles />} />
        </Route>
        <Route path="/dashboard" element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashBoardWrap />} />
        </Route>
        <Route path="/administration" element={<PrivateRoutes />}>
          <Route path="/administration" element={<Administration />} />
        </Route>
        <Route path="/cases" element={<PrivateRoutes />}>
          <Route path="/cases" element={<CaseMgmt />} />
        </Route>
        <Route path="/cases/:caseId" element={<PrivateRoutes />}>
          <Route path="/cases/:caseId" element={<CaseMgmt />} />
        </Route>
        <Route path="/hyprflows" element={<PrivateRoutes />}>
          <Route path="/hyprflows" element={<HyprFlows />} />
        </Route>
        <Route path="/workflows/:workflowId" element={<PrivateRoutes />}>
          <Route path="/workflows/:workflowId" element={<Workflow />} />
        </Route>
        <Route
          path="/workflow-templates/:workflowId"
          element={<PrivateRoutes />}
        >
          <Route
            path="/workflow-templates/:workflowId"
            element={<WorkflowTemplate />}
          />
        </Route>
        <Route path="/workspace" element={<PrivateRoutes />}>
          <Route path="/workspace" element={<Workspace />} />
        </Route>
        <Route path="/workspace/:active" element={<PrivateRoutes />}>
          <Route path="/workspace/:active" element={<Workspace />} />
        </Route>

        <Route path="/workflow-runs/:workflowRunId" element={<PrivateRoutes />}>
          <Route
            path="/workflow-runs/:workflowRunId"
            element={<WorkflowRun />}
          />
        </Route>

        {/* <Route path="/developer" element={<PrivateRoutes />}>
          <Route path="/developer" element={<Developer />} />
        </Route> */}
        <Route path="/developer" element={<PrivateRoutes />}>
          <Route path="/developer" element={<DeveloperX />} />
        </Route>
        <Route path="/developer/app/:appId" element={<PrivateRoutes />}>
          <Route path="/developer/app/:appId" element={<AppDeveloperView />} />
        </Route>
        <Route path="/nosso" element={<PublicRoutes />}>
          <Route path="/nosso" element={<NoSsoUser />} />
        </Route>
        <Route
          path="/developer/:specResourceType/:specId"
          element={<PrivateRoutes />}
        >
          <Route
            path="/developer/:specResourceType/:specId"
            element={<DeveloperRoute />}
          />
        </Route>

        <Route path="/nosso/:user" element={<PublicRoutes />}>
          <Route path="/nosso/:user" element={<NoSsoUser />} />
        </Route>
        <Route path="/managed-mode" element={<PrivateRoutes />}>
          <Route path="/managed-mode" element={<ManagedMode />} />
        </Route>
      </Routes>
    </TokenManager>
  );
};
