import { Space, Spin } from "antd";

import {
  EditorContext,
  OutputsField,
  ParametersField,
  RadioField,
  rules,
  TextField,
  ViewerType,
  ViewProps,
} from "components/EntityEditor";
import React, { useContext, useEffect } from "react";
import { useDeveloperStore } from "store";
import { ActionAccessType } from "types";
import { ViewSchema } from "./viewSchema";

/** Function component with app view props as argument */
export const ActionView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  
  const onDisplayNameChange = async (identifier: string, value: string) => {
    const name = value.replaceAll(" ", "_").toLowerCase();
    setTarget({ ...target, [identifier]: value, [schema.name.identifier]: name });
  };

  const onFieldChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  /** The key in value in the recordKey , and the indexed value is iodata */
  const onParametersChange = async (key: string,value: { [k: string]: any }) => {
    setTarget({ ...target, [key]: value });
  };

  const onOutputsChange = async (key: string, value: { [k: string]: any }) => {
    const newTarget = { ...target, [key]: value };
    setTarget(newTarget);
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  
  const accessTypeOptions = () => {
    return [
      { label: "Read", value: ActionAccessType.ActionAccessTypeReadOnly },
      { label: "Write", value: ActionAccessType.ActionAccessTypeWriteOnly },
      {
        label: "Read & Write",
        value: ActionAccessType.ActionAccessTypeReadWrite,
      },
    ];
  };

  const schema: { [k: string]: ViewSchema } = {
    name: {
      identifier: "name",
      label: "Name",
      help: "The name of the action",
      validators: [],
    },
    displayName: {
      identifier: "displayName",
      label: "Name",
      help: "The name of the action",
      validators: [rules.required, rules.minLength(1), rules.maxLength(1024)],
    },
    description: {
      identifier: "description",
      label: "Description",
      help: "The description of the action",
      validators: [rules.required, rules.minLength(1), rules.maxLength(2048)],
    },
    accessType: {
      identifier: "accessType",
      label: "Access Type",
      help: "Access type which indicates the intention of the action in the system",
      validators: [rules.required],
    },
    parameters: {
      identifier: "parameters",
      label: "Parameters",
      help: "Parameters which will be passed to this action",
      validators: [],
    },
    outputs: {
      identifier: "outputs",
      label: "Outputs",
      help: "Outputs which this action will produce",
      validators: [],
    },
  };

  return (
    <Spin spinning={loader}>
      <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
        <TextField
          identifier={schema.displayName.identifier}
          label={schema.displayName.label}
          value={target[schema.displayName.identifier]}
          path={[...props.path, schema.displayName.identifier]}
          validators={schema.displayName.validators}
          help={schema.displayName.help}
          onChange={onDisplayNameChange}
        />
        <TextField
          identifier={schema.description.identifier}
          label={schema.description.label}
          value={target[schema.description.identifier]}
          path={[...props.path, schema.description.identifier]}
          validators={schema.description.validators}
          help={schema.description.help}
          onChange={onFieldChange}
        />
        <RadioField
          identifier={schema.accessType.identifier}
          label={schema.accessType.label}
          value={target[schema.accessType.identifier]}
          path={[...props.path, schema.accessType.identifier]}
          onChange={onFieldChange}
          defaultSelected={ActionAccessType.ActionAccessTypeReadOnly}
          help={schema.accessType.help}
          direction="horizontal"
          options={accessTypeOptions()}
        />
        <ParametersField
          identifier={schema.parameters.identifier}
          optional={true}
          label={schema.parameters.label}
          value={target[schema.parameters.identifier] || {}}
          path={[...props.path, schema.parameters.identifier]}
          onChange={onParametersChange}
          viewerType={ViewerType.CardViewer}
          help={schema.parameters.help}
          showExpanded={true}
          showCount={true}
        />
        <OutputsField
          identifier={schema.outputs.identifier}
          optional={true}
          label={schema.outputs.label}
          value={target[schema.outputs.identifier] || {}}
          path={[...props.path, schema.outputs.identifier]}
          onChange={onOutputsChange}
          viewerType={ViewerType.CardViewer}
          enableEditIdentifier={false}
          help={schema.outputs.help}
          onKeyChange={async () => {
            return true;
          }}
          showExpanded={true}
          showCount={true}
        />
      </Space>
    </Spin>
  );
};
