import { ColorPicker, theme, Tooltip, Typography } from "antd";
import type { Color } from "antd/es/color-picker";
import { debounce } from "lodash";
import { useEffect, useMemo } from "react";

import { DeleteFilled, EditFilled } from "@ant-design/icons";
const { Text } = Typography;

export interface TileHeaderProps {
  title: string;
  description: string;
  color?: string;
  showOperations?: boolean;
  onEdit?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onChangeColor?: (color: string) => Promise<void>;
}

export const TileHeader = (props: TileHeaderProps) => {
  const debouncedColorPicker = useMemo(() => {
    return debounce(
      (c: Color | string) =>
        props.onChangeColor?.(typeof c === "string" ? c : c.toHexString()),
      300
    );
  }, []);

  useEffect(() => {
    return () => {
      debouncedColorPicker.cancel();
    };
  });

  const { token } = theme.useToken();

  return (
    <div
      id="kpi-mini-tile-header"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "15px",
        width: "100%",
        alignItems: "center",
        opacity: 0.7,
        marginBottom: token.marginXS,
      }}
    >
      <Tooltip title={props.description}>
        <Text
          strong
          ellipsis
          style={{
            fontSize: token.fontSizeSM,
            marginLeft: token.marginXXS,
            maxWidth: "100px",
          }}
        >
          {props.title}
        </Text>
      </Tooltip>
      {props.showOperations && (
        <div
          id="kpi-mini-tile-header-operations"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "5px",
          }}
        >
          <ColorPicker
            value={props.color || "#ff0000"}
            size="small"
            onChange={debouncedColorPicker}
            disabled={!props.onChangeColor}
            style={{
              border: "none",
              backgroundColor: "transparent",
            }}
          />
          {props.onEdit && (
            <EditFilled
              style={{
                color: token.colorTextBase,
              }}
              onClick={() => props.onEdit?.()}
            />
          )}

          {props.onDelete && (
            <DeleteFilled
              style={{
                color: token.colorTextBase,
              }}
              onClick={() => props.onDelete?.()}
            />
          )}
        </div>
      )}
    </div>
  );
};
