import { Collapse, Typography } from "antd";

import {
  DeveloperItem,
  IOData,
  IODataArray,
  IODataType,
  ResourceType,
} from "types";

import { CollectionField } from "components/EntityEditor";
import { useEffect, useState } from "react";
import { CollectionMutatorPosition, FieldProps, ViewerType } from "./common";

import { typeGuards } from "./typeGuards";

const { Text } = Typography;
const { Panel } = Collapse;

const items = [
  {
    key: ResourceType.IODataOutput,
    label: "Primitive",
  },
  {
    key: ResourceType.IODataMapOutput,
    label: "Collection",
  },
  {
    key: ResourceType.IODataArrayOutput,
    label: "Sequence",
  },
];

export interface OutputsFieldProps extends FieldProps {
  value: { [k: string]: any };
  inherited?: { [k: string]: any };
  viewerType: ViewerType;
  onChange: (key: string, value: { [k: string]: any }) => void;
  onKeyChange: (key: string, value: string) => void;
  enableEditIdentifier: boolean;
  showExpanded?: boolean;
  showCount?: boolean;
}

const getType = (item: unknown) => {
  if (typeGuards.isIOData(item)) {
    return ResourceType.IODataOutput;
  }
  if (typeGuards.isIODataMap(item)) {
    return ResourceType.IODataMapOutput;
  }
  if (typeGuards.isIODataArray(item)) {
    return ResourceType.IODataArrayOutput;
  }
  return ResourceType.Unknown;
};

const getResourceDescription = (type: ResourceType, item: unknown) => {
  switch (type) {
    case ResourceType.IODataOutput:
      return (item as IOData).description;
    case ResourceType.IODataMapOutput:
      return "collection of named outputs";
    case ResourceType.IODataArrayOutput:
      return "sequence of named outputs";
    default:
      return "";
  }
};

const getResourceDetails = (type: ResourceType, item?: unknown) => {
  switch (type) {
    case ResourceType.IODataOutput:
      return {
        labelName: "Output Name",
        cardTitle: "Output",
        displayType: item ? (item as IOData).type : "Primitive",
      };
    case ResourceType.IODataMapOutput:
      return {
        labelName: "Collection Name",
        cardTitle: "Outputs Collection",
        displayType: "Collection",
      };
    case ResourceType.IODataArrayOutput:
      return {
        labelName: "Sequence Name",
        cardTitle: "Outputs Sequence",
        displayType: "Sequence",
      };
    default:
      return { labelName: "", cardTitle: "" };
  }
};

/** react function component */
export const OutputsField = (props: OutputsFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const [target, setTarget] = useState<{ [k: string]: any }>(props.value);

  useEffect(() => {
    setTarget(props.value);
  }, [props.value]);

  const onOutputsItemAdd = async (
    key: string,
    type?: ResourceType
  ): Promise<DeveloperItem> => {
    const count = Object.keys(target).length;
    switch (type) {
      case ResourceType.IODataOutput:
        return {
          id: `output-${count}`,
          item: {
            recordKey: `output_${count}`,
            recordValue: {
              description: "",
              type: IODataType.String,
              required: false,
            },
          },
          type: type,
          context: {
            cardTitle: getResourceDetails(type).cardTitle,
          },
        };
      case ResourceType.IODataMapOutput:
        return {
          id: `collection-${count}`,
          item: {
            recordKey: `collection_${count}`,
            recordValue: {},
          },
          type: type,
          context: {
            cardTitle: getResourceDetails(type).cardTitle,
          },
        };
      case ResourceType.IODataArrayOutput:
        return {
          id: `sequence-${count}`,
          item: {
            recordKey: `sequence_${count}`,
            recordValue: [] as IODataArray,
          },
          type: type,
          context: {
            cardTitle: getResourceDetails(type).cardTitle,
          },
        };
      default:
        return {
          id: `output-${count}`,
          item: {
            recordKey: `output_${count}`,
            recordValue: {
              description: "",
              type: IODataType.String,
              required: false,
            },
          },
          type: ResourceType.IODataOutput,
          context: {
            cardTitle: getResourceDetails(ResourceType.IODataOutput).cardTitle,
          },
        };
    }
  };

  const onOutputsChange = async (key: string, value: DeveloperItem[]) => {
    const newTarget: { [k: string]: any } = value.reduce((acc, cur) => {
      acc[cur.item.recordKey] = cur.item.recordValue;
      return acc;
    }, {} as { [k: string]: any });
    props.onChange(props.identifier, newTarget);
  };

  return (
    <CollectionField
      optional={props.optional}
      value={Object.keys(target).map((key: string) => {
        const type = getType(target[key]);
        return {
          id: `ioData-${key}`,
          item: { recordKey: key, recordValue: target[key] },
          type: type,
          context: {
            cardTitle: getResourceDetails(type).cardTitle,
          },
        };
      })}
      columns={[
        {
          title: "Name",
          dataIndex: ["item", "recordKey"],
        },
        {
          title: "Description",
          render: (_: any, di: DeveloperItem) => {
            return (
              <Text>
                {getResourceDescription(di.type, di.item.recordValue)}
              </Text>
            );
          },
        },
        {
          title: "Type",
          render: (_: any, di: DeveloperItem) => {
            return (
              <Text>
                {getResourceDetails(di.type, di.item.recordValue).displayType}
              </Text>
            );
          },
        },
      ]}
      allowedTypes={[
        ResourceType.IODataOutput,
        ResourceType.IODataMapOutput,
        ResourceType.IODataArrayOutput,
      ]}
      identifier={props.identifier}
      label={props.label}
      help={props.help}
      onChange={onOutputsChange}
      onAdd={onOutputsItemAdd}
      viewerType={ViewerType.CardViewer}
      path={props.path}
      showTableHeader={true}
      showExpanded={props.showExpanded || false}
      mutatorPosition={CollectionMutatorPosition.InBetween}
      loading={false}
      showAdd={true}
      showDelete={true}
      showCount={props.showCount || false}
    />
  );
};
