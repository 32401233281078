import {
  Button,
  Descriptions,
  Divider,
  Dropdown,
  Flex,
  MenuProps,
  Pagination,
  Space,
  Spin,
  Typography,
  theme,
} from "antd";

import { FC, useEffect, useState } from "react";

import EdgeCard from "./edgeCard";
import Modal from "../../../components/Modal";

import { SvgIcon } from "components/SvgIcon";
import { useEdgeStore, useOrganizationStore, useSettingsStore } from "store";
import { getEdgeShortLogo } from "../../../utility/edge";
import { notification } from 'utility/notification';

import { commonIcons } from "assets/icons";
import { Edge, EdgeInfraType, EdgeInfraTypeMap } from "types";
import SearchInput from "components/SearchInput";
import EdgeConfig from "../EdgeConfig";

const { Title, Text } = Typography;

const EdgeCards: FC = () => {
  const { token } = theme.useToken();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [loader, setLoader] = useState(false);
  const [showEdgeConfig, setShowEdgeConfig] = useState<boolean>(false);
  const [deleteEdgeModal, setDeleteEdgeModal] = useState<{enable: boolean; edge?: Edge;}>({ enable: false });
  const [infraType, setInfraType] = useState<EdgeInfraType>();

  const context = useOrganizationStore((state) => state.context);

  const {
    edges,
    totalEdgesCount,
    edgePage,
    setCurrentPage,
    edgeFilter,
    setEdgeFilter,
    getEdges,
    clearEdges,
    deleteEdge,
    enableEdgeSubscription,
    disableEdgeSubscription,
    clearSelectedEdge,
    edgeSearchTextMap,
    setEdgeSearchTextMap,
  } = useEdgeStore((state) => ({
    edges: state.edges,
    totalEdgesCount: state.totalEdgesCount,
    edgePage: state.edgePage,
    setCurrentPage: state.setCurrentPage,
    edgeFilter: state.edgeFilter,
    setEdgeFilter: state.setEdgeFilter,
    getEdges: state.getEdges,
    clearEdges: state.clearEdges,
    deleteEdge: state.deleteEdge,
    enableEdgeSubscription: state.enableEdgeSubscription,
    disableEdgeSubscription: state.disableEdgeSubscription,
    clearSelectedEdge: state.clearSelectedEdge,
    edgeSearchTextMap: state.edgeSearchTextMap,
    setEdgeSearchTextMap: state.setEdgeSearchTextMap,
  }));

  const loadEdges = async () => {
    try {
      setLoader(true);
      clearEdges();
    
      await getEdges(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadEdges();
  }, [infraType, edgeFilter, edgeSearchTextMap, edgePage, context]);

  const enableEdge = async (edgeId?: string) => {
    try {
      setLoader(true);
      await enableEdgeSubscription(String(edgeId));
      notification.success({
        message: "Edge enabled successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error enabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const disableEdge = async (edgeSubscriptionId?: string) => {
    try {
      setLoader(true);
      await disableEdgeSubscription(String(edgeSubscriptionId));
      notification.success({
        message: "Edge disabled successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error disabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const deleteCurrentEdge = async (edgeId?: string) => {
    try {
      setLoader(true);
      setDeleteEdgeModal({ enable: false });
      await deleteEdge(String(edgeId));
      notification.success({
        message: "Edge deleted successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const closeEdgeConfig = async (refreshData?: boolean) => {
    clearSelectedEdge();
    setShowEdgeConfig(false);
    if (refreshData) {
      setLoader(true);
      await loadEdges();
      setLoader(false);
    }
  };

  const getEdgeLabel = (
    infraType?: EdgeInfraType,
    includeDisplayName?: boolean
  ) => {
    return infraType ? (
      <Space>
        <SvgIcon component={getEdgeShortLogo(infraType)} />
        {includeDisplayName && EdgeInfraTypeMap.get(infraType)}
      </Space>
    ) : (
      "All"
    );
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    e.key == "all"
      ? setInfraType(undefined)
      : setInfraType(e.key as EdgeInfraType);
  };

  const getMenuOptions = () => {
    const menuItems: MenuProps["items"] = [
      {
        key: "all",
        label: getEdgeLabel(),
      },
      {
        key: EdgeInfraType.Amazon,
        label: getEdgeLabel(EdgeInfraType.Amazon, true),
      },
      {
        key: EdgeInfraType.Azure,
        label: getEdgeLabel(EdgeInfraType.Azure, true),
      },
      {
        key: EdgeInfraType.Gcp,
        label: getEdgeLabel(EdgeInfraType.Gcp, true),
      },
      {
        key: EdgeInfraType.Oci,
        label: getEdgeLabel(EdgeInfraType.Oci, true),
      },
      {
        key: EdgeInfraType.OnPrem,
        label: getEdgeLabel(EdgeInfraType.OnPrem, true),
      },
    ];

    return {
      items: menuItems,
      selectable: true,
      defaultSelectedKeys: ["all"],
      onClick: handleMenuClick,
    };
  };

  return (
    <>
      <Spin spinning={loader}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: token.fontSizeLG, display:"flex", marginBottom: token.marginLG }}>
            {"Total Edges"} ({totalEdgesCount})
          </Text>
          <Space style={{ marginBottom: token.margin }}>
            <div style={{ width: "300px" }}>
              <SearchInput
                placeholder="search edges by name"
                value={edgeSearchTextMap.get("displayName")}
                onFilter={(e) => {
                  setCurrentPage();
                  edgeSearchTextMap.set("displayName", e.target.value);
                  setEdgeSearchTextMap(edgeSearchTextMap);
                }}
              />
            </div>
            <Button type="primary" onClick={() => setShowEdgeConfig(true)}>
              Create
            </Button>
           {/*  <Dropdown menu={getMenuOptions()} trigger={["click"]}>
              <Button
                size="middle"
                icon={<SvgIcon component={commonIcons.filterIcon} size={15}/>}
              >
                Filters
              </Button>
            </Dropdown> */}
          </Space>
        </div>
        <Flex wrap="wrap" gap={token.sizeSM}>
          {edges.map((edge) => (
            <EdgeCard
              key={edge.id}
              edge={edge}
              deleteEdge={(edgeId) =>
                setDeleteEdgeModal({ enable: true, edge: edge })
              }
              enableEdge={enableEdge}
              disableEdge={disableEdge}
            />
          ))}
        </Flex>
        {edges.length > 0 &&
          <Pagination
            style={{ float: "right", marginTop: "10px" }}
            current={edgePage.number}
            onChange={(pageNumber, pageSize) => setCurrentPage(pageNumber, pageSize)}
            pageSize={edgePage.size}
            total={totalEdgesCount}
            showSizeChanger={false}
            hideOnSinglePage={true}
          />
        }
      </Spin>
      {showEdgeConfig && (
        <EdgeConfig
          open={showEdgeConfig}
          onClose={(refresh) => closeEdgeConfig(refresh)}
        />
      )}
      {deleteEdgeModal?.enable && (
        <Modal
          title="Delete Edge"
          onClose={() => setDeleteEdgeModal({ enable: false })}
          open={deleteEdgeModal.enable}
          onSubmit={() => deleteCurrentEdge(deleteEdgeModal.edge?.id)}
        >
          {deleteEdgeModal.edge?.infraType == EdgeInfraType.OnPrem
            ?
              <Descriptions
                  size="small"
                  layout="vertical"
                  colon={false}
                  column={1}
              >
                <Descriptions.Item label="Stop docker container">
                  <Text copyable>docker stop `container id`</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Remove docker image">
                  <Text copyable>docker rm `container id`</Text>
                </Descriptions.Item>
              </Descriptions>
            :
              "Are you sure you want to delete edge?"
          }
        </Modal>
      )}
    </>
  );
};

export default EdgeCards;
