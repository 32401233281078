import { FC } from "react";

import { ProviderMode } from "./ProviderMode";

export const MspSubscribers: FC = () => {
  return (
    <div>
      <ProviderMode />
    </div>
  );
};
