
export interface UserRoleDto {
  id: string;
  name: string;
  description: string;
}

export interface UserTagDto {
  id: string;
  name: string;
  description: string;
  value: string;
}

export interface UserDto {
  firstName: string;
  lastName: string;
  id: string;
  image?: string;
  title: string;
  email: string;
  interactive: boolean;
  phone : string;
  region: string;
  roles: UserRoleDto[];
  heldTags: UserTagDto[];
}

export interface RoleUserDto {
  id: string;
  displayName: string;
}

export interface RolePermissionSetDto {
  id: string;
  name: string;
  description: string;
}

export interface RoleDto {
  id: string;
  name: string;
  admin: boolean;
  description: string;
  users: RoleUserDto[];
  psets: RolePermissionSetDto[];
}


export interface PermissionSetDto {
  id: string;
  name: string;
  description: string;
  userdefined: boolean;
  constituents: string[];
}


export enum RbacTab {
  Users = "TabUsers",
  Roles = "TabActions",
  PermissionSets = "TabPermissionSets",
  ClientCredentials = "TabClientCredentials",
}

export interface UpdatePermissionSet {
  id: string;
  name?: string;
  description?: string;
  constituents?: string[];
}

export interface CreatePermissionSet { 
  name: string;
  description: string;
  constituents: string[];
}

export interface CreateRole {
  name: string;
  description: string;
  psets: string[];
  users: string[];
}

export interface UpdateRole {
  id: string;
  name?: string;
  description?: string;
  addPsets?: string[];
  removePsets?: string[];
  addUsers?: string[];
  removeUsers?: string[];
}

export interface CreateUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  region: string;
  title: string;
  roles: string[];
  heldTags: string[];
}
  
export interface UpdateUser {
  id: string;
  firstName?: string;
  lastName?: string;
  region?: string;
  title?: string;
  addRoles?: string[];
  removeRoles?: string[];
  addHeldTags?: string[];
  removeHeldTags?: string[];
}

export interface ClientCredentials {
  clientId: string;
  roles: string[];
  tag: string;
  name: string;
  description: string;
}

export type ClientCredentialsCreateRequestDto = Omit<ClientCredentials, "clientId" >;
export type ClientCredentialsCreateResponseDto = ClientCredentials & { clientSecret: string };
export type ClientCredentialsUpdateRequestDto = Partial<Omit<ClientCredentials, "clientId" > >
export type ClientCredentialsUpdateResponseDto = ClientCredentials
export type ClientCredentialsGetResponseDto = ClientCredentials

export interface ClientCredentialsListResponseDto {
  items: ClientCredentialsGetResponseDto[];
}


export interface RbacStore {
  loading: boolean;
  metricsEditable: boolean;
  eligible: boolean;
  activeTab: RbacTab;
  users: { [id: string]: UserDto };
  roles: { [id: string]: RoleDto };
  clientCredentials: { [id: string]: ClientCredentials }; // Client credentials of the currently logged in user
  permissionSets: { [id: string]: PermissionSetDto };

  setLoading: (loading: boolean) => Promise<void>;
  setActiveTab: (tab: RbacTab) => Promise<void>;
  getEligibility: () => Promise<void>;
  listUsers: () => Promise<void>;
  getUser(id: string): Promise<void>;
  createUser: (user: CreateUser) => Promise<void>;
  updateUser: (user: UpdateUser) => Promise<void>;
  deleteUser: (id: string) => Promise<void>;

  listRoles: () => Promise<void>;
  getRole: (id: string) => Promise<void>;
  createRole: (role: CreateRole) => Promise<void>;
  updateRole: (role: UpdateRole) => Promise<void>;
  deleteRole: (id: string) => Promise<void>;

  listPermissionSets: () => Promise<void>;
  getPermissionSet: (id: string) => Promise<void>;
  createPermissionSet: (pset: CreatePermissionSet) => Promise<void>; 
  updatePermissionSet: (pset: UpdatePermissionSet) => Promise<void>;
  deletePermissionSet: (id: string) => Promise<void>;

  listClientCredentials: () => Promise<void>;
  createClientCredentials: (cc: ClientCredentialsCreateRequestDto) => Promise<ClientCredentialsCreateResponseDto>;
  updateClientCredentials: (clientId: string, cc: ClientCredentialsUpdateRequestDto) => Promise<void>;
  deleteClientCredentials: (id: string) => Promise<void>;
}
