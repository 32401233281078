import { getMandatoryFields } from "./assist";
import { getArtifactFields, queryLexer } from "./lexer";
import { compatParserInstance, parserInstance } from "./parser";

import { Query } from "types";

export const lex = (inputText: string) => {
    const lexResult = queryLexer.tokenize(inputText);

    if (lexResult.errors.length > 0) {
      throw Error(
        "lexing error occured:" +
        lexResult.errors[0].message
      );
    }
  
    return lexResult
}; 

export const parse = (inputText: string): Query => {
  const lexResult = lex(inputText);
  parserInstance.input = lexResult.tokens;
  let parseResults = parserInstance.queryStatement()
  if (parserInstance.errors.length > 0) {
    compatParserInstance.input = lexResult.tokens;
    parseResults = compatParserInstance.queryStatement()
    if (compatParserInstance.errors.length > 0) {
      throw Error(
        "parsing error occured:" +
        compatParserInstance.errors[0].message
      );
    }
  }
  const mandatoryFields = getMandatoryFields(lexResult, getArtifactFields(lexResult.tokens[0].image));
  if (Object.entries(mandatoryFields).length != 0) {
    throw Error("mandatory fields are missing");
  }

  return parseResults as Query;
};


export  function validateSearchQuery(inputString?: string){
  let input = inputString
  if (!input) {
    input = ""
  }
  parse(input);
  return true;
}