
import { GlobalToken } from "antd";
import { createUseStyles } from "react-jss";

export const useScrollBarStyles = createUseStyles({
  /* eslint-disable */
  scrollBar: (token: GlobalToken) => ({
    '& *::-webkit-scrollbar': {
      // General scrollbar
      width: token.sizeXS,
      height: token.sizeXS,
    },
    "& *::-webkit-scrollbar-thumb": {
      // Scrollbar slider
      background: token.controlItemBgActive,
      borderRadius: token.borderRadius
    },
    "& *::-webkit-scrollbar-thumb:hover": {
      // Slider hover
      background: token.controlItemBgActiveHover
    },
    "& *::-webkit-scrollbar-thumb:active": {
      // Slider active
      background: token.controlItemBgActiveHover,
    },
    "& *::-webkit-scrollbar-track": {
      // Scrollbar track
      background: token.colorBgLayout,
      borderRadius: token.borderRadius
    },
    "& *::-webkit-scrollbar-track:hover": {
      // Track hover
      background: token.colorBgTextHover
    },
    "& *::-webkit-scrollbar-track:active": {
      // Track active
      background: token.colorBgTextActive
    },
    "& *::-webkit-scrollbar-corner": {
      // Scrollbar corners where scrollbars meet
      background: "transparent"
    }
  })
});