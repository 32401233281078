
export enum ScriptLanguage {
    Python = "python",
}

export const ScriptLanguageMap: Map<ScriptLanguage, string>  =  new Map<ScriptLanguage, string> (
	[
		[ScriptLanguage.Python, "Python"],
	]
)

export interface ScriptRequest {
    language: ScriptLanguage;
    version: string;
    env: string[];
    input: Record<string, any>;
    script: string[];
    dependencies: string[];
    timeout: number;
}