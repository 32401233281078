import { create } from "zustand";

import { getPreferencesApi, getSamlIdpSettingsApi, getSamlSpSettingsApi, getSectionsApi, setPreferencesApi, setSamlIdpSettingsApi, setSectionsApI ,} from "api";
import { SettingsStore } from "types";
import { persist } from "zustand/middleware";

export const useSettingsStore = create<SettingsStore>()(
  persist(
  (set, get) => ({
    sections: [],
    lightModePrimaryColor: "#325BB1",
    darkModePrimaryColor: "#7F7F7F",
    lightMode: true,
    useDividerBelowHeader: true,
    samlIdpSettings: undefined,
    samlSpSettings: undefined,
    
    initMode : async (lightMode: boolean,
      lightModePrimaryColor: string,
      darkModePrimaryColor: string
    ) => {
      set((state) => ({
        ...state,
        lightMode,
        lightModePrimaryColor,
        darkModePrimaryColor,
      }));
    },
    setDarkMode: async (value: boolean) => {
      set((state) => ({
        ...state,
        lightMode: !value,
      }));
      try {
        const userPreferences = {
          lightMode: !value,
          lightModePrimaryColor: get().lightModePrimaryColor,
          darkModePrimaryColor: get().darkModePrimaryColor,
        }
        await setPreferencesApi(userPreferences);
        await get().getUserPreferences();
      } catch (error: any) {
        console.log(`Error setting dark mode: ${error}`)
      }      
    } ,

    setUserPreferences: async (userPreferences) => {
      try{
      await get().setDarkMode(!userPreferences.lightMode);
      await setPreferencesApi(userPreferences);
      await get().getUserPreferences();
      } catch (error: any) {
        console.log(`Error setting user preferences: ${error}`)
      }
    },

    setPrimaryColor: async (color: string) => {
      get().lightMode ? set((state) => ({ ...state , lightModePrimaryColor: color })) : set((state) => ({ ...state , darkModePrimaryColor: color }));
      try{
        const userPreferences = {
          lightMode: get().lightMode,
          lightModePrimaryColor: get().lightModePrimaryColor,
          darkModePrimaryColor: get().darkModePrimaryColor,
        }
        if (get().lightMode) {
          userPreferences.lightModePrimaryColor = color;
        } else {
          userPreferences.darkModePrimaryColor = color;
        }
        get().setUserPreferences(userPreferences);
        await setPreferencesApi(userPreferences);
        await get().getUserPreferences();
        
      } catch (error: any) {
        console.log(`Error setting primary color: ${error}`)
      }      
    },

    getUserPreferences: async () => {
      try{
        const response = await getPreferencesApi();
        const userPreferences = response.data;
        set((state) => ({ 
          ...state,
          lightMode: userPreferences.lightMode,
          lightModePrimaryColor: userPreferences.lightModePrimaryColor,
          darkModePrimaryColor: userPreferences.darkModePrimaryColor,
        }));
      } catch (error: any) {
        console.log(`Error getting user preferences: ${error}`)
      }
    },

    setUseDividerBelowHeader: (value: boolean) => {
      set((state) => ({
        ...state,
        useDividerBelowHeader: value
      }));
    },

    setSections: async (sections) => { 
      try {
        const response = await setSectionsApI(sections);
        set((state) => ({ 
          ...state,
          sections: response.data
        }));
      } catch (error: any) {
        console.log(`Error setting sections: ${error}`)
      }
    },

    getSections: async () => {
      try {
        const response = await getSectionsApi();
        set((state) => ({
          ...state,
          sections: response.data
        }));
      } catch (error: any) {
        console.log(`Error getting sections: ${error}`);
      }      
    },
    
    setSamlIdpSettings: async (samlIdpSettings) => { 
      try {
        const response = await setSamlIdpSettingsApi(samlIdpSettings);
        set((state) => ({ 
          ...state,
          samlIdpSettings: response.data
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSamlIdpSettings: async () => {
      try {
        const response = await getSamlIdpSettingsApi();
        set((state) => ({
          ...state,
          samlIdpSettings: response.data
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }      
    },

    getSamlSpSettings: async () => {
      try {
        const response = await getSamlSpSettingsApi();
        set((state) => ({
          ...state,
          samlSpSettings: response.data
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    }
  }),
  { name: "SettingsStore"})
);
