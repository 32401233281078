import { FC } from "react";

import PageWrapper from "../../components/PageWrapper";
import { Home } from "pages/Home";
// import HomeWrap from "./Home";

const HomeMain: FC = () => {
  return <PageWrapper content={<Home />}></PageWrapper>;
};

export default HomeMain;
