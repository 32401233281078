import React from "react";
import { Button, Divider, List, Tag, Typography, Tooltip, theme } from "antd";
import { DeleteFilled, PushpinFilled } from "@ant-design/icons";
import { Contact, ContactType } from "./types";
const { Text } = Typography;
export interface DisplayContactsProps {
  type: ContactType;
  contacts: Contact[];
  onAdd: () => Promise<void>;
  onRemove: (contact: Contact) => Promise<void>;
  onMakePrimary: (contact: Contact) => Promise<void>;
}

export const DisplayContacts = (props: DisplayContactsProps) => {
  const { token } = theme.useToken();
  const getLabel = (type: ContactType) => {
    switch (type) {
      case ContactType.Email:
        return "Emails";
      case ContactType.Phone:
        return "Phone Numbers";
      default:
        return "";
    }
  };
  return (
    <div style={{ margin: token.margin }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text strong>{getLabel(props.type)}</Text>
        <Button type="primary" onClick={() => props.onAdd()}>
          Add
        </Button>
      </div>
      <Divider></Divider>
      <List
        itemLayout="horizontal"
        dataSource={props.contacts.sort((a, b) => {
          return a.primary ? -1 : 1;
        })}
        renderItem={(item: Contact) => (
          <List.Item
            actions={
              !item.primary
                ? [
                    <Tooltip
                      key={`make-primary-${item.value}`}
                      title={"Make primary"}
                    >
                      <PushpinFilled
                        onClick={() => props.onMakePrimary(item)}
                      />
                    </Tooltip>,
                    <Tooltip title={"Remove"} key={`remove-${item.value}`}>
                      <DeleteFilled
                        style={{ color: token.colorError }}
                        key={`remove-${item.value}`}
                        onClick={() => props.onRemove(item)}
                      />
                    </Tooltip>,
                  ]
                : undefined
            }
            extra={item.primary ? <Tag color="green">Primary</Tag> : undefined}
          >
            {item.value}
          </List.Item>
        )}
      />
    </div>
  );
};
