import { Table } from "antd";

import { TableRowSelection } from "antd/es/table/interface";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useOrganizationStore, useTokenStore } from "store";
import { Provider } from "types";
import { getUserDetailsFromJWT } from "utility";

export interface MspSelectorProps {
  providers: { [key: string]: Provider };
  onClose: () => Promise<void>;
}

export const MspSelector = (props: MspSelectorProps) => {
  const token = useTokenStore((state) => state.token);
  const [tenantId, setTenantId] = useState<string>("");
  const attachToProvider = useOrganizationStore(
    (state) => state.attachToProvider
  );

  useEffect(() => {
    const details = getUserDetailsFromJWT();
    setTenantId(details.tenantId);
  }, [token]);

  const handleSubmit = async () => {
    try {
      await attachToProvider(selectedRowKeys[0]);
      await props.onClose();
    } catch (error) {
      console.log("Error attaching to provider", error);
    }
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection: TableRowSelection<Provider> = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
    type: "radio",
  };

  return (
    <Drawer
      title="Providers"
      onClose={props.onClose}
      open={true}
      subtitle="Select a provider to continue"
      onSubmit={handleSubmit}
    >
      <Table
        rowKey={"id"}
        key={`organization-table`}
        rowSelection={rowSelection}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
          },
        ]}
        dataSource={Object.values(props.providers).filter(
          (x) => x.id != tenantId
        )}
        bordered={true}
        pagination={
          Object.keys(props.providers).length <= 10 ? false : { pageSize: 10 }
        }
      />
    </Drawer>
  );
};
