import { FC, useState } from "react";
import {
  Space,
  Card,
  theme,
  Typography,
  Flex,
  Tooltip
} from "antd";

import { SvgIcon } from "components/SvgIcon";

import {
  EdgeInfraType,
  EdgeManageType,
  Edge,
  EdgeStatus,
} from "types";

import { getEdgeShortLogo } from "utility/edge";

const { Text, Title } = Typography;

export interface EdgeInfoProps {
  edge: Edge;
  editMode?: boolean;
  isSelectedEdge?: boolean;
  onClick?: () => void;
}

const EdgeInfo: FC<EdgeInfoProps> = ({
  edge,
  editMode,
  isSelectedEdge,
  onClick,
}) => {
  const { token } = theme.useToken();
 
  return (
    <Card
      hoverable={editMode && (edge.status == EdgeStatus.Online)}
      style={{ 
        height: "60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderRadius: token.borderRadius, 
        borderColor: editMode && edge.status == EdgeStatus.Online ? token.colorSuccessBorderHover : token.colorBorder,
        background: isSelectedEdge ? token.colorTextTertiary : (edge.status == EdgeStatus.Online ? token.colorBgBase : token.colorBgContainerDisabled),
      }}
      onClick={editMode ? onClick : undefined}
    >
      <Flex gap={"middle"} >
        <SvgIcon size={25} component={getEdgeShortLogo(edge.infraType)} />
        <div          
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            marginBottom: token.marginXS,
          }}
        >
          <Space direction="vertical" size={0}>
            <Tooltip
              color={token.colorPrimaryHover}
              title={edge.description}
              placement={"top"}
            >
              <Text ellipsis style={{
                maxWidth: '200px', 
                overflow:'hidden'
                }}
              >
                {edge.displayName}
              </Text>
            </Tooltip>
            <Space>
              <Text type="secondary">{edge.manageType == EdgeManageType.HyprEdge? "Managed" : "Unmanaged"}</Text>
              {edge.region && <Text type="secondary" keyboard>{edge.region}</Text>}
            </Space>
          </Space>
        </div>
      </Flex>
    </Card>
  );
};

export default EdgeInfo;
