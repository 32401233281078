import { Divider, Form, Input, Spin, Tabs } from "antd";
import { Assign, HasIdentity } from "components/Assign";
import { Drawer } from "components/Drawer";
import { useEffect, useState } from "react";
import { useOrganizationStore, useRbacStore } from "store";
import {
  ClientCredentials,
  ClientCredentialsCreateResponseDto,
  ClientCredentialsUpdateRequestDto,
} from "types";
import { notification } from "utility/notification";
const { TabPane } = Tabs;
export interface CredentialValues {
  clientId: string;
  clientSecret: string;
}

export interface ClientCredentialsEditProps {
  readOnly: boolean;
  clientCredentials?: ClientCredentials;
  isAdd: boolean;
  onClose: () => Promise<void>;
  onRoleAdd: () => Promise<void>;
  onClientCredentialsAdd: (creds: CredentialValues) => Promise<void>;
}

export const ClientCredentialsEdit = (props: ClientCredentialsEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const [drawerState, setDrawerState] = useState(false);
  const updateClientCredentials = useRbacStore(
    (state) => state.updateClientCredentials
  );
  const roles = useRbacStore((state) => state.roles);
  const tags = useOrganizationStore((state) => state.tags);
  const createClientCredentials = useRbacStore(
    (state) => state.createClientCredentials
  );

  const [assignmentDataSource, setAssignmentDataSource] = useState<
    HasIdentity[]
  >([]);

  const [tagAssignmentDataSource, setTagAssignmentDataSource] = useState<
    HasIdentity[]
  >([]);

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }

      if (props.isAdd) {
        const { name, description } = values;
        if (selectedRows.length === 0) {
          hasValidationError = true;
          notification.error({
            message: "Please select at least one role",
            duration: 6,
          });
          return;
        }
        if (!selectedTag) {
          hasValidationError = true;
          notification.error({
            message: "Please select a tag",
            duration: 6,
          });
          return;
        }
        const response: ClientCredentialsCreateResponseDto =
          await createClientCredentials({
            name,
            description,
            roles: selectedRows,
            tag: selectedTag,
          });
        props.onClientCredentialsAdd({
          clientId: response.clientId,
          clientSecret: response.clientSecret,
        });
      } else {
        if (props.clientCredentials) {
          const { name, description } = values;
          const updateData: ClientCredentialsUpdateRequestDto = {
            name,
            description,
            tag: selectedTag,
            roles: selectedRows,
          };
          await updateClientCredentials(
            props.clientCredentials.clientId,
            updateData
          );
        }
      }
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } credentials, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const formFields = [
    {
      name: "name",
      label: "Name",
      rules: [{ required: true, message: "Please input name!" }],
    },
    {
      name: "description",
      label: "Description",
      rules: [{ required: true, message: "Please input description !" }],
    },
  ];

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setDrawerState(true);
      try {
        setLoading(true);
        if (props.clientCredentials) {
          const fields = {
            name: props.clientCredentials.name,
            description: props.clientCredentials.description,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
        const r = props.clientCredentials?.roles || [];
        setSelectedRows(r);

        const assignableRoles = Object.values(roles).map((x) => ({
          id: x.id,
          displayName: x.name,
          description: x.description,
          selected: r.includes(x.id),
          assignable: true,
        }));
        setAssignmentDataSource(assignableRoles);

        setSelectedTag(props.clientCredentials?.tag);
        const assignableTags = Object.values(tags).map((x) => ({
          id: x.id,
          displayName: x.value,
          description: x.value,
          selected: props.clientCredentials?.tag == x.id,
          assignable: true,
        }));
        setTagAssignmentDataSource(assignableTags);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [props.clientCredentials, roles, tags]);

  const onRoleSelectionChange = async (
    selectedRowKeys: any[]
    // selectionRows: any[]
  ) => {
    console.log("selectedRowKeys roles", selectedRowKeys);
    setSelectedRows(selectedRowKeys);
  };

  const onTagSelectionChange = async (selectedRowKeys: any[]) => {
    console.log("selectedRowKeys tag", selectedRowKeys);
    setSelectedTag(selectedRowKeys[0]);
  };

  const onClose = async () => {
    setSelectedRows([]);
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="Api Credentials"
      onClose={onClose}
      open={drawerState}
      subtitle="OAuth2 client credentials"
      onSubmit={props.readOnly ? undefined : handleSubmit}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          disabled={props.readOnly}
          labelCol={{ span: 6, offset: 0 }}
          wrapperCol={{ span: 18, offset: 0 }}
        >
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              <Input disabled={!props.isAdd || props.readOnly} />
            </Form.Item>
          ))}
        </Form>
        <Divider />
        <Tabs defaultActiveKey="roles-tab">
          <TabPane tab="Roles" key="roles-tab">
            <Assign
              dataSource={assignmentDataSource}
              name="Roles"
              onChange={onRoleSelectionChange}
              readonly={props.readOnly}
              showAdd={!props.readOnly}
              onAdd={props.onRoleAdd}
              multiple={true}
              type="roles"
            />
          </TabPane>
          <TabPane tab="Tags" key="tags-tab">
            <Assign
              dataSource={tagAssignmentDataSource}
              name="Tags"
              showAdd={!props.readOnly}
              onChange={onTagSelectionChange}
              readonly={props.readOnly}
              multiple={false}
              type="tags"
            />
          </TabPane>
        </Tabs>
      </Spin>
    </Drawer>
  );
};
