import { memo } from "react";
import { Handle, Position } from "reactflow";
import { workflowIcons } from "assets/icons";
import { useNodeStyles } from "./styles";
import { SvgIcon } from "components/SvgIcon";

const EndNode = () => {
  const commonStyles = useNodeStyles();
  return (
    <div style={{ cursor: "none"}}>
      <Handle
        className={commonStyles.nodeTopHandle}
        type="target"
        id="b"
        position={Position.Top}
      />
      <SvgIcon size={40} component={workflowIcons.endOperator} />
    </div>
  );
};

export default memo(EndNode);
