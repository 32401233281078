import { Space, FormInstance } from "antd";
import ConditionsForm from "components/ConditionsForm";
import {
  EditorContext,
  ViewProps,
} from "components/EntityEditor";
import React, { useContext, useEffect } from "react";
import {
  Condition,
  Conditions,
} from "types";

export const ConditionsView = (props: ViewProps) => {
  /** target is  { recorKey: key, recordValue : Conditions object} */
  const mounted = React.useRef(false);
  const editorCtx = useContext(EditorContext);
  const [conditionsForm, setConditionsForm] = React.useState<undefined|FormInstance>(undefined);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const ctx = useContext(EditorContext);
  
  const onSuccessConditionsChange = (value: Array<Condition | Conditions>) => {
    setTimeout(() => {
      conditionsForm?.validateFields()
        .then(() => {
          setTarget({
            recordKey: target.recordKey,
            recordValue:  value ,
          });
        }).catch((err) => {
          console.log("error while saving conditions", err);
          //TODO - deal with SAVE button here
        });
    });
  }

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <ConditionsForm 
        editMode={!ctx.view}
        required={true}
        conditions={target["recordValue"]}
        //factSuggestions={}
        onChange={(value) => value && onSuccessConditionsChange(value)}
        onRender={form => setConditionsForm(form)}
      />
    </Space>
  );
};
