import { FC } from "react";
import { Space, Badge } from "antd";
import { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { SpecStateType, SpecStateMap, ResourceType, SpecActionType, SpecType, DeveloperTabType, DeveloperTab, DeveloperDataType, PageInfo, SpecNameValidationState } from "types"

import { useDeveloperStore } from "store";

export const getDeveloperTabByType = (tabType: DeveloperTabType) => {
  switch(tabType){
    case DeveloperTabType.Apps:
      return {tabType: tabType, specType: SpecType.App} as DeveloperTab;
    case DeveloperTabType.Actions:
      return {tabType: tabType, specType: SpecType.Action} as DeveloperTab;
    case DeveloperTabType.Triggers:
      return {tabType: tabType, specType: SpecType.Trigger} as DeveloperTab;
    case DeveloperTabType.Artifacts:
      return {tabType: tabType, specType: SpecType.Artifact} as DeveloperTab;
    case DeveloperTabType.Providers:
      return {tabType: tabType, specType: SpecType.ActionProvider} as DeveloperTab;
    default: 
      return {tabType: tabType, specType: SpecType.Action} as DeveloperTab;
  }
}
export const getSpecStatusReactNode = (state: SpecStateType, shared: boolean): React.ReactNode => {
  return (
    <Space>
      {/* {SpecStateMap.get(state)} */}
      {state == SpecStateType.StatePublishedDraft && <Badge count="draft" color="orange"/>}
      {state == SpecStateType.StatePublished && <Badge count="published" color="green"/>}
      {state == SpecStateType.StateShared && <Badge count="published" color="green"/>}
      {state == SpecStateType.StateShared && <Badge count="shared" color="blue"/>}
    </Space>
  )  
}
export const getSpecOwnerName = (firstName: string, lastName: string) => {
  if(firstName == "svc-hypredge") return "HyprEdge"
  return `${firstName} ${lastName}`
}

export const getResourceTypeFromSpecType = (specType: SpecType) => {
  switch(specType){
    case SpecType.Action:
      return ResourceType.Action;
    case SpecType.Trigger:
      return ResourceType.Trigger;
    case SpecType.Artifact:
      return ResourceType.Artifact;
    case SpecType.App:
      return ResourceType.App;
    default:
      return ResourceType.Unknown;
  }
}

export const getSpecTypeFromResourceType = (resourceType: ResourceType) => {
  switch(resourceType) {
    case ResourceType.Action:
      return SpecType.Action;
    case ResourceType.Trigger:
      return SpecType.Trigger;
    case ResourceType.Artifact:
      return SpecType.Artifact;
    case ResourceType.ActionProvider:
      return SpecType.ActionProvider;
    case ResourceType.TriggerProvider:
      return SpecType.TriggerProvider;
    case ResourceType.ArtifactProvider:
      return SpecType.ArtifactProvider;
    case ResourceType.App:
      return SpecType.App;
    default:
      return SpecType.Action;
  }
}

export const saveSpec = () => {
  return 
}


export const getDeveloperTabResources = (specType: SpecType) => {
  switch(specType){
    case SpecType.App:
      return useDeveloperStore.getState().apps;
    case SpecType.Action:
      return useDeveloperStore.getState().actions;
    case SpecType.Trigger:
      return useDeveloperStore.getState().triggers;
    case SpecType.Artifact:
      return useDeveloperStore.getState().artifacts;
    default:
      return [];
  }
}

export const getDeveloperPageInfo = (specType: SpecType) => {
  switch(specType){
    case SpecType.App:
      return useDeveloperStore.getState().appPage;
    case SpecType.Action:
      return useDeveloperStore.getState().appPage;
    case SpecType.Trigger:
      return useDeveloperStore.getState().appPage;
    case SpecType.Artifact:
      return useDeveloperStore.getState().appPage;
    default:
      return {number: 1,size: 12,total: 0,} as PageInfo
  }
}


export const getNameIconByState = (state: SpecNameValidationState) => {
  switch (state) {
    case SpecNameValidationState.Unknown:
      return <></>;
    case SpecNameValidationState.InProgress:
      return <SyncOutlined spin style={{color: "orange"}}/>;
    case SpecNameValidationState.Valid:
      return <CheckCircleOutlined style={{color: "green"}}/>;
    case SpecNameValidationState.InValid:
      return <CloseCircleOutlined style={{color: "red"}}/>;
    default:
      return <></>;
  }
};