/** Log out when the user is idle for a given period */
import React, { useState, useEffect } from "react";
import { Button, Typography, Modal } from "antd";
import { useIdleTimer } from "react-idle-timer";
const timeout = 1200_000;
const promptBeforeIdle = 60_000;

const { Title, Paragraph } = Typography;

export interface IdleManagerProps {
  onIdle: () => Promise<void>;
}

export const IdleManager = (props: IdleManagerProps) => {
  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
    props.onIdle();
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  return (
    <Modal
      open={open}
      onOk={handleStillHere}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
      footer={[
        <div
          key="ok"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button type="primary" onClick={handleStillHere}>
            I am still here
          </Button>
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={4}>Are you still here?</Title>
        <Paragraph>Logging out in {remaining} seconds</Paragraph>
      </div>
    </Modal>
  );
};
