import { administrationIcons, workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { TextWithIcon } from "components/TextWithIcon";

const IconSize = 15;

export const getSuggestionLabelWithIcon = (label: any) => {
  switch (label) {
    case "parameter store":
      return <TextWithIcon 
        icon={administrationIcons.adminParamterStoreIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "workflow":
      return <TextWithIcon 
        icon={workflowIcons.workflowShortIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "trigger":
      return <TextWithIcon 
        icon={workflowIcons.triggerShortDarkIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "parameters":
      return <TextWithIcon 
        icon={workflowIcons.parametersShortIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "outputs":
      return <TextWithIcon 
        icon={workflowIcons.outputsShortIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "steps":
      return <TextWithIcon 
        icon={workflowIcons.stepsShortIcon}
        iconSize={IconSize}
        text={label}
      />;
    case "apps":
      return <TextWithIcon 
        icon={workflowIcons.appsShortIcon}
        iconSize={IconSize}
        text={label}
      />;
    default:
      return label;
  }
};

export const getTreeTitleIcon = (label: any) => {
  switch (label) {
    case "paramstore":
      return <SvgIcon size={IconSize} component={administrationIcons.adminParamterStoreIcon} />;
    case "workflow":
      return <SvgIcon size={IconSize} component={workflowIcons.workflowShortIcon} />;
    case "trigger":
      return <SvgIcon size={IconSize} component={workflowIcons.triggerShortDarkIcon} />;
    case "parameters":
      return <SvgIcon size={IconSize} component={workflowIcons.parametersShortIcon} />;
    case "outputs":
      return <SvgIcon size={IconSize} component={workflowIcons.outputsShortIcon} />;
    case "steps":
      return <SvgIcon size={IconSize} component={workflowIcons.stepsShortIcon} />;
    case "apps":
      return <SvgIcon size={IconSize} component={workflowIcons.appsShortIcon} />;
    default:
      return <>{label}</>;
  }
};