import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { Goal, Metric, GoalCreateRequest, GoalList, GoalUpdateRequest } from "types";
import { goalsApiPath, metricsApiPath, jsonApiDeserializer } from "./constant";

export const listGoalsApi = async (): Promise<AxiosResponse<GoalList>> => axiosInterceptor("get", goalsApiPath);
export const getGoalApi = async (id: string): Promise<AxiosResponse<Goal>> => axiosInterceptor("get", `${goalsApiPath}/${id}`);
export const createGoalApi = async (goal: GoalCreateRequest): Promise<AxiosResponse<Goal>> => axiosInterceptor("post", goalsApiPath, { ...goal });
export const updateGoalApi = async (id: string, goal: GoalUpdateRequest): Promise<AxiosResponse<Goal>> => axiosInterceptor("patch", `${goalsApiPath}/${id}`, { ...goal });
export const deleteGoalApi = async (id: string): Promise<AxiosResponse<void>> => axiosInterceptor("delete", `${goalsApiPath}/${id}`);

export const getGoalMetricsApi = async () : Promise<Metric> => {   
  const url = `${metricsApiPath}/goal-values-total`
  const response = await axiosInterceptor("get", url);
  const metric = jsonApiDeserializer.deserialize(response.data) as Metric;
  return metric;
}



