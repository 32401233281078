import { FC, useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Space, Typography } from "antd";

import { ArrayObject, ArrayObjectDataType, ArrayObjectFormProps } from "types";

import { FieldLabel } from "components/FieldLabel";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import { FormInstance } from "antd/lib";

const { Option } = Select;

const ArrayObjectForm: FC<ArrayObjectFormProps> = (props) => {
  const [form] = Form.useForm();
  const [stringVal, setStringVal] = useState<string>("");
  const [numericVal, setNumericVal] = useState<number>(0);
  const [booleanVal, setBooleanVal] = useState<boolean>(true);
  const [listVal, setListVal] = useState<string>("");
  const [currentObject, setCurrentObject] = useState<ArrayObject>({} as ArrayObject);

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  useEffect(() => {
    const object = {
      name: props.object.name,
      type: props.object.type
    } as ArrayObject;
    if (!props.hideValue) {
      if (props.object.type == ArrayObjectDataType.Array) {
        object.value = props.object.value.join(" ");
      } else {
        object.value = props.object.value
      }
    }
    form.setFieldsValue(object);
    setCurrentObject(object);
  }, [props.object]);

  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    if (!props.hideValue) {
      if (form.getFieldValue("type") == ArrayObjectDataType.Array) {
        values.value = values.value.split(" ")
      }
    }
    props.onChange?.(values);
  };

  return (
    <Form 
      form={form} 
      name="outputForm" 
      layout="vertical" 
      autoComplete="off"
      initialValues={currentObject}
      onValuesChange={onValuesChange}
    >
      <Form.Item 
        name="name" 
        validateTrigger="onSubmit"
        label={<FieldLabel label={"Name"} help={"Name of the "+ props.name} />}
        rules={[
          { required: true, message: 'Name required!'}
        ]}
      >
        <Input disabled={!props.editMode}/>
      </Form.Item>
      <Form.Item 
        name="type" 
        validateTrigger="onSubmit"
        label={<FieldLabel label={"Type"} help={"Type of the "+ props.name} />}
        rules={[
          { required: true, message: 'Type required!'},
        ]}>
          <Select
            disabled={!props.editMode}
            showAction={["focus", "click"]}
            defaultValue={ArrayObjectDataType.String}
            onChange={(value) => 
              form.setFieldValue("value", (value == ArrayObjectDataType.String) ? stringVal : (value == ArrayObjectDataType.Numeric) ? numericVal : (value == ArrayObjectDataType.Boolean) ? booleanVal : listVal)}
          >
            <Option key={ArrayObjectDataType.String} value={ArrayObjectDataType.String}>String</Option>
            <Option key={ArrayObjectDataType.Numeric} value={ArrayObjectDataType.Numeric}>Number</Option>
            <Option key={ArrayObjectDataType.Boolean} value={ArrayObjectDataType.Boolean}>Boolean</Option>
            <Option key={ArrayObjectDataType.Array} value={ArrayObjectDataType.Array}>List</Option>
          </Select>
      </Form.Item>
      {!props.hideValue && 
        <Form.Item 
          name="value"
          validateTrigger="onSubmit" 
          label={<FieldLabel label={"Value"} help={"Value of the "+ props.name} />}
          rules={[
            { required: true, message: 'Value required!'},
            {
              validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
            }
          ]}>
            {
              (form.getFieldValue("type") == ArrayObjectDataType.Numeric) && 
                <InputNumber
                  disabled={!props.editMode}
                  onChange={(value) => setNumericVal(value as number)}
                /> ||
                (form.getFieldValue("type") == ArrayObjectDataType.String) && 
                  <SuggestionsInput
                    editMode={props.editMode}
                    name="value"
                    suggestionsTree={props.suggestionsTree}
                    setValue={setStringVal}
                  />
                ||
                (form.getFieldValue("type") == ArrayObjectDataType.Boolean) && 
                <Select
                    disabled={!props.editMode}
                    showAction={['focus', 'click']}
                    onChange={setBooleanVal}
                >
                  <Option key={1} value={true}>True</Option>
                  <Option key={0} value={false}>False</Option>
                </Select>
                || (form.getFieldValue("type") == ArrayObjectDataType.Array) && 
                <Input
                  disabled={!props.editMode}
                  onChange={(e) => setListVal(e.target.value)}
                />
            }
        </Form.Item>
      }
    </Form>
  );
};
  
export default ArrayObjectForm;