import { CSSProperties } from "react";

export const getContainerStyle = (overrides: CSSProperties): CSSProperties => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0 5px 0px 5px",
    ...overrides,
  };
};

export const getHeadingContainerStyle = (
  overrides?: CSSProperties
): CSSProperties => {
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    ...overrides,
  };
};

export const getHeadingTopLineStyle = (
  overrides?: CSSProperties
): CSSProperties => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    ...overrides,
  };
};
