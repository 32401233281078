import { Collapse, Space, Typography } from "antd";
import {
  EditorContext,
  ObjectBackedTextField,
  ParametersField,
  rules,
  TextField,
  ViewerType,
  ViewProps,
  PanelCollectionField,
  OutputsTransformField,
  SuggestionContext,
} from "components/EntityEditor";
import React, { useContext, useEffect, useMemo } from "react";
import { useDeveloperStore } from "store";
import {
  DeveloperItem,
  INVALID_DEVELOPER_ITEM,
  ResourceType,
  SpecType,
  App,
  HttpAdapterSpec,
  AdapterType,
} from "types";
import { resourceFactory } from "utility/resource";
import { ViewSchema } from "./viewSchema";

const { Text } = Typography;
const { Panel } = Collapse;

/** Function component with app view props as argument */
export const ActionProviderView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  
  const [loader, setLoader] = React.useState<boolean>(false);
  
  const actions = useDeveloperStore((state) => state.actions);
  const apps = useDeveloperStore((state) => state.apps);

  const doSpecsFetch = useDeveloperStore((state) => state.doSpecsFetch);

  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const [inheritedParameters, setInheritedParameters] = React.useState<{
    [k: string]: any;
  }>([]);

  const [inheritedOutputs, setInheritedOutputs] = React.useState<{
    [k: string]: any;
  }>([]);

  const [selectedApp, setSelectedApp] = React.useState<DeveloperItem>(INVALID_DEVELOPER_ITEM);
  const [selectedAction, setSelectedAction] = React.useState<DeveloperItem>(INVALID_DEVELOPER_ITEM);


  const onTextChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };
  
  const onDisplayNameChange = async (identifier: string, value: string) => {
    const name = value.replaceAll(" ", "_").toLowerCase();
    setTarget({ ...target, [identifier]: value, [schema.name.identifier]: name });
  };


  /** The key in value in the recordKey , and the indexed value is iodata */
  const onParametersChange = async (
    key: string,
    value: { [k: string]: any }
  ) => {
    setTarget({ ...target, [key]: value });
  };

  const onOutputsChange = async (key: string, value: { [k: string]: any }) => {
    setTarget({ ...target, [key]: value });
  };

  const onHttpAdapterChange = async (key: string, value: DeveloperItem[]) => {
    const saveItem = value.reduce((acc, prev) => {
      acc[prev.item?.recordKey] = prev.item?.recordValue;
      return acc;
    }, {} as { [k: string]: any });
    const newTarget = { ...target, [key]: saveItem };
    setTarget(newTarget);
  };


  /** Notify upstream whenever there is a change in value */
  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  const findAndSetAction = (actionId: string) => {
    const action = actions.find((action) => action.id == actionId) 
    if(action) {
      setSelectedAction({
        id: action.id,
        item: action,
        type: ResourceType.Action,
        context: {
          displayName: action.displayName,
          description: action.description,
        },
      });
      setInheritedParameters(action.parameters);
      setInheritedOutputs(action.outputs);
      return true;
    }
    return false;
  }

  
  const refreshActions = async () => {
    try {
      setLoader(true);
      if (actions.length == 0) {
        await doSpecsFetch(SpecType.Action);
      }
    } catch (e) {
      console.log(`Failed to load actions`, e);
    } finally {
      setLoader(false);
    }
  };

  const findAndSetApp = (appId: string) => {
    const app = apps.find((app) => app.id == appId) 
    if(app) {
      setSelectedApp({
        id: app.id,
        item: app,
        type: ResourceType.App,
        context: {
          displayName: app.displayName,
          description: app.description,
        },
      });
      return true;
    }
    return false;
  }
  
  const refreshApps = async () => {
    try {
      setLoader(true);
      if (apps.length == 0) {
        await doSpecsFetch(SpecType.App);
      }
    } catch (e) {
      console.log(`Failed to load apps`, e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
   refreshActions();
   refreshApps();
  }, []);

  useEffect(() => {
    if(target?.[schema.appID.identifier]){
      findAndSetApp(target[schema.appID.identifier])
    }
    if(target?.[schema.actionID.identifier]){
      findAndSetAction(target[schema.actionID.identifier])
    }
   }, [actions, apps]);

  const onAppChange = async (key: string, value: string) => {
    if(findAndSetApp(value)){
      setTarget({ ...target, [key]: value});
    }
  };

  const onActionChange = async (key: string, value: string) => {
    if(findAndSetAction(value)){
      setTarget({ ...target, [key]: value});
    }
  };
  
  const onHttpAdapterAddItem = async (): Promise<DeveloperItem> => {
    const index = Object.keys(target["http"] || {}).length;
    const key = `httpRequest_${index}`;
    const item = resourceFactory.createHttpRequest();

    return {
      id: `http-request-${index}`,
      item: { recordKey: key, recordValue: item },
      type: ResourceType.HttpRequest,
      context: { displayName: key, description: "" },
    };
  };

  
  const schema: { [k: string]: ViewSchema } = {
    name: {
      identifier: "name",
      label: "Name",
      help: "The name of the action provider",
      validators: [],
    },
    displayName: {
      identifier: "displayName",
      label: "Name",
      help: "The name of the action provider",
      validators: [rules.required, rules.minLength(1), rules.maxLength(1024)],
    },
    description: {
      identifier: "description",
      label: "Description",
      help: "The description of the action provider",
      validators: [rules.required, rules.minLength(1), rules.maxLength(2048)],
    },
    actionID: {
      identifier: "actionID",
      label: "Action Identifier",
      help: "Choose an action to associate with this provider",
      validators: [rules.required],
    },
    appID: {
      identifier: "appID",
      label: "App Identifier",
      help: "Choose an app to associate with this provider",
      validators: [rules.required],
    },
    parameters: {
      identifier: "parameters",
      label: "Parameters",
      help: "The parameters of the Action Provider. Includes parameters inherited from the action",
      validators: [],
    },
    outputs: {
      identifier: "outputs",
      label: "Output Transforms",
      help: "Denotes how the action provider transforms the output of the action",
      validators: [],
    },
    adapterType: {
      identifier: "adapterType",
      label: "Adapter Type",
      help: "The type of adapter to use for this action provider",
      validators: [],
    },
    http: {
      identifier: "http",
      label: "An http based adapter",
      help: "Specifications for an http based adapter",
      validators: [],
    },
  };

  const actionProviderSuggestions = useMemo(() => {
    const suggestions: Map<string, string[]> = new Map();
    // if(selectedAction != INVALID_DEVELOPER_ITEM && inheritedParameters && inheritedParameters.length > 0) {
    //   suggestions.set(getRegEx(), ["action"]);
    //   suggestions.get(getRegEx())?.push("parameters");
    //   suggestions.set(getRegEx("parameters"), Object.keys(inheritedParameters));
    // }

    // if(selectedApp != INVALID_DEVELOPER_ITEM) {
    //   const app = selectedApp.item as App;
    //   if(app.configurations && app.configurations.length > 0) {
    //     suggestions.get(getRegEx())?.push("config");
    //     suggestions.set(getRegEx("config"),app.configurations.map((c) => c.name));
    //   }
    // }
    // if (target.parameters && target.parameters.length > 0) {
    //     suggestions.get(getRegEx())?.push("provider");
    //     suggestions.get(getRegEx("provider"))?.push("parameters");
    //     suggestions.set(getRegEx("provider", "parameters"), Object.keys(target.parameters));
    // }

    // if (target.http) {
    //   const http = target.http as HttpAdapter;
    //   if(Object.keys(http).length > 0) {
    //     suggestions.get(getRegEx())?.push("http");  
    //   }
    //   Object.keys(http).forEach( (value:string, index:number)=>{
    //     suggestions.get(getRegEx("http"))?.push(value);
    //     suggestions.get(getRegEx("http", value))?.push("response");
    //     suggestions.set(getRegEx("http", value, "response"), ["status", "headers", "payload"]);  
    //   });
    // }
    return suggestions;
  }, [selectedApp, selectedAction, inheritedParameters, target.parameters]);

  return (
    <SuggestionContext.Provider
      value={{ suggestions: actionProviderSuggestions }}
    >
      <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
        <TextField
          identifier={schema.displayName.identifier}
          label={schema.displayName.label}
          value={target[schema.displayName.identifier]}
          path={[...props.path, schema.displayName.identifier]}
          validators={schema.displayName.validators}
          help={schema.displayName.help}
          onChange={onDisplayNameChange}
        />
        <TextField
          identifier={schema.description.identifier}
          label={schema.description.label}
          value={target[schema.description.identifier]}
          path={[...props.path, schema.description.identifier]}
          validators={schema.description.validators}
          help={schema.description.help}
          onChange={onTextChange}
        />

        <ObjectBackedTextField
          identifier={schema.actionID.identifier}
          label={schema.actionID.label}
          path={[...props.path, schema.actionID.identifier]}
          validators={schema.actionID.validators}
          help={schema.actionID.help}
          value={selectedAction}
          options={actions.map((action) => ({
            id: action.id,
            item: action,
            type: ResourceType.Action,
            context: {
              displayName: action.displayName,
              description: action.description,
            },
          }))}
          viewerType={ViewerType.CardViewer}
          onChange={onActionChange}
          popupOptions={true}
        ></ObjectBackedTextField>

        <ObjectBackedTextField
          identifier={schema.appID.identifier}
          label={schema.appID.label}
          path={[...props.path, schema.appID.identifier]}
          validators={schema.appID.validators}
          help={schema.appID.help}
          value={selectedApp}
          options={apps.map((app) => ({
            id: app.id,
            item: app,
            type: ResourceType.App,
            context: {
              displayName: app.displayName,
              description: app.description,
            },
          }))}
          viewerType={ViewerType.CardViewer}
          onChange={onAppChange}
          popupOptions={true}
        ></ObjectBackedTextField>

        <ParametersField
          identifier={schema.parameters.identifier}
          optional={true}
          label={schema.parameters.label}
          value={target[schema.parameters.identifier] || {}}
          path={[...props.path, schema.parameters.identifier]}
          onChange={onParametersChange}
          viewerType={ViewerType.CardViewer}
          help={schema.parameters.help}
          inherited={inheritedParameters}
          showExpanded={false}
          validators={schema.parameters.validators}
        />

        
        <TextField
          identifier={schema.adapterType.identifier}
          label={schema.adapterType.label}
          value={target[schema.adapterType.identifier] || "http"}
          help={schema.adapterType.help}
          path={[...props.path, schema.adapterType.identifier]}
          validators={schema.adapterType.validators}
          onChange={onTextChange}
          options={Object.values(AdapterType).map((v) => ({
            label: v,
            value: v,
          }))}
        />

        <PanelCollectionField
          items={Object.keys(target["http"] || {})?.map(
            (key: string, index: number) => {
              return {
                id: `http-request-${index}`,
                item: { recordKey: key, recordValue: target["http"]?.[key] },
                type: ResourceType.HttpRequest,
                context: { displayName: key, description: "" },
              };
            }
          )}
          identifier={schema.http.identifier}
          label={schema.http.label}
          help={schema.http.help}
          validators={schema.http.validators}
          onChange={onHttpAdapterChange}
          onAdd={onHttpAdapterAddItem}
          path={[...props.path, schema.http.identifier]}
          getName={(item) => item.item.recordKey}
          addLabel={"Add Http Adapter"}
        />
        {/* A single element collapse */}
        <OutputsTransformField
          current={target[schema.outputs.identifier] || {}}
          identifier={schema.outputs.identifier}
          optional={false}
          label={schema.outputs.label}
          path={[...props.path, schema.outputs.identifier]}
          help={schema.outputs.help}
          outputs={inheritedOutputs}
          onChange={onOutputsChange}
        ></OutputsTransformField>
      </Space>
    </SuggestionContext.Provider>
  );
};
