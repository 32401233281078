import { FC, useEffect, useState } from "react";
import {
  Space,
  Radio,
  Checkbox,
} from "antd";

import { AppInfo } from "types";
import Modal from "components/Modal";
import { ProviderApp, getSelectedAppSubscriptionAndAppInfo, getSelectedAppSubscriptionsAndAppInfos } from "./constant";
import Tree, { DataNode } from "antd/es/tree";

export interface SelectAppModalProps {
  providerApp?: ProviderApp
  isSingle?: boolean;
  onClose: () => void;
  onChange?: (apps: Record<string, string | string[]>[]) => void;
}

const SelectAppModal: FC<SelectAppModalProps> = ({
  providerApp,
  isSingle,
  onClose,
  onChange,
}) => {
  const [checkedApps, setCheckedApps] = useState<Record<string, string | string[]>[]>([]);
  const [appSubscriptions, setAppSubscriptions] = useState([] as string[]);

  useEffect(() => {
    if (isSingle) {
      const [ai, asi] = getSelectedAppSubscriptionAndAppInfo(providerApp)
      if (asi) {
        setAppSubscriptions([asi.id]);
      }

    } else {
      const checkedApps: Record<string, string | string[]>[] = [];
      const appInfos = getSelectedAppSubscriptionsAndAppInfos(providerApp)
      if (appInfos) {
        appInfos.forEach((appInfo: AppInfo) => {
          if (appInfo.appSubscriptionsInfos && appInfo.appSubscriptionsInfos.length) {
            const subscriptions: string[] = [];
            appInfo.appSubscriptionsInfos.forEach((asi) => subscriptions.push(asi.id));
            checkedApps.push({
              providerId: appInfo.providerID,
              appSubscriptions: subscriptions
            })
          }
        })
      }
      setCheckedApps(checkedApps);
      loadAllSubscriptions(checkedApps);
    }
  }, [providerApp]);


  const loadAllSubscriptions = (apps: Record<string, string | string[]>[]) => {
    const subscriptions = [] as string[];
    if (apps.length) {
      apps.map((app) => {
        const selectedSubscriptions = app?.appSubscriptions as string[];
        selectedSubscriptions?.map((selectedSubscription) => {
          subscriptions.push(selectedSubscription);
        });
      });
    }
    setAppSubscriptions(subscriptions);
  };



  const getSingleSelectTree = () => {
    const treeData: DataNode[] = [];
    if (providerApp?.appInfos) {
      providerApp.appInfos.forEach((appInfo: AppInfo) => {
        treeData.push({
          key: appInfo.id,
          disabled: !appInfo.isConfigured,
          title: appInfo.displayName,
          isLeaf: false,
          children: appInfo.appSubscriptionsInfos?.map((appSubscriptionsInfo) => ({
            key: appSubscriptionsInfo.id,
            title: (
              <Radio
                value={appSubscriptionsInfo.id}
                onChange={(e: any) => {
                  setCheckedApps([{
                    providerId: appInfo.providerID,
                    appSubscriptions: [e.target.value as string]
                  }])
                  setAppSubscriptions([e.target.value as string]);
                }}
              >
                {appSubscriptionsInfo.name}
              </Radio>
            ),
          }))
        });
      });
    }
    return treeData;
  };

  const getMultiSelectTree = () => {
    const treeData: DataNode[] = [];
    if (providerApp?.appInfos) {
      providerApp.appInfos.forEach((appInfo: AppInfo) => {
        treeData.push({
          key: appInfo.id,
          disabled: !appInfo.isConfigured,
          title: appInfo.displayName,
          isLeaf: false,
          children: appInfo.appSubscriptionsInfos?.map((appSubscriptionsInfo) => ({
            key: appSubscriptionsInfo.id,
            title: (
              <Checkbox
                value={appSubscriptionsInfo.id}
                onChange={(e: any) => {
                  const checked = e.target.checked as boolean;
                  const value = e.target.value as string;
                  let currentApps = checkedApps;
                  const currentapp = currentApps.find((app) => app.providerId == appInfo.providerID);
                  if (checked) {
                    if (currentapp) {
                      const subscriptions = currentapp.appSubscriptions as string[];
                      if (subscriptions) {
                        const appSub = currentapp.appSubscriptions as string[];
                        appSub.push(value);
                        currentapp.appSubscriptions = appSub;
                      } else {
                        currentapp.appSubscriptions = [value];
                      }
                    } else {
                      currentApps.push({
                        providerId: appInfo.providerID,
                        appSubscriptions: [value],
                      });
                    }
                  } else {
                    if (currentapp) {
                      const subscriptions = currentapp?.appSubscriptions as string[];
                      if (subscriptions) {
                        currentapp.appSubscriptions = subscriptions.filter((subscription) => subscription != value);
                        if (currentapp.appSubscriptions.length == 0) {
                          currentApps = currentApps.filter((app) => app.providerId != appInfo.providerID)
                        }
                      }
                    }
                  }
                  loadAllSubscriptions(currentApps);
                  setCheckedApps(currentApps);
                }}
              >
                {appSubscriptionsInfo.name}
              </Checkbox>
            ),
          }))
        });
      });
    }
    return treeData;
  };

  return (
    <Modal
      title={`Select App - (${providerApp?.name})`}
      open={true}
      footerName="Save"
      onClose={onClose}
      onSubmit={() => onChange?.(checkedApps)}
    >
      {isSingle ? (
        <Space direction="vertical">
          <Radio.Group value={appSubscriptions ? appSubscriptions[0] : ""}>
            <Tree
              defaultExpandAll
              treeData={getSingleSelectTree()}
            />
          </Radio.Group>
        </Space>
      ) : (
        <Space direction="vertical">
          <Checkbox.Group value={appSubscriptions}>
            <Tree
              defaultExpandAll
              treeData={getMultiSelectTree()}
            />
          </Checkbox.Group>
        </Space>

      )}


    </Modal>
  );
};

export default SelectAppModal;