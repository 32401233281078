import { FC, useEffect, useState } from "react";
import { useDeveloperStore, useSettingsStore } from "store";
import { Divider, Tabs, theme, Typography } from "antd";
import PageWrapper from "components/PageWrapper";

import ActivityOverview from "components/ActivityOverview";
import styles from "./Developer.module.scss";

import { useNavigate } from "react-router-dom";
import {
  DeveloperTab,
  ResourceType,
  DeveloperItem,
  INVALID_DEVELOPER_ITEM,
  DeveloperTabType,
} from "types";
import { ActionTabItem } from "./EntityManagement/ActionTabItem";
import { AppTabItem } from "./EntityManagement/AppTabItem";
import { ArtifactTabItem } from "./EntityManagement/ArtifactTabItem";

import { TriggerTabItem } from "./EntityManagement/TriggerTabItem";
import { ProviderTabItem } from "./EntityManagement/ProviderTabItem";
import { getDeveloperTabByType } from "utility/developer";

const { Title, Paragraph, Text, Link } = Typography;
export const Developer: FC = () => {
  const [activeTab, setActiveTab, setActiveItem] = useDeveloperStore(
    (state) => [state.activeTab, state.setActiveTab, state.setActiveItem]
  );

  const navigate = useNavigate();
  const onEdit = (item: DeveloperItem) => {
    setActiveItem(item);
    navigate(`/developer/${item.type}/${item.id}`);
  };

  const onCreate = (resourceType: ResourceType) => {
    setActiveItem(INVALID_DEVELOPER_ITEM);
    navigate(`/developer/${resourceType}/add`);
  };

  const tabItems = [
    {
      key: DeveloperTabType.Providers,
      label: "Provider",
      children: <ProviderTabItem onEdit={onEdit} onCreate={onCreate} />,
    },
    {
      key: DeveloperTabType.Apps,
      label: "App",
      children: <AppTabItem onEdit={onEdit} onCreate={onCreate} />,
    },
    {
      key: DeveloperTabType.Actions,
      label: "Action",
      children: <ActionTabItem onEdit={onEdit} onCreate={onCreate} />,
    },
    {
      key: DeveloperTabType.Triggers,
      label: "Trigger",
      children: <TriggerTabItem onEdit={onEdit} onCreate={onCreate} />,
    },
    {
      key: DeveloperTabType.Artifacts,
      label: "Artifact",
      children: <ArtifactTabItem onEdit={onEdit} onCreate={onCreate} />,
    },
  ];

  const { token } = theme.useToken();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  return (
    <PageWrapper
      content={
        <div style={{ margin: token.margin, width: "100%" }}>
          <Title level={3}>Developer</Title>
          {useDivider && <Divider />}
          <Tabs
            defaultActiveKey={DeveloperTabType.Providers}
            className={styles.developerTabsLayout}
            activeKey={activeTab.tabType}
            onChange={(key) =>
              setActiveTab(getDeveloperTabByType(key as DeveloperTabType))
            }
            items={tabItems}
          />
        </div>
      }
    ></PageWrapper>
  );
};
