import { AxiosResponse } from "axios";
import { axiosInterceptor } from "./axios";
import { Profile } from "types";
import { profileApiPath } from "./constant"

// get profile data
export const getProfileApi = async () :Promise<AxiosResponse<any, any>> => axiosInterceptor("get", profileApiPath);
export const getProfileImageUploadUrlApi = async (extension?: string) => axiosInterceptor("get", `${profileApiPath}/uploadurl?ct=${extension}`);
export const updateProfileApi = async ({
  business,
  email,
  firstName,
  image,
  lastName,
  locale,
  phone,
  region,
  title,
}: Profile) => await axiosInterceptor("patch", profileApiPath,
  {
    business,
    email,
    first_name: firstName,
    image,
    last_name: lastName,
    locale,
    phone,
    region,
    title,
  });

export const getProfileSecureDataApi = async () :Promise<AxiosResponse<any, any>>  => axiosInterceptor("get", `${profileApiPath}/security`);

export const phoneVerifyStartApi = async (value: string, challenge: string,) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "post",
  `${profileApiPath}/security/phone`, { value, challenge }
);

export const phoneVerifyCompleteApi = async (verifier: string, otp: string)  :Promise<AxiosResponse<any, any>> => axiosInterceptor(
  "patch", `${profileApiPath}/security/phone`, { verifier, otp }
);

export const emailVerifyStartApi = async (value: string, challenge: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "post",
  `${profileApiPath}/security/email`, { value, challenge }
);


export const emailVerifyCompleteApi = async (verifier: string, otp: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "patch",
  `${profileApiPath}/security/email`,
  {
    verifier,
    otp,
  }
);


export const passwordVerifyStartApi = async (value: string, challenge: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "post",
  `${profileApiPath}/security/password`,
  {
    value,
    challenge,
  }
);


export const passwordVerifyCompleteApi = async (
  verifier: string,
  otp: string,
)  :Promise<AxiosResponse<any, any>> => axiosInterceptor(
  "patch",
  `${profileApiPath}/security/password`,
  {
    verifier,
    otp,
  }
);


export const updatePrimaryPhoneApi = async (value: string)  :Promise<AxiosResponse<any, any>> => axiosInterceptor(
  "post",
  `${profileApiPath}/security/phone/primary`,
  {
    value,
  }
);

export const updatePrimaryEmailApi = async (value: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "post",
  `${profileApiPath}/security/email/primary`,
  {
    value,
  }
);

export const removePhoneApi = async (value: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "delete",
  `${profileApiPath}/security/phone/${value}`
);

export const removeEmailApi = async (value: string) :Promise<AxiosResponse<any, any>>  => axiosInterceptor(
  "delete",
  `${profileApiPath}/security/email/${value}`
);
