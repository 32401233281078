import {
  Button,
  Modal,
  Space,
  Table,
  Typography,
  notification,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { ClientCredentials, ResourceType } from "types";
import { PermissionSetEdit } from "./PermissionSetEdit";
import { RoleEdit } from "./RoleEdit";
import { UserEdit } from "./UserEdit";

import { useRbacStore } from "store";

import { getStyles } from "../../utility/styles";
import { MutationData } from "./MutationData";

import { ItemsGroup } from "components/ItemsGroup";
import { HLink } from "components/Link";
import {
  ClientCredentialsEdit,
  CredentialValues,
} from "./ClientCredentialsEdit";
const { Text, Paragraph } = Typography;

export interface ClientCredentialTabItemProps {
  readOnly: boolean;
}

export const ClientCredentialTabItem = (
  props: ClientCredentialTabItemProps
) => {
  const clientCredentials = useRbacStore((state) => state.clientCredentials);
  const roles = useRbacStore((state) => state.roles);
  /** We could have a sequence of mutations, where userEdit leads to role edit , leads to permission set edit etc.
   * When an item edit finishes , we need to return to the previous edit item, so we need to keep track of the sequence of edits
   * The mutation data is a stack of edits, where the last edit is the current edit
   */
  const [mutationData, setMutationData] = useState<MutationData[]>([]);
  const [editItem, setEditItem] = useState<MutationData | null>(null);
  const [credentials, setCredentials] = useState<CredentialValues | null>(null);

  const pushMutationData = (data: MutationData) => {
    setMutationData([...mutationData, data]);
  };

  const popMutationData = () => {
    const newMutationData = [...mutationData];
    newMutationData.pop();
    setMutationData(newMutationData);
  };

  const deleteClientCredentials = useRbacStore(
    (state) => state.deleteClientCredentials
  );

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);

  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  useEffect(() => {
    if (mutationData.length) {
      const lastMutationData = mutationData[mutationData.length - 1];
      setEditItem(lastMutationData);
    } else {
      setEditItem(null);
    }
  }, [mutationData]);

  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deleteClientCredentials(id);
      }, Promise.resolve());

      notification.success({
        message: `Successfully deleted api credentials`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting api credentials`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    popMutationData();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
  };

  return (
    <div
      id="cc-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <div
        id="cc-header"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: token.margin,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontSize: token.fontSizeHeading4 }}>
            Total API credentials ({Object.keys(clientCredentials).length})
          </Text>
          {props.readOnly && (
            <Text type="danger" style={{ fontSize: "0.75rem" }}>
              Create , edit and delete are disabled in the current context
            </Text>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            disabled={props.readOnly}
            onClick={() =>
              pushMutationData({
                isAdd: true,
                type: ResourceType.ClientCredentials,
              })
            }
          >
            Add
          </Button>
          <Button
            type="primary"
            onClick={() => onDelete(selectedRowKeys)}
            disabled={props.readOnly || !selectedRowKeys.length}
          >
            Delete
          </Button>
        </Space>
      </div>

      <div style={{ width: "100%" }}>
        <Table
          rowKey={"clientId"}
          key={`cc-table`}
          rowSelection={props.readOnly ? undefined : rowSelection}
          columns={[
            {
              title: "ClientId",
              render: (_: any, record: ClientCredentials) => {
                return (
                  <HLink
                    onClick={() => {
                      pushMutationData({
                        isAdd: false,
                        clientCredentials: record,
                        type: ResourceType.ClientCredentials,
                      });
                    }}
                    text={`${record.clientId}`}
                  />
                );
              },
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
            {
              title: "Roles Assigned",
              dataIndex: "roles",
              key: "roles-list",
              render: (r: string[]) => (
                <ItemsGroup
                  items={r.map((x) => ({
                    id: roles[x].id,
                    name: roles[x].name,
                    description: roles[x].description,
                  }))}
                ></ItemsGroup>
              ),
            },
            {
              title: "Roles Count",
              dataIndex: "roles",
              key: "roles",
              render: (_: any, record: ClientCredentials) =>
                record.roles?.length,
            },
          ]}
          dataSource={Object.values(clientCredentials)}
          bordered={true}
          pagination={
            Object.keys(clientCredentials).length <= 10
              ? false
              : { pageSize: 10 }
          }
        />
      </div>
      {editItem && editItem.type == ResourceType.ClientCredentials && (
        <ClientCredentialsEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          clientCredentials={editItem.clientCredentials}
          onClose={onClose}
          onClientCredentialsAdd={async (secret: CredentialValues) =>
            setCredentials(secret)
          }
          onRoleAdd={async () =>
            pushMutationData({
              type: ResourceType.Role,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.User && (
        <UserEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          user={editItem.user}
          onClose={onClose}
          onRoleAdd={async () =>
            pushMutationData({
              type: ResourceType.Role,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.Role && (
        <RoleEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          role={editItem.role}
          onClose={onClose}
          onPermissionSetAdd={async () =>
            pushMutationData({
              type: ResourceType.PermissionSet,
              isAdd: true,
            })
          }
          onUserAdd={async () =>
            pushMutationData({
              type: ResourceType.User,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.PermissionSet && (
        <PermissionSetEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          pset={editItem.permissionSet}
          onClose={onClose}
        />
      )}
      {credentials && (
        <Modal
          title="Api Credentials"
          open={true}
          onOk={() => setCredentials(null)}
          onCancel={() => setCredentials(null)}
        >
          <div
            id="client-credentials-display"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <div
              id="client-id-display"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text strong>Client Id</Text>
              <Paragraph copyable>{credentials.clientId}</Paragraph>
            </div>

            <div
              id="client-secret-display"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text strong>Client Secret</Text>
              <Paragraph copyable>{credentials.clientSecret}</Paragraph>
            </div>
            <div>
              <Text>
                This is the only time you will see the client credentials,
                please copy
              </Text>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
