import { AxiosResponse } from "axios";
import { CreationalSubscriber, MspState, Provider, Subscriber, SubscriberUpdateRequest, SubscriptionState } from "types";
import { axiosInterceptor } from "./axios";
import { providersApiPath, subscribersApiPath } from "./constant";

/** Provider apis */
export const getSubscribersApi = async () : Promise<AxiosResponse<{ tenants: Subscriber[]}>> => {
  return axiosInterceptor("get", providersApiPath + "/subscriptions");
}

export const createSusbscriberApi = async (organization : CreationalSubscriber) : Promise<AxiosResponse<Subscriber>> => { 
  return axiosInterceptor("post", providersApiPath + "/subscriptions", organization);
}

export const updateSubscriberApi = async (request : SubscriberUpdateRequest ) : Promise<AxiosResponse<Subscriber>> => {
  return axiosInterceptor("patch", `${providersApiPath}/subscriptions`, request)
};

export const getProviderStateApi = async () : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("get", `${providersApiPath}/self`);
}

export const updateProviderStateApi = async (active: boolean, external: boolean ) : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("post", `${providersApiPath}/self`,{active, external});
}

export const getProvidersApi = async () : Promise<AxiosResponse<{ providers: Provider[]}>> => { 
  return axiosInterceptor("get", providersApiPath);
}

/** Subscriber apis */
export const enrollWithProviderApi = async ( msspId: string) : Promise<AxiosResponse<any>> => {
  return axiosInterceptor("post", subscribersApiPath,{ msspId });
}

export const getSubscriberStateApi = async () : Promise<AxiosResponse<MspState>> => { 
  return axiosInterceptor("get", `${subscribersApiPath}`);
}

export const detachFromProviderApi = async () : Promise<AxiosResponse<any>> => { 
  return axiosInterceptor("delete", subscribersApiPath);
}

export const acknowledgeProviderCancelApi = async () : Promise<AxiosResponse<any>> => { 
  return axiosInterceptor("delete",`${subscribersApiPath}/ack`);
}

