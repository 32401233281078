import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin, Typography } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HLink } from "components/Link";

import { auth } from "api";
import { Auth0Error } from "auth0-js";
import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./SignIn.module.scss";
import { useAuthStore } from "store";
import { ssoLoginUrl } from "api/constant";
import { useTokenStore } from "store";
import { isProduction } from "api/constant";
import { v4 as uuidv4 } from "uuid";

import { notification } from "utility/notification";

const { Text } = Typography;

const SignIn: FC = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState<boolean>(false);
  const [sso, setSSO] = useState<boolean>(false);
  const navigate = useNavigate();
  const setState = useTokenStore((state) => state.setState);

  const onSubmit = (value: any) => {
    const doSSO = async () => {
      const res = `${ssoLoginUrl}?user=${btoa(value.email)}`;
      window.location.href = res;
    };
    if (sso) {
      doSSO();
    } else {
      console.log("value : ", value);
      setLoader(true);
      const authState = `${isProduction ? "prod" : "dev"}-${uuidv4()}`;
      const b64AuthState = btoa(authState);
      setState(b64AuthState);
      auth.login(
        {
          email: value.email,
          password: value.password,
          realm: process.env.REACT_APP_AUTH0_CONNECTION,
          responseType: "token id_token",
          redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
          state: b64AuthState,
        },

        function (error: Auth0Error | null, result: any) {
          if (error) {
            console.log("Oops ! Login failed");
            notification.error({
              message: error.description,
              duration: 6,
            });
            console.log(error);
            setLoader(false);
            return;
          }

          console.log("result : ", result);
          setLoader(false);
        }
      );
    }
  };

  return (
    <Spin spinning={loader}>
      <SignInSignUpWrapper>
        <div
          className="verticalAlignDiv"
          style={{ width: "75%", maxWidth: "400px" }}
        >
          <div className={styles.formWrap}>
            <div className={styles.FormDiv}>
              <Form
                form={form}
                name="signInForm"
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Email is required!" },
                    () => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
                          return Promise.reject("Enter a valid email");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className="authInput"
                    size="large"
                    prefix={<MailOutlined />}
                    placeholder="Enter your email address"
                  />
                </Form.Item>
                {!sso && (
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { required: true, message: "Password is required!" },
                    ]}
                  >
                    <Input.Password
                      className="authInput"
                      size="large"
                      prefix={<LockOutlined />}
                      placeholder="Enter your Password"
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="authbtn_100"
                      block={sso}
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        form
                          .validateFields()
                          .then(onSubmit)
                          .catch((err) => {
                            console.log(err);
                          })
                      }
                    >
                      Sign In
                    </Button>
                    {!sso && (
                      <HLink
                        className="textLink"
                        onClick={() => navigate(`/forgot-password`)}
                        text={"Reset your password?"}
                      />
                    )}
                  </div>
                </Form.Item>
              </Form>

              <div className={styles.or}>OR</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <Text>Using enterprise Single Sign On?</Text>
                <Button type="link" onClick={() => setSSO(!sso)}>
                  {sso ? "Back to login options" : "Sign in with SSO"}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <Text>{"Dont't have an account ? "}</Text>
                <Button type="link" onClick={() => navigate("/signup")}>
                  Sign Up here
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SignInSignUpWrapper>
    </Spin>
  );
};

export default SignIn;
