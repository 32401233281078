
export const enum ScheduleType {
	MinutesScheduleType   = "minutes",
	HourlyScheduleType    = "hourly",
	DailyScheduleType     = "daily",
  WeeklyScheduleType    = "weekly",
	MonthlyScheduleType   = "monthly",
	YearlyScheduleType    = "yearly",
  AdvancedScheduleType  = "advanced"
} 

export const ScheduleTypeOptions = [
  { value: ScheduleType.MinutesScheduleType, label: 'Minutes' },
  { value: ScheduleType.HourlyScheduleType, label: 'Hourly' },
  { value: ScheduleType.DailyScheduleType, label: 'Daily' },
  { value: ScheduleType.WeeklyScheduleType, label: 'Weekly' },
  { value: ScheduleType.MonthlyScheduleType, label: 'Monthly' },
  { value: ScheduleType.YearlyScheduleType, label: 'Yearly' },
  { value: ScheduleType.AdvancedScheduleType, label: 'Advanced' },
]

export const SchdeuleMonthOptions = [
  { value: "jan", label: 'Januray' },
  { value: "feb", label: 'February' },
  { value: "mar", label: 'March' },
  { value: "apr", label: 'April' },
  { value: "may", label: 'May' },
  { value: "jun", label: 'June' },
  { value: "jul", label: 'July' },
  { value: "aug", label: 'August' },
  { value: "sep", label: 'September' },
  { value: "oct", label: 'October' },
  { value: "nov", label: 'November' },
  { value: "dec", label: 'December' },
];

export const SchdeuleWeekDayOptions = [
  { value: "sun", label: 'Sunday' },
  { value: "mon", label: 'Monday' },
  { value: "tue", label: 'TuesDay' },
  { value: "wed", label: 'Wednesday' },
  { value: "thu", label: 'Thursday' },
  { value: "fri", label: 'Friday' },
  { value: "sat", label: 'Saturday' },
]
