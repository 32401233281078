import { AdapterType } from "./types";



export enum DatabaseType {
    PostgresDatabaseType = "postgres",
    MSSqlDatabaseType    = "mssql",
    OracleDatabaseType   = "oracle",
}

export const DatabaseTypeMap: Map<DatabaseType, string>  =  new Map<DatabaseType, string> (
	[
		[DatabaseType.PostgresDatabaseType, "Postgres"], 
		[DatabaseType.MSSqlDatabaseType, "Micorsfot SQl"], 
		[DatabaseType.OracleDatabaseType, "Oracle"]
	]
)

export enum SqlQueryType {
    Read  = "read",
	Write = "write"
}

export enum SqlResponseFormat {
	Json = "json",
	Text = "text",
	Csv = "csv"
}

export interface SqlRequest {
    queryType: SqlQueryType;
    query: string;
    timeout: number;
    responseFormat: SqlResponseFormat;
}
