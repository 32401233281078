import { PlusOutlined, DeleteOutlined, DeleteTwoTone } from "@ant-design/icons";
import { Collapse } from "antd";
import {
  EditorContext,
  TextField,
  ViewProps,
  rules,
} from "components/EntityEditor";
import React, { useContext, useEffect } from "react";

import { Button, Space } from "antd";
import { DeveloperItem, IOData, IODataType, ResourceType } from "types";

import { IODataUsageType, IODataView } from "./ioDataView";
import { useFieldValidationStore } from "store";
export interface IODataOutputViewProps extends ViewProps {
  array: boolean;
}

import styles from "../../DevEditor.module.scss";
const { Panel } = Collapse;

/**
 *  The input to this view is either an array or object. We take the case of array
 *  first. The target object for parameter view is as follows:
 *  {
 *    recordKey: "parameterName",
 *   recordValue: [ { parameter1 : { IODATA spec keys }, parameter2 : {IODATA spec keys}  }]
 *  }
 *  For the non-array case, the target object is a record value that is simply { paameter1 : { IODATA} , parameter2 : {IODATA} }
 *
 * dataSource is the keys of the recordValue, listed as { recordKey: "parameter1", recordValue: {IODATA} } etc
 */
export const IODataOutputView = (props: IODataOutputViewProps) => {
  const editorCtx = useContext(EditorContext);
  const insertError = useFieldValidationStore((state) => state.insertLeafNode);
  const mounted = React.useRef(false);
  const [recordKey, setRecordKey] = React.useState(props.data?.recordKey || "");
  const [addedItemIds, setAddedItemIds] = React.useState<string[]>([]);

  useEffect(() => {
    insertError(
      [...props.path, "addedItemIds"],
      addedItemIds.length ? [rules.required] : []
    );
  }, [addedItemIds]);

  const [dataSource, setDataSource] = React.useState<DeveloperItem[]>(() => {
    const item =
      (props.array ? props.data?.recordValue?.[0] : props.data?.recordValue) ||
      {};
    const items = Object.keys(item);
    insertError(props.path, items.length > 0 ? [] : [rules.minLength(1)]);

    return items.map((key) => {
      const value = item[key];
      return {
        id: key,
        item: {
          recordKey: key,
          recordValue: value,
        },
        type: ResourceType.IODataParameter,
      };
    });
  });

  useEffect(() => {
    const item = dataSource.reduce((acc, cur) => {
      const key = cur.item.recordKey;
      const value = cur.item.recordValue;
      acc[key] = value;
      return acc;
    }, {} as { [k: string]: any });

    const doAction = () => {
      editorCtx.onChange({
        recordKey,
        recordValue: props.array ? [item] : item,
      });
      insertError(
        props.path,
        dataSource.length > 0 ? [] : [rules.minLength(1)]
      );
    };

    mounted.current ? doAction() : (mounted.current = true);
    return () => {
      insertError(props.path, []);
    };
  }, [recordKey, dataSource]);

  const onKeyChange = async (identifier: string, value: string) => {
    setRecordKey(value);
  };

  /** Because we keep the  panel closed on addition of a new item, the item validations
   * do not run. As a result, we cannot disable the save button even if the added item is
   * invalid. To fix this, when an item is added, we mark the view state to be in error
   * until the user opens the panel and fixes the error. This is a hack, but it works.
   */
  const onAdd = async () => {
    const index = dataSource.length;
    const defaultIOData: IOData = {
      description: "",
      displayName: "",
      required: false,
      type: IODataType.String,
      max: 0,
      min: 0,
    };
    const newItem = {
      id: `parameter-${index}`,
      item: {
        recordKey: `parameter-${index}`,
        recordValue: defaultIOData,
      },
      type: ResourceType.IODataParameter,
    };
    const newDataSource = [...dataSource, newItem];
    setDataSource(newDataSource);
    setAddedItemIds([...addedItemIds, newItem.id]);
  };

  const onRemove = async (item: DeveloperItem) => {
    const newDataSource = dataSource.filter(
      (i) => i.item.recordKey !== item.item.recordKey
    );
    setDataSource(newDataSource);
    const newAddedItemIds = addedItemIds.filter((id) => id !== item.id);
    setAddedItemIds(newAddedItemIds);
  };

  const onParametersChange = async (
    key: string,
    value: { [k: string]: any }
  ) => {
    const index = dataSource.findIndex((item) => item?.item?.recordKey === key);
    const item = dataSource[index];
    const newItem: DeveloperItem = {
      ...item,
      item: value,
    };
    const newDataSource = [...dataSource];
    newDataSource[index] = newItem;
    setDataSource(newDataSource);
  };

  const onPanelChange = (key: string | string[]) => {
    if (typeof key === "string") {
      /** If the panel is opened, remove the item from the added item list */
      const newAddedItemIds = addedItemIds.filter((id) => id !== key);
      setAddedItemIds(newAddedItemIds);
    }
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <TextField
        identifier={"recordKey"}
        label={"Output Sequence Name"}
        value={recordKey}
        path={[...props.path, recordKey]}
        onChange={onKeyChange}
        validators={[rules.required]}
      />

      <Collapse ghost={true} accordion={true} onChange={onPanelChange}>
        {dataSource.map((item) => {
          return (
            <Panel
              key={item.id}
              header={`${item.item?.recordKey}`}
              style={{ margin: "5px" }}
              extra={
                !editorCtx.view && (
                  <Button
                    ghost
                    shape="circle"
                    size="small"
                    className={styles.deleteCondButton}
                    style={{ marginTop: "-3px" }}
                    icon={
                      <DeleteTwoTone className={styles.deleteCondIcon} />
                    }
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onRemove(item);
                    }}
                  />
                )
              }
            >
              <EditorContext.Provider
                value={{
                  view: editorCtx.view,
                  viewFactory: editorCtx.viewFactory,
                  onChange: async (value) => {
                    onParametersChange(item.item.recordKey, value);
                  },
                  onCancel: editorCtx.onCancel,
                }}
              >
                <IODataView
                  type={IODataUsageType.Output}
                  data={item.item}
                  path={[...props.path, item.item.recordKey]}
                ></IODataView>
              </EditorContext.Provider>
            </Panel>
          );
        })}
      </Collapse>
      {!editorCtx.view && (
        <div
          style={{
            width: "50%",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "10px",
          }}
        >
          <Button
            type="default"
            onClick={onAdd}
            block
            icon={<PlusOutlined />}
          >
            Add Output
          </Button>
        </div>
      )}
    </Space>
  );
};
