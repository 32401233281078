import { Space } from "antd";
import {
  EditorContext,
  NumberField,
  TextField,
  ViewProps,
} from "components/EntityEditor";

import React, { useContext, useEffect } from "react";

export const HttpPollIncrementTokenReaderTimeStampView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onPrimitiveChange = async (
    identifier: string,
    value: string | boolean | number
  ) => {
    setTarget({ ...target, [identifier]: value });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <TextField
        identifier={"timestampFormat"}
        label={"Timestamp Format"}
        value={target["timestampFormat"]}
        path={[...props.path, "timestampFormat"]}
        onChange={onPrimitiveChange}
      />

      <TextField
        identifier={"startInitialValue"}
        label={"Start Initial Value"}
        value={target["startInitialValue"]}
        path={[...props.path, "startInitialValue"]}
        onChange={onPrimitiveChange}
      />

      <TextField
        identifier={"endInitialValue"}
        label={"End Initial Value"}
        value={target["endInitialValue"]}
        path={[...props.path, "endInitialValue"]}
        onChange={onPrimitiveChange}
      />
    </Space>
  );
};
