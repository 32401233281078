import { Node, Edge,isNode, isEdge, MarkerType, SmoothStepEdgeProps, } from "reactflow";
import { v4 as uuidv4 } from 'uuid';

import {
    Workflow,
    Action,
    Operator,
    Trigger,
    OperatorType,
    WorkflowNodeType,
    WorkflowNodeProps,
    WorkflowStepType,
    WorkflowStepAction,
    WorkflowStepOperator,
    WorkflowSteps,
    WorkflowEdgeType,
} from "types";

import { useWorkflowStepsStore } from "store";

import {
    DecisionStepLabel,
    OperatorConditionEdgeLabel,
    OperatorParallelEdgeLabel,
    EdgeData,
    OperatorLoopEdgeLabel,
    OperatorApprovalEdgeLabel,
    DropzoneNodeHint,
    LabelColors,
    DropzoneNodeType
} from "./constants"
import { findStepResourceType } from "./utils";
import { commonIcons } from "assets/icons";
import { Dimensions } from "../constant";


export function getCanvasElementsFromWorkflow(workflow: Workflow, labelColors: LabelColors) {
    const elements: any[] = [];

    //create trigger elements
    const lastDropzoneNode = createTriggerElements(workflow, elements);
    createStepElements(workflow, elements, workflow.triggerRef.nextStep, lastDropzoneNode, labelColors);
    createLeafElements(elements);
    return elements;
}


function createTriggerElements(workflow: Workflow, elements: any[]) {
    const props = constructNodePropsFromWorfkflowTrigger(workflow);
    let counter = 0;

    const node = constructNode(props);
    elements.push(node);

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );

    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );
    elements.push(dropzoneNode, edgeNodeToDropzoneNode);
    return dropzoneNode
}


function createStepElements(workflow: Workflow, elements: any[], stepId: string, parentDropzoneNode: Node, labelColors: LabelColors): Node | undefined {
    //terminating condition of recursion
    if (stepId == "") return parentDropzoneNode;
    const props = constructNodePropsFromStep(workflow, stepId, workflow.steps[stepId]);

    switch (props.nodeType) {
        case WorkflowNodeType.Action: {
            const lastDropzoneNode = createActionElements(elements, props, parentDropzoneNode);
            return createStepElements(workflow, elements, workflow.steps[stepId].nextStep, lastDropzoneNode, labelColors);
        }
        case WorkflowNodeType.Operator: {
            const step = workflow.steps[stepId] as WorkflowStepOperator;
            switch (props.resourceType) {
                case OperatorType.Wait: {
                    const lastDropzoneNode = createOperatorWaitElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Search: {
                    const lastDropzoneNode = createOperatorSearchElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Condition: {
                    const lastDropzoneNode = createOperatorConditionElements(workflow, elements, props, parentDropzoneNode, step.decisionSteps, labelColors);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Approval: {
                    const lastDropzoneNode = createOperatorApprovalElements(workflow, elements, props, parentDropzoneNode, step.decisionSteps, labelColors);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Parallel: {
                    const lastDropzoneNode = createOperatorParallelElements(workflow, elements, props, parentDropzoneNode, step.parallelSteps, labelColors);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Loop: {
                    const lastDropzoneNode = createOperatorLoopElements(workflow, elements, props, parentDropzoneNode, step.loopStep, labelColors);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Break: {
                    const lastDropzoneNode = createOperatorBreakElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.KV: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Subworkflow: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Http: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Db: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Ai: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
                case OperatorType.Script: {
                    const lastDropzoneNode = createOperatorElements(elements, props, parentDropzoneNode);
                    return createStepElements(workflow, elements, step.nextStep, lastDropzoneNode, labelColors);
                }
            }
            break;
        }
    }
}

function createActionElements(elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node) {
    let counter = 0;

    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );

    elements.push(node, dropzoneNode, edgeNodeToParent, edgeNodeToDropzoneNode);
    return dropzoneNode
}

function createOperatorSearchElements(elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node) {
    let counter = 0;

    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );

    elements.push(node, dropzoneNode, edgeNodeToParent, edgeNodeToDropzoneNode);
    return dropzoneNode
}

function createOperatorWaitElements(elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node) {
    let counter = 0;

    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );

    elements.push(node, dropzoneNode, edgeNodeToParent, edgeNodeToDropzoneNode);
    return dropzoneNode
}

function createOperatorBreakElements(elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node) {
    let counter = 0;

    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );

    elements.push(node, dropzoneNode, edgeNodeToParent, edgeNodeToDropzoneNode);
    return dropzoneNode
}

function createOperatorElements(elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node) {
    let counter = 0;

    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    const dropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        dropzoneNode.id
    );

    elements.push(node, dropzoneNode, edgeNodeToParent, edgeNodeToDropzoneNode);
    return dropzoneNode
}

function createOperatorApprovalElements(workflow: Workflow, elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node, decisionSteps: Record<string, string>, labelColors: LabelColors) {
    let counter = 0;

    //create node
    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    //True branch
    const trueDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToTrueDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        trueDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        undefined,
        OperatorApprovalEdgeLabel.True
    );
    edgeNodeToTrueDropzoneNode.labelBgStyle = {fill: labelColors?.successBgColor, fillOpacity: 1, width: "35px" }
    edgeNodeToTrueDropzoneNode.labelBgPadding = [7, 3]
    edgeNodeToTrueDropzoneNode.labelStyle = { fill: labelColors?.successColor, fontWeight: 500 } as React.CSSProperties;

    //False branch
    const falseDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );

    const edgeNodeToFalseDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        falseDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        undefined,
        OperatorApprovalEdgeLabel.False
    );

    edgeNodeToFalseDropzoneNode.labelBgStyle = {fill: labelColors?.errorBgColor, fillOpacity: 1, width: "35px" }
    edgeNodeToFalseDropzoneNode.labelBgPadding = [9, 3]
    edgeNodeToFalseDropzoneNode.labelStyle = { fill: labelColors?.errorColor, fontWeight: 500 } as React.CSSProperties;

    //Close the approval operator for its join loop once branches are done for decision steps
    const joinDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Join,
        editMode: props.editMode
    } as WorkflowNodeProps
    );


    //Decision Steps - true branch
    let joinDropzoneEdgeSourceForTrueBranch = trueDropzoneNode.id;
    if (decisionSteps[DecisionStepLabel.True]) {
        const lastDropzoneNode = createStepElements(workflow, elements, decisionSteps[DecisionStepLabel.True], trueDropzoneNode, labelColors);
        if (lastDropzoneNode) {
            joinDropzoneEdgeSourceForTrueBranch = lastDropzoneNode.id;
        }
    }

    const joinEdgeType = getJoinEdgeType(workflow.steps[node.id] as WorkflowStepOperator, OperatorType.Approval, workflow);

    const edgeFromTrueBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        joinDropzoneEdgeSourceForTrueBranch,
        joinDropzoneNode.id,
        undefined,
        "b1",
        joinEdgeType,
        { parentId: node.id, parentType: node.type,  hiddenLabel: OperatorApprovalEdgeLabel.Join  } as EdgeData
    );

    //Decision Steps - false branch
    let joinDropzoneEdgeSourceForFalseBranch = falseDropzoneNode.id;
    if (decisionSteps[DecisionStepLabel.False]) {
        const lastDropzoneNode = createStepElements(workflow, elements, decisionSteps[DecisionStepLabel.False], falseDropzoneNode, labelColors);
        if (lastDropzoneNode) {
            joinDropzoneEdgeSourceForFalseBranch = lastDropzoneNode.id;
        }
    }
    const edgeFromFalseBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        joinDropzoneEdgeSourceForFalseBranch,
        joinDropzoneNode.id,
        undefined,
        "b2",
        joinEdgeType,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorApprovalEdgeLabel.Join } as EdgeData
    );

    elements.push(
        node, edgeNodeToParent,
        trueDropzoneNode, edgeNodeToTrueDropzoneNode,
        falseDropzoneNode, edgeNodeToFalseDropzoneNode,
        joinDropzoneNode, edgeFromTrueBranchEndToJoinDropzone, edgeFromFalseBranchEndToJoinDropzone
    );
    return joinDropzoneNode;
}


function createOperatorConditionElements(workflow: Workflow, elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node, decisionSteps: Record<string, string>, labelColors: LabelColors) {
    let counter = 0;

    //create node
    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    //True branch
    const trueDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );
    const edgeNodeToTrueDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        trueDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        undefined,
        OperatorConditionEdgeLabel.True
    );
    edgeNodeToTrueDropzoneNode.labelBgStyle = {fill: labelColors?.successBgColor, fillOpacity: 1, width: "45px" }
    edgeNodeToTrueDropzoneNode.labelBgPadding = [8, 4]
    edgeNodeToTrueDropzoneNode.labelStyle = { fill: labelColors?.successColor, fontWeight: 500 } as React.CSSProperties;

    //False branch
    const falseDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode
    } as WorkflowNodeProps
    );

    const edgeNodeToFalseDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        falseDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        undefined,
        OperatorConditionEdgeLabel.False
    );

    edgeNodeToFalseDropzoneNode.labelBgStyle = {fill: labelColors?.errorBgColor, fillOpacity: 1, width: "45px" }
    edgeNodeToFalseDropzoneNode.labelBgPadding = [8, 4]
    edgeNodeToFalseDropzoneNode.labelStyle = { fill: labelColors?.errorColor, fontWeight: 500 } as React.CSSProperties;

    //Close the condition operator for its join loop once branches are done for decision steps
    const joinDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Join,
        editMode: props.editMode
    } as WorkflowNodeProps
    );


    //Decision Steps - true branch
    let joinDropzoneEdgeSourceForTrueBranch = trueDropzoneNode.id;
    if (decisionSteps[DecisionStepLabel.True]) {
        const lastDropzoneNode = createStepElements(workflow, elements, decisionSteps[DecisionStepLabel.True], trueDropzoneNode, labelColors);
        if (lastDropzoneNode) {
            joinDropzoneEdgeSourceForTrueBranch = lastDropzoneNode.id;
        }
    }

    const joinEdgeType = getJoinEdgeType(workflow.steps[node.id] as WorkflowStepOperator, OperatorType.Condition, workflow);

    const edgeFromTrueBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        joinDropzoneEdgeSourceForTrueBranch,
        joinDropzoneNode.id,
        undefined,
        "b1",
        joinEdgeType,
        { parentId: node.id, parentType: node.type,  hiddenLabel: OperatorConditionEdgeLabel.Join  } as EdgeData
    );

    //Decision Steps - false branch
    let joinDropzoneEdgeSourceForFalseBranch = falseDropzoneNode.id;
    if (decisionSteps[DecisionStepLabel.False]) {
        const lastDropzoneNode = createStepElements(workflow, elements, decisionSteps[DecisionStepLabel.False], falseDropzoneNode, labelColors);
        if (lastDropzoneNode) {
            joinDropzoneEdgeSourceForFalseBranch = lastDropzoneNode.id;
        }
    }
    const edgeFromFalseBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        joinDropzoneEdgeSourceForFalseBranch,
        joinDropzoneNode.id,
        undefined,
        "b2",
        joinEdgeType,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorConditionEdgeLabel.Join } as EdgeData
    );

    elements.push(
        node, edgeNodeToParent,
        trueDropzoneNode, edgeNodeToTrueDropzoneNode,
        falseDropzoneNode, edgeNodeToFalseDropzoneNode,
        joinDropzoneNode, edgeFromTrueBranchEndToJoinDropzone, edgeFromFalseBranchEndToJoinDropzone
    );
    return joinDropzoneNode;
}


function createOperatorLoopElements(workflow: Workflow, elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node, loopStep: string, labelColors: LabelColors) {
    let counter = 0;

    //create node
    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );

    //Loop branch
    const loopDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Normal,
        editMode: props.editMode,
        dropzoneHint: DropzoneNodeHint.InsideLoopAndBreakCompatible
    } as WorkflowNodeProps
    );
    const edgeNodeToLoopDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        loopDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorLoopEdgeLabel.Top } as EdgeData
    );
    
    //Hidden branch
    const hiddenDropzoneNode = constructNode({
            id: (props.id + "_" + counter++),
            nodeType: WorkflowNodeType.Dropzone,
            dropzoneNodeType: DropzoneNodeType.Normal,
            editMode: props.editMode,
            hidden: true,
            hiddenIcon: commonIcons.loopIcon,
        } as WorkflowNodeProps
    );

    const edgeNodeToHiddenDropzoneNode = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        hiddenDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorLoopEdgeLabel.Top } as EdgeData
    );

    //Close the condition operator for its join loop once branches are done for loop steps
    const joinDropzoneNode = constructNode({
        id: (props.id + "_" + counter++),
        nodeType: WorkflowNodeType.Dropzone,
        dropzoneNodeType: DropzoneNodeType.Join,
        editMode: props.editMode
    } as WorkflowNodeProps
    );


    //Loop branch step
    let joinDropzoneEdgeSourceForLoopBranch = loopDropzoneNode.id;
    if (loopStep != "") {
        const lastDropzoneNode = createStepElements(workflow, elements, loopStep, loopDropzoneNode, labelColors );
        if (lastDropzoneNode) {
            joinDropzoneEdgeSourceForLoopBranch = lastDropzoneNode.id;
        }
        //insert the hint for dropzones inside loop which can be compatible for break;
        markDropzoneForLoopAndBreakCompatible(loopStep, elements, workflow);
    }

    const joinEdgeType = getJoinEdgeType(workflow.steps[node.id] as WorkflowStepOperator, OperatorType.Loop, workflow);

    //Connect Joins
    const edgeFromLoopBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        joinDropzoneEdgeSourceForLoopBranch,
        joinDropzoneNode.id,
        undefined,
        "b2",
        joinEdgeType,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorLoopEdgeLabel.Join } as EdgeData
    );

    const edgeFromHiddenBranchEndToJoinDropzone = constructEdge(
        (props.id + "_" + counter++),
        hiddenDropzoneNode.id,
        joinDropzoneNode.id,
        "edgeMarker",
        "b1",
        joinEdgeType,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorLoopEdgeLabel.Join } as EdgeData
    );
    elements.push(
        node, edgeNodeToParent,
        hiddenDropzoneNode, edgeNodeToHiddenDropzoneNode,
        loopDropzoneNode, edgeNodeToLoopDropzoneNode,
        joinDropzoneNode, edgeFromLoopBranchEndToJoinDropzone, edgeFromHiddenBranchEndToJoinDropzone
    );
    return joinDropzoneNode;
}


function createOperatorParallelElements(workflow: Workflow, elements: any[], props: WorkflowNodeProps, parentDropzoneNode: Node, parallelSteps: string[], labelColors: LabelColors) {
    let counter = 0;

    //create node
    const node = constructNode(props);
    const edgeNodeToParent = constructEdge(
        (props.id + "_" + counter++),
        parentDropzoneNode.id,
        node.id
    );
    
    //parallel branch hook
    const branchDropzoneNode = constructNode({
            id: (props.id + "_" + counter++),
            nodeType: WorkflowNodeType.Dropzone,
            dropzoneNodeType: DropzoneNodeType.Normal,
            editMode: props.editMode
        } as WorkflowNodeProps
    );
    const edgeNodeToBranch = constructEdge(
        (props.id + "_" + counter++),
        node.id,
        branchDropzoneNode.id,
        undefined,
        undefined,
        WorkflowEdgeType.Normal,
        { parentId: node.id, parentType: node.type, hiddenLabel: OperatorParallelEdgeLabel.Branch } as EdgeData
    );
    //Close the parallel operator for its join loop once branches are done for parallel steps
    const joinDropzoneNode = constructNode({
            id: (props.id + "_" + counter++),
            nodeType: WorkflowNodeType.Dropzone,
            dropzoneNodeType: DropzoneNodeType.Join,
            editMode: props.editMode
        } as WorkflowNodeProps
    );
    
    elements.push(node, edgeNodeToParent, branchDropzoneNode, edgeNodeToBranch, joinDropzoneNode);
    
    const joinDropzoneEdgeSources: string[] = [];
    const stepsLength = parallelSteps.length >2 ? parallelSteps.length : 2;
    //paralel branches
    for(let i = 0; i < stepsLength; i++){
        const dropzoneNode = constructNode({
            id: (props.id + "_" + counter++),
            nodeType: WorkflowNodeType.Dropzone,
            dropzoneNodeType: DropzoneNodeType.Normal,
            editMode: props.editMode
        } as WorkflowNodeProps
        );
        const edgeBranchNodeToDropzoneNode = constructEdge(
            (props.id + "_" + counter++),
            branchDropzoneNode.id,
            dropzoneNode.id,
            undefined,
            undefined,
            WorkflowEdgeType.Normal,
            { parentId: node.id, parentType: node.type, hiddenLabel: OperatorParallelEdgeLabel.Top } as EdgeData
        );
        elements.push(dropzoneNode, edgeBranchNodeToDropzoneNode);
        const nextStepId = parallelSteps.at(i);
        if(nextStepId) {
            const lastDropzoneNode = createStepElements(workflow, elements, nextStepId, dropzoneNode, labelColors );
            if (lastDropzoneNode) {
                joinDropzoneEdgeSources.push(lastDropzoneNode.id);
            }
        }else {
            joinDropzoneEdgeSources.push(dropzoneNode.id);
        }
    }

    const joinEdgeType = getJoinEdgeType(workflow.steps[node.id] as WorkflowStepOperator, OperatorType.Parallel, workflow);

    const lenDropzoneNodes = joinDropzoneEdgeSources.length;
    const middleLenDropzoneNodes = Math.floor(lenDropzoneNodes/2);
    const areDropzonesEven = Math.floor(lenDropzoneNodes %2)  == 0 ;
    //connect branch end nodes to join
    joinDropzoneEdgeSources.forEach( (sourceId: string, index) => {
        let targetHandle = "b"
        if (index < middleLenDropzoneNodes) {
            targetHandle = "b1"
        }else {
            if (index == middleLenDropzoneNodes && !areDropzonesEven) {
                targetHandle = lenDropzoneNodes == 3 ? "b" : "b1"
            }else {
                targetHandle  = "b2"
            }
        }
        const edgeFromBranchEndToJoinDropzone = constructEdge(
            (props.id + "_" + counter++),
            sourceId,
            joinDropzoneNode.id,
            undefined,
            targetHandle,
            joinEdgeType,
            { parentId: node.id, parentType: node.type, hiddenLabel: OperatorParallelEdgeLabel.Join } as EdgeData
        );
        elements.push(edgeFromBranchEndToJoinDropzone);
    });    
    return joinDropzoneNode;
}

function createLeafElements(elements: any[]) {
    const dropzoneLeafNodes = elements.filter((node) => {
        const isDropzoneNode = isNode(node) && node.type == WorkflowNodeType.Dropzone;
        if (isDropzoneNode) {
            //find any edge for which the source is this dropzone
            const edgeToDropzoneSource = elements.find((e) => isEdge(e) && e.source == node.id)
            return edgeToDropzoneSource ? false : true;
        }
        return false;
    })

    if (!dropzoneLeafNodes) return;

    dropzoneLeafNodes.forEach((dropzoneNode) => {
        const endNode = constructNode({ nodeType: WorkflowNodeType.End } as WorkflowNodeProps);
        const edgeDropzoneToEndNode = constructEdge(
            uuidv4(),
            dropzoneNode.id,
            endNode.id,
        );
        elements.push(endNode, edgeDropzoneToEndNode);
    });
}


function constructNodePropsFromWorfkflowTrigger(workflow: Workflow) {
    const props: WorkflowNodeProps = {
        id: "trigger",
        nodeType: WorkflowNodeType.Trigger,
    } as WorkflowNodeProps;

    props.resourceId = workflow.triggerRef?.triggerID;
    const triggers = useWorkflowStepsStore.getState().triggers;
    const trigger = triggers.find((i: Trigger) => i.type == workflow.triggerRef?.triggerType) as Trigger;
    if (trigger) {
        props.resourceType = trigger.type
    }
    return props;
}

function constructNodePropsFromStep(workflow: Workflow, stepId: string, step: WorkflowStepType) {
    const props: WorkflowNodeProps = {
        id: stepId,
        nodeType: step.type,
        editMode: true,
    } as WorkflowNodeProps;

    switch (props.nodeType) {
        case WorkflowNodeType.Action: {
            props.resourceId = (step as WorkflowStepAction).actionID;
            const actions = useWorkflowStepsStore.getState().actions;
            const action = actions.find((i: Action) => i.id == props.resourceId) as Action;
            if (action) {
                props.resourceType = action.type
            }
            break;
        }
        case WorkflowNodeType.Operator: {
            props.resourceId = (step as WorkflowStepOperator).operatorID;
            const operators = useWorkflowStepsStore.getState().operators;
            const operator = operators.find((i: Operator) => i.id == props.resourceId) as Operator;
            if (operator) {
                props.resourceType = operator.type
            }
            break;
        }
    }
    return props;
}

function constructNode(props: WorkflowNodeProps): Node {
    const node: Node<WorkflowNodeProps> = {
        id: props.id ? props.id : uuidv4(),
        data: { ...props },
        position: { x: 0, y: 0 },
        type: props.nodeType,
        style: { zIndex: 0 },
    };

    return node;
}

function constructEdge(id: string, source: string, target: string, edgeMarker?: any, targetHandle?: any, type?: WorkflowEdgeType, data?: EdgeData, label?: any): Edge {
    const edge: Edge = {
        id: id,
        source: source,
        targetHandle: targetHandle,
        target: target,
        data: data,
        label: label ? label : "",
        type: type ? type :  WorkflowEdgeType.Normal , 
        style:{zIndex: 1},
        interactionWidth: 5
    };

    if (edgeMarker) {
        edge.markerStart = {
            type: MarkerType.ArrowClosed,
            orient: 'auto-start-reverse',
            width: Dimensions.dropzoneNodeActiveWidthHeight,
            height: Dimensions.dropzoneNodeActiveWidthHeight,
            color: '#FF0072',
        }
        edge.zIndex = 0
    }

    return edge;
}

function getJoinEdgeType(step: WorkflowStepOperator, resourceType: string, workflow: Workflow) {
    let edgeType = WorkflowEdgeType.Normal;
    switch(resourceType){
        case OperatorType.Condition:{
            let lCount = 0;
            let rCount = 0;
            for (const [label, dStepId] of Object.entries(step.decisionSteps)) {
                if(!dStepId) continue;
                const count = getBranchCount(dStepId, workflow);
                if(label == DecisionStepLabel.True){
                    lCount = count;
                }else{
                    rCount = count;
                }
            }
            edgeType = (lCount == rCount ? WorkflowEdgeType.Normal : WorkflowEdgeType.Join);
            break;
        }
        case OperatorType.Approval:{
            let lCount = 0;
            let rCount = 0;
            for (const [label, dStepId] of Object.entries(step.decisionSteps)) {
                if(!dStepId) continue;
                const count = getBranchCount(dStepId, workflow);
                if(label == DecisionStepLabel.True){
                    lCount = count;
                }else{
                    rCount = count;
                }
            }
            edgeType = (lCount == rCount ? WorkflowEdgeType.Normal : WorkflowEdgeType.Join);
            break;
        }
        case OperatorType.Parallel: {
            switch(step.parallelSteps.length ){
                case 0:
                    break;
                case 1:
                    edgeType = WorkflowEdgeType.Join;
                    break;
                default: {
                    let previousCount = getBranchCount(step.parallelSteps[0], workflow);
                    for(let index = 1; index < step.parallelSteps.length ; index++){
                        const bCount = getBranchCount(step.parallelSteps[index], workflow);
                        if(bCount != previousCount) {
                            edgeType = WorkflowEdgeType.Join;
                            break;
                        }
                        previousCount = bCount;
                    }
                }
            }
            break;
        }
        case OperatorType.Loop: {
            edgeType = (step.loopStep == "" ? WorkflowEdgeType.Normal : WorkflowEdgeType.Join);
            break;
        }
        default:
            break;
    }
    return edgeType;
}

function getBranchCount(stepId: string, workflow: Workflow) {
    let count = 0;
    for(let s = stepId; s != ""; s = workflow.steps[s]?.nextStep) {
        count ++;
    }
    return count;
}

function markDropzoneForLoopAndBreakCompatible(stepId: string , elements: any[], workflow: Workflow) {
    const edges = elements.filter((x: any) => isEdge(x)) as Edge[];
    const dropzoneNodes = elements.filter((x: any) => isNode(x) && WorkflowNodeType.Dropzone) as Node[];
    //insert the hint for dropzones inside loop which can be compatible for break;
    for( let current = stepId; current != "";) {
        let dropzoneNode  = undefined;
        const step = workflow.steps[current];
        
        switch(step.type){
            case WorkflowNodeType.Action:{
                const edgeFromThisNode = edges.find((x: any) => x.source == current) as Edge;
                dropzoneNode = dropzoneNodes.find( (x: any) => x.id == edgeFromThisNode.target) as Node;
                break;
            }
            case WorkflowNodeType.Operator: {
                const rtype = findStepResourceType(step.operatorID, step.type);
                switch(rtype){
                    case OperatorType.Parallel: {
                        const joinEdge = edges.find((x: any) => x.data && (x.data as EdgeData).parentId == current && 
                            (x.data as EdgeData).hiddenLabel == OperatorParallelEdgeLabel.Join) as Edge;
                        dropzoneNode = dropzoneNodes.find( (x: any) => x.id == joinEdge.target) as Node;
                        break;
                    }
                    case OperatorType.Loop: {
                        const joinEdge = edges.find((x: any) => x.data && (x.data as EdgeData).parentId == current && 
                            (x.data as EdgeData).hiddenLabel == OperatorLoopEdgeLabel.Join) as Edge;
                        dropzoneNode = dropzoneNodes.find( (x: any) => x.id == joinEdge.target) as Node;
                        break;
                    }
                    case OperatorType.Condition: {
                        const joinEdge = edges.find((x: any) => x.data && (x.data as EdgeData).parentId == current && 
                            (x.data as EdgeData).hiddenLabel == OperatorConditionEdgeLabel.Join) as Edge;
                        dropzoneNode = dropzoneNodes.find( (x: any) => x.id == joinEdge.target) as Node;
                        break;
                    }
                    case OperatorType.Approval: {
                        const joinEdge = edges.find((x: any) => x.data && (x.data as EdgeData).parentId == current && 
                            (x.data as EdgeData).hiddenLabel == OperatorApprovalEdgeLabel.Join) as Edge;
                        dropzoneNode = dropzoneNodes.find( (x: any) => x.id == joinEdge.target) as Node;
                        break;
                    }
                    default: {
                        const edgeFromThisNode = edges.find((x: any) => x.source == current) as Edge;
                        dropzoneNode = dropzoneNodes.find( (x: any) => x.id == edgeFromThisNode.target) as Node;
                        break;
                    }
                }
                break;
            }
            default:
                break;
        }
        if(dropzoneNode) {
            dropzoneNode.data.dropzoneHint = DropzoneNodeHint.InsideLoopAndBreakCompatible;
        }
        current = workflow.steps[current].nextStep;
    }
}


