import { useEffect } from "react";
import { Typography } from "antd";
import React, { PropsWithChildren, useState } from "react";
import { FieldProps } from "./common";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditorContext } from "./editorContext";
import { truncateString } from "./utils";
import { FieldLabel } from "./fieldLabel";
const { Paragraph, Text } = Typography;

/**
 * Cannot use text area with options
 */
interface TextEditorFieldProps extends FieldProps {
  value: string;
  onChange: (key: string, value: string) => void;
}

/** react function component */
export const TextEditorField = (
  props: PropsWithChildren<TextEditorFieldProps>
) => {
  useEffect(() => {
    //console.log(`path : ${props.path}`);
  }, []);

  const editorContext = React.useContext(EditorContext);
  const [charLimit, setCharLimit] = useState(512);
  const [charCount, setCharCount] = useState(0);
  const handleChange = (value: string) => {
    props.onChange(props.identifier, value);
    setCharCount(value.length);
  };

  const handleKeyPress = (event: any) => {
    if (charCount >= charLimit) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <FieldLabel fp={props} />
    
      <div>
        {editorContext.view ? (
          <Paragraph
            ellipsis={{ rows: 5, expandable: true }}
            style={{ marginBottom: "10px" }}
          >
            {props.value}
          </Paragraph>
        ) : (
          <div style={{ marginBottom: "10px", maxHeight: "300px" }}>
            <ReactQuill
              theme="snow"
              defaultValue={truncateString(props.value, charLimit)}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
          </div>
        )}
      </div>
    </div>
  );
};
