import { Button, Space, Table, Typography, notification, theme } from "antd";
import { useEffect, useState } from "react";
import { ResourceType, UserRoleDto } from "types";
import { PermissionSetEdit } from "./PermissionSetEdit";
import { RoleEdit } from "./RoleEdit";
import { UserEdit } from "./UserEdit";

import { useRbacStore } from "store";

import { UserDto } from "types";
import { getStyles } from "../../utility/styles";
import { MutationData } from "./MutationData";

import { ItemsGroup } from "components/ItemsGroup";
import { HLink } from "components/Link";
const { Text } = Typography;

export interface UserTabItemProps {
  readOnly: boolean;
}

export const UserTabItem = (props: UserTabItemProps) => {
  const users = useRbacStore((state) => state.users);
  /** We could have a sequence of mutations, where userEdit leads to role edit , leads to permission set edit etc.
   * When an item edit finishes , we need to return to the previous edit item, so we need to keep track of the sequence of edits
   * The mutation data is a stack of edits, where the last edit is the current edit
   */
  const [mutationData, setMutationData] = useState<MutationData[]>([]);
  const [editItem, setEditItem] = useState<MutationData | null>(null);

  const pushMutationData = (data: MutationData) => {
    setMutationData([...mutationData, data]);
  };

  const popMutationData = () => {
    const newMutationData = [...mutationData];
    newMutationData.pop();
    setMutationData(newMutationData);
  };

  const deleteUser = useRbacStore((state) => state.deleteUser);

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);

  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  useEffect(() => {
    if (mutationData.length) {
      const lastMutationData = mutationData[mutationData.length - 1];
      setEditItem(lastMutationData);
    } else {
      setEditItem(null);
    }
  }, [mutationData]);

  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deleteUser(id);
      }, Promise.resolve());

      notification.success({
        message: `Successfully deleted users`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting users`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    popMutationData();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
  };

  return (
    <div
      id="users-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <div
        id="users-header"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: token.margin,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontSize: token.fontSizeHeading4 }}>
            Total Users ({Object.keys(users).length})
          </Text>
          {props.readOnly && (
            <Text type="danger" style={{ fontSize: "0.75rem" }}>
              Create , edit and delete are disabled in the current context
            </Text>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            disabled={props.readOnly}
            onClick={() =>
              pushMutationData({ isAdd: true, type: ResourceType.User })
            }
          >
            Add
          </Button>
          <Button
            type="primary"
            onClick={() => onDelete(selectedRowKeys)}
            disabled={props.readOnly || !selectedRowKeys.length}
          >
            Delete
          </Button>
        </Space>
      </div>

      <div style={{ width: "100%" }}>
        <Table
          rowKey={"id"}
          key={`user-table`}
          rowSelection={props.readOnly ? undefined : rowSelection}
          columns={[
            {
              title: "Name",
              render: (_: any, record: UserDto) => {
                return (
                  <HLink
                    onClick={() => {
                      pushMutationData({
                        isAdd: false,
                        user: record,
                        type: ResourceType.User,
                      });
                    }}
                    text={`${record.firstName} ${record.lastName}`}
                  />
                );
              },
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Region",
              dataIndex: "region",
              key: "region",
            },
            {
              title: "Roles Assigned",
              dataIndex: "roles",
              key: "roles-list",
              render: (roles: UserRoleDto[]) => (
                <ItemsGroup
                  items={roles.map((x) => ({
                    id: x.id,
                    name: x.name,
                    description: x.description,
                  }))}
                ></ItemsGroup>
              ),
            },
            {
              title: "Roles Count",
              dataIndex: "roles",
              key: "roles",
              render: (_: any, record: UserDto) => record.roles?.length,
            },
          ]}
          dataSource={Object.values(users)}
          bordered={true}
          pagination={
            Object.keys(users).length <= 10 ? false : { pageSize: 10 }
          }
        />
      </div>
      {editItem && editItem.type == ResourceType.User && (
        <UserEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          user={editItem.user}
          onClose={onClose}
          onRoleAdd={async () =>
            pushMutationData({
              type: ResourceType.Role,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.Role && (
        <RoleEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          role={editItem.role}
          onClose={onClose}
          onPermissionSetAdd={async () =>
            pushMutationData({
              type: ResourceType.PermissionSet,
              isAdd: true,
            })
          }
          onUserAdd={async () =>
            pushMutationData({
              type: ResourceType.User,
              isAdd: true,
            })
          }
        />
      )}
      {editItem && editItem.type == ResourceType.PermissionSet && (
        <PermissionSetEdit
          readOnly={props.readOnly}
          isAdd={editItem.isAdd}
          pset={editItem.permissionSet}
          onClose={onClose}
        />
      )}
    </div>
  );
};
