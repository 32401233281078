export enum SpecStateType {
    StateDraft = "draft",
    StatePublished = "published",
    StatePublishPending = "publish_pending",
    StatePublishedDraft = "published_draft",
    StatePendingShareReview  = "pending_share_review",
	StatePendingDeleteReview  = "pending_delete_review",
	StateShared = "shared",
}

export const SpecStateMap: Map<SpecStateType, string> = new Map<SpecStateType, string>(
    [
        [SpecStateType.StateDraft, "Draft"],
        [SpecStateType.StatePublished, "Published"],
        [SpecStateType.StatePublishPending, "Pending Approval"],
        [SpecStateType.StatePublishedDraft, "Published Draft"],
        [SpecStateType.StatePendingShareReview, "Pending Share Review"],
        [SpecStateType.StatePendingDeleteReview, "Pending Delete Review"],
        [SpecStateType.StateShared, "Shared"]
    ]
)


export enum SpecActionType {
    ActionPublish = "publish",
    ActionUnpublish = "unpublish",
    ActionEdit = "edit",
    ActionRevert = "revert",
    ActionRestore = "restore",
    ActionDelete = "delete",
    ActionShare = "share",
    ActionShareApprove  = "share-approve",
	ActionShareReject   = "share-reject",
	ActionDeleteApprove = "delete-approve",
	ActionDeleteReject  = "delete-reject",
}

export enum SpecType {
    Action = "Action",
    Trigger = "Trigger",
    Artifact = "Artifact",
    App = "App",
    ActionProvider = "ActionProvider",
    TriggerProvider = "TriggerProvider",
    ArtifactProvider = "ArtifactProvider",
    Workflow = "Workflow",
    Unknown = "Unknown"
}

export enum SpecResourceType {
    Actions = "actions",
    Triggers = "triggers",
    Artifacts = "artifacts",
    Apps = "apps",
    ActionProviders = "action-providers",
    TriggerProviders = "trigger-providers",
    ArtifactProviders = "artifact-providers",
    Workflows = "workflows",
    Unknown = "Unknown"
}

export const SpecResourceMap: Map<SpecResourceType, SpecType> = new Map<SpecResourceType, SpecType>(
    [
        [SpecResourceType.Actions, SpecType.Action],
        [SpecResourceType.Triggers, SpecType.Trigger],
        [SpecResourceType.Artifacts, SpecType.Artifact],
        [SpecResourceType.Apps, SpecType.App],
        [SpecResourceType.ActionProviders, SpecType.ActionProvider],
        [SpecResourceType.TriggerProviders, SpecType.TriggerProvider],
        [SpecResourceType.ArtifactProviders, SpecType.ArtifactProvider],
        [SpecResourceType.Workflows, SpecType.Workflow],
        [SpecResourceType.Unknown, SpecType.Unknown],
    ]
)

export const ResourceSpecMap: Map<SpecType, SpecResourceType > = new Map<SpecType, SpecResourceType>(
    [
        [SpecType.Action, SpecResourceType.Actions ],
        [SpecType.Trigger, SpecResourceType.Triggers],
        [SpecType.Artifact, SpecResourceType.Artifacts],
        [SpecType.App, SpecResourceType.Apps],
        [SpecType.ActionProvider, SpecResourceType.ActionProviders],
        [SpecType.TriggerProvider, SpecResourceType.TriggerProviders],
        [SpecType.ArtifactProvider, SpecResourceType.ArtifactProviders],
        [SpecType.Workflow, SpecResourceType.Workflows],
        [SpecType.Unknown, SpecResourceType.Unknown],
    ]
)

export enum SpecNameValidationState {
    Unknown = "unknown",
    Valid = "valid",
    InValid = "in_valid",
    InProgress = "in_progress"
}


//accessInfo
export interface AccessInfo {
    userID: string;
    tenantID: string;
    permissions: string[];
}

// SpecResourcePermissions ResourceID -> permissions mapping
export type SpecResourcePermissions = Record<string, string[]>