import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { ParameterStoreParameter, ParameterStoreStore } from "types";
import { createParameterApi, deleteParameterApi, getParametersApi, updateParameterApi } from "api";
import { useEdgeGlobalStore } from "./edge";


export const useParameterStoreStore = create<ParameterStoreStore>()(
    devtools((set, get) => ({
        parameters: [] as ParameterStoreParameter[],

        getParameters: async (edgeId: string, paramStoreId: string, pageNumber?: number, pageSize?: number) => {
            try {
                const edgeUrl = await useEdgeGlobalStore.getState().getEdgeEndpoint(edgeId);
                const parameters = await getParametersApi(edgeUrl, paramStoreId, pageNumber, pageSize);
                
                set((state) => ({
                    ...state,
                    parameters: [...parameters]
                }));
            } catch (error: any) {
                console.error(error);
                throw new Error(error);
            }
        },

        createParameter: async (edgeId: string, paramStoreId: string, param: ParameterStoreParameter) => {
            try {
                const edgeUrl = await useEdgeGlobalStore.getState().getEdgeEndpoint(edgeId);
                const parameter = await createParameterApi(edgeUrl, paramStoreId, param);
                 
                set((state) => ({
                    parameters: [...state.parameters, parameter]
                }));
            } catch (error: any) {
                console.error(error);
                throw new Error(error);
            }
        },

        updateParameter: async (edgeId: string, paramStoreId: string, name: string, parameter: ParameterStoreParameter) => {
            try {
                const edgeUrl = await useEdgeGlobalStore.getState().getEdgeEndpoint(edgeId);
                await updateParameterApi(edgeUrl, paramStoreId, name, parameter);
            
                set((state) => {
                    const parameters = get().parameters;
                    const currentItemIndex = parameters.findIndex((p) => p.name == name);
                    if (currentItemIndex != -1) {
                      const updatedItem = {...parameters[currentItemIndex], ...parameter};
                      parameters[currentItemIndex] = updatedItem;
                    }
                    return {
                        ...state,
                        parameters: [...parameters],
                    }
                });
            } catch (error: any) {
                console.error(error);
                throw new Error(error);
            }
        },

        deleteParameter: async (edgeId: string, paramStoreId: string, name: string) => {
            try {
                const edgeUrl = await useEdgeGlobalStore.getState().getEdgeEndpoint(edgeId);
                await deleteParameterApi(edgeUrl, paramStoreId, name);
                 
                set((state) => {
                    const parameters = get().parameters;
                    const currentItemIndex = parameters.findIndex((p) => p.name == name);
                    if (currentItemIndex != -1) {
                        parameters.splice(currentItemIndex, 1);
                    }
                    return {
                        ...state,
                        parameters: [...parameters],
                    }
                });

                set((state) => ({
                    parameters: [...state.parameters]
                }));
            } catch (error: any) {
                console.error(error);
                throw new Error(error);
            }
        },
    }),
    { name: "ParemeterStoreStore" })
  );