import { FC, useEffect, useMemo, useRef, useState } from "react";
import { AutoComplete, Modal, Input, theme } from "antd";
import type { InputRef } from 'antd';

import ReactQuill from "react-quill";
import Quill from "quill"
import "react-quill/dist/quill.snow.css";

import { SuggestionsInputProps, TextType } from "types";
import { getRegEx } from "./tree";
import { DefaultOptionType } from "antd/es/select";
import { getSuggestionLabelWithIcon } from "./icons";
import { TextWithIcon } from "components/TextWithIcon";
import { workflowIcons } from "assets/icons";
import SearchableTree from "components/SearchableTree";
import { useSettingsStore } from "store";
import { TextAreaRef } from "antd/lib/input/TextArea";

interface CustomOptionType extends DefaultOptionType {
  currentText?: string;
  cursorPosition?: number; 
  isTreeSelect?: boolean;
}

export const SuggestionsInput: FC<SuggestionsInputProps> = ({
  editMode,
  name,
  suggestionsTree,
  allowClear,
  textType,
  setValue,
  onChange,
  ...restProps
}) => {
  const { token } = theme.useToken();
  const TreeLabel = "Select from tree...";
  const [options, setOptions] = useState<CustomOptionType[] | undefined>([]);
  const [showTree, setShowTree] = useState<boolean>(false);
  const [treeValueOption, setTreeValueOption] = useState<CustomOptionType>();
  const inputRef = useRef<InputRef>(null);
  const textAreaRef = useRef<TextAreaRef>(null);
  const [reactQuillRef, setReactQuillRef] = useState<ReactQuill|undefined>(undefined);
  const [editor, setEditor] = useState<Quill|undefined>(undefined);
  const [unprivilegedEditor, setUnprivilegedEditor] = useState<any|undefined>(undefined);
  const [richTextHover, setRichTextHover] = useState(false);
  const quillRef = useRef<HTMLDivElement | null>(null);
  const lightMode = useSettingsStore((state) => state.lightMode);
  const [cursorPosition, setCursorPosition] = useState<{start: number, end: number}>();

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current;

      const container = editor?.querySelector('.ql-container') as HTMLElement;
      if (container) {
        container.style.borderColor = richTextHover ?  token.colorPrimaryHover : token.colorBorder;
      }

      const toolbar = editor?.querySelector('.ql-toolbar') as HTMLElement;
      if (toolbar) {
        toolbar.style.backgroundColor = token.colorPrimaryBg;
        toolbar.style.borderColor = richTextHover ?  token.colorPrimaryHover : token.colorBorder;
        toolbar.style.borderTopLeftRadius = "5px";
        toolbar.style.borderTopRightRadius = "5px";
      }

      const editorArea = editor?.querySelector('.ql-editor') as HTMLElement;
      if (editorArea) {
        editorArea.style.backgroundColor = token.colorBgContainer;
      }
    }
  }, [quillRef, lightMode, richTextHover]);

  const suggestions = useMemo(() => {
    const suggestionList: Map<string, CustomOptionType[]> = new Map<string, CustomOptionType[]>([]);
    const buildSuggestionsList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { regex, label, value } = node;
        let keys = suggestionList.get(regex)
        if (!keys) {
          keys = [];
        }
        keys.push({label: label, value: value});
        suggestionList.set(regex, keys);
        if (node.children) {
          buildSuggestionsList(node.children);
        }
      }
    };
    suggestionsTree && buildSuggestionsList(suggestionsTree);
    return suggestionList;
  }, [suggestionsTree]);

  const buildFirstLevelSuggestions = (value: string, currentPosition: number) => {
    if (suggestions) {
      const opts = suggestions.get(getRegEx())?.map((str) => ({
        label: getSuggestionLabelWithIcon(str.label),
        value: value.substring(0, currentPosition-2) + str.value,
        cursorPosition: currentPosition,
        currentText: value,
      })) as CustomOptionType[];
      if (suggestionsTree) {
        opts?.push({
          label: TreeLabel,
          isTreeSelect: true,
          value: value, 
          cursorPosition: currentPosition,
          currentText: value,
        })
      }
      setOptions(opts);
    }
  };

  /* useEffect(() => {
    buildFirstLevelSuggestions();
  }, [suggestions]); */

  const showSuggestions = (value: string, currentPosition: number) => {
    const preCursorValue = value.substring(0, currentPosition);
    if (preCursorValue.endsWith("$.") || preCursorValue.endsWith("$.\n")) {
      buildFirstLevelSuggestions(value, currentPosition);
    } else {
      let opts: CustomOptionType[] = [];
      suggestions?.forEach((keys, regex) => {
        const re = new RegExp(regex);
        if (preCursorValue.match(re)) {
          opts = keys.map((key) => ({
            label: getSuggestionLabelWithIcon(key.label),
            value: preCursorValue.replace(re, key.value as string),
            cursorPosition: currentPosition,
            currentText: value,
          }));
        }
      });
      setOptions(opts);
    }
  };
  
  const onSelect = (option: CustomOptionType) => {
    const value = option.value as string;
    if (value?.endsWith("}}") || value?.endsWith("}}\n")){
      setOptions([]);
    }
    if (option.isTreeSelect) {
      setShowTree(true);
      if (textType == TextType.Rich) {
        onChange?.(unprivilegedEditor?.getHTML());
      }
      setTreeValueOption(option);
    } else {
      const currentText = option.currentText as string;
      const position = option?.cursorPosition as number;
      const changedValue = value + currentText.substring(position);
      const changedPosition = value.length;
      if (textType == TextType.Rich) {
        editor?.setText(changedValue);
        editor?.setSelection(changedPosition, 0);
        onChange?.(unprivilegedEditor?.getHTML());
      } else {
        onChange?.(changedValue);
        setCursorPosition({...cursorPosition, start: changedPosition, end: changedPosition});
      }
      setValue && setValue(changedValue);
    }
  };

  useEffect(() => {
    if (inputRef?.current?.input) {
      inputRef.current.input?.focus();
      if (cursorPosition) {
        inputRef.current.input.setSelectionRange(cursorPosition.start, cursorPosition.end);
      } else {
        inputRef.current.focus({ cursor: 'end' });
      }
    }
    if (textAreaRef?.current?.resizableTextArea?.textArea) {
      textAreaRef.current.resizableTextArea.textArea.focus();
      if (cursorPosition) {
        textAreaRef.current.resizableTextArea.textArea.setSelectionRange(cursorPosition.start, cursorPosition.end);
      } else {
        textAreaRef.current.focus({ cursor: 'end' });
      }
    }
  }, [cursorPosition]);

  const onTreeNodeSelect = (node: any) => {
    setShowTree(false);
    const value = treeValueOption?.value as string;
    const insertText = node.value as string;
    const position = treeValueOption?.cursorPosition as number;
    const changedValue = value.substring(0, position-2) + insertText + value.substring(position);
    const changedPosition = position-2+insertText.length;
    if (textType == TextType.Rich) {
      editor?.setText(changedValue);
      editor?.setSelection(changedPosition, 0);
      onChange?.(unprivilegedEditor?.getHTML() as string);
    } else {
      onChange?.(changedValue);
      setCursorPosition({...cursorPosition, start: changedPosition, end: changedPosition});
    }
  };

  useEffect(() => {
    const e = reactQuillRef?.getEditor() as Quill;
    const unprivilegedEditor = reactQuillRef?.makeUnprivilegedEditor(e);
    setEditor(e);
    setUnprivilegedEditor(unprivilegedEditor);
  }, [reactQuillRef]);

  const toolbar = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{'color': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
  ];
  
  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'color',
    'list', 'bullet', 'indent',
    'link'
  ];

  return (
    <>
      <AutoComplete
        key={name}
        disabled={!editMode}
        options={options}
        onSearch={(text) => {
          let currentPosition = inputRef?.current?.input?.selectionStart;
          if (!currentPosition) {
            currentPosition = textAreaRef?.current?.resizableTextArea?.textArea?.selectionStart;
          }
          showSuggestions(text, currentPosition as number);
        }}
        onSelect={(value: string, option: CustomOptionType) => {
          onSelect(option);
        }}
        onChange={(value) => onChange?.(value)}
        style={{ "display": "inline"}}
        {...restProps}
      >
        {textType == TextType.Rich ?
          <div
            ref={quillRef}
            onMouseEnter={() => editMode && setRichTextHover(true)}
            onMouseLeave={() => editMode && setRichTextHover(false)}
          >
            <ReactQuill
              ref={(el) => el && setReactQuillRef(el)}
              key={name}
              theme={"snow"}
              modules={{
                toolbar: toolbar,
                clipboard: {matchVisual: false}
              }}
              formats={formats}
              readOnly={!editMode}
              placeholder={`Enter ${name} or $. for previous steps outputs suggestions`}
              onChange={(value) =>  {
                const text = unprivilegedEditor?.getText() as string;
                const currentPosition = unprivilegedEditor?.getSelection()?.index;
                text && currentPosition && showSuggestions(text, currentPosition);
                onChange?.(value);
              }}
              {...restProps}
            />
          </div>
          :
          textType == TextType.Text ?
            <Input.TextArea 
              ref={textAreaRef}
              placeholder={`Enter ${name} or $. for previous steps outputs suggestions`}
              showCount
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
            :
            <Input
              ref={inputRef}
              placeholder={`Enter ${name} or $. for suggestions`}
              allowClear={allowClear?allowClear:undefined}
            />
        }
      </AutoComplete>
      <Modal 
        title={
          <TextWithIcon 
            icon={workflowIcons.parametersShortIcon}
            iconSize={18}
            text={"Tree"}
          />
        }
        open={showTree} 
        onCancel={() => setShowTree(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <SearchableTree
          placeholder="search parameters"
          treeData={suggestionsTree ? suggestionsTree : []}
          onSelect={onTreeNodeSelect}
        />
      </Modal>
    </>
  );
};