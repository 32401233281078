


export enum ActivityGroup {
  Edge = "edge",
  App = "app",
  Workflow  = "workflow",
  Search = "search",
  Action = "action",
  Trigger = "trigger",
  Artifact = "artifact",
  Rbac = "rbac",
  Case = "case"
}

export const ActivityGroupMap: Map<ActivityGroup, string> = new Map<ActivityGroup, string>(
  [
      [ActivityGroup.Edge, "Edge"],
      [ActivityGroup.App, "App"],
      [ActivityGroup.Workflow, "Workflow"],
      [ActivityGroup.Search, "Search"],
      [ActivityGroup.Action, "Actions"],
      [ActivityGroup.Trigger, "Triggers"],
      [ActivityGroup.Artifact, "Artifacts"],
      [ActivityGroup.Rbac, "Users And Roles"],
      [ActivityGroup.Case, "Case Management"]
  ]
)

export interface Activity {
  id: string;
  message: string;
  userID: string;
  localTime: string;
  group: ActivityGroup;
}
