import React, { PropsWithChildren } from "react";
import { Card, Typography, Button, Collapse } from "antd";
import { DeleteTwoTone } from "@ant-design/icons";
import { useEffect } from "react";
import { EditorProps } from "./common";
import { EditorContext } from "./editorContext";
import { translateEnum } from "utility/developer/enumTranslator";
const { Text } = Typography;

const { Panel } = Collapse;
import styles from "./EntityEditor.module.scss";

/** Function component taking props with child templatized with EntityCardProps */
export const EntityPanel = (props: PropsWithChildren<EditorProps>) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const onChange = async (item: { [k: string]: any }) => {
    props.onSave(item);
  };

  return (
    <Collapse
      defaultActiveKey={`${props.editable.type}-${props.name}`}
      ghost={true}
    >
      <Panel
        header={
          <>
            <Text strong>
              {translateEnum(props.editable.type)}
              {" : "}
            </Text>
            <Text>{props.editable.context?.displayName || props.name}</Text>
          </>
        }
        key={`${props.editable.type}-${props.name}`}
        extra={
          <Button
            ghost
            shape="circle"
            size="small"
            className={styles.deleteCondButton}
            icon={<DeleteTwoTone className={styles.deleteCondIcon} />}
            onClick={(e: any) => {
              e.stopPropagation();
              props.onDelete(props.editable);
            }}
          />
        }
      >
        <EditorContext.Provider
          value={{
            view: props.view,
            onChange: onChange,
            onCancel: props.onCancel,
            viewFactory: props.viewFactory,
          }}
        >
          {props.children}
        </EditorContext.Provider>
      </Panel>
    </Collapse>
  );
};
