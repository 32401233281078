import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Space } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./ForgotPassword.module.scss";

import { signupResetPasswordApi } from "api/auth";
import { notification } from "utility/notification";

const ForgotPassword: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSubmit = async (value: any) => {
    try {
      const response = await signupResetPasswordApi(value.email);
      if (response.status == 200) navigate("/email-otp-verification");
      else if (response.status == 429)
        notification.error({
          message: "Too many requests. Please try again later",
        });
      else notification.error({ message: "Error sending password reset link" });
    } catch (error) {
      notification.error({ message: "Error sending password reset link" });
    }
  };

  return (
    <SignInSignUpWrapper>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <LockOutlined />
        </div>

        <h2 className={styles.verificationTitle}>Reset Password</h2>
        <p className={styles.verificationDescription}>
          Enter email id associated with your account
        </p>

        <Form
          form={form}
          name="forgotPasswordForm"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Email is required!" },
              () => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(value)) {
                    return Promise.reject("Enter a valid email");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              className="authInput"
              size="large"
              prefix={<MailOutlined />}
              placeholder="Enter your email address"
            />
          </Form.Item>
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            className="authbtn_100"
            size="large"
            type="primary"
            onClick={() => navigate("/signin")}
          >
            Back
          </Button>
          <Button
            className="authbtn_100"
            type="primary"
            size="large"
            onClick={() =>
              form
                .validateFields()
                .then(onSubmit)
                .catch((err) => {
                  console.log(err);
                })
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </SignInSignUpWrapper>
  );
};

export default ForgotPassword;
