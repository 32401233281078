import { Layout, Table, Typography } from "antd";
import { FC, useEffect, useState } from "react";

import { ColumnType } from "antd/es/table";
import { putTestApi } from "api";
import { Fader } from "components/Fader";
import { useOrganizationStore, useSettingsStore } from "store";
import { notification } from 'utility/notification';

const { Text, Title } = Typography;
interface IDataSource {
  key: string;
  name: string;
  value: string;
}

const ServiceProvider: FC = () => {
  const entityMapper = {
    acsUrl: "Assertion Consumer Service URL",
    entityId: "Entity ID (Audience)",
    x509: "X.509 Certificate",
  };
  const getSamlSpSettings = useSettingsStore(
    (state) => state.getSamlSpSettings
  );
  const samlSpSettings = useSettingsStore((state) => state.samlSpSettings);

  const context = useOrganizationStore((state) => state.context);

  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [columns, setColumns] = useState<ColumnType<IDataSource>[]>([]);

  useEffect(() => {
    getSamlSpSettings();
  }, [context]);

  useEffect(() => {
    const ds = samlSpSettings
      ? Object.entries(samlSpSettings).map(([key, value]) => ({
          key,
          name: key,
          value,
        }))
      : [];
    setDataSource(ds);
    setColumns([
      {
        title: "Name",
        dataIndex: "key",
        key: "name",
        render: (key: string, record: IDataSource) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text>{entityMapper[key as keyof typeof entityMapper]}</Text>
              <Fader text={record.value} onClickText="copied!" />
            </div>
          );
        },
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (value: string) => {
          return <Text style={{ whiteSpace: "pre-wrap" }}>{value}</Text>;
        },
      },
    ]);
  }, [samlSpSettings]);

  const testToken = async () => {
    try {
      await putTestApi();
      notification.info({
        message: "Test Token",
        description: "Test Token",
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Test Token",
        duration: 5,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Title level={3}> </Title>
      <Table dataSource={dataSource} columns={columns}></Table>
      {/* <Button type="primary" onClick={testToken}>
            Test Token
          </Button> */}
    </div>
  );
};

export default ServiceProvider;
