import { companyIcons, edgeIcons } from "assets/icons";
import { EdgeInfraType } from "types";

export function getEdgeLogo(edgeInfraType?: EdgeInfraType, mode?: boolean) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
      return mode ? companyIcons.awsLogoSvg : companyIcons.awsDarkLogoSvg;
    case EdgeInfraType.Azure:
      return mode ? companyIcons.azureLogoSvg : companyIcons.azureDarkLogoSvg;
    case EdgeInfraType.Gcp:
      return mode
        ? companyIcons.googleCloudLogoSvg
        : companyIcons.googleCloudDarkLogoSvg;
    case EdgeInfraType.Oci:
      return mode ? companyIcons.oracleLogoSvg : companyIcons.oracleDarkLogoSvg;
    case EdgeInfraType.OnPrem:
      return mode ? edgeIcons.onPremiseLogoSvg : edgeIcons.onPremiseDarkModeLogoSvg;
    default:
      return edgeIcons.multiCloudLogoSvg;
  }
}

export function getEdgeShortLogo(edgeInfraType?: EdgeInfraType) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
      return companyIcons.awsAppEdgeIcon;
    case EdgeInfraType.Azure:
      return companyIcons.azureAppEdgeIcon;
    case EdgeInfraType.Gcp:
      return companyIcons.googleCloudAppEdgeIcon;
    case EdgeInfraType.Oci:
      return companyIcons.oracleAppEdgeIcon;
    case EdgeInfraType.OnPrem:
      return companyIcons.onpremiseAppEdgeIcon;
    default:
      return companyIcons.multiCloudAppEdgeIcon;
  }
}

export function isEdgeTypeCloud(edgeInfraType: EdgeInfraType) {
  switch (edgeInfraType) {
    case EdgeInfraType.Amazon:
    case EdgeInfraType.Azure:
    case EdgeInfraType.Gcp:
    case EdgeInfraType.Oci:
      return true;
    default:
      return false;
  }
}
