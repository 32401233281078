import { FC, useState } from "react";
import { Space, Tag, theme } from "antd";

import { SearchableTagsProps, Tag as Label } from "types";
import SearchInput from "components/SearchInput";
import { getTagColor } from "utility";


const SearchableTags: FC<SearchableTagsProps> = ({
  tags,
  defaultTag,
  selectedTags,
  onSelect,
}) => {
  const { token } = theme.useToken();
  const [tagFilter, setTagFilter] = useState("");
  const [tagHoverId, setTagHoverId] = useState("");

  return (
    <>
      <div>
        <SearchInput
          placeholder={"Search tags..."}
          onFilter={(e) => setTagFilter(e.target.value)}
        />
      </div>
      <Space wrap style={{ marginTop: token.marginXS }}>
        {tags?.filter((tag) => tag.value.indexOf(tagFilter) !== -1).map((tag) => (   
          <div  
            key={tag.id}
            onClick={(e) => {
              onSelect?.(tag);
            }}
            style={{ cursor: defaultTag && defaultTag == tag.id ? "default" : "pointer" }}
            onMouseOver={() => setTagHoverId(tag.id)}
            onMouseOut={() => setTagHoverId("")}
          >
            <Tag 
              color={selectedTags.includes(tag.id) ? token.colorPrimary : tagHoverId == tag.id ? token.colorPrimaryBorderHover : getTagColor(tag.id)}
            >
              {tag.value}
            </Tag>
          </div>
        ))}
      </Space>
    </>
  );
};

export default SearchableTags;