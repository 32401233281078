import React, { useState } from "react";
import { Input, List, Button, Typography, Space, Radio } from "antd";
import { DeveloperItem } from "types";
const { Text } = Typography;
/** A function component that takes in developer items array , and displays them in a list.
 */

export interface SearchableCollectionProps {
  items: DeveloperItem[];
  current: DeveloperItem;
  onSelect: (item: DeveloperItem) => Promise<void>;
}

export const SearchableCollection = (props: SearchableCollectionProps) => {
  const [searchedItems, setSearchedItems] = useState(props.items);

  const onSearch = (value: string) => {
    const filteredItems = props.items.filter((item) => {
      return item.context?.displayName?.includes(value);
    });
    console.log(filteredItems);
    setSearchedItems(filteredItems);
  };

  const [selected, setSelected] = useState(props.current);

  return (
    <Space direction="vertical" style={{ display: "flex", marginTop: "10px" }}>
      <Input
        placeholder="Search"
        onChange={(e) => onSearch(e.target.value)}
        allowClear={true}
      />
      <Radio.Group
        onChange={(e) =>
          setSelected(
            props.items.find((x) => x.id == e.target.value) || props.current
          )
        }
        style={{ width: "100%" }}
      >
        <List
          rowKey={(item) => item.id}
          size="small"
          bordered
          dataSource={searchedItems}
          renderItem={(item) => (
            <List.Item>
              <Space>
                <Radio value={item.id} />
                <Text>{item.context?.displayName}</Text>
              </Space>
            </List.Item>
          )}
        />
      </Radio.Group>
      <Button type="primary" block onClick={() => props.onSelect(selected)}>
        Select
      </Button>
    </Space>
  );
};
