import { create } from "zustand"
import { devtools } from "zustand/middleware";

import {
  Approval,
  ApprovalStore,
} from "types";

import {
  getApprovalsApi,
  getApprovalApi
} from "api";

export const useApprovalStore = create<ApprovalStore>()(
  devtools((set, get) => ({
    totalApprovalsCount: 0,
    approvals: [],

    getApprovals: async (pageNumber, pageSize, filters, sorters) => {
      try {
        const [approvals, totalCount] = await getApprovalsApi(pageNumber, pageSize, filters, sorters);
        set((state) => ({
          ...state,
          approvals: [...approvals],
          totalApprovalsCount: totalCount,
        }));
        return [approvals, totalCount];
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearApprovals: () => {
      set((state) => ({
        ...state,
        approvals: [],
        totalAppsCount: 0,
      }));
    },

    getApproval: async (approvalId: string, refresh?: boolean) => {
      try {
        let approval: Approval= {} as Approval;
        const index = get().approvals.findIndex((x) =>  x.id == approvalId);
        if (index >= 0) {
          approval = get().approvals[index]
        }
        
        if (index == -1 || refresh) {
          approval =  await getApprovalApi(approvalId);
        } 
  
        set((state) => {
          index == -1 ? state.approvals = [...state.approvals, approval] : state.approvals[index] = approval ;
          return {...state}
        });
        return approval
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

  }),
    { name: "ApprovalStore" }
  )
);