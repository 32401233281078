import { Approval } from "types";
import { axiosInterceptor } from "./axios";
import { approvalsApiPath, jsonApiDeserializer } from "./constant";

export const getApprovalsApi = async (
  pageNumber?: number,
  pageSize?: number,
  filters?: string[],
  sorter?: string[]) : Promise<[Approval[], number]> => { 

  let url = `${approvalsApiPath}?`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`


  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }
  
  const response = await axiosInterceptor("get", url);
  const approvals = jsonApiDeserializer.deserialize(response.data) as Approval[];
  return [approvals, response?.data?.meta?.totalCount];
};

export const getApprovalApi = async (approvalId: string) : Promise<Approval> => {
  const url = `${approvalsApiPath}/${approvalId}`
  const response = await axiosInterceptor("get", url);
  return jsonApiDeserializer.deserialize(response.data)  as Approval;
};

export const approvalActionApi = async (approvalUrl: string, reason?: string): Promise<number> => {
  let url = approvalUrl
  if (reason) {
    url = `${approvalUrl}&r=${reason}`
  }
  const response = await axiosInterceptor("get", url);
  return response.status;
};
