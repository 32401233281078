import { FC, useEffect } from "react";

import { useMetricStore } from "store";
import PageWrapper from "../../components/PageWrapper";

import { KpiPageHighlightGrid } from "components/Metrics/KpiPageHighlightGrid";
import { MetricLayoutIdentifier } from "types";
import { useParams } from "react-router-dom";
import { CaseMgmtCases } from "./Cases";
import { CaseMgmtCase } from "./Case";

export const CaseMgmt: FC = () => {
  const { caseId: caseId } = useParams();

  const {
    metrics,
    fetchMetricsForLayout,
  } = useMetricStore((state) => ({
    metrics: state.metrics,
    fetchMetricsForLayout: state.fetchMetricsForLayout,
  }));

  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutCases);
    };
    asyncUseEffect();
  }, []);
        
  return (
    <>
      <PageWrapper
        highlights={
          <KpiPageHighlightGrid key="cases" metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutCases)} />
        }
        content={
          caseId 
            ?
            <CaseMgmtCase caseId={caseId} />
            :
            <CaseMgmtCases />
        }
      />
    </>
  );
};

export default CaseMgmt;