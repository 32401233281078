import { CSSProperties, FC, useEffect, useState,  } from "react";
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";

import {
  Tooltip,
  Button,
  theme,
  Form,
  Space,
} from "antd";

import {
  approvalActionApi,
} from "api";

import {  Approval, ApprovalActionProps, ApprovalStatus, Approver } from "types";
import { getUserDetailsFromJWT } from "utility";
import { notification } from 'utility/notification';

import Modal from "../Modal";
import TextArea from "antd/es/input/TextArea";
import { useApprovalStore } from "store/approval";

const ApprovalAction: FC<ApprovalActionProps> = ({
  approvalId,
  displayName,
  buttonProperties,
  isTooltipButton,
  toolTipPlacement,
  onApproval,
  showApprovalPending,
  showApprovalCancel,
  onCancel
}) => {
  const { token } = theme.useToken();
  const [approval, setApproval] = useState<Approval>({} as Approval);
  const [myApprover, setMyApprover] = useState<Approver>();
  const [userId, setUserId] = useState<string>("");

  const [rejectApprovalModal, setRejectApprovalModal] = useState(false);
  const [approveApprovalModal, setApproveApprovalModal] = useState(false);
  const antdButtonSize = "small";
  const [form] = Form.useForm();

  const defaultButtonProperties: CSSProperties = {
    color: token.colorPrimary,
    borderColor: token.colorBorder
  }
  const defaultTooltipPlacement =  "top";

  const getApproval = useApprovalStore((state) => state.getApproval);

  useEffect(() => {
    const asyncUseEffect = async () => {
      if (approvalId && approvalId != "") {
        const approval = await getApproval(approvalId);
        setApproval(approval);
        const approver = approval?.approvers?.find((approver) => approver.isMyApproval);
        setMyApprover(approver);
        const details = getUserDetailsFromJWT();
        setUserId(details.tenantId);
        console.log("userId",userId, approval.userID);
      } 
    };
    asyncUseEffect();
  }, [approvalId]);

  const onApproveAction = async () => {
    if (myApprover?.approveUrl) {
      try {
        setApproveApprovalModal(false);
        const status = await approvalActionApi(myApprover.approveUrl);
        notification.success({
          message: `Approval "${displayName}" successfully approved`,
          duration: 6,
        });
        await getApproval(approvalId as string, true);
        onApproval && onApproval();
      } catch (error) {
        console.log(error);
        notification.error({
          message: `Approval "${displayName}" failed to approve`,
          duration: 6,
        });
      }
    }
  }

  const onRejectAction = async (reason: string) => {
    if (myApprover?.rejectUrl) {
      try {
        setRejectApprovalModal(false);
        const status = await approvalActionApi(myApprover.rejectUrl, reason);
        notification.success({
          message: `Approval "${displayName}" successfully rejected`,
          duration: 6,
        });
        await getApproval(approvalId as string, true);
        onApproval && onApproval();
      } catch (error) {
        console.log(error);
        notification.error({
          message: `Approval "${displayName}" failed to reject`,
          duration: 6,
        });
      }
    }
  }

  return (
    <>
    {approval?.status == ApprovalStatus.ApprovalStatusPending
      ?
      myApprover?.response?.approvalStatus == ApprovalStatus.ApprovalStatusPending
        &&
        <Space>
          {isTooltipButton 
            ?
              <Space size={0}>
                <Tooltip key="approve" title="Approve" placement={toolTipPlacement ? toolTipPlacement : defaultTooltipPlacement} color={token.colorPrimaryHover}>
                  <Button 
                    type="default" 
                    ghost
                    size={antdButtonSize}
                    icon={<CheckSquareOutlined style={buttonProperties ? buttonProperties : defaultButtonProperties}/>}
                    onClick={(event:any) => {
                      event.stopPropagation();
                      setApproveApprovalModal(true)
                    }}
                  />
                </Tooltip>
                <Tooltip key="reject" title="Reject" placement={toolTipPlacement ? toolTipPlacement : defaultTooltipPlacement} color={token.colorPrimaryHover}>
                  <Button 
                      type="default" 
                      ghost
                      size={antdButtonSize}
                      icon={<CloseSquareOutlined style={buttonProperties ? buttonProperties : defaultButtonProperties}/>}
                      onClick={(event:any) => {
                        event.stopPropagation();
                        setRejectApprovalModal(true)
                      }}
                  />
                </Tooltip>
              </Space>
            :
            <Space>
              <Button 
                type="primary" 
                onClick={(event:any) => {
                  event.stopPropagation();
                  setApproveApprovalModal(true)
                }}
              >Approve</Button>
              <Button 
                  type="primary" 
                  onClick={(event:any) => {
                    event.stopPropagation();
                    setRejectApprovalModal(true)
                  }}
              >Reject</Button>
            </Space>
          }
          {approveApprovalModal && (
            <Modal
              title="Approve"
              onClose={() => {
                setApproveApprovalModal(false);
              }}
              open={approveApprovalModal}
              onSubmit={() => onApproveAction()}
            >
              <p>{`Are you sure you want to approve  "${displayName}"  ? `}</p>
            </Modal>
          )}
          {rejectApprovalModal && (
            <Modal
              title="Reject Approval"
              onClose={() => {
                setRejectApprovalModal(false);
              }}
              open={rejectApprovalModal}
              onSubmit={() => 
                form
                  .validateFields()
                  .then((values: any) =>{
                    onRejectAction(values.reason as string)
                  })
                  .catch((err) => {
                    console.log(err);
                  })
              }
            >
              <Form
                form={form} 
                layout="vertical" 
                name="rejectApprovalForm" 
                autoComplete="off"
              >
                <Form.Item  name="reason" label="Reasons" rules={[{ required: true, message: "Rejection reasons are required!" }]}>
                  <TextArea placeholder="Enter rejection reasons" showCount maxLength={100}/>
                </Form.Item>
              </Form>
            </Modal>
          )}
          {
            approval.userID.startsWith(userId)
            ?
            showApprovalCancel &&
              <Button
                type="primary"
                icon={<FieldTimeOutlined />}
                onClick={onCancel}
              >
                Cancel Approval
              </Button> 
            :
            showApprovalPending &&
              <Button
                type="primary"
                ghost
                icon={<FieldTimeOutlined />}
                style={{ cursor: "default" }}
              >
                Pending Approval
              </Button>
          }
        </Space>
      :
      <></>
    }
    </>
  );
};

export default ApprovalAction;
