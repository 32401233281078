import {
  Button,
  Descriptions,
  Space,
  Spin,
  Typography,
  theme,
  Image,
  Tag,
  Badge,
  Modal as AntModal,
  Popover,
  Card,
  Tooltip,
  List
} from "antd";
import { DoubleRightOutlined, CloseOutlined, DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { FC, useEffect, useState } from "react";

import { ComposableMap, Geographies, Geography, ZoomableGroup, Marker } from "react-simple-maps"

import { SvgIcon } from "components/SvgIcon";
import { useEdgeStore, useOrganizationStore, useSettingsStore } from "store";
import { getEdgeLogo, getEdgeShortLogo } from "../../../utility/edge";
import { notification } from 'utility/notification';

import { AppSubscription, Edge, EdgeTagsMap, EdgeInfraType, EdgeInfraTypeMap, EdgeManageType, EdgeManageTypeMap, EdgeStatus, EdgeStatusMap, EdgeSubscription } from "types";
import EdgeConfig from "../EdgeConfig";
import { getTagColor } from "utility";
import { TableProps } from "antd/lib/table";
import { DataType } from "@textea/json-viewer";
import { commonIcons, edgeIcons } from "assets/icons";
import { HLink } from "components/Link";
import { getAppSubscriptionsApi } from "api";
import { getAppLogoUrl } from "utility/app";
import SearchInput from "components/SearchInput";
import ControlButton from "components/ControlButton/controlButton";
import { awsRegions, azureRegions, gcpRegions, ociRegions } from "./coordinates";
const { Title, Text, Paragraph } = Typography;

const EdgeMap: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [showEdgeConfig, setShowEdgeConfig] = useState<boolean>(false);
  const [deleteEdgeModal, setDeleteEdgeModal] = useState<{enable: boolean; edge?: Edge;}>({ enable: false });
  const [disableEdgeModal, setDisableEdgeModal] = useState<{enable: boolean; edgeSubscription?: EdgeSubscription;}>({ enable: false });
  const [showAppsModal, setShowAppsModal] = useState<boolean>(false);
  const [edgeAppsMap, setEdgeAppsMap] = useState<Map<string, AppSubscription[]>>(new Map<string, AppSubscription[]>());

  const context = useOrganizationStore((state) => state.context);

  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  const {
    edges,
    getEdges,
    clearEdges,
    resetEdgeStore,
    deleteEdge,
    enableEdgeSubscription,
    disableEdgeSubscription,
    clearSelectedEdge,
    edgeSearchTextMap,
    setEdgeSearchTextMap,
  } = useEdgeStore((state) => ({
    edges: state.edges,
    getEdges: state.getEdges,
    clearEdges: state.clearEdges,
    resetEdgeStore: state.resetEdgeStore,
    deleteEdge: state.deleteEdge,
    enableEdgeSubscription: state.enableEdgeSubscription,
    disableEdgeSubscription: state.disableEdgeSubscription,
    clearSelectedEdge: state.clearSelectedEdge,
    edgeSearchTextMap: state.edgeSearchTextMap,
    setEdgeSearchTextMap: state.setEdgeSearchTextMap,
  }));

  const [markerGroups, setMarkerGroups] = useState<{key: string; coordinates: number[]; edges: Edge[]}[]>([]);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const [showEdgeDetails, setShowEdgeDetails] = useState<{enable: boolean; edge?: Edge}>({enable: false});
  const [showEdgeWarningModal, setShowEdgeWarningModal] = useState<{enable: boolean, name: string}>({enable: false, name: ""});

  const getEdgeCoordinates = (edge: Edge): number[] => {
    let locations: { region: string; city: string; coordinates: number[];}[] = [];
    switch (edge.infraType) {
      case EdgeInfraType.Amazon:
        locations = awsRegions;
        break
      case EdgeInfraType.Azure:
        locations = azureRegions;
        break
      case EdgeInfraType.Gcp:
        locations = gcpRegions
        break
      case EdgeInfraType.Oci:
        locations = ociRegions
        break
      case EdgeInfraType.OnPrem:
       /*  const cityList:City[] = cities as City[];
        const city = cityList.find((city) => city.name == location)
        console.log("city", city);
        return city ? [Number(city.lat), Number(city?.lng)] : [0, 0] as [number, number]; */
        return [-120, -10]
        break
    }
    
    const coordinates = locations.find((loc) => loc.region == edge.region)?.coordinates;
    if (coordinates) {
      return [coordinates[1], coordinates[0]];
    }
    return [-120, -20];  //place edges with unknown locaitons on left side of the map
  }

  const {mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const loadEdges = async () : Promise<Edge[]> => {
    try {
      setLoader(true);
      clearEdges(); 
      const edges = await getEdges(false);

      const markerGroups = edges.reduce((groups, edge) => {
        const coordinates = getEdgeCoordinates(edge);
        const key = coordinates.toString();
        let group = groups.find((g) => g.key == key);
        if (!group) {
          group = {
            key: key,
            coordinates: coordinates,
            edges: []
          };
          groups.push(group);
        }
        group.edges.push(edge);
        return groups;
      },  [] as any[]);
      setMarkerGroups(markerGroups);
      return edges;
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    resetEdgeStore();
    loadEdges();
    return () => {
      resetEdgeStore();
    };
  }, []);

  useEffect(() => {
    loadEdges();
  }, [edgeSearchTextMap, context]);

  const enableEdge = async (edgeId?: string) => {
    try {
      setShowEdgeDetails({enable: false});
      setLoader(true);
      await enableEdgeSubscription(String(edgeId));
      notification.success({
        message: "Edge enabled successfully",
        duration: 6,
      });
      const loadedEdges = await loadEdges();
      const edge = loadedEdges.find((e) => e.id == edgeId)
      edge && setShowEdgeDetails({enable: true, edge: edge});
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error enabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const disableEdge = async (edgeSubscriptionId?: string) => {
    try {
      const edgeId = showEdgeDetails?.edge?.id;
      setShowEdgeDetails({enable: false});
      setLoader(true);
      setDisableEdgeModal({ enable: false });
      await disableEdgeSubscription(String(edgeSubscriptionId));
      notification.success({
        message: "Edge disabled successfully",
        duration: 6,
      });
      const loadedEdges = await loadEdges();
      const edge = loadedEdges.find((e) => e.id == edgeId)
      edge && setShowEdgeDetails({enable: true, edge: edge});
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error disabling edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const deleteCurrentEdge = async (edgeId?: string) => {
    try {
      setLoader(true);
      setDeleteEdgeModal({ enable: false });
      await deleteEdge(String(edgeId));
      notification.success({
        message: "Edge deleted successfully",
        duration: 6,
      });
      await loadEdges();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting edge",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const loadAppsByEdge = async (edge: Edge) => {
    try {
      setModalLoader(true);
      const edgeApps = new Map<string, AppSubscription[]>();
      if (edge.edgeSubscriptions?.length) {
        const filters = [] as string[];
        filters.push("equals(edgeID,'" + edge.id + "')");
        const [appSubscriptions] = await getAppSubscriptionsApi(undefined, undefined, filters);
        appSubscriptions?.map((as) => {
          const subscriptions = edgeApps.get(as.appID);
          if (subscriptions) {
            subscriptions.push(as);
            edgeApps.set(as.appID, subscriptions)
          } else {
            const subscriptions = [] as AppSubscription[];
            subscriptions.push(as);
            edgeApps.set(as.appID, subscriptions);
          }
        });        
      }
      setEdgeAppsMap(edgeApps);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in loading apps",
        duration: 6,
      });
    } finally {
      setModalLoader(false);
    }
  }

  const loadAppsByEdgeSubscription = async (edgeSubscription: EdgeSubscription) => {
    try {
      setModalLoader(true);
      const edgeApps = new Map<string, AppSubscription[]>();
      const filters = [] as string[];
      filters.push("equals(edgeSubscriptionID,'" + edgeSubscription.id + "')");
      const [appSubscriptions] = await getAppSubscriptionsApi(undefined, undefined, filters);
      appSubscriptions?.map((as) => {
        const subscriptions = edgeApps.get(as.appID);
        if (subscriptions) {
          subscriptions.push(as);
          edgeApps.set(as.appID, subscriptions)
        } else {
          const subscriptions = [] as AppSubscription[];
          subscriptions.push(as);
          edgeApps.set(as.appID, subscriptions);
        }
      });        
      setEdgeAppsMap(edgeApps);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in loading apps",
        duration: 6,
      });
    } finally {
      setModalLoader(false);
    }
  }
  
  const closeEdgeConfig = async (refreshData?: boolean) => {
    clearSelectedEdge();
    setShowEdgeConfig(false);
    if (refreshData) {
      setLoader(true);
      await loadEdges();
      setLoader(false);
    }
  };

  const getEdgeStatus= (edgeStatus: EdgeStatus, showText?: boolean) => {
    switch (edgeStatus) {
      case EdgeStatus.Online:
        return <Badge status="processing" color="green" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Offline:
        return <Badge status="default" color="darkgrey" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Installing:
        return <Badge status="processing" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Updating:
        return <Badge status="warning" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      case EdgeStatus.Uninstalling:
        return <Badge status="processing" color="red" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
      default:
        return <Badge status="default" text={showText && <Text>{EdgeStatusMap.get(edgeStatus)}</Text>} />;
    }
  }

  const getMarkerGroupColor= (edges: Edge[]) => {
    if (edges.some((e) => e.status == EdgeStatus.Online)) {
      return "green";
    }
    if (edges.some((e) => e.status == EdgeStatus.Installing)) {
      return "blue";
    }
    if (edges.some((e) => e.status == EdgeStatus.Updating)) {
      return "orange";
    }
    if (edges.some((e) => e.status == EdgeStatus.Uninstalling)) {
      return "red";
    }
    return token.colorIconHover;
  }

  return (
    <>
      <div style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "2px"
        }}
      >
        <div style={{ flex: 1 }}>
          <h3>
            Total Edges ({edges.length})
          </h3>
        </div>
        <Space>
          <div style={{ width: "300px" }}>
            <SearchInput
              placeholder={"search edges"}
              value={edgeSearchTextMap.get("displayName")}
              onFilter={(e) =>  {
                edgeSearchTextMap.set("displayName", e.target.value);
                setEdgeSearchTextMap(edgeSearchTextMap);
              }}
            />
          </div>
          <Button 
            key={"create"} 
            type="primary"
            onClick={() => setShowEdgeConfig(true)}
          >
            Create
          </Button>
        </Space>
      </div>
      <Spin spinning={loader}>
        <ComposableMap stroke={token.colorPrimary} strokeWidth={0.1}>
          <ZoomableGroup 
            zoom={position.zoom}
            center={[position.coordinates[0], position.coordinates[1]]}
            onMoveEnd={setPosition}
          >
            <Geographies geography={"/world-map.json"}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography 
                    key={geo.rsmKey} 
                    geography={geo} 
                    fill={token.colorPrimary} 
                    stroke={token.colorBorderSecondary}
                    strokeWidth={0.2}
                  />
                ))
              }
            </Geographies>
            {markerGroups &&
              markerGroups.map((group) => (
                <Marker 
                  key={group.key} 
                  coordinates={[group.coordinates[0], group.coordinates[1]]}
                >
                  <Popover 
                    title={"Edges"} 
                    content={
                      <List
                        dataSource={group.edges}
                        renderItem={(edge, index) => (
                          <List.Item key={index}>
                            <List.Item.Meta
                              avatar={
                                <Tooltip title={EdgeInfraTypeMap.get(edge.infraType)}>
                                  <div>
                                    <SvgIcon size={20} component={getEdgeShortLogo(edge.infraType)} />
                                  </div>
                                </Tooltip>
                              }
                              title={<Text>{edge.displayName}</Text>}
                              description={
                                <Space direction="vertical">
                                  {getEdgeStatus(edge.status, true)}
                                  <Button 
                                    type="primary" 
                                    size="small" 
                                    block 
                                    onClick={() => {
                                      showEdgeDetails 
                                        ?
                                        setShowEdgeDetails({...showEdgeDetails, edge: edge})
                                        :
                                        setShowEdgeDetails({enable: true, edge: edge});
                                    }}
                                  >
                                    <Space>
                                      More Details
                                      <DoubleRightOutlined/>
                                    </Space>
                                  </Button>
                                </Space>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    }
                  >
                    <circle r={4} fill={getMarkerGroupColor(group.edges)} stroke={token.colorBorder} strokeWidth={0.5} />
                  </Popover>
                  {group.key == "-120,-10" &&
                    <text
                      textAnchor="middle"
                      x={10}
                      y={-10}
                      style={{ fill: token.colorPrimaryText, fontSize: 8 }}
                    >
                      {"On-Premises Edges"}
                    </text>
                  }
                  {group.key == "-120,-20" &&
                    <text
                      textAnchor="middle"
                      x={10}
                      y={-10}
                      style={{ fill: token.colorWarning, fontSize: 8 }}
                    >
                      {"Unknown Location Edges"}
                    </text>
                  }
                </Marker>
              ))
            }
          </ZoomableGroup>
        </ComposableMap>
        <Space size={0} style={{ position: "absolute", top: "1%", right: "1%" }}>
          <ControlButton
            displayName={"Zoom In"}
            svgComponent={
              <SvgIcon
                onClick={() => {
                  if (position.zoom >= 4) return;
                  setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
                }}
                component={commonIcons.zoomIn}
              />
            }
            hoverable={true}
            placement="bottom"
          />
          <ControlButton
            displayName={"Zoom Out"}
            svgComponent={
              <SvgIcon
                onClick={() => {
                  if (position.zoom <= 1) return;
                  setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
                }}
                component={commonIcons.zoomOut}
              />
            }
            hoverable={true}
            placement="bottom"
          />
          <ControlButton
            displayName={"Fit View"}
            svgComponent={
              <SvgIcon
                onClick={() => {
                  setPosition((pos) => ({ ...pos, coordinates: [0, 0], zoom: 1 }));
                }}
                component={commonIcons.fitView}
              />
            }
            hoverable={true}
            placement="bottom"
          />
        </Space>
        {showEdgeDetails && showEdgeDetails.edge &&
          <Card
            size="small"
            title={<Text style={{ color: token.colorTextLightSolid }}>{showEdgeDetails.edge.displayName}</Text>}
            extra={
              <CloseOutlined style={{ color: token.colorTextLightSolid }} onClick={() => setShowEdgeDetails({enable: false})}/>
            }
            bodyStyle={{
              overflowY: "auto",
              overflowX: "hidden",
              padding: token.padding
            }}
            headStyle={{
              backgroundColor: token.colorPrimary,
              textAlign: "left",
            }}
            style={{
              height: "auto",
              width: "400px",
              cursor: "default",
              position: "absolute", 
              top: "7%", 
              right: "1%"
            }}
          >
            <div style={{ display: "flex", justifyContent: "end"}}>
              <Space>
                <Tooltip title={showEdgeDetails.edge.edgeSubscriptions.length ? "Click here to disable": "Click here to enable"}>
                  <Button
                    size="small"
                    icon= {<SvgIcon size={18} component={showEdgeDetails.edge.edgeSubscriptions.length == 0 ? edgeIcons.edgeDisabledIcon : edgeIcons.edgeEnabledIcon} />}
                    onClick={() => {
                      if (showEdgeDetails.edge?.infraType == EdgeInfraType.Amazon || showEdgeDetails.edge?.infraType == EdgeInfraType.OnPrem) {
                        const es = showEdgeDetails.edge?.edgeSubscriptions?.find((es: EdgeSubscription) => es.tenantID == context?.split('_')[0])
                        if (!es) {
                          enableEdge(showEdgeDetails.edge?.id);
                        } else {
                          setDisableEdgeModal({ enable: true, edgeSubscription: es});
                          loadAppsByEdgeSubscription(es);
                        }
                      }  else {
                        setShowEdgeWarningModal({enable: true, name: showEdgeDetails.edge?.name as string})
                      }
                    }}
                  />
                </Tooltip>
                {showEdgeDetails.edge.manageType == EdgeManageType.Custom && showEdgeDetails.edge.edgeSubscriptions.length == 0
                  &&
                  <Tooltip title={"Click here to delete"}>
                    <Button
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => setDeleteEdgeModal({ enable: true, edge: showEdgeDetails.edge })}
                    />
                  </Tooltip>
                }
              </Space>
            </div>
            <Descriptions 
              size="small"
              colon={false}
              column={1} 
              bordered
            >
              <Descriptions.Item label="Name">{showEdgeDetails.edge.displayName}</Descriptions.Item>
              <Descriptions.Item label="Description">
                <Paragraph 
                  style={{ overflow: "hidden" }}
                  ellipsis={{
                    rows: 2,
                    expandable: 'collapsible',
                    symbol: ((expanded: boolean) => expanded ? 'Less' : 'More') 
                }}>
                  {showEdgeDetails.edge.description}
                </Paragraph>  
              </Descriptions.Item>
              <Descriptions.Item label="Infrastructure">  
                <Image
                  preview={false}
                  draggable={false}
                  width={"60px"}
                  height={"40px"}
                  src={getEdgeLogo(showEdgeDetails.edge.infraType, mode)}
                  alt="company-logo"
                />
              </Descriptions.Item>
              {showEdgeDetails.edge.region != "" 
                ? 
                  <Descriptions.Item label="Region">
                    <Text type="secondary" keyboard>{showEdgeDetails.edge?.region}</Text>
                  </Descriptions.Item>
                :
                  <></>
              }
              <Descriptions.Item label="Owned By">{showEdgeDetails.edge?.manageType == EdgeManageType.HyprEdge ? "HyprEdge" : "Me"}</Descriptions.Item>
              <Descriptions.Item label="Status">{getEdgeStatus(showEdgeDetails.edge?.status, true)}</Descriptions.Item>
              <Descriptions.Item label="Apps">
                <div>
                  <HLink
                    onClick={() => {
                      setShowAppsModal(true);
                      loadAppsByEdge(showEdgeDetails.edge as Edge);
                    }}
                    text={ <Tooltip title={"Click here to view apps"}>View</Tooltip>}
                    underline
                    alwaysLinkColor
                  />
                </div>
              </Descriptions.Item>
              {mspEnabled && showEdgeDetails.edge.edgeSubscriptions?.length 
                ?
                  <Descriptions.Item label="Tags">
                    <Space direction="vertical">
                      {showEdgeDetails.edge.edgeSubscriptions?.map((es: EdgeSubscription) => {
                        const tag = tags?.find((tag) => (tag.id == `${es?.tenantID}_tag`));
                        return tag 
                            ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag>
                            : <></>
                        }
                      )}
                    </Space>
                  </Descriptions.Item>
                :
                  <></>
              }
              <Descriptions.Item label="Labels">
                <Space direction="vertical">
                  {showEdgeDetails.edge.tags?.map((tag: any) => 
                    <Tag key={tag} color={getTagColor(tag)}>
                      {EdgeTagsMap.get(tag)}
                    </Tag>
                  )}
                </Space>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        }
      </Spin>
      {showEdgeConfig && (
        <EdgeConfig
          open={showEdgeConfig}
          onClose={(refresh) => closeEdgeConfig(refresh)}
        />
      )}
      {disableEdgeModal?.enable && (
        <AntModal
          title="Disable Edge"
          open={disableEdgeModal.enable}
          loading={modalLoader}
          onCancel={() => setDisableEdgeModal({ enable: false })}
          footer={[
            <Button key="ok" type="primary" onClick={() => setDisableEdgeModal({ enable: false })}>
              Cancel
            </Button>,
            <Button key="ok" type="primary" disabled={Array.from(edgeAppsMap).length != 0} onClick={() => disableEdge(disableEdgeModal?.edgeSubscription?.id)}>
              Confirm
            </Button>
          ]}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
        >
          {edgeAppsMap?.size
            ?
              <Space direction="vertical" style={{ display: "flex" }}>
                <Title type="warning" level={5} >{"Edge can not be disabled, Below apps are configured on this edge!"}</Title>
                {Array.from(edgeAppsMap).map(([appId, subscriptions]) =>
                  <Card 
                    key={appId} 
                    size="small" 
                    title={
                      <Image 
                        preview={false} 
                        draggable={false}
                        height={"15px"}
                        src={getAppLogoUrl(subscriptions[0].apps, mode)}
                        alt="company-logo"
                      />
                    }
                  >
                    <Space wrap>
                      {subscriptions.map((as: AppSubscription) => 
                        <Tag key={as.id} color={getTagColor(as.name)}>
                          {mspEnabled 
                            ?
                              <Popover 
                                title="Tag"
                                content= {
                                  <Tag color={getTagColor(`${as.tenantID}_tag`)}>
                                    {tags?.find((tag) => (tag.id == `${as.tenantID}_tag`))?.value}
                                  </Tag>
                                }
                              >
                                {as.name}
                              </Popover>
                            :
                              as.name
                          }
                        </Tag>
                      )}
                    </Space>
                  </Card>
                )}
              </Space>
            :
              <Text>{"Are you sure you want to disable edge?"}</Text>
          }
        </AntModal>
      )}
      {deleteEdgeModal?.enable && (
        <AntModal
          title="Delete Edge"
          open={deleteEdgeModal.enable}
          onClose={() => setDeleteEdgeModal({ enable: false })}
          footer={[
            <Button key="ok" type="primary" onClick={() => setDeleteEdgeModal({ enable: false })}>
              Cancel
            </Button>,
            <Button key="ok" type="primary" onClick={() => deleteCurrentEdge(deleteEdgeModal.edge?.id)}>
              Confirm
            </Button>
          ]}
        >
          {deleteEdgeModal.edge?.infraType == EdgeInfraType.OnPrem
            ?
              <Descriptions
                  size="small"
                  layout="vertical"
                  colon={false}
                  column={1}
              >
                <Descriptions.Item label="Stop docker container">
                  <Text copyable>docker stop `container id`</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Remove docker image">
                  <Text copyable>docker rm `container id`</Text>
                </Descriptions.Item>
              </Descriptions>
            :
              "Are you sure you want to delete edge?"
          }
        </AntModal>
      )}
      {showEdgeWarningModal.enable && (
        <AntModal
          title={
            <Space>
              <InfoCircleOutlined style={{ color: token.colorInfo }}/>
              <Text style={{ color: token.colorInfo }}>{"Info"}</Text>
            </Space>
          }
          open={showEdgeWarningModal.enable}
          closable={false}
          footer={[
            <Button 
              key="ok" 
              type="primary" 
              onClick={() => setShowEdgeWarningModal({enable: false, name: ""})}
            >
              OK
            </Button>
          ]}
        >
          {"Contact "}
          <HLink href={"mailto:contact@hypredge.com"} text={"HyprEdge"} alwaysLinkColor />
          {` to enable edge '${showEdgeWarningModal.name}' !`}
        </AntModal>
      )}
      {showAppsModal && (
        <AntModal
          title="Apps"
          open={showAppsModal}
          loading={modalLoader}
          onCancel={() => setShowAppsModal(false)}
          footer={[
            <Button key="ok" type="primary" onClick={() => setShowAppsModal(false)}>
              OK
            </Button>
          ]}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
        >
          {edgeAppsMap?.size
            ?
              <Space direction="vertical" style={{ display: "flex" }}>
                {Array.from(edgeAppsMap).map(([appId, subscriptions]) =>
                  <Card 
                    key={appId} 
                    size="small" 
                    title={
                      <Image 
                        preview={false} 
                        draggable={false}
                        height={"15px"}
                        src={getAppLogoUrl(subscriptions[0].apps, mode)}
                        alt="company-logo"
                      />
                    }
                  >
                    <Space wrap>
                      {subscriptions.map((as: AppSubscription) => 
                        <Tag key={as.id} color={getTagColor(as.name)}>
                          {mspEnabled 
                            ?
                              <Popover 
                                title="Tag"
                                content= {
                                  <Tag color={getTagColor(`${as.tenantID}_tag`)}>
                                    {tags?.find((tag) => (tag.id == `${as.tenantID}_tag`))?.value}
                                  </Tag>
                                }
                              >
                                {as.name}
                              </Popover>
                            :
                              as.name
                          }
                        </Tag>
                      )}
                    </Space>
                  </Card>
                )}
              </Space>
            :
              <Text type="secondary">Apps are not configured on this edge</Text>
          }
        </AntModal>
      )}
    </>
  );
};

export default EdgeMap;