import { useEffect } from "react";
import { InputNumber, Typography } from "antd";
import React, { PropsWithChildren } from "react";
import { createUseStyles } from "react-jss";
import { FieldProps } from "./common";
import { EditorContext } from "./editorContext";
import { FieldLabel } from "./fieldLabel";
const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    flexBasis: "20%",
    minWidth: "200px",
  },
  right: {},
});

interface NumberFieldProps extends FieldProps {
  value: number;
  onChange: (key: string, value: number) => Promise<void>;
}

/** react function component */
export const NumberField = (props: PropsWithChildren<NumberFieldProps>) => {
  useEffect(() => {
    //console.log(`path : ${props.path}`);
  }, []);
  const classes = useStyles();
  const editorContext = React.useContext(EditorContext);

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <FieldLabel fp={props} />
      </div>
      <div className={classes.right}>
        {editorContext.view ? (
          <Text>{props.value}</Text>
        ) : (
          <InputNumber
            defaultValue={props.value}
            onChange={(e) => {
              if (e) props.onChange(props.identifier, e);
            }}
          />
        )}
      </div>
    </div>
  );
};
