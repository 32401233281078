import { Subscriber } from "types";

export interface TreeNode {
  id: string;
  attributes: {[k:string ] : string};
  children: TreeNode[]
}

export const subscribersToTree = (subscribers: Subscriber[]): TreeNode[] => {
  const treeMap: { [key: string]: TreeNode } = {};

  // Helper function to get or create a node in the tree
  const transform = (subscriber: Subscriber): TreeNode => {
    const path = subscriber.nodePath;
    if (treeMap[path]) {
      return treeMap[path];
    }

    const self: TreeNode = {
      id: subscriber.tenantId,
      attributes: {
        description: subscriber.description,
        name: subscriber.name,
        owner: subscriber.userEmailAddress,
      },
      children: [],
    };
    treeMap[path] = self;

    const fragments = path.split("/");
    const parentPath = fragments.slice(0, fragments.length - 1).join("/");
    const parentSubscriber = subscribers.find(
      (x) => x.nodePath === parentPath
    );
    if (parentSubscriber) {
      const parentNode = transform(parentSubscriber);
      if (parentNode.children) {
        parentNode.children.push(self);
      } 
    }
    return self;
  };

  // Constructing tree structure
  subscribers.forEach((subscriber) => {
    transform(subscriber);
  });


  // Find root nodes (those without parents)
  const roots: TreeNode[] = [];
  for (const key in treeMap) {
    const parentPath = key.substring(0, key.lastIndexOf("/"));
    if (!treeMap[parentPath]) {            
      roots.push(treeMap[key]);
    }
  }
  return roots;
};
