import { create } from "zustand"


import { Metric, GoalCreateRequest, GoalStore, GoalUpdateRequest, DistributionData, RawPoint } from "types";
import { listGoalsApi ,createGoalApi, updateGoalApi,deleteGoalApi, getGoalMetricsApi} from "api";

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

  export const useGoalStore = create<GoalStore>(
    (set, get) => ({           
      loading: false,
      goals: [],
      goalMetrics: {},
    
      setLoading : async (loading) => {
        set((state) => ({...state, loading}));
      },

      listGoals: async () => {
        try {
          await get().setLoading(true);
          const goals = await listGoalsApi();
          const metric : Metric = await getGoalMetricsApi();      
          const data : DistributionData = metric.data as DistributionData;
          const groups : Record<string, RawPoint[] > = groupBy(data.primary.values, (i) => i.x); 
          const response = Object.keys(groups).reduce((acc, key) => {
            acc[key] = groups[key].map((i) => i.y);
            return acc;
          }, {} as Record<string, number[]>);      
          set(() => ({
            goalMetrics: response, goals: goals.data.goals,
          }));
        } finally {
          await get().setLoading(false);
        }
      },
    
      addGoal: async (goal: GoalCreateRequest) => {
        await createGoalApi(goal);
        await get().listGoals();
      },

      updateGoal: async (id: string, goal: GoalUpdateRequest) => {
        await updateGoalApi(id, goal);
        await get().listGoals();
      },

      deleteGoal: async (id: string) => {
        await deleteGoalApi(id);
        await get().listGoals();
      },
    })
);
