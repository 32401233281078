import { FC, useEffect } from "react";

import { Form, InputNumber, Select } from "antd";

import { 
  IODataType, 
  WorkflowParametersProps
} from "types"

import { SuggestionsInput } from "components/Suggestions";
import { FieldLabel } from "components/FieldLabel";

const WorkflowParameters: FC<WorkflowParametersProps> = ({
  editMode,
  parameters,
  parametersValues,
  parameterSuggestionsTree,
  onChange,
  onRender
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onRender?.(form);
  }, []);

  const onValuesChange = async () => {
    const currentValues = form.getFieldsValue(true);  
    onChange?.(currentValues);
  };

  const getParameterValue = (name: string, value: any) => {
    if (parametersValues && (name in parametersValues)) {
      return parametersValues[name];
    } else {
      return value;
    }
  };

  return (
    <Form 
      form={form} 
      name="parametersForm"
      layout="vertical" 
      autoComplete="off"
      onValuesChange={onValuesChange}
    >
      {parameters?.map((parameter) => 
        (<Form.Item 
          key={parameter.referenceName}
          name={parameter.referenceName}
          label={<FieldLabel label={parameter.name} help={parameter.description} />}
          required
          initialValue={getParameterValue(parameter.referenceName, parameter.value)}
          validateTrigger="onSubmit"
          rules={[
            { required: true, message: 'Required field!'}
          ]}
        >
          {parameter.type == IODataType.Numeric 
            &&
            <InputNumber disabled={!editMode}/>
          } 
          {parameter.type == IODataType.String && 
            <SuggestionsInput
              editMode={editMode}
              name={parameter.name}
              suggestionsTree={parameterSuggestionsTree}
              textType={parameter.textType}
            />
          }
          {parameter.type == IODataType.Boolean && 
            <Select showAction={['focus', 'click']} disabled={!editMode}>
              <Select.Option key={1} value={true}>True</Select.Option>
              <Select.Option key={0} value={false}>False</Select.Option>
            </Select>
          }
        </Form.Item>)
      )}
    </Form>
  );
};

export default WorkflowParameters;
