import {
    SpecStateType,
    AccessInfo,
    AppInfos,
    IODataMap,
    IODatas,
} from "types";



//Triggers types
export enum TriggerType {
    Manual = "manual",
    Schedule = "schedule",
    Custom = "custom"
}

export const TriggerTypeMap: Map<TriggerType, string>  =  new Map<TriggerType, string> (
    [
      [TriggerType.Manual, "Manual"], 
      [TriggerType.Schedule, "Schedule"], 
      [TriggerType.Custom, "Custom"], 
    ]
)

export const TriggerTypeList = [
    TriggerType.Custom,
    TriggerType.Schedule,
    TriggerType.Manual,
]

export interface Trigger {
    id: string;
    comments: string;
    state: SpecStateType;
    shared: boolean;
    name: string;
    displayName: string;
    type: TriggerType;
    appInfos?: AppInfos;
    description: string;
    parameters: IODataMap;
    outputs: IODatas;
    accessInfo: AccessInfo;
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;
    tags: string[];
}

export type TriggerUploadObjectType = Omit<Trigger,  'state' | 'shared' | 'appInfos' | 'accessInfos' | 'updatedAt' | 'createdAt'| 'userID'>;
