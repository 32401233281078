import { Button, Typography } from "antd";
import { FC } from "react";
import { TreeDisplayData } from "./TreeDisplayNode";
const { Text } = Typography;
export const TreePopoverContent: FC<TreeDisplayData> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text style={{ fontSize: "0.75rem" }}>{props.id}</Text>
      <Text style={{ fontSize: "0.75rem" }}>{props.name}</Text>
      <Text style={{ fontSize: "0.75rem" }}>{props.description}</Text>
      <Text style={{ fontSize: "0.75rem" }}>{props.owner}</Text>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Button
          style={{ width: "30%" }}
          type="primary"
          size="small"
          onClick={async () => props.onEdit(props.id)}
        >
          Edit
        </Button>
        <Button
          style={{ width: "30%" }}
          type="primary"
          danger
          size="small"
          onClick={async () => props.onDelete(props.id)}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
