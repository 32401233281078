import { ArtifactFieldType } from "types/search";
import {DataType} from "types/utils/models/types";

//conditions
export interface Condition {
    fact: string;
    operator: string;
    value: any;
}
export type Conditions = Condition[];

//rules
export interface Rule {
    name: string;
    desc: string;
    status: "enabled" | "disabled";
    conditions: Array<Condition | Conditions>;
}

export type Rules = Rule[];

export enum ConditionOpType {
    Equals = "==",
	NotEquals =  "!=",
	LessThan = "<" ,
	LessEquals =  "<=",
	GreaterThan = ">",
	GreaterEquals = ">=",
	IsEmpty      = "is_empty",
	Contains     = "contains", 
	NotContains   = "not_contains",
	Matches    =    "matches",
	StartsWith   = "starts_with", 
	EndsWith     =  "ends_with",
	Before        =  "before",
	After         = "after",
}

export enum LogicalConditionOpType {
	And = "and", 
	Or  = "or",
}

export const conditionOperators: ConditionOpType[] = [
    ConditionOpType.Equals,
	ConditionOpType.NotEquals,
	ConditionOpType.LessThan,
	ConditionOpType.LessEquals,
	ConditionOpType.GreaterThan,
	ConditionOpType.GreaterEquals,
	ConditionOpType.IsEmpty,
	ConditionOpType.Contains,
	ConditionOpType.NotContains,
	ConditionOpType.Matches,
	ConditionOpType.StartsWith,
	ConditionOpType.EndsWith,
	ConditionOpType.Before,
	ConditionOpType.After,
]

export const logicalOperators: LogicalConditionOpType[] = [
    LogicalConditionOpType.Or,
	LogicalConditionOpType.And,
]

export const logicalAndOperator = "and"
export const logicalOrOperator = "or"


export const ValueAndConditionOpType: Map<ArtifactFieldType, ConditionOpType[]> = new Map<ArtifactFieldType, ConditionOpType[]>(
    [
        [ArtifactFieldType.Boolean, [ConditionOpType.Equals, ConditionOpType.NotEquals]],
        [ArtifactFieldType.Numeric, [ConditionOpType.Equals, ConditionOpType.NotEquals, ConditionOpType.LessThan, ConditionOpType.LessEquals, ConditionOpType.GreaterThan, ConditionOpType.GreaterEquals]],
        [ArtifactFieldType.String, [ 
			ConditionOpType.Equals, ConditionOpType.NotEquals, ConditionOpType.IsEmpty, ConditionOpType.Contains, ConditionOpType.NotContains,
            ConditionOpType.Matches, ConditionOpType.StartsWith,ConditionOpType.EndsWith, 
        ]],
        [ArtifactFieldType.TimeStamp, [ConditionOpType.Before, ConditionOpType.After]],
		[ArtifactFieldType.Duration, [ConditionOpType.Before,ConditionOpType.After]],
        [ArtifactFieldType.IP, [ConditionOpType.Equals, ConditionOpType.NotEquals, ConditionOpType.Contains, ConditionOpType.NotContains]],
        [ArtifactFieldType.Array, [ConditionOpType.Contains, ConditionOpType.NotContains, ConditionOpType.IsEmpty,]],
        [ArtifactFieldType.Map, [ConditionOpType.Contains, ConditionOpType.NotContains, ConditionOpType.IsEmpty]],
    ]
)