import { MailOutlined } from "@ant-design/icons";
import { FC } from "react";
import { Button } from "antd";

import SignInSignUpWrapper from "../../components/SignInSignUpWrapper";
import styles from "./EmailOtpVerification.module.scss";

const EmailOtpVerification: FC = () => {
  return (
    <SignInSignUpWrapper>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <MailOutlined />
        </div>
        <p className={styles.verificationDescription}>
          We have sent you an email to reset your password.{" "}
        </p>
        <p className={styles.verificationDescription}>
          <a href="/signin">Back To SignIn</a>
        </p>
      </div>
    </SignInSignUpWrapper>
  );
};

export default EmailOtpVerification;
