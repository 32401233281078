import React, { useEffect } from "react";
import { Typography, Space, Radio, RadioChangeEvent } from "antd";
import { EditorContext } from "./editorContext";
import { FieldProps } from "./common";
import { createUseStyles } from "react-jss";
import { FieldLabel } from "./fieldLabel";
const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  left: {
    flexBasis: "20%",
    minWidth: "200px",
  },
  right: {},
});

interface RadioFieldProps extends FieldProps {
  value: string;
  direction: "vertical" | "horizontal";
  defaultSelected: string;
  options: { label: string; value: string }[];
  onChange: (key: string, value: string) => void;
}

/** react function component */
export const RadioField = (props: RadioFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const editorCtx = React.useContext(EditorContext);
  const [selectedValue, setSelectedValue] = React.useState<string>(
    props.value != "" ? props.value : props.defaultSelected
  );

  const onChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
    props.onChange(props.identifier, e.target.value);
  };

  return (
    <Space direction="vertical" style={{ display: "flex", marginTop: "10px" }}>
      <FieldLabel fp={props} />
      <Radio.Group onChange={onChange} value={selectedValue}>
        <Space direction={props.direction}>
          {props.options.map((option) => (
            <Radio
              key={option.label}
              value={option.value}
              style={{ marginLeft: "25px" }}
              disabled={editorCtx.view}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Space>
  );
};
