import { Card, Col, Divider, Row, Typography } from "antd";
import { Fader } from "components/Fader";
import { useEffect, useState } from "react";
import { useTokenStore } from "store";
import { getUserDetailsFromJWT } from "utility";

const { Text } = Typography;

export const InformationCard = () => {
  interface ItemType {
    title: string;
    content: string;
    icon: JSX.Element;
  }

  const [items, setItems] = useState<ItemType[]>([]);
  const { token } = useTokenStore((state) => ({
    token: state.token,
  }));

  useEffect(() => {
    const { tenantId, userId } = getUserDetailsFromJWT();
    setItems([
      {
        title: "Tenant ID",
        content: tenantId,
        icon: <Fader text={tenantId} onClickText="copied"></Fader>,
      },
      {
        title: "User ID",
        content: userId,
        icon: <Fader text={userId} onClickText="copied"></Fader>,
      },
    ]);
  }, [token]);

  return (
    <Card title="Additional Information" style={{ width: "100%" }}>
      <div
        id="profile-additional-information-container"
        style={{
          width: "100%",
        }}
      >
        {items.map((item, index) => (
          <Row key={index}>
            <Col span={10}>
              <Text strong>{item.title}</Text>
            </Col>
            <Col span={12}>
              <Text>{item.content}</Text>
            </Col>
            <Col span={2}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {item.icon}
              </div>
            </Col>
            <Divider />
          </Row>
        ))}
      </div>
    </Card>
  );
};
