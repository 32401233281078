import { FC, useState, useEffect } from "react";

import {
  Spin,
  Space,
  theme,
  Button,
  Timeline,
  Badge,
  Modal as AntModal,
  Tooltip,
  Typography
} from "antd";
import {
  MoreOutlined,
  DeleteOutlined,
  RollbackOutlined,
  ClockCircleOutlined,
  WarningOutlined
} from "@ant-design/icons";

import Modal from "components/Modal";

import { notification } from 'utility/notification';

import { SpecActionType, SpecStateType, VersionCardProps } from "types";

const { Text } = Typography;

const VersionCard: FC<VersionCardProps> = ({
  specId,
  specType,
  width,
  open,
  editMode,
  onFetchVersions,
  onAction,
  onActionCompleted,
  onClose,
}) => {
  const [loader, setLoader] = useState(false);
  const { token } = theme.useToken();
  const [specVersions, setSpecVersions] = useState([] as any[]);
  const [deleteVersionModal, setDeleteVersionModal] = useState(false);
  const [restoreVersionModal, setRestoreVersionModal] = useState(false);
  const [specVersion, setSpecVersion] = useState("");

  const loadVersions = async (specId: string) => {
    try {
      setLoader(true);
      const versions = await onFetchVersions(specId);
      setSpecVersions([...versions]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const deleteSpecVersion = async () => {
    try {
      setLoader(true);
      onAction(specId, SpecActionType.ActionDelete, specVersion);
      setDeleteVersionModal(false);
      notification.success({
        message: `${specType} version deleted successfully`,
        duration: 6,
      });
      await loadVersions(specId);
      onActionCompleted(specId, SpecActionType.ActionDelete);
    } catch (error) {
      console.log(error);
      notification.error({
        message:
          "Something went wrong while deleting your resource version...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const restoreSpecVersion = async () => {
    try {
      setLoader(true);
      onAction(specId, SpecActionType.ActionRestore, specVersion);
      setRestoreVersionModal(false);
      notification.success({
        message: `${specType} version restored successfully`,
        duration: 6,
      });
      await loadVersions(specId);
      onActionCompleted(specId, SpecActionType.ActionRestore);
    } catch (error) {
      console.log(error);
      notification.error({
        message:
          "Something went wrong while restoring to your resource version...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadVersions(specId);
  }, [specId]);

  const getBadgeByState = (state: SpecStateType) => {
    switch (state) {
      case SpecStateType.StateDraft:
        return <Badge count="draft" color="orange" />;
      case SpecStateType.StatePublishedDraft:
        return <Badge count="published draft" color="orange" />;
      case SpecStateType.StatePublishPending:
        return <Badge count="approval" color={token.colorInfo} />;
      case SpecStateType.StatePublished:
        return <Badge count="published" color="green" />;
      case SpecStateType.StateShared:
        return <Badge count="shared" color="blue"/>;
      default:
        return <></>
    }
  };

/*   const getVersionItems = async () => {
      return specVersions?.map((spec, index) => {
        dot: <ClockCircleOutlined />,
        children: 'Create a services site 2015-09-01',
      });
  };
 */

  return (
    <AntModal
      title={"Versions"}
      onCancel={onClose}
      open={open}
      width={width}
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loader}>
        <Timeline >
        {specVersions?.length &&
          specVersions.map((spec, index) => (
            <Timeline.Item 
              key={spec.version} 
              dot={<ClockCircleOutlined />} 
            >
              <Space direction="vertical">
                <Space>
                  {spec.versionName}
                  <div style={{ position: "absolute", right: 0, top: 0 }}>
                    {getBadgeByState(spec.state)}
                  </div>
                </Space>
                <Space style={{ flexDirection: "row" }}>
                  {spec.comments}
                  {editMode
                    &&
                    <Space style={{ position: "absolute", right: 0, top: 30 }}>
                      {(index == 0 && spec.state == SpecStateType.StatePublished)
                        ? 
                        <></>
                        :
                        (index > 0 && !specVersions?.find((spec, id) => id < index && spec.state == SpecStateType.StatePublished))
                          &&
                          <Tooltip key="restore" title="Restore" placement={"top"} color={token.colorPrimaryHover}>
                            <Button
                                type="default"
                                size="small"
                                icon={
                                  <RollbackOutlined style={{ color: token.colorPrimary }}/>
                                }
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  setSpecVersion(spec.version);
                                  setRestoreVersionModal(true);
                                }}
                            />
                          </Tooltip>
                      }
                      { spec.state == SpecStateType.StatePublished
                        ?
                          <></>
                        :
                          (specVersions?.length > 1) &&
                          <Tooltip key="delete" title="Delete" placement={"top"} color={token.colorPrimaryHover}>
                            <Button
                              type="default"
                              size="small"
                              icon={
                                <DeleteOutlined style={{ color: token.colorPrimary }}/>
                              }
                              onClick={(e: any) => {
                                e.stopPropagation();
                                setSpecVersion(spec.version);
                                setDeleteVersionModal(true);
                              }}
                            />
                          </Tooltip>
                      }
                    </Space>
                  }
                </Space>
              </Space>
            </Timeline.Item>
          ))
        }
        </Timeline>
        {deleteVersionModal && (
          <Modal
            title={`Delete ${specType} Version`}
            onClose={() => {
              setDeleteVersionModal(false);
            }}
            loader={loader}
            open={deleteVersionModal}
            onSubmit={deleteSpecVersion}
          >
            <Space direction="vertical">
              <Text>Are you sure you want to delete your {specType.toLocaleLowerCase()} version ?</Text>
              <Space>
                <WarningOutlined style={{ color: token.colorWarning }}/>
                <Text type="warning">Recommend to take backup of {specType.toLocaleLowerCase()}</Text>
              </Space>
            </Space>
          </Modal>
        )}
        {restoreVersionModal && (
          <Modal
            title={`Restore ${specType} Version`}
            onClose={() => {
              setRestoreVersionModal(false);
            }}
            loader={loader}
            open={restoreVersionModal}
            onSubmit={restoreSpecVersion}
          >
            <Space direction="vertical">
              <Text>Are you sure you want to restore your {specType.toLocaleLowerCase()} version ?</Text>
              <Space>
                <WarningOutlined style={{ color: token.colorWarning }}/>
                <Text type="warning">You will loss the current changes in {specType.toLocaleLowerCase()} on restore. Recommend to take backup of current {specType.toLocaleLowerCase()}</Text>
              </Space>
            </Space>
          </Modal>
        )}
      </Spin>
    </AntModal>
  );
};

export default VersionCard;
