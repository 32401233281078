import {
  BooleanField,
  EditorContext,
  NumberField,
  PrimitiveArrayField,
  TextField,
  ViewProps,
  rules,
} from "components/EntityEditor";
import React, { useContext, useEffect, useRef, useState } from "react";

import { DeveloperItem, IODataType, ResourceType } from "types";
import { Space } from "antd";

import { ViewSchema } from "../viewSchema";

export enum IODataUsageType {
  Parameter = "Parameter",
  Output = "Output",
}

export interface IODataViewProps extends ViewProps {
  type: IODataUsageType;
}

export const IODataView = (props: IODataViewProps) => {
  const editorCtx = useContext(EditorContext);
  const [recordKey, setRecordKey] = React.useState(props.data?.recordKey || "");
  const [recordValue, setRecordValue] = React.useState(
    props.data?.recordValue || {}
  );

  const [numericTypeSelected, setNumericTypeSelected] = React.useState(false);

  /** The view decides whether the child should show errors or not. In general,
   * erros are shown iff the user has touched the field. If the view has been touched,
   * then all field are shown errors.
   */
  const [showError, setShowError] = React.useState(false);

  const mounted = useRef(false);
  useEffect(() => {
    const doAction = () => {
      editorCtx.onChange({ recordKey, recordValue });
      setShowError(true);
    };
    mounted.current ? doAction() : (mounted.current = true);
  }, [recordKey, recordValue]);

  const onKeyChange = async (identifier: string, value: string) => {
    setRecordKey(value);
  };

  const onTextChange = async (identifier: string, value: string) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onTypeChange = async (identifier: string, value: string) => {
    if (value == IODataType.Numeric) {
      setNumericTypeSelected(true);
    } else {
      setNumericTypeSelected(false);
    }
    onTextChange(identifier, value);
  };

  const onBooleanChange = async (identifier: string, value: boolean) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  const onNumberChange = async (identifier: string, value: number) => {
    const rv = { ...recordValue, [identifier]: value };
    setRecordValue(rv);
  };

  /** On text field add*/
  const onArrayTextFieldAdd = async (key: string): Promise<DeveloperItem> => {
    const item = recordValue?.[key] || [];
    const index = item.length;
    const newItem = `value_${index}`;
    item.push(newItem);
    const rv = { ...recordValue, [key]: item };
    setRecordValue(rv);

    return {
      id: `value-${key}-${index}`,
      type: ResourceType.Primitive,
      item: { [0]: newItem },
    };
  };

  const onArrayTextFieldChange = async (
    key: string,
    value: DeveloperItem[]
  ) => {
    const rv = {
      ...recordValue,
      [key]: value.map((v) => v.item[0]),
    };
    setRecordValue(rv);
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <TextField
        identifier={"recordKey"}
        label={"Name"}
        help={`${props.type} name`}
        value={recordKey}
        path={[...props.path, recordKey]}
        onChange={onKeyChange}
        validators={[rules.required]}
        showError={showError}
      />
      <TextField
        identifier={"description"}
        label={"Description"}
        help={`The description about the ${props.type}`}
        value={recordValue?.["description"]}
        path={[...props.path, recordKey, "description"]}
        onChange={onTextChange}
        validators={[rules.required]}
        showError={showError}
      />
      <TextField
        identifier={"type"}
        label={"Type"}
        help={`${props.type} type`}
        value={recordValue?.["type"]}
        path={[...props.path, recordKey, "type"]}
        onChange={onTypeChange}
        options={Object.values(IODataType).map((v) => ({ label: v, value: v }))}
        validators={[rules.required]}
        showError={showError}
      />
      <BooleanField
        identifier={"required"}
        optional={true}
        label={"Required"}
        help={`${props.type} required or optional`}
        value={recordValue?.["required"]}
        checkedChildren="True"
        unCheckedChildren="False"
        defaultChecked={true}
        path={[...props.path, recordKey, "required"]}
        onChange={onBooleanChange}
        validators={[rules.required]}
        showError={showError}
      />

      {props.type == IODataUsageType.Parameter && numericTypeSelected && (
        <NumberField
          identifier={"min"}
          optional={true}
          label={"Minimum"}
          help={`${props.type} minimum value`}
          value={recordValue?.["min"]}
          path={[...props.path, recordKey, "min"]}
          onChange={onNumberChange}
          showError={showError}
        />
      )}
      {props.type == IODataUsageType.Parameter && numericTypeSelected && (
        <NumberField
          identifier={"max"}
          optional={true}
          label={"Maximum"}
          help={`${props.type} maximum value`}
          value={recordValue?.["max"]}
          path={[...props.path, recordKey, "max"]}
          onChange={onNumberChange}
          showError={showError}
        />
      )}

      <PrimitiveArrayField
        optional={true}
        value={
          recordValue?.["values"]?.map(
            (value: string, index: number): DeveloperItem => {
              return {
                id: `iodata-value-${index}`,
                item: { [0]: value },
                type: ResourceType.Primitive,
              };
            }
          ) || []
        }
        identifier={"values"}
        label={"List of possible values"}
        help={"Possible values collection"}
        addLabel={"Add Value"}
        showExpanded={false}
        onAdd={onArrayTextFieldAdd}
        onChange={onArrayTextFieldChange}
        path={[...props.path, recordKey, "values"]}
        showError={showError}
      />

      <TextField
        optional={true}
        identifier={"default"}
        label={"Default Value"}
        help={"default value to be used"}
        value={recordValue?.["default"]}
        path={[...props.path, recordKey, "default"]}
        onChange={onTextChange}
        options={recordValue?.["values"]?.map((v: string) => ({
          label: v,
          value: v,
        }))}
        showError={showError}
      />
    </Space>
  );
};
