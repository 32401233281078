import { Drawer } from "components/Drawer";
import { FC, useEffect, useState } from "react";
import { useProfileStore } from "store";

import { ContactEditor } from "./ContactEditor/ContactEditor";
import { Contact, ContactType } from "./ContactEditor";

export interface ContactDrawerProps {
  type: ContactType;
  visible: boolean;
  onClose: () => Promise<void>;
}

export const ContactDrawer: FC<ContactDrawerProps> = ({
  visible,
  onClose,
  type,
}) => {
  const [, setLoader] = useState(false);

  const { profileSecureData, getProfileSecureData } = useProfileStore(
    (state) => ({
      profileSecureData: state.profileSecureData,
      getProfileSecureData: state.getProfileSecureData,
      updatePrimaryEmail: state.updatePrimaryEmail,
    })
  );

  const [contacts, setContacts] = useState<Contact[]>([]);
  useEffect(() => {
    if (type === ContactType.Email && profileSecureData?.emails) {
      setContacts(
        profileSecureData.emails.map((x, index) => ({
          type: ContactType.Email,
          value: x,
          primary: profileSecureData.primaryEmailIndex == index,
        }))
      );
    }
    if (type === ContactType.Phone && profileSecureData?.phones) {
      setContacts(
        profileSecureData.phones.map((x, index) => ({
          type: ContactType.Phone,
          value: x,
          primary: profileSecureData.primaryPhoneIndex == index,
        }))
      );
    }
  }, [profileSecureData]);

  const fetchSecurityData = async () => {
    try {
      setLoader(true);
      await getProfileSecureData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!profileSecureData?.emails) {
      fetchSecurityData();
    }
  }, []);

  const getSubTitle = () => {
    if (type === ContactType.Email) {
      return "Email Address";
    } else return "Phone Number";
  };

  return (
    <Drawer
      title="Contact Information"
      subtitle={getSubTitle()}
      open={visible}
      onClose={async () => onClose()}
      hideFooter={true}
    >
      <ContactEditor contacts={contacts} type={type}></ContactEditor>
    </Drawer>
  );
};
