// import { Logger } from "tslog"
// const logger = new Logger({ name: "RootLogger",  minLevel: 2 })
// export { logger as HLogger }

import { FormInstance, notification } from "antd";
import { useTokenStore } from "store/token";
import { theme } from 'antd';
import { v4 as uuidv4 } from "uuid";
import { auth } from "api";
import { useOrganizationStore } from "store";
import { Layout, Layouts } from "react-grid-layout";
import { Widget } from "types";
const { getDesignToken } = theme;
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";

const globalToken = getDesignToken();

//TODO - tenants to hides all secrets of app configiration - temporarly till the time we have a separate permissions.
const tenantsToHideSecrets =  [ "146253cf-970c-4749-b79c-fb6461a3c663", "9b1250a5-351c-4e0f-ac1c-51c18598a6a3"]

export const generateRandomString = (lenString = 16) => {
  //define a variable consisting alphabets in small and capital letter
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

  let randomString = "";

  //loop to select a new character in each iteration
  for (let i = 0; i < lenString; i++) {
    const rNum = Math.floor(Math.random() * characters.length);
    randomString += characters.substring(rNum, rNum + 1);
  }

  return randomString;
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const  capitalizeFirstWord = (str: string): string =>{
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function addAlpha(color?: string, opacity?: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const getUserDetailsFromJWT = () => {
  const JWT = useTokenStore.getState().token;
  if (JWT) {
    const payload = JWT.split(".")[1];
    const decoded = JSON.parse(atob(payload));
    const tenantId = decoded["https://hypredge.com/rbac/zs_tenant_id"];
    const userId = decoded["https://hypredge.com/rbac/zs_user_id"];
    const scopes: string[] = decoded.scope?.split(" ") || [];
    const prefix = "zsgroup-";
    const context = scopes.find((scope) => scope.startsWith(prefix))?.replace(prefix, "");

    return {tenantId, userId, context};
  }
  return {tenantId: "", userId: "", context: ""};
};

export const showSecrets = () => {
  const tenantId = getUserDetailsFromJWT()?.tenantId;
  return tenantsToHideSecrets.indexOf(tenantId) == -1
}

export const  getAnyJSON = (elements:any) => {
  const downloadLink = document.createElement("a");
  const fileBlob = new Blob([JSON.stringify(elements, null, 2)], {
    type: "application/json",
  });
  downloadLink.href = URL.createObjectURL(fileBlob);
  downloadLink.download = "node-flow" + (Math.random()*100).toFixed(2) + ".json";
  downloadLink.click();
}

export const takeScreenShotByElementId = (elementID: string, nameSuffix: string, backgroundColor: string) => {
  const metricsElement =  document.getElementById(elementID);
  if (metricsElement != null) {
    html2canvas(metricsElement, {
      backgroundColor: backgroundColor,
      allowTaint: true, useCORS: true,
    }).then( (canvas) => {
      const el = document.createElement("a");
      el.setAttribute("download", `${Date.now().toString()}_${nameSuffix}.png`);
      el.setAttribute("href", canvas.toDataURL());
      el.click();
    }).catch(e => {
      console.log(`error occured while converting element  ${elementID} to png`, e);
    });

  }
}
export interface RegionDetails {
  id: string,
  infra: string,
  regions: string,
  location: { lat: number, lng: number },
  color: string
}

const regionLatLong : RegionDetails[]= 
  [
    {
      id: '8cd50e6e-cb80-47ba-a825-7af45724404f',
      infra: 'azure',
      regions: 'westus', 
      location : {lat: 38.58,lng: -121.49 },
      color : "blue"
    },
    {
      id: 'd72bb9d2-c522-4e5f-bddb-b29d2d7bafb2',
      infra: 'azure',
      regions: 'eastus2',
      location: { lat: 38.9586, lng: -77.3570 },
      color: "blue"
    },
    {
      id: '7ed9d56c-3d82-4d0c-885d-d01e43454389',
      infra: 'aws',
      regions: 'us-east-1',
      location: { lat: 38.9072, lng: -77.0369 },
      color: "red"
    },
    {
      id: 'b9ee2124-2c77-4dc8-a5ce-92846210d706',
      infra: 'oci',
      regions: 'us-sanjose-1' ,
      location: { lat: 37.3387, lng: -121.8853},
      color: "green"
    },
    {
      id: '85289324-7fe2-4124-822f-913fa7414569',
      infra: 'azure',
      regions: 'eastus',
      location: { lat: 40.7128, lng: -74.0060 },
      color: "blue"
    },
    {
      id: '0cb74166-a336-4fde-b9fb-3ad89fcbe5b0',
      infra: 'azure',
      regions: 'westus3', //  Arizona 33.4484° N, 112.0740° W
      location: { lat: 33.4484, lng: -112.0740 },
      color: "blue"
    },
    {
      id: '0afc9153-f1b9-49c7-87c9-2352bb83112c',
      infra: 'gcp',
      regions: 'us-east4' ,
      location: { lat: 40.7128, lng: -74.0060 },
      color: "yellow"
    },
    {
      id: 'f4f260db-6b2f-4642-b90e-37287adb0d15',
      infra: 'oci',
      regions: 'us-ashburn-1',
      location: { lat: 39.0438, lng: -77.4874 },
      color: "green"
    },
    {
      id: 'ca861d1a-0eaa-4c7f-8f2e-f84a47a3c0bd',
      infra: 'gcp',
      regions: 'us-west2',
      location: { lat: 37.3387, lng: -121.8853 },
      color: "yellow"
    },
    {
      id: '12063f0f-8210-433e-90bb-19a4313b62ae',
      infra: 'gcp',
      regions: 'us-east1' ,
      location: { lat: 33.1960, lng: -80.0364 },
      color: "yellow"
    },
    {
      id: 'beeefb97-cd00-49a9-8870-b7bf6fedd79f',
      infra: 'oci',
      regions: 'us-phoenix-1',
      location: { lat: 33.4484, lng: -112.0740 },
      color: "green"
    },
    {
      id: 'b8ada882-8933-4440-937c-a5c36b522bf6',
      infra: 'aws',
      regions: 'us-west-1',
      location: { lat: 37.7749, lng: -122.4194 },
      color: "red"
    },
    {
      id: '83d126da-f0a8-48fc-bcdc-5e905c729de4',
      infra: 'aws',
      regions: 'us-west-2',
      location: { lat: 37.3387, lng: -121.8853 },
      color: "red"
    },
    {
      id: '09e2801b-d28b-433a-97f3-dec2d7e399c1',
      infra: 'aws',
      regions: 'us-east-2',
      location: { lat: 38.9072, lng: -77.0369 },
      color: "red"
    },
    {
      id: 'b14bcd2c-01ea-41db-8a07-5bb56d4026f8',
      infra: 'gcp',
      regions: 'us-west1',
      location: { lat: 37.7749, lng: -122.4194 },
      color: "yellow"
    },
    {
      id: "3009e9d6-f2cf-4ffd-9f28-3be9773321d1",
      infra: "azure", 
      regions: "westus2",
      location: { lat: 38.9586, lng: -77.3570 },
      color: "blue"
    }, 
    {
      id: "df5785a1-f2cc-4579-a173-be4292a271a3",
      infra: "oci",
      regions: "eu-amsterdam-1",
      location: { lat: 52.3702, lng: 4.8952 },
      color: "green"
    }
  ];

export const getRegionDetails = (regionId: string) => {
  return regionLatLong.find((region) => region.id === regionId)|| {} as RegionDetails;
}

export const validateFormFields = async (form?: FormInstance) : Promise<any> =>  {
  try {
    if (form) {
      await form.validateFields();
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  } catch (errors) {
    //console.log("validateFormFields failed", errors);
    return Promise.reject();
  }
};

const avatarColors = [
  "#be8ccb",
  "#7a46b4",
  "#cb80aa",
  "#7c46a0",
  "#d769a7",
  "#704889",
  "#dd4eb5",
  "#793f63",
  "#da78e6",
  "#93577c",
  "#a96cd6",
  "#9a2e6a",
  "#d67ecc",
  "#9a4876",
  "#a43aa0",
  "#8c588c",
  "#b03d8d",
  "#9867ae",
  "#a35b97",
  "#8d4b96"
]

const tagColors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "gold",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "green",
  "lime"
];

function hashCode(str: string) {
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

export const mapStringToNumberInRange = (
  str: string,
  min: number,
  max: number
) => {  
  const hash = hashCode(str);
  const mapped = min + (hash % (max - min + 1));
  return mapped;
};

// Simple hash function for strings
export const getTagColor = (input: string): string => {
  const index = mapStringToNumberInRange(input, 0, tagColors.length - 1);
  return tagColors[index];
};

export const getAvatarColor = (input: string): string => {
  const index = mapStringToNumberInRange(input, 0, avatarColors.length - 1);
  return avatarColors[index];
};

export interface ContextSetupOptions {
  context: string;
  environment: string;
  silent: boolean;
  setToken: (token: string) => void, 
  activateContext: (context: string) => void,
  clearContextRequest: () => void 
}

export const setupContext = async (options: ContextSetupOptions): Promise<void> => {
  const { context, environment, setToken, activateContext, clearContextRequest , silent} = options;
  
  const authState = `${environment}-${uuidv4()}`;
  const b64AuthState = btoa(authState);
  const scope = `zsgroup-${context}`;

  console.log(`Checking session with scope ${scope}`);
  auth.checkSession(
    {
      scope: `openid profile ${scope}`,
      responseType: "token id_token",
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      state: b64AuthState,
    },
    (err, authResult) => {
      if (err) {
        console.log(err);
        throw err;
      } else {
        if (authResult?.accessToken) {
          const JWT = authResult.accessToken;
          if (b64AuthState === authResult.state) {
            const payload = JWT.split(".")[1];
            const decoded = JSON.parse(atob(payload));
            const scopes: string[] = decoded.scope?.split(" ") || [];
            const tenantId = decoded["https://hypredge.com/rbac/zs_tenant_id"];
            const prefix = "zsgroup-";
            const context = scopes.find((scope) => scope.startsWith(prefix))?.replace(prefix, "");
            setToken(JWT);      
            activateContext(context ?? `${tenantId}_tag`);
          } else {
            if (!silent) {
              console.log("State mismatch during auth, cannot set token");
              notification.error({message: "State mismatch during auth, cannot set token", duration: 6});
            }
            console.log("State mismatch during auth, cannot set token");
          }
        } else {
          if (!silent) {
            console.log("Failed to get access token");
            notification.error({message: "Failed to get access token", duration: 6});
          }
        }
        clearContextRequest();        
      }
    }
  );
}

const _buildTagsTree = (ids: string[], tree: any[]) => {
  const subscribers = useOrganizationStore.getState().subscribers;
  const id = ids.shift();
  if (id) {
    const index = tree?.findIndex((t) => t.key == id);
    if (index < 0) {
      tree.push({
        title: subscribers[id].name,
        key: id,
        value: id,
        children: []
      })
      ids.length != 0 && _buildTagsTree(ids, tree?.[tree.length-1]?.children);
    } else {
      _buildTagsTree(ids, tree?.[index]?.children);
    }
  }
}

export const buildTagsTree = (tree: any[]) => {
  const subscribers = useOrganizationStore.getState().subscribers;
  Object.values(subscribers).map((subscriber) => {
    const ids = subscriber.nodePath.split("/");
    _buildTagsTree(ids, tree);
  });
}

export const generateLayout = (widgets: Widget[], cols: number): Layout[] => {
  const layout: Layout[] = [];
  let currentRow = 0;
  let colTracker = Array(cols).fill(0); // Tracks the occupied columns at each row index

  widgets.sort((a,b)=> {
    if (a.position != b.position)
      return a.position - b.position;
    return a.title.localeCompare(b.title);
  }).forEach((widget) => {
    const width = 2;

    // Find the first available position where the widget can fit
    let x = 0;
    while (x <= cols - width) {
      // Check if the widget fits starting at column x
      const canFit = colTracker
        .slice(x, x + width)
        .every((col) => col <= currentRow);
      if (canFit) break;
      x++;
    }

    // If no position found in the current row, move to the next row
    if (x > cols - width) {
      currentRow++;
      colTracker = Array(cols).fill(currentRow); // Reset to the new row
      x = 0; // Start checking from the beginning of the new row
    }

    // Place the widget at the found position
    layout.push({
      i: widget.id,
      x: x,
      y: currentRow,
      w: width,
      h: 1, // Default height is 1; adjust if needed
      minW: width,
    });
    // Mark columns as occupied by the widget
    for (let i = x; i < x + width; i++) {
      colTracker[i] = currentRow + 1; // Occupy the columns and move to the next row after placement
    }
  });

  return layout;
};
