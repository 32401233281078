
import { SpecStateType, AccessInfo, AppInfos , IODataMap, IODatas } from "types";

export enum ActionType {
    Custom = "custom"
}
export enum ActionAccessType {
    //ActionAccessTypeReadOnly only gathers information
    ActionAccessTypeReadOnly = "read_only",
    //ActionAccessTypeReadWrite modifies the things in the environment with collection
    ActionAccessTypeReadWrite = "read_write",
    //ActionAccessTypeWriteOnly modifies the things in the environment
    ActionAccessTypeWriteOnly = "write_only"
}

export interface Action {
    id: string;
    comments: string;
    state: SpecStateType;
    shared: boolean;
    name: string;
    displayName: string;
    description: string;
    type: ActionType;
    category: string;
    visibility: boolean;
    accessType: ActionAccessType;
    appInfos?: AppInfos;
    parameters: IODataMap;
    outputs: IODatas;
    accessInfo: AccessInfo;
    updatedAt: string;
    createdAt: string;
    tenantID: string;
    userID: string;
    tags: string[];
}

export type ActionUploadObjectType = Omit<Action, 'state' | 'shared'  |  'appInfos' | 'accessInfos'| 'updatedAt' | 'createdAt'| 'userID' >;