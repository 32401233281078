import {
  SpecType,
  SpecStateType,
  AccessInfo
} from "types";

export enum DeveloperTabType {
  Apps = "Apps",
  Actions = "Actions",
  Triggers = "Triggers",
  Artifacts = "Artifacts",
  Providers = "Providers",
  ActionProviders = "ActionProviders",
  TriggerProviders = "TriggerProviders",
}

export interface DeveloperTab {
  tabType: DeveloperTabType,
  specType: SpecType
}

export interface DeveloperDataType {
  item: any;
  id: string;
  key: string;
  displayName: string;
  description: string;
  state: SpecStateType;
  specType: SpecType;
  shared: boolean;
  accessInfo: AccessInfo;
  updatedAt: string;
  onEdit: (id: string) => void;
}

export enum DeveloperNodeCardType {
  App = "app"
}

export interface DeveloperCardProps {
  onSave: () => void;
}


// Store for managing developer page
export enum ResourceType {
  User = "user",
  Role = "role",
  PermissionSet = "permissionSet",
  ClientCredentials = "clientCredentials",
  Action = "actions",
  Trigger = "triggers",
  Artifact = "artifacts",
  App = "apps",
  ActionProvider = "action-providers",
  TriggerProvider = "trigger-providers",
  ArtifactProvider = "artifact-providers",
  AppEdgeDetector = "appEdgeDetector",
  AppEdgeDetectorConstant = "appEdgeDetectorConstant",
  AppEdgeDetectorConfiguration = "appEdgeDetectorConfiguration",
  Parameters = "parameters",
  Outputs = "outputs",
  IODataParameter = "ioDataParameter",
  IODataOutput = "ioDataOutput",
  IODataMapOutput = "ioDataMapOutput",
  IODataArrayOutput = "ioDataArrayOutput",
  ArtifactField = "artifactField",
  Primitive = "primitives",
  Condition = "condition",
  Conditions = "conditions",
  HttpRequest = "httpRequest",
  HttpBasicAuth = "httpBasicAuth",
  HttpOAuth = "httpOAuth",
  HttpOAuthClientCredentials = "httpOAuthClientCredentials",
  HttpRetryPolicy = "httpRetryPolicy",
  HttpPollPolicy = "httpPollPolicy",
  HttpPollIncrementalTokenLocatorHeaders = "httpPollIncrementalTokenLocatorHeaders",
  HttpPollIncrementalTokenLocatorPayload = "httpPollIncrementalTokenLocatorPayload",
  HttpPollIncrementalTokenLocatorQuery = "httpPollIncrementalTokenLocatorQuery",
  HttpPollIncrementalTokenReaderTimestamp = "httpPollIncrementalTokenReaderTimestamp",
  HttpPollIncrementalTokenReaderResponseHeader = "httpPollIncrementalTokenReaderResponseHeader",
  HttpPollIncrementalTokenReaderResponsePayload = "httpPollIncrementalTokenReaderResponsePayload",

  Unknown = "Unknown"
}

export interface DeveloperXItem {
  item: any;
  specType: SpecType;
}

export interface DeveloperItem {
  /** transient id, doesnt map to the item id */
  id: string;
  /** Actual item / entity to be manipulated  */
  item: { [k: string | number]: any };
  type: ResourceType;
  /** Optional contextual data to store any arbitrary information associated with the item */
  context?: { [k: string | number]: any };
}

export const INVALID_DEVELOPER_ITEM: DeveloperItem = {
  id: "",
  item: {},
  type: ResourceType.Unknown,
}

type PathItem = string | number

/** PathItem array */
export type Path = PathItem[];


export interface FieldValidationRule {
  name: string;
  message: string;
  validator: (value: any) => boolean;
}
