import { IOData, IODataArray, IODataMap } from "types";

class TypeGuards {
  isIOData = (value: unknown): value is IOData => {
    if (value == null) return false;
    return (
      typeof value === "object" &&
      "description" in value &&
      "type" in value &&
      "required" in value
    );
  };

  isIODataMap = (value: unknown): value is IODataMap => {
    if (!value) return false;
    if (Array.isArray(value)) {
      return false;
    }

    Object.entries(value).forEach((k, v) => {
      if (typeof k !== "string") {
        return false;
      }
      if (!this.isIOData(v)) {
        return false;
      }
    });
    return true;
  };

  isIODataArray = (value: unknown): value is IODataArray => {
    if (value == null) return false;
    if (!Array.isArray(value)) {
      return false;
    }
    for (const element of value) {
      if (!this.isIODataMap(element)) {
        return false;
      }
    }
    return true;
  };
}

const typeGuards = new TypeGuards();
export { typeGuards };
