import React, { useContext, useEffect } from "react";
import { Space, Spin } from "antd";
import {
  EditorContext,
  PageContext,
  TextField,
  ViewerType,
  ViewProps,
  rules
} from "components/EntityEditor";
import { useDeveloperStore } from "store";
import { ViewSchema } from "./viewSchema";
import { ArtifactFieldsField } from "components/EntityEditor/artifactFieldsField";

/** Function component with app view props as argument */
export const ArtifactView = (props: ViewProps) => {
  const [loader, setLoader] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);

  const onTextChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };

  const onDisplayNameChange = async (identifier: string, value: string) => {
    const name = value.replaceAll(" ", "_").toLowerCase();
    setTarget({ ...target, [identifier]: value, [schema.name.identifier]: name });
  };

  const onFieldsChange = async (key: string, value: { [k: string]: any }) => {
    setTarget({ ...target, [key]: value });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  const schema: { [k: string]: ViewSchema } = {
    name: {
      identifier: "name",
      label: "Name",
      help: "The name of the action",
      validators: [rules.required, rules.minLength(1), rules.maxLength(1024)],
    },
    displayName: {
      identifier: "displayName",
      label: "Name",
      help: "The display name of the action",
      validators: [rules.required, rules.minLength(1), rules.maxLength(1024)],
    },
    description: {
      identifier: "description",
      label: "Description",
      help: "The description of the action",
      validators: [rules.required, rules.minLength(1), rules.maxLength(2048)],
    },
    fields: {
      identifier: "fields",
      label: "Fields",
      help: "The fields of the artifact which user will be able to search",
      validators: [rules.required],
    },
  };

  return (
    <Spin spinning={loader}>
      <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
        <TextField
          identifier={schema.displayName.identifier}
          label={schema.displayName.label}
          value={target[schema.displayName.identifier]}
          path={[...props.path, schema.displayName.identifier]}
          validators={schema.displayName.validators}
          help={schema.displayName.help}
          onChange={onDisplayNameChange}
        />
        <TextField
          identifier={schema.description.identifier}
          label={schema.description.label}
          value={target[schema.description.identifier]}
          path={[...props.path, schema.description.identifier]}
          validators={schema.description.validators}
          help={schema.description.help}
          onChange={onTextChange}
        />
        <ArtifactFieldsField
          identifier={schema.fields.identifier}
          optional={false}
          label={schema.fields.label}
          help={schema.fields.help}
          value={target[schema.fields.identifier] || {}}
          path={[...props.path, schema.fields.identifier]}
          onChange={onFieldsChange}
          viewerType={ViewerType.CardViewer}
          onKeyChange={async () => {
            return true;
          }}
          showExpanded={true}
        />
      </Space>
    </Spin>
  );
};
