import { Avatar, notification, Space, Spin, theme, Tooltip, Typography } from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  ReloadOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { DataType } from "@textea/json-viewer";

import { FC, useEffect, useState } from "react";

import Modal from "components/Modal";
import { useOrganizationStore, useCaseAttachmentStore, useUserInfoStore } from "store";
import { CaseAttachment, dateTimeFormatOptions } from "types";
import { TableProps } from "antd/lib";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/lib/table";
import { HLink } from "components/Link";
import { getUserDetailsFromJWT } from "utility";
import FileSaver from "file-saver";
import { TextWithIcon } from "components/TextWithIcon";
import { caseMgmtIcons } from "assets/icons";
import { FileUpload } from "./FileUpload";
import { getFileIcon } from "./utils";

const { Text } = Typography;

export interface CaseMgmtAttachmentsProps {
  caseId: string;
}

export const CaseMgmtAttachments: FC<CaseMgmtAttachmentsProps> = ({
  caseId
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [uploadAttachmentModal, setUploadAttachmentModal] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<any[]>([]);
  const context = useOrganizationStore((state) => state.context);

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const {
    caseAttachments,
    totalCaseAttachmentsCount,
    getCaseAttachments,
    uploadCaseAttachment,
    downloadCaseAttachment,
    deleteCaseAttachment,
    attachmentSearchTextMap,
    setAttachmentSearchTextMap,
    attachmentPage,
    setCurrentPage,
    attachmentFilter,
    setAttachmentFilter,
    attachmentSorter,
    setAttachmentSorter,
  } = useCaseAttachmentStore((state) => ({
    caseAttachments: state.attachments,
    totalCaseAttachmentsCount: state.totalCount,
    getCaseAttachments: state.getCaseAttachments,
    uploadCaseAttachment: state.uploadCaseAttachment,
    downloadCaseAttachment: state.downloadCaseAttachment,
    deleteCaseAttachment: state.deleteCaseAttachment,
    attachmentSearchTextMap: state.searchTextMap,
    setAttachmentSearchTextMap: state.setSearchTextMap,
    attachmentPage: state.pageInfo,
    setCurrentPage: state.setCurrentPage,
    attachmentFilter: state.filter,
    setAttachmentFilter: state.setSorter,
    attachmentSorter: state.sorter,
    setAttachmentSorter: state.setSorter
  }));
  
  const loadCaseAttachments = async (caseId: string) => {
    try {
      setLoader(true);
      await getCaseAttachments(caseId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    caseId && caseId !="" && loadCaseAttachments(caseId);
  }, [caseId, attachmentPage, attachmentSearchTextMap, attachmentSorter, attachmentFilter, context]);

  //Fetch user info for all users linked to cases
  useEffect(() => {
    try {
      const userIds = [] as string[];
      caseAttachments.map((c) => {
        c.userID && c.userID != "" && userIds.push(c.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [caseAttachments]);
  
  const OnDeleteCaseAttachments = async (items: any[]) => {
    try {
      setLoader(true);
      const attachments = items as CaseAttachment[];
      await Promise.all(
        attachments.map(async (a) => {
          await deleteCaseAttachment(caseId, a.id);
          notification.success({
            message: `Case attachment ${a.name} deleted successfully`,
            duration: 6,
          });
        })
      );
      loadCaseAttachments(caseId);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting case attachment",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const onUploadCaseAttachment = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      uploadFiles.map((file) => formData.append('files', file));
      await uploadCaseAttachment(caseId, formData);
      loadCaseAttachments(caseId);
      notification.success({
        message: `Case attachment is uploaded successfully`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: `Failed to upload case attachment, ${JSON.stringify(error)}`,
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDownloadCaseAttachment = async (items: CaseAttachment[]) => {
    try {
      setLoader(true);
      const caseAttachments = items as CaseAttachment[];
      await Promise.all(
        caseAttachments.map(async (caseAttachment) => {
          const responseData = await downloadCaseAttachment(caseAttachment.caseID, caseAttachment.id);
          const blob = new Blob([responseData], {type: caseAttachment.type});
          FileSaver.saveAs(blob, caseAttachment.name);
          notification.success({
            message: `Case attachment ${caseAttachment.name} downloaded successfully`,
            duration: 6,
          });
        })
      );
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while downloading attachments...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const BytesToSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';

    const KB = 1024;
    const MB = KB * 1024;

    if (bytes >= MB) {
        return (bytes / MB).toFixed(0) + ' MB';
    } else {
        return (bytes / KB).toFixed(0) + ' KB';
    }
}


  const columns: ColumnsType<CaseAttachment> = [   
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (_, a) => (
        <Space>
          {getFileIcon(a.type)}
          <Text>{a.name}</Text>
        </Space> 
      ),
    },
   /*  {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (type) => (
        <Text>{type}</Text>
      ),
    }, */
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (size: number) => (
        <Text>{BytesToSize(size)}</Text>
      ),
    },
    {
      title: "Uploaded By",
      dataIndex: "userID",
      key: "userID",
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Uploaded At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (createdAt: string) => (
        new Date(createdAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        )
      ),
    },
  ]

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setAttachmentSorter(sorter);
    setAttachmentFilter(filters);
    setCurrentPage();
  }

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header={"Attachments"}
        dataSource={caseAttachments as any[]}
        columns={columns}
        pageNumber={attachmentPage.number}
        pageSize={attachmentPage.size}
        total={totalCaseAttachmentsCount}
        onChange={onChange}
        onSearch={(text: string) => {
          setCurrentPage();
          setAttachmentSearchTextMap(new Map<string, string>().set("name", text));
        }}
        searchPlaceholder={"search attachments by name"}
        searchText={attachmentSearchTextMap?.get("name")}
        onPageChange={(pageNumber: number, pageSize: number)  => setCurrentPage(pageNumber, pageSize)}
        getItemProps={() => {
          return {
            disabled: false,
          };
        }}
        actions={
          [
            {
              key: "refresh", 
              label: <Tooltip title="Refresh"><ReloadOutlined /></Tooltip>, 
              enable: () => true, 
              onClick: () => loadCaseAttachments(caseId)
            },
            {
              key: "upload", 
              label: <Tooltip title="Upload"><UploadOutlined /></Tooltip>, 
              enable: () => true, 
              onClick: () => setUploadAttachmentModal(true)
            },
            {
              key: "download", 
              label: <Tooltip title="Download"><DownloadOutlined /></Tooltip>, 
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => caseAttachments.find((c) => c.id == id));
                } else {
                  return false;
                }
              },
              showWarn: false,
              onClick: (value) => {
                if (value) {
                  const itemIds = value as string[];
                  const items = caseAttachments.filter((c) => itemIds.find((id) => id == c.id))
                  if (items) {
                    OnDownloadCaseAttachment(items);
                  }
                }
              }
            },
            {
              key: "delete", 
              label: <Tooltip title="Delete"><DeleteOutlined /></Tooltip>,
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => caseAttachments.find((c) => c.id == id && c.userID == getUserDetailsFromJWT()?.userId));
                } else {
                  return false;
                }
              }, 
              hidden: !context?.endsWith('_tag'),
              showWarn: true,
              onClick: (value) => {
                const itemIds = value as string[];
                const items = caseAttachments.filter((c) => itemIds.find((id) => id == c.id))
                if (items) {
                  OnDeleteCaseAttachments(items);
                }
              }
            },
          ]
        }
      />  
      {uploadAttachmentModal 
        &&
        <Modal
          loader={loader}
          title={
            <TextWithIcon 
              icon={caseMgmtIcons.caseMgmtAttachmentsIcon}
              iconSize={18}
              text={"Attachments"}
            />
          }
          open={uploadAttachmentModal}
          footerName={"Upload"} 
          onClose={() => setUploadAttachmentModal(false)}
          onSubmit={() => {
            setUploadAttachmentModal(false);
            onUploadCaseAttachment();
          }}
        >
          <FileUpload 
            multiple={true} 
            onUpload={(files) => setUploadFiles(files)}
          />
        </Modal>
      } 
    </Spin>
  );
};