import { Typography } from "antd";
import { CollectionField } from "components/EntityEditor";

import {
  CollectionMutatorPosition,
  FieldProps,
  ViewerType,
} from "components/EntityEditor/common";

import { useEffect, useState } from "react";
import {
  ArtifactField,
  ArtifactFieldType,
  DeveloperItem,
  ResourceType,
} from "types";
import { resourceFactory } from "utility/resource";

export interface ArtifactFieldsFieldProps extends FieldProps {
  value: { [k: string]: any };
  viewerType: ViewerType;
  onChange: (key: string, value: { [k: string]: any }) => void;
  onKeyChange: (key: string, value: string) => void;
  showExpanded?: boolean;
}

const { Text } = Typography;

export const ArtifactFieldsField = (props: ArtifactFieldsFieldProps) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const [target, setTarget] = useState<{ [k: string]: any }>(props.value);

  useEffect(() => {
    setTarget(props.value);
  }, [props.value]);

  const onArtifactFieldAdd = async (key: string): Promise<DeveloperItem> => {
    const newField = resourceFactory.createArtifactField();
    const count = Object.keys(target).length;
    return {
      id: `field-${count}`,
      item: { recordKey: `field_${count}`, recordValue: newField },
      type: ResourceType.ArtifactField,
      context: {
        cardTitle: "Artifact Field",
      },
    };
  };

  const onArtifactFieldChange = async (key: string, value: DeveloperItem[]) => {
    const newTarget: { [k: string]: any } = value.reduce((acc, cur) => {
      acc[cur.item.recordKey] = cur.item.recordValue;
      return acc;
    }, {} as { [k: string]: any });

    setTarget(newTarget);
    props.onChange(props.identifier, newTarget);
  };

  return (
    <>
      <CollectionField
        optional={props.optional}
        value={Object.keys(target).map((key: string) => {
          return {
            id: `artifactField-${key}`,
            item: { recordKey: key, recordValue: target[key] },
            type: ResourceType.ArtifactField,
            context: {
              cardTitle: "Artifact Field",
            },
          };
        })}
        columns={[
          {
            title: "Name",
            dataIndex: ["item", "recordKey"],
          },
          {
            title: "Description",
            dataIndex: ["item", "recordValue", "description"],
          },
          {
            title: "Type",
            dataIndex: ["item", "recordValue", "type"],
          },
          {
            title: "Filterable",
            dataIndex: ["item", "recordValue", "filterable"],
            render: (filterable: boolean) => {
              return <Text>{filterable ? "on" : "off"}</Text>;
            },
          },
        ]}
        identifier={props.identifier}
        label={props.label}
        help={props.help}
        onChange={onArtifactFieldChange}
        onAdd={onArtifactFieldAdd}
        viewerType={props.viewerType}
        path={[props.identifier]}
        showTableHeader={true}
        showExpanded={props.showExpanded || false}
        mutatorPosition={CollectionMutatorPosition.InBetween}
        allowedTypes={[ResourceType.ArtifactField]}
        loading={false}
        showAdd={true}
        showCount={true}
        showDelete={true}
      />
    </>
  );
};
