import { IOData, IODataArray, IODataMap, ActionProvider, TriggerProvider, Trigger, ArtifactProvider, IODataValue, IODataValueMap, IODataValueArray  } from "types";


class TypeGuards {
  isIOData = (value: unknown): value is IOData => {
    if (value == null ) return false;
    return typeof value === 'object' && 
        'description' in value && 
        'type' in value && 
        'required' in value 
  }

  isIODataMap = (value: unknown): value is IODataMap => {
    if (!value) return false;
    Object.entries(value).forEach( (k,v)=>{
      if (typeof k !== 'string') {
        return false;
      }
      if (!this.isIOData(v)) {
        return false;
      }
    })
    return true;
  }

  isIODataArray = (value: unknown): value is IODataArray => {
    if (value == null) return false;
    if (!Array.isArray(value)) {
      return false;
    }
    for (const element of value) {
      if (!(this.isIODataMap(element))) {
        return false;
      }
    }
    return true;
  }

  isIODataValue = (value: unknown): value is IODataValue => {
    if (value == null ) return false;
    return typeof value === 'string' || 
      typeof value === 'number' || 
      typeof value === 'boolean' ||
      (typeof value === 'object')
  }

  isIODataValueMap = (value: unknown): value is IODataValueMap => {
    if (value == null ) return false;
    return typeof value === 'object'
  }

  isIODataValueArray = (value: unknown): value is IODataValueArray => {
    if (value == null ) return false;
    return typeof value === 'object'
  }


  isActionProvider = (value: ActionProvider | TriggerProvider | ArtifactProvider ): value is ActionProvider => {
    return 'actionID' in value;
  }

  isTriggerProvider = (value: ActionProvider | TriggerProvider | ArtifactProvider ): value is TriggerProvider => {
    return 'triggerID' in value;
  }


  isArtifactProvider = (value: ActionProvider | TriggerProvider | ArtifactProvider ): value is ArtifactProvider => {
    return 'artifactID' in value;
  }
}

const typeGuards = new TypeGuards();
export { typeGuards };
