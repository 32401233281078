import { FC } from "react";

import { theme, Typography, Descriptions, Space } from "antd";

import { IOData, IODataMap, IODataTypeMap, NodeCardOutputsProps, WorkflowNodeType } from "types";
import CollapsePanel from "components/CollapsePanel";
import { workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";

const { Text } = Typography;

const NodeCardOutputs: FC<NodeCardOutputsProps> = ({
  nodeType,
  outputs
}) => {
  const { token } = theme.useToken();

  const OutputDisplay: FC<{outputName: string, output: IOData}> = ({outputName, output}) => {
    return (
      <CollapsePanel
        name={
          <Space size={token.marginXXS}>
            <SvgIcon component={workflowIcons.outputsShortIcon} />
            <Text>{output.displayName ? output.displayName : outputName}</Text>
          </Space>
        }
        ghost={false}
        collapsePanel={true}
      >
        <Descriptions
          size="small"
          colon={false}
          column={1}
          bordered
        >
          <Descriptions.Item label="Name">
            <Text>{outputName}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            <Text style={{ overflow: "hidden" }}>
              {output.description}
            </Text>  
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {IODataTypeMap.get(output.type)}
          </Descriptions.Item>
          <Descriptions.Item label="Expected">
            <Text>{output.required?"Yes":"No"}</Text>
          </Descriptions.Item>
          {output.values
            &&
            <Descriptions.Item label="Values">
              <Text>{output.values.join(", ")}</Text>
            </Descriptions.Item>
          }
          {output.default
            &&
            <Descriptions.Item label="Default">
              <Text>{output.default}</Text>
            </Descriptions.Item>
          }
        </Descriptions>
      </CollapsePanel>
    )
  }

  return (
    <>
      {Object.entries(outputs)?.map(([outputName, output]) => 
        <Space key={outputName} direction="vertical" style={{ display: 'flex' }}>
          {"type" in output
            ?
              <OutputDisplay outputName={outputName} output={output} />
            :
              Array.isArray(output)
              ?
                <CollapsePanel
                  name={
                    <Space size={token.marginXXS}>
                      <SvgIcon component={workflowIcons.outputsShortIcon} />
                      <Text>{outputName + " (List)"}</Text>
                    </Space>
                  }
                  ghost={false}
                  collapsePanel={true}
                >
                  {output.map((outputMap: IODataMap, index: number) => 
                    Object.entries(outputMap)?.map(([name, out]) =>
                      <OutputDisplay key={name} outputName={name} output={out} />
                    )
                  )}
                </CollapsePanel>
              :
                <CollapsePanel
                  name={
                    <Space size={token.marginXXS}>
                      <SvgIcon component={workflowIcons.outputsShortIcon} />
                      <Text>{outputName + " (Map)"}</Text>
                    </Space>
                  }
                  ghost={false}
                  collapsePanel={true}
                >
                  {Object.entries(output)?.map(([name, o]) => 
                    <OutputDisplay key={name} outputName={name} output={o as IOData} />
                  )}
                </CollapsePanel>
          }
        </Space>
      )}
    </> 
  );
};

export default NodeCardOutputs;
