import {  useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useDeveloperStore } from "store";
import {
  ResourceType,
  DeveloperItem,
  INVALID_DEVELOPER_ITEM,
} from "types";

import DevEditor from "pages/DevEditor";
import { getSpecTypeFromResourceType } from "utility/developer";
import { resourceFactory } from "utility/resource";

export const DeveloperRoute = () => {
  const { specResourceType, specId } = useParams();
  const [loading, setLoading] = useState(false);
  const activeItem = useDeveloperStore((state) => state.activeItem);
  const setActiveItem = useDeveloperStore((state) => state.setActiveItem);
  const doSpecFetch = useDeveloperStore((state) => state.doSpecFetch);
  
  const syncSpec = async () => {
    try {
      setLoading(true);      
      const specType = getSpecTypeFromResourceType(specResourceType as ResourceType);
      if (specType && specId) {
        const obj = await doSpecFetch(specType, specId);
        const di: DeveloperItem = {
          id: specId,
          item: obj,
          type: specResourceType as ResourceType,
        };
        setActiveItem(di);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createSpec = async() => {
    const obj = resourceFactory.createSpec(specResourceType as ResourceType)
    const di: DeveloperItem = {
      id: "",
      item: obj,
      type: specResourceType as ResourceType,
    };
    setActiveItem(di);
  }

  useEffect(() => {
    specResourceType && (specId && specId != "add" ? syncSpec(): createSpec());
  }, []);
  
  useEffect(() => {
    return () => {
      setActiveItem(INVALID_DEVELOPER_ITEM);
    };
  }, []);
  
  return (
    <Spin spinning={loading}>
      {activeItem.type != ResourceType.Unknown && <DevEditor />}
    </Spin>
  );
};
