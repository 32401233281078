import { 
  Action,
  Trigger, 
  IODataMap, IODatas, IOData, 
  ResourceType, 
  ActionType,
  TriggerType,
  Artifact,
  ArtifactFields,
  ArtifactField,
  App,
  AppConfiguration,
  ActionProvider,
  IODataValueOutputs,
  HttpAdapterSpec,
  HttpRequest,
  TriggerProvider,
  WebhookAdapter,
  AdapterType,
} from "types"
import { typeGuards } from "./type_guards";

export enum ValidationType {
  Create = "create",
  Update = "update",
  Publish = "publish"
}
export function validateSpec(resourceType: ResourceType, obj:unknown, type: ValidationType):boolean  {
  //console.log("validating request for spec " , resourceType, obj)
  switch(resourceType){
    case ResourceType.Action:
      return validateAction(obj as Action, type);
    case ResourceType.Trigger:
      return validateTrigger(obj as Trigger, type);
    case ResourceType.Artifact:
      return validateArtifact(obj as Artifact, type);
    case ResourceType.App:
      return validateApp(obj as App, type);
    case ResourceType.ActionProvider:
      return validateActionProvider(obj as ActionProvider, type);
      case ResourceType.TriggerProvider:
        return validateTriggerProvider(obj as TriggerProvider, type);
    default:
      return false;
  }
}

function validateAction(o: Action, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
    || !o.accessType || (o.type != ActionType.Custom) 
  ) ?  false: true;

  if(type == ValidationType.Create || !result) return result;

  //update or publish
  result = (
    (o.parameters && !validateParameters(o.parameters)) 
    || (o.outputs && !validateOutputs(o.outputs)) 
  )? false: true;
  
  return result;
}

function validateTrigger(o: Trigger, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
    ||  (o.type != TriggerType.Custom) 
  )? false: true;
  
  if(type == ValidationType.Create || !result) return result;
  
  //update or publish
  result = (
    (o.parameters && !validateParameters(o.parameters)) 
    || (o.outputs && !validateOutputs(o.outputs)) 
  )? false: true;
  return result;
}


function validateArtifact(o: Artifact, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
  ) ? false: true;

  if(type == ValidationType.Create || !result) return result;
  
  //update
  result =  (
    (o.fields && !validateArtifactFields(o.fields))
  )? false: true;
  
  if(type == ValidationType.Update || !result) return result;
  
  //publish
  result =  (
    Object.entries(o.fields).length == 0
  )? false: true;
  
  return result;
}

function validateApp(o: App, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
  )? false: true;
  
  if(type == ValidationType.Create || !result) return result;
  
  
  if(type == ValidationType.Update || !result) return result;
  
  //publish
  result =  (
    o.logoUrl == ""
  )? false: true;
  
  return result;
}

function validateActionProvider(o: ActionProvider, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
    || !o.actionID || o.actionID == "" 
    || !o.appID || o.appID == ""
  ) ?  false: true;
  
  if(type == ValidationType.Create || !result) return result;
  
  //update
  result =  (
    (o.parameters && !validateParameters(o.parameters)) 
    || (o.outputs && !validateOutputTransformation(o.outputs))
  )? false: true;
  
  if(type == ValidationType.Update || !result) return result;
  
  //publish
  result =  (
    (o.adapterType != "http") || (!o.http)
    || !validateHttpAdapter(o.http)
  )? false: true;
  
  return result;
}

function validateTriggerProvider(o: TriggerProvider, type: ValidationType): boolean {
  //create
  let result = (
    o.name == "" || o.displayName == "" || o.description == ""
    || !o.triggerID || o.triggerID == "" 
    || !o.appID || o.appID == ""
  ) ?  false: true;
  
  if(type == ValidationType.Create || !result) return result;
  
  //update
  result =  (
    (o.parameters && !validateParameters(o.parameters)) 
    || (o.outputs && !validateOutputTransformation(o.outputs))
  )? false: true;
  
  if(type == ValidationType.Update || !result) return result;
  
  //publish
  result =  (
    (o.adapterType == AdapterType.Http && o.http && !validateHttpAdapter(o.http))
    || (o.adapterType == AdapterType.Webhook && o.webhook && !validateWebhookAdapter(o.webhook))
  )? false: true;
  
  return result;
}

function validateIOData(iodata: IOData): boolean {
  return (!iodata.type || iodata.description == "" || iodata.required == undefined) ? false: true;
}
function validateArtifactField(field: ArtifactField): boolean {
  return (
    field.description == "" || !field.type || field.lowPowerMode == undefined || field.required == undefined 
    || field.filterable == undefined || field.isPii == undefined
  )? false: true;
}

function validateArtifactFields(artifactFields: ArtifactFields): boolean {
  for (const [_, field] of Object.entries(artifactFields)) {
    if(!validateArtifactField(field))return false;
  }
  return true;
}

function validateAppConfiguration(c: AppConfiguration): boolean {
  return (
    c.name == "" || c.label == "" || c.description == "" 
    || !c.type || c.required == undefined 
  )? false: true;
}

function validateAppConfigurations(configurations: AppConfiguration[]): boolean {
  for (const [_, configuration] of Object.entries(configurations)) {
    if(!validateAppConfiguration(configuration))return false;
  }
  return true;
}

function validateParameters(parameters: IODataMap): boolean {
  for (const [_, iodata] of Object.entries(parameters)) {
    if(!validateIOData(iodata))return false;
  }
  return true;
}

function validateOutputs(outputs: IODatas): boolean {
  for (const [_, value] of Object.entries(outputs)) {
    if(typeGuards.isIOData(value)){
      if(!validateIOData(value))return false;
    }else if(typeGuards.isIODataMap(value)){
      for (const [_, iodata] of Object.entries(value)) {
        if(!validateIOData(iodata))return false;
      }
    }else if(typeGuards.isIODataArray(value)){
      for( const iodataMap of value){
        for (const [_, iodata] of Object.entries(iodataMap)) {
          if(!validateIOData(iodata))return false;
        }
      }
    }else {
      return false;
    }
  }
  return true;
}

function validateOutputTransformation(outputs: IODataValueOutputs): boolean {
  for (const [_, value] of Object.entries(outputs)) {
    if( ! (typeGuards.isIODataValue(value) 
      || typeGuards.isIODataValueMap(value)
      || typeGuards.isIODataValueArray(value))) {
        return false;
    }
  }
  return true;
}

function validateHttpRequest(o: HttpRequest): boolean{
  return (
    o.path == "" || !o.method || !o.contentType
  ) ? false: true;
}

function validateHttpAdapter(o: HttpAdapterSpec): boolean{
  if(Object.entries(o).length == 0) {
    return false;
  }
  for (const [_, request] of Object.entries(o)) {
    if (!validateHttpRequest(request)) {
      return false;
    }
  }

  return true;
}

function validateWebhookAdapter(o: WebhookAdapter): boolean{
  return true;
}
