
export enum QuickLinkComponent {
    Goals = "goals",
    Apps = "apps",
    Edges = "edges",
    Rbac = "rbac",
    Search = "search",
    Hyprflows = "hyprflows",
    Workspace = "workspace",
    Dashboard = "dashboard",
    Administration = "administration",
    Cases = "cases"
  }

export interface QuickLinkMeta {
    url: string;
    thumbnail: string;
}

export const QuickLinkMetaMap: Map<string, QuickLinkMeta>  =  new Map<string, QuickLinkMeta> (
    [
      [
        QuickLinkComponent.Goals, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Goals.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Goals.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Apps, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Apps.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Apps.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Edges, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Edges.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Edges.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Rbac, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-RBAC.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-RBAC.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Search, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-FedSearch.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-FedSearch.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Hyprflows, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-HyprFlows.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-HyprFlows.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Workspace, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Workspace.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Workspace.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Dashboard, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Dashboard.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Dashboard.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Administration, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Administration.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Administration.png"
        } as QuickLinkMeta,
      ],
      [
        QuickLinkComponent.Cases, 
        {
            url: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Cases.mp4",
            thumbnail: "https://app.dev.hypredge.com/apps/media/assets/HyprEdge-Cases.png"
        } as QuickLinkMeta,
      ],
    ],
  )