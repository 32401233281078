import { FC, useEffect, useState, useReducer } from "react";
import {
  Form,
  FormInstance,
  Space,
  Typography,
  theme,
} from "antd";

import {
  ActionProvider,
  AppInfo,
  IODataMap,
  IODataValueMap,
  ActionFormProps,
  WorkflowNodeType,
  AppSubscriptionInfos,
  IODatas,
  ActionProviders,
  IOData,
  TextType,
} from "types";
import { cloneDeep } from "lodash"
import { useWorkflowStepProvidersStore } from "store";

import { validateFormFields } from "utility";
import CollapsePanel from "components/CollapsePanel";
import NodeCardApps from "components/HyprFlows/NodeCard/Apps";
import NodeCardParameters from "components/HyprFlows/NodeCard/Parameters";

import { workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import NodeCardOutputs from "../NodeCard/Outputs";

const { Text } = Typography;

const ActionForm: FC<ActionFormProps> = ({
  actionId,
  tenantId,
  appId,
  isConfigured,
  editMode,
  actionData,
  hideOutputs,
  suggestionsTree,
  onChange,
  onRender,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
 
  //App
  const [appInfos, setAppInfos] = useState(new Map<string, AppInfo>());
  const [selectedApp, setSelectedApp] = useState<Record<string, string|string[]>>({});
  
  //Action
  const [actionParameters, setActionParameters] = useState({} as IODataMap);
  const [actionOutputs, setActionOutputs] = useState({} as IODatas);
  const [parametersForm, setParametersForm] = useState<undefined | FormInstance>(undefined);
  const [actionParametersValues, setActionParametersValues] = useState({} as IODataValueMap);

  //Action Provider
  const [providerParametersForm, setProviderParametersForm] = useState<undefined | FormInstance>(undefined);
  const [actionProviderParameterValues, setActionProviderParameterValues] = useState({} as IODataValueMap);

  useEffect(() => {
    onRender?.(form);
  }, []);

  const { actionProvidersMap } =
    useWorkflowStepProvidersStore((state) => ({
      actionProvidersMap: state.actionProvidersMap,
    }));

  const syncCurrentState = async () => {
    try {
      let actionApp = {};
      if (actionData) {
        if (actionData.providerId && actionData.providerId != "") {
          actionApp = {
            providerId: actionData.providerId,
            appSubscriptions: actionData?.appSubscriptions,
          };
        }

        const providerParamValues = actionData.providerParamValues
                              ? actionData.providerParamValues
                              : ({} as IODataValueMap);

        if (!isConfigured) {
          const actionProvider = actionProvidersMap.get(actionId);
          if (actionProvider) {
            if (!actionData.paramValues) {
              actionData.paramValues = {};
            }
            actionProvider.action &&
              actionProvider.action.parameters &&
              Object.entries(actionProvider.action.parameters).forEach(
                ([key, value]) => {
                  if (value.default != undefined) {
                    actionData.paramValues[key] = value.default;
                  }
                }
              );
          }

          actionProvider?.providers.map((provider) => {
            if (providerParamValues) {
              provider.parameters &&
                Object.entries(provider.parameters).forEach(([key, value]) => {
                  if (value.default != undefined) {
                    providerParamValues[key] = value.default;
                  }
                });
            }
          });
        }
        
        actionData.providerParamValues = providerParamValues;

        setActionParametersValues(actionData.paramValues);
        form.setFieldsValue({ parameters: actionData.paramValues });

        setActionProviderParameterValues(actionData.providerParamValues);
        form.setFieldsValue({ providerParameters: actionData.providerParamValues});
      }

      form.setFieldsValue({ apps: actionApp });
      setSelectedApp(actionApp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncCurrentState();
  }, [actionData]);

  const syncAppInfos = async () => {
    try {
      const actionProvider = actionProvidersMap.get(actionId);
      const appInfos = new Map<string, AppInfo>();
      if (actionProvider && actionProvider.providers) {
        let providers = actionProvider.providers;
        if(appId) {
          providers = providers.filter( (p) => p.appID == appId);
        }
        providers.map((provider: ActionProvider) => {
          if (tenantId &&  provider?.appInfo?.appSubscriptionsInfos) {
            provider.appInfo.appSubscriptionsInfos = provider.appInfo.appSubscriptionsInfos.filter((as) => as.tenantID == tenantId);
          }
          appInfos.set(provider.id, provider.appInfo);
        });
      }
      setAppInfos(appInfos);
    } catch (error) {
      console.log(error);
    }
  };

  const overrideActionParameters = async (actionProvider: ActionProviders, newActionParameters: IODataMap) => {
    if (selectedApp.providerId && selectedApp.providerId != "") {
      const currentActionProvider = actionProvider?.providers.find((p) => p.id == selectedApp.providerId);
      if (currentActionProvider && currentActionProvider.actionParameterOverrides) {
        Object.entries(newActionParameters).forEach(([key, value]) => {
          const overrideParameter = currentActionProvider?.actionParameterOverrides?.[key];
          if (overrideParameter) {
            //TODO - supports only texType, values and default for now. add more if we support more overrides
            if (overrideParameter?.values && overrideParameter.values.length > 0) {
              newActionParameters[key].values = overrideParameter.values;
            }
            if (overrideParameter?.default && overrideParameter.default != "") {
              newActionParameters[key].default = overrideParameter.default;
            }
            if (overrideParameter?.textType && (overrideParameter.textType != TextType.String || newActionParameters[key].textType != TextType.String)) {
              newActionParameters[key].textType = overrideParameter.textType;
            }
          }
        });
      }
    }
  };

  const syncActionParameters = async () => {
    try {
      const actionProvider = actionProvidersMap.get(actionId);
      if (actionProvider && actionProvider.action) {
        const newActionParameters = cloneDeep(actionProvider.action.parameters);
        overrideActionParameters(actionProvider, newActionParameters)
        setActionParameters({...newActionParameters});
        setActionOutputs(actionProvider.action.outputs);
      } else {
        setActionParameters({} as IODataMap);
        setActionOutputs({} as IODatas);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    syncAppInfos();
    syncActionParameters();
  }, [actionProvidersMap]);

  useEffect(() => {
    syncActionParameters();
  }, [selectedApp]);

  const getActionProviderParameters = (providerId: string) => {
      const actionProvider = actionProvidersMap.get(actionId);
      if (actionProvider) {
        const appInfo = appInfos.get(providerId);
        if (appInfo) {
          const provider = actionProvider.providers.find(
            (provider: ActionProvider) => provider.id === appInfo.providerID
          );
          if (provider && provider.parameters) {
            return provider.parameters;
          }
        }
      }
  };

  const onValuesChange = async (values: any) => {
    const currentValues = form.getFieldsValue(true); 
    const newActionData = actionData;

    if (currentValues.app.providerId) {
      newActionData.providerId = currentValues.app.providerId;
    }
    if (currentValues.app.appSubscriptions) {
      newActionData.appSubscriptions =currentValues.app.appSubscriptions
    }
    if (currentValues.parameters) {
      newActionData.paramValues = currentValues.parameters;
    }
    if (currentValues.providerParameters) {
      newActionData.providerParamValues = currentValues.providerParameters;
    }
  
    onChange?.(newActionData);
  };

  const showParameters = () => {
    return Object.keys(selectedApp).length > 0 && (showActionParameters() || showActionProviderParameters());
  };

  const showActionParameters = () => {
    return actionParameters && Object.keys(actionParameters).length > 0;
  };

  const showOutputs = () => {
    return hideOutputs ? false : Object.keys(selectedApp).length > 0 && showActionOutputs()
  };

  const showActionOutputs = () => {
    return actionOutputs && Object.keys(actionOutputs).length > 0;
  };

  const showActionProviderParameters = () => {
    return selectedApp &&  getActionProviderParameters(selectedApp?.providerId as string);
  };

  return (
    <Form
      form={form}
      name="actionForm"
      layout="vertical"
      autoComplete="off"
      onValuesChange={onValuesChange}
    >
      <Space direction="vertical" style={{ display: "flex" }}>
        <CollapsePanel
          name={
            <Space  size={token.marginXXS}>
              <SvgIcon component={workflowIcons.appsShortIcon} />
              <div>Apps</div>
            </Space>
          }
          ghost={false}
          collapsePanel={false}
        >
          <Form.Item
            name="app"
            initialValue={selectedApp}
            rules={[
              {
                validator: (_, value) =>  {
                  return (!appInfos.size || !selectedApp.providerId)
                    ? Promise.reject("Apps are required!")
                    : Promise.resolve();
                }
              },
            ]}
          >
            <NodeCardApps
              isSingle={true}
              appInfos={appInfos}
              selectedApps={selectedApp}
              editMode={editMode}
              onChange={(apps) => {
                const app = form.getFieldValue("app")
                              ? form.getFieldValue("app")
                              : apps as Record<string, string | string[]>;
                setSelectedApp({...app});
              }}
            />
          </Form.Item>
        </CollapsePanel>
        {showParameters() &&
          <CollapsePanel
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.parametersShortIcon} />
                <Text>Parameters</Text>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <Space direction="vertical" style={{ display: "flex" }}>
              {showActionProviderParameters() && 
                <Form.Item
                  name="providerParameters"
                  initialValue={actionProviderParameterValues}
                  validateTrigger="onSubmit"
                  rules={[
                    {
                      validator: (_, value) => validateFormFields(providerParametersForm),
                    },
                  ]}
                >
                  <NodeCardParameters
                    nodeType={WorkflowNodeType.Action}
                    editMode={editMode}
                    parameters={getActionProviderParameters(selectedApp?.providerId as string) as IODataMap}
                    parametersValues={actionProviderParameterValues}
                    parameterSuggestionsTree={suggestionsTree}
                    appName={appInfos?.get(selectedApp?.providerId as string)?.displayName}
                    onRender={(form) => setProviderParametersForm(form)}
                  />
                </Form.Item>
              }
              {showActionParameters() &&
                <Form.Item
                  name="parameters"
                  initialValue={actionParametersValues}
                  validateTrigger="onSubmit"
                  rules={[
                    {
                      validator: (_, value) => validateFormFields(parametersForm),
                    },
                  ]}
                >
                  <NodeCardParameters
                    nodeType={WorkflowNodeType.Action}
                    editMode={editMode}
                    parameters={actionParameters}
                    parametersValues={actionParametersValues}
                    parameterSuggestionsTree={suggestionsTree}
                    onRender={(form) => setParametersForm(form)}
                  />
                </Form.Item>
              }
            </Space>
          </CollapsePanel>
        }
        {showOutputs() &&
          <CollapsePanel
            name={
              <Space size={token.marginXXS}>
                <SvgIcon component={workflowIcons.outputsShortIcon} />
                <Text>Outputs</Text>
              </Space>
            }
            ghost={false}
            collapsePanel={false}
          >
            <NodeCardOutputs 
              nodeType={WorkflowNodeType.Action}
              outputs={actionOutputs}
            />
          </CollapsePanel>
        }
      </Space>
    </Form>
  );
};

export default ActionForm;
