import {Key,TreeNode, FieldValidationRule} from 'types'
import {cloneDeep} from 'lodash'
import create from 'zustand';

interface TreeState {
  tree: TreeNode;
  insertLeafNode: (path: Key[], leafNode: FieldValidationRule[]) => void;
  clear: () => void;
  countLeafNodes: (path: Key[]) => number;
}

export const useFieldValidationStore = create<TreeState>((set, get) => ({
  tree: new TreeNode(""),
    
  insertLeafNode: (path: Key[], leafNode: FieldValidationRule[]) => {
    set((state) => {
      state.tree.insertLeafNode(path, leafNode);
      return { tree: cloneDeep(state.tree) };
    });
  },

  clear: () => {
    set((state) => {
      state.tree = new TreeNode("");
      return { tree: cloneDeep(state.tree) };
    });
  },

  countLeafNodes: (path: Key[]) => {
    const value = get().tree.countLeafNodes(path);    
    return value;
  }
}));
