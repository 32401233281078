import { EditorContext, TextField, ViewProps } from "components/EntityEditor";
import React, { useContext, useEffect } from "react";
import { ConditionOpType } from "types";
/** Function component with app view props as argument */
export const ConditionView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target): mounted.current = true;
  }, [target]);

  const onTextChange = async (identifier: string, value: string) => {
    setTarget({ ...target, [identifier]: value });
  };
  return (
    <>
      <TextField
        identifier={"fact"}
        label={"Fact"}
        value={target["fact"]}
        path={["fact"]}
        onChange={onTextChange}
      />

      <TextField
        identifier={"operator"}
        label={"Operator"}
        value={target["operator"]}
        path={["operator"]}
        onChange={onTextChange}
        options={ Object.values(ConditionOpType).map( (opType) =>  ({
          label: opType,
          value: opType,
        }))}
      />

      <TextField
        identifier={"value"}
        label={"Value"}
        value={target["value"]}
        path={["value"]}
        onChange={onTextChange}
      />
    </>
  );
};
