import React from "react";
import { Typography } from "antd";
const { Text } = Typography;

export const getLabel = (label: string, optional: boolean) => {
  return (
    <Text strong>
      {label}
      {!optional && <Text style={{ color: "red" }}>{"*"}</Text>}
    </Text>
  );
};

export const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};
