import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useTokenStore } from "store/token";
import styles from "./SamlCallback.module.scss";
import { TokenUtils } from "types";

const SamlCallback = () => {
  const token = useTokenStore((state) => state.token);
  const setToken = useTokenStore((state) => state.setToken);
  const [tokenStatus, setTokenStatus] = useState(
    TokenUtils.getTokenStatus(token)
  );
  const { hash } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(hash.slice(1));
    const accessToken = params.get("access_token");
    console.log("access token", accessToken);
    if (accessToken) {
      setToken(accessToken);
    }
  }, []);

  useEffect(() => {
    const status = TokenUtils.getTokenStatus(token);
    setTokenStatus(status);
  }, [token]);

  const getUrl = () => {
    const params = new URLSearchParams(hash.slice(1));
    const path = params.get("path") || "/home";
    return path;
  };

  return tokenStatus.isAuthenticated ? (
    <Navigate to={getUrl()} />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.heading}>Verifying</div>
        <div className={styles.subHeading}>Please hold on...!</div>
      </div>
    </div>
  );
};

export default SamlCallback;
