import { AppInfos, AppInfo, AppSubscriptionInfo, AppSubscriptionInfos, Edge } from "types";
import { appConfigurationView, appConfigurationViews } from "./configurationView";

export interface ProviderApp {
    id: string;
    name: string;
    description: string;
    appInfos: AppInfos;
    tags: string[]
}

export const getSelectedAppSubscriptionAndAppInfo = (providerApp?: ProviderApp, edges?: Edge[]): [AppInfo|undefined, AppSubscriptionInfo|undefined] => {
    if (providerApp?.appInfos) {
        for (const ai of providerApp.appInfos) {
            for (const asi of ai.appSubscriptionsInfos) {
                if (asi.selected && asi.selected) {
                    if(edges) {
                        asi.edge = edges?.find((edge) => edge.id == asi.edgeID);
                    }
                    return [ai,asi]
                }
            }
        }
    }
    return [undefined, undefined]
}

export const getSelectedAppSubscriptionsAndAppInfos = (providerApp?: ProviderApp, edges?: Edge[]): AppInfos=> {
    const selectedApps: AppInfos = [];
    if (providerApp?.appInfos) {
        for (const ai of providerApp.appInfos) {
            const si : AppInfo = {
                id: ai.id,
                name: ai.name,
                displayName: ai.displayName,
                description: ai.description,
                logoUrl: ai.logoUrl,
                darkLogoUrl: ai.darkLogoUrl,
                isConfigured: ai.isConfigured,
                providerID: ai.providerID,
                appSubscriptionsInfos: []
            } 
            for (const asi of ai.appSubscriptionsInfos) {
                if (asi.selected && asi.selected) {
                    if(edges) {
                        asi.edge = edges?.find((edge) => edge.id == asi.edgeID);
                    }
                    si.appSubscriptionsInfos.push(asi);
                }
            }
            if(si.appSubscriptionsInfos.length) {
                selectedApps.push(si);
            }
        }
    }
    return selectedApps
}

export const getSelectedAppSubscriptionsAndAppInfosViews = (providerApp?: ProviderApp, edges?: Edge[]): appConfigurationViews => {
    const views: appConfigurationViews = [];
    const appInfos = getSelectedAppSubscriptionsAndAppInfos(providerApp, edges);
    appInfos.forEach ( (ai) => {
        ai.appSubscriptionsInfos.forEach( (asi) => {
            views.push({
                ai: ai,
                asi: asi
            } as appConfigurationView)
        })
    })
    return views;
}