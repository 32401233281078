import { create } from "zustand"
import { devtools,persist } from "zustand/middleware";

import { 
  WorkflowStepsStore,
  workflowStepsStoreName,
} from "types";

import {
  getTriggersApi,
  getActionsApi,
  getOperatorsApi,
} from "api";

export const useWorkflowStepsStore = create<WorkflowStepsStore>()(
  devtools(
    persist((set, get) => ({
      triggers: [],
      actions: [],
      operators: [],
  
      getTriggers: async () => {
        try {
          const [triggers, total] = await getTriggersApi();
          set((state) => ({
            ...state,
            triggers: triggers,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      getActions: async () => {
        try {
          const [actions, total] = await getActionsApi();
          set((state) => ({
            ...state,
            actions: actions,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },

      getOperators: async () => {
        try {
          const operators = await getOperatorsApi();
          set((state) => ({
            ...state,
            operators: operators,
          }));
        } catch (error: any) {
          console.error(error);
          throw new Error(error);
        }
      },
    }),
    {
      name: workflowStepsStoreName, 
      getStorage: () => sessionStorage,
    }),
  { name: workflowStepsStoreName })
);
