export enum DataType {
    String = "string",
    Password = "password",
    Numeric = "numeric",
    Boolean = "boolean",
    Array = "array",
    Map = "map",
    TimeStamp = "timestamp",
    Duration = "duration",
    IP = "ip",
    File = "file"
}

export enum TextType {
    String = "string",
    Text  = "text",
    Rich = "rich",
    Map = "map",
    Array = "array",
    Code = "code",
    Url = "url",
    Artifact = "artifact"
}

export enum ArrayType {
    Native = "native",
    Object  = "object",
    ObjectWithNoValue  = "object_with_no_value"
}


export enum  ArrayObjectDataType {
	String   = "string",
	Numeric  = "numeric",
	Boolean  = "boolean",
	Array    = "array"
}

export interface ArrayObject {
	name: string,
	type: ArrayObjectDataType,
	value: any
}

export type ArrayObjects = ArrayObject[];