import { Layout, Layouts } from "react-grid-layout";
import { WidgetType } from "./visibility";

export enum FormFactor {
  lg = "lg",
  md = "md",
  sm = "sm",
  xs = "xs",
  xxs = "xxs",
}

export const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
export const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

export interface Widget {
  id: string;
  type: WidgetType;
  unit: string;
  title: string;
  description: string;
  search: string;
  searchIntervalSeconds: number;
  color: string;
  position: number;
}

export type WidgetCreateRequest = Omit<Widget, "id">;

export type WidgetUpdateRequest = Partial<WidgetCreateRequest>;

export interface Dashboard {
  id: string;
  name: string;
  description: string;
  color: string;
  layouts: Layouts;
  widgets: Widget[];
}

export interface DashboardStore {  
  dashboards: { [k:string] : Dashboard };
  dashboardEdit: string | null; // Id of the fashboard being edited if under edit mode
  getDashboards: () => Promise<void>;
  createDashboard: (name: string, description: string, color: string) => Promise<void>;
  setLayout: (dashboardId: string, layout: Layout[]) => Promise<void>;
  updateDashboardProperties: (dashboardId: string, name: string, description: string, color: string) => Promise<void>;
  addWidget: (dashboardId: string, widget: WidgetCreateRequest) => Promise<Widget>;
  transferWidget: (sourceDashboardId: string, targetDashboardId: string, widgetId: string) => Promise<void>;
  updateWidget: (dashboardId: string, widgetid: string, widget: WidgetUpdateRequest) => Promise<Widget>;
  deleteWidget: (dashboardId: string, widgetId: string) => Promise<void>;  
  deleteDashboard: (dashboardId: string) => Promise<void>;  
  setDashboardEdit: (dashboardId: string | null) => void; 
}
