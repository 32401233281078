import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { debounce } from "lodash";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
const { Text } = Typography;

export interface HasIdentity {
  id: string;
  selected: boolean;
  assignable: boolean;
  displayName: string;
  description: string;
}

export interface AssignProps {
  type: string;
  name: string;
  dataSource: HasIdentity[];
  showAdd: boolean;
  readonly: boolean;
  multiple: boolean; // Allow multiple selection
  onAdd?: () => Promise<void>;
  // onSearch: (value: string) => Promise<string[]>;
  onChange: (
    selectedRowKeys: React.Key[],
    selectedRows: HasIdentity[]
  ) => Promise<void>;
}

export const Assign = (props: AssignProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [rowSelection, setRowSelection] = useState<
    TableRowSelection<HasIdentity>
  >({
    type: props.multiple ? "checkbox" : "radio",
    selectedRowKeys: [],
    getCheckboxProps: (record: HasIdentity) => ({
      disabled: !record.assignable,
    }),
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: HasIdentity[]) =>
      onChange(selectedRowKeys, selectedRows),
  });

  const [dataSource, setDataSource] = useState<HasIdentity[]>(
    props.dataSource.sort((a) => (a.selected ? -1 : 1))
  );

  useEffect(() => {
    const rows = props.dataSource.filter((x) => x.selected);
    const filteredSet = new Set(rows.map((x) => x.id));

    setRowSelection({
      ...rowSelection,
      selectedRowKeys: Array.from(filteredSet),
    });

    setDataSource(getSearchedDataSource());

    return () => {
      setRowSelection({
        ...rowSelection,
        selectedRowKeys: [],
      });
    };
  }, [props.dataSource]);

  const onChange = (
    selectedRowKeys: React.Key[],
    selectionRows: HasIdentity[]
  ) => {
    setRowSelection({
      ...rowSelection,
      selectedRowKeys,
    });
    props.onChange(selectedRowKeys, selectionRows);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(setSearchValue, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const getSearchedDataSource = () => {
    return searchValue === ""
      ? props.dataSource
      : props.dataSource.filter((x) =>
          x.displayName.toLowerCase().includes(searchValue.toLowerCase())
        );
  };

  useEffect(() => {
    setDataSource(getSearchedDataSource());
  }, [searchValue]);

  const columns: ColumnsType<HasIdentity> = [
    {
      title: `Select ${props.name}`,
      dataIndex: "displayName",
      key: "id",
      sorter: (a) =>
        rowSelection.selectedRowKeys?.findIndex((x) => x === a.id) || 0,
      render: (text: string) => (
        <Text style={{ width: "75%" }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
  ];

  const selectAll = () => {
    const selectedRowKeys = dataSource.map((x) => x.id);
    const selectedRows = dataSource;
    setRowSelection({
      ...rowSelection,
      selectedRowKeys,
    });
    // setSelectedRows(selectedRows);
    props.onChange(selectedRowKeys, selectedRows);
  };

  const clearAll = () => {
    /** Remove all datasource items from selectedrow keys */
    const newSelectedRowKeys =
      rowSelection.selectedRowKeys?.filter(
        (x) => !dataSource.map((x) => x.id).includes(x.toString())
      ) || [];

    /** Include only thos items from props.dataSource that are in the newSelectedRowKeys */
    const newSelectedRows = props.dataSource.filter((x) =>
      newSelectedRowKeys.includes(x.id)
    );

    setRowSelection({
      ...rowSelection,
      selectedRowKeys: newSelectedRowKeys,
    });

    props.onChange(newSelectedRowKeys, newSelectedRows);
  };

  const rowStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    gap: "10px",
  };

  return (
    <>
      <div
        id="assign-available"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        <div id="assign-available-title" style={rowStyle}>
          <Text>{`Available ${props.name} (${
            props.dataSource.length || 0
          })`}</Text>

          {props.showAdd ? (
            <div id="assign-available-title-add-button">
              <PlusOutlined onClick={props.onAdd} />
            </div>
          ) : undefined}
        </div>
        <div id="assign-available-search" style={rowStyle}>
          <Input
            placeholder="Search"
            onChange={(e) => debouncedSearch(e.target.value)}
            allowClear={true}
            size="small"
          />
          {props.multiple && (
            <>
              <Button onClick={selectAll} disabled={props.readonly}>
                <Text>Select All</Text>
              </Button>
              <Button onClick={clearAll} disabled={props.readonly}>
                <Text>Clear All</Text>
              </Button>
            </>
          )}
        </div>
        <Table
          rowKey={"id"}
          rowSelection={props.readonly ? undefined : rowSelection}
          key={`${props.type}-table`}
          bordered={true}
          pagination={
            props.dataSource.length > 5
              ? { pageSize: 5, showSizeChanger: false }
              : false
          }
          size="small"
          showHeader={false}
          dataSource={dataSource}
          columns={columns}
        ></Table>
      </div>
      <Divider></Divider>
      <div
        id="assign-applied"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "10px",
        }}
      >
        <div id="assign-applied-title" style={rowStyle}>
          <Text>{`Applied ${props.name} (${
            rowSelection.selectedRowKeys?.length || 0
          })`}</Text>
        </div>
        <Table
          rowKey={"id"}
          key={`selected-${props.type}-table`}
          bordered={true}
          pagination={
            (rowSelection.selectedRowKeys?.length || 0) > 5
              ? { pageSize: 5, showSizeChanger: false }
              : false
          }
          size="small"
          showHeader={false}
          dataSource={props.dataSource.filter((x) =>
            rowSelection.selectedRowKeys?.includes(x.id)
          )}
          columns={columns}
        ></Table>
      </div>
    </>
  );
};
