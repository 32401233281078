import { theme, Typography } from "antd";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { Metric, MetricLayout, WidgetType } from "types";
import { MetricDisplay } from "./MetricDisplay";

const { Text } = Typography;

export interface KpiDashboardGridProps {
  layout?: MetricLayout;
  metrics?: Metric[];
}

export const KpiDashboardGrid = (props: KpiDashboardGridProps) => {
  const [tileGrid, setTileGrid] = useState<Metric[][]>([]);
  const [distributionMetrics, setDistributionMetrics] = useState<Metric[]>([]);
  const [pieMetrics, setPieMetrics] = useState<Metric[]>([]);
  const { token } = theme.useToken();
  useEffect(() => {
    if (props.metrics) {
      setTileGrid(
        props.metrics
          ?.filter(
            (x: Metric) =>
              x.widgetType == WidgetType.MiniTile ||
              x.widgetType == WidgetType.Trend
          )
          .reduce((result, item, index) => {
            if (index % 2 === 0) {
              result.push([item]);
            } else {
              result[result.length - 1].push(item);
            }
            return result;
          }, [] as Metric[][])
      );
      setDistributionMetrics(
        props.metrics?.filter(
          (x: Metric) => x.widgetType == WidgetType.Distribution
        )
      );
      setPieMetrics(
        props.metrics?.filter((x: Metric) => x.widgetType == WidgetType.Pie)
      );
    }
  }, [props.metrics]);

  const useStyles = createUseStyles({
    container: {
      width: "100%",
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      paddingTop: "15px",
      paddingLeft: "10px",
      justifyContent: "space-between",
    },
  });

  const classes = useStyles();

  return (
    <div id="dashboard-container" className={classes.container}>
      <Text
        style={{
          fontSize: token.fontSizeHeading5,
          fontWeight: "bold",
        }}
      >
        {props.layout?.displayName}
      </Text>
      <div
        id={"dashboard-content"}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <div
          id="dashboard-tile-container"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          {tileGrid.map((row, index) => {
            return (
              <div
                id="dashboard-tile-row"
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                {row.map((metric) => (
                  <MetricDisplay
                    key={metric.id}
                    metric={metric}
                    stretchMiniTiles={false}
                  />
                ))}
              </div>
            );
          })}
        </div>
        <div
          id="dashboard-distribution-container"
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            flex: 1,
          }}
        >
          {distributionMetrics.map((metric: Metric) => (
            <MetricDisplay
              key={metric.id}
              metric={metric}
              stretchMiniTiles={false}
            />
          ))}
          {pieMetrics.map((metric: Metric) => (
            <MetricDisplay
              key={metric.id}
              metric={metric}
              stretchMiniTiles={false}
              height={"310px"}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
