import { FC, useState, useEffect } from "react";
import { Spin, Typography, Space, Tooltip, Badge, Avatar } from "antd";

import { useDeveloperXStore, useOrganizationStore, useTokenStore, useUserInfoStore } from "store";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { App, DeveloperXTabType, PageInfo, SpecStateType, dateTimeFormatOptions } from "types";
import { notification } from 'utility/notification';

import { HLink } from "components/Link";

import { getUserDetailsFromJWT } from "utility";
import CreateModal from  "./Modals/createModal";
import { useNavigate } from "react-router-dom";
import { getDefaultAppSvg } from "utility/developer";
import { resourceFactory } from "utility/resource";


const { Text, Link } = Typography;


export const AppsTab: FC = () => {
  const [loader, setLoader] = useState(false);
  const [createAppModal, setCreateAppModal] = useState(false);
  const [deleteAppModal, setDeleteAppModal] = useState(false);
  const navigate = useNavigate();
  const userInfos = useUserInfoStore((state) => state.userInfos);
  const loadUserInfos = useUserInfoStore((state) => state.loadUserInfos);
  
  const context = useOrganizationStore((state) => state.context);

  const { appItemsMap, appsPage, myAppsPage, activeTab, getApps,  getPageInfo, createApp, deleteApp} = useDeveloperXStore((state) => ({
    appItemsMap: state.appItemsMap,
    appsPage: state.appsPage,
    myAppsPage: state.myAppsPage,
    activeTab: state.activeTab,
    getApps: state.getApps,
    getPageInfo: state.getPageInfo,
    createApp: state.createApp,
    deleteApp: state.deleteApp,
  }));

  const getAppList = async (pageNumber?: number, pageSize?: number) => {
    try {
      setLoader(true);
      let tenantId = undefined;
      if (activeTab == DeveloperXTabType.MyApps) {
        tenantId = getUserDetailsFromJWT()?.tenantId;
      }
      await getApps(activeTab, pageNumber? pageNumber : getPageInfo(activeTab).number,  pageSize ? pageSize: getPageInfo(activeTab).size, tenantId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };


  useEffect(() => {
    getAppList();
  }, [activeTab, context]);

  //Fetch user info for all users linked to workflows
  useEffect(() => {
    try {
      const userIds = [] as string[];
      appItemsMap?.get?.(activeTab)?.forEach((app) => {
        app.userID && app.userID != "" && userIds.push(app.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [appItemsMap]);


  const columns: ColumnsType<App> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_, app) => (
        <HLink onClick={() => navigate("/developer/app/" + app.id)} text={app.displayName}></HLink>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: {
        showTitle: false,
      },
      render: (_, app) => (
        <Tooltip title={app.description}>
          {app.description}
        </Tooltip>
      ),
    },
    {
      title: "Developer",
      dataIndex: "userID",
      key: "userID",
      sorter: (a, b) => a.userID.localeCompare(b.userID),
      sortDirections: ["descend", "ascend"],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "status",
      sorter: (a, b) => a.state.localeCompare(b.state),
      sortDirections: ["descend", "ascend"],
      render: (_, app) => (
        <Space>
          {app.state == SpecStateType.StatePublished && <Badge count="published" color="green" />}
          {app.state == SpecStateType.StateShared && <Badge count="published" color="green" />}
          {app.state == SpecStateType.StateShared && <Badge count="shared" color="blue" />}
          {(app.state == SpecStateType.StatePublishedDraft  || app.state == SpecStateType.StateDraft) && <Badge count="draft" color="orange" />}
        </Space>
      ),
    },
    {
      title: "Last Update On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      sortDirections: ["descend", "ascend"],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    }
  ];

  const saveNewApp = async (values: any) => {
    try {
      setLoader(true);
      const app = resourceFactory.createApp(values.name, values.description);
      app.logoBuffer = getDefaultAppSvg();
      app.darkLogoBuffer = getDefaultAppSvg();
      const appOut = await createApp(app)
      notification.success({
        message: `App ${appOut.name} created successfully`,
        duration: 6,
      });
      navigate(`/developer/app/${appOut.id}`);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating app...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDeleteApps = async (items: any[]) => {
    try {
      setLoader(true);
      const apps = items as App[];
      await Promise.all(
        apps.map(async (app) => {
          await deleteApp(app.id);
          notification.success({
            message: `App ${app.displayName} deleted successfully`,
            duration: 6,
          });
        })
      );
      
      await getAppList();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting apps...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };



  const onPageChange = (pageNumber: number, pageSize: number) => {
    setLoader(true);
    getAppList(pageNumber, pageSize).then(() => {
      setLoader(false);
    });
  }

  return (
    <Spin spinning={loader}>
      {createAppModal && (
        <CreateModal
          open={createAppModal}
          header={"App"}
          object={"app"}
          notAvailableNames={[]}
          loader={loader}
          onClose={() => setCreateAppModal(false)}
          onSubmit={(values) => saveNewApp(values)}
        />
      )}
      <EntitiesDisplay
        header={"Apps"}
        dataSource={appItemsMap?.get?.(activeTab) as any[]}
        columns={columns}
        pageNumber={getPageInfo(activeTab).number}
        pageSize={getPageInfo(activeTab).size}
        total={getPageInfo(activeTab).total}
        onPageChange={onPageChange}
        actions={
          [
            {
              key: "create", 
              label: "Create", 
              enable: () => true, 
              onClick: () => {setCreateAppModal(true)}
            },
            {
              key: "delete", 
              label: "Delete", 
              enable: (itemIds) => {
                if (itemIds && itemIds.length) {
                  return itemIds.every((id) => appItemsMap?.get?.(activeTab)?.find((a) => a.id == id && a.state == SpecStateType.StateDraft))
                } else {
                  return false
                }
              }, 
              showWarn: true,
              onClick: (value) => {
                const itemIds = value as string[];
                const items = appItemsMap?.get?.(activeTab)?.filter((a) => itemIds.find((id) => id == a.id))
                if (items) {
                  OnDeleteApps(items);
                }
              }
            }
          ]
        }
      />
    </Spin>
  );
};

export default AppsTab;
