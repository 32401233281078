import { useEffect, useState, FC } from "react";
import {
  Row,
  Button,
  Radio,
  Skeleton,
  Space,
  theme,
  Typography,
  Tooltip,
  Card,
  Form,
  Select,
  Spin,
  FormInstance,
  Flex,
} from "antd";

import { CloseOutlined } from "@ant-design/icons";
import pRetry, {FailedAttemptError} from 'p-retry';

import { SvgIcon } from "components/SvgIcon";
import { useOrganizationStore, useSearchArtifactStore, useSearchStore, useSettingsStore, useWorkflowStepProvidersStore } from "store";
import { ParameterStoreEdgePolicyName, PolicyCategoryType, SearchActionInfo,  SearchRunActionInfo,  SearchRunActionProvider,  SearchRunActionProviders,  SearchRunActionStatus } from "types";
import { workflowIcons } from "assets/icons";
import ActionBanner  from "./actionBanner";
import { notification } from 'utility/notification';
import { FieldLabel } from "components/FieldLabel";
import { validateFormFields } from "utility";
import ActionForm from "components/HyprFlows/ActionForm";
import { buildSearchActionParametersTree } from "components/Suggestions";
import SearchInput from "components/SearchInput";
import { buildParameterStoreTree } from "components/Suggestions/tree";
import { usePolicyStore } from "store/policy";
import { useParameterStoreStore } from "store/parameter_store";

const { Text } = Typography;

export interface ActionPanelProps {
  searchRunId: string;
  artifactName: string;
  open: boolean;
  onClose: () => void;
}

const ActionPanel: FC<ActionPanelProps> = ({ 
  searchRunId,
  artifactName,
  open,
  onClose,
}) => {

  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [actionLoader, setActionLoader] = useState(false);
  const [selectedSearchActionInfo, setSelectedSearchActionInfo] = useState<SearchRunActionInfo>({} as SearchRunActionInfo)
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedTenant, setSelectedTenant] = useState<string>("");
  const [involvedTenants, setInvolvedTenants] = useState<string[]>([]);
  const [actionDataForm, setActionDataForm] = useState<undefined | FormInstance>(undefined);
  const [showActionForm, setShowActionForm] = useState(false);
  const [actionEnabled, setActionEnabled] = useState(false);
  const [actionData, setActionData] = useState<Record<string, any>>({});
  const [parametersSuggestionsTree, setParametersSuggestionsTree] = useState<[]>([]);
  const [filter, setFilter] = useState("");
  const [actionHoverId, setActionHoverId] = useState("");

  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));

  const {
    mspEnabled, 
    tags
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags
  }));

  const abortController = new AbortController();

  const loadParametersStore = async () => {
    try {
      const paramStorePolicy = await usePolicyStore.getState().getPolicy(PolicyCategoryType.WorkflowGeneral, ParameterStoreEdgePolicyName, false);
      if (paramStorePolicy) {
        if (paramStorePolicy?.value.data && paramStorePolicy?.value.data != "" && paramStorePolicy?.id) {
          const edgeId = paramStorePolicy.value.data;
          const storeId =  paramStorePolicy.id;
          await useParameterStoreStore.getState().getParameters(edgeId, storeId);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    recommendedActions,
    searchRunActionSelectedDatas,
    currentSearchRunAction,
    runSearchActions,
    getSearchRunAction,
    clearCurrentSearchRunAction,
    clearAllSelectedSearchResults,
  } = useSearchStore((state) => ({
    recommendedActions: state.recommendedActions,
    searchRunActionSelectedDatas: state.searchRunActionSelectedDatas,
    getSearchRunAction: state.getSearchRunAction,
    currentSearchRunAction: state.currentSearchRunAction,
    runSearchActions: state.runSearchActions,
    clearCurrentSearchRunAction: state.clearCurrentSearchRunAction,
    clearAllSelectedSearchResults: state.clearAllSelectedSearchResults,
  }));

  const { artifacts } = useSearchArtifactStore((state) => ({artifacts: state.artifacts,}));

  const { actionProvidersMap, getActionProviders } =
    useWorkflowStepProvidersStore((state) => ({
      actionProvidersMap: state.actionProvidersMap,
      getActionProviders: state.getActionProviders,
    }));
  
  const syncSearchRunActionInBackground = async (searchRunActionId: string) => {
    const sr = await getSearchRunAction(searchRunActionId, true);
    if (sr.status == SearchRunActionStatus.Running ) {
      throw new Error(sr.status)
    }
  }

  //TODO - add global parameters to access in search action params
  const buildActionParametersSuggestions = () => {
    const suggestionsTree: [] = [];
    buildParameterStoreTree(suggestionsTree);
    buildSearchActionParametersTree(artifactName, suggestionsTree);
    setParametersSuggestionsTree(suggestionsTree);
  };

  const buildActionParameters = () => {
    if (!actionData.paramValues) {
      actionData.paramValues = {};
    }
    const artifact = artifacts.find ((x) => x.name == artifactName)
    if (!artifact) {
      return 
    }
    const action = artifact.actions.find ( (x) => x.id == selectedAction)
    if (!action || !action.parameters) {
      return
    }
    Object.entries(action?.parameters).forEach ( ([key, value]) => {
      if (!actionData.paramValues[key]) {
        actionData.paramValues[key] = `{{$.${artifactName}.${value}}}`
      }
    })
  };

  useEffect(() => {
    loadParametersStore();
    return () => {abortController.abort("exiting search action panel page")} ;
  }, []);

  const loadActionProviders = async (actionId: string, tenantId: string) => {
    try {
      setActionLoader(true);
      await getActionProviders(actionId);
    } catch (error) {
      console.log(error);
    } finally {
      setActionLoader(false);
    }
  };

  useEffect(() => {
    if (selectedAction && selectedAction != "" ) {
      loadActionProviders(selectedAction, selectedTenant);
      setShowActionForm(true);
    } else {
      setShowActionForm(false);
    }
    buildActionParameters();
    buildActionParametersSuggestions();
  }, [selectedAction, selectedTenant]);

  const getAppInfo = (providerId: string) => {
    const actionProvider = actionProvidersMap.get(selectedAction);
    return actionProvider?.providers.find((provider) => provider.id == providerId)?.appInfo;
  };

  const onActionSelect = (actionId: string) => {
    setSelectedAction(actionId);
    form.setFieldValue("actionId", actionId);
    const actionInfo: SearchRunActionInfo = {} as SearchRunActionInfo;
    actionInfo.id = actionId
    setSelectedSearchActionInfo(actionInfo);
  };

  const onTenantSelect = (tenantID: string) => {
    setSelectedTenant(tenantID);
    form.setFieldValue("tenantId", tenantID);
  };

  const onActionClose = async () => {
    onClose();
  };

  const onActionChange =  (actionData : any) => {
    const searchRunActionProvider = {} as SearchRunActionProvider;
    if (actionData) {
      selectedSearchActionInfo.parameters = actionData.paramValues;  
      const providerId = actionData.providerId as string;
      if (providerId) {
        const appInfo = getAppInfo(providerId);
        if (appInfo) {
          searchRunActionProvider.providerID = providerId;
          searchRunActionProvider.appID = appInfo.id;
          searchRunActionProvider.appDisplayName = appInfo.displayName;
          searchRunActionProvider.appSubscriptionIDs = [] as string[];
          searchRunActionProvider.parameters = actionData.providerParamValues;
          const subscriptions = actionData.appSubscriptions as string[];
          subscriptions?.map((subscriptionId) => {
            searchRunActionProvider.appSubscriptionIDs.push(subscriptionId);
          });
        }
      }
      setActionData(actionData);
      if(!selectedSearchActionInfo.providers) {
        selectedSearchActionInfo.providers = {} as SearchRunActionProviders;
      }
      selectedSearchActionInfo.providers[selectedTenant] = searchRunActionProvider;
      setSelectedSearchActionInfo(selectedSearchActionInfo);
    }
  }

  const validateActionInputs = (actionInfo: SearchRunActionInfo) => {
    if(Object.entries(searchRunActionSelectedDatas).length == 0) {
      setActionEnabled(false);
      return false;
    }

    if( !actionInfo.id  || actionInfo.id == "" ){
      setActionEnabled(false);
      return false
    }
    if(!actionInfo.providers) {
      setActionEnabled(false);
      return false;
    } 
    for(const tenantId of involvedTenants) {
      const provider = actionInfo.providers[tenantId];
      if(!provider) {
        setActionEnabled(false);
        return false
      }
    }
    setActionEnabled(true);
    return true
  }


  const onActionSubmit = async (values: any) => {
    form
      .validateFields()
      .then(async () => {
        if (Object.entries(searchRunActionSelectedDatas).length && validateActionInputs(selectedSearchActionInfo)) {
          try {
            setActionLoader(true);
            clearCurrentSearchRunAction();
            const newSearchRunAction = await runSearchActions(searchRunId, selectedSearchActionInfo, searchRunActionSelectedDatas);
            notification.success({
              message: "Action execution is pipelined succesfully",
              duration: 6,
            });
            pRetry(() => syncSearchRunActionInBackground(newSearchRunAction.id), {
              retries: 60,
              minTimeout: 5000,
              maxTimeout: 5000,
              signal: abortController.signal
            }).catch((e: FailedAttemptError) => {
              console.log("pretry sync search run status completed, exiting sync of status");
            })
    
            setSelectedAction("");
            setSelectedTenant("");
            setActionData({});
            clearAllSelectedSearchResults();
          } catch (error) {
            console.log(error);
            notification.error({
              message: "Something went wrong while pipeling action execution",
              duration: 6,
            });
          }finally{
            setActionLoader(false);
          }
        } else {
          console.warn("search results shoudl be selected");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const syncAsPerSelectedData = async () => {
    const tenantIds: string[] = [];
    Object.entries(searchRunActionSelectedDatas).forEach ( ([appSubscriptionId, selectedData]) => {
      const foundTenant = tenantIds.find( (id) => id == selectedData.tenantId);
      if (!foundTenant ){
        tenantIds.push(selectedData.tenantId);
      }
    });
    setInvolvedTenants(tenantIds);
    if (tenantIds.length == 0)  {
      onTenantSelect("");
    }else {
      onTenantSelect(tenantIds[0])
    }
  }

  useEffect(() => {
    syncAsPerSelectedData()
  }, [searchRunActionSelectedDatas]);


  return (
    <Card
      size="small"
      title={
        <Space direction="horizontal">
          <SvgIcon component={workflowIcons.actionShortIcon} />
            <Text style={{ color: token.colorTextLightSolid }}>
              Actions
            </Text>
        </Space>
      }
      headStyle={{
        backgroundColor: token.colorPrimary,
        textAlign: "left",
      }}
      bodyStyle={{
        backgroundColor: token.colorBgContainer,
        padding: token.padding,
        
        height: "auto",
        maxHeight: "100%",
        overflowX: "hidden",
      }}
      extra={
        <CloseOutlined 
          style={{ color: token.colorTextLightSolid }} 
          onClick={() => onActionClose()} 
        />
      }
    >
      <Space direction="vertical"  style={{ display: "flex" }}>
        <div style={{ display: "flex",flexDirection: "column", paddingLeft: token.paddingXXS}}>
          {currentSearchRunAction.id  &&
            <div style={{ width: "95%", borderLeft: `1px solid ${token.colorPrimary}`, padding: token.paddingXXS, marginBottom: token.margin}}>
              <ActionBanner  
                searchRunActionId={currentSearchRunAction.id} 
                backgroundColor={token.colorPrimaryBg} 
                hoverableColor={token.colorPrimaryBgHover}
              />
            </div>
          }
          {!showActionForm ? 
            <Spin spinning={actionLoader}>
              <SearchInput
                placeholder="search actions"
                onFilter={(e) => setFilter(e.target.value)}
              /> 
              {recommendedActions?.filter((x: SearchActionInfo) => x.displayName.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                .sort((a, b) => ~~b.active - ~~a.active).map((i) => (
                <Space key={i.id} size={token.size} direction="vertical" style={{ display: "flex", marginTop: "3px" }}>
                  <Card
                    hoverable={i.active}
                    style={{ 
                      height: "40px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      background: (i. active ? (i.id == actionHoverId ? token.colorPrimaryBgHover : token.colorBgBase) : mode ? "#f5f5f5" : "#1f1f1f"),
                    }}
                    onClick={() => {
                      if (i.active) {
                        onActionSelect(i.id);
                      }
                    }}
                    onMouseEnter={() => setActionHoverId(i.id)}
                    onMouseLeave={() => setActionHoverId("")}
                  >
                    <Tooltip
                      color={token.colorPrimaryHover}
                      title={i.description}
                      placement={"right"}
                    >
                      <Flex gap="small" justify={"flex-start"}>
                        <SvgIcon component={mode ? workflowIcons.actionSidebarLightIcon : workflowIcons.actionSidebarDarkIcon} />
                          <Text>
                            {i.displayName}
                          </Text>
                      </Flex>                 
                    </Tooltip>
                  </Card>
                </Space>
              ))}
            </Spin>
            :
            <Form
              form={form}
              name="searchOperatorCardForm"
              layout="vertical"
              autoComplete="off"
              onFinish={onActionSubmit}
            >
              <Spin spinning={actionLoader}>
                <div>
                  <Form.Item 
                    name="actionId" 
                    label={<FieldLabel label={"Select Action"} help={"Action to be taken on search results"} />}
                    validateTrigger="onSubmit"
                  >
                    <Select
                      onSelect={(id) => onActionSelect(id)}
                      options={recommendedActions?.map(
                        (action) => ({
                          value: action.id,
                          label: action.displayName,
                        })
                      )}
                    />
                  </Form.Item>
                  {mspEnabled && involvedTenants.length > 1 &&
                    <Form.Item 
                      name="tenantId" 
                      label={<FieldLabel label={"Select Tenant"} help={"Tenant for which action to be taken"} />}
                      validateTrigger="onSubmit"
                      rules={[
                        {
                          validator: (_, value) => validateActionInputs(selectedSearchActionInfo) ? Promise.resolve(): Promise.reject("Action inputs are missing for some tenants")
                        }
                      ]}
                    >
                      <Select
                        onSelect={(id) => setSelectedTenant(id)}
                        options={involvedTenants?.map(
                          (tenantId) => ({
                            value: tenantId,
                            label: tags?.find((tag) => (tag.id == `${tenantId}_tag`))?.value
                          })
                        )}
                      />
                    </Form.Item>
                  }
                  <Form.Item 
                    name="actionData"
                    validateTrigger="onSubmit"
                    rules={[
                      { validator: (_, value) =>  validateFormFields(actionDataForm) }
                    ]}
                  >
                    <ActionForm 
                      tenantId={selectedTenant}
                      actionId={selectedAction}
                      isConfigured={false}
                      editMode={true}
                      actionData={actionData}
                      hideOutputs={true}
                      suggestionsTree={parametersSuggestionsTree}
                      onRender={(form) => setActionDataForm(form)}
                      onChange={onActionChange}
                    />
                  </Form.Item>
                </div>
              </Spin>
              <Row justify="space-between" style={{ marginTop: token.marginLG }}>
                <Button key="cancel" onClick={onActionClose}>
                  Cancel
                </Button>
                <Button 
                  key="action"
                  type="primary" 
                  htmlType="submit"
                >
                  Take Action
                </Button>
              </Row>
            </Form>        
          }
        </div>
      </Space>
    </Card>
  );
};

export default ActionPanel;



