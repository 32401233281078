import { FC, useEffect, useState, useReducer } from "react";

import { Form, Divider, Dropdown, FormInstance, theme } from "antd";

import { ConditionsFormProps, Condition, Conditions } from "types";

import Modal from "components/Modal";
import ConditionForm from "./Condition";
import { validateFormFields } from "utility";
import { ColorFormat } from "antd/es/color-picker/interface";

interface DeleteConditionProps {
  enable: boolean;
  orCondKey?: number;
  andCondKey?: number;
}

const ConditionsForm: FC<ConditionsFormProps> = (props) => {
  const {token} = theme.useToken();
  const [form] = Form.useForm();
  const [currentConditions, setCurrentConditions] = useState<Array<Condition | Conditions> | undefined>(props.conditions);
  const [deleteConditionProps, setDeleteConditionProps] = useState<DeleteConditionProps>({ enable: false });

  useEffect(() => {
    props.onRender?.(form);
  }, []);

  const [conditionForms, dispatch] = useReducer(
    (conditionForms: Array<FormInstance | FormInstance[]>, action: any) => {
      switch (action.type) {
        case "add": {
          if (action.andCondKey) {
            const andConditionForms = conditionForms[action.orCondKey];
            return Array.isArray(andConditionForms)
              ? [
                  ...conditionForms.slice(0, action.orCondKey),
                  [...andConditionForms, action.form],
                  ...conditionForms.slice(action.orCondKey),
                ]
              : [
                  ...conditionForms.slice(0, action.orCondKey),
                  [andConditionForms, action.form],
                  ...conditionForms.slice(action.orCondKey),
                ];
          } else {
            return [
              ...conditionForms.slice(0, action.orCondKey),
              action.form,
              ...conditionForms.slice(action.orCondKey),
            ];
          }
        }
        case "delete": {
          const orConditionForms = [...conditionForms];
          if (action.andCondKey) {
            const andConditionForms = orConditionForms[
              action.orCondKey
            ] as FormInstance[];
            andConditionForms.splice(action.andCondKey, 1);
            orConditionForms[action.orCondKey] =
              andConditionForms.length == 1
                ? andConditionForms[0]
                : andConditionForms;
          } else {
            orConditionForms.splice(action.orCondKey, 1);
          }
          return orConditionForms;
        }
        default:
          throw new Error(`Unknown action type: ${action.type}`);
      }
    },
    []
  );

  const ConditionMenu: FC<{ onCondKey: number; editMode: boolean }> = ({
    onCondKey,
    editMode,
  }) => {
    if (currentConditions) {
      return (
        <Dropdown
          disabled={!editMode}
          menu={{
            items: [
              { key: "and", label: getConditionTypeStyle("AND") },
              { key: "or", label: getConditionTypeStyle("OR") },
            ],
            onClick: ({ key }) => {
              switch (key) {
                case "or": {
                  const nextCondKey = onCondKey + 1;
                  const orConditions = currentConditions.map((_, index) =>
                    form.getFieldValue(["conditions", index])
                  );
                  orConditions.splice(nextCondKey, 0, {} as Condition);
                  form.resetFields(["conditions", nextCondKey]);
                  form.setFields([{ name: ["conditions"], value: orConditions }]);
                  setCurrentConditions(orConditions);
                  break;
                }
  
                case "and": {
                  const orConditions = [...currentConditions];
                  orConditions[onCondKey] = Array.isArray(orConditions[onCondKey])
                    ? [
                        ...form.getFieldValue(["conditions", onCondKey]),
                        {} as Condition,
                      ]
                    : [
                        form.getFieldValue(["conditions", onCondKey]),
                        {} as Condition,
                      ];
                  form.setFields([
                    {
                      name: ["conditions", onCondKey],
                      value: orConditions[onCondKey],
                    },
                  ]);
                  setCurrentConditions(orConditions);
                  break;
                }
              }
            },
          }}
        >
          <a 
            style={{
              fontSize: token.fontSizeSM,
              fontWeight: token.fontWeightStrong,
            }} 
            onClick={(e: any) => e.preventDefault()}>
            Add Condition
          </a>
        </Dropdown>
      );
    } else {
      return (
        editMode
        ?
          <a 
            style={{
              fontSize: token.fontSizeSM,
              fontWeight: token.fontWeightStrong,
            }} 
            onClick={(e: any) => setCurrentConditions([{}] as Conditions)}>
            Add Condition
          </a>
        :
          <div 
            style={{
              fontSize: token.fontSizeSM,
              fontWeight: token.fontWeightStrong,
              color: token.colorTextDisabled
            }} 
          >
            Add Condition
          </div>
      )
    }
  };

  const onConditionDelete = () => {
    if (currentConditions) {
      if (deleteConditionProps.orCondKey != undefined) {
        const orConditions = currentConditions.map((_, index) =>
          form.getFieldValue(["conditions", index])
        );
        if (Array.isArray(orConditions[deleteConditionProps.orCondKey])) {
          if (deleteConditionProps.andCondKey != undefined) {
            const andConditions = orConditions[
              deleteConditionProps.orCondKey
            ] as Conditions;
            andConditions.splice(deleteConditionProps.andCondKey, 1);
            dispatch({
              type: "delete",
              orCondKey: deleteConditionProps.orCondKey,
              andCondKey: deleteConditionProps.andCondKey,
            });
            form.resetFields([
              "conditions",
              deleteConditionProps.orCondKey,
              deleteConditionProps.andCondKey,
            ]);
            if (andConditions.length == 1) {
              orConditions[deleteConditionProps.orCondKey] = andConditions[0];
              form.setFields([
                {
                  name: ["conditions", deleteConditionProps.orCondKey],
                  value: andConditions[0],
                },
              ]);
            } else {
              orConditions[deleteConditionProps.orCondKey] = andConditions;
              form.setFields([
                {
                  name: ["conditions", deleteConditionProps.orCondKey],
                  value: andConditions,
                },
              ]);
            }
          }
          setCurrentConditions(orConditions);
        } else {
          orConditions.splice(deleteConditionProps.orCondKey, 1);
          dispatch({ type: "delete", orCondKey: deleteConditionProps.orCondKey });
          form.resetFields(["conditions", deleteConditionProps.orCondKey]);
          if (orConditions.length == 0 && !props.required) {
            form.setFields([{ name: ["conditions"], value: [{}] as Conditions }]);
            setCurrentConditions(undefined);
          } else {
            form.setFields([{ name: ["conditions"], value: orConditions }]);
            setCurrentConditions(orConditions);
          }
        }
      }
      setDeleteConditionProps({ enable: false });
    }
  };

  const getCondition = (conditions: Conditions, index: number): Condition => {
    return conditions[index];
  };

  const getConditionForm = (
    conditionForms: FormInstance[],
    index: number
  ): FormInstance => {
    return conditionForms[index];
  };

  const onValuesChange = async (changedValues: any, values: any) => {
    props.onChange?.(form.getFieldValue("conditions"));
  };

  const getConditionTypeStyle = (condTypeStr: string) => {
    return <div style={{
      fontSize: token.fontSizeSM,
      color: token.colorPrimary,
    }}>
      {condTypeStr}
    </div>;
  };

  useEffect(() => {
    props.onChange?.(currentConditions);
  }, [currentConditions, deleteConditionProps]);

  return (
    <>
      {deleteConditionProps.enable && (
        <Modal
          title="Delete Condition"
          onClose={() => {
            setDeleteConditionProps({ enable: false });
          }}
          open={deleteConditionProps.enable}
          onSubmit={() => onConditionDelete()}
        >
        {"Are you sure you want to delete condition?"}
        </Modal>
      )}
      <Form
        form={form}
        preserve={false}
        name="conditionsForm"
        layout="vertical"
        autoComplete="off"
        initialValues={{ conditions: currentConditions ? currentConditions : [{}] as Conditions }}
        onValuesChange={onValuesChange}
      >
        <Form.List name="conditions">
          {(orCondFields) => (
            <>
              {orCondFields.map((orCondField) => (
                <div key={orCondField.name}>
                  {orCondField.key != 0 && (
                    <Divider
                      style={{
                        borderColor: token.colorPrimaryBorderHover
                      }}
                      orientation="left"
                      orientationMargin="0"
                    >
                      {getConditionTypeStyle("OR")}
                    </Divider>
                  )}
                  {currentConditions && Array.isArray(currentConditions[orCondField.key]) ? (
                    <>
                      <div style={{
                            borderLeft: `2px solid ${token.colorPrimary}`,
                            paddingLeft: token.paddingXXS
                          }}
                      >
                        <Form.List
                          name={[orCondField.key]}
                          initialValue={
                            currentConditions[orCondField.key] as Conditions
                          }
                        >
                          {(andCondFields) => (
                            <>
                              {andCondFields.map((andCondField) => (
                                <>
                                  {andCondField.key != 0 && (
                                    <Divider
                                      style={{
                                        borderColor: token.colorPrimaryBorderHover
                                      }}
                                      orientation="left"
                                      orientationMargin="0"
                                    >
                                      {getConditionTypeStyle("AND")}
                                    </Divider>
                                  )}
                                  <Form.Item
                                    {...andCondField}
                                    key={andCondField.key}
                                    name={[andCondField.key]}
                                    validateTrigger="onSubmit"
                                    rules={[
                                      {
                                        validator: (_, value) =>
                                          validateFormFields(
                                            getConditionForm(
                                              conditionForms[
                                                orCondField.key
                                              ] as FormInstance[],
                                              andCondField.key
                                            )
                                          ),
                                      },
                                    ]}
                                  >
                                    <ConditionForm
                                      editMode={props.editMode}
                                      condition={getCondition(
                                        currentConditions[
                                          orCondField.key
                                        ] as Conditions,
                                        andCondField.key
                                      )}
                                      isCloseRequired={
                                        props.required ?
                                        !(
                                          orCondField.key == 0 &&
                                          andCondField.key == 0
                                        ) : true
                                      }
                                      factSuggestionsTree={props.factSuggestionsTree}
                                      factList={props.factList}
                                      valueSuggestionsTree={props.valueSuggestionsTree}
                                      valueList={props.valueList}
                                      onRender={(form) =>
                                        dispatch({
                                          type: "add",
                                          form: form,
                                          orCondKey: orCondField.key,
                                          andCondKey: andCondField.key,
                                        })
                                      }
                                      onDelete={() =>
                                        setDeleteConditionProps({
                                          enable: true,
                                          orCondKey: orCondField.key,
                                          andCondKey: andCondField.key,
                                        })
                                      }
                                    />
                                  </Form.Item>
                                </>
                              ))}
                            </>
                          )}
                        </Form.List>
                      </div>
                      {<ConditionMenu
                          onCondKey={orCondField.key}
                          editMode={props.editMode}
                      />}
                    </>
                  ) : (
                    <>
                      {currentConditions && 
                        <div style={{
                              borderLeft: `2px solid ${token.colorPrimary}`,
                              paddingLeft: token.paddingXXS,
                            }}
                        >
                          <Form.Item
                            {...orCondField}
                            key={orCondField.key}
                            name={[orCondField.key]}
                            validateTrigger="onSubmit"
                            rules={[
                              {
                                validator: (_, value) =>
                                  validateFormFields(
                                    conditionForms[
                                      orCondField.key
                                    ] as FormInstance
                                  ),
                              },
                            ]}
                          >
                            <ConditionForm
                              editMode={props.editMode}
                              condition={
                                currentConditions[orCondField.key] as Condition
                              }
                              isCloseRequired={props.required ? orCondField.key != 0 : true}
                              factSuggestionsTree={props.factSuggestionsTree}
                              factList={props.factList}
                              valueSuggestionsTree={props.valueSuggestionsTree}
                              valueList={props.valueList}
                              onRender={(form) =>
                                dispatch({
                                  type: "add",
                                  form: form,
                                  orCondKey: orCondField.key,
                                })
                              }
                              onDelete={() =>
                                setDeleteConditionProps({
                                  enable: true,
                                  orCondKey: orCondField.key,
                                })
                              }
                            />
                          </Form.Item>
                        </div>
                      }
                      {<ConditionMenu
                          onCondKey={orCondField.key}
                          editMode={props.editMode}
                      />}
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};

export default ConditionsForm;
