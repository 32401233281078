import { memo, useEffect, useState } from "react";
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'
import { Handle, Position } from "reactflow";
import { DraggableItem, DraggableSourceType } from "types";

import NodeBody from "./Body";
import { useNodeStyles } from "./styles";

const ActionNode = (props: any) => {
  const commonStyles = useNodeStyles();
  const [mouseDown, setMouseDown] = useState(false);
  const item: DraggableItem = {
    id: props.id,
    nodeType: props.data.nodeType,
    resourceId: props.data.resourceId,
    resourceType: props.data.resourceType,
    source: DraggableSourceType.Editor,
  };

  const [collected, dragActionRef, dragActionPreview] = useDrag(() => ({
    type: DraggableSourceType.Editor,
    item: item,
    canDrag: (monitor) => {
      return props.data.editMode
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: !!monitor.isDragging(),
      }
    },
    options: {
      dropEffect: 'move',
    },
  }));

  useEffect(() => {
    dragActionPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div  
      key={props.id} 
      className={`${commonStyles.node} nopan`}
      ref={dragActionRef}
      style={{
        cursor: "pointer",
        position: "relative",
      }}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
    >
      <Handle
        className={commonStyles.nodeTopHandle}
        type="target"
        position={Position.Top}
      />  
      <NodeBody {...props.data} />
      <Handle
        className={commonStyles.nodeBottomHandle}
        type="source"
        position={Position.Bottom}
        id="a"
      />
    </div>
  );
};

export default memo(ActionNode);
