
export const awsRegions = [
    { "region": "us-east-1", "city": "N. Virginia, USA", "coordinates": [39.0438, -77.4874] }, //[38.9531, -77.3467]
    { "region": "us-east-2", "city": "Ohio, USA", "coordinates": [40.4173, -82.9071] },
    { "region": "us-west-1", "city": "N. California, USA", "coordinates": [37.7749, -122.4194] },
    { "region": "us-west-2", "city": "Oregon, USA", "coordinates": [45.5231, -122.6765] },
    { "region": "ca-central-1", "city": "Montreal, Canada", "coordinates": [45.5017, -73.5673] },
    { "region": "eu-central-1", "city": "Frankfurt, Germany", "coordinates": [50.1109, 8.6821] },
    { "region": "eu-west-1", "city": "Ireland", "coordinates": [53.3331, -6.2489] },
    { "region": "eu-west-2", "city": "London, UK", "coordinates": [51.5074, -0.1278] },
    { "region": "eu-west-3", "city": "Paris, France", "coordinates": [48.8566, 2.3522] },
    { "region": "eu-north-1", "city": "Stockholm, Sweden", "coordinates": [59.3293, 18.0686] },
    { "region": "ap-southeast-1", "city": "Singapore", "coordinates": [1.3521, 103.8198] },
    { "region": "ap-southeast-2", "city": "Sydney, Australia", "coordinates": [-33.8688, 151.2093] },
    { "region": "ap-southeast-3", "city": "Jakarta, Indonesia", "coordinates": [-6.2088, 106.8456] },
    { "region": "ap-northeast-1", "city": "Tokyo, Japan", "coordinates": [35.6895, 139.6917] },
    { "region": "ap-northeast-2", "city": "Seoul, South Korea", "coordinates": [37.5665, 126.9780] },
    { "region": "ap-northeast-3", "city": "Osaka, Japan", "coordinates": [34.6937, 135.5023] },
    { "region": "ap-south-1", "city": "Mumbai, India", "coordinates": [19.0760, 72.8777] },
    { "region": "sa-east-1", "city": "São Paulo, Brazil", "coordinates": [-23.5505, -46.6333] },
    { "region": "me-south-1", "city": "Bahrain", "coordinates": [26.0667, 50.5577] },
    { "region": "af-south-1", "city": "Cape Town, South Africa", "coordinates": [-33.9249, 18.4241] }
];

export const azureRegions = [
    { "region": "eastus", "city": "Ashburn, Virginia", "coordinates": [39.0438, -77.4874] },
    { "region": "eastus2", "city": "Virginia", "coordinates": [36.6681, -78.3889] },
    { "region": "centralus", "city": "Des Moines, Iowa", "coordinates": [41.5868, -93.6250] },
    { "region": "northcentralus", "city": "Chicago, Illinois", "coordinates": [41.8781, -87.6298] },
    { "region": "southcentralus", "city": "San Antonio, Texas", "coordinates": [29.4241, -98.4936] },
    { "region": "westus", "city": "California", "coordinates": [37.7749, -122.4194] },
    { "region": "westus2", "city": "Washington", "coordinates": [47.6062, -122.3321] },
    { "region": "westcentralus", "city": "Wyoming", "coordinates": [43.0750, -107.2903] },
    { "region": "northeurope", "city": "Dublin, Ireland", "coordinates": [53.3498, -6.2603] },
    { "region": "westeurope", "city": "Amsterdam, Netherlands", "coordinates": [52.3676, 4.9041] },
    { "region": "uksouth", "city": "London, United Kingdom", "coordinates": [51.5074, -0.1278] },
    { "region": "ukwest", "city": "Cardiff, United Kingdom", "coordinates": [51.4816, -3.1791] },
    { "region": "francecentral", "city": "Paris, France", "coordinates": [48.8566, 2.3522] },
    { "region": "francesouth", "city": "Marseille, France", "coordinates": [43.2965, 5.3698] },
    { "region": "germanycentral", "city": "Frankfurt, Germany", "coordinates": [50.1109, 8.6821] },
    { "region": "germanynorth", "city": "Berlin, Germany", "coordinates": [52.5200, 13.4050] },
    { "region": "canadaeast", "city": "Quebec City, Quebec", "coordinates": [46.8139, -71.2082] },
    { "region": "canadacentral", "city": "Toronto, Ontario", "coordinates": [43.6510, -79.3470] },
    { "region": "australiaeast", "city": "Sydney, Australia", "coordinates": [-33.8688, 151.2093] },
    { "region": "australiasoutheast", "city": "Melbourne, Australia", "coordinates": [-37.8136, 144.9631] },
    { "region": "japaneast", "city": "Tokyo, Japan", "coordinates": [35.6895, 139.6917] },
    { "region": "japanwest", "city": "Osaka, Japan", "coordinates": [34.6937, 135.5023] },
    { "region": "koreacentral", "city": "Seoul, South Korea", "coordinates": [37.5665, 126.9780] },
    { "region": "koreasouth", "city": "Busan, South Korea", "coordinates": [35.1796, 129.0756] },
    { "region": "southeastasia", "city": "Singapore", "coordinates": [1.3521, 103.8198] },
    { "region": "eastasia", "city": "Hong Kong", "coordinates": [22.3193, 114.1694] },
    { "region": "brazilsouth", "city": "São Paulo, Brazil", "coordinates": [-23.5505, -46.6333] },
    { "region": "indiacentral", "city": "Pune, India", "coordinates": [18.5204, 73.8567] },
    { "region": "indiasouth", "city": "Chennai, India", "coordinates": [13.0827, 80.2707] },
    { "region": "indiawest", "city": "Mumbai, India", "coordinates": [19.0760, 72.8777] },
    { "region": "southafricanorth", "city": "Johannesburg, South Africa", "coordinates": [-26.2041, 28.0473] },
    { "region": "southafricawest", "city": "Cape Town, South Africa", "coordinates": [-33.9249, 18.4241] },
    { "region": "westus3", "city": "Goodyear, El Mirage, Phoenix", "coordinates": [33.4484, -112.0740] } //[33.4358, -112.3496]
]

export const gcpRegions = [
    { "region": "us-central1", "city": "Council Bluffs, Iowa, USA", "coordinates": [41.2619, -95.8608] },
    { "region": "us-east1", "city": "Moncks Corner, South Carolina, USA", "coordinates": [33.1954, -79.9980] },
    { "region": "us-east4", "city": "Ashburn, Virginia, USA", "coordinates": [39.0438, -77.4874] },
    { "region": "us-west1", "city": "The Dalles, Oregon, USA", "coordinates": [45.5946, -121.1787] },
    { "region": "us-west2", "city": "Los Angeles, California, USA", "coordinates": [34.0522, -118.2437] },
    { "region": "us-west3", "city": "Salt Lake City, Utah, USA", "coordinates": [40.7608, -111.8910] },
    { "region": "us-west4", "city": "Las Vegas, Nevada, USA", "coordinates": [36.1699, -115.1398] },
    { "region": "northamerica-northeast1", "city": "Montréal, Québec, Canada", "coordinates": [45.5017, -73.5673] },
    { "region": "southamerica-east1", "city": "São Paulo, Brazil", "coordinates": [-23.5505, -46.6333] },
    { "region": "europe-north1", "city": "Hamina, Finland", "coordinates": [60.5690, 27.1979] },
    { "region": "europe-west1", "city": "St. Ghislain, Belgium", "coordinates": [50.4547, 3.8186] },
    { "region": "europe-west2", "city": "London, England, UK", "coordinates": [51.5074, -0.1278] },
    { "region": "europe-west3", "city": "Frankfurt, Germany", "coordinates": [50.1109, 8.6821] },
    { "region": "europe-west4", "city": "Eemshaven, Netherlands", "coordinates": [53.4170, 6.8274] },
    { "region": "europe-west6", "city": "Zurich, Switzerland", "coordinates": [47.3769, 8.5417] },
    { "region": "asia-east1", "city": "Changhua County, Taiwan", "coordinates": [23.9920, 120.5340] },
    { "region": "asia-east2", "city": "Hong Kong", "coordinates": [22.3193, 114.1694] },
    { "region": "asia-northeast1", "city": "Tokyo, Japan", "coordinates": [35.6895, 139.6917] },
    { "region": "asia-northeast2", "city": "Osaka, Japan", "coordinates": [34.6937, 135.5023] },
    { "region": "asia-northeast3", "city": "Seoul, South Korea", "coordinates": [37.5665, 126.9780] },
    { "region": "asia-south1", "city": "Mumbai, India", "coordinates": [19.0760, 72.8777] },
    { "region": "asia-southeast1", "city": "Jurong West, Singapore", "coordinates": [1.3521, 103.8198] },
    { "region": "asia-southeast2", "city": "Jakarta, Indonesia", "coordinates": [-6.2088, 106.8456] },
    { "region": "australia-southeast1", "city": "Sydney, Australia", "coordinates": [-33.8688, 151.2093] },
    { "region": "australia-southeast2", "city": "Melbourne, Australia", "coordinates": [-37.8136, 144.9631] },
    { "region": "europe-central2", "city": "Warsaw, Poland", "coordinates": [52.2297, 21.0122] },
    { "region": "southamerica-west1", "city": "Santiago, Chile", "coordinates": [-33.4489, -70.6693] }
]

export const ociRegions = [
    { "region": "us-ashburn-1", "city": "Ashburn, Virginia, USA", "coordinates": [39.0438, -77.4874] },
    { "region": "us-phoenix-1", "city": "Phoenix, Arizona, USA", "coordinates": [33.4484, -112.0740] },
    { "region": "us-sanjose-1", "city": "San Jose, California, USA", "coordinates": [37.7749, -122.4194] }, //[37.3382, -121.8863]
    { "region": "us-chicago-1", "city": "Chicago, Illinois, USA", "coordinates": [41.8781, -87.6298] },
    { "region": "ca-toronto-1", "city": "Toronto, Ontario, Canada", "coordinates": [43.6510, -79.3470] },
    { "region": "ca-montreal-1", "city": "Montreal, Quebec, Canada", "coordinates": [45.5017, -73.5673] },
    { "region": "uk-london-1", "city": "London, England, UK", "coordinates": [51.5074, -0.1278] },
    { "region": "uk-cardiff-1", "city": "Cardiff, Wales, UK", "coordinates": [51.4816, -3.1791] },
    { "region": "eu-frankfurt-1", "city": "Frankfurt, Germany", "coordinates": [50.1109, 8.6821] },
    { "region": "eu-amsterdam-1", "city": "Amsterdam, Netherlands", "coordinates": [52.3676, 4.9041] },
    { "region": "eu-zurich-1", "city": "Zurich, Switzerland", "coordinates": [47.3769, 8.5417] },
    { "region": "eu-marseille-1", "city": "Marseille, France", "coordinates": [43.2965, 5.3698] },
    { "region": "me-dubai-1", "city": "Dubai, UAE", "coordinates": [25.2048, 55.2708] },
    { "region": "me-jeddah-1", "city": "Jeddah, Saudi Arabia", "coordinates": [21.4858, 39.1925] },
    { "region": "sa-saopaulo-1", "city": "São Paulo, Brazil", "coordinates": [-23.5505, -46.6333] },
    { "region": "sa-santiago-1", "city": "Santiago, Chile", "coordinates": [-33.4489, -70.6693] },
    { "region": "ap-tokyo-1", "city": "Tokyo, Japan", "coordinates": [35.6895, 139.6917] },
    { "region": "ap-osaka-1", "city": "Osaka, Japan", "coordinates": [34.6937, 135.5023] },
    { "region": "ap-seoul-1", "city": "Seoul, South Korea", "coordinates": [37.5665, 126.9780] },
    { "region": "ap-mumbai-1", "city": "Mumbai, India", "coordinates": [19.0760, 72.8777] },
    { "region": "ap-sydney-1", "city": "Sydney, Australia", "coordinates": [-33.8688, 151.2093] },
    { "region": "ap-melbourne-1", "city": "Melbourne, Australia", "coordinates": [-37.8136, 144.9631] },
    { "region": "ap-singapore-1", "city": "Singapore", "coordinates": [1.3521, 103.8198] },
    { "region": "ap-hyderabad-1", "city": "Hyderabad, India", "coordinates": [17.3850, 78.4867] },
    { "region": "ap-chuncheon-1", "city": "Chuncheon, South Korea", "coordinates": [37.8813, 127.7297] }
]
