import { Space } from "antd";
import {
  RadioField,
  ObjectField,
  EditorContext,
  TextField,
  ViewProps,
} from "components/EntityEditor";
import {
  ResourceType,
  DeveloperItem,
  AppEdgeDetectionHintType,
  AppEdgeDetectorConst,
  EdgeInfraType,
  
} from "types";

import React, { useContext, useEffect } from "react";

export const AppEdgeDetectorView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);
  const [configurations] = React.useState<{ [k: string]: any }>(
    props.context ? props.context : {}
  );

  const [edgeDetectionHintType, setEdgeDetectionHintType] =
    React.useState<AppEdgeDetectionHintType>(
      target["detectionHint"]
        ? target["detectionHint"]
        : AppEdgeDetectionHintType.AppEdgeDetectionHintConstant
    );

  const onRadioChange = async (identifier: string, value: string) => {
    if (identifier == "detectionHint")
      setEdgeDetectionHintType(value as AppEdgeDetectionHintType);
    setTarget({ ...target, [identifier]: value });
  };

  const onObjectChange = async (key: string, value: any) => {
    setTarget({ ...target, [key]: value });
  };

  const edgeDetectionHintOptions = () => {
    return [
      {
        label: "Via connectivity action",
        value: AppEdgeDetectionHintType.AppEdgeDetectionHintConnectivity,
      },
      {
        label: "Using provided constant",
        value: AppEdgeDetectionHintType.AppEdgeDetectionHintConstant,
      },
    ];
  };

  
  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }} size={"large"}>
      <RadioField
        identifier={"detectionHint"}
        label={"Edge Detection Hint"}
        help={"Edge detection hint which HyprEdge can use to choose right edge for app"}
        value={target?.["detectionHint"]}
        path={[...props.path, "detectionHint"]}
        onChange={onRadioChange}
        defaultSelected={edgeDetectionHintType}
        direction="vertical"
        options={edgeDetectionHintOptions()}
      />
      {edgeDetectionHintType ==
        AppEdgeDetectionHintType.AppEdgeDetectionHintConstant && (
        <ObjectField
          item={{
            id: "app-edge-detection-constant",
            item:
              target?.["constantDetector"] ||
              ({
                infraType: EdgeInfraType.Amazon,
                region: "us-west-2",
              } as AppEdgeDetectorConst),
            type: ResourceType.AppEdgeDetectorConstant,
          }}
          identifier="constantDetector"
          label="Constant Detection Parameters"
          help={"Constant edge detection technique"}
          onChange={onObjectChange}
          path={[...props.path, "constantDetector"]}
          showExpanded={true}
        />
      )}
      
    </Space>
  );
};
