import { Layout, Spin } from "antd";
import { FC, ReactNode, useState } from "react";

import { useSidebarStore, useTokenStore } from "store";

import { auth } from "api";
import { IdleManager } from "components/IdleManager";
import CustomHeader from "./Header";
import Sidebar from "./Sidebar";

const { Content } = Layout;

const PageWrapper: FC<{
  /** Header area , if necessary */
  header?: ReactNode;
  /** Top area just below header bar to show big metrics, optional */
  highlights?: ReactNode;
  /** Work area , necessary */
  content: ReactNode;
  /** feedback area about user actions, actvity log for example, optional */
  feedback?: ReactNode;
  /** whether to show search bar or not, default true */
  showSearch?: boolean;
  /** whether to show context switch or not, default true */
  showContext?: boolean;
}> = ({ header, highlights, content, feedback, showSearch = true }) => {
  const sidebarWidth = useSidebarStore((state) => state.sidebarWidth);
  const [loader] = useState(false);
  const setToken = useTokenStore((state) => state.setToken);

  const onIdle = async () => {
    localStorage.clear();
    setToken(null);
    auth.logout({ returnTo: process.env.REACT_APP_SIGNIN_URI });
  };

  return (
    <Spin spinning={loader}>
      <IdleManager onIdle={onIdle}></IdleManager>
      <Layout
        style={{
          height: "100vh",
          minWidth: "1440px",
          
        }}
      >
        <Sidebar />
        <Layout
          style={{
            marginLeft: sidebarWidth,
            overflow: "hidden",
          }}
        >
          <CustomHeader showSearch={showSearch}/>
          {header && <div>{header}</div>}
          <Content
            style={{
              height: "100%",
              width: "100%",
              overflowX: "hidden",
            }}
          >
            {highlights && <div>{highlights}</div>}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              {content}
              {feedback}
            </div>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default PageWrapper;
