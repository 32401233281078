import { Space } from "antd";
import { EditorContext, TextField, ViewProps } from "components/EntityEditor";

import React, { useContext, useEffect } from "react";

export const HttpRequestBasicAuthView = (props: ViewProps) => {
  const editorCtx = useContext(EditorContext);
  const mounted = React.useRef(false);
  const [target, setTarget] = React.useState<{ [k: string]: any }>(props.data);

  const onPrimitiveChange = async (
    identifier: string,
    value: string | boolean | number
  ) => {
    setTarget({ ...target, [identifier]: value });
  };

  useEffect(() => {
    mounted.current ? editorCtx.onChange(target) : (mounted.current = true);
  }, [target]);

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <TextField
        identifier={"username"}
        label={"User Name"}
        help={"User name for basic auth"}
        value={target["username"]}
        path={[...props.path, "username"]}
        onChange={onPrimitiveChange}
      />
      <TextField
        identifier={"password"}
        label={"Password"}
        help={"Password for basic auth"}
        value={target["password"]}
        path={[...props.path, "password"]}
        onChange={onPrimitiveChange}
        password={true}
      />
    </Space>
  );
};
