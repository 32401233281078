import { Layouts } from "react-grid-layout";
import {
  Metric, MetricLayout,
} from "types";
import { jsonApiDeserializer, metricsApiPath, metricsLayoutsApiPath } from "./constant";
import { AxiosConfig, axiosInterceptor } from "./axios";
//import defaultMetrics from 'data/defaultMetrics.json'


export const getMetricLayouts = async () : Promise<MetricLayout[]> => { 
  const url = `${metricsLayoutsApiPath}`
  const response = await axiosInterceptor("get", url);
  const metricLayouts = jsonApiDeserializer.deserialize(response.data) as MetricLayout[];
  return metricLayouts;
}

const shouldRetryOnResponse = (response: any) => {
  if(response.status == 200 && 
      response?.data?.meta?.retry == true  && 
      response?.data?.meta?.runID && response?.data?.meta?.runID != "") {
        return true;
  }
  return false;
}
const getEvalutedMetricsApiUsingRunID = async (runId: string) : Promise<Metric[]> => { 
  const url = `${metricsApiPath}?runID=${runId}`
  const config: AxiosConfig = { maxRetries: 30, retryDelay: 2*1000, shouldRetryOnResponse: shouldRetryOnResponse};
  const pollResponse = await axiosInterceptor("get", url, null, config);
  const metrics = jsonApiDeserializer.deserialize(pollResponse.data) as Metric[];
  return metrics; 
}

export const getMetricsApi = async (cache?: boolean) : Promise<Metric[]> => { 
  let url = `${metricsApiPath}`

  if (cache) {
    url += `?cache=true`
  }
  const response = await axiosInterceptor("get", url);
  if(response?.data?.meta?.retry == true  && response?.data?.meta?.runID && response?.data?.meta?.runID != "") {
    const metrics =  await getEvalutedMetricsApiUsingRunID(response?.data?.meta?.runID)
    return metrics
  }else {
    const metrics = jsonApiDeserializer.deserialize(response.data) as Metric[];
    return metrics;
  }
}

export const getMetricsWithLayoutApi = async (layout: string, cache?: boolean) : Promise<Metric[]> => { 
  let url = `${metricsApiPath}?layout=${layout}`

  if (cache) {
      url += `&cache=true`
  }
  const response = await axiosInterceptor("get", url);
  if(response?.data?.meta?.retry == true  && response?.data?.meta?.runID && response?.data?.meta?.runID != "") {
    const metrics =  await getEvalutedMetricsApiUsingRunID(response?.data?.meta?.runID)
    return metrics
  }else {
    const metrics = jsonApiDeserializer.deserialize(response.data) as Metric[];
    return metrics;
  }
}