import { CloseOutlined } from "@ant-design/icons";
import { Card, Input, message, Select, Space, Typography } from "antd";
import React, { PropsWithChildren, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { DeveloperItem, INVALID_DEVELOPER_ITEM } from "types";

import { FieldProps, ViewerType } from "./common";
const { Text } = Typography;

import {
  CaretDownOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ResourceType } from "types";
import { FieldLabel } from "./fieldLabel";
import { SearchableCollection } from "./searchableCollection";
import { ViewCreator } from "./viewCreator";

const useStyles = createUseStyles({
  renderCard: {
    width: "25%",
    minWidth: "400px",
    maxWidth: "400px",
    top: "130px",
    right: "1%",
    position: "fixed",
    border: ".5px solid #c1c1c1",
    borderRadius: "2px",
    zIndex: "100",
  },
  cardFooter: {
    background: "transparent",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
});

/**
 * Cannot use text area with options
 */
interface ObjectBackedTextFieldProps extends FieldProps {
  value: DeveloperItem;
  options?: DeveloperItem[];
  /** Whether we should support and invoke a button allowing adding a new option separately */
  onAdd?: (key: string) => Promise<DeveloperItem>;
  /** Callback when the supplementary item is finished eing added */
  onEditDone?: (key: string, item: { [k: string]: any }) => Promise<void>;
  /** When the selected value changes */
  onChange: (key: string, value: string) => Promise<void>;
  viewerType: ViewerType;
  /** Applicable only when options is specified */
  popupOptions?: boolean;
}

/** react function component */
export const ObjectBackedTextField = (
  props: PropsWithChildren<ObjectBackedTextFieldProps>
) => {
  
  const [editItem, setEditItem] = React.useState<DeveloperItem>(
    INVALID_DEVELOPER_ITEM
  );
  const [popupSearch, setPopupSearch] = React.useState(false);

  /** Call when the additional external item is done  */
  const onEditDone = async (item: { [k: string]: any }) => {
    try {
      await props.onEditDone?.(props.identifier, item);
    } catch (e) {
      message.error("Error creating item");
    }
    setEditItem(INVALID_DEVELOPER_ITEM);
  };

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [popupSelected, setPopupSelected] = React.useState<DeveloperItem>(
    props.value
  );

  useEffect(() => {
    setPopupSelected(props.value);
  }, [props.value]);

  const classes = useStyles();
  return (
    <Space
      direction="vertical"
      size={"small"}
      style={{ display: "flex", width: "100%" }}
    >
      {/* label */}
      <FieldLabel fp={props} />
      <div>
        {props.options && props.options.length > 0 ? (
          /** Ant design dropdown using the props option */
          <>
            {!props.popupOptions ? (
              <Select
                defaultValue={props.value.id}
                options={props.options.map((item) => ({
                  label: item.context?.displayName,
                  value: item.id,
                }))}
                onChange={(value) => {
                  props.onChange(props.identifier, value);
                  setDropdownOpen(false);
                }}
                bordered={true}
                open={dropdownOpen}
                suffixIcon={
                  <Space>
                    <CaretDownOutlined
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                    />
                    {props.onAdd && (
                      <PlusOutlined
                        onClick={async () => {
                          const item = await props.onAdd?.(props.identifier);
                          if (item) setEditItem(item);
                        }}
                      />
                    )}
                  </Space>
                }
                style={{
                  display: "flex",
                  width: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
              />
            ) : (
              <Input
                disabled
                defaultValue={props.value?.context?.displayName}
                value={popupSelected?.context?.displayName}
                addonAfter={
                  <Space>
                    <SearchOutlined
                      onClick={async () => setPopupSearch(true)}
                    />
                    {props.onAdd && (
                      <PlusOutlined
                        onClick={async () => {
                          const item = await props.onAdd?.(props.identifier);
                          if (item) setEditItem(item);
                        }}
                      />
                    )}
                  </Space>
                }
              />
            )}
          </>
        ) : (
          <Input
            defaultValue={props.value.context?.displayName}
            onChange={(e) => {
              props.onChange(props.identifier, e.target.value);
            }}
            maxLength={500}
          />
        )}

        {editItem.type != ResourceType.Unknown && (
          <ViewCreator
            item={editItem}
            name={"New"}
            onSave={onEditDone}
            onCancel={async () => setEditItem(INVALID_DEVELOPER_ITEM)}
            onDelete={async () => setEditItem(INVALID_DEVELOPER_ITEM)}
            path={props.path}
            viewerType={props.viewerType}
          />
        )}

        {popupSearch && props.options?.length ? (
          <Card
            size="small"
            title={"Select " + props.label}
            extra={<CloseOutlined onClick={() => setPopupSearch(false)} />}
            className={classes.renderCard}
            headStyle={{
              backgroundColor: "#8377C6",
              textAlign: "left",
              color: "white",
              zIndex: 100,
            }}
            bodyStyle={{
              backgroundColor: "white",
              maxHeight: "calc(100vh - 179px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <SearchableCollection
              items={props.options}
              onSelect={async (item) => {
                setPopupSelected(item);
                await props.onChange(props.identifier, item.id);
                setPopupSearch(false);
              }}
              current={popupSelected}
            />
          </Card>
        ) : null}
      </div>
    </Space>
  );
};
