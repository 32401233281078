import { FC, useEffect, useMemo, useState } from "react";
import { Tree, theme } from "antd";

import { SearchableTreeProps } from "types";
import SearchInput from "components/SearchInput";

const getParentKey = (key: React.Key, tree: any[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

const SearchableTree: FC<SearchableTreeProps> = ({
  placeholder,
  treeData,
  onSelect,
}) => {
  const { token } = theme.useToken();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const dataList: { key: React.Key; title: string }[] = [];
  
  useEffect(() => {
    setExpandedKeys(treeData?.map((node: any) => (node.key)));
  }, []);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

 
  const generateList = (data: any[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key } = node;
      dataList.push({ key, title: key as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  };
  generateList(treeData);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    console.log("value", value);
    if (value == "") {
      setExpandedKeys(treeData?.map((node: any) => (node.key)));
      return;
    }
    const newExpandedKeys = dataList.map((item) => {
      if (item.title.indexOf(value) > -1) {
        return getParentKey(item.key, treeData);
      }
      return null;
    }).filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(newExpandedKeys as React.Key[]);
    setAutoExpandParent(true);
  };

  return (
    <>
      <div style={{ margin: token.marginXS }}>
        <SearchInput
          placeholder={placeholder ? placeholder: "search..."}
          onFilter={onChange}
        />
      </div>
      <Tree
        showIcon
        onExpand={onExpand}
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
        onSelect={(_: React.Key[], info: any) =>  onSelect?.(info.node)}
        treeData={treeData}
      >
      </Tree>
    </>
  );
};

export default SearchableTree;