import { FC, useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Typography } from "antd";
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

import { WorkflowCardOutputProps, IODataType } from "types";

import { FieldLabel } from "components/FieldLabel";
import { SuggestionsInput, validateSuggestionInput } from "components/Suggestions";
import { generateRandomString } from "utility";

const WorkflowCardOutput: FC<WorkflowCardOutputProps> = (props) => {
  const [form] = Form.useForm();
  const [stringVal, setStringVal] = useState("");
  const [numericVal, setNumericVal] = useState(0);
  const [booleanVal, setBooleanVal] = useState(true);
  const [referenceName, setReferenceName] = useState<string>(props.output?.referenceName);

  const generateOutputReferenceName = (name: string) => {
    if (name != "") {
        let referenceName = name.replaceAll(" ", "_").replaceAll("-", "_").toLowerCase()
        referenceName += "_" + generateRandomString(3).toLowerCase();
        return referenceName
    } else {
      return "";
    }
  }

  useEffect(() => {
    props.onRender?.(form);
    setReferenceName(generateOutputReferenceName(props.output.name));
  }, []);

  
  const onValuesChange = async (changedValues: any, _: any) => {
    const values = form.getFieldsValue(true);
    values.type = IODataType.String;
    values.referenceName = referenceName;
    props.onChange?.(values);
  };

  return (
    <Form 
      form={form} 
      name="outputForm" 
      layout="vertical" 
      autoComplete="off"
      initialValues={props.output}
      onValuesChange={onValuesChange}>
        <Form.Item 
          name="name" 
          label={<FieldLabel label={"Name"} help={"Name of output"} />}
          rules={[
            { required: true, message: 'Name is required!'}
          ]}
          extra={referenceName != "" &&  <Text italic type="secondary">Reference Name: {referenceName}</Text>}
        >
          <Input disabled={!props.editMode} onChange={(val) => setReferenceName(generateOutputReferenceName(val.target.value))}/>
        </Form.Item>
        <Form.Item 
          name="description" 
          label={<FieldLabel label={"Description"} help={"Description of output"} />}
        >
          <TextArea
            disabled={!props.editMode}
            showCount
            maxLength={100}
          />
        </Form.Item>
       {/*  <Form.Item 
          name="type" 
          label={<FieldLabel label={"Type"} help={"Type of output"} />}
          rules={[
            { required: true, message: 'Type is required!'},
          ]}>
            <Select
              disabled={!props.editMode}
              showAction={["focus", "click"]}
              onChange={(value) => 
                form.setFieldValue("value", (value == IODataType.String) ? stringVal : (value == IODataType.Numeric) ? numericVal : booleanVal)}
            >
              <Option key={IODataType.String} value={IODataType.String}>String</Option>
              <Option key={IODataType.Numeric} value={IODataType.Numeric}>Number</Option>
              <Option key={IODataType.Boolean} value={IODataType.Boolean}>Boolean</Option>
            </Select>
        </Form.Item> */}
        <Form.Item 
          name="value" 
          label={<FieldLabel label={"Value"} help={"Value of output"} />}
          rules={[
            { required: true, message: 'Value is required!'},
            {
              validator: (_, value) => validateSuggestionInput(value) ? Promise.resolve(): Promise.reject("Invalid input")
            }
          ]}>
            <SuggestionsInput
              editMode={props.editMode}
              name="value"
              suggestionsTree={props.outputSuggestionsTree}
              setValue={setStringVal}
            />
        </Form.Item>
    </Form>
  );
};
  
export default WorkflowCardOutput;