import { Form, Input, Modal } from "antd";
import { notification } from "utility/notification";

import { useState } from "react";
import { useDashboardStore } from "store";

export interface DashboardNewProps {
  open: boolean;
  onClose: () => Promise<void>;
}

export const DashboardNew = (props: DashboardNewProps) => {
  const [, setLoading] = useState(false);
  const [form] = Form.useForm();
  const createDashboard = useDashboardStore((state) => state.createDashboard);

  const handleSubmit = async () => {
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        const { name, description } = await form.validateFields();
        await createDashboard(name, description, "#000000");
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to create dashboard, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const onClose = async () => {
    props.onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      onOk={handleSubmit}
      title={"New Category"}
      open={props.open}
      width={512}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 8, offset: 0 }}
          wrapperCol={{ span: 16, offset: 0 }}
        >
          <div id={"create-dashboard-name"}>
            <Form.Item
              id={"name"}
              label={"Name"}
              name={"name"}
              rules={[
                { required: true, message: "Please input dashboard name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div id={"create-dashboard-description"}>
            <Form.Item
              id={"description"}
              label={"Description"}
              name={"description"}
              rules={[
                {
                  required: true,
                  message: "Please input dashboard description!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
