import { Avatar, Popover, theme } from "antd";
import { AvatarSize } from "antd/es/avatar/AvatarContext";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { BigScreenQuery, Item } from "types";

export interface ItemsGroupProps {
  items: Item[];
  size?: AvatarSize;
}

export const ItemsGroup: FC<ItemsGroupProps> = ({ items, size }) => {
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const avatarBgColors = ["#f56a00", "#87d068", "#1677ff"];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: token.marginXS,
      }}
    >
      <Avatar.Group maxCount={isBigScreen ? 5 : 3}>
        {items?.map((item: Item, index: number) => (
          <Popover content={item.description} key={item.id}>
            <Avatar
              // style={{
              //   backgroundColor: avatarBgColors[index % 3],
              // }}
              size={size ? size : "small"}
            >
              {item.name?.substring(0, 2) || "NA"}
            </Avatar>
          </Popover>
        ))}
      </Avatar.Group>
    </div>
  );
};
