import { create } from "zustand";
import { devtools, persist, subscribeWithSelector } from "zustand/middleware";

import {
  HyprFlowTabType,
  OperatorType,
  PageInfo,
  SpecStateType,
  Workflow,
  WorkflowActionType,
  WorkflowGlobalStore,
  workflowGlobalStoreName,
  WorkflowRun,
  WorkflowRunResult,
  WorkflowRunStatusCode,
  WorkflowRunStepResult,
  WorkflowStore,
  workflowStoreName
} from "types";

import {
  actionWorkflowApi,
  cancelWorkflowRunApi,
  createWorkflowApi,
  deleteWorkflowApi,
  getWorkflowApi,
  getWorkflowRunApi,
  getWorkflowRunStatusApi,
  getWorkflowsApi,
  updateWorkflowApi
} from "api";
import { autoPopulateWorkflowStepsProviders } from "utility/workflow";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "store/utils";

export const useWorkflowStore = create<WorkflowStore>()(
  subscribeWithSelector(devtools((set, get) => ({
    workflows: [] as Workflow[],
    templates: [] as Workflow[],
    selectedWorkflow: {} as Workflow,
    selectedWorkflowRun: {status: WorkflowRunStatusCode.NotRunning} as WorkflowRun,
    subworkflows: [],
    activeNodeId: "",
    activeNodeCardId: "",
    activeDragItem: null,
    activeDropItem: null,
    activeCopyId: null,
    elements: [],
    stepsSidebarCollapsed: false,
    renderFlowInitialized: false,
    workflowPage: {
      [HyprFlowTabType.Workflows]: { number: 1, size: 12, total: 0 } as PageInfo,
      [HyprFlowTabType.WorkflowTemplates]: { number: 1, size: 12, total: 0 } as PageInfo
    } as Record<HyprFlowTabType, PageInfo>,
    workflowFilters:{
      [HyprFlowTabType.Workflows]: {} as Record<string, FilterValue | null>,
      [HyprFlowTabType.WorkflowTemplates]: {} as Record<string, FilterValue | null>
    } as Record<HyprFlowTabType, Record<string, FilterValue | null>>,
    workflowSorters: {
      [HyprFlowTabType.Workflows]: {} as SorterResult<DataType>,
      [HyprFlowTabType.WorkflowTemplates]: {} as SorterResult<DataType>
    } as Record<HyprFlowTabType, SorterResult<DataType> | SorterResult<DataType>[]>,
    workflowSearchTextMap: {
      [HyprFlowTabType.Workflows]: new Map<string, string>(),
      [HyprFlowTabType.WorkflowTemplates]: new Map<string, string>()
    } as Record<HyprFlowTabType, Map<string, string>>,
    activeTab: HyprFlowTabType.Workflows,
    
    resetWorkflowState: () => {
      set((state) => ({
        activeNodeId: "",
        activeNodeCardId: "",
        activeDragItem: null,
        activeDropItem: null,
        activeCopyId: null,
        selectedWorkflow: {} as Workflow,
        selectedWorkflowRun: {status: WorkflowRunStatusCode.NotRunning} as WorkflowRun,
        elements: [],
        renderFlowInitialized: false,
      }));
    },

    changeSidebarCollapse: () => {
      set((state) => ({
        ...state,
        stepsSidebarCollapsed: !get().stepsSidebarCollapsed,
      }));
    },

    getWorkflows: async (pageNumber: number, pageSize: number) => {
      try {
        const filters = get().workflowFilters[HyprFlowTabType.Workflows];
        const sorters = get().workflowSorters[HyprFlowTabType.Workflows];
        const searches = get().workflowSearchTextMap[HyprFlowTabType.Workflows];

        const wfFilters = convertToApiFilters(filters, searches);
        const wfSorters = convertToApiSorters(sorters);
        //Default sort field
        if (wfSorters.length == 0) {
          wfSorters.push("-updatedAt");
        }

        const [workflows, total] = await getWorkflowsApi(true, false, pageNumber, pageSize, undefined, wfFilters, wfSorters);
        const workflowPage = get().workflowPage;
        workflowPage[HyprFlowTabType.Workflows] =  {number: pageNumber, size: pageSize, total: total};
        set((state) => ({
          ...state,
          workflows: workflows,
          workflowPage: {...workflowPage},
          activeTab: total == 0 && wfFilters.length == 0 ? HyprFlowTabType.WorkflowTemplates : HyprFlowTabType.Workflows,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getWorkflowTemplates: async (pageNumber: number, pageSize: number) => {
      try {
        const filters = get().workflowFilters[HyprFlowTabType.WorkflowTemplates];
        const sorters = get().workflowSorters[HyprFlowTabType.WorkflowTemplates];
        const searches = get().workflowSearchTextMap[HyprFlowTabType.WorkflowTemplates];

        const wfFilters = convertToApiFilters(filters, searches);
        const wfSorters = convertToApiSorters(sorters);
        //Default sort field
        if (wfSorters.length == 0) {
          wfSorters.push("-updatedAt");
        }

        const [templates, total] = await getWorkflowsApi(false, true, pageNumber, pageSize, undefined, wfFilters, wfSorters);
        const workflowPage = get().workflowPage;
        workflowPage[HyprFlowTabType.WorkflowTemplates] =  {number: pageNumber, size: pageSize, total: total};
        set((state) => ({
          ...state,
          templates: templates,
          templatePage: {...workflowPage},
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
    
    getWorkflow: async (workflowId, syncAutoFill) => {
      try {
        const workflow = await getWorkflowApi(workflowId);
        await get().getSubworkflows(workflow);
        if(syncAutoFill) {
          const anyWorkflowChangeHappend  = await autoPopulateWorkflowStepsProviders(workflow, true, []);
          if(anyWorkflowChangeHappend) await updateWorkflowApi(workflow);
        }
        set((state) => ({
          ...state,
          selectedWorkflow: workflow,
          selectedWorkflowRun: {status: WorkflowRunStatusCode.NotRunning} as WorkflowRun,
        }));
        
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getSubworkflows: async (workflow: Workflow) => {
      try {
        if (workflow) {
          const subworkflowIds = [] as string[];
          Object.entries(workflow.steps).map(([id, step]) => {
            if ('operatorType' in step) { 
              if(step?.operatorType == OperatorType.Subworkflow && step?.parameters?.workflow_id) {
                subworkflowIds.push(step.parameters.workflow_id)
              }
            }
          });
          const subworkflows = [] as Workflow[];
          for(let i = 0; i < subworkflowIds.length; i++) {
            const subworkflow = await getWorkflowApi(subworkflowIds[i]);
            if (subworkflow) {
              subworkflows.push(subworkflow);
            }
          }
          set((state) => ({
            ...state,
            subworkflows: [...subworkflows],
          }));
        }
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearSelectedWorkflow: () => {
      set((state) => ({
        ...state,
        selectedWorkflow: {} as Workflow,
      }));
    },

    createWorkflow: async (workflowIn, isImport, tagsOnly) => {
      try {
        const workflowOut = await createWorkflowApi(workflowIn, isImport, tagsOnly);
        set((state) => ({
          ...state,
          selectedWorkflow: workflowOut,
        }));
        return workflowOut

      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    updateWorkflow: async (workflowIn) => {
      try {
        await updateWorkflowApi(workflowIn);
        const workflowOut = await getWorkflowApi(workflowIn.id);
        await get().getSubworkflows(workflowOut);
        set((state) => ({
          ...state,
          selectedWorkflow: workflowOut,
          selectedWorkflowRun: {status: WorkflowRunStatusCode.NotRunning} as WorkflowRun,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    deleteWorkflow: async (workflowId) => {
      try {
        await deleteWorkflowApi(workflowId);
        set((state) => ({
          ...state,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    executeWorkflow: async (workflowId: string, workflow: Workflow) => {
      try {
        const response = await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeExecute, workflow);
        const runID = Object.entries(response).find(([key, value]) => (key == "workflowRunID"))?.[1] as string;
        set((state) => {
          state.selectedWorkflowRun.id = runID;
          state.selectedWorkflowRun.status = WorkflowRunStatusCode.Running;
          state.selectedWorkflowRun.result = {trigger: {status: WorkflowRunStatusCode.Running} as WorkflowRunStepResult} as  WorkflowRunResult;
          
          return {
            ...state,
          }
        });
        return runID;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    publishWorkflow: async (workflowId: string,  workflow: Workflow) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypePublish, workflow);
        set((state) => {
          state.selectedWorkflow.state = SpecStateType.StatePublished;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    publishCancelWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypePublishCancel);
        set((state) => {
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    shareWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeShare);
        const workflowOut = await getWorkflowApi(workflowId)
        set((state) => {
          state.selectedWorkflow = workflowOut;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    unpublishWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeUnpublish);
        set((state) => {
          state.selectedWorkflow.state = SpecStateType.StateDraft;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    editWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeEdit);
        set((state) => {
          state.selectedWorkflow.state = SpecStateType.StatePublishedDraft;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
    
    revertWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeRevert); 
        set((state) => {
          state.selectedWorkflow.state = SpecStateType.StatePublished;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    enableWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeEnable);
        set((state) => {
          state.selectedWorkflow.enabled = true;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    disableWorkflow: async (workflowId: string) => {
      try {
        await actionWorkflowApi(workflowId, WorkflowActionType.WorkflowActionTypeDisable);
        set((state) => {
          state.selectedWorkflow.enabled = false;
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getWorkflowRunStatus: async (workflowRunId: string) => {
      try {
        const workflowRun = await getWorkflowRunStatusApi(workflowRunId);
        set((state) => {
          const wr = get().selectedWorkflowRun;
          return {
            ...state,
            selectedWorkflowRun: {
              ...wr, 
              status: workflowRun.status, 
              result: workflowRun.result,
              approvals: workflowRun.approvals,
              edgeID: workflowRun.edgeID
            },
          }
        });
        return workflowRun.status;
      } catch (error: any) {
        console.error(error);
        //Return existing workflowRun object on failure of get workflowRun from backend
        set((state) => {
          return {
            ...state,
            selectedWorkflowRun: get().selectedWorkflowRun,
          }
        });
        return get().selectedWorkflowRun.status;
      }
    },
    
    getWorkflowRun: async (workflowRunId: string) => {
      try {
        const workflowRun = await getWorkflowRunApi(workflowRunId);
        set((state) => {
          return {
            ...state,
            selectedWorkflowRun: workflowRun,
            selectedWorkflow: workflowRun.workflows,
          }
        });
      } catch (error: any) {
        console.error(error);
        //Return existing workflowRun object on failure of get workflowRun from backend
        set((state) => {
          return {
            ...state,
            selectedWorkflowRun: get().selectedWorkflowRun,
          }
        });
      }
    },

    stopWorkflowRun: async (workflowRunId: string) => {
      try {
        await cancelWorkflowRunApi(workflowRunId);
        set((state) => {
          return {
            ...state,
            selectedWorkflowRun: {status: WorkflowRunStatusCode.NotRunning} as WorkflowRun,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setActiveNodeId: (id) => {
      set((state) => ({
        ...state,
        activeNodeId: id,
      }));
    },

    setElements: (elements) => {
      set((state) => ({
        ...state,
        elements: elements,
      }));
    },

    setActiveNodeCardId: (id) => {
      set((state) => ({
        ...state,
        activeNodeCardId: id,
        activeNodeId: (id != get().activeNodeId) ? "": get().activeNodeId,
      }));
    },

    setActiveDragItem: (item) => {
      set((state) => ({
        ...state,
        activeDragItem: item,
      }));
    },

    setActiveDropItem: (item) => {
      set((state) => ({
        ...state,
        activeDropItem: item,
      }));
    },

    setActiveCopyId: (id) => {
      set((state) => ({
        ...state,
        activeCopyId: id,
      }));
    },

    setRenderFlowInitialized: () => {
      set((state) => ({
        ...state,
        renderFlowInitialized: true,
      }));
    },

    setActiveTab: (tabType: HyprFlowTabType) => {
      set((state) => ({
        ...state,
        activeTab: tabType,
      }));
    },

      
    setCurrentPage: (tabType: HyprFlowTabType, pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const pageInfo = state.workflowPage[tabType];
        const newPage: PageInfo = {...pageInfo, number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: pageInfo?.total as number};
        return { ...state, workflowPage : {...state.workflowPage, [tabType]: newPage}};
      });
      return;
    },

    setWorkflowFilters: (tabType: HyprFlowTabType, filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, workflowFilters: {...state.workflowFilters, [tabType]: filters}};
      });
      return;
    },

    setWorkflowSorters: (tabType: HyprFlowTabType, sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, workflowSorters: {...state.workflowSorters, [tabType]: sorters}};
      });
      return;
    },

    setWorkflowSearchTextMap: (tabType: HyprFlowTabType, searchTextMap: Map<string, string>) => {
      set( (state) => {
        return { ...state, workflowSearchTextMap: {...state.workflowSearchTextMap, [tabType]: searchTextMap}};
      });
      return;
    },

  }),
  { name: workflowStoreName })
));

export const useWorkflowGlobalStore = create<WorkflowGlobalStore>()(
  devtools((set, get) => ({
    workflows: [] as Workflow[],

    getWorkflows: async () => {
      try {
        const [workflows, total] = await getWorkflowsApi();
        set((state) => ({
          ...state,
          workflows: [...workflows],
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },
  }),
  { name: workflowGlobalStoreName })
);