import React, { useEffect, useReducer } from "react";
import { ResourceType, SpecStateType, SpecType } from "types";
import { theme } from "antd";
import { getSpecTypeFromResourceType, validateSpec } from "utility/developer";
import { EditorContext } from "components/EntityEditor";
import PageWrapper from "components/PageWrapper";
import { useDeveloperStore } from "store";
import { viewFactory } from "./Views";
import { HeaderBar } from "./HeaderBar";
import { useNavigate } from "react-router-dom";
import { PageContext } from "components/EntityEditor";
import { useFieldValidationStore } from "store";

import styles from "./DevEditor.module.scss";
import { ValidationType } from "utility/developer/validate";

const topLevelViewsType = [
  ResourceType.Action,
  ResourceType.Trigger,
  ResourceType.Artifact,
  ResourceType.App,
  ResourceType.ActionProvider,
  ResourceType.TriggerProvider,
  ResourceType.ArtifactProvider,
];
/**
 * Depending on the item type, save will call the appropriate backend apis
 */
export const DevEditor = () => {
  /** Use to navigate to developer page once the editor is finished */
  const navigate = useNavigate();
  const activeItem = useDeveloperStore((state) => state.activeItem);
  const setActiveItem = useDeveloperStore((state) => state.setActiveItem);
  const activeItemRef = React.useRef(activeItem);
  const saveNeededRef = React.useRef(false);
  const specCreatedRef = React.useRef(
    validateSpec(activeItem.type, activeItem.item, ValidationType.Create)
  );
  const [specType] = React.useState<SpecType>(
    getSpecTypeFromResourceType(activeItem.type)
  );
  const [viewMode, setViewMode] = React.useState(
    activeItem.item?.state == SpecStateType.StatePublished ? true : false
  );
  const doSpecUpdate = useDeveloperStore((state) => state.doSpecUpdate);
  const doSpecCreate = useDeveloperStore((state) => state.doSpecCreate);

  const clearFieldErrors = useFieldValidationStore((state) => state.clear);

  useEffect(() => {
    clearFieldErrors();
  }, [activeItem]);

  // Disable eslint warning for empty function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onCancel = async () => {};

  const onBack = async () => {
    navigate(`/developer`);
  };

  const onViewModeChange = (viewMode: boolean) => {
    setViewMode(viewMode);
  };

  const onChange = async (item: { [k: string]: any }) => {
    setActiveItem({ ...activeItem, item: { ...activeItem.item, ...item } });
    saveNeededRef.current = true;
    activeItemRef.current = {
      ...activeItem,
      item: { ...activeItem.item, ...item },
    };
    !specCreatedRef.current && updateSpec();
  };

  const updateSpec = async () => {
    const needUpdate = saveNeededRef.current;
    const di = activeItemRef.current;
    const validationType = specCreatedRef.current
      ? ValidationType.Update
      : ValidationType.Create;
    if (
      !needUpdate ||
      !topLevelViewsType.includes(di.type) ||
      !validateSpec(di.type, di.item, validationType)
    ) {
      return;
    }

    let obj: any = null;
    try {
      let naviagteToObject = false;
      if (!specCreatedRef.current) {
        obj = await doSpecCreate(specType, di.item);
        specCreatedRef.current = true;
        naviagteToObject = true;
      } else {
        obj = await doSpecUpdate(specType, di.item);
      }
      setActiveItem({ ...di, id: obj.id, item: obj });
      activeItemRef.current = { ...di, id: obj.id, item: obj };
      saveNeededRef.current = false;
      if (naviagteToObject) navigate(`/developer/${di.type}/${obj.id}`);
    } catch (err) {
      console.log("errror occured in backend operation", err);
    }
  };

  useEffect(() => {
    const pollInterval = 15 * 1000;
    const intervalId = setInterval(updateSpec, pollInterval);
    return () => clearInterval(intervalId);
  }, []);

  const { token } = theme.useToken();
  return (
    <PageContext.Provider value={{}}>
      <EditorContext.Provider
        value={{
          onChange: onChange,
          onCancel: onCancel,
          view: viewMode,
          viewFactory: viewFactory,
        }}
      >
        <PageWrapper
          content={
            <div style={{ width: "100%" }}>
              <div>
                <HeaderBar
                  specType={specType}
                  di={activeItem}
                  onBack={onBack}
                  onViewModeChange={onViewModeChange}
                />
              </div>
              {activeItem.type != ResourceType.Unknown && (
                <div style={{ margin: "auto", display: "flex" }}>
                  <div style={{ width: "20%" }} />
                  <div style={{ width: "48%", maxWidth: "70%" }}>
                    <div className={styles.specView}>
                      {viewFactory(activeItem, [])}
                    </div>
                  </div>
                  <div style={{ width: "30%" }} />
                </div>
              )}
            </div>
          }
        ></PageWrapper>
      </EditorContext.Provider>
    </PageContext.Provider>
  );
};
