import { Space, Empty, Button } from "antd";
export interface NoDataWithCreateProps {
  onCreate: () => void;
  text: string;
}

export const NoDataWithCreate = (props: NoDataWithCreateProps) => {
  return (
    <Space direction="vertical" align="center">
      <Empty description={props.text} />
      <Button type="primary" onClick={props.onCreate}>
        Create
      </Button>
    </Space>
  );
};
