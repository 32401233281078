import { useEffect, useState, FC } from "react";
import {
  Skeleton,
  Space,
  theme,
  Typography,
  Drawer as AntDrawer,
  Empty
} from "antd";

import { CloseOutlined } from "@ant-design/icons";

import { workflowIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import SearchInput from "components/SearchInput";
import { SearchRunAction } from "types";
import { useSearchStore } from "store";
import ActionBanner from "./actionBanner";


const { Text } = Typography;

export interface ActionsHistory {
  searchRunId: string;
  title?: string;
  searchTitle?: string;
  widthPercentage?: string;
  maxItems?: number;
  open: boolean;
  onClose: () => void;
}

const ActionsHistory: FC<ActionsHistory> = ({
  searchRunId,
  title,
  searchTitle,
  maxItems,
  open,
  onClose,
}) => {
  const { token } = theme.useToken();
  const [searchActionsFilter, setSearchActionsFilter] = useState("");
  const [selectedSearchRunActionId, setSelectedSearchRunActionId] = useState("");

  const {
    searchRunActions,
  } = useSearchStore((state) => ({
    searchRunActions: state.searchRunActions,
  }));

  useEffect ( () => {
    searchRunActions.length && setSelectedSearchRunActionId(searchRunActions[0].id);
  }, [searchRunActions])

  return (
    <AntDrawer
      closable={false}
      title={
        <Space direction="horizontal">
          <SvgIcon component={workflowIcons.actionShortIcon} />
          <Text style={{ color: token.colorTextLightSolid }}>
            {title? title : "Historical Actions"}
          </Text>
        </Space>
      }
      headerStyle={{
        backgroundColor: token.colorPrimary,
        textAlign: "left",
        zIndex: 100,
      }}
      open={open}
      className={"drawer"}
      extra={
        <CloseOutlined style={{ color: token.colorTextLightSolid }}  onClick={onClose} />
      }
    >
      {searchRunActions.length ? ( 
        <>
          <div style={{ width: "75%", padding: token.paddingXXS, marginLeft: token.marginXXL, marginBottom: token.margin }}>
            <SearchInput
              placeholder={searchTitle ? searchTitle : "search historical actions"}
              onFilter={(e) => setSearchActionsFilter(e.target.value)}
            />
          </div>
          {searchRunActions
            .sort((a, b) => {
              return b.updatedAt.localeCompare(a.updatedAt);
            })
            ?.filter((x: SearchRunAction) => x.actionInfo.displayName.toLowerCase().indexOf(searchActionsFilter.toLowerCase()) !== -1)
            .map((searchRunAction) => (
              <div key={searchRunAction.id} style={{width: "90%", padding: token.paddingXXS, marginLeft: token.margin}}>
                <ActionBanner 
                  searchRunActionId={searchRunAction.id} 
                  backgroundColor={(selectedSearchRunActionId== searchRunAction.id) ? token.colorPrimaryBg : token.colorBgBase}
                  hoverableColor={(selectedSearchRunActionId == searchRunAction.id) ? token.colorPrimaryBgHover : token.colorBgTextHover}
                  onClick={() => {
                    setSelectedSearchRunActionId(searchRunAction.id);
                  }}
                />
              </div>
            ))}
        </>
      ): (
        <Empty />
      )}
    </AntDrawer>
  )
}

export default ActionsHistory;