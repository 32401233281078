
//workflow node 
export const triggerBandColor = "#6B0BEA";
export const actionBandColor = "#D21D71";
export const operatorBandColor = "#665DFD";

// export const triggerHoverColor = "#CFD7F2";
// export const actionHoverColor = "#EECADB";
// export const operatorHoverColor = "#DEDBF2";

// export const triggerSelectedColor = "#B7C2E8";
// export const actionSelectedColor = "#EAB4CD";
// export const operatorSelectedColor = "#CFC9EF";
