import { theme } from "antd";
import { useScrollBarStyles } from "./constants";
type GlobalStyleProps = {
  children?: React.ReactNode;
};

export const GlobalStyles: React.FC<GlobalStyleProps> = ({ children }) => {
  const { token } = theme.useToken();
  const classes = useScrollBarStyles(token);
  return <div className={classes.scrollBar}>{children}</div>;
};
