import {
  Button,
  Spin,
  Typography,
  theme,
} from "antd";
import { ReactFlowProvider } from "reactflow";

import { Editor } from "components/HyprFlows";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkflowStore } from "store";

import PageWrapper from "../../components/PageWrapper";

import { getStyles } from "../../utility/styles";
import Controls from "./EditorControls/controls";
import { notification } from 'utility/notification';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { autoPopulateWorkflowStepsProviders } from "utility/workflow";
import { HyprFlowTabType } from "types";

const { Text } = Typography;

export const WorkflowTemplate: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS },
  })();

  const {
    selectedWorkflow,
    getWorkflow,
    updateWorkflow,
    createWorkflow,
    workflowPage,
    getWorkflows, 
  } = useWorkflowStore((state) => ({
    selectedWorkflow: state.selectedWorkflow,
    getWorkflow: state.getWorkflow,
    updateWorkflow: state.updateWorkflow,
    createWorkflow: state.createWorkflow,
    workflowPage: state.workflowPage[HyprFlowTabType.Workflows],
    getWorkflows: state.getWorkflows, 
  }));

  const syncWorkflowTemplate = async () => {
    try {
      setLoader(true);
      await getWorkflow(workflowId, false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (workflowId) {
      syncWorkflowTemplate();
    }
  }, []);


  const OnUseTemplate = async () => {
    try {
      setLoader(true);
      const newWorkflow = await createWorkflow(selectedWorkflow, true);
      autoPopulateWorkflowStepsProviders(newWorkflow, true, []).then( (anyWorkflowChangeHappend) => {
        updateWorkflow(newWorkflow).then ( () => {
          getWorkflows(workflowPage.number, workflowPage.size);
        })
      })
      notification.success({
        message: `Workflow template ${selectedWorkflow.name} imported successfully`,
        duration: 6,
      });
      await getWorkflows(workflowPage.number, workflowPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while using template...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  return (
    <Spin spinning={loader}>
      <PageWrapper
        content={
          /** Use an explicit relative position so that the header can be positioned absolute , and correctly  */
          <DndProvider  backend={HTML5Backend} options={{enableMouseEvents: true}}>
            <ReactFlowProvider>    
              <div id="workflow-container" className={classes.container}> 
                <div
                  id="workflow-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: token.paddingXS,
                    backgroundColor: token.colorBorderSecondary,
                    alignItems: "center",
                  }}
                >      
                  <Controls editMode={false}></Controls>
                  <Text style={{fontSize: token.fontSizeHeading5}} strong>{selectedWorkflow.name}</Text>
                  <Button
                    type="primary"
                    onClick={() => OnUseTemplate()}
                  >
                    Use This Template
                  </Button>
                </div>
                <div
                  id="workflow-editor"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <Editor
                    editMode={false}
                    templateMode={true}
                  />
                </div>
              </div>
            </ReactFlowProvider>
          </DndProvider>
        }
      ></PageWrapper>
    </Spin>
  );
};
