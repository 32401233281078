import React, { PropsWithChildren, useEffect } from "react";
import { Card, Typography, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";

import { translateEnum } from "utility/developer";
import { EditorProps } from "./common";
import { EditorContext } from "./editorContext";
import { useDeveloperStore, useFieldValidationStore } from "store";

//import styles from "./EntityEditor.Module.scss";
const useStyles = createUseStyles({
  renderCard: {
    width: "25%",
    minWidth: "400px",
    maxWidth: "400px",
    top: "130px",
    right: "1%",
    position: "fixed",
    border: ".5px solid #c1c1c1",
    borderRadius: "2px",
    zIndex: "100",
  },
  cardFooter: {
    background: "transparent",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
});

/** Function component taking props with child templatized with EntityCardProps */
export const EntityCard = (props: PropsWithChildren<EditorProps>) => {
  const checkForErrors = useFieldValidationStore(
    (state) => state.countLeafNodes
  );
  const errorTree = useFieldValidationStore((state) => state.tree);
  const activeCard = useDeveloperStore((state) => state.activeCard);
  const setActiveCard = useDeveloperStore((state) => state.setActiveCard);

  const [target, setTarget] = React.useState<{ [k: string]: any }>([]);
  const [saveEnabled, setSaveEnabled] = React.useState(false);

  const classes = useStyles();

  const onChange = async (item: { [k: string]: any }) => {
    setTarget(item);
  };

  useEffect(() => {
    setSaveEnabled(checkForErrors(props.path) == 0);
  }, [errorTree]);

  useEffect(() => {
    const thisCard = props.path.join("/");
    setActiveCard(thisCard);
    return () => {
      if (useDeveloperStore.getState().activeCard == thisCard) {
        setActiveCard(null);
      }
    };
  }, []);

  useEffect(() => {
    const path = props.path.join("/");
    // Cannot use the activeCard state variable as the value is from the previous render
    const activeCard = useDeveloperStore.getState().activeCard;
    if (activeCard == null) return;
    if (activeCard != path) {
      props.onCancel();
    }
  }, [activeCard]);

  return (
    <Card
      size="small"
      title={translateEnum(props.editable.type)}
      extra={<CloseOutlined onClick={() => props.onCancel()} />}
      className={classes.renderCard}
      headStyle={{
        backgroundColor: "#8377C6",
        textAlign: "left",
        color: "white",
        zIndex: 100,
      }}
      bodyStyle={{
        backgroundColor: "white",
        maxHeight: "calc(100vh - 179px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <EditorContext.Provider
        value={{
          view: props.editable.context?.readonly ? true : props.view,
          onChange: onChange,
          onCancel: props.onCancel,
          viewFactory: props.viewFactory,
        }}
      >
        {props.children}
        {!props.view && (
          <div className={classes.cardFooter}>
            <Button
              type="primary"
              disabled={!saveEnabled}
              htmlType="submit"
              onClick={() => {
                props.onSave(target);
                setSaveEnabled(false);
              }}
            >
              Save
            </Button>
          </div>
        )}
      </EditorContext.Provider>
    </Card>
  );
};
