import { useEffect } from "react";
import { Typography } from "antd";
import { PropsWithChildren } from "react";
import { EditorProps } from "./common";
import { EditorContext } from "./editorContext";
const { Text } = Typography;

/** Function component taking props with child templatized with EntityCardProps */
export const EntityNoControls = (props: PropsWithChildren<EditorProps>) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  /** Since is no editor control in this editor, changes are directly passed upstream as a save */
  const onChange = async (item: { [k: string]: any }) => {
    props.onSave(item);
  };

  return (
    <EditorContext.Provider
      value={{
        view: props.view,
        onChange: onChange,
        onCancel: props.onCancel,
        viewFactory: props.viewFactory,
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};
