import { useEffect } from "react";
import { Collapse, Typography } from "antd";
import { PropsWithChildren } from "react";
import { DeveloperItem } from "types";
import { FieldProps, ViewerType } from "./common";
import { FieldLabel } from "./fieldLabel";
import { ViewCreator } from "./viewCreator";

const { Text } = Typography;
const { Panel } = Collapse;

export interface ObjectFieldItemProps {
  key: string;
  displayName: string;
  value: DeveloperItem;
  render: (item: DeveloperItem) => JSX.Element;
}

export interface ObjectFieldProps extends FieldProps {
  item: DeveloperItem;
  showExpanded: boolean;
  onChange(key: string, value: any): Promise<void>;
}

/** react function component */
export const ObjectField = (props: PropsWithChildren<ObjectFieldProps>) => {
  useEffect(() => {
    // console.log(`path : ${props.path}`);
  }, []);

  const onSave = async (value: any) => {
    await props.onChange(props.identifier, value);
  };

  return (
    <Collapse
      ghost={true}
      defaultActiveKey={props.showExpanded ? props.identifier : undefined}
    >
      <Panel header={<FieldLabel fp={props} />} key={props.identifier}>
        <ViewCreator
          item={props.item}
          name={props.label}
          onSave={onSave}
          onCancel={async () => {
            return;
          }}
          onDelete={async () => {
            return;
          }}
          path={props.path}
          viewerType={ViewerType.NoControlsViewer}
        />
      </Panel>
    </Collapse>
  );
};
