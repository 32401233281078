import { theme, Typography, Space, Empty } from "antd";
import React, { CSSProperties, useCallback, useState } from "react";
import { Tooltip as AntdToolTip } from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DistributionData, BigScreenQuery } from "types";
import {
  getContainerStyle,
  getHeadingContainerStyle,
  getHeadingTopLineStyle,
} from "./KpiStyles";
import { useMediaQuery } from "react-responsive";
import { useSettingsStore } from "store";
import { convertToReadbableUnit } from "utility/metrics";

const { Text } = Typography;

/** Create a props interface with title string  and data field as DistributionData */
export interface KpiDistributionProps {
  data: DistributionData;
  colors: string[];
}

export const KpiDistribution = (props: KpiDistributionProps) => {
  const { token } = theme.useToken();
  const isBigScreen = useMediaQuery(BigScreenQuery);
  const [activeIndex, setActiveIndex] = useState(0);
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const containerStyle = getContainerStyle({
    background: token.colorBgContainer,
    minWidth: "200px",
    height: "310px",
    marginLeft: "5px",
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
  });
  const headingtextStyle: CSSProperties = {
    fontSize: token.fontSizeSM,
    marginLeft: token.marginXXS,
    marginTop: token.marginXXS,
    opacity: 0.7,
  };
  const headingContainerStyle: CSSProperties = getHeadingContainerStyle();
  const topLine: CSSProperties = getHeadingTopLineStyle();
  const onBarEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const CustomTooltip = (activeIndex: number) => {
    return (
      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          textAlign: "center",
          borderRadius: token.borderRadius,
        }}
      >
        <AntdToolTip placement="top">
          <Space size={1} direction="vertical">
            <Text
              style={{
                marginLeft: token.marginXS,
                marginRight: token.marginXS,
              }}
            >
              {props.data.primary.values[activeIndex]?.x}
            </Text>
            {props.data.primary.values[activeIndex]?.y && (
              <Text
                strong
                style={{
                  color: mode ? "#282876" : token.colorTextLightSolid,
                }}
              >
                {convertToReadbableUnit(
                  props.data.primary.unit,
                  props.data.primary.values[activeIndex].y
                )}
              </Text>
            )}
          </Space>
        </AntdToolTip>
      </div>
    );
  };

  const CustomizedLabel = (renderProps: any) => {
    const { x, y, fill, value } = renderProps;

    return (
      <text x={x + 10} y={y - 2} fill={fill} textAnchor="middle">
        {value}
      </text>
    );
  };

  return (
    <div id="kpi-distribution-container" style={containerStyle}>
      <div id="kpi-distribution-heading-container" style={topLine}>
        <div style={headingContainerStyle}>
          <Text strong ellipsis style={headingtextStyle}>
            {props.data.primary.text}
          </Text>
        </div>
      </div>
      {props.data.primary.values.length != 0 && (
        <ResponsiveContainer width={"99%"}>
          <BarChart
            data={props.data.primary.values.map((x) => {
              return {
                [props.data.primaryAxis]: x.x,
                [props.data.secondaryAxis]: x.y,
              };
            })}
            width={200}
            height={200}
            margin={{
              top: token.margin,
              bottom: token.margin,
              right: token.margin,
            }}
          >
            <Tooltip content={CustomTooltip(activeIndex)} cursor={false} />
            <XAxis dataKey={props.data.primaryAxis} hide tick={false} />
            <YAxis hide />
            <defs>
              {props.colors.map((color, index) => (
                <linearGradient
                  key={`lg-${index}`}
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="100%"
                  gradientUnits="userSpaceOnUse"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor={color} stopOpacity={0.9} />
                  <stop offset="40%" stopColor={color} stopOpacity={0.75} />
                  <stop offset="55%" stopColor={color} stopOpacity={0.75} />
                  <stop offset="70%" stopColor={color} stopOpacity={0.65} />
                  <stop offset="80%" stopColor={color} stopOpacity={0.55} />
                  <stop offset="90%" stopColor={color} stopOpacity={0.15} />
                  <stop offset="95%" stopColor={color} stopOpacity={0.02} />
                  <stop offset="100%" stopColor={color} stopOpacity={0.0} />
                </linearGradient>
              ))}
            </defs>
            <Bar
              dataKey={props.data.secondaryAxis}
              stackId={"a"}
              fill={token.colorPrimaryActive}
              barSize={isBigScreen ? 20 : 10}
              //label={isBigScreen ? CustomizedLabel : undefined}
              radius={isBigScreen ? 20 : 10}
              onMouseEnter={onBarEnter}
              isAnimationActive={false}
            >
              {props.data.primary.values.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
      {props.data.primary.values.length == 0 && (
        <Empty style={{ margin: "20%" }} />
      )}
    </div>
  );
};
