import { FC, useState } from "react";
import { Collapse, theme } from "antd";

import { CollapsePanelProps } from "types";

const { Panel } = Collapse;

const CollapsePanel: FC<CollapsePanelProps> = (props) => {
  const { token } = theme.useToken();
  const [collapsePanel, setCollapsePanel] = useState<boolean>(
    props.collapsePanel == undefined ? true : props.collapsePanel
  );

  const onCollapseChange = () => {
    setCollapsePanel(!collapsePanel);
  };

  return (
    <Collapse
      defaultActiveKey={!collapsePanel ? ["1"] : []}
      bordered={true}
      style={{ background: token.colorPrimaryBg, ...props.style }}
      onChange={onCollapseChange}
      ghost={props.ghost}
      size={"small"}
    >
      <Panel
        header={props.name}
        key="1"
        forceRender
        extra={props.extraElement ? props.extraElement : undefined}
      >
        {props.children}
      </Panel>
    </Collapse>
  );
};

export default CollapsePanel;
