import { CopyOutlined } from "@ant-design/icons";
import {
  Button,
  Popover,
  Space,
  Table,
  Tooltip,
  Typography,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { PermissionSetDto, ResourceType } from "types";

import { useRbacStore } from "store";

import { notification } from "utility/notification";
import { getStyles } from "../../utility/styles";
import { MutationData } from "./MutationData";
import { PermissionSetEdit } from "./PermissionSetEdit";
const { Text } = Typography;

export interface PermissionSetTabItemProps {
  readOnly: boolean;
}
export const PermissionSetTabItem = (props: PermissionSetTabItemProps) => {
  const permissionSets = useRbacStore((state) => state.permissionSets);
  const [mutationData, setMutationData] = useState<MutationData[]>([]);
  const [editItem, setEditItem] = useState<MutationData | null>(null);
  const pushMutationData = (data: MutationData) => {
    setMutationData([...mutationData, data]);
  };

  const popMutationData = () => {
    const newMutationData = [...mutationData];
    newMutationData.pop();
    setMutationData(newMutationData);
  };

  const deletePermissionSet = useRbacStore(
    (state) => state.deletePermissionSet
  );

  /** Set up a boolean loader in state  */
  const [, setLoading] = useState(false);

  const { token } = theme.useToken();

  const classes = getStyles({
    container: { gap: token.marginXS, padding: token.padding },
  })();

  useEffect(() => {
    if (mutationData.length) {
      const lastMutationData = mutationData[mutationData.length - 1];
      setEditItem(lastMutationData);
    } else {
      setEditItem(null);
    }
  }, [mutationData]);

  /** Do not delete any permission sets that are not marked userdefined */
  const onDelete = async (ids: string[]) => {
    try {
      setLoading(true);
      await ids.reduce(async (promise, id) => {
        await promise;
        return deletePermissionSet(id);
      }, Promise.resolve());

      notification.success({
        message: `Successfully deleted permission set(s)`,
        duration: 6,
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || `Error deleting users`,
        duration: 6,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClose = async () => {
    popMutationData();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any[]) => setSelectedRowKeys(selectedRowKeys),
    getCheckboxProps: (record: PermissionSetDto) => ({
      disabled: !record.userdefined,
    }),
  };

  const onCopyOrEdit = async (pset: PermissionSetDto, isCopy: boolean) => {
    const newPset = { ...pset };
    if (isCopy) {
      newPset.name = `${pset.name} (copy)`;
    }
    pushMutationData({
      isAdd: isCopy,
      permissionSet: newPset,
      type: ResourceType.PermissionSet,
    });
  };

  const onAdd = async () => {
    const rawPset: PermissionSetDto = {
      id: "",
      name: "",
      description: "",
      userdefined: true,
      constituents: [],
    };
    pushMutationData({
      isAdd: true,
      permissionSet: rawPset,
      type: ResourceType.PermissionSet,
    });
  };

  return (
    <div
      id="permissionsets-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <div
        id="permissionsets-header"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: token.margin,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontSize: token.fontSizeHeading4 }}>
            Total Permission Sets ({Object.keys(permissionSets).length})
          </Text>
          {props.readOnly && (
            <Text type="danger" style={{ fontSize: "0.75rem" }}>
              Create , edit and delete are disabled in the current context
            </Text>
          )}
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => onAdd()}
            disabled={props.readOnly}
          >
            Add
          </Button>
          <Button
            type="primary"
            onClick={() => onDelete(selectedRowKeys)}
            disabled={props.readOnly || !selectedRowKeys.length}
          >
            Delete
          </Button>
        </Space>
      </div>

      <div style={{ width: "100%" }}>
        <Table
          rowKey={"id"}
          key={`pset-table`}
          rowSelection={props.readOnly ? undefined : rowSelection}
          columns={[
            {
              title: "Name",
              render: (_: any, record: PermissionSetDto) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        <Popover content={record.description}>
                          {record.userdefined ? (
                            <Button
                              type="link"
                              onClick={() => onCopyOrEdit(record, false)}
                            >
                              {record.name}
                            </Button>
                          ) : (
                            <Text style={{ marginLeft: token.marginXS }}>
                              {record.name}
                            </Text>
                          )}
                        </Popover>
                      </div>
                    </div>
                    <Space>
                      <Tooltip title="Copy">
                        <CopyOutlined
                          onClick={() => onCopyOrEdit(record, true)}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                );
              },
            },
          ]}
          dataSource={Object.values(permissionSets).sort((a, b) =>
            a.userdefined && !b.userdefined ? -1 : 1
          )}
          bordered={true}
          pagination={
            Object.keys(permissionSets).length <= 10 ? false : { pageSize: 10 }
          }
        />
      </div>
      {editItem && editItem.type == ResourceType.PermissionSet && (
        <PermissionSetEdit
          readOnly={props.readOnly}
          key={"pset-edit"}
          isAdd={editItem.isAdd}
          pset={editItem.permissionSet}
          psets={Object.values(permissionSets).filter((x) => !x.userdefined)}
          onClose={onClose}
        />
      )}
    </div>
  );
};
