import { create } from "zustand"
import { devtools,persist } from "zustand/middleware";

import { 
  Trigger,
  TriggerType,
  TriggerProviders,
  ActionProviders,
  WorkflowStepProvidersStore,
  workflowStepProvidersStoreName,
} from "types";

import {
  getTriggerApi,
  getActionApi,
  getTriggerProvidersApi,
  getActionProvidersApi,
} from "api";
import { useWorkflowStepsStore } from "./steps";

export const useWorkflowStepProvidersStore = create<WorkflowStepProvidersStore>()(
  devtools((set, get) => ({
    triggerProvidersMap: new Map<string, TriggerProviders>(),
    actionProvidersMap: new Map<string, ActionProviders>(),
    
    clearProviders: () => {
      set((state) => ({
        triggerProvidersMap: new Map<string, TriggerProviders>(),
        actionProvidersMap:new Map<string, ActionProviders>(),
      }));
    },
    getTriggerProviders: async (triggerId: string, triggerType: TriggerType) => {
      try {
        const triggerProviders: TriggerProviders = ({} as any) as TriggerProviders
        switch(triggerType) {
          case TriggerType.Schedule:
          case TriggerType.Manual:
            triggerProviders.trigger = await getTriggerApi(triggerId);
            break;
          case TriggerType.Custom: {
            const [providers, total] = await getTriggerProvidersApi(triggerId, null);
            triggerProviders.providers = providers;
            if(triggerProviders.providers.length) {
              triggerProviders.trigger = triggerProviders.providers[0].triggers;
            }
            break;
          }
        }
        
        set((state) => ({
          ...state,
          triggerProvidersMap: new Map<string, TriggerProviders>(state.triggerProvidersMap).set(triggerId, triggerProviders)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    getActionProviders: async (actionId: string) => {
      try {
        const actionProviders: ActionProviders = ({} as any) as ActionProviders;
        const [providers, total] = await getActionProvidersApi(actionId, null);
        actionProviders.providers = providers;
        if(actionProviders.providers.length) {
          actionProviders.action = actionProviders.providers[0].actions;
        }

        set((state) => ({
          ...state,
          actionProvidersMap: new Map<string, ActionProviders>(state.actionProvidersMap).set(actionId, actionProviders)
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

  }),
  { name: workflowStepProvidersStoreName })
);