import { DeveloperItem, Path } from "types";
import { FieldValidationRule } from "types";
export interface PathProps {
  path: Path;
}


/** Editors are containers that provide edit capabilities */
export interface EditorProps extends PathProps{
  editable: DeveloperItem;
  name: string| number;
  view: boolean;
  viewFactory: (item: DeveloperItem, path: Path) => JSX.Element;
  /** Editor notifies upstream on save complete by the view */
  onSave: (item: {[k:string]: any}) => Promise<void>;
  onCancel: () => Promise<void>;
  onDelete: (item:DeveloperItem)  => Promise<void>;
}

/** Fields are items in the  container that are able to be edited*/
export interface FieldProps extends PathProps{
  /** Whether the field is optional */
  optional?: boolean;
  /** Label for the field */
  label: string;
  /** The key / id of the field by which it is dereference by the immedaiate parent */
  identifier: string;
  /** optional array of validation rules  for this field*/
  validators?: FieldValidationRule[];
  /** Help text */
  help? : string;
  /** Whether the field should display validation errors */
  showError?: boolean;
}

/** Properties needed by all view items  */
export interface ViewProps extends PathProps{
  data: { [key: string]: any };
  context?: {[k:string|number]: any}; 
  /** View validator rule */
  validator?: FieldValidationRule;
}

export enum ViewerType {
  CardViewer = "CardViewer",
  PageViewer = "PageEditor",
  InlineViewer = "InlineViewer",
  NoControlsViewer = "NoControlsViewer",
}


/** Denotes an array or object item that is being edited */
export interface EditItem {
  index: number| string;
  item: DeveloperItem;
}

/** Denotes an array or object item that is being edited */
export interface StringEditItem {
  key: string;
  item: DeveloperItem;
}

export enum CollectionMutatorPosition {
  /** Add remove buttons on header band */
  Header = "header",
  /** Add remove buttons in between header and table  */
  InBetween = "inBetween",
  /** Add remove button at the bottom */
  Footer = "footer",
}
