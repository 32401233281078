import { Divider, Form, Input, Select, Spin, Tabs } from "antd";
import { Drawer } from "components/Drawer";
import { act, useEffect, useState } from "react";
import { useOrganizationStore, useRbacStore } from "store";
import { UpdateUser, UserDto, userRegions } from "types";
import { Assign, HasIdentity } from "components/Assign";
import { notification } from "utility/notification";
import { HyprEdgePhoneInput } from "components/HyprEdgePhoneInput";
const { TabPane } = Tabs;
export interface UserEditProps {
  readOnly: boolean;
  user?: UserDto;
  isAdd: boolean;
  onClose: () => Promise<void>;
  onRoleAdd: () => Promise<void>;
}

export const UserEdit = (props: UserEditProps) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [drawerState, setDrawerState] = useState(false);
  const [buttonText, setButtonText] = useState("Save");
  const [activeAssignmentTab, setActiveAssignmentTab] = useState("roles-tab");
  const updateUser = useRbacStore((state) => state.updateUser);
  const roles = useRbacStore((state) => state.roles);
  const tags = useOrganizationStore((state) => state.tags);
  const createUser = useRbacStore((state) => state.createUser);

  const [assignmentDataSource, setAssignmentDataSource] = useState<
    HasIdentity[]
  >([]);

  const [tagAssignmentDataSource, setTagAssignmentDataSource] = useState<
    HasIdentity[]
  >([]);

  const handleSubmit = async () => {
    if (activeAssignmentTab === "roles-tab") {
      setActiveAssignmentTab("tags-tab");
      return;
    }
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }

      if (props.isAdd) {
        if (selectedRows.length === 0) {
          hasValidationError = true;
          notification.error({
            message: "Please select at least one role",
            duration: 6,
          });
          return;
        }
        if (selectedTags.length == 0) {
          hasValidationError = true;
          notification.error({
            message: "Please select a tag",
            duration: 6,
          });
          return;
        }
        
        const { firstName, lastName, email, phone, region, title } = values;
        await createUser({
          firstName,
          lastName,
          email,
          phone,
          region,
          title,
          roles: selectedRows,
          heldTags: selectedTags,
        });
      } else {
        if (props.user) {
          const existantRoleIds = props.user?.roles?.map((x) => x.id);
          const existantTagIds = props.user?.heldTags?.map((x) => x.id);

          const added = selectedRows.filter((x) =>
            !props.user ? false : !existantRoleIds.some((y) => y === x)
          );
          const removed = existantRoleIds.filter(
            (x) => !selectedRows.some((y) => y === x)
          );

          const addedTags = selectedTags.filter(
            (x) => !existantTagIds.some((y) => y === x)
          );

          const removedTags = existantTagIds.filter(
            (x) => !selectedTags.some((y) => y === x)
          );

          const { firstName, lastName, region, title } = values;
          const updateData: UpdateUser = {
            id: props.user.id,
            firstName,
            lastName,
            region,
            title,
            addRoles: added,
            removeRoles: removed,
            addHeldTags: addedTags,
            removeHeldTags: removedTags,
          };
          await updateUser(updateData);
        }
      }
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } user, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  const formFields = [
    {
      name: "firstName",
      label: "First Name",
      rules: [{ required: true, message: "Please input first name!" }],
    },
    {
      name: "lastName",
      label: "Last Name",
      rules: [{ required: true, message: "Please input last name!" }],
    },
    {
      name: "title",
      label: "Title",
      rules: [{ required: true, message: "Please input title!" }],
    },
    {
      name: "email",
      label: "Email",
      rules: [{ required: props.isAdd, message: "Please input email!" }],
    },
    {
      name: "phone",
      label: "Phone",
      rules: [{ required: props.isAdd, message: "Please input phone!" }],
    },
    {
      name: "region",
      label: "Region",
      rules: [{ required: true, message: "Please input region!" }],
    },
  ];

  useEffect(() => {
    setButtonText(activeAssignmentTab === "roles-tab" ? "Next" : "Save");
  }, [activeAssignmentTab]);

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setDrawerState(true);
      try {
        setLoading(true);
        if (props.user) {
          const fields = {
            firstName: props.user.firstName,
            lastName: props.user.lastName,
            title: props.user.title,
            email: props.user.email,
            phone: props.user.phone,
            region: props.user.region,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
        const r = props.user?.roles?.map((x) => x.id) || [];
        setSelectedRows(r);

        const assignableRoles = Object.values(roles).map((x) => ({
          id: x.id,
          displayName: x.name,
          description: x.description,
          selected: r.includes(x.id),
          assignable: true,
        }));
        setAssignmentDataSource(assignableRoles);

        const t = props.user?.heldTags?.map((x) => x.id) || [];
        setSelectedTags(t);
        const assignableTags = Object.values(tags).map((x) => ({
          id: x.id,
          displayName: x.value,
          description: x.value,
          selected: t.includes(x.id),
          assignable: true,
        }));
        setTagAssignmentDataSource(assignableTags);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    asyncUseEffect();
  }, [props.user, roles, tags]);

  const renderSwitchForInputType = (fieldType: string): JSX.Element => {
    switch (fieldType) {
      case "phone":
        return (
          <HyprEdgePhoneInput
            country={"us"}
            enableSearch={true}
            onChange={(phone: string) => form.setFieldsValue({ phone })}
            disabled={!props.isAdd}
          />
        );
      case "region":
        return (
          <div>
            <Select
              onSelect={(value) => form.setFieldsValue({ region: value })}
              defaultValue={[props.user?.region]}
              disabled={props.readOnly}
            >
              {userRegions.map((region) => (
                <Select.Option key={region} value={region}>
                  {region}
                </Select.Option>
              ))}
            </Select>
          </div>
        );
      default:
        return (
          <Input
            disabled={(fieldType == "email" && !props.isAdd) || props.readOnly}
          />
        );
    }
  };

  const onRoleSelectionChange = async (
    selectedRowKeys: any[]
    // selectionRows: any[]
  ) => {
    setSelectedRows(selectedRowKeys);
  };

  const onTagSelectionChange = async (selectedRowKeys: any[]) => {
    setSelectedTags(selectedRowKeys);
  };

  const onClose = async () => {
    setSelectedRows([]);
    setDrawerState(false);
    await props.onClose();
  };

  return (
    <Drawer
      title="User"
      footerName={buttonText}
      onClose={onClose}
      open={drawerState}
      subtitle="Information and roles"
      onSubmit={props.readOnly ? undefined : handleSubmit}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          disabled={props.readOnly}
          labelCol={{ span: 6, offset: 0 }}
          wrapperCol={{ span: 18, offset: 0 }}
        >
          {formFields.map((field) => (
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              rules={field.rules}
            >
              {renderSwitchForInputType(field.name)}
            </Form.Item>
          ))}
        </Form>
        <Divider />
        <Tabs
          defaultActiveKey="roles-tab"
          onChange={setActiveAssignmentTab}
          activeKey={activeAssignmentTab}
        >
          <TabPane tab="Roles" key="roles-tab">
            <Assign
              dataSource={assignmentDataSource}
              name="Roles"
              onChange={onRoleSelectionChange}
              readonly={props.readOnly}
              showAdd={!props.readOnly}
              onAdd={props.onRoleAdd}
              multiple={true}
              type="roles"
            />
          </TabPane>
          <TabPane tab="Tags" key="tags-tab">
            <Assign
              dataSource={tagAssignmentDataSource}
              name="Tags"
              showAdd={!props.readOnly}
              onChange={onTagSelectionChange}
              readonly={props.readOnly}
              multiple={true}
              type="tags"
            />
          </TabPane>
        </Tabs>
      </Spin>
    </Drawer>
  );
};
