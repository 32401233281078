import { FC } from "react";
import {
  Form,
  Input,
} from "antd";

import Modal from "components/Modal";

const { TextArea } = Input;

import { SpecType } from "types";

export type PublishModalProps = {
  specType: SpecType
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  loader: boolean;
};

const PublishModal: FC<PublishModalProps> = ({
  specType,
  open,
  onClose,
  onSubmit,
  loader
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      loader={loader}
      title={ "Publish " + specType }
      open={open}
      footerName="Publish"
      onClose={onClose}
      onSubmit={() => 
        form
          .validateFields()
          .then(onSubmit)
          .catch((err) => {
            console.log(err);
          })
      }
      >
        <Form 
          form={form} 
          layout="vertical" 
          name="publishSpecForm" 
          autoComplete="off"
        >
          <Form.Item  name="comments" label="Comments" rules={[{ required: true, message: "Comments are required!" }]}>
            <TextArea placeholder="Enter comments" showCount maxLength={100}/>
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default PublishModal;
