import { Image, Typography } from "antd";
import { companyIcons, signupIcons } from "assets/icons";
import { FC, ReactNode } from "react";

import styles from "./SignInSignUpWrapper.module.scss";

const { Text, Title } = Typography;

const SignInSignUpWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.mainWrapper}>
      <div
        id="leftSide"
        style={{
          display: "flex",
          paddingLeft: "5%",
          backgroundColor: "#fff",
          width: "50%",
          clipPath: "ellipse(80% 100% at 10% 50%)",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Image
          preview={false}
          src={companyIcons.hyprEdgeLogoWebSvg}
          style={{ height: "40px", marginLeft: "35px", marginBottom: "20px" }}
        />
        {children}
      </div>

      <div className={styles.wrapRight}>
        <div className={styles.rightContent}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Title level={1} style={{ textAlign: "center" }}>
              Welcome to HyprEdge!
            </Title>
            <ul>
              <li>
                <Text style={{ color: "white", textAlign: "center" }}>
                  Learn from digital data from within your organization using
                  federated{" "}
                  <Text style={{ color: "white" }} strong>
                    Search
                  </Text>
                </Text>
              </li>
              <li>
                <Text style={{ color: "white" }}>
                  Adapt to new technologies by simplifying automation using
                  <Text strong style={{ color: "white" }}>
                    {" "}
                    HyprFlows
                  </Text>
                </Text>
              </li>
              <li>
                <Text style={{ color: "white" }}>
                  Expand your business by connecting applications and taking
                  decisive{" "}
                  <Text style={{ color: "white" }} strong>
                    Actions
                  </Text>
                </Text>
              </li>
            </ul>
            <div>
              <Image
                preview={false}
                src={signupIcons.signupRighSideIconPng}
                className={styles.imgDiv}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInSignUpWrapper;
