import { Handle, Position } from "reactflow";
import { memo, useEffect, useState } from "react";
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend'

import { DraggableItem, DraggableSourceType } from "types";
import { OperatorType } from "types";
import NodeBody from "./Body";

import { useNodeStyles } from "./styles";

const OperatorNode = (props: any) => {
  const commonStyles = useNodeStyles();
  const [mouseDown, setMouseDown] = useState(false);
  const item: DraggableItem = {
    id: props.id,
    nodeType: props.data.nodeType,
    resourceId: props.data.resourceId,
    resourceType: props.data.resourceType,
    source: DraggableSourceType.Editor,
  };
  const [isHover, setHover] = useState(false);

  const [collected, dragOperatorRef, dragOperatorPreview] = useDrag(() => ({
    type: DraggableSourceType.Editor,
    item: item,
    canDrag: () => {
      if(
        !props.data.editMode ||
        props.data.resourceType == OperatorType.Condition ||
        props.data.resourceType == OperatorType.Approval ||
        props.data.resourceType == OperatorType.Loop ||
        props.data.resourceType == OperatorType.Break ||
        props.data.resourceType == OperatorType.Parallel) {
          return false
      }
      return true
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: {
      dropEffect: 'move',
    },
  }));

  const MouseOver = () => {
    setHover(true);
  };

  const MouseOut = () => {
    setHover(false);
  };

  useEffect(() => {
    dragOperatorPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      key={props.id}
      className={`${commonStyles.node} nopan`}
      ref={dragOperatorRef}
      style={{
        cursor: "pointer",
        position: "relative",
      }}
      onMouseOver={MouseOver}
      onMouseOut={MouseOut}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      
    >
      <Handle
        className={commonStyles.nodeTopHandle}
        type="target"
        position={Position.Top}
      />
      <NodeBody {...props.data} />
      <Handle
        className={commonStyles.nodeBottomHandle}
        type="source"
        position={Position.Bottom}
        id="a"
      />
    </div>
  );
};
export default memo(OperatorNode);
