export interface Subscriber {
  name: string;
  description : string;
  tenantId: string;
  userDisplayName: string;
  userEmailAddress: string;
  nodePath: string;
  state: string;
}

export interface Provider {
  name: string;
  description : string;
  id: string;
}

export interface SubscriberUpdateRequest{
  subscriber: string;
  state?: SubscriptionState;
  name? : string,
  description? : string
}

export interface CreationalSubscriber { 
  name: string;
  description : string;
  primary : string;
  password : string;
  phone: string;
  region: string;
  firstName: string;
  lastName: string;
}

export enum SubscriptionState {
  uninitialized = "UNINITIALIZED",
  initiated = "INITIATED",  
  approved = "APPROVED",
  rejected = "REJECTED",
  cancelInitiatedBySubscriber = "CANCEL_INITIATED_BY_SUBSCRIBER",
  cancelInitiatedByProvider = "CANCEL_INITIATED_BY_PROVIDER",
}

export interface MspState { 
  external : boolean;
  active: boolean;
  msspId: string;
  enrolmentState : SubscriptionState;
}

export interface TypedResource {
  id: string;
  type: string;
}

export interface Tag{
  id : string;
  value: string;
  readonly: boolean;
  resources: TypedResource[];
}

export type TagCreateRequest = Omit<Tag, "id" | "readonly" | "resources"> & { resources: string[] };
export type TagUpdateRequest = Omit<Partial<TagCreateRequest>, "resources" > & { addResources?: string[], removeResources?: string[] };
export type TagList = { tags : Tag[]};


export interface MspStore {
  /** Public providers that can provide management capabilities */
  providers: { [key: string]: Provider };
  /** subscribers for the current provider */
  subscribers: { [key: string]: Subscriber };
  /** Whether msp capabilities are enabled  */
  treeDirection : string;

  tags: Tag[];

  mspEnabled : boolean;
  /** Whether msp capabilities are public, or restricted to tenant's organization */
  external: boolean;
  context: string | null;
  contextRequest: string | null;
  
  currentProviderId: string | null;
  subscriptionState: SubscriptionState | null;
  
  getTags: () => Promise<void>;
  createTag: (tag: TagCreateRequest) => Promise<void>;
  deleteTag: (tagId: string) => Promise<void>;
  updateTag: (id: string, tag: TagUpdateRequest) => Promise<void>;

  getSubscribers: () => Promise<void>;
  createSubscriber: (organization: CreationalSubscriber) => Promise<void>;
  approveSubscriber: (organizationId: string) => Promise<void>;
  rejectSubscriber: (organizationId: string) => Promise<void>;
  acknowledgeCancel: (organizationId: string) => Promise<void>;
  updateSubscriberFields: (data: SubscriberUpdateRequest) => Promise<void>;
  detachSubscriber: (organizationId: string) => Promise<void>;  
  setMspEnabled: (active: boolean, external: boolean ) => Promise<void>;
  getMspEnabled: () => Promise<void>;

  getSubscriptionState : () => Promise<void>;
  getProviders : () => Promise<void>;
  detachFromProvider : () => Promise<void>;
  attachToProvider : (providerId: string) => Promise<void>;
  acknowledgeProviderCancel : () => Promise<void>;

  activateContext: (tagId: string) => void;
  setContextRequest: (value: string) => void;
  clearContextRequest: () => void;

  setTreeDirection: (value: string) => void;
}