import { Button, Row } from "antd";
import { FC } from "react";

export interface DrawerFooterProps {
  enabled: boolean;
  footerName?: string;
  onClose: () => Promise<void>;
  handleSubmit: () => Promise<void>;
}

export const DrawerFooter: FC<DrawerFooterProps> = ({
  enabled,
  footerName,
  onClose,
  handleSubmit,
}) => {
  return (
    <div>
      <Row justify="space-between">
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!enabled} type="primary" onClick={handleSubmit}>
          {footerName ? footerName : "Save"}
        </Button>
      </Row>
    </div>
  );
};
