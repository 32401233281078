
import { EdgeSubscription  } from "types";

export enum EdgeActionType {
  EdgeActionTypeGenerateConfig = "generate-config",
}

export enum EdgeManageType {
  HyprEdge =  "hypredge_managed",
  Custom = "customer_managed"
} 

export const EdgeManageTypeMap: Map<EdgeManageType, string>  =  new Map<EdgeManageType, string> (
  [
    [EdgeManageType.HyprEdge, "HyprEdge"], 
    [EdgeManageType.Custom, "Me"],
  ]
)

export enum EdgeInfraType {
  Amazon = "aws",
  Gcp = "gcp",
  Azure = "azure",
  Oci = "oci",
  OnPrem = "on-prem"
}

export const EdgeInfraTypeMap: Map<EdgeInfraType, string>  =  new Map<EdgeInfraType, string> (
  [
    [EdgeInfraType.Amazon, "Amazon Web Services"], 
    [EdgeInfraType.Azure, "Microsoft Azure"], 
    [EdgeInfraType.Gcp, "Google Cloud Platfrom"], 
    [EdgeInfraType.Oci, "Oracle Cloud Infrastructure"],
    [EdgeInfraType.OnPrem, "On Premises"]
  ]
)

export const enum EdgeDeployStatus {
  Unknown = "unknown",
  Initiated = "initiated",
  InProgress = "in_progress",
  Failed = "failed",
  Canceled = "cancelled",
  TimedOut = "timed_out",
  Terminated = "terminated",
  Completed = "completed"
}

export const EdgeDeployStatusMap: Map<EdgeDeployStatus, string>  =  new Map<EdgeDeployStatus, string> (
  [
    [EdgeDeployStatus.Unknown, "Unknown"], 
    [EdgeDeployStatus.Initiated, "Ready For Deployment"], 
    [EdgeDeployStatus.InProgress, "In-Progress"], 
    [EdgeDeployStatus.Failed, "Failed"],
    [EdgeDeployStatus.Canceled, "Canceled"], 
    [EdgeDeployStatus.TimedOut, "Timed Out"], 
    [EdgeDeployStatus.Terminated, "Terminated"], 
    [EdgeDeployStatus.Completed, "Success"]
  ]
)

export const enum EdgeStatus {
  Online = "online",
  Offline = "offline",
  Installing = "installing",
  Updating = "updating",
  Uninstalling = "uninstalling"
}

export const EdgeStatusMap: Map<EdgeStatus, string>  =  new Map<EdgeStatus, string> (
  [
    [EdgeStatus.Online, "Online"], 
    [EdgeStatus.Offline, "Offline"], 
    [EdgeStatus.Installing, "Installing"], 
    [EdgeStatus.Updating, "Updating"],
    [EdgeStatus.Uninstalling, "Uninstalling"]
  ]
)

export const enum EdgeReachability {
  Direct  = "direct",
	Proxy  = "proxy"
}

export const enum EdgeDeployMode {
  Unknown  = "unknown",
  Local    = "local",
  Remote   = "remote"
}

export interface EdgeAWSConfiguration {
  accessKeyID: string;
  secretAccessKey: string;
  region: string;
  vpcCidr: string;
}

export interface Edge {
  id: string;
  name: string;
  description: string;
  displayName: string;
  default: boolean;
  manageType: EdgeManageType;
  infraType: EdgeInfraType;
  configuration: Record<string, string>;
  reachability: EdgeReachability;
  status: EdgeStatus;
  deployMode: EdgeDeployMode;
  deployStatus: EdgeDeployStatus;
  region: string;
  endpoint: string;
  edgeSubscriptions: EdgeSubscription[]
  tags: string[];
}

export type EdgeUploadObjectType = Omit<Edge, 'endpoint' | 'edgeSubscriptionId' | 'endpoint' | 'edgeSubscriptions' >;
