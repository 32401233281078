import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Space, Switch, Typography, theme } from "antd";
import { FC, useState } from "react";
import { useOrganizationStore } from "store";
import { notification } from "utility/notification";
import { getStyles } from "../../utility/styles";
import { SubscriberMode } from "./SubscriberMode";
const { Text, Title } = Typography;

export const MspSettings: FC = () => {
  const { token } = theme.useToken();
  const mspEnabled = useOrganizationStore((state) => state.mspEnabled);
  const external = useOrganizationStore((state) => state.external);
  const setMspEnabled = useOrganizationStore((state) => state.setMspEnabled);
  const [, setLoading] = useState(false);

  const classes = getStyles({
    container: {
      gap: token.marginXS,
      padding: token.padding,
      alignItems: "flex-start",
    },
  })();

  return (
    <div
      id="msp-settings-container"
      className={classes.container}
      style={{
        backgroundColor: token.colorBgContainer,
        marginBottom: token.margin,
        width: "100%",
      }}
    >
      <Title level={4} key={`title_msp`}>
        Managed Service Provider Settings
      </Title>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        <Space direction="horizontal">
          <Text>Enable Multi-Tenancy Mode</Text>
          <Popover content="Enables hierarchical organization commonly used in Manage Service Provider contexts">
            <InfoCircleOutlined />
          </Popover>
        </Space>
        <Switch
          checked={mspEnabled}
          onChange={async (checked) => {
            try {
              setLoading(true);
              await setMspEnabled(checked, external);
            } catch (err) {
              notification.error({
                message: (err as any)?.message || `Error setting MSP mode`,
                duration: 6,
              });
            } finally {
              setLoading(false);
            }
          }}
        ></Switch>
      </div>
      {mspEnabled && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          <Space direction="horizontal">
            <Text>Add to Global Provider List</Text>
            <Popover content="Make this Managed Services Provider visible and discoverable">
              <InfoCircleOutlined />
            </Popover>
          </Space>
          <Switch
            checked={external}
            onChange={(checked) => {
              setMspEnabled(mspEnabled, checked);
            }}
          ></Switch>
        </div>
      )}
      <SubscriberMode />
    </div>
  );
};
