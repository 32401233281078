import { FC, useState, useEffect } from "react";
import { Card,  Space, Typography, Upload, Image, theme } from "antd";
import { RcFile } from "antd/es/upload";

import { useDeveloperXStore, } from "store";

const { Text } = Typography;

export type AppLogosProps = {
  editMode: boolean
  onChange: () => void;
}
const AppLogos: FC<AppLogosProps> = ({
  editMode,
  onChange,
}) => {
  const { token } = theme.useToken();
  const {selectedApp,} = useDeveloperXStore((state) => ({selectedApp: state.selectedApp,}));
  const [fileList, setSelectedFileList]= useState([selectedApp.logoPath, selectedApp.darkLogoPath]);
  const [currentFileName, setCurrentFileName] = useState("");
  
  const beforeUpload = async (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      return false;
    }
    return file;
  };
  
  const handleUpload = async ({ onSuccess, onError, file }: any) => {
    const reader = new FileReader();
    reader.readAsText(file)
    reader.onloadend = (readerEvent: ProgressEvent<FileReader>) => {
      if (readerEvent?.target?.result) {
        if (currentFileName == selectedApp.logoPath) {
          selectedApp.logoBuffer = readerEvent?.target?.result.toString();
        } else {
          selectedApp.darkLogoBuffer = readerEvent?.target?.result.toString();
        }
        onChange();
      }
    };
  };

  useEffect(() => {
    setSelectedFileList([selectedApp.logoPath, selectedApp.darkLogoPath]);
  }, [selectedApp]);

  
  return (
    <Card
      style={{
        margin: token.marginXS,
        display: "flex",
        justifyContent: "center",
        backgroundColor: editMode ? "" : token.colorBorderSecondary,
      }}
    >
      <Space size={token.sizeXXL} direction="horizontal" >
        {fileList.map( (fileName) => (
          <div
            key={fileName}
            style={{
              background: fileName == selectedApp.logoPath
                ? token.colorFillSecondary
                : token.colorPrimaryBgHover,
              borderRadius: "5px",
            }}
          >
            <Upload
              accept=".svg"
              listType="picture"
              showUploadList={false}
              beforeUpload={ (file) => {
                const status =  beforeUpload(file)
                setCurrentFileName(fileName)
                return status;
              }}
              customRequest={(e) => handleUpload(e)}
              disabled={!editMode}
            >
              <div
                id="picture-container"
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "90%",
                  margin: token.marginXS,
                  height: "90%",
                }}
              >
                <Image
                  preview={false}
                  draggable={false}
                  width={"100%"}
                  src={fileName == selectedApp.logoPath? selectedApp.logoUrl : selectedApp.darkLogoUrl}
                  alt="company-logo"
                />
              </div>
            </Upload>
          </div>
        ))}
      </Space>
    </Card>
  );
};

export default AppLogos;
