import { FC, useEffect, useState } from "react";

import {
  Space,
  Table,
  Descriptions,
  message,
  Typography,
  theme,
  Modal as AntModal,
  Button,
  Spin,
  Empty,
} from "antd";

import { SvgIcon } from "components/SvgIcon";
import { ColumnsType } from "antd/es/table";

import { useAppSubscriptionStore } from "store";

import { WebhookConfigProps } from "types";

import { commonIcons } from "assets/icons";
const { Text, Title } = Typography;

export const HttpRequestTypeMap: Map<string, string> = new Map<string, string>([
  ["post", "POST"],
  ["get", "GET"],
]);

export const HttpContentTypeMap: Map<string, string> = new Map<string, string>([
  ["json", "application/json"],
  ["xml", "application/xml"],
]);

const WebhhokConfig: FC<WebhookConfigProps> = ({
  appSubscriptionId,
  open,
  onClose,
  onRegenerate
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [loadAppSubscriptionFailed, setLoadAppSubscriptionFailed] =useState(false);
  const [isRegenerated, setIsRegenerated] = useState<boolean>(false);

  const { selectedAppSubscription, getAppSubscription } =
  useAppSubscriptionStore((state) => ({
    selectedAppSubscription: state.selectedAppSubscription,
    getAppSubscription: state.getAppSubscription,
  }));

  const loadAppSubscription = async (refresh?: boolean) => {
    try {
      setLoader(true);
      await getAppSubscription(appSubscriptionId, refresh);
      setLoadAppSubscriptionFailed(false);
    } catch (error) {
      console.log(error);
      setLoadAppSubscriptionFailed(true);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadAppSubscription();
  }, []);

  const columns: ColumnsType<Record<string, string>> = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <AntModal
      title={
        <Space>
          <SvgIcon size={15} component={commonIcons.webhookIcon} />
          <Text>Webhook Details</Text>
        </Space>
      }
      open={open}
      width={800}
      centered={true}
      keyboard={false}
      maskClosable={false}
      onCancel={async () => onClose(isRegenerated)}
      footer={[
        <Button 
          key="copy" 
          type="primary" 
          onClick={async () => {
            const type = "text/plain";
            const blob = new Blob([JSON.stringify(selectedAppSubscription?.webhookConfiguration, null, 2)], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            await navigator.clipboard.write(data);
            message.success("copied!");
          }}
        >
          Copy
        </Button>,
        <Button 
          key="regenerate" 
          type="primary" 
          onClick={async () => {
            setLoader(true);
            setIsRegenerated(true);
            selectedAppSubscription.webhookConfiguration = undefined;
            await onRegenerate(selectedAppSubscription);
            setLoader(false);
            await loadAppSubscription(true);
          }}
        >
          Regenerate
        </Button>,
      ]}
    >
      <Spin spinning={loader}>
        {loadAppSubscriptionFailed ? (
            <Empty
              imageStyle={{ height: 60 }}
              description={
                <Text>Error in loading configuration!</Text>
              }
            >
              <Button type="primary" onClick={() => loadAppSubscription()}>Retry!</Button>
            </Empty>
          ) :
          selectedAppSubscription?.id && selectedAppSubscription?.webhookConfiguration && (
            <Descriptions
              size="middle"
              layout="vertical"
              colon={false}
              column={1}
            >
              <Descriptions.Item label="URL">
                <Text copyable>{selectedAppSubscription?.webhookConfiguration?.url}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Secret">
                <Text copyable>{selectedAppSubscription?.webhookConfiguration?.secret}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Request Type">
                <Text copyable>{HttpRequestTypeMap.get(
                  selectedAppSubscription?.webhookConfiguration?.requestType as string
                )}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Request Content Type">
                <Text copyable>{HttpContentTypeMap.get(
                    selectedAppSubscription?.webhookConfiguration?.responseContentType as string
                  )}</Text>
              </Descriptions.Item>
              {selectedAppSubscription?.webhookConfiguration?.expectedHeaders
                ?.length && (
                <Descriptions.Item label="Expected Headers">
                  <Table
                    dataSource={[
                      selectedAppSubscription?.webhookConfiguration
                        ?.expectedHeaders,
                    ]}
                    columns={columns}
                    pagination={false}
                  />
                </Descriptions.Item>
              )}
              {selectedAppSubscription?.webhookConfiguration?.expectedQueryParams
                ?.length && (
                <Descriptions.Item label="Expected Query Params">
                  <Table
                    dataSource={[
                      selectedAppSubscription?.webhookConfiguration
                        ?.expectedQueryParams,
                    ]}
                    columns={columns}
                    pagination={false}
                  />
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Response Code">
                <Text copyable>{selectedAppSubscription?.webhookConfiguration?.responseCode}</Text>
              </Descriptions.Item>
              <Descriptions.Item label="Response Content Type">
                <Text copyable>{HttpContentTypeMap.get(
                    selectedAppSubscription?.webhookConfiguration?.responseContentType as string
                  )}</Text>
              </Descriptions.Item>
              {selectedAppSubscription?.webhookConfiguration?.responseHeaders
                ?.length && (
                <Descriptions.Item label="Response Headers">
                  <Table
                    dataSource={[
                      selectedAppSubscription?.webhookConfiguration
                        ?.responseHeaders,
                    ]}
                    columns={columns}
                    pagination={false}
                  />
                </Descriptions.Item>
              )}
              {selectedAppSubscription?.webhookConfiguration?.responsePayload
                ?.length && (
                <Descriptions.Item label="Response Payload">
                  {selectedAppSubscription?.webhookConfiguration?.responsePayload}
                </Descriptions.Item>
              )}
            </Descriptions>
          )
        }
      </Spin>
    </AntModal>
  );
};

export default WebhhokConfig;
