import { Avatar, Space, Spin, Typography, DatePicker, Button, theme, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { FilterFilled } from "@ant-design/icons";
import { useOrganizationStore, useUserInfoStore } from "store";

import { EntitiesDisplay } from "components/EntitiesDisplay";
import { Activity, ActivityGroup, ActivityGroupMap, dateTimeFormatOptions } from "types";
import { ColumnsType } from "antd/es/table";
import { HLink } from "components/Link";
import { useAuditLogStore } from "store";
import { TableProps } from "antd/lib";
import { DataType } from "@textea/json-viewer";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const AuditLogs: FC = () => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const context = useOrganizationStore((state) => state.context);
  const [localTimeFiltered, setLocalTimeFiltered] = useState(false);

  const { 
    auditLogs, 
    getAuditLogs,
    totalCount,
    clearAuditLogs,
    auditLogPage,
    setCurrentPage,
    auditLogFilter,
    setAuditLogFilter,
    auditLogSorter,
    setAuditLogSorter,
    auditLogSearchTextMap,
    setAuditLogSearchTextMap,
  } = useAuditLogStore((state) => ({
    auditLogs: state.auditLogs,
    getAuditLogs: state.getAuditLogs,
    totalCount: state.totalCount,
    clearAuditLogs: state.clearAuditLogs,
    auditLogPage: state.pageInfo,
    setCurrentPage: state.setCurrentPage,
    auditLogFilter: state.filter,
    setAuditLogFilter: state.setFilter,
    auditLogSorter: state.sorter,
    setAuditLogSorter: state.setSorter,
    auditLogSearchTextMap: state.searchTextMap,
    setAuditLogSearchTextMap: state.setSearchTextMap,
  }));

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const loadAuditLogs = async () => {
    try {
      setLoader(true);
      await getAuditLogs();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      clearAuditLogs();
    };
  }, []);

  useEffect(() => {
    loadAuditLogs();
  }, [auditLogFilter, auditLogSorter, auditLogSearchTextMap, auditLogPage]);

  useEffect(() => {
    const userIds = auditLogs.map((auditLog) => auditLog.userID);
    userIds.length > 0 && loadUserInfos(userIds);
  }, [auditLogs]);

  const autitLogColumns: ColumnsType<Activity> = [
    {
      title: "Date & Time",
      dataIndex: "localTime",
      key: "localTime",
      width: "20%",
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
        <Space direction="vertical" style={{ padding: token.paddingXS }}>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format='YYYY-MM-DDTHH:mm'
            onChange={(dates: any, dateStrings: [string, string]) => {
              setSelectedKeys(dateStrings);
            }}
            value={selectedKeys.length == 0 ? undefined : [dayjs(selectedKeys[0] as string), dayjs(selectedKeys[1] as string)]}
            placement={"bottomLeft"}
            allowClear={false}
          />
          <Row justify="end">
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setLocalTimeFiltered(true);
                  confirm();
                }}
                size="small"
                style={{ width: 90 }}
                disabled={selectedKeys.length == 0}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.();
                  setSelectedKeys([]);
                  setLocalTimeFiltered(false);
                  delete auditLogFilter['localTime'];
                  setAuditLogFilter({...auditLogFilter});
                  confirm();
                }}
                size="small"
                style={{ width: 90 }}
                disabled={selectedKeys.length == 0}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Space>
      ),
      filteredValue: auditLogFilter["localTime"],
      filtered: localTimeFiltered,
      render: (localTime: string) => 
        new Date(localTime).toLocaleTimeString(undefined, dateTimeFormatOptions),
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      filters: Array.from(ActivityGroupMap, ([tag, value]) => ({
        text: value,
        value: tag,
      })),
      filterSearch: true,
      filteredValue: auditLogFilter["group"],
      render: (group: ActivityGroup) =>
        <Text>{ActivityGroupMap.get(group)}</Text>
    },
    {
      title: "User",
      dataIndex: "userID",
      key: "user",
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && <Avatar src={userInfos[userID]?.logoUrl} />}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <Text>{message}</Text>
      ),
    },
  ];

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setAuditLogSorter(sorter);
    setAuditLogFilter(filters);
    setCurrentPage();
  };

  return (
    <Spin spinning={loader}>
      <EntitiesDisplay
        header="Audit Logs"
        dataSource={auditLogs}
        rowKey={"localTime"}
        columns={autitLogColumns}
        pageNumber={auditLogPage.number}
        pageSize={auditLogPage.size}
        total={totalCount}
        onChange={onChange}
        onSearch={(text: string) => {
          setCurrentPage();
          setAuditLogSearchTextMap(new Map<string, string>().set("message", text));
        }}
        searchPlaceholder={"search audit logs by message"}
        searchText={auditLogSearchTextMap?.get("message")}
        onPageChange={(pageNumber: number, pageSize: number) =>
          setCurrentPage(pageNumber, pageSize)
        }
      />
    </Spin>
  );
};

export default AuditLogs;
