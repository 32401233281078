import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, Space, Typography, theme } from "antd";
import { SvgIcon } from "components/SvgIcon";
import { VideoPlayer } from "components/VideoPlayer";
import { FunctionComponent } from "react";
const { Text } = Typography;

export interface QuickLinkProps {
  id: string;
  category: string;
  duration: string;
  cta: string;
  description: string;
  icon: FunctionComponent;
  backgroundColor: string;
  videoUrl: string;
  thumbnailUrl: string;
  onCtaClick: () => void;
}

export const QuickLink = (props: QuickLinkProps) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Card
        style={{
          flex: 1,
          backgroundColor: props.backgroundColor,
          border: "none",
        }}
        styles={{
          body: {
            padding: token.padding,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Space>
            <div id="quick-link-image">
              <SvgIcon
                component={props.icon}
                size={token.fontSizeHeading1}
                style={{
                  width: "60px",
                  height: "60px",
                }}
              />
            </div>
            <div id="quick-link-title">
              <Text style={{fontSize: token.fontSizeHeading5}}>{props.category}</Text>
              <Text>{` (${props.duration})`}</Text>
            </div>
          </Space>
          <div
            id="quick-link-cta"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text style={{ fontSize: token.fontSizeHeading4 }}>
              {props.cta}
            </Text>
            <ArrowRightOutlined
              style={{
                fontSize: token.fontSizeHeading5,
                color: token.colorText,
              }}
              onClick={props.onCtaClick}
            ></ArrowRightOutlined>
          </div>
          <div id="quick-link-description">
            <Text>{props.description}</Text>
          </div>
        </div>
      </Card>
      <div
        style={{
            padding: token.padding,
            backgroundColor: props.backgroundColor,
            display: "flex",
            flexDirection: "column",
            gap: "5px",
        }}
      >
        <div id="react-player" style={{position: "relative", paddingTop: "56.25%"}}>
          <VideoPlayer
            videoUrl={props.videoUrl}
            thumbnailUrl={props.thumbnailUrl}
          />
        </div>
        {/* {props.cta && 
            <Popover title={props.videoUrl}>
                <Text  strong style={{ fontSize: token.fontSizeSM }}>{props.cta}</Text>
            </Popover>
        } */}
      </div>
    </div>
  );
};
